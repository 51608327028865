import { Panel, Table } from "common";
import React, { Component, Fragment } from "react";
import { array, func, number, oneOfType, shape, string } from "prop-types";
import { getEndDate, getStartDate } from "screens/sites/helpers/date_helpers";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchAdwordsCampaigns } from "screens/sites/actions";
import { formatStartAndEndDateFromAppHeader } from "lib/dateHelpers";
import logo from "images/icon_adwords.png";

class GoogleAdwordsCampaigns extends Component {
  static propTypes = {
    fetchAdwordsCampaigns: func,
    adwords_id: oneOfType([string, number]),
    date: shape({
      month: string,
      year: string
    }),
    adwordsCampaigns: array
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.adwords_id) return;
    const params = {
      adwords_id: this.props.adwords_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = prevProps => {
    const hasDifferentMonth = this.props.date.month !== prevProps.date.month;
    const hasDifferentYear = this.props.date.year !== prevProps.date.year;
    const hasDifferentId = this.props.adwords_id !== prevProps.adwords_id;

    if (!this.props.adwords_id) return;

    if (
      this.props.date.dateOneStartForCompare !==
        prevProps.date.dateOneStartForCompare ||
      this.props.date.dateOneEndForCompare !==
        prevProps.date.dateOneEndForCompare
    ) {
      // let momentStart = moment(this.props.date);
      // let momentEnd = moment(this.props.date);
      const appHeaderDates = formatStartAndEndDateFromAppHeader(this.props.date)
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: this.props.date.dateOneStartForCompare || appHeaderDates.startOfMonth,
        endDate: this.props.date.dateOneEndForCompare || appHeaderDates.endOfMonth
      };
      this.fetchData(params);
    }

    if (hasDifferentId) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.props.clearCacheBool === true &&
        this.props.fetchAdwordsCampaigns(
          params,
          () => {},
          this.props.clearCacheBool
        );
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });
    // const appHeaderDates = formatStartAndEndDateFromAppHeader(this.props.date)
    this.props.fetchAdwordsCampaigns(
      params,
      () => this.mounted && this.setState({ isFetching: false }),
      this.props.clearCacheBool
    );
  };

  calculateSales = data => {
    data.forEach(item => {
      if (item.roas !== 0 && item.roas !== "0") {
        let sales = 0;
        sales += Number(item.Conversions);
        item.sales = sales.toFixed(2);
      } else {
        item.sales = 0;
      }
    });
  };
  calculateRevenue = data => {
    data.forEach(item => {
      let revenue = 0;
      if (item.roas !== 0 && item.roas !== "0") {
        revenue += Number(item.Total_conv_value);
        item.revenue = revenue.toFixed(2);
      } else {
        item.sales = 0;
      }
    });
  };
  render() {
    // Sorts campaigns in alphabetical order
    const adwordsCampaignsSorted = this.props.adwordsCampaigns.sort((a, b) =>
      a.roas !== b.v ? (a.roas > b.roas ? -1 : 1) : 0
    );
    this.calculateSales(adwordsCampaignsSorted);
    this.calculateRevenue(adwordsCampaignsSorted);
    if (!this.props.adwords_id) return null;
    const adwordsCampaigns = adwordsCampaignsSorted.map(data => ({
      campaign: { value: data.Campaign },
      conversions: { value: data.Conversions },
      cost: { value: data.Cost },
      sales: { value: data.sales || 0 },
      revenue: { value: data.revenue || 0 },
      roas: { value: data.roas || 0 },
      clicks: { value: data.Clicks },
      "cost / conv": { value: data.Cost_conv },
      "search impressions lost": { value: data.Search_Lost_IS_budget }
    }));
    let headers = [
      "Campaign",
      "Conversions",
      "Cost",
      "Sales",
      "Revenue",
      "Roas",
      "Clicks",
      "Cost / Conv",
      "Search Impressions Lost (Budget)"
    ];
    if (this.props.isEcommerce) {
      headers = [
        "Campaign",
        "Cost",
        "Sales",
        "Revenue",
        "Roas",
        "Clicks",
        "Cost / Conv",
        "Search Impressions Lost (Budget)"
      ];
    }
    let adwordsClone = _.cloneDeep(adwordsCampaigns);
    if (this.props.isEcommerce) {
      if (adwordsClone) {
        if (adwordsClone.length > 0) {
          adwordsClone.forEach(item => {
            delete item.conversions;
          });
        }
      }
    }

    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="5">Campaigns</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Campaigns</td>
                  <td>Conversions</td>
                  <td>Clicks</td>
                  <td>Cost</td>
                  <td>Cost / Conv</td>
                  <td>Search Impressions Lost (Budget)</td>
                  <td>Roas</td>
                  <td>Sales</td>
                  <td>Revenue</td>
                </tr>
                {adwordsCampaigns.map(item => {
                  return (
                    <tr key={item.campaign.value}>
                      <td>{item.campaign.value}</td>
                      <td>{item.conversions.value}</td>
                      <td>${item.clicks.value}</td>
                      <td>{item.cost.value}</td>
                      <td>${item["cost / conv"].value}</td>
                      <td>{item["search impressions lost"].value}</td>
                      <td>{item["roas"].value || 0}</td>
                      <td>{item.sales.value || 0}</td>
                      <td>{item.revenue.value || 0}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return (
      <Fragment>
        <ReportSubTitle
          text="Google Ads Campaigns"
          classes="mt-3"
          logo={logo}
        />
        <Panel containerClass="col-md-12 p-3" isLoading={this.state.isFetching}>
          <Panel.Body
            style={{
              display: "block",
              paddingBottom: "1em"
            }}
            render={() => (
              <Table
                headers={headers}
                data={this.props.isEcommerce ? adwordsClone : adwordsCampaigns}
              />
            )}
          />
        </Panel>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { adwords_id },
      paidAdsData: { adwordsCampaigns }
    }
  }
}) => ({
  date,
  adwords_id,
  adwordsCampaigns
});
GoogleAdwordsCampaigns.defaultProps = {
  isEcommerce: false
};
export default connect(
  mapStateToProps,
  { fetchAdwordsCampaigns }
)(GoogleAdwordsCampaigns);
