import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import * as sitesActions from "screens/sites/actions";
import * as clientsActions from "screens/clients/actions";
import { getHashFromUrl } from "screens/sites/helpers/endpoint_helpers";
import { getQueryVar } from 'lib/utilities';

import PrintControls from "screens/sites/components/PrintControls";
import PrintTitle from "screens/sites/components/PrintTitle";
import PrintAnalytics from "screens/sites/components/PrintAnalytics";
import PrintPaidAds from "screens/sites/components/PrintPaidAds";
import PrintRankings from "screens/sites/components/PrintRankings";
import PrintLeads from "screens/sites/components/PrintLeads";
import PrintEcommerce from "screens/sites/components/PrintEcommerce";

class SitesShowPrint extends Component {
  static propTypes = {
    fetchSite: func,
    setPermission: func,
    displayYOY: bool
  };

  state = {
    hash: getHashFromUrl(window.location.pathname).slice(0, -6),
    displayYOY: (getQueryVar('dy') === "true")
  };

  componentDidMount() {
    this.props.fetchSite({ hash: this.state.hash, isPublic: true });

    const hash = getHashFromUrl(window.location.pathname);
    const isPublic = !Number(hash);

    this.props.setPermission({ isPublic });
  }

  render() {
    return (
      <Fragment>
        <PrintControls />
        <PrintTitle />
        {(this.props.site || {}).ga_account_id !== null ? <PrintAnalytics displayYOY={this.state.displayYOY} /> : null}
        <PrintPaidAds />
        {(this.props.site || {}).se_site_id !== null ? <PrintRankings /> : null}
        {(this.props.site || {}).wc_token !== null ? <PrintLeads /> : null}
        {(this.props.site || {}).shopify_access_token !== null ? <PrintEcommerce /> : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ sites: { show: { data } } }) => ({ site: data });

const connected = connect(mapStateToProps, {
  ...sitesActions,
  ...clientsActions
})(SitesShowPrint);

export { connected as SitesShowPrint };
