import React from "react";
import { bool, node } from "prop-types";
import { Loader } from "common";

function withLoader(Component) {
  function Wrapper({ isLoading, children, ...props }) {
    return (
      <Component {...props}>
        {isLoading && <Loader />}
        {!isLoading && children}
      </Component>
    );
  }

  Wrapper.propTypes = {
    isLoading: bool,
    children: node
  };
  Wrapper.displayName = "withLoader()";

  return Wrapper;
}

export default withLoader;
