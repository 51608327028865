import { createReducer } from "lib/utilities";

const initialState = {
  index: {
    data: {}
  }
};

export default createReducer(initialState, {
  FETCHING_TEMPLATES_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: payload
    }
  }),
  UPDATING_TEMPLATE_SUCCESS: (state, payload) => {
    const targetArr = state.index.data[payload.team.name];
    const index = targetArr.findIndex(item => item.id === payload.id);
    const updatedData = [
      ...targetArr.slice(0, index),
      {
        data: payload.data,
        id: payload.id,
        title: payload.title
      },
      ...targetArr.slice(index + 1)
    ];

    return {
      ...state,
      index: {
        data: {
          ...state.index.data,
          [payload.team.name]: updatedData
        }
      }
    };
  }
});
