import React from "react";


const ReviewLoopDemoConfirmation = props => {
  return (
    <div className="review-confirmation">
      <h2>ReviewLoop just sent you a real review request via text!</h2>
      <p>(This is what your customers would see, with your business details!)</p>
      <button className={"btn btn-secondary"} onClick={() => {
        props.displayModal()
      }}>Close
      </button>
    </div>
  )
}


export {ReviewLoopDemoConfirmation};