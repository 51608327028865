import React, { Component } from "react";

import WhatConvertsLeads from "screens/sites/components/WhatConvertsLeads";
import { CallRailCalls } from "../components/CallRailCalls";
import { RedisButton, Toggle } from "common";
import { useCallRail } from "lib/api";
class Leads extends Component {
  state = {
    clearCacheBool: false,
  };
  render() {
    let context = this;
    return (
      <div className="col-sm-12">
        {this.props.isPrimarySite && (
          <div className="lead-toggle-row">
            <div className="toggle-wrapper">
              <Toggle
                label="All Site Inquiries"
                toggle={this.props.fetchAllSites}
                onChange={this.props.onFetchAllSitesChange}
              />
            </div>
          </div>
        )}
        <WhatConvertsLeads
          clearCacheBool={this.state.clearCacheBool}
          changeComparison={this.props.changeComparison}
          compare={this.props.compare}
          fetchAllSites={this.props.fetchAllSites}
          siteId={this.props.siteId}
        />
        {useCallRail ? <CallRailCalls /> : null}
        <RedisButton isPublic={this.props.isPublic} context={context} />
      </div>
    );
  }
}

export default Leads;
