import React, { Component } from "react";

import { GenericPieGraph } from "common";

class DemoGoogleAdwordsConversionSources extends Component {

    render() {
        // Fixtures 
        const isFetching = false;
        const adSources = [
            {
                "name": "Calls from website",
                "value": 2.4
            },
            {
                "name": "Website",
                "value": 5.4
            },
            {
                "name": "",
                "value": 1
            },
            {
                "name": "Calls from ads",
                "value": 2
            }
        ]
        return (
            <GenericPieGraph
                title="Conversion Sources"
                isLoading={isFetching}
                data={adSources}
            />
        )
    }
}

export default DemoGoogleAdwordsConversionSources;
