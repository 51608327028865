import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EditorState, convertFromRaw } from "draft-js";

import * as notesActions from "screens/notes/actions";
import NoteItem from "screens/clients/components/NoteItem";
import NoteForm from "screens/clients/components/NoteForm";
import { Button, Card } from "common";

class NotesContainer extends Component {
  state = {
    displayForm: false,
    editing: false,
    editId: null,
    isLoading: true,
    paginationPage: 1
  };

  handleCreateNote = data => {
    data.client_id = this.props.clients.show.data.id;
    let client = this.props.clientDetails.name;
    let { first_name, last_name, email } = this.props.clientDetails.manager;
    data.client_name = client;
    data.manager_first_name = first_name;
    data.manager_last_name = last_name;
    data.manager_email = email;
    let raw =  convertFromRaw(JSON.parse(data.rawContent));
    let editorState = EditorState.createWithContent(raw);
    let text = editorState.getCurrentContent().getPlainText('\u0001')
    data.text_content = text;
    this.props.createNote(data, () => {
      this.setState({ displayForm: false });
    });
  };

  componentDidMount() {
    this.props.fetchNotes(this.props.clientDetails.id, 1, () => {
      this.setState({ isLoading: false });
    });
  }

  handleDeleteNote = (e, id) => {
    e.preventDefault();
    this.props.deleteNote(id);
  };

  handleEditNote = id => {
    this.setState({
      displayForm: !this.state.displayForm,
      editing: !this.state.editing,
      editId: id
    });
  };

  onToggleDisplayForm = () => {
    this.setState(nextState => {
      return { displayForm: !nextState.displayForm, editing: false };
    });
  };

  handlePrevNotePage = () => {
    this.setState(
      { isLoading: true, paginationPage: this.state.paginationPage - 1 },
      () =>
        this.props.fetchNotes(
          this.props.clientDetails.id,
          this.state.paginationPage,
          () => {
            this.setState({ isLoading: false });
          }
        )
    );
  };

  handleNextNotePage = () => {
    this.setState(
      { isLoading: true, paginationPage: this.state.paginationPage + 1 },
      () =>
        this.props.fetchNotes(
          this.props.clientDetails.id,
          this.state.paginationPage,
          () => {
            this.setState({ isLoading: false });
          }
        )
    );
  };

  renderButtonleft = () => {
    let buttonDisabledLeft = false;
    let buttonDisabledRight = false;
    if (this.state.paginationPage === 1) {
      buttonDisabledLeft = true;
    }
    let pageLimit = Math.ceil(this.props.notes.index.totalNotes / 12);
    if (this.state.paginationPage === pageLimit) {
      buttonDisabledRight = true;
    }
    return this.state.editing === false && this.state.displayForm === false ? (
      <div>
        <Button
          classes={"button-blue mr-2"}
          onClick={this.onToggleDisplayForm}
          inverse
        >
          Add Note
        </Button>
        <Button
          classes={"mr-2"}
          key="chevron-left"
          disabled={buttonDisabledLeft}
          onClick={this.handlePrevNotePage}
          inverse
        >
          <i className="fas fa-chevron-left" />
        </Button>
        <Button
          key="chevron-right"
          disabled={buttonDisabledRight}
          onClick={this.handleNextNotePage}
          inverse
        >
          <i className="fas fa-chevron-right" />
        </Button>
      </div>
    ) : null;
  };

  renderBody() {
    const notes = this.props.notes.index.data;
    if (this.state.displayForm) {
      return (
        <NoteForm
          fromSitesShow={false}
          editNote={this.props.editNote}
          handleCreateNote={this.handleCreateNote}
          hideForm={this.onToggleDisplayForm}
          handleEditNote={this.handleEditNote}
          editing={this.state.editing}
          notes={notes}
          editId={this.state.editId}
        />
      );
    } else {
      let notesList = notes.map((el, i) => {
        return (
          <NoteItem
            handleEditNote={this.handleEditNote}
            handleDeleteNote={this.handleDeleteNote}
            currentUser={this.props.user.authentication.data}
            user={this.props.user.user_info.data}
            key={i}
            {...el}
          />
        );
      });
      return notesList.length ? (
        notesList
      ) : (
        <div className="alert mb-0">No notes added yet</div>
      );
    }
  }

  render() {
    return (
      <Card
        isLoading={this.state.isLoading}
        classes={"bg-default client-notes-container"}
        buttonLeft={this.renderButtonleft}
        padding={1}
      >
        {this.state.isLoading === false && this.renderBody()}
      </Card>
    );
  }
}

NotesContainer.propTypes = {
  user: PropTypes.object,
  clients: PropTypes.object,
  notes: PropTypes.object,
  createNote: PropTypes.func,
  deleteNote: PropTypes.func
};

const mapStateToProps = ({ user, clients, notes }) => ({
  user,
  clients,
  notes
});

const connected = connect(
  mapStateToProps,
  notesActions
)(NotesContainer);

export { connected as NotesContainer };
