import React, { Component } from "react";
import yelp_logo from "images/yelp_logo.png";
import google_logo from "images/google_logo.png";

import fb_logo from "images/fb_logo_reviews.png";
import { DemoReviewPanel } from "./DemoReviewPanel";
import { DemoAverageRatings } from "./DemoAverageRatings";



class Reviews extends Component {
    state = {
        yelpId: 1,
        googleId: 1,
        fbPageId: 1,
        yelpData: {
            review_count: 45,
            rating: 4.9,
        },
        googleData: {
            rating: 5,
            user_ratings_total: 58,
        },
        fbData: {
            overall_star_rating: 5,
            rating_count: 76
        },
        reviewSiteId: 1,
    }

    render() {
        return (
            <div className="row reviews-container">
                <div className="row mt-5 z-index-2">
                    <div className="col-sm-12 col-md-5 offset-md-1">
                        <DemoAverageRatings
                            yelpId={this.state.yelpId}
                            googleId={this.state.googleId}
                            yelpData={this.state.yelpData.rating}
                            googleData={this.state.googleData.rating}
                            fbData={this.state.fbData.overall_star_rating}
                            fbPageId={this.state.fbPageId}
                        />
                    </div>
                    <div className="col-sm-6 col-md-5 panels-container">
                        <DemoReviewPanel
                            updateSite={() => { }}
                            yelpData={this.state.yelpData}
                            fbData={this.state.fbData}
                            reviewSiteId={this.state.reviewSiteId}
                            googleData={this.state.googleData}
                            isLoading={false}
                            name={"Yelp"}
                            logo={yelp_logo}
                            fbPageId={this.state.fbPageId}
                            noOptionClass={'cursor-no'}
                            isPublic={true}
                        />
                        <DemoReviewPanel
                            updateSite={() => { }}
                            yelpData={this.state.yelpData}
                            fbData={this.state.fbData}
                            reviewSiteId={this.state.reviewSiteId}
                            googleData={this.state.googleData}
                            isLoading={false}
                            name={"Google"}
                            logo={google_logo}
                            fbPageId={this.state.fbPageId}
                            noOptionClass={'cursor-no'}
                            isPublic={true}
                        />
                        <DemoReviewPanel
                            updateSite={() => { }}
                            yelpData={this.state.yelpData}
                            fbData={this.state.fbData}
                            reviewSiteId={this.state.reviewSiteId}
                            googleData={this.state.googleData}
                            isLoading={false}
                            name={"Facebook"}
                            logo={fb_logo}
                            fbPageId={this.state.fbPageId}
                            noOptionClass={'cursor-no'}
                            isPublic={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default Reviews;
