import React, { Component, Fragment } from "react";
import { string, number, oneOfType, bool } from "prop-types";
import { connect } from "react-redux";

import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";
import ThirdPartyDisconnect from "screens/sites/components/ThirdPartyDisconnect";
import AdwordsConnect from "screens/sites/components/AdwordsConnect";
import {
  fetchAdwordsAccounts,
  fetchAdwordsMCCAccounts,
  formatAdwordsAccounts,
} from "screens/sites/helpers/adwords_helpers";
import {
  fetchFacebookAccounts,
  formatFacebookAccounts
} from "screens/sites/helpers/facebook_helpers";

import AdsOverview from "./AdsOverview";


import { updateSite } from '../actions/sites_actions';

import { RedisButton } from "common";


class PaidAds extends Component {
  static propTypes = {
    siteId: oneOfType([string, number]),
    adwords_id: string,
    fb_ads_id: string,
    isPublic: bool,
  };

  state = {
    tab: "Adwords",
    clearCacheBool: false,
    cacheFromWeekly: true,
  };

  toggleTab(e, tab) {
    e.preventDefault();
    this.setState({
      tab
    });
  }

  renderTab() {
    let { tab } = this.state;
    let context = this;
    if (tab === "Adwords") {
      return (
        <div className="row">
          <ThirdPartyFallback id={this.props.adwords_id} isMCC={this.props.adwords_mcc_id} type="Paid Ads" />
          {!this.props.isPublic && (
            <div className="col-12">
              <AdwordsConnect
                siteId={this.props.siteId}
                fetchAccounts={fetchAdwordsAccounts}
                formatAccounts={formatAdwordsAccounts}
                type="adwords_id"
                displayName="Adwords Account"
              />
              <ThirdPartyDisconnect
                siteId={this.props.siteId}
                isMCC={this.props.adwords_mcc_id}
                type="adwords_id"
                displayName="Adwords"
              />
            </div>
          )}
          {(this.props.adwords_id || this.props.adwords_mcc_id) && <AdsOverview type="Google" clearCacheBool={this.state.clearCacheBool} fetchAdwordsMCCAccounts={fetchAdwordsMCCAccounts} context={context} />}
          <RedisButton  isPublic ={this.props.isPublic} cacheFromWeekly={this.state.cacheFromWeekly} context={context} />
        </div>
      )
    } else {
      return (
        <div className="row">
          <ThirdPartyFallback id={this.props.fb_ads_id} type="Paid Ads" />
          {!this.props.isPublic && (
            <Fragment>
              <div className="col-12">
                <AdwordsConnect
                  siteId={this.props.siteId}
                  fetchAccounts={fetchFacebookAccounts}
                  formatAccounts={formatFacebookAccounts}
                  type="fb_ads_id"
                  displayName="Facebook Ads"
                />
                <ThirdPartyDisconnect
                  siteId={this.props.siteId}
                  type="fb_ads_id"
                  displayName="Facebook"
                />
              </div>
            </Fragment>
          )}
          {this.props.fb_ads_id && <AdsOverview type="Facebook" clearCacheBool={this.state.clearCacheBool} fetchAdwordsMCCAccounts={fetchAdwordsMCCAccounts} context={context} />}
          <RedisButton isPublic ={this.props.isPublic} cacheFromWeekly={this.state.cacheFromWeekly} context={context} />
        </div>
      );
    }
  }

  render() {
    let tabs = ["Adwords", "Facebook"];
    return (
      <div className="col-sm-12">
        <ul className="secondary-nav">
          {tabs.map((el, i) => {
            return <li key={i}><a className={(el === this.state.tab ? "active" : "")} onClick={e => this.toggleTab(e, el)}>{el}</a></li>
          })}
        </ul>
        {this.renderTab()}
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      permissions: { isPublic },
      data: { adwords_id, fb_ads_id, id: siteId },
    }
  },
  sites: { show: { data: { adwords_mcc_id } } },
}) => ({
  siteId,
  adwords_id,
  fb_ads_id,
  isPublic,
  adwords_mcc_id
});

const mapDispatchToProps = { updateSite }

export default connect(mapStateToProps, mapDispatchToProps)(PaidAds);
