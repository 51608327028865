import { createReducer } from "../../lib/utilities";

const initialState = {
  index: {
    isFetching: false,
    errors: null,
    data: []
  },
  new: {
    isCreating: false,
    isCreatingComment: false,
    errors: null
  },
  edit: {
    isUpdating: false,
    errors: null
  }
};

export default createReducer(initialState, {
  FETCHING_TODOS: state => ({
    ...state,
    index: {
      ...state.index,
      isFetching: true
    }
  }),
  FETCHING_TODOS_FAIL: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      isFetching: false,
      errors: payload
    }
  }),
  FETCHING_TODOS_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      isFetching: false,
      errors: null,
      data: payload
    }
  }),
  CREATING_TODO: state => ({
    ...state,
    new: {
      ...state.new,
      isCreating: true
    }
  }),
  CREATING_TODO_FAIL: (state, payload) => ({
    ...state,
    new: {
      ...state.new,
      isCreating: false,
      errors: payload
    }
  }),
  CREATING_TODO_SUCCESS: (state, payload) => ({
    ...state,
    new: {
      ...state.new,
      isCreating: false,
      errors: null
    },
    index: {
      ...state.index,
      data: [...state.index.data, payload]
    }
  }),
  UPDATING_TODO: state => ({
    ...state,
    edit: {
      ...state.edit,
      isUpdating: true
    }
  }),
  UPDATING_TODO_FAIL: (state, payload) => ({
    ...state,
    edit: {
      ...state.edit,
      isUpdating: false,
      errors: payload
    }
  }),
  UPDATING_TODO_SUCCESS: (state, payload) => {
    const index = state.index.data.findIndex(item => item.id === payload.id);

    const updatedData = [
      ...state.index.data.slice(0, index),
      payload,
      ...state.index.data.slice(index + 1)
    ];

    return {
      ...state,
      index: {
        data: updatedData
      },
      edit: {
        ...state.edit,
        isUpdating: false,
        errors: null
      }
    };
  },
  CREATING_TODO_COMMENT: state => ({
    ...state,
    new: {
      ...state.new,
      isCreatingComment: true
    }
  }),
  CREATING_TODO_COMMENT_FAIL: state => ({
    ...state,
    new: {
      ...state.new,
      isCreatingComment: false
    }
  }),
  CREATING_TODO_COMMENT_SUCCESS: state => ({
    ...state,
    new: {
      ...state.new,
      isCreatingComment: false
    }
  })
});
