import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Panel } from "common";


class NoGraphDataNotification extends Component {
    render() {
        return (
            <Fragment>
                <Panel
                    containerClass="col-12 p-3 float-left"
                    isLoading={null}
                    padding={2}>
                    <Panel.Body
                        className={"no-graph-data-container"}
                        render={() => {
                            return (
                                <h1>No graph data available for the current selection.</h1>
                            )
                        }}
                    />
                </Panel>
            </Fragment>
        )
    }
}
export { NoGraphDataNotification }