import React from "react";
import { array, bool, func, number, string } from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ContentEditable from "react-simple-contenteditable";
import { getListStyle, getItemStyle } from "screens/todos/helpers/dnd_helpers";
import Checkbox from "screens/todos/components/Checkbox";

/*
* Main List
*/
SubtasksList.propTypes = {
  data: array.isRequired,
  todoId: number,
  onDragEnd: func,
  handleDeleteSubtask: func,
  handleToggleSubtask: func,
  handleUpdateSubtask: func
};
function SubtasksList(props) {
  return (
    <DragDropContext
      onDragEnd={result => props.onDragEnd({ result, todoId: props.todoId })}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {props.data.map((item, subIndex) => (
              <Draggable key={subIndex} draggableId={item.id} index={subIndex}>
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}>
                      {item.section && (
                        <SectionTitle
                          key={subIndex}
                          title={item.title}
                          handleDeleteSubtask={props.handleDeleteSubtask}
                          handleUpdateSubtask={props.handleUpdateSubtask}
                          subIndex={subIndex}
                          todoId={props.todoId}
                        />
                      )}

                      {!item.section && (
                        <ListItem
                          key={subIndex}
                          handleToggleSubtask={props.handleToggleSubtask}
                          handleUpdateSubtask={props.handleUpdateSubtask}
                          handleDeleteSubtask={props.handleDeleteSubtask}
                          title={item.title}
                          completed={item.completed}
                          subIndex={subIndex}
                          todoId={props.todoId}
                        />
                      )}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

/*
 * SectionTitle
 */
SectionTitle.propTypes = {
  title: string,
  handleUpdateSubtask: func,
  handleDeleteSubtask: func,
  subIndex: number,
  todoId: number
};
function SectionTitle(props) {
  return (
    <section className="todo-subtasks-section">
      <i className="fas fa-ellipsis-v mr-3" />
      <SubtaskInput
        section
        completed={false}
        title={props.title}
        handleUpdateSubtask={props.handleUpdateSubtask}
        subIndex={props.subIndex}
        todoId={props.todoId}
      />
      <section className="todo-subtasks-delete">
        <button
          className="btn btn-inverse"
          onClick={props.handleDeleteSubtask.bind(null, {
            subIndex: props.subIndex,
            todoId: props.todoId
          })}>
          <i className="fas fa-trash" />
        </button>
      </section>
    </section>
  );
}

/*
 * ListItem
 */
ListItem.propTypes = {
  completed: bool,
  handleToggleSubtask: func,
  handleUpdateSubtask: func,
  handleDeleteSubtask: func,
  subIndex: number,
  todoId: number,
  title: string
};
function ListItem(props) {
  return (
    <section className="todo-subtasks-item">
      <div className="todos-subtasks-ellipsis-container">
        <i className="fas fa-ellipsis-v mr-2" />
      </div>
      <Checkbox
        completed={props.completed}
        onClick={props.handleToggleSubtask.bind(null, {
          subIndex: props.subIndex,
          todoId: props.todoId
        })}
      />
      <SubtaskInput
        section={false}
        completed={props.completed}
        title={props.title}
        handleUpdateSubtask={props.handleUpdateSubtask}
        subIndex={props.subIndex}
        todoId={props.todoId}
      />
      <section className="todo-subtasks-delete">
        <button
          className="btn btn-inverse"
          onClick={props.handleDeleteSubtask.bind(null, {
            subIndex: props.subIndex,
            todoId: props.todoId
          })}>
          <i className="fas fa-trash" />
        </button>
      </section>
    </section>
  );
}

/*
 * SubtaskInput
 */
SubtaskInput.propTypes = {
  title: string,
  handleUpdateSubtask: func,
  subIndex: number,
  todoId: number,
  completed: bool,
  section: bool
};
function SubtaskInput(props) {
  if (props.completed) {
    return (
      <div
        style={{
          width: "100%",
          color: "#d6d6d",
          textDecoration: "line-through",
          fontSize: props.section ? "1.5em" : "1em",
        }}>
        {props.title}
      </div>
    );
  }
  if (!props.completed) {
    return (
      <ContentEditable
        html={props.title}
        onChange={(e, value) =>
          props.handleUpdateSubtask({
            e,
            value,
            subIndex: props.subIndex,
            todoId: props.todoId
          })
        }
        contentEditable="plaintext-only"
        className="todo-subtasks-item__input"
        style={{
          width: "100%",
          color: "#939598",
          textDecoration: "none",
          fontSize: props.section ? "1.5em" : "1em",
          fontWeight: props.section ? "bold" : "normal"
        }}
      />
    );
  }
}

export default SubtasksList;
