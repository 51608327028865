import React, { Component } from "react";
import { func, object } from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getListStyle,
  getItemStyle,
  reorder
} from "screens/todos/helpers/dnd_helpers";

import * as actions from "screens/templates/actions";
import * as teamsActions from "screens/teams/actions";

import { Button, Card, FormGroup, FormGroupSelect } from "common";
import { handleInputChange } from "lib/utilities";

class TemplatesNew extends Component {
  static propTypes = {
    createTemplate: func,
    fetchTeams: func,
    teams: object
  };

  state = {
    title: "",
    team_id: "",
    subtaskTitle: "",
    subtaskPreview: []
  };

  componentDidMount = async () => {
    await this.props.fetchTeams();
  };

  handleAddSubtask = e => {
    e.preventDefault();
    if (this.state.subtaskTitle.length < 2) return;
    const subtask =
      this.state.subtaskTitle[0] === "#"
        ? {
            title: this.state.subtaskTitle.slice(1),
            section: true,
            completed: false
          }
        : {
            title: this.state.subtaskTitle,
            section: false,
            completed: false
          };
    const subtaskPreview = this.state.subtaskPreview.concat(subtask);
    this.setState({ subtaskTitle: "", subtaskPreview });
  };

  handleRemoveSubtask = index => {
    this.setState({
      subtaskPreview: [
        ...this.state.subtaskPreview.slice(0, index),
        ...this.state.subtaskPreview.slice(index + 1)
      ]
    });
  };

  handleSubmit = () => {
    this.props.createTemplate({
      team_id: this.state.team_id,
      data: JSON.stringify(this.state.subtaskPreview),
      title: this.state.title
    });
  };

  renderTeams = () => {
    const options = this.props.teams.data.map(team => {
      return {
        id: team.id,
        name: team.name
      };
    });

    return (
      <FormGroupSelect
        label="Team"
        value={this.state.team_id}
        onChange={e => handleInputChange(this, e, "team_id")}
        options={options}
      />
    );
  };

  renderTemplatePreview = () => {
    if (!this.state.subtaskPreview.length) return;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.subtaskPreview.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={`${item.title}+${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="template-detail-item">
                          <div
                            style={{
                              width: "100%",
                              fontSize: item.section ? "1.5em" : "1em",
                              fontWeight: item.section ? "bold" : "normal"
                            }}
                          >
                            {item.title}
                          </div>

                          <button
                            className="template-detail-item__trash"
                            onClick={this.handleRemoveSubtask.bind(this, index)}
                          >
                            <i className="fas fa-trash" />
                          </button>
                        </div>
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  onDragEnd = result => {
    if (!result.destination) return;

    const subtaskPreview = reorder(
      this.state.subtaskPreview,
      result.source.index,
      result.destination.index
    );

    this.setState({
      subtaskPreview
    });
  };

  render() {
    const isDisabled =
      this.state.title === "" ||
      this.state.team_id === "" ||
      this.state.subtaskPreview.length < 1;

    return (
      <Card
        title="Create Template"
        classes="col-sm-12 col-md-6 offset-md-3"
        isLoading={this.props.teams.isFetching}
        padding={5}
      >
        <FormGroup
          label="Template Name"
          type="text"
          value={this.state.title}
          onChange={e => handleInputChange(this, e, "title")}
        />
        {!this.props.teams.isFetching && this.renderTeams()}

        {this.renderTemplatePreview()}

        <form onSubmit={this.handleAddSubtask}>
          <input
            className="template-detail-form"
            placeholder="Start Typing. Press Enter to Add Item to Template."
            type="text"
            value={this.state.subtaskTitle}
            onChange={e => handleInputChange(this, e, "subtaskTitle")}
          />
        </form>

        <Button
          onClick={this.handleSubmit}
          disabled={isDisabled}
          style={{ marginTop: "10px" }}
        >
          Create Template
        </Button>
        <Button
          link to={`/templates`}
          style={{ marginLeft: "10px" }}
        >
          Cancel
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = ({ teams }) => ({ teams });

const connected = connect(mapStateToProps, { ...actions, ...teamsActions })(
  TemplatesNew
);

export { connected as TemplatesNew };
