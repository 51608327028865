import React, { Component, Fragment } from "react";

import {Panel, Table, Toggle} from "common";
import LeadAnalytics from "screens/sites/components/LeadAnalytics";
import { Inqueries_Types_Mediums_Sources_History } from "../../fixtures/fixtures"

import { InqueriesData } from "../../fixtures/fixtures";

class WhatConvertsLeads extends Component {
    render() {
        // Fixtures 
        const isFetching = false;
        const handleCompareChange = () => { }
        const compare = 6;
        const isPublic = false;
        return (
            <Fragment>
                <LeadAnalytics isPublic={isPublic} handleCompareChange={handleCompareChange} compare={compare} isFetching={isFetching} leadAnalytics={Inqueries_Types_Mediums_Sources_History} />
                <Panel containerClass="col-md-12 p-3">
                    <Panel.Header
                        title="Inquiries"
                    />
                    <Panel.Body
                        isLoading={isFetching}
                        style={{
                            display: "block",
                            borderTop: `2px solid #ffffff`,
                            paddingBottom: "1em"
                        }}
                        render={() => (
                            <Table
                                headers={["Lead Type", "Source", "Medium", "Date", "Time"]}
                                data={InqueriesData}
                            />
                        )}
                    />
                </Panel>
            </Fragment>
        );
    }
}

export default WhatConvertsLeads;
