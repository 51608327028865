const stableVersion = "v1";
export const isAlliance = window.location.origin.includes(
  ".allianceanimal.com"
);
export const isLocal = window.location.origin.includes("localhost");
export const isS3SB = window.location.origin.includes(".s3sb.com");
export const useCallRail = isAlliance || isS3SB;

const API_HOST_DEFAULT = "https://api.studio3marketing.com";
const API_HOST_ALLIANCE = "https://api.allianceanimal.com";
const API_HOST_S3SB = "https://api.s3sb.com";
const API_HOST_LOCAL = "http://localhost:1337";
const API_LEADLOOP_LOCAL = "http://localhost:8081";
const API_LEADLOOP = "https://api.leadloop.io/2.0/public/adwords";

export const apiBase = (function() {
  if (isAlliance) {
    return API_HOST_ALLIANCE;
  } else if (isS3SB) {
    return API_HOST_S3SB;
  } else if (isLocal) {
    return API_HOST_LOCAL;
  }

  return API_HOST_DEFAULT;
})();

export const rootUrl = `${apiBase}/${stableVersion}`;
export const googleAnalyticsRootUrl = `${apiBase}/`;
export const adwordsLeadloopApi = isLocal ? API_LEADLOOP_LOCAL : API_LEADLOOP;

export const getInstagramStatsEndpoint = () => {
  if (isAlliance) {
    return API_HOST_ALLIANCE;
  } else if (isS3SB) {
    return API_HOST_S3SB;
  }

  return API_HOST_DEFAULT;
};
