import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as actions from "screens/clients/actions";
import SBTicket from "screens/clients/components/SBTicket";
import { Button, Card } from "common";

class TicketsContainer extends Component {
  handlePrevSBTickets = () => {
    this.props.fetchSBTickets(
      this.props.client.supportbee_label,
      parseInt(this.props.client.sbTickets.current_page, 10) - 1
    );
  };

  handleNextSBTickets = () => {
    this.props.fetchSBTickets(
      this.props.client.supportbee_label,
      parseInt(this.props.client.sbTickets.current_page, 10) + 1
    );
  };

  renderButtonleft = () => [
    <Button key="chevron-left" onClick={this.handlePrevSBTickets} inverse>
      <i className="fas fa-chevron-left" />
    </Button>,
    <Button key="chevron-right" onClick={this.handleNextSBTickets} inverse>
      <i className="fas fa-chevron-right" />
    </Button>
  ];

  render() {
    return (
      <Card
        title="Tickets"
        isLoading={this.props.isFetching}
        buttonLeft={this.renderButtonleft}
      >
        {this.props.data.tickets.map((el, i) => {
          return <SBTicket key={i} {...el} />;
        })}
      </Card>
    );
  }
}

TicketsContainer.propTypes = {
  data: PropTypes.object,
  client: PropTypes.object,
  isFetching: PropTypes.bool,
  fetchSBTickets: PropTypes.func
};

const mapStateToProps = state => ({
  currentClient: state.currentClient
});

const connected = connect(mapStateToProps, actions)(TicketsContainer);

export { connected as TicketsContainer };
