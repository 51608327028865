import React, { Component } from "react";
import { StatisticsBar } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_shopify.png";

class DemoShopifySales extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const shopifySales = {
            "totalSales": {
                "title": "Total Sales",
                "value": "$102,426.20"
            },
            "totalOrders": {
                "title": "Total Orders",
                "value": 653
            
            },
            "averageOrderValue": {
                "title": "Average Order Value",
                "value": "$156.85"
            },
            "repeatCustomerRate": {
                "title": "Repeat Customer",
                "value": "24.18%"
            },
            "clv": {
                "title": "CLV",
                "value": "$123.05"
            }
        };
        return (
            <StatisticsBar
                renderSubtitle={() => (
                    <ReportSubTitle
                        text="Shopify Sales"
                        classes="pt-3 clearfix"
                        logo={logo}
                    />
                )}
                isLoading={isFetching}
                isPublic={false}
                data={shopifySales}
            />
        );
    }
}

export default DemoShopifySales;
