import { push } from "react-router-redux";
import axios from "axios";
import { apiBase } from "lib/api";
import { dispatchHelper } from "lib/actionHelpers";

import { AUTHENTICATING_USER, RESETTING_PASSWORD } from "./types";

export const authenticateUser = params => async dispatch => {
  const dispatching = dispatchHelper(AUTHENTICATING_USER);
  const url = `${apiBase}/auth`;
  try {
    const response = await axios.post(url, params);
    dispatching(response, () => dispatch(push("/")));
  } catch (err) {
    let response = null;
    if (err.response.status === 401) {
      response = {
        status: err.response.status,
        message: err.response.data.message
      }
    } else {
      response = { status: 500 };
    }
    dispatching(response);
  }
};

export const authLogoutAndRedirect = () => dispatch => {
  localStorage.removeItem("token");
  dispatch({ type: "LOG_OUT" });
  dispatch(push("/login"));
};

export const authLoginAndRedirect = token => dispatch => {
  localStorage.setItem("token", token);
  dispatch({ type: "AUTHENTICATION_REDIRECT", payload: token })
};

export const resetPassword = (params, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(RESETTING_PASSWORD);
  const url = `${apiBase}/auth/reset`;
  try {
    const response = await axios.post(url, params);
    dispatching(response, callback);
  } catch (err) {
    const response =
    {
      status: err.response.status,
      message: err.response.data.message
    };
    dispatching(response);
  }
};