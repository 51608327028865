import axios from "axios";
import { rootUrl } from "lib/api";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";

import { authLogoutAndRedirect } from "screens/users/actions";
import { UPDATING_TODO } from "./types";

export const updateTodo = (id, params) => async (dispatch) => {
  const dispatching = dispatchHelper(UPDATING_TODO);
  const url = `${rootUrl}/todos/${id}`;
  const token = getToken();
  const headers = requestHeaders(token);

  try {
    const response = await axios.patch(url, params, headers);
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const updatedTodoFollowers = (id, params) => async (dispatch) => {
  const dispatching = dispatchHelper(UPDATING_TODO);
  const url = `${rootUrl}/todos/${id}/followers`;
  const token = getToken();
  const headers = requestHeaders(token);

  try {
    const response = await axios.post(url, params, headers);
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};
