import _ from "lodash";

export const paidAdsMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (!action.paidAds) {
      return next(action);
    }
    let val = 0;
    let newAction;
    if (action.isDataUpdate) {
      let campaign;
      if (!action.campaignId) {
        newAction = {
          ...action,
          payload: {
            data: [],
          },
          paidAds: false,
        };
        return dispatch(newAction);
      }
      let data = [];
      if (action.payload.data) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }

      action.adType === "Google" &&
        (campaign = data.find(
          (item) => item.Campaign_ID === action.campaignId
        ));
      action.adType === "Facebook" &&
        (campaign = action.payload.find(
          (item) => item.id === action.campaignId
        ));

      newAction = {
        ...action,
        payload: {
          data: campaign,
        },
        paidAds: false,
      };
      return dispatch(newAction);
    }

    if (action.adType === "Facebook") {
      let campaign = action.payload.find(
        (item) => item.id === action.FBCampaignId
      );
      if (campaign) {
        campaign.insights.forEach((item) => {
          let newVal = parseFloat(item[action.key]);
          val += newVal;
        });
        if (action.key === "clicks") {
          action.key = "clicks";
          let newArr = [];
          campaign.insights.forEach((item) => {
            if (item.outbound_clicks) {
              newArr.push({
                ctr: item.outbound_clicks[0].value,
                date_start: item.date_start,
              });
            } else {
              newArr.push({
                ctr: 0,
                date_start: item.date_start,
              });
            }
          });
          // Is CTR, modify key
          newAction = {
            ...action,
            payload: {
              key: action.key,
              data: newArr,
              campaignDoesNotExistInDateRangeError: false,
            },
            paidAds: false,
          };
          return dispatch(newAction);
        } else {
          let newArr = [];
          campaign.insights.forEach((item) => {
            newArr.push({
              [action.key]: item[action.key],
              date_start: item.date_start,
            });
          });
          newAction = {
            ...action,
            payload: {
              key: action.key,
              data: newArr,
              campaignDoesNotExistInDateRangeError: false,
            },
            paidAds: false,
          };
          return dispatch(newAction);
        }
        // Is not CTR, send off action
        // newAction = {
        //     ...action,
        //     payload: {
        //         key: action.key,
        //         data: campaign.insights,
        //         campaignDoesNotExistInDateRangeError: false,
        //     },
        //     paidAds: false,
        // };
      }
      // If no campaign exists in date range throw error
      else {
        newAction = {
          ...action,
          payload: {
            key: action.key,
            data: action.payload,
            campaignDoesNotExistInDateRangeError: true,
          },
          paidAds: false,
        };
      }
    }
    // Return default action
    newAction = {
      ...action,
      payload: {
        key: action.key,
        data: action.payload,
        campaignDoesNotExistInDateRangeError: false,
      },
      paidAds: false,
    };
    return dispatch(newAction);
  };
