import React from "react";
import { string } from "prop-types";

ProfileCircle.propTypes = {
  profileImage: string,
  firstName: string,
  lastName: string
};

function ProfileCircle({ profileImage, firstName, lastName }) {
  if (profileImage) {
    return (
      <span
        className="profile-image"
        style={{
          backgroundImage: `url(${profileImage})`
        }}
      />
    );
  } else {
    return (
      <span className="profile-image--initials">{`${firstName[0]}${
        lastName[0]
      }`}</span>
    );
  }
}

export default ProfileCircle;
