import React, { Component, Fragment } from "react";
import { bool, number, oneOfType, string } from "prop-types";

import GoogleAnalyticsConnect from "screens/sites/components/GoogleAnalyticsConnect";
import GoogleAnalyticsEvents from "../components/GoogleAnalyticsEvents";
import GoogleAnalyticsOrganicSiteMetrics from "screens/sites/components/GoogleAnalyticsOrganicSiteMetrics";
import GoogleAnalyticsPageViews from "screens/sites/components/GoogleAnalyticsPageViews";
import GoogleAnalyticsSessionsByMonth from "screens/sites/components/GoogleAnalyticsSessionsByMonth";
import GoogleAnalyticsSocialSourcePageViews from "screens/sites/components/GoogleAnalyticsSocialSourcePageViews";
import GoogleAnalyticsTopLandingPages from "screens/sites/components/GoogleAnalyticsTopLandingPages";
import GoogleAnalyticsVisitorsByDevices from "screens/sites/components/GoogleAnalyticsVisitorsByDevices";
import GoogleAnalyticsVisitorsByLocation from "screens/sites/components/GoogleAnalyticsVisitorsByLocation";
import GoogleAnalyticsVisitorsByMonth from "screens/sites/components/GoogleAnalyticsVisitorsByMonth";
import { RedisButton } from "common";
import ThirdPartyDisconnect from "screens/sites/components/ThirdPartyDisconnect";
import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";
import { connect } from "react-redux";
import PrimarySiteAnalytics from "../components/PrimarySiteReports/PrimarySiteAnalytics";
import { determineIfGA4 } from "../actions";

class Analytics extends Component {
  static propTypes = {
    siteId: oneOfType([string, number]),
    isPublic: bool,
    ga_property_id: string,
  };
  state = {
    clearCacheBool: false,
  };

  render() {
    const apiVersion = determineIfGA4({endDate: this.props.crmDateSelectorEndDate})

    let context = this;
    const isPrimarySite =
      this.props.primarySiteId &&
      this.props.siteId &&
      this.props.siteId === this.props.primarySiteId;

    if (isPrimarySite) {
      return (
        <PrimarySiteAnalytics
          toggleYOY={this.props.toggleYOY}
          clearCacheBool={this.state.clearCacheBool}
          displayYOY={this.props.displayYOY}
        />
      );
    }
    return (
      <div className="col-md-12">
        <ThirdPartyFallback id={this.props.ga_property_id} type="Analytics" />
        {!this.props.isPublic && (
          <Fragment>
            <GoogleAnalyticsConnect siteId={this.props.siteId} />
            <ThirdPartyDisconnect
              siteId={this.props.siteId}
              type="ga_property_id"
              displayName="Google Analytics"
            />
          </Fragment>
        )}

        <GoogleAnalyticsOrganicSiteMetrics
          clearCacheBool={this.state.clearCacheBool}
        />
        {!this.props.isPublic && this.props.ga_property_id && (
          <div className="col-12">
            <div className="yoy-toggle-bar row">
              <div className="col-12 col-md-8">
                <h3>
                  <i className="fa fa-info-circle"></i> Display YoY Data?
                </h3>
                <p>
                  Turn on YoY data to share with client using the public link
                  above. Clients cannot change this setting on their end.
                </p>
              </div>
              <div className="col-12 col-md-4 text-right">
                <button className="btn" onClick={() => this.props.toggleYOY()}>
                  {this.props.displayYOY ? "Hide YoY" : "Show YoY"}
                </button>
              </div>
            </div>
          </div>
        )}
        <GoogleAnalyticsVisitorsByMonth
          clearCacheBool={this.state.clearCacheBool}
          displayYOY={this.props.displayYOY}
        />
        <GoogleAnalyticsSessionsByMonth
          clearCacheBool={this.state.clearCacheBool}
          displayYOY={this.props.displayYOY}
        />
        <GoogleAnalyticsSocialSourcePageViews
          clearCacheBool={this.state.clearCacheBool}
        />
        <GoogleAnalyticsVisitorsByDevices
          clearCacheBool={this.state.clearCacheBool}
        />
        <GoogleAnalyticsVisitorsByLocation
          clearCacheBool={this.state.clearCacheBool} apiVersion={apiVersion}
        /> 
        <GoogleAnalyticsTopLandingPages
          clearCacheBool={this.state.clearCacheBool}
        />
        <GoogleAnalyticsPageViews clearCacheBool={this.state.clearCacheBool} />
        {!this.props.primarySiteId ||
        this.props.primarySiteId === this.props.siteId ? (
          <GoogleAnalyticsEvents clearCacheBool={this.state.clearCacheBool} />
        ) : null}
        <RedisButton isPublic={this.props.isPublic} context={context} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPublic: state.sites.show.permissions.isPublic,
    ga_property_id: state.sites.show.data.ga_property_id,
    siteId: state.sites.show.data.id,
    primarySiteId: state.sites.show.data.client.primary_site_id,
    newVisitorsByState: state.sites.show.analyticsData.newVisitorsByState,
    crmDateSelectorEndDate: state.sites.show.date.dateOneEndForCompare
  };
};

export default connect(mapStateToProps)(Analytics);
