import React, { Fragment } from "react";
import { array, string, func } from "prop-types";

SiteTabs.propTypes = {
  items: array,
  selectedTab: string,
  onSwitchTab: func,
};

function SiteTabs(props) {
  return (
    <div className="col-sm-12">
      <ul className="site-tabs">
        {props.items.map((item) => {
          return (
            <li
              key={Math.random()}
              className={props.selectedTab === item.name ? "active" : ""}
              onClick={props.onSwitchTab.bind(
                this,
                item.name.includes("GA4") ? "connect" : item.name
              )}
            >
              <img
                className={`icon-block`}
                key={item.name + "icon"}
                height="25px"
                src={`/img/${
                  item.name.includes("GA4") ? "analytics" : item.name
                }-icon.svg`}
                alt=""
              />
              {item.name.includes("paid") ? "Paid Ads" : item.name}
              {!item.connected && props.isPublic && (
                <i className="fa fa-exclamation-circle fa-2x connected-icon"></i>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SiteTabs;
