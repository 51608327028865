import React, { Component } from 'react';
import { Panel } from "common";
import { shadeColor } from "lib/utilities";

class DemoAdwordsMatrix extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const matrix = [
            {
                "day": "S",
                "hours": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0.8,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            },
            {
                "day": "M",
                "hours": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    1,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            },
            {
                "day": "T",
                "hours": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            },
            {
                "day": "W",
                "hours": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            },
            {
                "day": "T",
                "hours": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0.6,
                    0,
                    0.4,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            },
            {
                "day": "F",
                "hours": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            },
            {
                "day": "S",
                "hours": [
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            }
        ];
        let hours = [];
        while (hours.length < 24) {
            hours.push(<span key={hours.length}>{hours.length + 1}</span>)
        }
        return (
            <Panel
                containerClass="col-md-12 p-3 float-left"
                isLoading={isFetching}
                padding={2}>
                <Panel.Subtitle text="Conversions By Hour" />
                <Panel.Body
                    render={() => {
                        return (
                            <div className="adwords-matrix col-12">
                                <div className="matrix-heading">{hours}</div>
                                {
                                    matrix.map((el, i) => {
                                        return (
                                            <div key={i} className="matrix-item clearfix">
                                                <span className="matrix-label">{el.day}</span>
                                                {el.hours.map((el, i) => {
                                                    return <div key={i} className="matrix-block"><span className={(parseFloat(el, 10) === 0 ? "zero" : "")} style={{ background: shadeColor('#91bdf5', -(parseFloat(el, 10) * 2)) }}><i>{el} Conversions</i></span></div>
                                                })}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }}
                />
            </Panel>
        )
    }
}


export default DemoAdwordsMatrix;
