export const emailState = {
  klaviyoAccountReport: {},
  klaviyoCampaignsReport: [],
  mailchimpCampaignsReport: [],
  mailchimpAccountReport: {},

};

export const emailReducer = {
  FETCHING_KLAVIYO_ACCOUNT_SUCCESS: (state, payload) => {
    return (
      {
        ...state,
        show: {
          ...state.show,
          klaviyoAccountReport: {
            ...state.show.klaviyoAccountReport,
            ...payload
          }
        }
      })
  },
  FETCHING_KLAVIYO_CAMPAIGNS_SUCCESS: (state, payload) => {
    return (
      {
        ...state,
        show: {
          ...state.show,
          klaviyoCampaignsReport: [
            ...state.show.klaviyoCampaignsReport,
            ...payload
          ]
        }
      })
  },
  FETCHING_MAILCHIMP_ACCOUNT_SUCCESS: (state, payload) => {
    return (
      {
        ...state,
        show: {
          ...state.show,
          mailchimpAccountReport: {
            ...state.show.mailchimpAccountReport,
            ...payload
          }
        }
      })
  },
  FETCHING_MAILCHIMP_CAMPAIGNS_SUCCESS: (state, payload) => {
    return (
      {
        ...state,
        show: {
          ...state.show,
          mailchimpCampaignsReport: [
            ...state.show.mailchimpCampaignsReport,
            ...payload
          ]
        }
      })
  },
};
