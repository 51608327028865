import React, { Component } from "react";
import { ViewWithLoader } from "common";
import AgeGenderBreakdown from "screens/social/components/AgeGenderBreakdown";
import { SocialGraphViewWithLoader } from "../SocialGraphViewWithLoader";

class InstagramAudienceDemographics extends Component {
  state = {
    ageDataFinal: {
      female: 0,
      male: 0,
    },
    percentLikesGenderAge: null,
    femaleLikesPercent: 0,
    maleLikesPercent: 0,
    displayString: null,
    isFetching: true,
  };
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && (this.props.data || []).length) {
      let page_fans_gender_age = {};
      this.props.data.forEach((item, index) => {
        if (item.name === "audience_gender_age") {
          page_fans_gender_age = item;
        }
      });
      const ageData = page_fans_gender_age.values[0].value;
      const ageDataObj = {
        female: 0,
        male: 0,
      };

      for (let key in ageData) {
        if (key[0] === "F") {
          ageDataObj.female += ageData[key];
        }
        if (key[0] === "M") {
          ageDataObj.male += ageData[key];
        }
      }
      const tempArr = [];
      let entries = Object.entries(ageData);
      let sorted = entries.sort((a, b) => b[1] - a[1]);
      sorted.forEach((array) => {
        var obj = {};
        obj["category"] = array[0];
        obj["value"] = array[1];
        tempArr.push(obj);
      });
      let totalLikes = 0;
      tempArr.forEach((data) => {
        totalLikes += data.value;
      });
      const percentLikesGenderAge = tempArr.reduce((accum, item) => {
        var tempObj = item;
        tempObj.value = Math.round((item.value / totalLikes) * 100);
        tempObj.amt = 100;
        accum.push(tempObj);
        return accum;
      }, []);
      const ageRange = sorted[0][0];
      const ageRangeSlice = ageRange.slice(2);
      const genderFlag = sorted[0][0][0];
      let gender;
      if (genderFlag === "F") {
        gender = "Women";
      } else if (genderFlag === "M") {
        gender = "Men";
      }
      let stringToDisplay;
      if (ageRange.length > 6) {
        let ageRangeFormatted = ageRangeSlice.replace("-", " - ");
        stringToDisplay = `Facebook Core Demographic: ${gender} ages ${ageRangeFormatted}`;
      } else {
        stringToDisplay = `Facebook Core Demographic: ${gender} aged ${ageRangeSlice}`;
      }

      const femaleLikesP = Math.round((ageDataObj.female / totalLikes) * 100);
      const maleLikesP = Math.round((ageDataObj.male / totalLikes) * 100);
      this.setState(function(state, props) {
        return {
          percentLikesGenderAge: percentLikesGenderAge,
          demographics: tempArr,
          displayString: stringToDisplay,
          ageDataFinal: ageDataObj,
          femaleLikesPercent: femaleLikesP,
          maleLikesPercent: maleLikesP,
          isFetching: false,
        };
      });
    }
  }

  render() {
    return (
      <SocialGraphViewWithLoader
        classes="mb-3 col-6"
        isLoading={this.state.isFetching}
      >
        <AgeGenderBreakdown data={this.state.demographics} />
      </SocialGraphViewWithLoader>
    );
  }
}

export { InstagramAudienceDemographics };
