export const templates = ({ dispatch }) => next => action => {
  if (!action.fromTemplates) {
    return next(action);
  }

  const formattedData = action.payload.slice(0).reduce((obj, item) => {
    if (!obj[item.team.name]) {
      obj[item.team.name] = [
        { id: item.id, data: item.data, title: item.title }
      ];
    } else {
      obj[item.team.name] = [
        ...obj[item.team.name],
        {
          id: item.id,
          data: item.data,
          title: item.title
        }
      ];
    }
    return obj;
  }, {});

  const newAction = {
    ...action,
    payload: formattedData,
    fromTemplates: false
  };

  dispatch(newAction);
};
