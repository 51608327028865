import React, { Component } from "react";
import { Panel, Table } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";

class DemoGoogleAnalyticsTopLandingPages extends Component {
  render() {
    // Fixtures 
    const topLandingPages = [
      {
        "page": "/",
        "thisMonth": "228",
        "lastMonth": "305",
        "change": "-77",
        "percentChange": "-25%"
      },
      {
        "page": "/studio3/business/",
        "thisMonth": "56",
        "lastMonth": "120",
        "change": "-64",
        "percentChange": "-53%"
      },
      {
        "page": "/studio3/business/",
        "thisMonth": "46",
        "lastMonth": "58",
        "change": "-12",
        "percentChange": "-21%"
      },
      {
        "page": "/gallery/04/",
        "thisMonth": "42",
        "lastMonth": "37",
        "change": "5",
        "percentChange": "14%"
      },
      {
        "page": "/contact-us/",
        "thisMonth": "26",
        "lastMonth": "18",
        "change": "8",
        "percentChange": "44%"
      },
      {
        "page": "/studio3/business/",
        "thisMonth": "24",
        "lastMonth": "22",
        "change": "2",
        "percentChange": "9%"
      },
      {
        "page": "/studio3/business/",
        "thisMonth": "24",
        "lastMonth": "22",
        "change": "2",
        "percentChange": "9%"
      },
      {
        "page": "/treatments/laser/",
        "thisMonth": "24",
        "lastMonth": "28",
        "change": "-4",
        "percentChange": "-14%"
      },
      {
        "page": "/meet-the-doctor/",
        "thisMonth": "23",
        "lastMonth": "25",
        "change": "-2",
        "percentChange": "-8%"
      },
      {
        "page": "/studio3/business/",
        "thisMonth": "19",
        "lastMonth": "20",
        "change": "-1",
        "percentChange": "-5%"
      }
    ]
    const isFetching = false;
    const topLanding = topLandingPages.map(page => ({
      page: { value: page.page },
      "users this month": { value: page.thisMonth },
      "users last month": { value: page.lastMonth },
      change: { value: page.change },
      "% change": { value: page.percentChange }
    }));

    return (
      <div className="row">
        <ReportSubTitle text="Top Organic Landing Pages" classes="mt-3" logo={logo} />
        <Panel
          containerClass="col-md-12 p-3 float-left"
          isLoading={isFetching}>
          <Panel.Body
            style={{
              display: "block",
              paddingBottom: "1em"
            }}
            render={() => (
              <Table
                headers={[
                  "Page",
                  "This Month",
                  "Last Month",
                  "Change",
                  "% Change"
                ]}
                data={topLanding}
              />
            )}
          />
        </Panel>
      </div>
    );
  }
}

export default DemoGoogleAnalyticsTopLandingPages
