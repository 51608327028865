import {toCurrency} from "lib/currencyHelpers";

export const shopifySales = ({ dispatch }) => next => action => {
  if (!action.fromShopifySales) return next(action);

  const formattedData = formatData(action.payload)

  const newAction = {
    ...action,
    payload: formattedData,
    fromShopifySales: false
  }

  dispatch(newAction);
}

const formatData = data => {
  if (!data) return;

  return {
    totalSales: { title: "Total Sales", value: toCurrency(data.totalSales) },
    totalOrders: { title: "Total Orders", value: data.totalOrders },
    averageOrderValue: {
      title: "Average Order Value",
      value: toCurrency(data.avgOrderValue)
    },
    repeatCustomerRate: {
      title: "Repeat Customer",
      value: data.repeatCustomerRate
    },
    clv: { title: "CLV", value: toCurrency(data.avgClv) },
  };

}
