import _ from "lodash";
import React, { Component } from "react";
import { array, bool, string } from "prop-types";
import { Panel } from "common";

class TableView extends Component {
  static propTypes = {
    title: string,
    isLoading: bool,
    errors: string,
    data: array,
    colA: string,
    colB: string,
    colC: string
  };

  render() {
    const isLoading = this.props.isLoading || _.isEmpty(this.props.data);

    return (
      <Panel
        containerClass="col-md-12 p-3"
        isLoading={isLoading}
        errors={this.props.errors}
        padding={1}>
        <Panel.Subtitle text={this.props.title} />
        <Panel.Body
          render={() => {
            return (
              <table className="table table-hover p-5">
                <thead>
                  <tr>
                    <th>{this.props.colA}</th>
                    <th>{this.props.colB}</th>
                    <th>{this.props.colC}</th>
                    <th>Change</th>
                    <th>% Change</th>
                  </tr>
                </thead>

                <tbody>
                  {_.map(this.props.data, (item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.page}</td>
                        <td>{item.thisMonth}</td>
                        <td>{item.lastMonth}</td>
                        <td>{item.change}</td>
                        <td>{item.percentChange}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          }}
        />
      </Panel>
    );
  }
}

export { TableView };
