import _ from "lodash";
import React, { Component, Fragment } from "react";

import Modal from "react-modal";
import moment from "moment";

class CallRailModal extends Component {
  render() {
    if (!this.props.data) {
      return null;
    }
    return (
      <Modal
        appElement={document.getElementById("root")}
        isOpen={this.props.isOpen}
        onRequestClose={() => {
          this.props.onRequestClose();
        }}
        contentLabel="Modal"
        className="react-modal lead-modal"
        overlayClassName="react-modal-overlay"
      >
        <Fragment>
          <h3 className="lead-title clearfix p-3">Phone Call</h3>
          {this.props.data.map((n, i) => (
            <div key={i} className="lead-field">
              <span>{n.friendly}</span>
              {n.friendly === "Date"
                ? moment(n.value).format("MMMM Do h:mm:ss a")
                : n.value}
            </div>
          ))}
        </Fragment>
      </Modal>
    );
  }
}

export default CallRailModal;
