import _ from "lodash";

let facebook_ecom = {
    names: ['Impressions', 'Revenue', 'Spend', 'CTR', 'Visitors', 'Sales', 'Roas',],
    keys: ['impressions', 'ad_value', 'spend', 'ctr', 'visitors', 'sales', 'roas'],
}

let facebook_boost = {
    names: ['Impressions', 'Conversions', 'Spend', 'CTR', 'Visitors', 'Brand Awarness'],
    keys: ['impressions', 'ad_value', 'spend', 'ctr', 'visitors', 'ad_value'],
}

let facebook_leads = {
    names: ['Impressions', 'Conversions', 'Spend', 'CTR', 'Visitors', 'Form'],
    keys: ['impressions', 'ad_value', 'spend', 'ctr', 'visitors', 'ad_value'],
}


let google_ecom = {
    names: ['Impressions', 'Purchases', 'Cost Conv', 'CTR', 'Sales', 'ROAS', 'Clicks'],
    keys: ['Impressions', 'Conversions', 'Cost_conv', 'CTR', 'conversions', 'roas', 'Clicks'],
}
let google_leads = {
    names: ['Impressions', 'Conversions', 'Cost Conv', 'CTR', 'Calls', 'Forms ', 'Clicks'],
    keys: ['Impressions', 'Conversions', 'Cost_conv', 'CTR', 'Phone_calls', 'ad_value', 'Clicks'],
}
let google_display = {
    names: ['Impressions', 'Conversions', 'Cost Conv', 'CTR', 'Clicks'],
    keys: ['Impressions', 'Conversions', 'Cost_conv', 'CTR', 'Clicks'],
}
let google_video = {
    names: ['Impressions', 'Conversions', 'Cost Conv', 'CTR', 'Views', 'Clicks'],
    keys: ['Impressions', 'Conversions', 'Cost_conv', 'CTR', 'VideoViews', 'Clicks'],
}

export const chooseStatsBasedOnCampaign = (campaign, type, isEcomm = false) => {
    let stats_selections;
    if (type === "Google") {
        campaign.ad_label === 'Sales' && (stats_selections = google_ecom);
        campaign.ad_label === 'Leads' && (stats_selections = google_leads);
        campaign.ad_label === 'Reach' && (stats_selections = google_display);
        campaign.ad_label === 'Video' && (stats_selections = google_video);
        if(isEcomm){
            stats_selections = google_ecom;
        }
    }
    if (type === "Facebook") {
        campaign.ad_type === 'ecommerce' && (stats_selections = facebook_ecom);
        campaign.ad_type === 'boost' && (stats_selections = facebook_boost);
        campaign.ad_type === 'leadgen' && (stats_selections = facebook_leads);
    }
    return stats_selections;
}

export const addFieldForAPI = (stat, type) => {
    let field;
    if (type === "Google") {
        stat === "Impressions" && (field = "Impressions");
        stat === "Conversions" && (field = "Conversions");
        stat === "Cost Conv" && (field = "CostPerConversion");
        stat === "Clicks" && (field = "Clicks");
        stat === "ROAS" && (field = 'roas');
        stat === "CTR" && (field = "Ctr");
        stat === "Calls" && (field = "NumOfflineInteractions");
        stat === "Video" && (field = "VideoViews");
        stat === "Sales" && (field = 'sales');
        return field
    } else {
        stat === "Impressions" && (field = "impressions");
        stat === "Conversions" && (field = "conversions");
        stat === "CPC" && (field = "cost_per_conversion");
        stat === "ROAS" && (field = "website_purchase_roas");
        stat === "CTR" && (field = "ctr");
        stat === "Clicks" && (field = "outbound_clicks");
        stat === "Spend" && (field = "spend");
        stat === "Actions" && (field = "actions");
        return field;
    }
}

export const fetchDailyStatsByField = (params, campaign, type, fetchDailyStats, isSecondDate, callBack = () => { }, clearCacheBool) => {
    let stats = chooseStatsBasedOnCampaign(campaign, type);
    stats.names.forEach((stat, i) => {
        params.field = addFieldForAPI(stat, type);
        params.key = stats.keys[i];
        fetchDailyStats(params, callBack, clearCacheBool, type, isSecondDate, clearCacheBool)
    })
}

export const sortData = (data, type) => {
    let datesInChronologicalOrder = data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        if (type === "Google") {
            return new Date(a.Day) - new Date(b.Day);
        } else {
            return new Date(a.date_start) - new Date(b.date_start);
        }
    });
    return datesInChronologicalOrder;
}
