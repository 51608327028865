import React from "react";
import { array, bool, func, number } from "prop-types";
import CommentsItem from "screens/todos/components/CommentsItem";

CommentsList.propTypes = {
  data: array.isRequired,
  handleDeleteComment: func,
  todoId: number,
  isOwner: bool
};

function CommentsList(props) {
  return (
    <section className="todo-comments-list">
      {props.data.map(comment => {
        return (
          <CommentsItem
            key={comment.id}
            comment={comment}
            isOwner={props.isOwner}
            handleDelete={props.handleDeleteComment.bind(null, {
              todoId: props.todoId,
              commentId: comment.id
            })}
          />
        );
      })}
    </section>
  );
}

export default CommentsList;
