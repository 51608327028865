import axios from "axios";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import { rootUrl } from "lib/api";
import moment from "moment";

import {
  FETCHING_SHOPIFY_SALES,
  UPDATE_AMAZON_INTEGRATION,
  FETCH_AMAZON_REPORTS
} from "./types";
import { authLogoutAndRedirect } from "screens/users/actions";

export const fetchShopifySales = (
  params,
  callback = () => {}
) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_SHOPIFY_SALES);

  const token = getToken();
  const headers = requestHeaders(token);
  const qs = `startDate=${params.startDate}&endDate=${params.endDate}`;
  const sales = `${rootUrl}/public/shopify/sales/${params.siteId}?${qs}`;
  const clv = `${rootUrl}/public/shopify/clv/${params.siteId}?${qs}`;

  try {
    const [{ data: dataSet1 }, { data: dataSet2 }] = await Promise.all([
      axios.get(sales, headers),
      axios.get(clv, headers)
    ]);

    const response = {
      data: { ...dataSet1, ...dataSet2 },
      status: 200,
      middleware: { fromShopifySales: true }
    };

    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const updateAmazonIntegration = (
  params,
  callback = () => {}
) => async dispatch => {
  const dispatching = dispatchHelper(UPDATE_AMAZON_INTEGRATION, dispatch);

  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/sites/${params.siteId}/amazon`;

  const response = await axios.post(
    url,
    {
      merchant_id: params.merchant_id,
      marketplace_id: params.marketplace_id,
      auth_token: params.auth_token
    },
    headers
  );
  // response.middleware = { fromExampleMiddleware: true }

  dispatching(response, callback);
};

// GET {api_version}/amazon/reports/{site_id}
export const fetchAmazonReports = (
  params,
  callback = () => {}
) => async dispatch => {
  const dispatching = dispatchHelper(FETCH_AMAZON_REPORTS, dispatch);
  const startDate = moment(params.startDate, "YYYY-MM-DD").format("MM-DD-YYYY")

  const headers = {};
  const url = `${rootUrl}/public/amazon/reports/${params.siteId}?startDate=${startDate}`;

  const response = await axios.get(url, headers);
  response.middleware = { fromAmazonReports: true };

  dispatching(response, callback);
};
