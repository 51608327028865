import axios from "axios";
import { getToken, requestHeaders } from "lib/authHelpers";
import { rootUrl } from "lib/api";
import { toCurrency } from "lib/currencyHelpers";

export const fetchFacebookAccounts = async (callback = () => {}) => {
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/fb-ads/accounts`;
  const { data: fbAccounts } = await axios.get(url, headers);

  callback(fbAccounts);
};

export const formatFacebookAccounts = account => ({
  value: account.name,
  label: account.name,
  id: account.account_id
});

export const formatFbOverviewData = (
  data,
  isWeekly = false,
  ad_type = null,
  isOfTypeEcommerce = false
) => {
  let facebookData = data;
  if (isWeekly === true) {
    facebookData = data.filter(data => data.ad_type === ad_type);
  }

  const formattedData = facebookData.reduce(
    (acc, next) => {
      if (isWeekly === true) {
        // UNCOMMENT TO ADD CONVERSIONS TO STATS BAR
          acc.conversions.value =
            Number(acc.conversions.value) + Number(next.ad_value);
        } else {
          if (next.conversions.value !== "-") {
            acc.conversions.value =
              Number(acc.conversions.value) + Number(next.conversions.value);
          }
      }
      if (next.cpc.value !== "-") {
        if (isOfTypeEcommerce === true) {
          acc.cpc.totalIterations++;
          acc.cpc.value += Number(next.cpc.value);
        }
        // acc.cpc.value = ((Number(acc.cpc.value) + Number(next.cpc.value)) /
        // acc.cpc.totalIterations).toFixed(2);
      }

      //used to calculate CPC
      if (next.clicks.value !== "-") {
        if (isOfTypeEcommerce === true) {
          acc.clicks.totalIterations++;
          acc.clicks.value += Number(next.clicks.value);
        }
      }

      if (next.ctr.value !== "-") {
        if (isOfTypeEcommerce === true) {
          if (next.roas.value !== "-") {
            acc.ctr.totalIterations++;
            acc.ctr.value += Number(next.ctr.value);
          }
        } else {
          acc.ctr.totalIterations++;
          acc.ctr.value = (
            (Number(acc.ctr.value) + Number(next.ctr.value)) /
            acc.ctr.totalIterations
          ).toFixed(2);
        }
      }

      if (next.roas.value !== "-") {
        acc.roas.totalIterations++;
        acc.roas.value =
          (Number(acc.roas.value) + Number(next.roas.value)) /
          acc.roas.totalIterations;
        let salesValue = 0;
        if (next.conversions.meta.length > 0) {
          next.conversions.meta.forEach(element => {
            salesValue = Number(element.value);
            if (element.type.includes("fb_pixel_purchase")) {
              acc.sales.value += salesValue;
            }
          });
        }
        acc.revenue.totalIterations++;
        acc.revenue.value += Number(next.ad_value);
      }

      if (next.spend.value !== "-") {
        acc.spend.value = Number(acc.spend.value) + Number(next.spend.value);
      }

      if (next.visitors.value !== "-") {
        acc.visitors.value = (
          Number(acc.visitors.value) + Number(next.visitors.value)
        ).toFixed();
      }
      return acc;
    },
    {
      // UNCOMMENT TO ADD CONVERSIONS TO STATS BAR
      conversions: {
        title: "Conversions",
        value: 0
      },
      sales: {
        title: "Sales",
        value: 0
      },
      revenue: {
        title: "Revenue",
        value: 0,
        totalIterations: 0
      },
      cpc: {
        title: "CPC",
        value: 0,
        totalIterations: 0
      },
      clicks: {
        title: "Clicks",
        value: 0,
        totalIterations: 0
      },
      ctr: {
        title: "CTR",
        value: 0,
        totalIterations: 0
      },
      roas: {
        title: "ROAS",
        value: 0,
        totalIterations: 0
      },
      spend: {
        title: "Spend",
        value: 0
      },
      visitors: {
        title: "Visitors",
        value: 0
      }
    }
  );

  formattedData.roas.value = (formattedData.revenue.value / formattedData.spend.value).toFixed(2);
  formattedData.revenue.value = "$" + formattedData.revenue.value.toLocaleString();
  //manually calculate CPC for better accuracy
  formattedData.cpc.value = toCurrency(formattedData.spend.value / formattedData.clicks.value);
  formattedData.ctr.value = formattedData.ctr.value / formattedData.ctr.totalIterations;
  formattedData.ctr.value = formattedData.ctr.value.toFixed(2) + "%";
  formattedData.spend.value = toCurrency(formattedData.spend.value);

  //cleanup
  delete formattedData.clicks;

  return formattedData;
};
