import { createReducer } from "lib/utilities";
import {
  analyticsState,
  analyticsReducer
} from "screens/sites/reducer/analytics_reducer";
import {
  rankingsState,
  rankingsReducer
} from "screens/sites/reducer/rankings_reducer";
import { leadsState, leadsReducer } from "screens/sites/reducer/leads_reducer";
import {
  paidAdsState,
  paidAdsReducer
} from "screens/sites/reducer/paid_ads_reducer";
import {
  ecommerceState,
  ecommerceReducer
} from "screens/sites/reducer/ecommerce_reducer";

import {
  emailReducer,
  emailState
} from "./email_reducer";

import {
  reviewsState,
  reviewsReducer
} from "screens/sites/reducer/reviews_reducer";
import { dateState, dateReducer } from "screens/sites/reducer/date_reducer";

const initialState = {
  index: {
    data: []
  },
  show: {
    data: {
      id: null,
      amazon_integration: null,
      ga_property_id: null,
      se_site_id: null,
      adwords_id: null,
      shopify_url: "",
      shopify_access_token: null,
      fb_ads_id: null,
      client: {
        profile_image: "",
        name: ""
      }
    },
    permissions: { isPublic: false },
    ...dateState,
    ...analyticsState,
    ...rankingsState,
    ...leadsState,
    ...paidAdsState,
    ...ecommerceState,
    ...reviewsState,
    ...emailState
  }
};

export default createReducer(initialState, {
  ...analyticsReducer,
  ...rankingsReducer,
  ...leadsReducer,
  ...paidAdsReducer,
  ...ecommerceReducer,
  ...dateReducer,
  ...emailReducer,
  ...reviewsReducer,
  
  UPDATING_ADWORDS_ID_WITH_MCC_SUB_ACCOUNT_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data:{
        ...state.show.data,
        adwords_id: payload
      }
    }
  }),
  SET_PERMISSION: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      permissions: { isPublic: payload }
    }
  }),
  FETCHING_SITES_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: payload
    }
  }),
  FETCHING_SITE_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: payload
    }
  }),
  UPDATING_SITE_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        ...payload
      }
    }
  }),
  UPDATE_AMAZON_INTEGRATION_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        amazon_integration: payload
      }
    }
  }),
  SAVE_TWITTER_HANDLE_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        twitter_username: payload
      }
    }
  }),
  FETCHING_SOCIAL_INSTAGRAM_BUSINESS_ID_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        instagram_business_id: payload,
      }
    }
  }),
});
