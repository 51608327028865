import React from "react";
import { string } from "prop-types";
import { Button, ProfileCircle } from "common";
import iconCheckmark from "images/check.png";

NameBadge.propTypes = {
  linkTo: string.isRequired,
  name: string.isRequired,
  subtitle: string.isRequired,
};

function NameBadge(props) {
  let { user } = props;
  return (
    <div className="col-sm-12 col-md-4">
      <Button classes="name-badge-container" link to={props.linkTo}>
        <h2 className="name">{props.name}</h2>
        <h3 className="subtitle">{props.subtitle}</h3>
        {user ? (
          <div className="manager-profile">
            <ProfileCircle
              profileImage={user.profile_image}
              firstName={user.first_name}
              lastName={user.last_name}
            />
            <span className="profile-image-name">
              {user.first_name + " " + user.last_name}
            </span>
          </div>
        ) : null}
      </Button>
      {props.showGA4Connect ? (
        <img
          style={{ position: "absolute", right: 22, top: 10 }}
          src={iconCheckmark}
          alt="Google analytics connected"
        />
      ) : null}
    </div>
  );
}

export { NameBadge };
