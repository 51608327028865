import React, { Component } from "react";
import moment from "moment";
class DemoSiteOverview extends Component {
    render() {
        // Fixtures 
        const launch_date = moment().format("MM/YY");
        const date = { month: moment().format("MM"), year: moment().format("YYYY") };
        const sites = [
            {
                adwords_id: "4988153121",
                amazon_integration: null,
                client_id: 79,
                dev_url: "",
                fb_ads_id: null,
                ga_access_token: "fakeToken",
                ga_account_id: "8024350",
                ga_property_id: "UA-8024350-1",
                health: "healthy",
                id: 176,
                launch_date: null,
                live_url: "https://www.studio3marketing.com/",
                name: "Marcus Med Spa",
                public_hash: "7f2f90292a6cbd45de8d5beb63871f47-987da866c05d8ea29985bf8dc155cf08",
                se_site_id: "83849",
                shopify_access_token: null,
                shopify_url: null,
                wc_secret: "dc1fc2f22d65d9cf0b9f9ba44a953f15",
                wc_token: "60653-a5d9618b032f06d4",
            },
            {
                adwords_id: "4988153121",
                amazon_integration: null,
                client_id: 79,
                dev_url: "",
                fb_ads_id: null,
                ga_access_token: "fakeToken",
                ga_account_id: "8024350",
                ga_property_id: "UA-8024350-1",
                health: "healthy",
                id: 176,
                launch_date: null,
                live_url: "https://www.studio3marketing.com/",
                name: "Marcus Med Spa",
                public_hash: "7f2f90292a6cbd45de8d5beb63871f47-987da866c05d8ea29985bf8dc155cf08",
                se_site_id: "83849",
                shopify_access_token: null,
                shopify_url: null,
                wc_secret: "dc1fc2f22d65d9cf0b9f9ba44a953f15",
                wc_token: "60653-a5d9618b032f06d4",
            },
        ];
        const clientName = 'Studio 3 Marketing';
        const websiteName = 'Studio 3';
        const { month, year } = date;
        const inqueries = 213;
        return (
            <div className="col-xl-12 pb-5">
                <div className="site-meta">
                    <div className="row pt-5">
                        <div className="col-md-3 site-stat site-client">
                            <img src={require('./DemoImages/studio3Logo.png')} alt="" />
                            <h3>
                                <span>Client</span>
                                {clientName}
                            </h3>
                        </div>

                        <div
                            className={`col-md-3 site-stat ${
                                sites.length > 1 ? "site-name" : ""
                                }`}>
                            <h3>
                                <span>Website</span>
                                {websiteName}
                                {sites.length > 1 && <i className="fas fa-chevron-down" />}
                            </h3>
                        </div>

                        <div className="col-md-2 site-stat">
                            {true && (
                                <h3>
                                    <span>{year}</span>
                                    {moment(Number(month) + 1, "MM").format("MMMM")}
                                </h3>
                            )}
                        </div>
                        <div className="col-md-2 site-stat site-leads">
                            <h3>
                                <span>Inquiries</span>
                                {inqueries}
                            </h3>
                        </div>
                        <div className="col-md-2 site-stat site-leads">
                            <h3>
                                <span>Launched</span>
                                {launch_date}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default DemoSiteOverview;
