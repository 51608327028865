import React from "react";
import { Button } from "common";
import SiteDatePicker from "screens/sites/components/SiteDatePicker";

function PrintControls() {
  return (
    <div className="row print-datepicker">
      <SiteDatePicker />

      <div className="d-flex align-items-center justify-content-end w-50 pr-3">
        <Button orangeDark onClick={() => window.print()}>
          Print PDF
        </Button>
      </div>
    </div>
  );
}

export default PrintControls;
