import moment from "moment";

export const getStartDate = ({ month, year }) =>
  moment(`${Number(month) + 1}-${year}`, "M-YYYY")
    .startOf("month")
    .format("YYYY-MM-DD");


export const getEndDate = ({ month, year }) => 
  moment(`${Number(month) + 1}-${year}`, "M-YYYY")
    .endOf("month")
    .format("YYYY-MM-DD");

