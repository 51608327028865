import { EditorState, convertFromRaw } from "draft-js";
import React, { Component } from "react";
import { Toggle, ViewWithLoader } from "common";
import {
  endDateMMYYYY,
  endOfMonth,
  getPreviousMonthEnd,
  getPreviousMonthStart,
  startDateMMYYYY,
  startOfMonth,
} from "lib/dateHelpers";
import { fetchSite, setPermission } from "screens/sites/actions";
import { func, object } from "prop-types";
import { getQueryVar, hasQueryVar } from "lib/utilities";

import Analytics from "screens/sites/containers/Analytics";
import ECommerce from "screens/sites/containers/ECommerce";
import Email from "screens/sites/containers/Email";
import Leads from "screens/sites/containers/Leads";
import NoteForm from "screens/clients/components/NoteForm";
import PaidAds from "screens/sites/containers/PaidAds";
import Rankings from "screens/sites/containers/Rankings";
import ReportSelection from "../scenes/ReportSelection";
import Reviews from "screens/sites/containers/Reviews";
import SiteDatePicker from "screens/sites/components/SiteDatePicker";
import SiteLinks from "screens/sites/components/SiteLinks";
import SiteOverview from "screens/sites/components/SiteOverview";
import SiteTabs from "screens/sites/components/SiteTabs";
import { SocialIndex } from "../../social/SocialIndex";
import _ from "lodash";
import { connect } from "react-redux";
import { createNote } from "screens/notes/actions";
import { getHashFromUrl } from "screens/sites/helpers/endpoint_helpers";
import moment from "moment";
import GoogleAnalyticsConnectV4 from "../components/GoogleAnalyticsConnectV4";
let connectedMap = {
  analytics: {
    DB_key: ["ga_property_id"],
    connected: false,
  },
  rankings: {
    DB_key: ["se_site_id"],
    connected: false,
  },
  inquiries: {
    DB_key: ["wc_token"],
    connected: false,
  },
  "paid-ads": {
    DB_key: ["adwords_id", "fb_ads_id"],
    connected: false,
  },
  social: {
    DB_key: ["fb_page_id", "instagram_business_id"],
    connected: false,
  },
  reviews: {
    DB_key: ["yelp_business_id", "google_places_id", "fb_page_id"],
    connected: false,
  },
  "e-commerce": {
    DB_key: ["shopify_access_token", { amazon_integration: "auth_token" }],
    connected: false,
  },
  email: {
    DB_key: ["klaviyo_api_key", "mailchimp_api_key"],
    connected: false,
  },
};
class SitesShow extends Component {
  static propTypes = {
    fetchSite: func,
    setPermission: func,
    match: object,
    location: object,
  };

  state = {
    displayTab:
      window.location.hash !== "" ? window.location.hash.split("#")[1] : "none",
    startDate: getPreviousMonthStart(startOfMonth),
    endDate: getPreviousMonthEnd(endOfMonth),
    isFetching: true,
    displayYOY: getQueryVar("dy") === "true",
    compare: hasQueryVar("cp") ? getQueryVar("cp") : 6,
    fetchAllSites: undefined,
    displayForm: false,
  };

  toggleYOY = () => {
    this.setState((prevState) => ({
      displayYOY: !prevState.displayYOY,
    }));
  };

  changeComparison = (amt) => {
    this.setState((prevState) => ({
      compare: amt,
    }));
  };

  componentDidMount() {
    const hash = getHashFromUrl(window.location.pathname);
    const isPublic = !Number(hash);
    if (isPublic) {
      this.props.fetchSite({ hash, isPublic }, () =>
        this.setState({ isFetching: false })
      );
    } else {
      this.props.fetchSite({ siteId: this.props.match.params.id }, () =>
        this.setState({ isFetching: false })
      );
    }
    this.props.setPermission({ isPublic });
  }

  componentDidUpdate() {
    let primarySiteId = this.props.show.data.client.primary_site_id;
    let siteId = this.props.show.data.id;
    if (primarySiteId === siteId && this.state.fetchAllSites === undefined) {
      this.setState({ fetchAllSites: true });
    }
  }

  handleCreateNote = (data) => {
    let client = this.props.show.data.client.name;
    let { first_name, last_name, email } = this.props.show.data.client.manager;
    data.created_at = moment().format("YYYY-MM-DD hh:mm:ss");
    data.client_id = this.props.show.data.client.id;
    data.client_name = client;
    data.manager_first_name = first_name;
    data.manager_last_name = last_name;
    data.manager_email = email;
    let raw = convertFromRaw(JSON.parse(data.rawContent));
    let editorState = EditorState.createWithContent(raw);
    let text = editorState.getCurrentContent().getPlainText("\u0001");
    data.text_content = text;
    this.props.createNote(data, () => {
      this.setState({ displayForm: false });
    });
  };

  onToggleDisplayForm = () => {
    this.setState((nextState) => {
      return { displayForm: !nextState.displayForm, editing: false };
    });
  };

  componentWillUpdate(nextProps) {
    if (!this.props.location) return;
    const hasSameHash = this.props.location.hash === nextProps.location.hash;

    if (!hasSameHash) {
      this.setState({ displayTab: nextProps.location.hash.slice(1) });
    }
  }

  handleDateChange = (month, year) => {
    this.setState({
      startDate: startDateMMYYYY(month, year),
      endDate: endDateMMYYYY(month, year),
    });
  };

  handleFetchAllSitesChange = (e, data) => {
    this.setState({ fetchAllSites: !this.state.fetchAllSites });
  };

  handleTabSwitch = (tab) => {
    window.location.hash = "#" + tab;
    this.setState({ displayTab: tab });
  };

  renderTabContent = () => {
    let primarySiteId = this.props.show.data.client.primary_site_id;
    let siteId = this.props.show.data.id;
    const isPrimarySite = primarySiteId === siteId;

    switch (this.state.displayTab) {
      case "e-commerce":
        return <ECommerce />;
      case "reviews":
        return <Reviews />;
      case "social":
        return <SocialIndex />;
      case "paid-ads":
        return <PaidAds {...this.props} />;
      case "inquiries":
        return (
          <Leads
            isPublic={this.props.show.permissions.isPublic}
            changeComparison={this.changeComparison}
            compare={this.state.compare}
            isPrimarySite={isPrimarySite}
            siteId={siteId}
            fetchAllSites={this.state.fetchAllSites}
            onFetchAllSitesChange={this.handleFetchAllSitesChange}
          />
        );
      case "rankings":
        return <Rankings />;
      case "analytics":
        return (
          <Analytics
            displayYOY={this.state.displayYOY}
            toggleYOY={this.toggleYOY}
            primarySiteId={primarySiteId}
          />
        );
      case "email":
        return <Email />;
      case "connect":
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <GoogleAnalyticsConnectV4 />
          </div>
        );
      default:
        return <ReportSelection />;
    }
  };

  trimString = (str) => {
    return str.trim();
  };

  checkIfConnected = (propType) => {
    let value = this.props.show.data[propType];
    if (value !== undefined && value !== null) {
      if (_.isString(value)) {
        let trimmed = this.trimString(value);
        if (trimmed.length !== 0) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  };

  checkIfConnectedMultiple = (propTypeArr, type) => {
    propTypeArr.forEach((item) => {
      let value = this.props.show.data[item];
      if (connectedMap[type].connected === true) {
        return;
      }
      if (value !== undefined && value !== null) {
        if (_.isString(value)) {
          let trimmed = this.trimString(value);
          if (trimmed.length !== 0) {
            connectedMap[type].connected = true;
            return true;
          }
          return false;
        }
        connectedMap[type].connected = true;
        return true;
      }
      return false;
    });
  };

  checkEcommConnected = () => {
    let shopify = this.props.show.data.shopify_access_token;
    let amazon = this.props.show.data.amazon_integration;
    if (connectedMap["e-commerce"].connected === true) {
      return;
    }
    // Check shopify
    if (shopify !== null && shopify !== undefined) {
      if (_.isString(shopify)) {
        let trimmed = this.trimString(shopify);
        if (trimmed.length !== 0) {
          connectedMap["e-commerce"].connected = true;
        }
      }
    }
    // Check amazon
    if (amazon) {
      if (amazon.merchant_id !== null && amazon.merchant_id !== undefined) {
        if (_.isString(amazon)) {
          let trimmed = this.trimString(amazon);
          if (trimmed.length !== 0) {
            connectedMap["e-commerce"].connected = true;
          }
        }
        return true;
      }
    }
  };

  render() {
    let isPublic = this.props.show.permissions.isPublic;
    this.checkIfConnectedMultiple(["adwords_id", "fb_ads_id"], "paid-ads");
    let paidAdsConnected = connectedMap["paid-ads"].connected;
    this.checkIfConnectedMultiple(
      ["fb_page_id", "instagram_business_id"],
      "social"
    );
    let socialConnected = connectedMap["social"].connected;
    this.checkIfConnectedMultiple(
      ["yelp_business_id", "google_places_id", "fb_page_id"],
      "reviews"
    );
    let reviewsConnected = connectedMap["reviews"].connected;
    this.checkIfConnectedMultiple(
      ["klaviyo_api_key", "mailchimp_api_key"],
      "email"
    );
    let emailConnected = connectedMap["email"].connected;
    this.checkEcommConnected();
    let ECommerceConnected = connectedMap["e-commerce"].connected;
    return (
      <ViewWithLoader isLoading={this.state.isFetching}>
        <div className="row site--control-bar">
          <SiteDatePicker />
          <SiteLinks
            compare={this.state.compare}
            displayYOY={this.state.displayYOY}
          />
        </div>
        <SiteOverview fetchAllSites={this.state.fetchAllSites} />
        <div className="alert-container">
          {this.props.archived === 1 && (
            <div className="alert alert-warning">
              You are viewing an archived account
            </div>
          )}
        </div>
        <SiteTabs
          items={[
            {
              name: "analytics",
              connected: this.checkIfConnected("ga_property_id"),
            },
            {
              name: "GA4 Connect",
              connected: this.checkIfConnected("ga4_account_id"),
            },
            {
              name: "rankings",
              connected: this.checkIfConnected("se_site_id"),
            },
            { name: "inquiries", connected: this.checkIfConnected("wc_token") },
            { name: "paid-ads", connected: paidAdsConnected },
            { name: "social", connected: socialConnected },
            { name: "reviews", connected: reviewsConnected },
            { name: "e-commerce", connected: ECommerceConnected },
            { name: "email", connected: emailConnected },
          ]}
          selectedTab={this.state.displayTab}
          onSwitchTab={this.handleTabSwitch}
          isPublic={isPublic}
        />
        {this.renderTabContent()}
        {this.state.displayForm === true ? (
          <NoteForm
            classes={"sites-show-notes"}
            editNote={() => {}}
            handleCreateNote={this.handleCreateNote}
            hideForm={this.onToggleDisplayForm}
            handleEditNote={() => {}}
            editing={false}
            fromSitesShow={true}
            notes={null}
            editId={() => {}}
          />
        ) : null}
        {isPublic === false ? (
          <div
            onClick={this.onToggleDisplayForm}
            className="reports-note-button"
          >
            {this.state.displayForm && (
              <span>
                <i className="fa fa-times fa-lg" />
              </span>
            )}
            {this.state.displayForm === false && (
              <span>
                <i className="fa fa-sticky-note fa-lg" />
              </span>
            )}
          </div>
        ) : null}
      </ViewWithLoader>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      data: {
        client: { archived },
      },
    },
  },
  user,
  sites: { show },
  clients,
}) => ({ archived, user, show, clients });

const connected = connect(mapStateToProps, {
  fetchSite,
  setPermission,
  createNote,
})(SitesShow);

export { connected as SitesShow };
