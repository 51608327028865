import React, { Component } from "react";

import { DemoStatisticsBar } from "./DemoStatisticsBar"
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";

class GoogleAnalyticsOrganicSiteMetrics extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const metrics = {
            "New Users": {
                "title": "New Users",
                "value": 1752,
                "change": "+8%",
                "positiveChange": false
            },
            "Bounce Rate": {
                "title": "Bounce Rate",
                "value": "32%",
                "change": "-2%",
                "positiveChange": false
            },
            "Sessions": {
                "title": "Sessions",
                "value": "2.1k",
                "change": "-22%",
                "positiveChange": false
            },
            "Pages/Session": {
                "title": "Pages/Session",
                "value": 4,
                "change": "7%",
                "positiveChange": false
            },
            "Avg. Session Duration": {
                "title": "Avg. Session Duration",
                "value": "3m 55s",
                "change": "-8%",
                "positiveChange": false
            },
            "Page Views": {
                "title": "Page Views",
                "value": "5.6k",
                "change": "-17%",
                "positiveChange": false
            }
        }
        return (
            <DemoStatisticsBar
                renderSubtitle={() => (
                    <ReportSubTitle
                        text="Monthly Organic Site Metrics"
                        classes="pt-3 clearfix"
                        logo={logo}
                    />
                )}
                borderColor="#ef7a30"
                isLoading={isFetching}
                data={metrics}
            />
        );
    }
}


export default GoogleAnalyticsOrganicSiteMetrics;
