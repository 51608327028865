import React, { Component } from "react";
import { StatisticsBar } from "common";
import { connect } from "react-redux";
class InstagramStatsBar extends Component {
  render() {
    const data = {
      followersGrowth: {
        title: "Follower Growth",
        value: this.props.instagramFollowerGrowthCurrentMonth
          ? this.props.instagramFollowerGrowthCurrentMonth.toLocaleString()
          : "...",
        change: "0",
        positiveChange: false,
      },
      engagementRate: {
        title: "Engagement Rate",
        value: this.props.instagramEngagementRateCurrentMonth
          ? `${this.props.instagramEngagementRateCurrentMonth.toFixed(2)}%`
          : "...",
        change: "0",
        positiveChange: false,
      },
      profileViews: {
        title: "Profile Views",
        value: this.props.instagramProfileViewsCurrentMonth
          ? this.props.instagramProfileViewsCurrentMonth.toLocaleString()
          : "...",
        change: "0",
        positiveChange: false,
        bubbleValues: ["Organic", "Paid"],
      },
      likes_received: {
        title: "Likes Received",
        value: this.props.instagramLikesCountCurrentMonth
          ? this.props.instagramLikesCountCurrentMonth.toLocaleString()
          : "...",
        change: "0",
        positiveChange: false,
      },
      comments_received: {
        title: "Comments Received",
        value: this.props.instagramCommentsCountCurrentMonth
          ? this.props.instagramCommentsCountCurrentMonth.toLocaleString()
          : "...",
        change: "0",
        positiveChange: false,
      },
      impressions: {
        title: "Impressions",
        value: (this.props.instagramImpressionsCurrentMonthSelection || {})
          .Impressions
          ? (
              this.props.instagramImpressionsCurrentMonthSelection || {}
            ).Impressions.toLocaleString()
          : "...",
        change: "0",
        positiveChange: false,
      },
      reach: {
        title: "Reach",
        value: (this.props.instagramReachCurrentMonthSelection || {}).Reach
          ? (
              this.props.instagramReachCurrentMonthSelection || {}
            ).Reach.toLocaleString()
          : "...",
        change: "0",
        positiveChange: false,
        bubbleValues: ["Organic", "Paid"],
      },
    };

    return <div className="row">{<StatisticsBar data={data} />}</div>;
  }
}

const mapStateToProps = (state) => ({
  instagramReachCurrentMonthSelection:
    state.social.instagramReachCurrentMonthSelection,
  instagramReachTwoMonthsAgo: state.social.instagramReachTwoMonthsAgo,
  instagramReachThreeMonthsAgo: state.social.instagramReachThreeMonthsAgo,
  instagramImpressionsCurrentMonthSelection:
    state.social.instagramImpressionsCurrentMonthSelection,
  instagramImpressionsTwoMonthsAgo:
    state.social.instagramImpressionsTwoMonthsAgo,
  instagramImpressionsThreeMonthsAgo:
    state.social.instagramImpressionsThreeMonthsAgo,
  instagramCommentsCountCurrentMonth:
    state.social.instagramCommentsCountCurrentMonth,
  instagramFollowerGrowthCurrentMonth:
    state.social.instagramFollowerGrowthCurrentMonth,
  instagramEngagementRateCurrentMonth:
    state.social.instagramEngagementRateCurrentMonth,
  instagramLikesCountCurrentMonth: state.social.instagramLikesCountCurrentMonth,
  instagramProfileViewsCurrentMonth:
    state.social.instagramProfileViewsCurrentMonth,
});

const connected = connect(mapStateToProps)(InstagramStatsBar);

export { connected as InstagramStatsBar };
