import React, { Component } from 'react';
import { FormGroup } from "common";
import { handleInputChange, parseYelpUrl } from "lib/utilities";

export default class YelpTutorial extends Component {

  state = {
    yelp_url: "",
    url_valid: false,
  };

  componentDidUpdate(prevProps, prevState){
    if(prevState.yelp_url !== this.state.yelp_url){
      if(this.state.yelp_url.includes("yelp.com")){
        this.setState({
          url_valid: true
        });
      } else {
        this.setState({
          url_valid: false
        });
      }
    }
  }

  handleUpdateYelp = (e) => {
    e.preventDefault();
    this.props.saveReviewId(this.props.siteId, parseYelpUrl(this.state.yelp_url), "yelp", () => {
      this.props.displayManualConnectYelp()
    });
  };

  render() {

    return (
      this.props.manualConnectYelpVisable === true ?
        <div className="yelp-tutorial-popup">
          <h3>Issues with Yelp?</h3>
          <p>1. Visit the corresponding <a href="https://www.yelp.com" target="_blank">Yelp</a> page and click on "Write a Review" at the top of page.</p>
          <p>2. Once you're on "Write a Review" page, copy the link in the URL bar and paste below:</p>
          <FormGroup
            label="Example: https://www.yelp.com/writeareview/biz/Dl8q0lxcYJO8IshR..."
            value={this.state.yelp_url}
            placeholder={"Paste URL here"}
            onChange={e => handleInputChange(this, e, "yelp_url")}
          />
          <button disabled={!this.state.url_valid} className="popup-button btn btn-primary" onClick={(e) => this.handleUpdateYelp(e)}>Save Yelp</button>
          <button className="popup-button btn btn-secondary" onClick={() => this.props.displayManualConnectYelp()}>Close</button>
        </div>
        : null
    )
  }
}
