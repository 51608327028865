import "bootstrap/dist/css/bootstrap.min.css";
import "react-select/dist/react-select.css";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import 'react-datepicker/dist/react-datepicker.css';
import "./styles/main.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { Route } from "react-router-dom";
import rollbar from "rollbar";

import { store, history } from "store";
import App from "./App";
import { authLoginAndRedirect } from "screens/users/actions";

var _rollbarConfig = {
  accessToken: "1036f035d1414b22ad646ef55efd4d92",
  captureUncaught: true,
  payload: {
    environment: process.env.NODE_ENV
  }
};
const Rollbar = new rollbar(_rollbarConfig);
window.Rollbar = Rollbar;

let token = localStorage.getItem("token");

if (token !== null && token !== "undefined") {
  store.dispatch(authLoginAndRedirect(token));
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={App} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
