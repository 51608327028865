import axios from "axios";
import { requestHeaders, getToken } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import {
  startAndEndDate,
  withPreviousMonth,
  startAndEndDateAll,
} from "screens/sites/helpers/endpoint_helpers";
import { store } from "store";
import {
  FETCHING_GA_SITE_METRICS,
  FETCHING_GA_SESSIONS,
  FETCHING_GA_NEW_VISITORS,
  FETCHING_GA_EVENTS,
  FETCHING_GA_SOCIAL_SOURCES,
  FETCHING_GA_DEVICES,
  FETCHING_GA_NEW_VISITORS_BY_STATE,
  FETCHING_GA_TOP_LANDING,
  FETCHING_GA_PAGE_VIEWS,
} from "./types";

export const determineIfGA4 = ({ endDate }) => {
  const formatCRMDate = endDate.replace(/([-])+/g, "/");
  const crmReportsDatePickerStartDate = new Date(formatCRMDate);
  const ga4StartingMonth = new Date("2023/07/31");
  const isAfterGA4Date = crmReportsDatePickerStartDate > ga4StartingMonth;
  const dateSelectionIsGA4StartMonth =
    crmReportsDatePickerStartDate.toDateString() ===
    ga4StartingMonth.toDateString();
  return isAfterGA4Date || dateSelectionIsGA4StartMonth ? "v2" : "v1";
};

const requestParams = (clear) => {
  let finalParameters;
  if (clear === true) {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: true,
      },
    };
  } else {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: false,
      },
    };
  }
  return finalParameters;
};

export const fetchGoogleAnalyticsSiteMetrics = (
  params,
  callback,
  clearCacheBool
) => async () => {
  const GA4Version = determineIfGA4({ endDate: params.endDate });
  const dispatching = dispatchHelper(
    GA4Version === "v1"
      ? FETCHING_GA_SITE_METRICS
      : "FETCHING_GA_SITE_METRICS_GA4"
  );
  const token = getToken();
  const url = withPreviousMonth(params, "/analytics/metrics/", GA4Version);
  const headers = params.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);
  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  const middlewareKey =
    GA4Version === "v1" ? "fromGASiteMetrics" : "fromGASiteMetricsGA4";
  response.middleware = { [middlewareKey]: true };
  dispatching(response, callback);
};

export const fetchGoogleAnalyticsNewVisitors = (
  params,
  callback,
  clearCacheBool,
  actionName = undefined
) => async () => {
  const state = store.getState();
  const crmDateSelectorDate = state.sites.show.date.dateOneEndForCompare;
  const GA4Version = determineIfGA4({ endDate: crmDateSelectorDate });
  let updatedParams = { ...params };
  const dispatching = dispatchHelper(
    actionName ? actionName : FETCHING_GA_NEW_VISITORS
  );
  const token = getToken();
  let url = startAndEndDate(
    updatedParams,
    "/analytics/newvisitors/",
    GA4Version
  );
  const headers = updatedParams.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);

  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  //response.middleware = { fromGASessions: true };
  dispatching(response, callback);
};

export const fetchGoogleAnalyticsNewVisitorsAll = (
  params,
  callback,
  clearCacheBool,
  actionName = undefined
) => async () => {
  const state = store.getState();
  const crmDateSelectorDate = state.sites.show.date.dateOneEndForCompare;
  const GA4Version = determineIfGA4({ endDate: crmDateSelectorDate });
  let updatedParams = { ...params };
  const dispatching = dispatchHelper(
    actionName ? actionName : "FETCHING_GA_NEW_VISITORS_ALL"
  );
  const token = getToken();
  let url = startAndEndDateAll(
    updatedParams,
    "/analytics/newvisitors/",
    GA4Version
  );
  const headers = updatedParams.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);

  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  //response.middleware = { fromGASessions: true };
  dispatching(response, callback);
};

export const fetchGoogleAnalyticsSessions = (
  params,
  callback,
  clearCacheBool,
  actionName
) => async () => {
  const state = store.getState();
  const crmDateSelectorDate = state.sites.show.date.dateOneEndForCompare;
  const GA4Version = determineIfGA4({ endDate: crmDateSelectorDate });
  let updatedParams = { ...params };
  const dispatching = dispatchHelper(
    actionName ? actionName : FETCHING_GA_SESSIONS
  );

  const token = getToken();
  let url = startAndEndDate(updatedParams, "/analytics/sessions/", GA4Version);
  const headers = updatedParams.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);

  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  //response.middleware = { fromGASessions: true };
  dispatching(response, callback);
};

export const fetchGoogleAnalyticsEvents = (
  params,
  callback,
  clearCacheBool
) => async () => {
  const state = store.getState();
  const crmDateSelectorDate = state.sites.show.date.dateOneEndForCompare;
  const GA4Version = determineIfGA4({ endDate: crmDateSelectorDate });
  const dispatching = dispatchHelper(
    GA4Version === "v1" ? FETCHING_GA_EVENTS : "FETCHING_GA_EVENTS_GA4"
  );
  const token = getToken();
  const url = startAndEndDate(params, "/analytics/events/", GA4Version);
  const headers = params.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  const middlewareKey =
    GA4Version === "v1" ? "fromGAEvents" : "fromGAEventsGA4";
  response.middleware = { [middlewareKey]: true };
  dispatching(response, callback);
};
export const fetchGoogleAnalyticsSocialSources = (
  params,
  callback,
  clearCacheBool
) => async () => {
  const GA4Version = determineIfGA4({ endDate: params.endDate });
  const dispatching = dispatchHelper(
    GA4Version === "v1"
      ? FETCHING_GA_SOCIAL_SOURCES
      : "FETCHING_GA_SOCIAL_SOURCES_GA4"
  );
  const token = getToken();
  const url = startAndEndDate(params, "/analytics/social-sources/", GA4Version);
  const headers = params.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);

  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  const middlewareKey =
    GA4Version === "v1" ? "fromGASocial" : "fromGASocialGA4";

  response.middleware = { [middlewareKey]: true };
  dispatching(response, callback);
};

export const fetchGoogleAnalyticsDevices = (
  params,
  callback,
  clearCacheBool
) => async () => {
  const GA4Version = determineIfGA4({ endDate: params.endDate });
  const dispatching = dispatchHelper(
    GA4Version === "v1" ? FETCHING_GA_DEVICES : "FETCHING_GA_DEVICES_GA4"
  );
  const token = getToken();
  const url = startAndEndDate(params, "/analytics/devices/", GA4Version);
  const headers = params.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);
  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  const middlewareKey =
    GA4Version === "v1" ? "fromGASocial" : "fromGADevicesGA4";

  response.middleware = { [middlewareKey]: true };
  dispatching(response, callback);
};

export const fetchGoogleAnalyticsVisitorsByState = (
  params,
  callback,
  clearCacheBool,
  actionName
) => async () => {
  const state = store.getState();
  const crmDateSelectorDate = state.sites.show.date.dateOneEndForCompare;
  const GA4Version = determineIfGA4({ endDate: crmDateSelectorDate });
  const token = getToken();
  if (GA4Version === "v2" && !actionName) {
    const dispatching = dispatchHelper("FETCHING_GA_NEW_VISITORS_BY_STATE_GA4");
    const url = startAndEndDate(
      params,
      "/analytics/user-locations/",
      GA4Version
    );
    const headers = params.isPublic ? {} : requestHeaders(token);
    let finalParameters = requestParams(clearCacheBool);
    // const response = await axios.get(url, headers);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    response.middleware = actionName ? {} : { fromGAVisitorsByStateGA4: true };
    dispatching(response, callback);
  } else {
    const dispatching = dispatchHelper(
      actionName ? actionName : FETCHING_GA_NEW_VISITORS_BY_STATE
    );
    const url = startAndEndDate(params, "/analytics/user-locations/");
    const headers = params.isPublic ? {} : requestHeaders(token);
    let finalParameters = requestParams(clearCacheBool);

    // const response = await axios.get(url, headers);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    response.middleware = actionName ? {} : { fromGAVisitorsByState: true };
    dispatching(response, callback);
  }
};

export const fetchGoogleAnalyticsTopLanding = (
  params,
  callback,
  clearCacheBool
) => async () => {
  const GA4Version = determineIfGA4({ endDate: params.endDate });
  const dispatching = dispatchHelper(
    GA4Version === "v1"
      ? FETCHING_GA_TOP_LANDING
      : "FETCHING_GA_TOP_LANDING_GA4"
  );
  const token = getToken();
  const url = withPreviousMonth(params, "/analytics/toplanding/", GA4Version);
  const headers = params.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);

  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  const middlewareKey =
    GA4Version === "v1" ? "fromGATopLanding" : "fromGATopLandingGA4";
  response.middleware = { [middlewareKey]: true };
  dispatching(response, callback);
};

export const fetchGoogleAnalyticsPageViews = (
  params,
  callback,
  clearCacheBool
) => async () => {
  const GA4Version = determineIfGA4({ endDate: params.endDate });
  const dispatching = dispatchHelper(
    GA4Version === "v1" ? FETCHING_GA_PAGE_VIEWS : "FETCHING_GA_PAGE_VIEWS"
  );
  const token = getToken();
  const url = withPreviousMonth(params, "/analytics/pageviews/", GA4Version);
  const headers = params.isPublic ? {} : requestHeaders(token);
  let finalParameters = requestParams(clearCacheBool);

  // const response = await axios.get(url, headers);
  const response = await axios.get(
    url,
    Object.assign({}, finalParameters, headers)
  );
  const middlewareKey =
    GA4Version === "v1" ? "fromGATopLanding" : "fromGATopLandingPageViewsGA4";
  response.middleware = { [middlewareKey]: true };
  dispatching(response, callback);
};
