import axios from "axios";
import { requestHeaders, getToken } from "lib/authHelpers";
import { rootUrl } from "lib/api";
import { dispatchHelper } from "lib/actionHelpers";
import { FETCHING_CATEGORIES } from "./types";

export const fetchCategories = () => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_CATEGORIES, dispatch);
  const token = getToken();
  const url = `${rootUrl}/categories`;
  const headers = requestHeaders(token);

  const response = await axios.get(url, headers);
  dispatching(response);
};
