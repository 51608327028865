export const fetchSite = ({ dispatch }) => next => action => {
  if (!action.fromFetchSite) return next(action);

  let formattedData = {
    ...action.payload
  };

  if (formattedData.amazon_integration === null){
    formattedData.amazon_integration = {
      merchant_id: "",
      marketplace_id: "",
      auth_token: ""
    } 
  }


  const newAction = {
    ...action,
    payload: formattedData,
    fromFetchSite: false
  };

  dispatch(newAction);
}
