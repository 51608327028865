import React, { Component, Fragment } from "react";
import { SocialMediaSitesNavBar } from "./components/SocialMediaSitesNavBar";
import {
  fetchSocialSites,
  fetchTwitterData,
  fetchInstagramDataInsights,
  fetchFollowersCount,
} from "./actions/actions";
import { fetchInstagramBusinessID } from "../sites/actions/sites_actions";
import { connect } from "react-redux";
import { Facebook } from "./Facebook";
import { Instagram } from "./Instagram";
import { Twitter } from "./Twitter";
import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";

import { RedisButton } from "common";
const blankFunction = () => {};

class SocialIndex extends Component {
  state = {
    siteList: [],
    selectedAccount: {
      label: null,
      id: null,
    },
    selection: "Facebook",
    clearCacheBool: false,
  };
  handleAccountChange(selectedAccount) {
    let accountSelection = Object.assign({}, this.state.selectedAccount);
    accountSelection.label = selectedAccount.label;
    accountSelection.id = selectedAccount.id;
    this.setState({
      selectedAccoun2t: accountSelection,
    });
  }
  selectSocialMedia = (selection) => {
    this.setState(function(state, props) {
      return {
        selection: selection,
      };
    });
  };

  componentDidMount() {
    if (this.props.fb_page_id && this.props.fb_page_token) {
      this.props.instagram_business_id !== null &&
        this.props.fetchFollowersCount(this.props.public_hash);
      !this.props.instagram_business_id &&
        this.props.fetchInstagramBusinessID(this.props.public_hash);
    }
    this.props.twitter_username !== null &&
      this.props.fetchTwitterData(this.props.twitter_username);
  }
  componentDidUpdate(prevProps) {
    if (this.props.social.sites !== prevProps.social.sites) {
      const siteOptions = this.props.social.sites.map((site) => ({
        label: `${site.name}`,
        id: site.id,
      }));
      this.setState({ siteList: siteOptions });
    }
    for (let key in this.props.date) {
      if (this.props.date[key] !== prevProps.date[key]) {
        this.props.fetchSocialSites();
      }
    }
    if (this.props.twitter_username !== prevProps.twitter_username) {
      this.props.twitter_username !== null &&
        this.props.fetchTwitterData(this.props.twitter_username);
    }
    if (this.props.instagram_business_id !== prevProps.instagram_business_id) {
      this.props.instagram_business_id !== null &&
        this.props.fetchFollowersCount(this.props.public_hash);
    }
    if (this.props.fb_page_id !== prevProps.fb_page_id) {
      if (this.props.fb_page_id !== null) {
        !this.props.instagram_business_id &&
          this.props.fetchInstagramBusinessID(this.props.public_hash);
      }
    }
  }

  render() {
    let context = this;
    if (this.props.isPublic && this.props.fb_page_id === null) {
      return (
        <Fragment>
          <SocialMediaSitesNavBar
            twitterUsername={this.props.twitter_username}
            twitterData={this.props.social.twitter}
            siteId={this.props.id}
            fbPageId={this.props.fb_page_id}
            fbPageToken={this.props.fb_page_token}
            data={this.props.social.fans}
            selectSocialMedia={this.selectSocialMedia}
            followerCount={this.props.social.follower_count}
            instagramBusinessId={this.props.instagram_business_id}
          />
          <div className="col-12 mt-4">
            <ThirdPartyFallback
              id={this.props.fb_page_id}
              type={this.state.selection}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <SocialMediaSitesNavBar
            twitterUsername={this.props.twitter_username}
            twitterData={this.props.social.twitter}
            siteId={this.props.id}
            fbPageId={this.props.fb_page_id}
            fbPageToken={this.props.fb_page_token}
            data={this.props.social.fans}
            selectSocialMedia={this.selectSocialMedia}
            followerCount={this.props.social.follower_count}
            instagramBusinessId={this.props.instagram_business_id}
          />
          {this.state.selection === "Facebook" && (
            <Facebook
              isPublic={this.props.isPublic}
              siteId={this.props.id}
              fetchAccounts={this.props.fetchSocialSites}
              formatAccounts={blankFunction}
              fb_page_id={this.props.fb_page_id}
              fb_page_token={this.props.fb_page_token}
              pageId={this.props.fb_page_id}
              selection={this.state.selection}
              clearCacheBool={this.state.clearCacheBool}
            />
          )}
          {this.state.selection === "Twitter" && (
            <Twitter
              isPublic={this.props.isPublic}
              twitterData={this.props.social.twitter}
              twitterUsername={this.props.twitter_username}
              siteId={this.props.id}
            />
          )}
          {this.state.selection === "Instagram" && (
            <Instagram
              isPublic={this.props.isPublic}
              fetchAccounts={this.props.fetchSocialSites}
              formatAccounts={blankFunction}
              instagramData={this.props.social.instagram}
              siteId={this.props.id}
              fb_page_id={this.props.fb_page_id}
              fb_page_token={this.props.fb_page_token}
              pageId={this.props.fb_page_id}
              instagram_business_id={this.props.instagram_business_id}
              clearCacheBool={this.state.clearCacheBool}
            />
          )}
          <RedisButton isPublic={this.props.isPublic} context={context} />
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ...state.social,
  ...state.sites.show.data,
  ...state.sites.show.permissions,
  date: { ...state.sites.show.date },
});

const mapDispatchToProps = {
  fetchSocialSites,
  fetchTwitterData,
  fetchInstagramBusinessID,
  fetchInstagramDataInsights,
  fetchFollowersCount,
};

const connected = connect(mapStateToProps, mapDispatchToProps)(SocialIndex);

export { connected as SocialIndex };
