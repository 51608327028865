import React, { Component } from "react";
import { array, func, object } from "prop-types";
import { connect } from "react-redux";

import {fetchClient, fetchClients, updateClient} from "screens/clients/actions";
import {fetchOneUser, fetchUsers} from "screens/users/actions";
import { fetchCategories } from "screens/categories/actions";
import { Button, Card, FormGroup, FormGroupSelect, Toggle } from "common";
import { handleInputChange } from "lib/utilities";

class ClientsEdit extends Component {
  static propTypes = {
    fetchUsers: func,
    fetchCategories: func,
    fetchClient: func,
    updateClient: func,
    match: object,
    clientData: object,
    categories: array,
    users: array
  }

  state = { name: "", isFetching: true, archived: 0 };

  async componentDidMount() {
    await this.props.fetchUsers();
    await this.props.fetchCategories();
    await this.props.fetchClient({ id: this.props.match.params.id });

    const { clientData } = this.props;

    this.setState({
      name: clientData.name,
      supportbee_label: clientData.supportbee_label,
      categories_id: clientData.categories_id,
      manager_id: clientData.manager_id,
      archived: clientData.archived,
      isFetching: false,
      primary_site_id: clientData.primary_site_id,
    });
  }

  updateClient = () => {
    const primarySiteId = this.state.primary_site_id === 'default'
        ? 0
        : this.state.primary_site_id;
    this.props.updateClient(this.props.clientData.id, {
      id: this.props.clientData.id,
      name: this.state.name,
      supportbee_label: this.state.supportbee_label,
      categories_id: this.state.categories_id,
      manager_id: this.state.manager_id,
      archived: this.state.archived,
      primary_site_id: primarySiteId
    });
  };

  renderCategories = () => (
    <FormGroupSelect
      label="Category"
      value={this.state.categories_id}
      onChange={e => handleInputChange(this, e, "categories_id")}
      options={this.props.categories}
    />
  );

  handleCheckboxChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    value === true && (value = 1);
    value === false && (value = 0);
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleArchiveChange = (event) => {
    event.preventDefault();
    let archiveInt = !this.state.archived ? 1 : 0;
    this.setState({
      archived: archiveInt,
    });
  };

  renderUsers = () => {
    const options = this.props.users.map(user => {
      return {
        id: user.id,
        name: `${user.first_name} ${user.last_name} - ${user.job}`
      };
    });
    return (
      <FormGroupSelect
        label="Account Manager"
        value={this.state.manager_id}
        onChange={e => handleInputChange(this, e, "manager_id")}
        options={options}
      />
    );
  };

  renderSites = () => {
    const options = (this.props.clientData.sites || []).map(n => {
      return {
        id: n.id,
        name: `${n.name}`
      };
    });
    options.unshift({
      id: 0,
      name: 'None'
    });
    return (
        <FormGroupSelect
            label="Primary Site"
            value={this.state.primary_site_id}
            onChange={e => handleInputChange(this, e, "primary_site_id")}
            options={options}
        />
    );
  };

  render() {

    const sites = this.props.clientData.sites || [];

    let archivedCheckBox;
    this.state.archived === 1 && (archivedCheckBox = true)
    this.state.archived === 0 && (archivedCheckBox = false)
    return (
      <Card
        title="Edit Client"
        classes="col-sm-12 col-md-6 offset-md-3"
        padding={5}
        isLoading={this.state.isFetching}
      >
        <FormGroup
          label="Client Name"
          value={this.state.name || ""}
          onChange={e => handleInputChange(this, e, "name")}
        />
        <FormGroup
          label="SupportBee Label"
          value={this.state.supportbee_label || ""}
          onChange={e => handleInputChange(this, e, "supportbee_label")}
        />
        {this.renderCategories()}
        {this.renderUsers()}
        {this.renderSites()}
        <div className="checkbox-clients-edit">
          <Toggle
            label="Archive?"
            toggle={!!this.state.archived}
            onChange={this.handleArchiveChange}
          />

        </div>
        <Button onClick={this.updateClient} classes="mr-3">
          Save Edits
        </Button>
        <Button classes={"cancel-btn"} link to={`/clients/${this.props.clientData.id}`}>
          Cancel
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  clientData: state.clients.show.data,
  users: state.user.index.data,
  categories: state.categories.data
});

const mapDispatchToProps = { fetchUsers, fetchCategories, fetchClient, updateClient };

const connected = connect(mapStateToProps, mapDispatchToProps)(ClientsEdit);

export { connected as ClientsEdit };

