import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Panel, CustomPieChart } from "common";
import CampaignInfographic from './CampaignInfographic';
import Icon_Conversions from '../../../images/icon-conversions.svg';
import Icon_Money from '../../../images/icon-money.svg';
import Icon_Display from 'images/icon-display.svg';
import Icon_Ecommerce from 'images/icon-ecommerce.svg';
import Icon_Search from 'images/icon-search.svg';
import _ from "lodash";
import { formatFbOverviewData } from "screens/sites/helpers/facebook_helpers";



export default class AdsInfographic extends Component {

    state = {
        spend: 0,
        value: 0,
        conversions: 0,
    }

    getImageByAdType = (isEcomm) => {
        let image;
        let text = '';
        if (this.props.type === "Google") {
            if (this.props.label === "Sales") {
                image = Icon_Ecommerce;
                text = "Revenue";
            }
            if (this.props.label === "Leads") {
                image = Icon_Search;
                text = "Leads";
            }
            if (this.props.label === "Reach") {
                image = Icon_Display;
                text = "Reach";
            }
            if(isEcomm){
                image = Icon_Ecommerce;
                text = "Revenue";
            }
        } else {
            if (this.props.label === "Revenue") {
                image = Icon_Ecommerce;
                text = "Revenue";
            }
            if (this.props.label === "Leads") {
                image = Icon_Search;
                text = "Leads";
            }
            if (this.props.label === "Engagement") {
                image = Icon_Conversions;
                text = "Brand Awareness";
            }
        }
        let content = {
            image,
            text
        }
        return content
    }

    componentDidMount() {
        this.props.type === "Google" && this.calculateInfographicValuesAdwords();
    }

    componentDidUpdate(prevProps) {
        // Adwords
        if (!_.isEqual(this.props.adwordsPerformanceAcctLevel.totals.start, prevProps.adwordsPerformanceAcctLevel.totals.start)) {
            this.props.type === "Google" && this.calculateInfographicValuesAdwords();
        }
        if (this.props.spend !== prevProps.spend) {
            this.props.type === "Google" && this.calculateInfographicValuesAdwords();
        }
        if (this.props.conversions !== prevProps.conversions) {
            this.props.type === "Google" && this.calculateInfographicValuesAdwords();
        }
        // FB 
        if (!_.isEqual(this.props.dateOneAdValuesData, prevProps.dateOneAdValuesData)) {
            if (this.props.type === "Facebook") {
                let data = formatFbOverviewData(this.props.facebookPerformanceAcctLevel.data, true, this.props.ad_type);
                this.calculateInfographicValuesFacebook(data, this.props.dateOneAdValuesData);
            }
        }
        if (!_.isEqual(this.props.facebookPerformanceAcctLevel.data, prevProps.facebookPerformanceAcctLevel.data)) {
            let data = formatFbOverviewData(this.props.facebookPerformanceAcctLevel.data, true, this.props.ad_type);
            this.calculateInfographicValuesFacebook(data, this.props.dateOneAdValuesData);
        }

    }

    calculateInfographicValuesAdwords = () => {
        let clone = {
            Clicks: 0,
            Conversions: 0,
            Cost: 0,
            Cost_conv: 0,
            Search_Lost_IS_budget: "0%",
            Total_conv_value: 0,
        }
        if(this.props.adwordsPerformanceAcctLevel.totals.start !== null){
            clone = _.cloneDeep(this.props.adwordsPerformanceAcctLevel.totals.start);
        }
        if (clone.Cost === 0 && clone.Conversions === 0) {
            return null;
        }
        if (this.props.spend && this.props.ad_value) {
            let spend = this.props.spend / clone.Cost * 100;
            let adVal = this.props.ad_value / clone.Conversions * 100;
            if (this.props.label === "Sales" || this.props.label === "Revenue") {
                adVal = this.props.ad_value / clone.Total_conv_value * 100;
            }
            if (this.props.isEcomm) {
                adVal = this.props.dateOneAdValuesData.Total_conv_value / clone.Total_conv_value * 100;
            }
            let newSpend = spend.toFixed(1);
            let newAdVal = adVal.toFixed(1);
            this.setState({ spend: newSpend, value: newAdVal }, () => this.props.stopLoader("infoGraphicLoader"));
        }
    };

    calculateInfographicValuesFacebook = (accountLevelData, individualData) => {
        let cloneAccountLevelData = _.cloneDeep(accountLevelData);
        _.forOwn(cloneAccountLevelData, (value, key) => {
            if (isNaN(value.value)) {
                if (value.value.includes("$")) {
                    value.value = value.value.replace("$", '');
                }
                if (value.value.includes(",")) {
                    value.value = value.value.replace(",", '');
                }
            }
        });
        if (!_.isEmpty(individualData)) {
            let spend = individualData.spend / cloneAccountLevelData.spend.value * 100;
            let kpiValue = individualData.ad_value / cloneAccountLevelData.conversions.value * 100;
            let newSpendToFixed = spend.toFixed(1);
            let newKpiValue = kpiValue.toFixed(1);
            let parsedSpend = parseFloat(newSpendToFixed);
            let parseKpiValue = parseFloat(newKpiValue);
            this.setState({ spend: parsedSpend, value: parseKpiValue }, () => this.props.stopLoader("infoGraphicLoader"));
        }
    }

    infographicValueEcomm = () => {
        if(this.props.isEcomm){
            if(this.props.dateOneAdValuesData){
                if(this.props.dateOneAdValuesData.Total_conv_value){
                    let parsedVal = parseFloat(this.props.dateOneAdValuesData.Total_conv_value);
                    return parsedVal;
                }
            }
        }
        return this.props.ad_value;
    }
  

    render() {
        let content = this.getImageByAdType(this.props.isEcomm);
        let value = this.infographicValueEcomm();
        let spend = this.props.spend;
        let accountLevelDataGoogle;
        let data = {
            conversions: { title: "Conversions", value: 0 },
            cpc: { title: "CPC", value: 0, totalIterations: 0 },
            ctr: { title: "CTR", value: 0, totalIterations: 0 },
            roas: { title: "ROAS", value: 0, totalIterations: 0 },
            spend: { title: "Spend", value: 0 },
            visitors: { title: "Visitors", value: 0 },
        }
        if (this.props.type === "Facebook") {
            data = formatFbOverviewData(this.props.facebookPerformanceAcctLevel.data, true, this.props.ad_type);
        }
        if (_.isString(this.props.spend)) {
            spend = parseFloat(spend);
        }
        if (this.props.type === "Google") {
            if(this.props.adwordsPerformanceAcctLevel.totals.start !== null){
                if(this.props.isEcomm){
                    accountLevelDataGoogle = this.props.adwordsPerformanceAcctLevel.totals.start.Total_conv_value;
                } else {
                    if (this.props.label === "Sales") {
                        accountLevelDataGoogle = this.props.adwordsPerformanceAcctLevel.totals.start.Total_conv_value;
                        } else {
                        accountLevelDataGoogle = this.props.adwordsPerformanceAcctLevel.totals.start.Conversions;
                    }
                }
            }
        }
        let cost = 0;
        if(this.props.adwordsPerformanceAcctLevel.totals.start === null){
            cost = 0;
        } else {
            cost = this.props.adwordsPerformanceAcctLevel.totals.start.Cost
        }
        if (this.props.type === "Google") {
            return (
                <div className="col-12 adwords-infographics">
                    <CampaignInfographic
                        isLoading={this.props.isLoading}
                        heading={"Spend"}
                        individualValue={spend}
                        icon={Icon_Money}
                        accountLevelData={cost}
                        footerPercentValue={this.state.spend}
                    />
                    <CampaignInfographic
                        isLoading={this.props.isLoading}
                        heading={content.text}
                        individualValue={value}
                        accountLevelData={accountLevelDataGoogle}
                        icon={content.image}
                        footerPercentValue={this.state.value}
                    />
                </div>
            )
        } else {
            return (
                <div className="col-12 adwords-infographics">
                    <CampaignInfographic
                        isLoading={this.props.isLoading}
                        heading={"Spend"}
                        accountLevelData={data.spend.value}
                        individualValue={spend}
                        icon={Icon_Money}
                        footerPercentValue={this.state.spend} />
                    <CampaignInfographic
                        isLoading={this.props.isLoading}
                        heading={content.text}
                        accountLevelData={data.conversions.value}
                        individualValue={this.props.ad_value}
                        icon={content.image}
                        footerPercentValue={this.state.value} />
                </div>
            )
        }

    }
}