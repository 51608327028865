import _ from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bool, func, string, object } from "prop-types";
import Modal from "react-modal";
import dateFormat from "dateformat";

import { Button } from "common";

const LeadItemInput = props => {
  return (
    <div className="lead-field">
      <span>{props.label}</span>
      <input
        type="text"
        className="form-control"
        style={{ border: "0" }}
        readOnly={props.readOnly}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
};

LeadItemInput.propTypes = {
  label: string.isRequired,
  readOnly: bool,
  value: string,
  onChange: func
};

const LeadItemSelect = props => {
  return (
    <div className="lead-field">
      <span>{props.label}</span>
      <select
        className="form-control"
        style={{ border: "0" }}
        readOnly={props.readOnly}
        value={props.value}
        onChange={props.onChange}
      >
        <option value="" disabled={props.readOnly}>
          Select One
        </option>
        <option value="yes" disabled={props.readOnly}>
          yes
        </option>
        <option value="no" disabled={props.readOnly}>
          no
        </option>
      </select>
    </div>
  );
};

LeadItemSelect.propTypes = {
  label: string.isRequired,
  readOnly: bool,
  value: string,
  onChange: func
};

const CALL_FIELDS = [
  "date_created",
  "caller_name",
  "caller_city",
  "caller_state",
  "caller_number",
  "destination_number",
  "call_duration",
  "answer_status",
  "call_status"
];

const WEB_FIELDS = ["date_created", "lead_source", "landing_url", "lead_url"];

class CallFormFields extends Component {
  state = {
    is_lead: "",
    name: "",
    spoke_with: "",
    scheduled: "",
    extra: "",
    inquiry: "",
    isSaving: false
  };

  componentDidMount() {
    if (!this.props.data.lead_meta) return;
    const {
      is_lead,
      name,
      spoke_with,
      scheduled,
      extra,
      inquiry
    } = this.props.data.lead_meta;

    this.setState({
      is_lead,
      name,
      spoke_with,
      scheduled,
      extra,
      inquiry
    });
  }

  handleClick = () => {
    this.setState({ isSaving: true });
    this.props.postLeadData(
      {
        lead_id: this.props.leadId,
        name: this.state.name,
        spoke_with: this.state.spoke_with,
        scheduled: this.state.scheduled,
        inquiry: this.state.inquiry,
        extra: this.state.extra,
        is_lead: this.state.is_lead
      },
      () => this.setState({ isSaving: false })
    );
  };

  render() {
    return (
      <Fragment>
        <Button classes="float-left" onClick={this.handleClick}>
          {this.state.isSaving ? "Saving..." : "Save"}
        </Button>
        <h3 className="lead-title clearfix p-3">Phone Call</h3>
        <LeadItemSelect
          label="Lead Yes / No"
          value={this.state.is_lead}
          onChange={e => this.setState({ is_lead: e.target.value })}
        />
        <LeadItemInput
          label="Name"
          value={this.state.name}
          onChange={e => this.setState({ name: e.target.value })}
        />
        <LeadItemInput
          label="Spoke With"
          value={this.state.spoke_with}
          onChange={e => this.setState({ spoke_with: e.target.value })}
        />
        <LeadItemSelect
          label="Scheduled Yes / No"
          value={this.state.scheduled}
          onChange={e => this.setState({ scheduled: e.target.value })}
        />
        <LeadItemInput
          label="Inquiry"
          value={this.state.inquiry}
          onChange={e => this.setState({ inquiry: e.target.value })}
        />
        <div className="lead-field" key="notes">
          <span>Notes</span>
          <textarea
            className="form-control"
            value={this.state.extra}
            onChange={e => this.setState({ extra: e.target.value })}
          />
        </div>
      </Fragment>
    );
  }
}

function renderCallFields(data, mp3Html, callFields) {
  if (!data) return;
  if (data.lead_type !== "Phone Call") return;

  return (
    <Fragment>
      <div dangerouslySetInnerHTML={{ __html: mp3Html }} />
      {callFields.map(field => {
        return (
          <div key={field} className="lead-field">
            <span>{field.split("_").join(" ")}</span>
            {field === "date_created"
              ? dateFormat(data[field], "fullDate")
              : data[field]}
          </div>
        );
      })}
    </Fragment>
  );
}

function renderWebFormFields(data, webFields) {
  if (!data) return;
  if (data.lead_type !== "Web Form") return;
  const fields = { ...data.additional_fields };
  delete fields["What is two plus two?"];
  delete fields["spa"];

  return (
    <Fragment>
      <h3 className="lead-title">Web Form</h3>
      {_.map(fields, (item, label) => {
        return (
          <div className="lead-field" key={label}>
            <span>{label}</span>
            {item}
          </div>
        );
      })}
      {webFields.map(field => {
        return (
          <div key={field} className="lead-field">
            <span>{field.split("_").join(" ")}</span>
            {field === "date_created"
              ? dateFormat(data[field], "fullDate")
              : data[field]}
          </div>
        );
      })}
    </Fragment>
  );
}

WhatConvertsModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  mp3Html: string,
  data: object,
  isPublic: bool
};

function WhatConvertsModal(props) {
  if (!props.data) return null;

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentLabel="Modal"
      className="react-modal lead-modal"
      overlayClassName="react-modal-overlay"
    >
      {(props.data.lead_type === "Phone Call" && !props.isPublic) && (
        <CallFormFields
          postLeadData={props.postLeadData}
          leadId={props.leadId}
          data={props.data}
        />
      )}
      {renderCallFields(props.data, props.mp3Html, CALL_FIELDS)}
      {renderWebFormFields(props.data, WEB_FIELDS)}
    </Modal>
  );
}

const mapStateToProps = ({
  sites: { show: { permissions: { isPublic } } }
}) => ({ isPublic });

export default connect(mapStateToProps)(WhatConvertsModal);
