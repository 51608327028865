import React, { Component, Fragment } from "react";
import { fetchSocialDataInsights } from "./actions/actions";
import { connect } from "react-redux";
import facebookLogo from "images/icon_facebook.png";

import moment from "moment";
import { StatsBarContainer } from "./components/StatsBarContainer";
import { Engagement } from "./components/Engagement";
import { Location } from "./components/Location";
import { LikesPaidOrganic } from "./components/LikesPaidOrganic";
import { FacebookDemographics } from "./components/FacebookDemographics";
import { Impressions } from "./components/Impressions";
import { VideoViews } from "./components/VideoViews";
import { LikeSources } from "./components/LikeSources";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import ThirdPartyDisconnect from "screens/sites/components/ThirdPartyDisconnect";
import ThirdPartyConnectFBAnalytics from "screens/sites/components/ThirdPartyConnect_FB_Analytics";
import PropTypes from "prop-types";
import GoogleAnalyticsSocialSourcePageViews from "screens/sites/components/GoogleAnalyticsSocialSourcePageViews";

const VALUES = [
  "likesAndPageViews",
  "growth",
  "engagement",
  "video",
  "clicks",
  "age",
  "location",
  "fans",
  "impressions",
];
const Three_Months_Time_Period = [
  "growth",
  "likesAndPageViews",
  "impressions",
  "clicks",
  "video",
  "engagement",
];
const Lifetime = ["fans", "age", "location"];
class Facebook extends Component {
  state = {
    startDate: null,
    endDate: null,
  };

  fetchDataWithStartAndEndDates = () => {
    let startDate;
    let endDate;
    let currentMonth;
    let currentYear;
    if (this.props.date.month === "0" || this.props.date.month === "1") {
      currentYear = this.props.date.year - 1;
      currentYear.toString();
    } else {
      currentYear = this.props.date.year;
    }
    if (this.props.date.month === "0") {
      let jan = "01";
      currentMonth = moment(`${jan}`)
        .startOf("month")
        .format(`${this.props.date.year}-MM-DD`);
    } else {
      currentMonth = moment(`${this.props.date.month}`)
        .add(1, "month")
        .startOf("month")
        .format(`${this.props.date.year}-MM-DD`);
    }
    VALUES.forEach((value) => {
      if (Three_Months_Time_Period.includes(value)) {
        let threeMonthsAgo = moment(currentMonth)
          .startOf("month")
          .subtract(2, "months")
          .format(`${currentYear}-MM-DD`);
        let firstDayOfMonthThreeMonthsAgo = moment(threeMonthsAgo)
          .startOf("month")
          .format(`${currentYear}-MM-DD`);
        let lastDayOfThisMonth = moment(currentMonth)
          .endOf("month")
          .format(`${this.props.date.year}-MM-DD`);
        startDate = firstDayOfMonthThreeMonthsAgo;
        endDate = lastDayOfThisMonth;
        this.props.fetchSocialDataInsights(
          value,
          startDate,
          endDate,
          this.props.siteData.public_hash,
          this.props.clearCacheBool
        );
      } else if (Lifetime.includes(value)) {
        startDate = "noDate";
        endDate = "noDate";
        this.props.fetchSocialDataInsights(
          value,
          startDate,
          endDate,
          this.props.siteData.public_hash,
          this.props.clearCacheBool
        );
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.date.month !== prevProps.date.month ||
      this.props.date.year !== prevProps.date.year
    ) {
      this.fetchDataWithStartAndEndDates();
    }
    if (
      this.props.fb_page_id !== prevProps.fb_page_id &&
      this.props.fb_page_token !== prevProps.fb_page_token
    ) {
      this.fetchDataWithStartAndEndDates();
    }
    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      this.props.clearCacheBool === true &&
        this.fetchDataWithStartAndEndDates();
    }
  }
  componentDidMount() {
    if (this.props.fb_page_token && this.props.fb_page_id) {
      this.fetchDataWithStartAndEndDates();
    }
  }
  render() {
    if (this.props.fb_page_id && this.props.fb_page_token) {
      return (
        <Fragment>
          {!this.props.isPublic ? (
            <div className="centerDisconnect">
              <ThirdPartyDisconnect
                siteId={this.props.siteId}
                type="fb_page_id"
                displayName="Facebook Analytics"
              />
            </div>
          ) : null}
          <ReportSubTitle
            text="Facebook Overview"
            classes="mt-3"
            logo={facebookLogo}
          />
          <StatsBarContainer
            month={this.props.date.month}
            data={this.props.social}
          />
          <ReportSubTitle text="Growth" classes="mt-3" logo={facebookLogo} />
          <div className="row">
            <LikesPaidOrganic
              month={this.props.date.month}
              data={this.props.social.growth}
            />
            <Impressions
              month={this.props.date.month}
              data={this.props.social.impressions}
            />
            <GoogleAnalyticsSocialSourcePageViews
              clearCacheBool={this.props.clearCacheBool}
              colSize={12}
            />
            <VideoViews
              month={this.props.date.month}
              data={this.props.social.video}
              title={"Video Views"}
            />
            <LikeSources
              month={this.props.date.month}
              data={this.props.social.growth}
            />
          </div>
          <FacebookDemographics
            month={this.props.date.month}
            data={this.props.social.age}
          />
          <Location
            logo={facebookLogo}
            month={this.props.date.month}
            data={this.props.social.location}
          />
          <Engagement
            month={this.props.date.month}
            data={this.props.social.engagement}
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <ThirdPartyConnectFBAnalytics
            siteId={this.props.siteId}
            fetchAccounts={this.props.fetchAccounts}
            formatAccounts={this.props.formatAccounts}
            type="fb_page_id"
            displayName="Facebook Analytics"
          />
        </Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  ...state.social,
  siteData: { ...state.sites.show.data },
  date: { ...state.sites.show.date },
});

const mapDispatchToProps = { fetchSocialDataInsights };

const connected = connect(mapStateToProps, mapDispatchToProps)(Facebook);

Facebook.propTypes = {
  isPublic: PropTypes.bool,
  siteId: PropTypes.number,
  fbPageId: PropTypes.string,
  fbPageToken: PropTypes.string,
  data: PropTypes.array,
  fetchAccounts: PropTypes.func,
  formatAccounts: PropTypes.func,
  pageId: PropTypes.string,
  selection: PropTypes.string,
};

export { connected as Facebook };
