import React, { Component, Fragment } from "react";
import { string, number, oneOfType, bool, object } from "prop-types";
import { connect } from "react-redux";

import ShopifyConnect from "screens/sites/components/ShopifyConnect";
import ShopifySales from "screens/sites/components/ShopifySales";

import ThirdPartyDisconnect from "screens/sites/components/ThirdPartyDisconnect";

import AmazonConnect from "screens/sites/components/AmazonConnect";
import AmazonDisconnect from "screens/sites/components/AmazonDisconnect";
import AmazonSales from "screens/sites/components/AmazonSales";
import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";

class ECommerce extends Component {
  static propTypes = {
    siteId: oneOfType([string, number]),
    amazon_integration: object,
    shopify_access_token: string,
    isPublic: bool
  };

  render() {
    let isPublic = this.props.isPublic;
    let shopifyToken = this.props.shopify_access_token;
    let amazon = this.props.amazon_integration.auth_token;
    return (
      <div className="col-sm-12">
        {!this.props.isPublic && (
          <Fragment>
            <ShopifyConnect siteId={this.props.siteId} />
            <ThirdPartyDisconnect
              siteId={this.props.siteId}
              type="shopify_access_token"
              displayName="Shopify"
            />
          </Fragment>
        )}

        <ShopifySales />

        {!this.props.isPublic && (
          <Fragment>
            <AmazonConnect siteId={this.props.siteId} />
            <AmazonDisconnect />
          </Fragment>
        )}

        <AmazonSales />

        {!shopifyToken && !amazon && isPublic ? (
          <ThirdPartyFallback
            isPublic={isPublic}
            // id={this.props.fb_page_id}
            tabs={['Shopify', 'Amazon']}
            type={"E-Commerce"}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      permissions: { isPublic },
      data: { shopify_access_token, amazon_integration, id: siteId }
    }
  }
}) => ({
  siteId,
  shopify_access_token,
  amazon_integration,
  isPublic
});

export default connect(mapStateToProps)(ECommerce);
