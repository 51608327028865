import React, { Component } from "react";

import { YearGraph } from "common";

class DemoGoogleAnalyticsVisitorsByMonth extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const year = '2018';
        const displayYOY = false;
        const newVisitors = [
            {
                "month": 1,
                "label": "Jan",
                "currentData": 729,
                "previousData": 500
            },
            {
                "month": 2,
                "label": "Feb",
                "currentData": 640,
                "previousData": 500
            },
            {
                "month": 3,
                "label": "Mar",
                "currentData": 813,
                "previousData": 500
            },
            {
                "month": 4,
                "label": "Apr",
                "currentData": 792,
                "previousData": 500
            },
            {
                "month": 5,
                "label": "May",
                "currentData": 837,
                "previousData": 500
            },
            {
                "month": 6,
                "label": "Jun",
                "currentData": 685,
                "previousData": 500
            },
            {
                "month": 7,
                "label": "Jul",
                "currentData": 796,
                "previousData": 500
            },
            {
                "month": 8,
                "label": "Aug",
                "currentData": 1033,
                "previousData": 400
            },
            {
                "month": 9,
                "label": "Sep",
                "currentData": 1000,
                "previousData": 600
            },
            {
                "month": 10,
                "label": "Oct",
                "currentData": 1200,
                "previousData": 400
            },
            {
                "month": 11,
                "label": "Nov",
                "currentData": 1600,
                "previousData": 500
            },
            {
                "month": 12,
                "label": "Dec",
                "currentData": 1752,
                "previousData": 500
            }
        ]
        return (
            <YearGraph
                className={"yearly-graph"}
                title="Organic New Visitors By Month"
                yLabel="Visitors"
                displayYOY={displayYOY}
                isLoading={isFetching}
                data={newVisitors}
                year={year}
            />
        );
    }
}

export default DemoGoogleAnalyticsVisitorsByMonth;
