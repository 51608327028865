import React, { Component, Fragment } from "react";
import { func, string, number, shape, object } from "prop-types";
import { connect } from "react-redux";

import { fetchAmazonReports } from "screens/sites/actions";
import { getStartDate } from "screens/sites/helpers/date_helpers";
import { StatisticsBar } from "common";
import { Button, Panel } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_amazon.png";

class AmazonSales extends Component {
  static propTypes = {
    fetchAmazonReports: func,
    amazon_integration: object,
    siteId: number,
    amazonReports: object,
    date: shape({
      month: string,
      year: string
    })
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.amazon_integration.merchant_id) return;
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {

    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;

    if (hasDifferentMonth || hasDifferentYear) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date)
      };
      this.fetchData(params);
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchAmazonReports(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {

    if (
      !this.props.amazon_integration.marketplace_id
    )return null;


    if(this.props.amazonReports){
      if(this.props.amazonReports.error){
        //display refresh container here
        return (
          <Fragment>
            <ReportSubTitle
              text="Amazon Sales"
              classes="pt-3 clearfix"
              logo={logo}
            />
            <Panel containerClass="col-sm-12 text-center pt-3" isLoading={this.state.isFetching}>
              <Panel.Body
                style={{
                  display: "block",
                  paddingBottom: "1em"
                }}
                render={() => (
                  <div style={{
                    padding: "30px 30px 30px 30px"
                  }}>
                <span style={{
                  fontSize: "16px",
                  marginRight: "15px"
                }}>Report is currently being processed. This may take a few minutes.</span>
                    <Button classes={"btn-disconnect"} onClick={()=>{
                      this.fetchData({
                        siteId: this.props.siteId,
                        startDate: getStartDate(this.props.date)
                      });
                    }}>
                      <i style={{
                        fontSize: "11px",
                        marginRight: "5px"
                      }}
                        className="fa fa-icon fa-sync"></i>
                      Refresh
                    </Button>
                  </div>
                )}
              />
            </Panel>
          </Fragment>
        );
      }
    }

    return (
      <StatisticsBar
        renderSubtitle={() => (
          <ReportSubTitle
            text="Amazon Sales"
            classes="pt-3 clearfix"
            logo={logo}
          />
        )}
        borderColor="#f29d39"
        isLoading={this.state.isFetching}
        isPublic={false}
        data={this.props.amazonReports}
      />
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: {
        id: siteId,
        amazon_integration
      },
      ecommerceData: { amazonReports }
    }
  }
}) => {
  return {
    amazon_integration,
    date,
    siteId,
    amazonReports
  };
};

export default connect(mapStateToProps, { fetchAmazonReports })(AmazonSales);
