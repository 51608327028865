import React from "react";
import { bool, func, string } from "prop-types";
import { Button } from "common";

TemplateDetailHeader.propTypes = {
  editMode: bool,
  toggleEditMode: func,
  handleCancelChanges: func,
  handleSaveTemplate: func,
  handleChangeTemplateTitle: func,
  title: string
};

function TemplateDetailHeader(props) {
  return (
    <section className="template-detail-header">
      <input
        disabled={!props.editMode}
        className="template-detail-header__title"
        value={props.title}
        onChange={e => props.handleChangeTemplateTitle(e)}
      />
      <section className="template-detail-header__buttons">
        {!props.editMode && (
          <Button onClick={props.toggleEditMode}>Edit</Button>
        )}
        {props.editMode && (
          <Button onClick={props.handleCancelChanges}>Cancel</Button>
        )}
        {props.editMode && (
          <Button onClick={props.handleSaveTemplate}>Save</Button>
        )}
      </section>
    </section>
  );
}

export default TemplateDetailHeader;
