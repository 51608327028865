import React from "react";
import { string } from "prop-types";
import disabledReport from "../../../images/disabledReport.png";
import { connect } from "react-redux";

ThirdPartyFallback.propTypes = {
  id: string,
  type: string
};

const toLowerCase = (val) =>{
  let toLower = val.toLowerCase();
  return toLower;
}

function ThirdPartyFallback({ id, type, isMCC, isPublic, manager, tabs }) {
  if (id) return null;
  if (isMCC && type) return null;
  if (isPublic == false) {
    return null;
  }
  
  return (
    <div className="row disconnect-row">
      <div className="col-12">
        <ul className="secondary-nav">
          {tabs.length > 0 &&
            tabs.map((el, i) => {
              return (
                <li key={i}>
                  <a className={i === 0 && "active"}>{el}</a>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="col-6 disconnectImage">
        <img
          src={disabledReport}
          width={350}
          height={210}
          alt="Disabled Report"
        />
      </div>
      <div className="col-6">
        <div className="row connect-row">
          <div className="col-8 disconnect-description">
            <div>
              <span className="lrg-text">Oh no!</span>
              <p>
                {type} Reporting is not currently enabled. Please contact{" "}
                <span>
                  <a href={`mailto:${manager.email}`}>
                    {manager.first_name + " " + manager.last_name + " "}
                  </a>
                </span>
                to learn how we can provide {toLowerCase(type)} reporting and insights!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-12 connected-upsell">
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div> */}
    </div>
  );
}
ThirdPartyFallback.defaultProps = {
  isMCC: null,
  tabs:[],
};

const mapStateToProps = ({
  sites: {
    show: {
      permissions: { isPublic },
      data: {
        client: { manager }
      }
    }
  }
}) => ({
  isPublic,
  manager
});

export default connect(mapStateToProps)(ThirdPartyFallback);
