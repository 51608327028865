import React, { Component } from "react";
import { func, object } from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "screens/users/actions";
import { NavbarSearch } from "common";
import logo from "images/tresio_logo.svg";
import allianceLogo from "images/alliance_logo.jpeg";
import {isAlliance} from "../lib/api";

export default class Navbar extends Component {
  static propTypes = {
    user: object,
    router: object,
    dispatch: func
  };


  generateName = () => {
    const { first_name, last_name } = this.props.user.authentication.data;
    return `${first_name} ${last_name.charAt(0)}.`;
  };

  handleLogout = e => {
    e.preventDefault();
    this.props.authLogoutAndRedirect();
  };

  async componentDidMount() {
    var arr = this.props.router.location.pathname.split('/')
    const checkPathNamePublic = arr.includes('public');
    const checkPathNameActivate = arr.includes('activate');
    const checkPathNameDemo = arr.includes('demo')

    if (checkPathNamePublic === false && checkPathNameActivate === false && checkPathNameDemo === false) {
      const user_id = this.props.user.authentication.data.user_id || null;
      await this.props.fetchOneUser(user_id)
    }
  }

  renderNav = (profileImage) => [
    <li key="search">
      <NavbarSearch />
    </li>,
    <li key="clients">
      <NavLink to="/clients">Clients</NavLink>
    </li>,
    <li key="sites">
      <NavLink to="/sites">Sites</NavLink>
    </li>,
    <li key="todos">
      <NavLink to="/todos/me">Todos</NavLink>
      <ul>
        <li>
          <Link to="/templates">Templates</Link>
        </li>
      </ul>
    </li>,
    <li key="tools">
      <NavLink to="/tools">Tools</NavLink>
    </li>,
    <li key="userImage">
      <img key="profileImage" className="profile-image-nav" src={profileImage} alt="" />
    </li>,
    <li key="user">
      <a className="user-link">
        {this.generateName()}
      </a>
      <ul>
        {this.props.user.authentication.data.role.id === 1 && (
          <li>
            <NavLink to="/users/new">Add User</NavLink>
          </li>
        )}
        <li>
          <a onClick={this.handleLogout}>Logout</a>
        </li>
        <li>
          <NavLink to={`/users/${this.props.user.authentication.data.user_id}/editAccount`}>My Account</NavLink>
        </li>
      </ul>
    </li>
  ];

  renderLogin = () => (
    <li>
      <NavLink to="/login">Login</NavLink>
    </li>
  );





  render() {
    let profileImage = this.props.user.user_info.data.profile_image || 'https://s3-us-west-2.amazonaws.com/studio-3-crm/userDefault.png'
    return (
      <header className="navbar row container-fluid">
        <div className="col-sm-12 col-md-4">
          <NavLink className="header-branding" to="/clients">
            <img src={isAlliance ? allianceLogo : logo} alt="Studio 3 CRM" />
          </NavLink>
        </div>

        <nav className="col-sm-12 col-md-8">

          <ul>
            {this.props.user.authentication.data.token
              ? this.renderNav(profileImage)
              : this.renderLogin()}
          </ul>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = ({ user, router }) => ({
  user,
  router
});

const connected = connect(mapStateToProps, actions)(Navbar);

export { connected as Navbar };
