import React from "react";
import { bool, func, string } from "prop-types";

TemplateDetailForm.propTypes = {
  editMode: bool,
  handleAddTemplateItem: func,
  onChangeNewItem: func,
  newTemplateItem: string
};

function TemplateDetailForm(props) {
  if (!props.editMode) return null;

  return (
    <form onSubmit={e => props.handleAddTemplateItem(e)}>
      <input
        className="template-detail-form"
        placeholder="Start typing a new template item. Press enter to submit."
        value={props.newTemplateItem}
        onChange={props.onChangeNewItem}
      />
    </form>
  );
}

export default TemplateDetailForm;
