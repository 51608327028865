import React, { Component } from "react";
import { func, number, object, oneOfType, shape, string } from "prop-types";
import { getEndDate, getStartDate } from "screens/sites/helpers/date_helpers";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { StatisticsBar } from "common";
import _ from "lodash";
import { calculateChange } from "screens/sites/helpers/adwords_helpers";
import { connect } from "react-redux";
import { fetchAdwordsPerformance } from "screens/sites/actions";
import { formatStartAndEndDateFromAppHeader } from "lib/dateHelpers";
import logo from "images/icon_adwords.png";

class GoogleAdwordsOverview extends Component {
  static propTypes = {
    fetchAdwordsPerformance: func,
    adwords_id: oneOfType([string, number]),
    date: shape({
      month: string,
      year: string
    }),
    totals: object
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.adwords_id) return;
    const params = {
      adwords_id: this.props.adwords_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = prevProps => {
    const hasDifferentMonth = this.props.date.month !== prevProps.date.month;
    const hasDifferentYear = this.props.date.year !== prevProps.date.year;
    const hasDifferentId = this.props.adwords_id !== prevProps.adwords_id;
    if (!this.props.adwords_id) return;

    // Date One
    if(this.props.date.dateOneStartForCompare !== prevProps.date.dateOneStartForCompare || this.props.date.dateOneEndForCompare !== prevProps.date.dateOneEndForCompare ){
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: this.props.date.dateOneStartForCompare,
        endDate: this.props.date.dateOneEndForCompare
      };
      this.fetchData(params);
    }
    
    // Date Two
    if(this.props.date.dateTwoStartForCompare !== prevProps.date.dateTwoStartForCompare || this.props.date.dateTwoEndForCompare !== prevProps.date.dateTwoEndForCompare ){
      if(this.props.date.dateTwoStartForCompare !== null && this.props.dateTwoEndForCompare !== null){
        const params = {
          adwords_id: this.props.adwords_id,
          startDate: this.props.date.dateTwoStartForCompare,
          endDate: this.props.date.dateTwoEndForCompare
        };
        this.fetchData(params, true);
      }
    }

    if (hasDifferentId) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }

    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.props.clearCacheBool === true &&
        this.props.fetchAdwordsPerformance(
          params,
          () => {},
          this.props.clearCacheBool,
          false,
          false,
        );
    }
  };

  fetchData = (params, isSecondDate = false) => {
    if(params.startDate === undefined){
      const formattedDates = formatStartAndEndDateFromAppHeader(this.props.date)
      params.startDate = formattedDates.startOfMonth
      params.endDate = formattedDates.endOfMonth
    }
    this.mounted && this.setState({ isFetching: true });
    this.props.fetchAdwordsPerformance(
      params,
      () => this.mounted && this.setState({ isFetching: false }),
      this.props.clearCacheBool,
      isSecondDate,
      false
    );
  };

  formatTotals = (data) => {
    let clone = _.cloneDeep(data);
    if (clone.Total_conv_value) {
      if (clone.Total_conv_value.value !== "$0") {
        clone.Conversions.title = "Sales";
      } else {
        delete clone.Roas;
        delete clone.Total_conv_value;
      }
    }
    return clone;
  };

  addDolarSign = (data) => {
    let clone = _.cloneDeep(data);
    _.forOwn(clone, (value, key) => {
      if(value.title){
        clone[key].value = Number(clone[key].value).toLocaleString();
        if(value.title === "Cost" || value.title === "Revenue"){
          clone[key].value = "$" + clone[key].value;
        }
      }
    });
    return clone;
  }

  render() {
    let formattedTotals = this.formatTotals(this.props.totals);
    let formattedTotalsDateTwo = this.formatTotals(this.props.adwordsPerformanceDateTwo.totals);
    let withComparisonCalculated = calculateChange(formattedTotals,formattedTotalsDateTwo, this.props.date);
    let final = this.addDolarSign(withComparisonCalculated)
    if (!this.props.adwords_id) return null;
    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="4">Performance Report</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Clicks</td>
                  <td>Cost</td>
                  <td>Conversions</td>
                  <td>Cost / Conv</td>
                </tr>
                <tr>
                  <td>{this.props.totals.Clicks.value}</td>
                  <td>{this.props.totals.Cost.value}</td>
                  <td>{this.props.totals.Conversions.value}</td>
                  <td>{this.props.totals.Cost_conv.value}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return (
      <StatisticsBar
        renderSubtitle={() => (
          <ReportSubTitle
            text="Google Ads Overview"
            classes="pt-3 clearfix"
            logo={logo}
          />
        )}
        subtitle="Google Ads Overview"
        fromPaidAds={true}
        borderColor="#53a46a"
        isLoading={this.state.isFetching}
        data={final}
      />
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { adwords_id },
      paidAdsData: {
        adwordsPerformance: { totals },
        adwordsCampaigns,
        adwordsPerformanceDateTwo,
      }
    }
  }
}) => ({
  adwords_id,
  date,
  totals,
  adwordsCampaigns,
  adwordsPerformanceDateTwo,
});

const mapDispatchToProps = {  fetchAdwordsPerformance };

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAdwordsOverview);
