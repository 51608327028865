import axios from "axios";
import { push } from "react-router-redux";
import { rootUrl } from "lib/api";
import { startOfMonth, endOfMonth } from "lib/dateHelpers";
import { requestHeaders, getToken } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import { authLogoutAndRedirect } from "screens/users/actions";
import {
  withNoQueryString,
  startAndEndDate,
} from "screens/sites/helpers/endpoint_helpers";
import { store } from "store";

import {
  FETCHING_SITES,
  FETCHING_SITE,
  CREATING_SITE,
  FETCHING_FACEBOOK_REVIEW,
  FETCHING_GOOGLE_REVIEW,
  FETCHING_YELP_REVIEW,
  UPDATING_SITE,
  SAVE_YELP_BUSINESS_ID,
  SAVE_GOOGLE_PLACES_ID,
  FETCH_REVIEW_IDS,
  SAVE_TWITTER_HANDLE,
  UPDATE_YELP_BUSINESS,
  UPDATE_REVIEWS_DISCONNECTED_YELP,
  UPDATE_REVIEWS_DISCONNECTED_GOOGLE,
  FETCHING_YELP_BUSINESS_OPTIONS_REVIEW,
  FETCHING_SOCIAL_INSTAGRAM_BUSINESS_ID,
  FETCHING_KLAVIYO_ACCOUNT,
  FETCHING_KLAVIYO_CAMPAIGNS,
  FETCHING_MAILCHIMP_CAMPAIGNS,
  FETCHING_MAILCHIMP_ACCOUNT,
} from "./types";

export const fetchSites = (callback) => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_SITES);
  const token = getToken();
  const url = `${rootUrl}/sites`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    console.log("error", err);
  }
};

export const fetchSite = (params, callback = () => {}) => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_SITE);
  const token = getToken();
  const url = withNoQueryString(params, "/sites/");
  const headers = params.isPublic ? {} : requestHeaders(token);

  try {
    const response = await axios.get(url, headers);
    response.middleware = { fromFetchSite: true };
    dispatching(response, callback);
  } catch (err) {}
};

export const createSite = (params, callback = () => {}) => async () => {
  const dispatching = dispatchHelper(CREATING_SITE);
  const token = getToken();
  const url = `${rootUrl}/sites/new`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);

  let response = await axios.post(url, postParams, headers);
  dispatching(response, callback);
};

export const updateSite = (
  id,
  params,
  callback = () => {},
  redirect = true
) => async (dispatch, getState) => {
  let dispatching;
  params = params || {};
  if (params.yelp_business_id === null) {
    dispatching = dispatchHelper(UPDATE_REVIEWS_DISCONNECTED_YELP);
  } else if (params.google_places_id === null) {
    dispatching = dispatchHelper(UPDATE_REVIEWS_DISCONNECTED_GOOGLE);
  } else {
    dispatching = dispatchHelper(UPDATING_SITE);
  }

  const token = getToken();
  const url = `${rootUrl}/sites/${id}`;
  const headers = requestHeaders(token);
  let response = await axios.post(url, params, headers);

  const {
    router: {
      location: { hash },
    },
  } = getState();

  response.middleware = { fromFetchSite: true };

  dispatching(response, () => {
    callback();
    if (redirect) {
      dispatch(push(`/sites/${id}${hash || ""}`));
    }
  });
};

export const setPermission = ({ isPublic }) => ({
  type: "SET_PERMISSION",
  payload: isPublic,
});

export const saveTwitterHandle = (username, siteId) => async () => {
  const dispatching = dispatchHelper(SAVE_TWITTER_HANDLE);
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/twitter/${username}/${siteId}`;
  // const parameters = {
  //   params: {
  //     null: null,
  //   }
  // }
  try {
    let response = await axios.post(url, null, headers);
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};

export const fetchYelpBusinessOptions = (searchTerm, ltLng) => async () => {
  const dispatching = dispatchHelper(FETCHING_YELP_BUSINESS_OPTIONS_REVIEW);
  let url = `//lambda.studio3marketing.com/.netlify/functions/getYelp?searchTerm=${searchTerm}&lat=${ltLng.lat}&lng=${ltLng.lng}`;
  const parameters = {
    params: {
      null: null,
    },
    // crossDomain: true,
    // headers: {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // }
  };
  try {
    let response = await axios.get(
      url,
      Object.assign({}, parameters, {
        Accept: "application/json",
        "Content-Type": "application/json",
      })
    );
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};
export const fetchReview = (
  pageId,
  pageToken,
  company,
  yelp_business_id,
  google_place_id
) => async () => {
  let dispatchVar;
  company === "YELP" && (dispatchVar = FETCHING_YELP_REVIEW);
  company === "FACEBOOK" && (dispatchVar = FETCHING_FACEBOOK_REVIEW);
  company === "GOOGLE" && (dispatchVar = FETCHING_GOOGLE_REVIEW);
  const dispatching = dispatchHelper(dispatchVar);
  const token = getToken();
  const headers = requestHeaders(token);
  let url;
  company === "FACEBOOK" &&
    (url = `//lambda.studio3marketing.com/.netlify/functions/getFacebook?fbPageId=${pageId}&fbPageToken=${pageToken}`);
  company === "GOOGLE" &&
    (url = `//lambda.studio3marketing.com/.netlify/functions/getGooglePlaces?placeId=${google_place_id}`);
  company === "YELP" &&
    (url = `//lambda.studio3marketing.com/.netlify/functions/getYelpSingleBusiness?business_id=${yelp_business_id}`);
  const parameters = {
    params: {
      null: null,
    },
    // crossDomain: true,
    // headers: {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // }
  };
  try {
    let response = await axios.get(
      url,
      Object.assign({}, parameters, {
        Accept: "application/json",
        "Content-Type": "application/json",
      })
    );
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};

export const saveReviewId = (
  siteId,
  reviewId,
  company,
  callback = () => {}
) => async () => {
  let dispatching;
  company === "google" && (dispatching = dispatchHelper(SAVE_GOOGLE_PLACES_ID));
  company === "yelp" && (dispatching = dispatchHelper(SAVE_YELP_BUSINESS_ID));

  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/reviews/save/${siteId}?review_id=${reviewId}&company=${company}`;
  try {
    let response = await axios({
      method: "post",
      url: url,
      parameters: null,
      headers: headers.headers,
    });
    dispatching(response, callback);
  } catch (err) {
    console.log(err);
  }
};

export const fetchReviewId = (public_hash) => async () => {
  const dispatching = dispatchHelper(FETCH_REVIEW_IDS);
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/public/reviews/${public_hash}`;
  const parameters = {
    params: {
      null: null,
    },
  };
  try {
    let response = await axios.get(
      url,
      Object.assign({}, parameters, {
        ...headers,
      })
    );
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};

export const fetchInstagramBusinessID = (public_hash) => async () => {
  const dispatching = dispatchHelper(FETCHING_SOCIAL_INSTAGRAM_BUSINESS_ID);
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/public/instagram/businessID/${public_hash}`;
  const parameters = {
    params: {
      null: null,
    },
  };
  try {
    let response = await axios.get(url, Object.assign({}, parameters, headers));
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};

export const updateYelpBusiness = (business) => async () => {
  const dispatching = dispatchHelper(UPDATE_YELP_BUSINESS);
  // fake response for dispatch helper
  const response = {
    status: 200,
    data: { ...business },
    statusText: "OK",
  };
  try {
    let useless = await axios.get("nothing");
  } catch (err) {
    dispatching(response, () => {});
  }
};

export const fetchKlaviyoAccount = (
  params,
  public_hash,
  callback = () => {}
) => async () => {
  const dispatching = dispatchHelper(FETCHING_KLAVIYO_ACCOUNT);
  const token = getToken();
  const headers = requestHeaders(token);
  const parameters = {
    params: { ...params, checkRedis: true },
  };
  try {
    let response = await axios.get(
      `${rootUrl}/public/klaviyo/account/${public_hash}`,
      Object.assign({}, parameters, headers)
    );
    response.middleware = { fromFetchKlaviyoAccount: true };
    dispatching(response, callback);
  } catch (err) {
    console.log(err);
  }
};

export const fetchKlaviyoCampaigns = (
  params,
  public_hash,
  callback = () => {}
) => async () => {
  const dispatching = dispatchHelper(FETCHING_KLAVIYO_CAMPAIGNS);
  const token = getToken();
  const headers = requestHeaders(token);
  const parameters = {
    params: { ...params, checkRedis: true },
  };
  try {
    let response = await axios.get(
      `${rootUrl}/public/klaviyo/campaigns/${public_hash}`,
      Object.assign({}, parameters, headers)
    );
    response.middleware = { fromFetchKlaviyoCampaigns: true };
    dispatching(response, callback);
  } catch (err) {
    console.log(err);
  }
};

export const fetchMailchimpAccount = (
  params,
  site,
  callback = () => {}
) => async () => {
  const dispatching = dispatchHelper(FETCHING_MAILCHIMP_ACCOUNT);
  const token = getToken();
  const headers = requestHeaders(token);
  const parameters = {
    params: { ...params },
    // params: { ...params, checkRedis: true }
  };
  try {
    let response = await axios.get(
      `${rootUrl}/public/mailchimp/account/${site}`,
      Object.assign({}, parameters, headers)
    );
    response.middleware = { fromFetchKlaviyoAccount: true };
    dispatching(response, callback);
  } catch (err) {
    console.log(err);
  }
};

export const fetchMailchimpCampaigns = (
  params,
  site,
  callback = () => {}
) => async () => {
  const dispatching = dispatchHelper(FETCHING_MAILCHIMP_CAMPAIGNS);
  const token = getToken();
  const headers = requestHeaders(token);
  const parameters = {
    params: { ...params, checkRedis: true },
  };
  try {
    let response = await axios.get(
      `${rootUrl}/public/mailchimp/campaigns/${site}`,
      Object.assign({}, parameters, headers)
    );
    response.middleware = { fromMailchimpCampaigns: true };
    dispatching(response, callback);
  } catch (err) {
    console.log(err);
  }
};

export const updateAdwordsIdWithSubAcctId = (params, callback) => async (
  dispatch
) => {
  store.dispatch({
    type: "UPDATING_ADWORDS_ID_WITH_MCC_SUB_ACCOUNT_SUCCESS",
    payload: params,
  });
};
