
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    PieChart, Pie, Sector, Cell, ResponsiveContainer
} from 'recharts';
import _ from "lodash";

const COLORS = ['#619bf9', '#8eb6ff'];

class CustomPieChart extends Component {

    state = {
        accountLevelData: 0,
        individualValue: 0,
    }

    formatData = (data) => {
        let val = data;
        if (_.isString(val)) {
            if (data.includes('$')) {
                if (val.includes("$")) {
                    val = val.replace("$", '');
                }
                if (val.includes(",")) {
                    val = val.replace(",", '');
                }
            }
            val = parseFloat(val);
        }
        return val;
    }
    componentDidMount() {
        let accountLevelData = this.formatData(this.props.accountLevelData);
        let individualValue = this.formatData(this.props.individualValue);
        this.setState({ accountLevelData, individualValue })
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountLevelData !== prevProps.accountLevelData) {
            let accountLevelData = this.formatData(this.props.accountLevelData);
            this.setState({ accountLevelData })
        }
        if (this.props.individualValue !== prevProps.individualValue) {
            let individualValue = this.formatData(this.props.individualValue);
            this.setState({ individualValue })
        }
    }

    render() {
        let data = [
            { name: 'Account', value: this.state.accountLevelData },
            { name: 'Individual', value: this.state.individualValue }
        ]

        return (
            <PieChart width={110} height={110} className={"flex-grow-1"} onMouseEnter={this.onPieEnter}>
                <Pie
                    data={data}
                    innerRadius={37}
                    outerRadius={55}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
            </PieChart>

        )
    }
}

export { CustomPieChart };








