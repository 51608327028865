import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SearchBar } from "common";
import { handleInputChange } from "lib/utilities";
import { requestHeaders, getToken } from "lib/authHelpers";
import axios from "axios";
import { rootUrl } from "lib/api";
import _ from "lodash";
import { Button, ProfileCircle } from "common";
import { connect } from "react-redux";
import * as actions from "screens/users/actions";
import { push } from "react-router-redux";
import { store } from "store";

const RenderSitesAndClientsInfo = (props) => {
    if (props.type === "client") {
        return (
            <div onClick={props.goToSite} className={`col-12 ${props.data.id}`}>
                <Button classes="name-badge-container" link to={`/clients/${props.data.id}`}>
                    <h2 className="name-nav">{props.data.name}</h2>
                    <div className="manager-profile">
                        <ProfileCircle profileImage={props.data.profile_image} firstName={props.data.name} />
                    </div>
                </Button>
            </div>
        );
    } else {
        return (
            <div onClick={props.goToSite} className={`col-12 ${props.data.id}`}>
                <Button classes="name-badge-container" link to={`/sites/${props.data.id}`}>
                    <h2 className={`name-nav sites health-${props.data.health}`}>{props.data.name}</h2>
                </Button>
            </div>
        )
    }

};

const RenderClientsAndSitesContainer = (props) => {
    if (props.data.length !== 0) {
        return props.data.map((item, index) => {
            return <RenderSitesAndClientsInfo goToSite={props.goToSite} type={props.type} key={Math.random()} data={item} />
        });
    } else {
        return null;
    }

};


class NavbarSearch extends Component {
    static propTypes = {
        // prop: PropTypes
    }

    state = {
        searchQuery: '',
        clients: [],
        sites: [],
        visible: false,
    }

    onBlur = () => {
        setTimeout(() => {
            this.setState({ visible: false })
        }, 500);
    }


    goToSite = () => {
        setTimeout(() => {
            // store.dispatch(push(`${this.props.router.location.pathname}`));
            window.location.reload();
        }, 100);
    }


    fetchSitesAndClients = async () => {
        if (this.state.searchQuery.length > 2) {
            const token = getToken();
            const headers = requestHeaders(token);
            const url = `${rootUrl}/search?q=${this.state.searchQuery}`;
            try {
                const response = await axios.get(url, headers);
                this.setState({ sites: response.data.sites, clients: response.data.clients, visible: true })
            } catch (err) {
                console.log(err)
            }
        }
    }

    render() {
        return (
            <div>
                <SearchBar
                    value={this.state.searchQuery}
                    onBlur={this.onBlur}
                    handleChange={e => {
                        handleInputChange(this, e, "searchQuery");
                        this.fetchSitesAndClients();
                    }}
                    classes="nav-search"
                    styles={{ width: "300px", marginRight: "30px" }}
                />
                {
                    (this.state.visible) &&
                    <div className="clients-and-sites-search-container">
                        <div className="col-6">
                            <div className="col-12 nav-search-header">
                                <h3>Clients</h3>
                            </div>
                            <RenderClientsAndSitesContainer goToSite={this.goToSite} type={"client"} data={this.state.clients} />
                        </div>
                        <div className="col-6">
                            <div className="col-12 nav-search-header">
                                <h3>Sites</h3>
                            </div>
                            <RenderClientsAndSitesContainer goToSite={this.goToSite} type={"site"} data={this.state.sites} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ router }) => ({
    router
});

const connected = connect(mapStateToProps, actions)(NavbarSearch);

export { connected as NavbarSearch };
