import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import moment from "moment";


const linkifyPlugin = createLinkifyPlugin({
  target: "_blank"
});

const plugins = [linkifyPlugin];

export default class NoteItem extends Component {
  state = {
    hiddenId: null,
    editorState: EditorState.createEmpty()
  };

  onEditorChange = editorState => this.setState({ editorState });

  showNoteBody = (id) => {
    if (id === this.state.hiddenId) {
      this.setState({
        hiddenId: null
      })
    } else {
      let blocks = JSON.parse(this.props.content).blocks;

      const editorState = EditorState.createWithContent(
        convertFromRaw({ blocks, entityMap: {} })
      );

      this.setState({
        hiddenId: id,
        editorState: editorState
      })
    }
  }


  render() {
    return (
      <div
        onClick={() => this.showNoteBody(this.props.id)}
        className={
          "note-item" +
          (this.props.currentUser.user_id === this.props.user.id
            ? " my-note"
            : "")
        }>
        <div className="notes-header"  >

          <div className="note-header-date-container">
            <div className="note-header-date">
              {moment(this.props.created_at, "YYYY-MM-DD h:mm:ss").format(
                "MM/DD/YY"
              )}
            </div>
          </div>
          <div className="note-header-title-container">
            <div className="note-header-title">
              {this.props.note_title}
            </div>
          </div>
          <div className="note-header-identity-container">
            {this.props.currentUser.user_id === this.props.user.id && (
              <div onClick={e => this.props.handleEditNote(this.props.id)}>
                <i className="fas fa-pencil-alt note-functionality"></i>
              </div>
            )}
            <div className="note-header-identity">
              <span className="person-name">
                {this.props.user.first_name} {this.props.user.last_name}
              </span>
            </div>
            {
              this.props.user.profile_image !== null ?
                <img key="profileImage" className="profile-image-nav ml-3" src={this.props.user.profile_image} alt="" />
                : <img key="profileImage" className="profile-image-nav ml-3" src={"https://s3-us-west-2.amazonaws.com/studio-3-crm/userDefault.png"} alt="" />

            }
          </div>
        </div>
        {
          this.props.id === this.state.hiddenId ?
            <div className="notes-body">
              <div className={"note-content"}>
                <Editor
                  textAlignment="left"
                  stripPastedStyles={true}
                  readOnly={true}
                  onChange={this.onEditorChange}
                  plugins={plugins}
                  editorState={this.state.editorState}
                  onEditorChange={this.onEditorChange}
                />

                {this.props.currentUser.user_id === this.props.user.id && (
                  <a className="notes-delete" onClick={e => this.props.handleDeleteNote(e, this.props.id)}>
                    Delete
                  </a>
                )}
              </div>
            </div> : null
        }

      </div>
    );
  }
}

NoteItem.propTypes = {
  handleDeleteNote: PropTypes.func,
  currentUser: PropTypes.object,
  user: PropTypes.object,
  content: PropTypes.string,
  id: PropTypes.number,
  created_at: PropTypes.string
};
