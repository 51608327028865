import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button } from "common";

class ProfileImage extends Component {
  state = {
    file: "",
    filePreview: null,
    showUpload: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.file);
    if (this.props.forUserProfileImage) {
      this.props.updateUserImage(this.props.userId, formData, () => {
        window.location.reload();
      });
    } else {
      this.props.handleUpdateImage(formData);
    }
  };

  handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);

    if (file !== undefined) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          filePreview: url,
          imagePreviewUrl: reader.result
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        imagePreviewUrl: this.props.clientImage
      });
    }
  };

  toggleUpload = () => {
    this.setState({ showUpload: !this.state.showUpload });
  };

  render() {
    let userImage;
    if (this.state.filePreview !== null) {
      userImage = this.state.filePreview;
    } else {
      userImage = this.props.userImage || 'https://s3-us-west-2.amazonaws.com/studio-3-crm/userDefault.png';
    }
    return (
      <div className="file-preview">
        <img className="client-profile-image" src={this.props.clientImage || userImage} alt="" />
        {this.state.showUpload ? (
          <form>
            <input type="file" onChange={this.handleImageChange} />
            <Button onClick={this.handleSubmit}>Upload Image</Button>
            <Button grey classes={"cancel-btn"} onClick={this.toggleUpload}>Cancel</Button>
          </form>
        ) : (
            <Button onClick={this.toggleUpload}>
              <i className="fas text-white fa-image" />
            </Button>
          )}
      </div>
    );
  }
}

ProfileImage.propTypes = {
  clientImage: PropTypes.string,
  handleUpdateImage: PropTypes.func
};

export default ProfileImage;
