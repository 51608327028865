import React, { Component, Children } from "react";
import { node } from "prop-types";

import TodosHeader from "screens/todos/components/TodosHeader";
import TodosList from "screens/todos/components/TodosList";
import TodosForm from "screens/todos/components/TodosForm";

class Todos extends Component {
  static Header = TodosHeader;
  static List = TodosList;
  static Form = TodosForm;

  static propTypes = {
    children: node
  };

  render() {
    const children = Children.map(this.props.children, child =>
      React.cloneElement(child, {
        ...this.props
      })
    );

    return (
      <section className="todos-main-container">
        {children}
      </section>
    );
  }
}

export default Todos;
