import React from "react";
import { array, func, string } from "prop-types";
import { SearchBar } from "common";

TodosHeader.propTypes = {
  title: string.isRequired,
  filter: string,
  filterTeam: string,
  filterClient: string,
  filterSearch: string,
  handleFilterChange: func,
  handleFilterTeam: func,
  handleFilterClient: func,
  handleFilterSearch: func,
  data: array,
  routeType: array
};

TodosHeader.defaultProps = {
  title: ""
}

function TodosHeader(props) {
  const { teams, clients } = getTeamsAndClients(props.data);
  const [routeType] = props.routeType;
  return (
    <section className="todos-main-header">
      <SearchBar
        value={props.filterSearch}
        placeholder={`Search ${props.title}`}
        handleChange={props.handleFilterSearch}
      />
      <section className="todos-main-header__filters">
        {routeType !== "teams" && (
          <select
            className="todos-main-header__dropdown"
            value={props.filterTeam}
            onChange={props.handleFilterTeam}>
            <option value="">All Teams</option>
            {teams.map(team => <option key={team}>{team}</option>)}
          </select>
        )}
        {routeType !== "clients" && (
          <select
            className="todos-main-header__dropdown"
            value={props.filterClient}
            onChange={props.handleFilterClient}>
            <option value="">All Clients</option>
            {clients.map(client => <option key={client}>{client}</option>)}
          </select>
        )}
        <select
          className="todos-main-header__dropdown"
          value={props.filter}
          onChange={props.handleFilterChange}>
          <option disabled value="default">
            Select
          </option>
          <option value="active">Active</option>
          <option value="completed">Completed</option>
        </select>
      </section>
    </section>
  );
}

export const getTeamsAndClients = data => {
  return data.reduce(
    (acc, next) => {
      if (next.team && !acc.teams.includes(next.team.name)) {
        acc.teams = [...acc.teams, next.team.name];
      }

      if (next.client && !acc.clients.includes(next.client.name)) {
        acc.clients = [...acc.clients, next.client.name];
      }

      return acc;
    },
    {
      teams: [],
      clients: []
    }
  );
};
export default TodosHeader;
