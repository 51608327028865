import React, { Component, Fragment } from "react";
import instagram from "../../screens/social/images/instagram.png";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { DemoStatsBarContainer } from './DemoStatsBarContainer';
import { DemoEngagement } from './DemoEngagement';
import { DemoImpressions } from "./DemoImpressions";
import { DemoAudienceDemographics } from "./DemoAudienceDemographics";
import { DemoInstagramHashtags } from "./DemoInstagramHashtags";
import { DemoLocations } from "./DemoLocations";

class DemoInstagram extends Component {
    render() {
        return (
            <Fragment >
                <ReportSubTitle text="Instagram Overview" classes="mt-5" logo={instagram} />
                <DemoStatsBarContainer name={"Instagram"} />
                <DemoEngagement logo={instagram} />
                <ReportSubTitle text="Growth" classes="mt-5" logo={instagram} />
                <div className="row">
                    <DemoImpressions text={"Page Impressions"} />
                    <DemoImpressions text={"Instagram Followers Gained"} />
                    <DemoImpressions text={"Instagram Reach"} />
                    <DemoAudienceDemographics logo={instagram} />
                    <DemoLocations logo={instagram} />
                </div>
                <DemoInstagramHashtags logo={instagram} />
            </Fragment >
        )
    }
}

export { DemoInstagram };
