import axios from "axios";
import moment from "moment";
import { requestHeaders, getToken } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import {
  startAndEndDate,
  withNoQueryString,
} from "screens/sites/helpers/endpoint_helpers";
import { authLogoutAndRedirect } from "screens/users/actions";

import { FETCHING_SE_RANKINGS } from "./types";

const requestParams = (clear) => {
  let finalParameters;
  if (clear === true) {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: true,
      },
    };
  } else {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: false,
      },
    };
  }
  return finalParameters;
};

export const fetchSERankings =
  (params, callback, clearCacheBool) => async (dispatch) => {
    const updatedParams = { ...params };
    updatedParams.startDate = moment(params.startDate, "YYYY-MM-DD")
      .subtract(2, "months")
      .format("YYYY-MM-DD");
    let noCacheParams = {
      params: {
        clearCache: true,
      },
    };
    const dispatching = dispatchHelper(FETCHING_SE_RANKINGS);
    const token = getToken();
    const headers = params.isPublic ? {} : requestHeaders(token);
    let seStatsUrl = startAndEndDate(updatedParams, "/rankings/stats/");
    // seStatsUrl += "&checkRedis=true";
    let seKeywordsUrl = withNoQueryString(updatedParams, "/rankings/keywords/");
    // seKeywordsUrl += "?checkRedis=true";
    let seGroupsUrl = withNoQueryString(updatedParams, "/rankings/groups/");
    // seGroupsUrl += "?checkRedis=true";
    let finalParameters = requestParams(clearCacheBool);

    try {
      const [statsResponse, keywordsResponse, groupsResponse] =
        await Promise.all([
          axios.get(seStatsUrl, Object.assign({}, finalParameters, headers)),
          axios.get(seKeywordsUrl, Object.assign({}, noCacheParams, headers)),
          axios.get(seGroupsUrl, Object.assign({}, noCacheParams, headers)),
        ]);

      const response = {
        data: { statsResponse, keywordsResponse, groupsResponse },
        status: 200,
        middleware: { fromSeRankings: true },
      };
      console.log("Resp", response);
      dispatching(response, callback);
    } catch (err) {
      console.log("Err", err);
      // dispatch(authLogoutAndRedirect());
    }
  };

// [
//   axios.get(seStatsUrl, headers),
//   axios.get(seKeywordsUrl, headers),
//   axios.get(seGroupsUrl, headers)
// ]
