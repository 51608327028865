import React from "react";
import { bool, object, func } from "prop-types";
import Editor from "draft-js-plugins-editor";

import { Button } from "common";

CommentsForm.propTypes = {
  editorState: object,
  onChange: func,
  onSubmit: func,
  isCreating: bool,
  setRef: func
};

function CommentsForm(props) {
  return (
    <section className="todo-comments-form">
      <Editor
        placeholder="Start typing a comment..."
        editorState={props.editorState}
        onChange={props.onChange}
        ref={props.setRef}
      />
      {!props.isCreating && (
        <Button onClick={props.onSubmit}>Send</Button>
      )}
      {props.isCreating && <div className="todos-main__spinner" />}
    </section>
  );
}

export default CommentsForm;
