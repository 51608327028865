import React from "react";
import moment from "moment";
import uuid from "uuid";
import { push } from "react-router-redux";
import { store } from "store";
import { reorder } from "screens/todos/helpers/dnd_helpers";
import { EditorState, convertToRaw } from "draft-js";

export const getRouteType = pathname => {
  return pathname.split("/").slice(2);
};

export const updateTodoValue = (key, value) => ({
  [key]: value
});

export const displayFilter = (todos, filter) => {
  return todos.filter(todo => {
    return todo.status.label === filter;
  });
};

export const typeFilter = (todos, filter) => {
  return todos.filter(todo => {
    return todo.client.name.includes(filter);
  })
}

export const sortByDueDate = todos => {
  return todos.sort((a, b) => {
    const currentDate = new Date(a.due_date);
    const nextDate = new Date(b.due_date);

    if (!currentDate.getTime()) {
      if (!nextDate.getTime()) {
        return a.id - b.id;
      }
      return 1;
    }
    if (!nextDate.getTime()) {
      return -1;
    }

    return currentDate.getTime() - nextDate.getTime();
  });
};

export const calculateActiveSubtasks = subtasks => {
  const activeSubtaskCount = subtasks.filter(task => !task.completed).length;

  return `${activeSubtaskCount}`;
};

export const renderDueDateLabel = (date, today = moment().startOf("day")) => {
  if (!date) return "";
  if (typeof date === "object") {
    date = moment(date).format("YYYY-MM-DD");
  }

  const dueDate = moment(date, "YYYY-MM-DD").endOf("day");
  const dueToday = moment(today)
    .add(1, "days")
    .startOf("day");

  if (dueDate.isBefore(today)) {
    return <span className="badge badge-date text-danger">Overdue</span>;
  }

  if (moment(dueDate).isBetween(today, dueToday)) {
    return (
      <span className="badge badge-date" style={{ color: "#6ab5d8" }}>
        Due Today
      </span>
    );
  }

  return (
    <span className="badge">{moment(date, "YYYY-MM-DD").format("MMM Do")}</span>
  );
};

// The following functions are not tested
export const _handleToggleTodo = ({
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  const isDifferentUser =
    that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  if (isDifferentUser) return;

  const { id } = selectedTodo.status;
  const updatedValue = updateTodoValue("status", {
    id: id === 1 ? 2 : 1,
    label: id === 1 ? "completed" : "active"
  });
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ data: updatedData });
  that.debounceUpdateTodo(todoId, { status_id: updatedValue.status.id });
};

export const _handleChangeTitle = ({
  e,
  currentUser,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  if (currentUser !== selectedTodo.user.id) return;

  const updatedValue = updateTodoValue("title", e.target.value);
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ data: updatedData });
  that.debounceUpdateTodo(todoId, updatedValue);
};

export const _handleDateChange = ({
  dueDate,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  // const isDifferentUser =
  //   that.props.user.authentication.data.user_id !== selectedTodo.user.id;
  //
  // if (isDifferentUser) return;

  const updatedValue = updateTodoValue("due_date", dueDate);
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ dueDate, data: updatedData });
  that.debounceUpdateTodo(todoId, updatedValue);
};

export const _handleToggleSubtask = ({
  subIndex,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  // const isDifferentUser =
  //   that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  // if (isDifferentUser) return;

  const updatedSubtask = {
    ...that.state.subtasks[subIndex],
    completed: !that.state.subtasks[subIndex].completed
  };
  const newSubtasksArray = [
    ...that.state.subtasks.slice(0, subIndex),
    updatedSubtask,
    ...that.state.subtasks.slice(subIndex + 1)
  ];
  const updatedValue = updateTodoValue(
    "subtasks",
    JSON.stringify(newSubtasksArray)
  );
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ subtasks: newSubtasksArray, data: updatedData });
  that.debounceUpdateTodo(todoId, updatedValue);
};

export const _handleDeleteSubtask = ({
  subIndex,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  // const isDifferentUser =
  //   that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  // if (isDifferentUser) return;

  const newSubtasksArray = [
    ...that.state.subtasks.slice(0, subIndex),
    ...that.state.subtasks.slice(subIndex + 1)
  ];

  const updatedValue = updateTodoValue(
    "subtasks",
    JSON.stringify(newSubtasksArray)
  );

  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ subtasks: newSubtasksArray, data: updatedData });
  that.debounceUpdateTodo(todoId, updatedValue);
};

export const _handleUpdateSubtask = ({
  e,
  subIndex,
  todoId,
  todoIndex,
  selectedTodo,
  value,
  that
}) => {
  // const isDifferentUser =
  // that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  // if (isDifferentUser) return;
  const updatedSubtask = {
    ...that.state.subtasks[subIndex],
    title: value.replace(/<(?:.|\n)*?>/gm, '')
  };
  const newSubtasksArray = [
    ...that.state.subtasks.slice(0, subIndex),
    updatedSubtask,
    ...that.state.subtasks.slice(subIndex + 1)
  ];
  const updatedValue = updateTodoValue(
    "subtasks",
    JSON.stringify(newSubtasksArray)
  );
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ subtasks: newSubtasksArray, data: updatedData });
  that.debounceUpdateTodo(todoId, updatedValue);
};

export const _handleCreateSubtask = ({
  e,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  // const isDifferentUser =
  //   that.props.user.authentication.data.user_id !== selectedTodo.user.id;
  //
  // if (isDifferentUser) return;

  e.preventDefault();
  let subtasks = [];

  if (that.state.newSubtaskTitle[0] === "#") {
    subtasks = [
      ...that.state.subtasks,
      {
        id: uuid.v4(),
        title: that.state.newSubtaskTitle.slice(1),
        completed: false,
        section: true
      }
    ];
  } else {
    subtasks = [
      ...that.state.subtasks,
      {
        id: uuid.v4(),
        title: that.state.newSubtaskTitle.replace(/<(?:.|\n)*?>/gm, ''),
        completed: false,
        section: false
      }
    ];
  }

  const updatedValue = updateTodoValue("subtasks", JSON.stringify(subtasks));
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ subtasks, newSubtaskTitle: "", data: updatedData });
  that.props.updateTodo(todoId, updatedValue);
};

export const _onSelectClient = ({
  client,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  const isDifferentUser =
    that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  if (isDifferentUser) return;

  const { pathType, pathTypeId } = that.state;
  const selectedClient = client === null ? { id: null, name: "" } : client;

  const updatedValue = updateTodoValue("client", {
    id: selectedClient.id,
    name: selectedClient.name
  });
  let updatedData;

  if (pathType === "clients") {
    updatedData = that.updatedTodosData(todoIndex, {
      ...selectedTodo,
      ...updatedValue
    });

    updatedData = [
      ...updatedData.slice(0, todoIndex),
      ...updatedData.slice(todoIndex + 1)
    ];
    const url = `/todos/clients/${pathTypeId}`;

    store.dispatch(push(url));
    that.setState({ showDetails: false });
  } else {
    updatedData = that.updatedTodosData(todoIndex, {
      ...selectedTodo,
      ...updatedValue
    });
  }

  that.setState({ selectedTodoClient: selectedClient, data: updatedData });
  that.props.updateTodo(todoId, { client_id: selectedClient.id });
};

export const _onSelectTeam = ({
  team,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  const isDifferentUser =
    that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  if (isDifferentUser) return;

  const { pathType, pathTypeId } = that.state;
  const selectedTeam = team === null ? { id: null, name: "" } : team;

  const updatedValue = updateTodoValue("team", {
    id: selectedTeam.id,
    name: selectedTeam.name
  });

  let updatedData;

  if (pathType === "teams") {
    updatedData = that.updatedTodosData(todoIndex, {
      ...selectedTodo,
      ...updatedValue
    });

    updatedData = [
      ...updatedData.slice(0, todoIndex),
      ...updatedData.slice(todoIndex + 1)
    ];
    const url = `/todos/teams/${pathTypeId}`;

    store.dispatch(push(url));
    that.setState({ showDetails: false });
  } else {
    updatedData = that.updatedTodosData(todoIndex, {
      ...selectedTodo,
      ...updatedValue
    });
  }

  that.setState({ selectedTodoTeam: selectedTeam, data: updatedData });
  that.props.updateTodo(todoId, { team_id: selectedTeam.id });
};

export const _handleSelectFollowers = ({
  value,
  todoId,
  selectedTodo,
  todoIndex,
  that
}) => {
  // const isDifferentUser =
  //   that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  // if (isDifferentUser) return;

  const userIds = [];
  value.forEach(user => userIds.push(user.id));
  const params = { todo_followers: userIds };

  const followers = [];
  value.forEach(user => followers.push({ user: { ...user } }));
  const updatedValue = updateTodoValue("followers", followers);

  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ selectedTodoFollowers: value, data: updatedData });
  that.debounceUpdateTodoFollowers(todoId, params);
};

export const _handleAddTemplateData = ({
  todoId,
  todoIndex,
  selectedTodo,
  templateData,
  that
}) => {
  const parsedData = JSON.parse(templateData).map(item => ({
    ...item,
    id: uuid.v4()
  }));

  const subtasks = [...that.state.subtasks, ...parsedData];
  const updatedValue = updateTodoValue("subtasks", JSON.stringify(subtasks));
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ subtasks, newSubtaskTitle: "", data: updatedData });
  that.props.updateTodo(todoId, updatedValue);
};

export const _onDragEnd = ({
  result,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  // const isDifferentUser =
  //   that.props.user.authentication.data.user_id !== selectedTodo.user.id;

  // if (isDifferentUser) return;

  if (!result.destination) return;

  const subtasks = reorder(
    that.state.subtasks,
    result.source.index,
    result.destination.index
  );

  const updatedValue = updateTodoValue("subtasks", JSON.stringify(subtasks));

  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ subtasks, data: updatedData });
  that.debounceUpdateTodo(todoId, updatedValue);
};

export const _handleDeleteComment = async ({
  commentId,
  todoIndex,
  selectedTodo,
  that
}) => {
  const index = selectedTodo.comments.findIndex(
    comment => comment.id === commentId
  );
  const commentUserId = selectedTodo.comments[index].user.id;

  const isDifferentUser =
    that.props.user.authentication.data.user_id !== commentUserId;

  if (isDifferentUser) return;
  const comments = [
    ...selectedTodo.comments.slice(0, index),
    ...selectedTodo.comments.slice(index + 1)
  ];
  const updatedValue = updateTodoValue("comments", comments);
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ data: updatedData });
  that.props.deleteTodoComment(commentId);
};

export const _handleAddComment = async ({
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  const rawContent = convertToRaw(
    that.state.commentEditorState.getCurrentContent()
  );
  const content = JSON.stringify(rawContent);
  let updatedData;

  await that.props.createTodoComment(todoId, { content }, data => {
    const todoComments = [...selectedTodo.comments, data];
    const updatedValue = updateTodoValue("comments", todoComments);
    updatedData = that.updatedTodosData(todoIndex, {
      ...selectedTodo,
      ...updatedValue
    });
  });

  that.setState({
    commentEditorState: EditorState.createEmpty(),
    data: updatedData
  });
};

export const _handleChangeDescription = async ({
  descriptionEditorState,
  todoId,
  todoIndex,
  selectedTodo,
  that
}) => {
  // const isDifferentUser =
  //   that.props.user.authentication.data.user_id !== selectedTodo.user.id;
  //
  // if (isDifferentUser) return;

  const currentState = that.state.descriptionEditorState.getCurrentContent();
  await that.setState({ descriptionEditorState });
  const nextState = that.state.descriptionEditorState.getCurrentContent();
  const shouldUpdate = currentState !== nextState;
  const details = JSON.stringify(convertToRaw(nextState));
  const updatedValue = updateTodoValue("details", details);
  const updatedData = that.updatedTodosData(todoIndex, {
    ...selectedTodo,
    ...updatedValue
  });

  that.setState({ data: updatedData });
  shouldUpdate && that.debounceUpdateTodo(todoId, updatedValue);
};
