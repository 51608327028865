/*
fetchGoogleAnalyticsNewVisitors
fetchGoogleAnalyticsSessions
*/

import moment from "moment";

const formatReports = payload => {
  let { reports } = payload;
  reports = reports[0].data.rows;

  const sortedReports = reports.sort((a, b) => {
    const { dimensions: [thisMonth, thisYear] } = a;
    const { dimensions: [nextMonth, nextYear] } = b;
    return thisYear - nextYear || thisMonth - nextMonth;
  });

  let formattedData = {};

  sortedReports.forEach(obj => {
    let [ month, year ] = obj.dimensions;
    year = moment(year, "YYYY").format("'YY");
    formattedData[moment(month, "MM").format("MMM") + year] = parseInt(
      obj.metrics[0].values[0],
      0,
    );
  });

  return formattedData;
};

export const googleAnalyticsSessions = ({ dispatch }) => next => action => {
  if (!action.fromGASessions) {
    return next(action);
  }

  let formattedData = formatReports(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGASessions: false,
  };

  dispatch(newAction);
};
