import _ from "lodash";
import React, { Component } from "react";
import { string, arrayOf, func } from "prop-types";
import ReactTooltip from "react-tooltip";
import moment from "moment";
const headers = [
  "Lead Type",
  "Source",
  "Phone Name",
  "Tracking #",
  "Medium",
  "Date",
];

const tableItems = [
  "type",
  "source",
  "customer_name",
  "tracking_phone_number",
  "medium",
  "start_time",
];

const orderMap = {
  type: 1,
  source: 2,
  customer_name: 3,
  tracking_phone_number: 4,
  medium: 5,
  start_time: 6,
};

const nameMap = {
  type: "Lead Type",
  source: "Source",
  customer_name: "Phone Name",
  tracking_phone_number: "Tracking #",
  medium: "Medium",
  start_time: "Date",
};

const formatData = (data) => {
  return data.map((n) => ({
    ...n,
    rowData: Object.entries(n)
      .filter((n) => tableItems.includes(n[0]))
      .map((n) => ({
        name: n[0],
        friendly: nameMap[n[0]],
        value: n[1],
        order: orderMap[n[0]],
      }))
      .sort((a, b) => a.order - b.order),
  }));
};

class CallRailTable extends Component {
  static defaultProps = {
    classes: "",
  };

  state = { enableTooltip: false };

  render() {
    const { data } = this.props;
    const formattedTableData = formatData(data);

    return (
      <table className={`responsive-table ${this.props.classes}`}>
        <thead className="responsive-thead">
          <tr className="responsive-tr">
            {headers.map((header) => {
              return (
                <th scope="col" className="responsive-th" key={header}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="responsive-tbody">
          {formattedTableData.map((row, index) => {
            return (
              <tr
                className="responsive-tr"
                key={index}
                onClick={() => this.props.setParentState(row)}
              >
                {row.rowData.map((n, i) => (
                  <td
                    data-label={n.name}
                    key={i}
                    className="responsive-td"
                    data-tip
                    data-for={"meta-" + i}
                  >
                    {n.name === "start_time"
                      ? moment(n.value).format("MMM Do h:mm:ss a")
                      : n.value}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export { CallRailTable };
