import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "screens/users/actions";
import { Button, FormGroup } from "common";


class LoginForm extends Component {
    render() {
        if (this.props.visible === false) {
            return (
                <form onSubmit={this.props.handleSubmit}>
                    <FormGroup
                        label="Email"
                        type="email"
                        value={this.props.email}
                        onChange={e => this.props.handleInputChange(this.props.UsersAuthenticateComponent, e, "email")}
                    />
                    <FormGroup
                        label="Password"
                        type="password"
                        value={this.props.password}
                        onChange={e => this.props.handleInputChange(this.props.UsersAuthenticateComponent, e, "password")}
                    />
                    <Button
                        onClick={this.props.handleSubmit}
                        disabled={this.props.isDisabled}
                        grey={this.props.isDisabled}
                        style={{ marginTop: "10px", marginRight: "10px" }}
                    >
                        Login
                        </Button>
                    <Button
                        onClick={this.props.showForgotPasswordForm}
                        disabled={false}
                        grey={this.props.isDisabled}
                        style={{ marginTop: "10px" }}
                    >
                        Forgot Password
                        </Button>
                </form>
            );
        } else {
            return null;
        }
    }
}
// LoginForm.propTypes = {
//   user: PropTypes.object,
//   authenticateUser: PropTypes.func,
//   lastVisited: PropTypes.string
// };

const mapStateToProps = ({ user, lastVisited }) => ({ user, lastVisited });

const connected = connect(mapStateToProps, actions)(LoginForm);

export { connected as LoginForm };
