import React, { Component } from "react";
import {
  determineChange,
  determinePositions,
  rankingsMap
} from "screens/sites/helpers/rankings_helpers";

import PropTypes from "prop-types";
import SEGroupsListItemKeyword from "./SEGroupsListItemKeyword";
import _ from "lodash";

export default class SEGroupsListItem extends Component {
  state = {
    displayKeywords: false
  };

  toggleKeywords() {
    this.setState({
      displayKeywords: !this.state.displayKeywords
    });
  }

  render() {
    if (!this.props.name) return <div />;
    const modified = this.props.keywords.reduce((all, item, index) => {
      if (!all) {
        all = [];
      }
      const positions = determinePositions(
        _.orderBy(item.positions, ["date"], ["asc"])
      );
      const change = determineChange(positions);
      const { changeClass, movement } = rankingsMap(positions, change);
      item.changeClass = changeClass;
      item.movement = movement;
      item.change = change;
      item.positionThisMonth = positions.positionThisMonth;
      item.positionLastMonth = positions.positionLastMonth;
      all.push(item);
      return all;
    }, []);
    const sortedByPositionThisMonth = _.orderBy(
      modified,
      ["positionThisMonth"],
      ["asc"]
    );

    const filteredByPositionZero = sortedByPositionThisMonth.filter(
      item => item.positionThisMonth === 0
    );

    const allPositionsExceptZero = sortedByPositionThisMonth.filter(
      item => item.positionThisMonth !== 0
    );

    filteredByPositionZero.forEach(item => {
      allPositionsExceptZero.push(item);
    });
    
    return (
      <div
        className={
          "se-group-item " + (this.state.displayKeywords ? "active" : "")
        }
      >
        <h4 onClick={() => this.toggleKeywords()}>{this.props.name}</h4>
        <div>
          {allPositionsExceptZero.map((val, i) => {
            return <SEGroupsListItemKeyword key={i} {...val} />;
          })}
        </div>
      </div>
    );
  }
}

SEGroupsListItem.propTypes = {
  name: PropTypes.string,
  keywords: PropTypes.array
};
