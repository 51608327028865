import React, { Component } from "react";
import { func, array, string, shape, oneOfType, number } from "prop-types";
import { connect } from "react-redux";
import { calculateChange } from "screens/sites/helpers/adwords_helpers";
import { fetchFbAdCampaigns } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { formatFbOverviewData } from "screens/sites/helpers/facebook_helpers";
import { StatisticsBar } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_facebook.png";
import _ from "lodash";

class FacebookAdOverview extends Component {
  static propTypes = {
    fetchFbAdCampaigns: func,
    fb_ads_id: oneOfType([string, number]),
    date: shape({
      month: string,
      year: string
    }),
    fbData: array
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.fb_ads_id) return;
    const params = {
      fb_ads_id: this.props.fb_ads_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = prevProps => {
    const hasDifferentMonth = this.props.date.month !== prevProps.date.month;
    const hasDifferentYear = this.props.date.year !== prevProps.date.year;
    const hasDifferentId = this.props.fb_ads_id !== prevProps.fb_ads_id;

    if (!this.props.fb_ads_id) return;

    if(this.props.date.dateOneStartForCompare !== prevProps.date.dateOneStartForCompare || this.props.date.dateOneEndForCompare !== prevProps.date.dateOneEndForCompare ){
      const params = {
        fb_ads_id: this.props.fb_ads_id,
        startDate: this.props.date.dateOneStartForCompare,
        endDate: this.props.date.dateOneEndForCompare
      };
      this.fetchData(params);
    }
    if(this.props.date.dateTwoStartForCompare !== prevProps.date.dateTwoStartForCompare || this.props.date.dateTwoEndForCompare !== prevProps.date.dateTwoEndForCompare ){
      if(this.props.date.dateTwoStartForCompare !== null && this.props.date.dateTwoEndForCompare !== null){
        const params = {
          fb_ads_id: this.props.fb_ads_id,
          startDate: this.props.date.dateTwoStartForCompare,
          endDate: this.props.date.dateTwoEndForCompare
        };
        this.fetchData(params, true);
      }
    }

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        fb_ads_id: this.props.fb_ads_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      const params = {
        fb_ads_id: this.props.fb_ads_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.props.clearCacheBool === true && this.props.fetchFbAdCampaigns(
        params,
        () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = (params, isSecondDate = false)  => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchFbAdCampaigns(
      params,
      () => this.mounted && this.setState({ isFetching: false }),
      this.props.clearCacheBool,
      isSecondDate
    ); 
  };

  checkForRoas = () => {
    let isOfTypeEcommerce = false
    if(this.props.fbData.length > 0){
      this.props.fbData.forEach(item => {
        if(item.roas.value !== "-"){
          isOfTypeEcommerce = true;
        }
      })
    }
    return isOfTypeEcommerce;
  }

  checkForNaN =(data) => {
      _.forOwn(data, (value, key) => {
          if(value.value == "$NaN"){
            value.value = 0;
          }
          if(value.value == "NaN"){
            value.value = 0;
          }
        });
  }

  formatRoas = (data) => {
    if (_.isNumber(data.roas.value)) {
      data.roas.value = data.roas.value.toFixed(2);
    }
  }

  calculateChange = (dataDateOne, dataDateTwo) => {
    const addField = (data) => {
      _.forOwn(data, (value, key) => {
       value.positiveChange = false;
       value.change = "0.00";
       if(value.value === "NaN%"){
         value.value = "0";
       }
      });
    }
    addField(dataDateOne);
    addField(dataDateTwo);    
  }

  addDolarSign = (data) => {
    let clone = _.cloneDeep(data);
    _.forOwn(clone, (value, key) => {
      if(value.title){
        if(clone[key].value){
          clone[key].value = Number(clone[key].value).toLocaleString();
          if(value.title === "Cost" || value.title === "Revenue" || value.title === "Spend" || value.title === "CPC"){
            clone[key].value = "$" + clone[key].value;
          }
        }
      }
    });
    return clone;
  }

  render() {
    if (!this.props.fb_ads_id) return null;
    if (this.props.fbError !== "") return <div className="row text-center"><h2 className="col-12">No Facebook data for this month</h2></div>;
    // if (this.props.fbData.length === 0 && this.props.fb_ads_id) return <div className="row text-center"><h2 className="col-12">No Facebook data for this month</h2></div>;
    let isOfTypeEcommerce = this.checkForRoas();
    const data = formatFbOverviewData(this.props.fbData, false, null, isOfTypeEcommerce);
    const dataDateTwo = formatFbOverviewData(this.props.fbDataDateTwo, false, null, isOfTypeEcommerce);
    this.checkForNaN(data);
    this.checkForNaN(dataDateTwo);
    const dataForPrint = formatFbOverviewData(this.props.fbData, false, null, isOfTypeEcommerce);
    if(!dataForPrint.conversions){
      dataForPrint.conversions = {
        title: "Conversion",
        value: "0"
      }
    } 
    this.formatRoas(data);
    this.formatRoas(dataDateTwo);
    let withChangeCalculated = calculateChange(data, dataDateTwo, this.props.date);
    let final  = this.addDolarSign(withChangeCalculated);
    if(isOfTypeEcommerce){
      if(final.conversions){
        delete final.conversions;
      }
    }
    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="6">Facebook Ads Overview</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Conversions</td>
                  <td>CPC</td>
                  <td>CTR</td>
                  <td>ROAS</td>
                  <td>Spend</td>
                  <td>Visitors</td>
                  <td>Sales</td>
                  <td>Revenue</td>
                </tr>
                <tr>
                  <td>{dataForPrint.conversions.value}</td>
                  <td>{dataForPrint.cpc.value}</td>
                  <td>{dataForPrint.ctr.value}</td>
                  <td>{dataForPrint.roas.value}</td>
                  <td>{dataForPrint.spend.value}</td>
                  <td>{dataForPrint.visitors.value}</td>
                  <td>{dataForPrint.sales.value}</td>
                  <td>{dataForPrint.revenue.value}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return (
      <StatisticsBar
        renderSubtitle={() => (
          <ReportSubTitle
            text="Facebook Overview"
            classes="pt-3 clearfix"
            logo={logo}
          />
        )}
        subtitle="Facebook Overview"
        borderColor="#425a94"
        isLoading={this.state.isFetching}
        data={final}
      />
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { fb_ads_id },
      paidAdsData: { fbCampaigns: { data: fbData, error: fbError } },
      paidAdsData: { fbCampaignsDateTwo:  { data: fbDataDateTwo, error: fbErrorDateTwo }}
    }
  }
}) => ({
  fb_ads_id,
  date,
  fbData,
  fbError,
  fbDataDateTwo,
  fbErrorDateTwo,
});

export default connect(mapStateToProps, { fetchFbAdCampaigns })(
  FacebookAdOverview
);
