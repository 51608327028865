import React, { Component } from 'react'

export default class ReviewPanelButtons extends Component {
    render() {
        return (
            <div className="button-container">
                {
                    this.props.name === "Yelp" && <button className="yelp-manual-connect" onClick={() => this.props.displayManualConnectYelp()}>?</button>
                }
                <button className="btn btn-secondary" onClick={() => { this.props.showInputFunc() }}>Connect {this.props.name}</button>
            </div>
        )
    }
}
