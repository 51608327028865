import React, { Component } from "react";
import { monthSorter, removeNegative } from "../../../lib/socialHelpers";

import { StatisticsBar } from "common";
import moment from "moment";

const stuctureData = (data, category, state, api_data_name) => {
  let stateCopy = Object.assign({}, state);
  let changeInValues = 0;
  let positiveChange;
  const checkMonths = [];
  let dataArr = [];
  let dataToStructure;

  const setValue = (length) => {
    if (length === 2) {
      if (
        (dataArr[0] || {}).name === "Jan" &&
        (dataArr[1] || {}).name === "Dec"
      ) {
        return (stateCopy.value = dataArr[0].value.toLocaleString());
      }
      return (stateCopy.value = dataArr[1].value.toLocaleString());
    }
    if (length === 3) {
      if (
        (dataArr[0] || {}).name === "Feb" &&
        (dataArr[1] || {}).name === "Jan"
      ) {
        return (stateCopy.value = dataArr[0].value.toLocaleString());
      }
      return (stateCopy.value = dataArr[1].value.toLocaleString());
    }
  };

  const correctDataObj = (data, api_data_name) => {
    let values;
    data.forEach((category) => {
      if (category.name === api_data_name) {
        values = category.values;
      }
    });
    return values;
  };

  const objectCreation = (checkMonthsArr) => {
    checkMonthsArr.forEach((item) => {
      var obj = { name: item, value: 0 };
      dataArr.push(obj);
    });
  };

  const structureMonths = (data, category) => {
    let objectTimeName = "end_time";
    category === "engagement" && (objectTimeName = "created_time");
    data.forEach((item) => {
      item.month_name = moment(item[objectTimeName]).format("MMM");
      if (!checkMonths.includes(item.month_name)) {
        checkMonths.push(item.month_name);
      }
    });
  };

  const calculateChange = (data, stateCopy) => {
    if (dataArr.length === 0) {
      stateCopy.change = 0;
      stateCopy.positiveChange = false;
      stateCopy.value = 0;
    }
    if (dataArr.length === 1) {
      stateCopy.change = 0;
      stateCopy.positiveChange = false;
      stateCopy.value = stateCopy.value = dataArr[0].value.toLocaleString();
    }
    if (dataArr.length === 2) {
      dataArr = monthSorter(dataArr);
      let changeInValues = dataArr[1].value - dataArr[0].value;
      changeInValues = removeNegative(changeInValues.toLocaleString());
      stateCopy.change = changeInValues;
      stateCopy.value = setValue(2);
      dataArr[1] > dataArr[0]
        ? (stateCopy.positiveChange = false)
        : (stateCopy.positiveChange = true);
    }
    if (dataArr.length === 3) {
      dataArr = monthSorter(dataArr);
      dataArr.shift();
      let changeInValues = dataArr[1].value - dataArr[0].value;
      changeInValues = removeNegative(changeInValues.toLocaleString());
      stateCopy.change = changeInValues;
      stateCopy.value = setValue(3);
      dataArr[1] > dataArr[0]
        ? (stateCopy.positiveChange = false)
        : (stateCopy.positiveChange = true);
    }
    return stateCopy;
  };

  category === "engagement"
    ? (dataToStructure = data)
    : (dataToStructure = correctDataObj(data, api_data_name));

  // Checks Start
  if (dataToStructure === undefined) {
    return stateCopy;
  }
  // Checks End
  structureMonths(dataToStructure, category);
  if (category === "likes") {
    const dataNested = [[], [], []];
    dataToStructure.forEach((item) => {
      const index = checkMonths.indexOf(item.month_name);
      if (dataNested[index]) {
        dataNested[index].push(item);
      }
    });
    let indexValueOne = 1;
    let indexValueTwo = 2;
    if (dataNested[2].length === 0) {
      indexValueOne = 0;
      indexValueTwo = 1;
    }
    if (dataNested[1].length === 0) {
      indexValueOne = 0;
      indexValueTwo = 0;
    }
    let secondMonth =
      dataNested[indexValueOne].slice(-1)[0].value -
      dataNested[indexValueOne][0].value;
    let thirdMonth =
      dataNested[indexValueTwo].slice(-1)[0].value -
      dataNested[indexValueTwo][0].value;
    let changeInlikes = 0;
    let positiveChange = null;
    if (thirdMonth > secondMonth) {
      changeInlikes = thirdMonth - secondMonth;
      positiveChange = true;
    } else {
      changeInlikes = secondMonth - thirdMonth;
      positiveChange = false;
    }
    stateCopy.value = thirdMonth.toLocaleString();
    stateCopy.change = removeNegative(changeInlikes.toLocaleString());
    stateCopy.positiveChange = positiveChange;
  }
  if (category === "impressions") {
    objectCreation(checkMonths);
    dataToStructure.forEach((item) => {
      const index = checkMonths.indexOf(item.month_name);
      dataArr[index].value += parseInt(item.value, 10);
    });
    dataArr.length == "3" && dataArr.shift();
    stateCopy = calculateChange(dataArr, stateCopy);
  }
  if (category === "engagement") {
    objectCreation(checkMonths);
    dataToStructure.forEach((post) => {
      if (post.insights) {
        post.insights.data.forEach((insight) => {
          if (insight.name === "post_reactions_by_type_total") {
            let reactionTotal = insight.values[0].value;
            for (let keys in reactionTotal) {
              const index = checkMonths.indexOf(post.month_name);
              dataArr[index].value += reactionTotal[keys];
            }
          }
        });
      }
    });
    stateCopy = calculateChange(dataArr, stateCopy);
  }
  if (category === "video") {
    objectCreation(checkMonths);
    dataToStructure.forEach((item) => {
      const index = checkMonths.indexOf(item.month_name);
      dataArr[index].value += item.value;
    });
    dataArr.shift();
    stateCopy = calculateChange(dataArr, stateCopy);
  }
  return stateCopy;
};

class StatsBarContainer extends Component {
  state = {
    page_likes: {
      title: "Page Likes",
      value: "...",
      change: "0",
      positiveChange: false,
    },
    page_impressions: {
      title: "Page Impressions",
      value: "...",
      change: "0",
      positiveChange: false,
    },
    reactions: {
      title: "Reactions",
      value: "...",
      change: "0",
      positiveChange: false,
    },
    video_views: {
      title: "Video Views",
      value: "...",
      change: "0",
      positiveChange: false,
    },
  };

  componentDidUpdate(prevProps) {
    if (this.props.month !== prevProps.month) {
      this.setState((state) => {
        state.page_likes.value = "...";
        state.page_likes.change = "0";
        state.page_likes.positiveChange = false;
        state.page_impressions.value = "...";
        state.page_impressions.change = "0";
        state.page_impressions.positiveChange = false;
        state.reactions.value = "...";
        state.reactions.change = "0";
        state.reactions.positiveChange = false;
        state.video_views.value = "...";
        state.video_views.change = "0";
        state.video_views.positiveChange = false;
        return state;
      });
    }
    if (this.props.data.likes !== prevProps.data.likes) {
      const data = this.props.data.likes;
      const page_likes = stuctureData(
        data,
        "likes",
        this.state.page_likes,
        "page_fans"
      );
      this.setState({ page_likes });
    }
    if (this.props.data.impressions !== prevProps.data.impressions) {
      let data = this.props.data.impressions;
      const page_impressions = stuctureData(
        data,
        "impressions",
        this.state.page_impressions,
        "page_impressions"
      );
      this.setState({ page_impressions });
    }
    if (this.props.data.engagement !== prevProps.data.engagement) {
      let data = this.props.data.engagement;
      const reactions = stuctureData(
        data,
        "engagement",
        this.state.reactions,
        "reactions"
      );
      this.setState({ reactions });
    }
    if (this.props.data.video !== prevProps.data.video) {
      let data = this.props.data.video;
      const video_views = stuctureData(
        data,
        "video",
        this.state.video_views,
        "page_video_views"
      );
      this.setState({ video_views });
    }
  }
  render() {
    const dataWrapper = {
      data: { ...this.state },
    };
    return (
      <div className="row">
        <StatisticsBar data={dataWrapper.data} />
      </div>
    );
  }
}

export { StatsBarContainer };
