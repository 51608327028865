import React, { Component } from "react";
import { func, number, shape, string, bool, array } from "prop-types";
import { connect } from "react-redux";
import { store } from "store";
import { YearGraph } from "common";
import { fetchGoogleAnalyticsNewVisitors } from "screens/sites/actions";

class PrimarySiteGoogleAnalyticsVisitorsByMonth extends Component {
  static propTypes = {
    fetchGoogleAnalyticsNewVisitors: func,
    newVisitors: array,
    siteId: number,
    ga_property_id: string,
    date: shape({
      month: string,
      year: string,
    }),
    public_hash: string,
    isPublic: bool,
    displayYOY: bool,
  };

  static defaultProps = {
    displayYOY: false,
  };

  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: this.props.displayYOY
        ? this.props.date.year - 1 + "-01-01"
        : this.props.date.year + "-01-01",
      endDate: this.props.date.year + "-12-31",
      hash: this.props.public_hash,
      isPublic: this.props.isPublic,
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = (nextProps) => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentDisplayYOY =
      this.props.displayYOY !== nextProps.displayYOY;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentDisplayYOY) {
      const params = {
        siteId: this.props.siteId,
        startDate: this.props.displayYOY
          ? this.props.date.year - 1 + "-01-01"
          : this.props.date.year + "-01-01",
        endDate: this.props.date.year + "-12-31",
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: this.props.displayYOY
          ? this.props.date.year - 1 + "-01-01"
          : this.props.date.year + "-01-01",
        endDate: this.props.date.year + "-12-31",
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.props.clearCacheBool === true &&
        this.props.fetchGoogleAnalyticsNewVisitors(
          params,
          () =>
            store.dispatch({
              type: "SET_PRIMARY_SITE_TABLES_LOAD_STATE",
              payload: { primarySiteNewVisitorsLoadState: false },
            }),
          this.props.clearCacheBool,
          "FETCHING_PRIMARY_SITE_GA_NEW_VISITORS"
        );
    }
  };

  fetchData = (params) => {
    if (!params.siteId) return;
    this.mounted &&
      store.dispatch({
        type: "SET_PRIMARY_SITE_TABLES_LOAD_STATE",
        payload: { primarySiteNewVisitorsLoadState: true },
      });

    this.props.fetchGoogleAnalyticsNewVisitors(
      params,
      () =>
        store.dispatch({
          type: "SET_PRIMARY_SITE_TABLES_LOAD_STATE",
          payload: { primarySiteNewVisitorsLoadState: false },
        }),
      false,
      "FETCHING_PRIMARY_SITE_GA_NEW_VISITORS"
    );
  };

  render() {
    return (
      <YearGraph
        downloadCSV={this.props.downloadCSV}
        downloadCSVKey={this.props.downloadCSVKey}
        downloading={this.props.downloading}
        setDownloading={this.props.setDownloading}
        className={"yearly-graph no-padding padding-right"}
        title="Organic New Visitors By Month"
        yLabel="Visitors"
        displayYOY={this.props.displayYOY}
        isLoading={this.props.primarySiteNewVisitorsLoadState}
        data={this.props.newVisitors}
        year={this.props.date.year}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    date: state.sites.show.date,
    isPublic: state.sites.show.permissions.isPublic,
    public_hash: state.sites.show.data.public_hash,
    siteId: state.sites.show.data.id,
    ga_property_id: state.sites.show.data.ga_property_id,
    newVisitors: state.sites.show.primarySiteAnalyticsData.newVisitors,
    primarySiteNewVisitorsLoadState:
      state.sites.show.primarySiteAnalyticsData.primarySiteNewVisitorsLoadState,
  };
};

export default connect(mapStateToProps, {
  fetchGoogleAnalyticsNewVisitors,
})(PrimarySiteGoogleAnalyticsVisitorsByMonth);
