import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IframeDropdown } from "common";
import Slider from "react-slick";


let options = [
    "DESKTOP FEED STANDARD",
    "INSTAGRAM STANDARD",
    "INSTAGRAM STORY",
];

var iframeStyle = {
    border: "none",
};

const Iframe = ({ source, format }) => {
    let defaultWidth = "320";
    let defaultHeight = "520";
    if (format === options[0]) {
        defaultWidth = "502";
        defaultHeight = "520";
    }
    if (!source) {
        return <div>Loading...</div>;
    } else {
        let url = source.split(/"/)[1];
        let formatURL = url.replace('amp;', '');
        return (
            <iframe src={formatURL} style={iframeStyle} width={defaultWidth} height={520}></iframe>
        );
    }
};


const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

export default class AdsPreview extends Component {

    state = {
        format: options[0]
    }

    liftSelection = (format) => {
        //Clear Redux state
        this.props.clearAdPreviewState();
        this.setState({
            format
        }, () => {
            let formattedData = format.replace(' ', '_');
            if (formattedData.includes(' ')) {
                formattedData = formattedData.replace(' ', '_')
            }
            if (Array.isArray(this.props.creatives)) {
                if (this.props.creatives.length > 0) {
                    this.props.creatives.forEach(creativeId => {
                        this.props.fetchAdPreview(creativeId, () => { }, this.props.clearCacheBool, formattedData);
                    });
                }
            } else {
                this.props.fetchAdPreview(this.props.creatives, () => { }, this.props.clearCacheBool, formattedData);
            }
        });
    }

    getCreativeLength = () => {
        if (Array.isArray(this.props.creatives)) {
            if (this.props.creatives.length > 0) {
                let amount = this.props.creatives.length;
                if (this.props.facebookAdPreviews.length > 0) {
                    let iframesForView = this.props.facebookAdPreviews.slice(-amount);
                    return iframesForView;
                }
                else {
                    return []
                }
            }
            else {
                return []
            }
        } else {
            return [];
        }
    }


    render() {
        let iframeData = this.getCreativeLength();
        if (this.props.creatives.length > 0) {
            return (
                <div className="ad-preview-modal">
                    <IframeDropdown placeholder={"Select an ad"} data={options} liftSelection={this.liftSelection} />
                    <Slider {...settings}>
                        {
                            iframeData.map((ad, i) => <Iframe key={i} format={this.state.format} source={ad} />)
                        }
                    </Slider>
                </div>
            )
        } else {
            return null;
        }

    }
}

