import React from "react";

const DevelopmentBanner = () => {
    return (
        process.env.NODE_ENV === 'development' ?
            <div className="devBanner"></div> :
            null
    )
}

export default DevelopmentBanner;