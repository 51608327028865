import React, { Component } from 'react'
import PropTypes from "prop-types";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import { handleInputChange } from "lib/utilities";
import DatePicker from "react-datepicker";

import moment from "moment";
import { Button, FormGroup } from "common";


const linkifyPlugin = createLinkifyPlugin({ target: "_blank" });
const plugins = [linkifyPlugin];

export default class EditNote extends Component {
    state = {
        note_title: "",
        content: "",
        created_at: "",
        editorState: EditorState.createEmpty(),
    }

    onEditorChange = editorState => this.setState({ editorState });

    handleDateChange = (date) => {
        const DateFormmated = moment(date).format('DD-MM-YYYY')
        const DBFormat = moment(date).format('YYYY-MM-DD hh:mm:ss');
        this.setState({
            created_at: DBFormat,
            formtted_date: DateFormmated
        });
    }
    componentDidMount() {
        let note = this.props.notes.find(note => note.id === this.props.editId)
        this.setState({
            note_title: note.note_title,
            content: note.content,
            created_at: note.created_at,
            editorState: EditorState.createWithContent(
                convertFromRaw(JSON.parse(note.content))
            ),
        })
    }

    render() {
        return (
            <div className="p-4" >
                <div className="add-note-container">
                    <label className="note-form-label">Title</label>
                    <input
                        className={"add-note-form"}
                        type={"text"}
                        value={this.state.note_title}
                        onChange={e => handleInputChange(this, e, "note_title")}
                        placeholder={"Add Title"}
                    />
                    <label className="note-form-label">Date</label>
                    <DatePicker
                        ref={datePicker => (this.datePicker = datePicker)}
                        placeholderText={"Click to select a date"}
                        isClearable
                        className={"hover-pointer"}
                        selected={moment(this.state.created_at)}
                        onChange={this.handleDateChange}
                        dateFormat="MM/DD/YYYY"
                    />
                    <label className="note-form-label">Notes</label>
                    <Editor
                        textAlignment="left"
                        stripPastedStyles={true}
                        placeholder="Add long form notes"
                        onChange={this.onEditorChange}
                        plugins={plugins}
                        onEditorChange={this.state.onEditorChange}
                        editorState={this.state.editorState}
                    />
                </div>
                <Button classes={"mt-4 mb-1"} onClick={() => this.props.editNote({
                    note_title: this.state.note_title,
                    rawContent: JSON.stringify(
                        convertToRaw(this.state.editorState.getCurrentContent()),
                    ),
                    created_at: this.state.created_at,
                    id: this.props.editId,
                }, () => { this.props.handleEditNote(null) })}>
                    Edit Note
                </Button>
                <Button classes={"ml-3 mt-4 mb-1 btn-disabled font-black"} onClick={this.props.hideForm}>
                    Cancel
            </Button>
            </div >
        )
    }
}
