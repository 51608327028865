import React from "react";
import { func, number, string } from "prop-types";

ContactItem.propTypes = {
  handleInputChange: func.isRequired,
  deleteContact: func.isRequired,
  index: number.isRequired,
  name: string,
  title: string,
  email: string,
  altEmail: string,
  altPhone: string,
  phone: string
};

function ContactItem(props) {
  return (
    <div className="contact-item">
      <div className="input-wrap">
        <img src="/img/contacts/icon-contact.png" alt="" />
        <input
          data-testid="name-input"
          type="text"
          className="contact-name"
          onChange={e => props.handleInputChange(e, props.index, "name")}
          value={props.name}
          placeholder="Name"
        />
        <br />
        <input
          type="text"
          className="contact-title"
          onChange={e => props.handleInputChange(e, props.index, "title")}
          value={props.title}
          placeholder="Title"
        />
        <br />
      </div>
      <div className="input-wrap">
        <img src="/img/contacts/icon-email.png" alt="" />
        <input
          type="text"
          className="contact-email"
          onChange={e => props.handleInputChange(e, props.index, "email")}
          value={props.email}
          placeholder="Email"
        />
        <br />
      </div>
      <div className="input-wrap">
        <img src="/img/contacts/icon-email.png" alt="" />
        <input
          type="text"
          className="contact-email"
          onChange={e => props.handleInputChange(e, props.index, "altEmail")}
          value={props.altEmail}
          placeholder="Alternate Email"
        />
        <br />
      </div>
      <div className="input-wrap">
        <img src="/img/contacts/icon-phone.png" alt="" />
        <input
          type="text"
          className="contact-phone"
          onChange={e => props.handleInputChange(e, props.index, "altPhone")}
          value={props.altPhone}
          placeholder="Office Number"
        />
        <br />
      </div>
      <div className="input-wrap">
        <img src="/img/contacts/icon-phone.png" alt="" />
        <input
          type="text"
          className="contact-phone"
          onChange={e => props.handleInputChange(e, props.index, "phone")}
          value={props.phone}
          placeholder="Mobile Number"
        />
        <br />
      </div>
      <a
        className="contact-delete"
        onClick={e => props.deleteContact(e, props.index)}
      >
        <span className="fas fa-trash" />
      </a>
    </div>
  );
}

export default ContactItem;
