import {
  CLEAR_AD_PREVIEW_STATE,
  CLEAR_PAID_ADS_CAMPAIGNS,
  FETCHING_ADWORDS_ADGROUPS,
  FETCHING_ADWORDS_AD_SOURCES,
  FETCHING_ADWORDS_CAMPAIGNS,
  FETCHING_ADWORDS_CAMPAIGNS_STATS_DAILY,
  FETCHING_ADWORDS_CAMPAIGNS_STATS_SECOND_DATE_DAILY,
  FETCHING_ADWORDS_MATRIX,
  FETCHING_ADWORDS_MCC_ACCOUNTS,
  FETCHING_ADWORDS_MTM,
  FETCHING_ADWORDS_PERFORMANCE,
  FETCHING_ADWORDS_PERFORMANCE_ACCT_LEVEL,
  FETCHING_ADWORDS_PERFORMANCE_DATE_TWO,
  FETCHING_ADWORDS_PERFORMANCE_MONTH_VS_MONTH,
  FETCHING_FACEBOOK_CAMPAIGNS,
  FETCHING_FACEBOOK_CAMPAIGNS_STATS_DAILY,
  FETCHING_FACEBOOK_CAMPAIGNS_STATS_SECOND_DATE_DAILY,
  FETCHING_FB_ADSETS,
  FETCHING_FB_ADSETS_AD_PREVIEW,
  FETCHING_FB_AD_CAMPAIGNS,
  FETCHING_FB_AD_CAMPAIGNS_DATE_TWO,
  FETCHING_FB_PERFORMANCE_ACCT_LEVEL,
  FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK,
  FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK_DATE_TWO,
  FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE,
  FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE_DATE_TWO,
} from "./types";
import { getToken, requestHeaders } from "lib/authHelpers";

import { authLogoutAndRedirect } from "screens/users/actions";
import axios from "axios";
import { dispatchHelper } from "lib/actionHelpers";
import { getPreviousMonthStart } from "lib/dateHelpers";
import moment from "moment";
import qs from "qs";
import { rootUrl } from "lib/api";
import { adwordsLeadloopApi } from "lib/api";
import { store } from "store";
export const adwordsToken = "9f5e4363-a9a8-4896-b7aa-6592664bd090";

const requestParams = (clear) => {
  let finalParameters;
  if (clear === true) {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: true,
      },
    };
  } else {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: false,
      },
    };
  }
  return finalParameters;
};

export const clearAdPreviewState = (dispatch) => {
  let action = {
    type: CLEAR_AD_PREVIEW_STATE,
    payload: {},
  };
  return store.dispatch(action);
};

export const fetchAdwordsMCCAccounts =
  (params, callback, clearCacheBool) => async (dispatch) => {
    const token = getToken();
    const headers = requestHeaders(token);
    const url = `${rootUrl}/adwords/accounts?mcc_account_id=${params}`;
    const dispatching = dispatchHelper(FETCHING_ADWORDS_MCC_ACCOUNTS);
    const parameters = {
      params: {
        checkRedis: true,
        clearCache: true,
      },
    };
    try {
      let response = await axios.get(
        url,
        Object.assign({}, parameters, headers)
      );
      dispatching(response, callback);
    } catch (err) {}
  };

export const fetchFbAdCampaigns =
  (params, callback, clearCacheBool, isSecondDate) => async (dispatch) => {
    const search = qs.stringify({
      startDate: params.startDate,
      endDate: params.endDate,
    });
    let dispatching;

    if (isSecondDate === false) {
      dispatching = dispatchHelper(FETCHING_FB_AD_CAMPAIGNS);
    } else {
      dispatching = dispatchHelper(FETCHING_FB_AD_CAMPAIGNS_DATE_TWO);
    }
    const token = getToken();
    const headers = requestHeaders(token);
    const url = `${rootUrl}/public/fb-ads/campaigns/${params.fb_ads_id}?${search}`;

    let finalParameters = requestParams(clearCacheBool);
    try {
      const response = await axios.get(
        url,
        Object.assign({}, finalParameters, headers)
      );
      response.middleware = { fromFbAdCampaigns: true };

      dispatching(response, callback);
    } catch (err) {}
  };

export const fetchFacebookAdsets =
  (params, callback, clearCacheBool) => async (dispatch) => {
    let formatStartDate = moment(params.startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(params.endDate).format("YYYY-MM-DD");

    const search = qs.stringify({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });
    const dispatching = dispatchHelper(FETCHING_FB_ADSETS);
    const token = getToken();
    const headers = requestHeaders(token);
    const url = `${rootUrl}/public/fb-ads/adsets/${params.campaign_id}?${search}`;

    let finalParameters = requestParams(clearCacheBool);
    try {
      const response = await axios.get(
        url,
        Object.assign({}, finalParameters, headers)
      );
      dispatching(response, callback);
    } catch (err) {}
  };

export const fetchFBAdsetsAdPreview =
  (creative_id, callback, clearCacheBool, format) => async (dispatch) => {
    const dispatching = dispatchHelper(FETCHING_FB_ADSETS_AD_PREVIEW);
    const token = getToken();
    const headers = requestHeaders(token);
    const url = `${rootUrl}/public/fb-ads/ad-preview/${creative_id}?ad_format=${format}`;
    let finalParameters = requestParams(clearCacheBool);
    try {
      const response = await axios.get(
        url,
        Object.assign({}, finalParameters, headers)
      );
      dispatching(response, callback);
    } catch (err) {}
  };

export const fetchAdwordsPerformanceAcctLevel =
  (params, callback, clearCacheBool) => async () => {
    let formatStartDate = moment(params.startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(params.endDate).format("YYYY-MM-DD");
    const dispatching = dispatchHelper(FETCHING_ADWORDS_PERFORMANCE_ACCT_LEVEL);
    const token = getToken();
    const search = qs.stringify({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });
    const url = `${adwordsLeadloopApi}/performance/${params.adwords_id}?${search}&token=${adwordsToken}`;

    let finalParameters = requestParams(clearCacheBool);
    const headers = requestHeaders(token);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    // response.middleware = { fromAdwordsPerformance: true };
    dispatching(response, callback);
  };
export const fetchFBPerformanceAcctLevel =
  (params, callback, clearCacheBool) => async () => {
    let formatStartDate = moment(params.startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(params.endDate).format("YYYY-MM-DD");
    const search = qs.stringify({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });

    const dispatching = dispatchHelper(FETCHING_FB_PERFORMANCE_ACCT_LEVEL);
    const token = getToken();
    const headers = requestHeaders(token);
    const url = `${rootUrl}/public/fb-ads/campaigns/${params.fb_ads_id}?${search}`;

    let finalParameters = requestParams(clearCacheBool);
    try {
      const response = await axios.get(
        url,
        Object.assign({}, finalParameters, headers)
      );
      response.middleware = { fromFbAdCampaigns: true };
      dispatching(response, callback);
    } catch (err) {}
  };

export const fetchAdwordsPerformance =
  (params, callback, clearCacheBool, isSecondDate, isMvM = false) =>
  async () => {
    let dispatching;
    if (isMvM) {
      params.startDate = getPreviousMonthStart(params.startDate);
      dispatching = dispatchHelper(FETCHING_ADWORDS_PERFORMANCE_MONTH_VS_MONTH);
    } else {
      if (isSecondDate === false) {
        dispatching = dispatchHelper(FETCHING_ADWORDS_PERFORMANCE);
      } else {
        dispatching = dispatchHelper(FETCHING_ADWORDS_PERFORMANCE_DATE_TWO);
      }
    }
    const token = getToken();
    const search = qs.stringify({
      startDate: params.startDate,
      endDate: params.endDate,
    });
    const url = `${adwordsLeadloopApi}/performance/${params.adwords_id}?${search}&token=${adwordsToken}`;
    let finalParameters = requestParams(clearCacheBool);
    const headers = requestHeaders(token);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    if (isMvM) {
      response.middleware = {
        fromAdwordsPerformance: {
          isAdwordsPerformance: true,
          isOverview: false,
        },
      };
    } else {
      response.middleware = {
        fromAdwordsPerformance: {
          isAdwordsPerformance: true,
          isOverview: true,
        },
      };
    }
    dispatching(response, callback);
  };

export const fetchAdwordsCampaigns =
  (params, callback, clearCacheBool) => async () => {
    params.startDate = moment(params.endDate, "YYYY-MM-DD")
      .startOf("month")
      .format("YYYY-MM-DD");
    const dispatching = dispatchHelper(FETCHING_ADWORDS_CAMPAIGNS);
    const token = getToken();
    const search = qs.stringify({
      startDate: params.startDate,
      endDate: params.endDate,
    });
    let finalParameters = requestParams(clearCacheBool);
    const url = `${adwordsLeadloopApi}/campaigns/${params.adwords_id}?${search}&token=${adwordsToken}`;
    const headers = requestHeaders(token);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    dispatching(response, callback);
  };

export const fetchCampaigns =
  (params, callback, clearCacheBool, type, isSecondDate) =>
  async (dispatch) => {
    let formatStartDate = moment(params.startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(params.endDate).format("YYYY-MM-DD");
    const token = getToken();
    const search = qs.stringify({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });
    let dispatching;
    let url;

    dispatch({
      type: CLEAR_PAID_ADS_CAMPAIGNS,
      payload: true,
    });

    if (type === "Google") {
      dispatching = dispatchHelper(FETCHING_ADWORDS_CAMPAIGNS);
      url = `${adwordsLeadloopApi}/campaigns/${params.adwords_id}?${search}&token=${adwordsToken}`;
    } else {
      dispatching = dispatchHelper(FETCHING_FACEBOOK_CAMPAIGNS);
      url = `${rootUrl}/public/fb-ads/campaigns/${params.fb_ads_id}?${search}`;
    }
    const headers = requestHeaders(token);
    let finalParameters = requestParams(clearCacheBool);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    dispatching(response, callback);
  };

function IdClosure(x) {
  let key = x;
  return function () {
    return key;
  };
}
export const fetchUpdatedCampaignValues =
  (params, callback, clearCacheBool, type, isSecondDate) => async () => {
    let formatStartDate = moment(params.startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(params.endDate).format("YYYY-MM-DD");
    const token = getToken();
    const search = qs.stringify({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });
    let dispatching;
    let url;
    if (type === "Google") {
      isSecondDate === false
        ? (dispatching = dispatchHelper(FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE))
        : (dispatching = dispatchHelper(
            FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE_DATE_TWO
          ));
      url = `${adwordsLeadloopApi}/campaigns/${params.adwords_id}?${search}&token=${adwordsToken}`;
    } else {
      isSecondDate === false
        ? (dispatching = dispatchHelper(FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK))
        : (dispatching = dispatchHelper(
            FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK_DATE_TWO
          ));
      url = `${rootUrl}/public/fb-ads/campaigns/${params.fb_ads_id}?${search}`;
    }
    const headers = requestHeaders(token);
    let finalParameters = requestParams(clearCacheBool);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    if (type == "Facebook") {
      // Double check if closure affects selectFBCampaignId
      response.middleware = {
        paidAds: true,
        adType: "Facebook",
        campaignId: params.campaign_id,
        isDataUpdate: true,
      };
    } else {
      response.middleware = {
        paidAds: true,
        adType: "Google",
        campaignId: params.campaign_id,
        isDataUpdate: true,
      };
    }
    dispatching(response, callback);
  };

function keyClosure(x) {
  let key = x;
  return function () {
    return key;
  };
}

export const fetchCampaignsInfo_DAILY =
  (params, callback, clearCacheBool, type, isSecondDate) => async () => {
    if (params.field === undefined) {
      return null;
    }
    const token = getToken();
    let formatStartDate = moment(params.startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(params.endDate).format("YYYY-MM-DD");
    const search = qs.stringify({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });
    let url;
    let dispatching;
    if (type === "Google") {
      isSecondDate === false
        ? (dispatching = dispatchHelper(FETCHING_ADWORDS_CAMPAIGNS_STATS_DAILY))
        : (dispatching = dispatchHelper(
            FETCHING_ADWORDS_CAMPAIGNS_STATS_SECOND_DATE_DAILY
          ));
      url = `${adwordsLeadloopApi}/campaign/${params.adwords_id}/${params.field}/${params.campaign_id}?${search}&token=${adwordsToken}`;
    } else {
      isSecondDate === false
        ? (dispatching = dispatchHelper(
            FETCHING_FACEBOOK_CAMPAIGNS_STATS_DAILY
          ))
        : (dispatching = dispatchHelper(
            FETCHING_FACEBOOK_CAMPAIGNS_STATS_SECOND_DATE_DAILY
          ));
      url = `${rootUrl}/public/fb-ads/campaigns/${params.field}/${params.fb_ads_id}?${search}`;
    }
    var getKey = keyClosure(params.key);
    const headers = requestHeaders(token);
    let finalParameters = requestParams(clearCacheBool);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    let key = getKey();
    if (type == "Facebook") {
      // Double check if closure affects selectFBCampaignId
      response.middleware = {
        paidAds: true,
        key,
        adType: "Facebook",
        FBCampaignId: params.selectFBCampaignId,
      };
    } else {
      response.middleware = { paidAds: true, key, adType: "Google" };
    }

    dispatching(response, callback);
  };

export const fetchAdwordsAdgroups =
  (params, callback, clearCacheBool) => async () => {
    let formatStartDate = moment(params.startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(params.endDate).format("YYYY-MM-DD");
    const dispatching = dispatchHelper(FETCHING_ADWORDS_ADGROUPS);
    const token = getToken();
    const search = qs.stringify({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });
    const url = `${adwordsLeadloopApi}/adgroups/${params.adwords_id}/${params.campaign_id}?${search}&token=${adwordsToken}`;
    const headers = requestHeaders(token);
    let finalParameters = requestParams(clearCacheBool);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    dispatching(response, callback);
  };

export const fetchAdwordsAdSources =
  (params, callback, clearCacheBool) => async () => {
    params.startDate = moment(params.endDate, "YYYY-MM-DD")
      .startOf("month")
      .format("YYYY-MM-DD");
    const dispatching = dispatchHelper(FETCHING_ADWORDS_AD_SOURCES);
    const token = getToken();
    const search = qs.stringify({
      startDate: params.startDate,
      endDate: params.endDate,
    });
    const url = `${adwordsLeadloopApi}/ad-sources/${params.adwords_id}?${search}&token=${adwordsToken}`;
    let finalParameters = requestParams(clearCacheBool);
    const headers = requestHeaders(token);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    dispatching(response, callback);
  };

export const fetchAdwordsMTM =
  (params, callback, clearCacheBool) => async () => {
    params.startDate = moment(params.endDate, "YYYY-MM-DD")
      .startOf("month")
      .format("YYYY-MM-DD");
    const dispatching = dispatchHelper(FETCHING_ADWORDS_MTM);
    const token = getToken();
    const search = qs.stringify({
      startDate: params.startDate,
      endDate: params.endDate,
      compare: params.compare,
    });
    const url = `${adwordsLeadloopApi}/mtm/${params.adwords_id}?${search}&token=${adwordsToken}`;
    let finalParameters = requestParams(clearCacheBool);
    const headers = requestHeaders(token);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    dispatching(response, callback);
  };

export const fetchAdwordsMatrix =
  (params, callback, clearCacheBool) => async () => {
    params.startDate = moment(params.endDate, "YYYY-MM-DD")
      .startOf("month")
      .format("YYYY-MM-DD");
    const dispatching = dispatchHelper(FETCHING_ADWORDS_MATRIX);
    const token = getToken();
    const search = qs.stringify({
      startDate: params.startDate,
      endDate: params.endDate,
    });
    const url = `${adwordsLeadloopApi}/matrix/${params.adwords_id}?${search}&token=${adwordsToken}`;
    let finalParameters = requestParams(clearCacheBool);
    const headers = requestHeaders(token);
    const response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    dispatching(response, callback);
  };
