import React, { Component, Fragment } from "react";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { connect } from "react-redux";
import facebookLogo from "images/icon_facebook.png";
import { fetchSocialDataInsights } from "../actions/actions";
import iconComments from "images/social-comments.svg";
import iconEngagement from "images/social-engagement.svg";
import iconLikes from "images/social-likes.svg";
import iconReach from "images/social-reach.svg";
import moment from "moment";
import { SocialGraphViewWithLoader } from "../SocialGraphViewWithLoader";

import SocialTooltips from "./SocialTooltips";

Array.prototype.sortBy = function(p) {
  return this.slice(0).sort(function(a, b) {
    return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
  });
};

const SelectOptions = (props) => {
  return (
    <select
      onChange={(e) => {
        props.setFilter(e.target.value);
      }}
      value={props.currentFilter}
    >
      <option value="Impressions">Impressions</option>
      <option value="User engagement">Engagement</option>
      <option value="Number of comments">Comments</option>
      <option value="Number of likes">Likes</option>
    </select>
  );
};

const RenderFBPosts = (props) => {
  if (props.data) {
    let formattedData = props.data.reduce((all, item, index) => {
      // Object to be pushed to final [all] array
      const objectStructure = {};
      if (item.from === undefined) {
        objectStructure["page_name"] = "No Data";
      } else {
        objectStructure["page_name"] = item.from.name;
      }
      objectStructure["link"] = item.link;
      objectStructure["total_comments"] = item.comments.summary.total_count;
      objectStructure["post_id"] = item.id;
      objectStructure["type_icon"] = item.icon;
      objectStructure["message"] = item.message;
      objectStructure["full_picture"] = item.full_picture;
      objectStructure["month_name"] = item.month_name;
      objectStructure["created_time"] = moment(item.created_time).format(
        "MM/DD/YYYY"
      );
      // For each insight ('post reactions', 'impressions, ect...')
      if (item.insights) {
        item.insights.data.forEach((object, index) => {
          if (object.name === "post_reactions_by_type_total") {
            // Get object containting all reaction types and values
            const reactions = object.values[0].value;
            // Create a key with corresponding value and add to objectStructure
            // This makes const objectStructure = {} a flat object with no nested values
            for (let key in reactions) {
              // Create a key with its corresponding value and add to objectStructure
              // This makes const objectStructure = {} a flat object with no nested values
              // E.G: reactions ={likes: 5, wow: 4, mad: 2}
              objectStructure[key] = reactions[key];
              // Output const objectStructure = { like: 5, wow: 4 } ect...
            }
          } else {
            objectStructure[object["name"]] = object.values[0].value;
          }
        });
      }
      all.push(objectStructure);
      return all;
    }, []);
    if (props.filterBy === "Impressions") {
      formattedData = formattedData.sortBy("post_impressions_unique");
    }
    if (props.filterBy === "User engagement") {
      formattedData = formattedData.sortBy("post_engaged_users");
    }
    if (props.filterBy === "Number of comments") {
      formattedData = formattedData.sortBy("total_comments");
    }
    if (props.filterBy === "Number of likes") {
      formattedData = formattedData.sortBy("like");
    }
    formattedData.forEach((post) => {
      for (let key in post) {
        if (post[key] === undefined) {
          post[key] = "No Data Available";
        }
      }
    });
    let dateAddOneMonth = parseInt(props.month);
    dateAddOneMonth += 1;
    let backToString = dateAddOneMonth.toString();
    let currentMonthName = moment(backToString).format("MMM");
    let postsToDisplay = formattedData.filter(
      (post) => post.month_name === currentMonthName
    );
    return (
      <Fragment>
        {postsToDisplay.map((obj, i) => (
          <div className="card-social" key={i}>
            <div
              className="card-social_image"
              style={{ backgroundImage: `url(${obj.full_picture})` }}
            ></div>
            <div className="card-social_content">
              <h3 className="card-social_title">{obj.page_name}</h3>
              <p className="card-social_date">{obj.created_time}</p>
              <img className="card-social_type" src={obj.type_icon} alt="" />
              <p className="card-social_text">
                {obj.message.substring(0, 150) + "..."}
              </p>
              <ul className="card-social_stats">
                <li className="card-social_stats-item">
                  <img src={iconLikes} alt="" />
                  {obj.like}
                  <SocialTooltips title={"Likes"} />
                </li>
                <li className="card-social_stats-item">
                  <img src={iconComments} alt="" />
                  {obj.total_comments}
                  <SocialTooltips title={"Comments"} />
                </li>
                <li className="card-social_stats-item">
                  <img src={iconEngagement} alt="" />
                  {obj.post_engaged_users}
                  <SocialTooltips title={"Engagement"} />
                </li>
                <li className="card-social_stats-item">
                  <img src={iconReach} alt="" />
                  {obj.post_impressions_unique}
                  <SocialTooltips title={"Impressions"} />
                </li>
              </ul>
            </div>
          </div>
        ))}
      </Fragment>
    );
  } else {
    return null;
  }
};

class Engagement extends Component {
  state = {
    filterBy: "Impressions",
    isFetching: true,
  };

  goToPost = (link) => {
    window.location = link;
  };

  setFilter = (filterOption) => {
    this.setState({
      filterBy: filterOption,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.month !== prevProps.month) {
      this.setState({
        isFetching: true,
      });
    }
    if (this.props.data !== prevProps.data) {
      this.setState({
        isFetching: false,
      });
    }
  }

  render() {
    let renderData = this.props.data;
    if (Array.isArray(renderData) && (renderData || []).length > 6) {
      renderData = renderData.slice(0, 6);
    }
    return (
      <div className="row social-posts mt-3">
        <div className="col-sm-12 col-md-6 social-posts_title">
          <ReportSubTitle text="Top Posts" logo={facebookLogo} />
        </div>
        <div className="col-sm-12 col-md-6 social-posts_filter">
          <span>Filter By: </span>
          <SelectOptions
            currentFilter={this.state.filterBy}
            setFilter={this.setFilter}
          />
        </div>
        <div className="col-sm-12 row">
          <SocialGraphViewWithLoader
            classes="mb-3"
            customStyle={{ width: "100%" }}
            isLoading={this.props.isFetching}
          >
            <div className="col-12 instagram-posts-container mt-3">
              <RenderFBPosts
                month={this.props.month}
                goToPost={this.goToPost}
                filterBy={this.state.filterBy}
                data={renderData}
              />
            </div>
          </SocialGraphViewWithLoader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.social,
});

const mapDispatchToProps = { fetchSocialDataInsights };

const connected = connect(mapStateToProps, mapDispatchToProps)(Engagement);

export { connected as Engagement };
