import React from "react";
import { func, number, string } from "prop-types";

SubtasksForm.propTypes = {
  onSubmit: func,
  onKeyDown: func,
  onChange: func,
  value: string,
  todoId: number
};

function SubtasksForm(props) {
  return (
    <form onSubmit={e => props.onSubmit({ e, todoId: props.todoId })}>
      <input
        placeholder="Start typing a new subtask. Press enter to submit!"
        tabIndex="0"
        value={props.value}
        onChange={props.onChange}
        className="todo-subtasks-form"
      />
    </form>
  );
}

export default SubtasksForm;
