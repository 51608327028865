import React, { Component } from "react";
import { Panel } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { DemoLocationsFixtures } from "./fixtures/PanelFixtures";

const TopFiveCitiesTable = (props) => {
    if (props.data) {
        return props.data.map((item, index) => {
            return (
                <tr key={Math.random()}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.city}</td>
                    <td>{item.value}</td>
                </tr>
            )
        })
    } else {
        return null;
    }
}

class DemoLocations extends Component {

    render() {
        return (
            <div className="row">
                <ReportSubTitle text="Likes By City (Realtime)" classes="mt-3" logo={this.props.logo} />
                <Panel
                    containerClass="col-md-12 p-3 float-left"
                    isLoading={false}
                    errors={null}
                    padding={0}>
                    <Panel.Body
                        render={() => {
                            return (
                                <table className="table table-hover" >
                                    <thead className="thead">
                                        <tr>
                                            <th scope="col">Ranking</th>
                                            <th scope="col">City</th>
                                            <th scope="col"># of Likes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TopFiveCitiesTable data={DemoLocationsFixtures} />
                                    </tbody>
                                </table >
                            );
                        }}
                    />
                </Panel>
            </div>
        )
    }
}

export { DemoLocations };


