import React, { Component, Fragment } from "react";
import { fetchInstagramDataInsights } from "./actions/actions";
import { connect } from "react-redux";
import instagram from "./images/instagram.png";
import { formatInsightsForSelectedDate } from "./../../lib/socialHelpers";
import { InstagramImpressions } from "./components/InstagramImpressions";
import { InstagramReach } from "./components/InstagramReach";
import { InstagramPosts } from "./components/InstagramPosts";
import { InstagramStatsBar } from "./components/InstagramStatsBar";
import { InstagramAudienceDemographics } from "./components/InstagramAudienceDemographics";
import { Location } from "./components/Location";
import { ErrorBoundary } from "common";
import { InstagramSavesAndSharesPieGraph } from "./components/InstagramSavesAndSharesPieGraph";
import GoogleAnalyticsSocialSourcePageViews from "screens/sites/components/GoogleAnalyticsSocialSourcePageViews";
class Instagram extends Component {
  state = {
    gender_age: [],
    audience_city: [],
  };

  handleFetchInstagramDataInsights() {
    let dateRanges = formatInsightsForSelectedDate(this.props.date);
    const currentMonthDates = {
      startOfMonth: this.props.date.dateOneStartForCompare,
      endOfMonth: this.props.date.dateOneEndForCompare,
    };
    dateRanges.forEach((date) => {
      this.props.fetchInstagramDataInsights(
        date.category,
        date,
        this.props.siteData.public_hash,
        this.props.clearCacheBool
      );
    });
    this.props.fetchInstagramDataInsights(
      "posts",
      currentMonthDates,
      this.props.siteData.public_hash,
      this.props.clearCacheBool
    );
    this.props.fetchInstagramDataInsights(
      "demographics",
      {},
      this.props.siteData.public_hash,
      this.props.clearCacheBool
    );
  }
  componentDidMount() {
    let business_id = this.props.instagram_business_id;
    if (business_id && this.props.fb_page_id) {
      this.handleFetchInstagramDataInsights();
    }
  }

  componentDidUpdate(prevProps) {
    // On demographics data change set state of new data
    // ******************************************************************************************
    if (
      this.props.social.instagram_demographics !==
      prevProps.social.instagram_demographics
    ) {
      let gender_age;
      let audience_city;
      this.props.social.instagram_demographics.data.forEach((dataset) => {
        if (dataset.name === "audience_gender_age") {
          gender_age = dataset;
        }
        if (dataset.name === "audience_city") {
          audience_city = dataset;
        }
      });
      this.setState({
        audience_city: audience_city ? [audience_city] : [],
        gender_age: gender_age ? [gender_age] : [],
      });
    }

    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      if (this.props.clearCacheBool === true) {
        this.handleFetchInstagramDataInsights();
      }
    }

    // Fetch insights if instagram business id is added.
    // ******************************************************************************************
    if (this.props.instagram_business_id !== prevProps.instagram_business_id) {
      if (this.props.instagram_business_id !== null) {
        this.handleFetchInstagramDataInsights();
      }
    }

    if (
      this.props.date.dateOneStartForCompare !==
      prevProps.date.dateOneStartForCompare
    ) {
      this.handleFetchInstagramDataInsights();
    }
  }

  render() {
    return this.props.instagram_business_id === null ? (
      <div className="instagram-connect-container">
        <h1 className="mt-5 textCenter">
          No Instagram business id associated with this account.
        </h1>
      </div>
    ) : (
      <Fragment>
        <ErrorBoundary component={"Instagram Stats Bar"}>
          <InstagramStatsBar date={this.props.date} />
        </ErrorBoundary>
        <InstagramReach />
        <InstagramImpressions />

        <InstagramAudienceDemographics
          month={this.props.date.month}
          data={this.state.gender_age}
        />
        <InstagramSavesAndSharesPieGraph />
        <GoogleAnalyticsSocialSourcePageViews
          clearCacheBool={this.props.clearCacheBool}
          colSize={12}
        />
        <Location logo={instagram} data={this.state.audience_city} />
        <InstagramPosts />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.social,
  siteData: { ...state.sites.show.data },
  date: { ...state.sites.show.date },
});

const mapDispatchToProps = { fetchInstagramDataInsights };

const connected = connect(mapStateToProps, mapDispatchToProps)(Instagram);

export { connected as Instagram };
