import React, { Component } from "react";
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

import { Google_Columns, Facebook_Columns, Button } from "common";
import Modal from "react-modal";

import AdsPreview from "../screens/sites/components/AdsPreview";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class InteractiveTable extends Component {

    state = {
        modalIsOpen: false,
        loadingPreviews: true,
        id: null,
        adData: {
            creatives: []
        },
    }

    openModal = (data) => {
        this.setState({ modalIsOpen: true, adData: data });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    renderTooltip = (id) => {
        this.state.id !== id && this.setState({ id: id });
    }

    render() {
        this.props.data.length > 0 && this.props.data.forEach(item => {
            item.state = this.state;
            item.renderTooltip = this.renderTooltip;
            item.openModal = this.openModal;
            item.clearAdPreviewState = this.props.clearAdPreviewState;
        });

        return (
            <div>
                <ReactTable
                    data={this.props.data}
                    columns={this.props.type === "Google" ? Google_Columns : Facebook_Columns}
                    defaultPageSize={10}
                    loading={this.props.tableLoader}
                    className="-striped -highlight"
                />
                <br />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >
                    <AdsPreview
                        creatives={this.state.adData.creatives}
                        clearAdPreviewState={this.props.clearAdPreviewState}
                        fetchAdPreview={this.props.fetchAdPreview}
                        clearCacheBool={this.props.clearCacheBool}
                        facebookAdPreviews={this.props.facebookAdPreviews} />
                </Modal>
            </div>
        );
    }
}

export { InteractiveTable };

