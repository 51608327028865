import React, { Component, Fragment } from "react";
import { saveTwitterHandle } from '../sites/actions/sites_actions';
import { connect } from "react-redux";
import { Button, FormGroup, Panel } from "common";
import ThirdPartyDisconnect from "screens/sites/components/ThirdPartyDisconnect";

class Twitter extends Component {
    state = {
        twitter_username: '',
    }

    handleInputChange = (e, type) => {
        this.setState({ [type]: e.target.value });
    };

    handleSubmit = () => {
        this.props.saveTwitterHandle(this.state.twitter_username, this.props.siteId);
    }

    render() {
        let following = 0;
        if (this.props.twitterData.following !== null) {
            following = this.props.twitterData.following;
        }
        let newLink = null;
        if (this.props.twitterData.profile_image_url_https) {
            let twitterLink = this.props.twitterData.profile_image_url_https;
            let format = null;
            var formatType = twitterLink.slice(-3);
            if (formatType === "peg") {
                format = ".jpeg";
            } else {
                format = ".jpg";
            }
            for (let i = 0; i < twitterLink.length; i++) {
                if (twitterLink[i] === "_") {
                    newLink = this.props.twitterData.profile_image_url_https.slice(0, i);
                }
            }
            newLink += format;
            newLink.trim();
        }
        if (this.props.twitterUsername) {
            return (
                <Fragment>
                    {
                        !this.props.isPublic ? <div className="centerDisconnect">
                            <ThirdPartyDisconnect
                                siteId={this.props.siteId}
                                type="twitter_username"
                                displayName="Twitter"
                            />
                        </div> : null
                    }
                    <div className="twitter-card-container">
                        <div className="twitter-card">
                            <div className="twitter-card-content">
                                <div className="twitter-card-handle-name">
                                    <p className="twitter-card-font">{this.props.twitterData.name}</p>
                                    <p className="twitter-card-handle">{this.props.twitterData.screen_name}</p>
                                </div>
                                <div className="twitter-card-stats">
                                    <div className="twitter-stat-container">
                                        <span className="twitter-stat-header">
                                            Tweets
                                        </span>
                                        <span className="twitter-stat">
                                            {this.props.twitterData.statuses_count}
                                        </span>
                                    </div>
                                    <div className="twitter-stat-container">
                                        <span className="twitter-stat-header">
                                            Followers
                                        </span>
                                        <span className="twitter-stat">
                                            {this.props.twitterData.followers_count}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="twitter-card-profile-pic">
                                <img className="background-cover-profile" alt="twitter card profile" src={newLink}></img>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Panel containerClass="col-md-12 p-3">
                    <Panel.Header title={'Connect Twitter'} />
                    <Panel.Body
                        className="account-connect-body"
                        render={() => (
                            <Fragment>
                                <FormGroup
                                    classes="col-sm-6 col-md-6"
                                    label="Twitter Username"
                                    value={this.state.twitter_username}
                                    onChange={e => this.handleInputChange(e, "twitter_username")}
                                />
                                <Button classes={"twitter-button"} disabled={null} onClick={this.handleSubmit}>
                                    Save
                                </Button>
                            </Fragment>
                        )}
                    />
                </Panel>
            )
        }
    }
}



const mapStateToProps = state => ({
    ...state.social
});

const mapDispatchToProps = { saveTwitterHandle };

const connected = connect(mapStateToProps, mapDispatchToProps)(Twitter);

export { connected as Twitter };
