import React, { Component } from "react";
import { PieGraph } from "common";
import { ViewWithLoader } from "common";
import { DemoAgeGenderBreakdown } from './DemoAgeGenderBreakdown';
import facebookLogo from "images/icon_facebook.png";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { AudienceDemographicsFixtures } from "./fixtures/PanelFixtures";

class DemoAudienceDemographics extends Component {
    render() {
        return (
            <ViewWithLoader classes="mb-3" isLoading={false} >
                <div className="col-12">
                    <ReportSubTitle text="Demographics (Realtime)" classes="mt-3 pl-0" logo={this.props.logo} />
                </div>
                <DemoAgeGenderBreakdown data={AudienceDemographicsFixtures} />
                <PieGraph
                    title="Gender Demographics"
                    isLoading={false}
                    data={AudienceDemographicsFixtures}
                    color={["#5E92FD", "#f8abff"]}
                />
            </ViewWithLoader>
        )
    }
}


export { DemoAudienceDemographics };