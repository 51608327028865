import React, { Component } from "react";
import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import moment from "moment";

import { Button, FormGroup, FormGroupSelect } from "common";

class SitesNewForm extends Component {
  state = {
    name: "",
    live_url: "",
    dev_url: "",
    wc_token: "",
    wc_secret: "",
    health: "",
    launch_date: null,
    date: moment()
  };

  handleInputChange = (e, type) => {
    this.setState({ [type]: e.target.value });
  };

  handleSiteCreate = () => {
    var launch_date = null;
    if (this.state.launch_date === null) {
      launch_date = moment().format('YYYY-MM-DD');
      this.setState({
        launch_date: launch_date
      }, () => {
        this.props.createSite(
          {
            name: this.state.name,
            live_url: this.state.live_url,
            dev_url: this.state.dev_url,
            wc_token: this.state.wc_token,
            wc_secret: this.state.wc_secret,
            health: this.state.health,
            launch_date: this.state.launch_date,
            client_id: this.props.clientId
          },
          () => {
            this.setState({
              name: "",
              live_url: "",
              dev_url: "",
              wc_token: "",
              wc_secret: "",
              health: ""
            });
          }
        )
      })
    } else {
      this.props.createSite(
        {
          name: this.state.name,
          live_url: this.state.live_url,
          dev_url: this.state.dev_url,
          wc_token: this.state.wc_token,
          wc_secret: this.state.wc_secret,
          health: this.state.health,
          launch_date: this.state.launch_date,
          client_id: this.props.clientId
        },
        () => {
          this.setState({
            name: "",
            live_url: "",
            dev_url: "",
            wc_token: "",
            wc_secret: "",
            health: ""
          });
        }
      )
    }
  };

  handleCancelSiteCreate = () => {
    this.setState(
      {
        name: "",
        live_url: "",
        dev_url: "",
        wc_token: "",
        wc_secret: "",
        health: "",
        launch_date: null
      },
      () => {
        this.props.onToggleDisplayForm();
      }
    );
  };

  handleDateChange = (date) => {
    const launchDateFormmated = moment(date).format('YYYY-MM-DD')
    this.setState({
      date: date,
      launch_date: launchDateFormmated
    });
  }

  renderHealthOptions = () => {
    const options = [
      { id: "healthy", name: "healthy" },
      { id: "warning", name: "warning" },
      { id: "danger", name: "danger" }
    ];
    return (
      <FormGroupSelect
        classes="col-sm-12 col-md-6"
        label="Select Health"
        value={this.state.health}
        onChange={e => this.handleInputChange(e, "health")}
        options={options}
      />
    );
  };

  render() {
    const buttonIsDisabled =
      this.state.name.length === 0 ||
      this.state.live_url.length === 0 ||
      this.state.dev_url.length === 0 ||
      this.state.wc_token.length === 0 ||
      this.state.wc_secret.length === 0 ||
      this.state.health.length === 0;

    return (
      <div className="row">
        <FormGroup
          classes="col-sm-12 col-md-12"
          label="Site Name"
          value={this.state.name}
          onChange={e => this.handleInputChange(e, "name")}
        />
        <FormGroup
          classes="col-sm-12 col-md-6"
          label="Live URL"
          value={this.state.live_url}
          onChange={e => this.handleInputChange(e, "live_url")}
        />
        <FormGroup
          classes="col-sm-12 col-md-6"
          label="Dev URL"
          value={this.state.dev_url}
          onChange={e => this.handleInputChange(e, "dev_url")}
        />
        <FormGroup
          classes="col-sm-12 col-md-6"
          label="WhatConverts Token"
          value={this.state.wc_token}
          onChange={e => this.handleInputChange(e, "wc_token")}
        />
        <FormGroup
          classes="col-sm-12 col-md-6"
          label="WhatConverts Secret"
          value={this.state.wc_secret}
          onChange={e => this.handleInputChange(e, "wc_secret")}
        />
        {this.renderHealthOptions()}
        <div className="launchDateContainer">
          <label id="launchLabel">Launch Date</label>
          <DatePicker
            ref={datePicker => (this.datePicker = datePicker)}
            placeholderText={"Click to select a date"}
            isClearable
            selected={this.state.date}
            onChange={this.handleDateChange}
            dateFormat="YYYY-MM-DD"
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <Button className="saveSiteMargin" disabled={buttonIsDisabled} onClick={this.handleSiteCreate}>
            Save Site
          </Button>
          <Button secondary onClick={this.handleCancelSiteCreate}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

SitesNewForm.propTypes = {
  onToggleDisplayForm: PropTypes.func,
  createSite: PropTypes.func,
  clientId: PropTypes.number
};

export default SitesNewForm;
