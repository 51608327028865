import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { ErrorBoundary } from "common";

import KlaviyoConnect from "screens/sites/components/KlaviyoConnect";
import MailchimpConnect from "screens/sites/components/MailchimpConnect";
import KlaviyoAccountOverview from "screens/sites/components/KlaviyoAccountOverview";
import KlaviyoCampaigns from "screens/sites/components/KlaviyoCampaigns";
import MailchimpAccountOverview from "screens/sites/components/MailchimpAccountOverview";
import MailchimpCampaigns from "screens/sites/components/MailchimpCampaigns";
import ThirdPartyDisconnect from "screens/sites/components/ThirdPartyDisconnect";
import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";
import {
  updateSite,
  fetchKlaviyoAccount,
  fetchKlaviyoCampaigns,
  fetchMailchimpCampaigns,
  fetchMailchimpAccount
} from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";

class EmailContainer extends Component {
  state = {
    loadingKlaviyoAccount: false,
    loadingKlaviyoCampaigns: false,
    loadingMailchimpAccount: false,
    loadingMailchimpCampaigns: false
  };

  fetchKlaviyoAccount = () => {
    if (this.props.siteData.klaviyo_api_key == null) return;
    const params = {
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.setState({
      loadingKlaviyoAccount: true
    });

    this.props.fetchKlaviyoAccount(
      params,
      this.props.siteData.public_hash,
      () => {
        this.setState({
          loadingKlaviyoAccount: false
        });
      }
    );
  };

  fetchKlaviyoCampaigns = () => {
    if (this.props.siteData.klaviyo_api_key == null) return;
    const params = {
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.setState({
      loadingKlaviyoCampaigns: true
    });

    this.props.fetchKlaviyoCampaigns(
      params,
      this.props.siteData.public_hash,
      () => {
        this.setState({
          loadingKlaviyoCampaigns: false
        });
      }
    );
  };

  fetchMailchimpAccount = () => {
    if (this.props.siteData.mailchimp_api_key == null) return;
    const params = {
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.setState({
      loadingMailchimpAccount: true
    });

    this.props.fetchMailchimpAccount(
      params,
      this.props.siteData.public_hash,
      () => {
        this.setState({
          loadingMailchimpAccount: false
        });
      }
    );
  };

  fetchMailchimpCampaigns = () => {
    if (this.props.siteData.mailchimp_api_key == null) return;
    const params = {
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.setState({
      loadingMailchimpCampaigns: true
    });

    this.props.fetchMailchimpCampaigns(
      params,
      this.props.siteData.public_hash,
      () => {
        this.setState({
          loadingMailchimpCampaigns: false
        });
      }
    );
  };

  componentDidMount() {
    this.fetchKlaviyoCampaigns();
    this.fetchKlaviyoAccount();
    this.fetchMailchimpCampaigns();
    this.fetchMailchimpAccount();
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentIdKlaviyo =
      this.props.siteData.klaviyo_api_key !==
      nextProps.siteData.klaviyo_api_key;
    const hasDifferentIdMailchimp =
      this.props.siteData.mailchimp_api_key !==
      nextProps.siteData.mailchimp_api_key;
    if (hasDifferentMonth || hasDifferentYear || hasDifferentIdKlaviyo || hasDifferentIdMailchimp) {
      this.fetchKlaviyoCampaigns();
      this.fetchKlaviyoAccount();
      this.fetchMailchimpCampaigns();
      this.fetchMailchimpAccount();
    }
  };

  renderKlaviyoMetrics() {
    if (this.props.siteData.klaviyo_api_key !== null && this.props.siteData.klaviyo_api_key !== undefined) {
      return (
        <Fragment>
          <KlaviyoAccountOverview
            isLoading={this.state.loadingKlaviyoAccount}
            data={this.props.klaviyoAccountReport}
          />
          <KlaviyoCampaigns
            isLoading={this.state.loadingKlaviyoCampaigns}
            data={this.props.klaviyoCampaignsReport}
          />
        </Fragment>
      );
    } else {
      return null;
    }
  }

  renderMailchimpMetrics() {
    if (this.props.siteData.mailchimp_api_key !== null && this.props.siteData.mailchimp_api_key !== undefined) {
      return (
        <Fragment>
          <MailchimpAccountOverview
            isLoading={this.state.loadingKlaviyoAccount}
            data={this.props.mailchimpAccountReport}
          />
          <MailchimpCampaigns
            isLoading={this.state.loadingKlaviyoCampaigns}
            data={this.props.mailchimpCampaignsReport}
          />
        </Fragment>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className={"col-12"}>
        { 
          <div className="row">
          {!this.props.isPublic && <MailchimpConnect
              updateSite={this.props.updateSite}
              mailchimpApiKey={this.props.siteData.mailchimp_api_key}
              siteId={this.props.siteData.id}
            /> }
           
            <div className="col-12 flex-end">
            {!this.props.isPublic && 
              <ThirdPartyDisconnect
              siteId={this.props.siteData.id}
              type="mailchimp_api_key"
              displayName="Mailchimp"
            />
            }
            </div>
            <ErrorBoundary>{this.renderMailchimpMetrics()}</ErrorBoundary>
            <div className="col-12 flex-end">
            {
              !this.props.isPublic && 
              <ThirdPartyDisconnect
              siteId={this.props.siteData.id}
              type="klaviyo_api_key"
              displayName="Klaviyo"
            />
            }
            </div>
            {
              !this.props.isPublic && <KlaviyoConnect
              updateSite={this.props.updateSite}
              klaviyoApiKey={this.props.siteData.klaviyo_api_key}
              siteId={this.props.siteData.id}
            />
            }
            <ErrorBoundary>{this.renderKlaviyoMetrics()}</ErrorBoundary>
          </div>
        }
          <ThirdPartyFallback
          id={this.props.siteData.klaviyo_api_key || this.props.siteData.mailchimp_api_key}
          tabs={['Mailchimp', 'Klaviyo']}
          type="Email"
        />

      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: siteData,
      klaviyoAccountReport,
      klaviyoCampaignsReport,
      mailchimpAccountReport,
      mailchimpCampaignsReport
    }
  }
}) => ({
  siteData,
  isPublic,
  klaviyoAccountReport,
  klaviyoCampaignsReport,
  mailchimpAccountReport,
  mailchimpCampaignsReport,
  date
});

export default connect(
  mapStateToProps,
  {
    updateSite,
    fetchKlaviyoAccount,
    fetchKlaviyoCampaigns,
    fetchMailchimpAccount,
    fetchMailchimpCampaigns
  }
)(EmailContainer);
