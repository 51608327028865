import React, { Component, Fragment } from "react";
import { func, number, string, object } from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

import { Button, Panel } from "common";
import { updateSite } from "screens/sites/actions";

class ThirdPartyConnectFBAnalytics extends Component {
    static propTypes = {
        updateSite: func.isRequired,
        fetchAccounts: func.isRequired,
        formatAccounts: func.isRequired,
        siteId: number,
        type: string.isRequired,
        displayName: string.isRequired,
        siteData: object
    }

    state = {
        siteList: [],
        selectedAccount: {
            label: null,
            id: null,
            access_token: null
        },
    }
    mounted = true;

    async componentDidMount() {
        if (this.state.siteList.length) return;
        if (this.props.siteData[this.props.type]) return;
        this.props.fetchAccounts();
    }
    ƒ
    componentDidUpdate(prevProps) {
        if (this.props.social.sites !== prevProps.social.sites) {
            const siteOptions = this.props.social.sites.map(site => ({
                label: `${site.name}`,
                id: site.id,
                access_token: site.access_token
            }));
            this.mounted && this.setState({ siteList: siteOptions })
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleAccountChange(selectedAccount) {
        if (selectedAccount !== null) {
            let accountSelection = Object.assign({}, this.state.selectedAccount);
            accountSelection.label = selectedAccount.label;
            accountSelection.id = selectedAccount.id
            accountSelection.access_token = selectedAccount.access_token
            this.setState({
                selectedAccount: accountSelection,
            });
        }
    }

    render() {
        if (!this.props.siteId) return null;
        if (this.props.siteData[this.props.type]) return null;

        return (
            <Panel containerClass="col-md-12 p-3">
                <Panel.Header title={`Connect ${this.props.displayName}`} />
                <Panel.Body
                    className="account-connect-body"
                    render={() => (
                        <Fragment>
                            <Select
                                name="form-field-name"
                                placeholder="Select Account"
                                value={this.state.selectedAccount}
                                onChange={item => this.handleAccountChange(item)}
                                options={this.state.siteList}
                            />
                            <Button
                                onClick={() =>
                                    this.props.updateSite(this.props.siteId, {
                                        [this.props.type]: this.state.selectedAccount.id,
                                        fb_page_token: this.state.selectedAccount.access_token
                                    })
                                }
                            >
                                Select
                            </Button>
                        </Fragment>
                    )}
                />
            </Panel>
        )
    }
}

const mapStateToProps = state => ({
    ...state.social,
    siteData: { ...state.sites.show.data }
});

export default connect(mapStateToProps, { updateSite })(ThirdPartyConnectFBAnalytics);
