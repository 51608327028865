import React, { Component } from "react";
import { array, func } from "prop-types";
import { connect } from "react-redux";

import { fetchSites } from "screens/sites/actions";
import { NameBadge, SearchBar, ViewWithLoader, Toggle } from "common";
import { filterResults, handleInputChange } from "lib/utilities";

class SitesIndex extends Component {
  static propTypes = {
    fetchSites: func,
    data: array,
  };

  state = { filter: "", isFetching: true, show_archived: false };

  componentDidMount() {
    this.props.fetchSites(() => this.setState({ isFetching: false }));
  }

  handleCheckboxChange = (event) => {
    event.preventDefault();
    this.setState({
      show_archived: !this.state.show_archived,
    });
  };

  render() {
    let results = filterResults(this.props.data, this.state.filter);
    if (this.state.show_archived) {
      results = results.filter((site) => {
        return site.client.archived === 1;
      });
    } else {
      results = results.filter((site) => {
        return site.client.archived === 0;
      });
    }
    return (
      <div className="row">
        <div className="mb-4 pl-3 pr-3">
          <SearchBar
            value={this.state.filter}
            handleChange={(e) => handleInputChange(this, e, "filter")}
          />
        </div>
        <div className="archive-filter is-sites">
          <Toggle
            label="Archived"
            toggle={this.state.show_archived}
            onChange={this.handleCheckboxChange}
          />
        </div>
        <ViewWithLoader classes="row" isLoading={this.state.isFetching}>
          {results.map((site) => {
            return (
              <NameBadge
                key={site.id}
                id={site.id}
                name={site.name}
                subtitle={site.client.name}
                linkTo={`/sites/${site.id}`}
                showGA4Connect={
                  site.ga4_account_id && site.ga4_property_id ? true : false
                }
              />
            );
          })}
        </ViewWithLoader>
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    index: { data },
  },
}) => ({ data });

const connected = connect(mapStateToProps, { fetchSites })(SitesIndex);

export { connected as SitesIndex };
