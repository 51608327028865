import React, { Component } from "react";
import { bool, func, string } from "prop-types";

class TodosForm extends Component {
  static propTypes = {
    formInput: string,
    handleFormInputChange: func,
    handleFormFocus: func,
    handleFormSubmit: func,
    isCreatingNewTodo: bool
  };

  render() {
    const insideFollowing = window.location.pathname.includes("following");
    if (insideFollowing) return null;
    return (
      <form onSubmit={this.props.handleFormSubmit} className="todos-main-form">
        <i className="fa fa-list-ul"></i>
        <input
          className="todos-main-item__input"
          placeholder="Start typing. Press enter to submit!"
          value={this.props.formInput}
          onFocus={this.props.handleFormFocus}
          onChange={e => this.props.handleFormInputChange(e)}
        />
        {this.props.isCreatingNewTodo && (
          <div className="todos-main-item__spinner" />
        )}
      </form>
    );
  }
}

export default TodosForm;
