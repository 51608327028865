import React, { Component } from "react";

import { formatFbOverviewData } from "screens/sites/helpers/facebook_helpers";
import { StatisticsBar } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_facebook.png";

class DemoFacebookAdOverview extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const fbData = [
            {
                "id": "6056915599929",
                "name": {
                    "value": "Lead generation"
                },
                "cpc": {
                    "value": "1.65"
                },
                "ctr": {
                    "value": "2.882814"
                },
                "visitors": {
                    "value": "909"
                },
                "spend": {
                    "value": "1500"
                },
                "conversions": {
                    "value": "18",
                    "meta": [
                        {
                            "type": "comment",
                            "value": "14"
                        },
                        {
                            "type": "leadgen.other",
                            "value": "32"
                        },
                        {
                            "type": "like",
                            "value": "12"
                        },
                        {
                            "type": "offsite_conversion.fb_pixel_lead",
                            "value": "35"
                        },
                        {
                            "type": "landing_page_view",
                            "value": "10"
                        },
                        {
                            "type": "link_click",
                            "value": "415"
                        },
                        {
                            "type": "offsite_conversion",
                            "value": "35"
                        },
                        {
                            "type": "page_engagement",
                            "value": "505"
                        },
                        {
                            "type": "post_engagement",
                            "value": "493"
                        },
                        {
                            "type": "post_reaction",
                            "value": "64"
                        }
                    ]
                },
                "roas": {
                    "value": "30"
                }
            }
        ];
        const data = formatFbOverviewData(fbData);
        return (
            <StatisticsBar
                renderSubtitle={() => (
                    <ReportSubTitle
                        text="Facebook Overview"
                        classes="pt-3 clearfix"
                        logo={logo}
                    />
                )}
                subtitle="Facebook Overview"
                borderColor="#425a94"
                isLoading={isFetching}
                data={data}
            />
        );
    }
}

export default DemoFacebookAdOverview;
