import { createReducer } from "../../lib/utilities";

const initialState = {
  data: []
};

export default createReducer(initialState, {
  FETCHING_TEAMS_SUCCESS: (state, payload) => ({
    ...state,
    data: payload
  })
});
