import React, { Component } from "react";
import { bool, func, shape, string } from "prop-types";
import { getEndDate, getStartDate } from "screens/sites/helpers/date_helpers";

import { Button } from "common";
import { changeDate } from "screens/sites/actions";
import { connect } from "react-redux";
import moment from "moment";
import { push } from "react-router-redux";
import { store } from "store";

class SiteDatePicker extends Component {
  static propTypes = {
    changeDate: func,
    date: shape({
      month: string,
      year: string
    }),
    public_hash: string,
    isPublic: bool
  };

  state = { month: this.props.date.month, year: this.props.date.year };

  componentDidUpdate(prevProps) {
    if (!window.location.href.includes("print")) return;
    if (this.props.isPublic) {
      const startDate = getStartDate(this.props.date);
      const endDate = getEndDate(this.props.date);

      store.dispatch(
        push(
          `/public/sites/${this.props.public_hash}/print?startDate=${
          startDate
          }&endDate=${endDate}`
        )
      );
    }
  }

  renderYearOptions() {
    let currentYear = moment().format("YYYY");
    currentYear = parseInt(currentYear);
    let yearArray = [currentYear - 3, currentYear - 2, currentYear - 1, currentYear];
    return yearArray.map((item, index) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  }

  renderMonthOptions() {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months.map((item, index) => {
      return (
        <option key={index} value={index}>
          {item}
        </option>
      );
    });
  }

  onSelectDateChange = () => {
    const { month, year } = this.state;
    this.props.changeDate({ month, year });
  };

  render() {
    let buttonDisabled = false;
    let month = parseInt(this.state.month, 10) + 1;
    let year = this.state.year;
    let datePicked = moment(`${month}/01/${year}`).endOf('month').subtract('1', 'day').unix();
    const endOfMonth = moment().endOf('month').subtract('1', 'day').unix();
    if (datePicked > endOfMonth) {
      buttonDisabled = true;
    } else {
      buttonDisabled = false;
    }
    const noClickClassName = this.props.noClickClassName || null;
    return (
      <div className={`site-date-picker ${noClickClassName}`}>
        {!this.props.isPublic && <span>Select Report:</span>}
        <select
          defaultValue={this.props.date.month}
          onChange={e => this.setState({ month: e.target.value })}
          className="form-control">
          <option disabled value="default">
            Month
          </option>
          {this.renderMonthOptions()}
        </select>

        <select
          defaultValue={this.props.date.year}
          onChange={e => this.setState({ year: e.target.value })}
          className="form-control">
          <option disabled value="default">
            Year
          </option>
          {this.renderYearOptions()}
        </select>

        <Button disabled={buttonDisabled} onClick={this.onSelectDateChange}>Select</Button>
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: { show: { date, permissions: { isPublic }, data: { public_hash } } }
}) => ({ date, public_hash, isPublic });

export default connect(mapStateToProps, { changeDate })(SiteDatePicker);
