import React, { Component } from "react";
import { number, string } from "prop-types";
import { Button, Panel } from "common";

export default class Mailchimp extends Component {
  static propTypes = {
    siteId: number.isRequired,
    mailchimpApiKey: string
  };

  state = { key: "" };

  render() {
    if (this.props.mailchimpApiKey) return null;
    const isDisabled = !this.state.key;

    return (
      <Panel containerClass="col-md-12 p-3">
        <Panel.Header title="Connect Mailchimp" />
        <Panel.Body
          className="p-5"
          style={{ display: "block" }}
          render={() => {
            return (
              <form
                onSubmit={() => {
                  if (isDisabled) return
                  this.props.updateSite(this.props.siteId, {
                    mailchimp_api_key: this.state.key
                  });
                }}
              >
                <input
                  id="mailchimp-url"
                  type="text"
                  className="form-control mb-4"
                  placeholder="Mailchimp Key"
                  value={this.state.key}
                  onChange={e => this.setState({ key: e.target.value })}
                />
                <Button disabled={isDisabled}>Connect</Button>
              </form>
            );
          }}
        />
      </Panel>
    );
  }
}
