import { ViewWithLoader } from "common";
import React, { Component } from "react";
import { isEmpty } from "lodash"
import { DisconnectButton } from "./DisconnectButton";
import ReviewPanelContent from "./ReviewPanelContent";
import ReviewPanelButtons from "./ReviewPanelButtons";
class ReviewPanel extends Component {
    state = {
        yelpRating: 0,
        facebookRating: 0,
        googleRating: 0,
        googleReviewCount: 0,
        facebookReviewCount: 0,
        yelpReviewCount: 0,
        average: 0,
        calculating: true,
        loading: true,
    }
    componentWillUnmount() {
        this.setState({
            yelpRating: 0,
            facebookRating: 0,
            googleRating: 0,
            googleReviewCount: 0,
            facebookReviewCount: 0,
            yelpReviewCount: 0,
            average: 0,
            calculating: true,
            loading: true,
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.reviewSiteId !== prevProps.reviewSiteId) {
            this.props.reviewSiteId === null && this.setState({ loading: true })
            this.props.reviewSiteId === null && this.setState({ loading: false })
        }
        // Refactor 
        if (prevProps.yelpData !== this.props.yelpData || prevProps.googleData !== this.props.googleData || prevProps.fbData !== this.props.fbData) {
            if (!isEmpty(this.props.googleData)) {
                this.setState(function (state, props) {
                    return {
                        googleRating: this.props.googleData.rating,
                        googleReviewCount: this.props.googleData.user_ratings_total,
                    }
                });
            }
            if (!isEmpty(this.props.fbData)) {
                this.setState(function (state, props) {
                    return {
                        facebookRating: this.props.fbData.overall_star_rating,
                        facebookReviewCount: this.props.fbData.rating_count,
                    }
                });
            }
            if (this.props.yelpData.length > 0) {
                this.setState(function (state, props) {
                    return {
                        yelpRating: this.props.yelpData[0].rating,
                        yelpReviewCount: this.props.yelpData[0].review_count,
                    }
                });
            }
        }
    }

    generateURL = () => {
        if (this.props.name === 'Google') {
            return 'https://search.google.com/local/reviews?placeid=' + this.props.reviewSiteId;
        } else if (this.props.name === 'Yelp') {
            return 'https://www.yelp.com/biz/' + this.props.reviewSiteId;
        } else {
            return 'https://www.facebook.com/pg/' + this.props.reviewSiteId + '/reviews/';
        }
    };

    render() {
        let FB = false;
        if (this.props.fbPageId !== undefined) {
            FB = true;
        }
        return (
            this.props.reviewSiteId == null ?
                <ViewWithLoader isLoading={this.props.isLoading}>
                    <div className="review-panel">
                        <div className="single-review-addition-container">
                            <div className="logo-container">
                                <img width={"30px"} height={"30px"} src={this.props.logo} className="review-logo-margin" alt="Review Loop" />
                            </div>
                            <div className="button-container">
                                {
                                    (this.props.isPublic === false && FB === true) ? (
                                        <h4>Connect Facebook Under Social</h4>
                                    ) : (this.props.isPublic === false) && <ReviewPanelButtons name={this.props.name} showInputFunc={this.props.showInputFunc} displayManualConnectYelp={this.props.displayManualConnectYelp} />
                                }
                            </div>
                        </div>
                    </div>
                </ViewWithLoader>
                :
                <ViewWithLoader isLoading={this.props.isLoading}>
                    <ReviewPanelContent
                        name={this.props.name}
                        reviewSiteId={this.props.reviewSiteId}
                        googleData={this.state.googleRating}
                        yelpData={this.state.yelpRating}
                        fbData={this.state.facebookRating}
                        facebookReviewCount={this.state.facebookReviewCount}
                        googleReviewCount={this.state.googleReviewCount}
                        yelpReviewCount={this.state.yelpReviewCount}
                        logo={this.props.logo}
                        fbPageId={this.props.fbPageId}
                        generateURL={this.generateURL}
                        isPublic={this.props.isPublic}
                    />
                    {
                        // Refactor into DisconnectButton Component
                        this.props.isPublic === false &&
                        <div className="disconnect-container">
                            <div className={`disconnect-x tooltip-disconnect ${this.props.noOptionClass}`} onClick={() => this.props.updateSite(this.props.siteId, { [this.props.type]: null })}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                                <span className="tooltiptext">
                                    Disconnect
                                </span>
                            </div>
                        </div>

                    }
                </ViewWithLoader>
        )
    }
};


export { ReviewPanel };
