import React, { Component } from "react";

import { Panel, Table } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";

class GoogleAnalyticsPageViews extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const pageViewsData = [
            {
                "page": "/",
                "thisMonth": "475",
                "lastMonth": "626",
                "change": "-151",
                "percentChange": "-24%"
            },
            {
                "page": "/gallery/",
                "thisMonth": "384",
                "lastMonth": "497",
                "change": "-113",
                "percentChange": "-23%"
            },
            {
                "page": "/contact/",
                "thisMonth": "145",
                "lastMonth": "184",
                "change": "-39",
                "percentChange": "-21%"
            },
            {
                "page": "/studio3/business/",
                "thisMonth": "125",
                "lastMonth": "225",
                "change": "-100",
                "percentChange": "-44%"
            },
            {
                "page": "/gallery/01/",
                "thisMonth": "86",
                "lastMonth": "76",
                "change": "10",
                "percentChange": "13%"
            },
            {
                "page": "/studio3/business/",
                "thisMonth": "79",
                "lastMonth": "67",
                "change": "12",
                "percentChange": "18%"
            },
            {
                "page": "/studio3/business/",
                "thisMonth": "74",
                "lastMonth": "85",
                "change": "-11",
                "percentChange": "-13%"
            },
            {
                "page": "/about/meet-the-team/",
                "thisMonth": "68",
                "lastMonth": "96",
                "change": "-28",
                "percentChange": "-29%"
            },
            {
                "page": "/about/meet-the-doctor/",
                "thisMonth": "63",
                "lastMonth": "60",
                "change": "3",
                "percentChange": "5%"
            },
            {
                "page": "/studio3/business/",
                "thisMonth": "63",
                "lastMonth": "64",
                "change": "-1",
                "percentChange": "-2%"
            }
        ];
        const pageViews = pageViewsData.map(page => ({
            page: { value: page.page },
            "views this month": { value: page.thisMonth },
            "views last month": { value: page.lastMonth },
            change: { value: page.change },
            "% change": { value: page.percentChange }
        }));
        return (
            <div className="row">
                <ReportSubTitle text="Top Organic Page Views" classes="mt-3" logo={logo} />
                <Panel
                    containerClass="col-md-12 p-3 float-left"
                    isLoading={isFetching}>
                    <Panel.Body
                        style={{
                            display: "block",
                            paddingBottom: "1em"
                        }}
                        render={() => (
                            <Table
                                headers={[
                                    "Page",
                                    "This Month",
                                    "Last Month",
                                    "Change",
                                    "% Change"
                                ]}
                                data={pageViews}
                            />
                        )}
                    />
                </Panel>
            </div>
        );
    }
}

export default GoogleAnalyticsPageViews;

