import axios from "axios";
import { requestHeaders, getToken } from "lib/authHelpers";
import { rootUrl } from "lib/api";
import { dispatchHelper } from "lib/actionHelpers";
import { FETCHING_TEAMS } from "./types";

export const fetchTeams = () => async () => {
  const dispatching = dispatchHelper(FETCHING_TEAMS);
  const token = getToken();
  const url = `${rootUrl}/teams`;
  const headers = requestHeaders(token);

  const response = await axios.get(url, headers);
  dispatching(response);
};
