import React, { Component, Fragment } from "react";
import {
    LineChart,
    XAxis,
    YAxis,
    Line,
    Legend,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { Panel, NoGraphDataNotification } from "common";
import moment from "moment";
import _ from "lodash";
// const CustomTooltip = props => {
//     if (_.isEmpty(props.payload)) return <divs />;

//     return (
//         <section
//             style={{
//                 backgroundColor: "white",
//                 padding: "1em"
//             }}
//         >
//             <div>Day: {props.label}</div>
//             {props.payload.map(data => {
//                 return (
//                     <div key={data.dataKey}>
//                         <div>{`${data.dataKey}: ${data.payload[data.dataKey]}`}</div>
//                     </div>
//                 );
//             })}
//         </section>
//     );
// };

// CustomTooltip.propTypes = {
//     payload: array,
//     label: string
// };


class LineChartLarge extends Component {

    state = {
        dataForLineChart: [],
        hasSecondDateData: false,
        names: ['Roas', 'ROAS', 'Visitors', 'visitors', 'Conversions']
    }

    sortData = (data, type) => {
        let datesInChronologicalOrder = data.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            if (type === "Google") {
                return new Date(a.Day) - new Date(b.Day);
            } else {
                return new Date(a.date_start) - new Date(b.date_start);
            }
        });
        return datesInChronologicalOrder;
    }

    parseValue = (data) => {
        let withParsedValues = data.reduce((all, item, index) => {
            let newVal = parseFloat(item[this.props.selectedStatKey]);
            item[this.props.selectedStatKey] = newVal;
            all.push(item);
            return all;
        }, []);
        return withParsedValues;
    }

    setGraphData = () => {
        let { dateTWO_START, dateTWO_END } = this.props.dates;
        let dataForLineChart = this.formatGraphData();
        dateTWO_START && dateTWO_END ? this.setState({ dataForLineChart, hasSecondDateData: true }, () => this.props.stopLoader("lineChartLoader")) : this.setState({ dataForLineChart }, () => this.props.stopLoader("lineChartLoader"));
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.dateOneDailyData[this.props.selectedStatKey], this.props.dateOneDailyData[this.props.selectedStatKey])) {
            this.setGraphData();
        }
        if (!_.isEqual(prevProps.dateTwoDailyData[this.props.selectedStatKey], this.props.dateTwoDailyData[this.props.selectedStatKey])) {
            this.setGraphData();
        }
        if (this.props.name !== prevProps.name) {
            this.setGraphData();
        }
    }

    padGraphData = (dataArr, dateType) => {
        let dateOnePadded = this.enumerateDates(this.props.dates.dateONE_START, this.props.dates.dateONE_END);
        let dateTwoPadded = this.enumerateDates(this.props.dates.dateTWO_START, this.props.dates.dateTWO_END);
        let paddedDate = dateOnePadded
        if (dateType === "DateTwo") {
            paddedDate = dateTwoPadded;
        };
        let date_key = 'Day';
        if (this.props.type === "Facebook") {
            date_key = 'date_start';
        }
        if (dataArr[0]) {
            // Iterate over each Date in padded array ['6/26/2019', '6/27/2019']
            paddedDate.forEach(date => {
                let included = false;
                // Iterate over each Metric {Day:'6/25/2019', 'Cost_conv: 242 ect...}
                dataArr.forEach(metric => {
                    // If the metric Day value is equal to the date that is set above we know it's included
                    if (metric[date_key] === date) {
                        included = true;
                        if (this.props.type === "Facebook") {
                            _.forOwn(metric, (value, key) => {
                                if (value === undefined) {
                                    metric[key] = 0;
                                }
                            });
                        }
                    }
                });
                if (included === false) {
                    // Date is not included so deep clone the first object to get all keys and values
                    let cloneObj = _.cloneDeep(dataArr[0]);
                    _.forOwn(cloneObj, (value, key) => {
                        if (key === date_key) {
                            // Set the date
                            cloneObj[key] = date
                        } else {
                            // Set all values to 0
                            cloneObj[key] = 0
                        }
                    });
                    // Push onto dataArr which will then be sorted and rendered
                    dataArr.push(cloneObj);
                }
            });
        }
    }

    formatGraphData = () => {
        let dateOneDailyDataCopy = _.cloneDeep(this.props.dateOneDailyData);
        let type = this.props.type;
        if (!_.isEmpty(dateOneDailyDataCopy)) {
            let dateOneDataArr = [];
            _.forOwn(dateOneDailyDataCopy, (value, key) => {
                if (key === this.props.selectedStatKey) {
                    dateOneDataArr = value.data;
                }
            });
            this.padGraphData(dateOneDataArr, 'DateOne');
            let dateOneDailyDataSorted = this.sortData(dateOneDataArr, type);
            let parsedDateOneValues = this.parseValue(dateOneDailyDataSorted);

            if (!_.isEmpty(this.props.dateTwoDailyData)) {
                let dateTwoDataArr = [];
                _.forOwn(this.props.dateTwoDailyData, (value, key) => {
                    if (key === this.props.selectedStatKey) {
                        dateTwoDataArr = value.data;
                    };
                });
                if (dateTwoDataArr[0]) {
                    this.padGraphData(dateTwoDataArr, 'DateTwo');
                    let dateTwoDailyDataSorted = this.sortData(dateTwoDataArr, type);
                    dateTwoDailyDataSorted.forEach((item, i) => {
                        let newVal = parseFloat(item[this.props.selectedStatKey]);
                        if (i < parsedDateOneValues.length) {
                            parsedDateOneValues[i][this.props.selectedStatKey + '(Compared Date)'] = newVal;
                        }
                    });
                }
            }
            let final = parsedDateOneValues
            this.props.stopLoader("lineChartLoader");
            return final;
        }
    }

    enumerateDates = (startDate, endDate) => {
        let momentStart = moment(startDate);
        let momentEnd = moment(endDate);
        var now = momentStart.clone(), dates = [];
        while (now.isSameOrBefore(momentEnd)) {
            dates.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    render() {
        let X_Axis_Key;
        this.props.type === "Facebook" ? (X_Axis_Key = "date_start") : (X_Axis_Key = "Day");
        let isGoogleException = this.props.type === "Google" && this.state.names.includes("Conversions");
        if (this.state.names.includes(this.props.name) && !isGoogleException) {
            return <NoGraphDataNotification />
        }
        return (
            <Fragment>
                <Panel
                    containerClass="col-12 p-3 float-left"
                    isLoading={this.props.lineChartLoader}
                    padding={2}>
                    <Panel.Subtitle text={this.props.lineChartLoader ? null : this.props.name} />
                    <Panel.Body
                        render={() => {
                            return (
                                <ResponsiveContainer height={230} width={"100%"}>
                                    <LineChart
                                        data={this.state.dataForLineChart}
                                        margin={{ top: 5, right: 40, left: -20, bottom: 20 }}
                                    >
                                        <XAxis dataKey={X_Axis_Key} />
                                        <YAxis />
                                        <Legend />
                                        <Tooltip />
                                        <Line
                                            type="monotone"
                                            dataKey={this.props.selectedStatKey}
                                            stroke="#619bf9"
                                            strokeWidth={3}
                                        />
                                        {
                                            (this.state.hasSecondDateData) &&
                                            <Line
                                                type="monotone"
                                                dataKey={this.props.selectedStatKey + '(Compared Date)'}
                                                stroke="#CCCCCC"
                                                strokeWidth={3}
                                            />
                                        }
                                    </LineChart>
                                </ResponsiveContainer>
                            )
                        }}
                    />
                </Panel>
            </Fragment>
        );
    }
}

export { LineChartLarge };
