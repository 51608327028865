import React, { Component } from "react";
import DemoGoogleAnalyticsOrganicSiteMetrics from "./DemoGoogleAnalyticsOrganicSiteMetrics";
import DemoGoogleAnalyticsVisitorsByMonth from "./DemoGoogleAnalyticsVisitorsByMonth";
import DemoGoogleAnalyticsSessionsByMonth from "./DemoGoogleAnalyticsSessionsByMonth";
import DemoGoogleAnalyticsSocialSourcePageViews from "./DemoGoogleAnalyticsSocialSourcePageViews";
import DemoGoogleAnalyticsVisitorsByDevices from "./DemoAnalyticsVisitorsByDevices";
import DemoGoogleAnalyticsVisitorsByLocation from "./DemoGoogleAnalyticsVisitorsByLocation";
import DemoGoogleAnalyticsTopLandingPages from "./DemoGoogleAnalyticsTopLandingPages";
import DemoGoogleAnalyticsPageViews from "./DemoGoogleAnalyticsPageViews";

class DemoAnalytics extends Component {
    render() {
        // Fixtures
        const displayYOY = false;
        return (
            <div className="col-md-12">
                <DemoGoogleAnalyticsOrganicSiteMetrics />
                <DemoGoogleAnalyticsVisitorsByMonth displayYOY={displayYOY} />
                <DemoGoogleAnalyticsSessionsByMonth displayYOY={displayYOY} />
                <DemoGoogleAnalyticsSocialSourcePageViews />
                <DemoGoogleAnalyticsVisitorsByDevices />
                <DemoGoogleAnalyticsVisitorsByLocation />
                <DemoGoogleAnalyticsTopLandingPages />
                <DemoGoogleAnalyticsPageViews />
            </div>
        );
    }
}

export default DemoAnalytics;
