import React, { Component } from "react";
import { ClickableStatsBar, LineChartLarge, ErrorBoundary } from "common";
import AdsInfographic from "./AdsInfographic";
import AdsTable from "./AdsTable";
import PropTypes from "prop-types";
import {
  addFieldForAPI,
  fetchDailyStatsByField,
  chooseStatsBasedOnCampaign,
} from "../helpers/paidAdsHelpers";
import _ from "lodash";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import googleLogo from "images/icon_adwords.png";
import fbLogo from "images/icon_facebook.png";

export default class Campaigns extends Component {
  state = {
    indexOfStatCurrentlyInView: 0,
    selectedStatKey: "Cost_conv",
    loadingStatsForGraph: true,
  };

  selectStatToView = (index, info, isEcomm = false) => {
    let { type, selectedCampaign } = this.props;
    let adType = chooseStatsBasedOnCampaign(selectedCampaign, type, isEcomm);
    let indexOfName = adType.names.indexOf(info.title);
    let key = adType.keys[indexOfName];
    this.setState(
      { indexOfStatCurrentlyInView: index, selectedStatKey: key },
      () => {}
    );
  };

  dailyStatsFetcher = (isSecondDate = false) => {
    let { dateONE_END, dateONE_START, dateTWO_END, dateTWO_START } = this.props;
    let startDate;
    let endDate;
    startDate = dateTWO_START;
    endDate = dateTWO_END;
    if (isSecondDate === false) {
      startDate = dateONE_START;
      endDate = dateONE_END;
    }
    let campaignId = null;
    let params = {
      startDate: startDate,
      endDate: endDate,
    };
    this.props.type === "Facebook" && (params.fb_ads_id = this.props.Ads_ID);
    this.props.type === "Facebook" &&
      (params.selectFBCampaignId = this.props.selectedCampaign.id);
    this.props.type === "Google" && (params.adwords_id = this.props.Ads_ID);
    this.props.type === "Google" &&
      (params.campaign_id = this.props.selectedCampaign.Campaign_ID);
    !_.isEmpty(this.props.selectedCampaign) &&
      fetchDailyStatsByField(
        params,
        this.props.selectedCampaign,
        this.props.type,
        this.props.fetchDailyStats,
        isSecondDate,
        () => {
          this.setState({ loadingStatsForGraph: false });
        },
        this.props.clearCacheBool
      );
  };

  componentDidMount() {
    let startingKey = "Cost_conv";
    this.props.type === "Facebook" && (startingKey = "ctr");
    this.setState(
      { selectedStatKey: startingKey, loadingStatsForGraph: true },
      () => this.dailyStatsFetcher()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { dateONE_END, dateONE_START, dateTWO_END, dateTWO_START } = this.props;
    //     // Fetch Daily Stats data based on date changes
    if (
      dateONE_END !== prevProps.dateONE_END ||
      dateONE_START !== prevProps.dateONE_START
    ) {
      this.setState({ loadingStatsForGraph: true });
      this.dailyStatsFetcher(false);
    }
    if (
      dateTWO_START !== prevProps.dateTWO_START ||
      dateTWO_END !== prevProps.dateTWO_END
    ) {
      this.setState({ loadingStatsForGraph: true });
      this.dailyStatsFetcher(true);
    }
    // Fetch data based on Campaign changes
    if (this.props.selectedCampaign !== prevProps.selectedCampaign) {
      this.dailyStatsFetcher(false);
      this.setState({ loadingStatsForGraph: true });
      if (dateTWO_START && dateTWO_END) {
        this.setState({ loadingStatsForGraph: true });
        this.dailyStatsFetcher(true);
      }
    }
    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      this.setState({ loadingStatsForGraph: true });
      this.dailyStatsFetcher(false);
      if (dateTWO_START && dateTWO_END) {
        this.dailyStatsFetcher(true);
      }
    }
  }

  selectFBCampaign = (data) => {
    let defaultState = {
      id: null,
      name: null,
      insights: [],
      status: null,
    };
    if (!_.isEmpty(this.props.selectedCampaign)) {
      if (data.length > 0) {
        let id = this.props.selectedCampaign.id;
        let campaign = data.find((item) => item.id === id);
        if (campaign === undefined) {
          return defaultState;
        }
        return campaign;
      }
    }
    return defaultState;
  };

  generateGraphTitle = () => {
    let name = "";
    let statKeysAndNames = chooseStatsBasedOnCampaign(
      this.props.selectedCampaign,
      this.props.type,
      this.props.isEcomm
    );
    if (statKeysAndNames === undefined) {
      return "No Value";
    }
    let index = statKeysAndNames.keys.indexOf(this.state.selectedStatKey);
    name = statKeysAndNames.names[index];
    return name;
  };

  calculateConversions = () => {
    let selectedKey = "Conversions";
    this.props.type === "Facebook" && (selectedKey = "conversions");
    let totalConversions = "N/A";
    if (Array.isArray(this.props.dateOneAdValuesData[selectedKey])) {
      if (this.props.dateOneAdValuesData[selectedKey].length > 0) {
        totalConversions = 0;
        this.props.dateOneAdValuesData[selectedKey].forEach((item) => {
          let newVal = parseFloat(item.value);
          totalConversions += newVal;
        });
      }
    } else {
      return this.props.dateOneAdValuesData[selectedKey];
    }
    return totalConversions;
  };

  determineIfEcommerce = () => {
    let isEcomm = false;
    if (this.props.dateOneAdValuesData) {
      if (this.props.dateOneAdValuesData.Total_conv_value) {
        if (this.props.dateOneAdValuesData.Total_conv_value > 0) {
          return true;
        }
      }
    }
    return isEcomm;
  };

  render() {
    let isEcomm = this.determineIfEcommerce();
    let { ad_label, ad_value, spend, Cost, ad_type } =
      this.props.dateOneAdValuesData;
    let { dateONE_END, dateONE_START, dateTWO_END, dateTWO_START } = this.props;
    let graph_name = this.generateGraphTitle();
    let conversionsInfographic = this.calculateConversions();
    if (this.props.selectedCampaign.ad_type === "boost") {
      ad_value = this.props.selectedCampaign.impressions;
    }
    let statKeysAndNames = chooseStatsBasedOnCampaign(
      this.props.selectedCampaign,
      this.props.type,
      this.props.isEcomm
    );
    // Handle when campaigns do not exist in date range
    if (this.props.type === "Facebook" && this.props.campaignDoesNotExist) {
      return <h1>Campaign does not exist in date range</h1>;
    }
    console.log("campaigns props", this.props);
    return (
      <div className="col-12 campaigns-info-container">
        <ErrorBoundary>
          <ReportSubTitle
            text={
              this.props.type === "Google"
                ? "Google Campaigns Overview"
                : "Facebook Campaigns Overview"
            }
            classes="pt-3 clearfix"
            logo={this.props.type === "Google" ? googleLogo : fbLogo}
          />
          <AdsInfographic
            adwordsPerformanceAcctLevel={
              this.props.paidAdsData.adwordsPerformanceAcctLevel
            }
            facebookPerformanceAcctLevel={
              this.props.facebookPerformanceAcctLevel
            }
            dateOneAdValuesData={this.props.dateOneAdValuesData}
            paidAdsData={this.props.paidAdsData}
            isLoading={this.props.infoGraphicLoader}
            type={this.props.type}
            spend={spend || Cost}
            conversions={conversionsInfographic}
            label={ad_label}
            ad_type={ad_type}
            isEcomm={isEcomm}
            stopLoader={this.props.stopLoader}
            ad_value={ad_value}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <ClickableStatsBar
            selectedCampaign={this.props.selectedCampaign}
            type={this.props.type}
            isLoading={this.props.statsBarLoader}
            indexOfStatCurrentlyInView={this.state.indexOfStatCurrentlyInView}
            selectStatToView={this.selectStatToView}
            dateOneAdValuesData={this.props.dateOneAdValuesData}
            stopLoader={this.props.stopLoader}
            dateTwoAdValuesData={this.props.dateTwoAdValuesData}
            clearCacheBool={this.props.clearCacheBool}
            isEcomm={isEcomm}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <LineChartLarge
            name={graph_name}
            type={this.props.type}
            lineChartLoader={this.props.lineChartLoader}
            dates={{ dateONE_END, dateONE_START, dateTWO_END, dateTWO_START }}
            selectedStatKey={this.state.selectedStatKey}
            stopLoader={this.props.stopLoader}
            dateOneDailyData={this.props.dateOneDailyData}
            dateTwoDailyData={this.props.dateTwoDailyData}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <AdsTable
            dates={{
              dateONE_START,
              dateONE_END,
            }}
            statKeysAndNames={statKeysAndNames}
            type={this.props.type}
            data={this.props.tableData}
            fetchTableData={this.props.fetchTableData}
            Ads_ID={this.props.Ads_ID}
            campaignInfo={this.props.selectedCampaign}
            facebookAdPreviews={this.props.facebookAdPreviews}
            fetchAdPreview={this.props.fetchAdPreview}
            clearCacheBool={this.props.clearCacheBool}
            clearAdPreviewState={this.props.clearAdPreviewState}
          />
        </ErrorBoundary>
      </div>
    );
  }
}
