import moment from "moment";
import { getDateFromUrl } from "screens/sites/helpers/endpoint_helpers";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";

try {
  var year = moment(
    getDateFromUrl("startDate", window.location.search),
    "YYYY-MM-DD"
  ).year();
  var month = moment(
    getDateFromUrl("startDate", window.location.search),
    "YYYY-MM-DD"
  ).month();
} catch (e) {
  month = moment()
    .subtract(1, "months")
    .month();

  year = moment()
    .subtract(1, "months")
    .year();
}

export const dateState = {
  date: {
    month: String(month),
    year: String(year),
    dateOneStartForCompare: getStartDate({ year, month }),
    dateOneEndForCompare: getEndDate({ year, month }),
    dateTwoStartForCompare: null,
    dateTwoEndForCompare: null,
  },
};

export const dateReducer = {
  CHANGE_DATE: (state, payload) => {
    const monthInt = parseInt(payload.month) + 1;
    const yearInt = parseInt(payload.year);
    const startOfMonth =
      monthInt >= 10
        ? `${yearInt}-${monthInt}-01`
        : `${yearInt}-0${monthInt}-01`;
    const endOfMonth = new moment(startOfMonth)
      .endOf("month")
      .format("YYYY-MM-DD");
    return {
      ...state,
      show: {
        ...state.show,
        date: {
          ...payload,
          dateOneStartForCompare: startOfMonth,
          dateOneEndForCompare: endOfMonth,
        },
      },
    };
  },
  CHANGE_OVERVIEW_COMPARE_DATE: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        date: {
          ...state.show.date,
          ...payload,
        },
      },
    };
  },
  CHANGE_OVERVIEW_COMPARE_DATE_TWO: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        date: {
          ...state.show.date,
          ...payload,
        },
      },
    };
  },
};
