import React, { Component } from "react";
import { PieGraph } from "common";
import { LikesSourcesFixtures } from "./fixtures/PanelFixtures";

class DemoLikesSources extends Component {
    render() {
        return (
            <PieGraph
                title="Page Likes By Source"
                isLoading={false}
                data={LikesSourcesFixtures}
                heightClassname="height-auto"
            />
        )
    }
}

export { DemoLikesSources };

