import { isAlliance } from "./api";

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer
      ? reducer(state, action.payload)
      : state;
  };
}

export const handleInputChange = (t, e, key) => {
  t.setState({ [key]: e.target.value })
};

export const filterResults = (items, target) => {
  const results = items.filter(item => {
    return item.name
      .toLowerCase()
      .includes(target.toLowerCase());
  })

  return results;
};

const _pipe = (f, g) => (...args) => g(f(...args));

export const pipe = (...fns) => fns.reduce(_pipe);


export function getQueryVar(field, url) {
  let href = url ? url : window.location.href;
  let reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  let string = reg.exec(href);
  return string ? string[1] : false;
};

export function hasQueryVar(field, url) {
  let href = url ? url : window.location.href;
  let reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  let string = reg.exec(href);
  return !!string;
}

export function shadeColor(col, amt) {
  let usePound = false;
  // ESLINT FIX === instead of == flagging in case something breaks
  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}


export let parseYelpUrl = (url) => {
  let yelpUrl = url.split('/biz/')[1];
  if (yelpUrl.substring(0, (yelpUrl.indexOf("?")))) {
    let parsedUrl = yelpUrl.substring(0, (yelpUrl.indexOf("?")));
    return parsedUrl;
  }
  else {
    let parsedUrl = yelpUrl.substring(0, (yelpUrl.indexOf("&")));
    return parsedUrl;
  }
}

export const allianceConnectAccess = (roleId) => {
  if(isAlliance){
    return roleId === 1 ? true: false
  }
  return true
}