import React, { Component } from "react";
import { func, number, object } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as todosActions from "screens/todos/actions";
import { Card } from "common";
import { renderDueDateLabel } from "screens/todos/helpers/todo_helpers";

class TodosContainer extends Component {
  static propTypes = {
    fetchClientTodos: func,
    todos: object,
    clientId: number
  };

  componentDidMount() {
    this.props.fetchClientTodos({ clientId: this.props.clientId });
  }

  render() {
    const activeTodos = this.props.todos.index.data.filter(todo => {
      return todo.status.id === 1;
    });

    return (
      <Card
        title="Todos"
        isLoading={this.props.todos.index.isFetching}
        errors={this.props.todos.index.errors}>
        {activeTodos.map(todo => {
          return (
            <section key={todo.id} className="todos-main-item">
              <section className="todos-main-item__top">
                <Link
                  className="client-todos-item w-100"
                  to={`/todos/clients/${this.props.clientId}/${todo.id}`}>
                  {todo.title}
                </Link>
                <div className="todos-main-item__badges">
                  {renderDueDateLabel(todo.due_date)}
                  {todo.team && (
                    <span className="badge badge-team bg-dark text-white">
                      {todo.team.name}
                    </span>
                  )}
                </div>
              </section>

              <section className="todos-main-item__bottom">

              </section>
            </section>
          );
        })}
      </Card>
    );
  }
}

const mapStateToProps = ({ todos }) => ({ todos });

const connected = connect(mapStateToProps, todosActions)(TodosContainer);

export { connected as TodosContainer };
