import React, { Component, Fragment } from "react";
import { Panel, Table } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_adwords.png";

class DemoGoogleAdwordsCampaigns extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const adwordsCampaignsFixture = [
            {
                "Campaign": "Product #1 ($500/m)",
                "Campaign_state": "enabled",
                "Cost_conv": 172.15,
                "Conversions": "3.00",
                "Cost": 516.45,
                "Clicks": "88",
                "Search_Lost_IS_budget": "49.48%"
            },
            {
                "Campaign": "Product #2 (2K/m)",
                "Campaign_state": "enabled",
                "Cost_conv": 289.07,
                "Conversions": "6.80",
                "Cost": 1965.67,
                "Clicks": "332",
                "Search_Lost_IS_budget": "6.00%"
            }
        ]
        // Sorts campaigns in alphabetical order
        const adwordsCampaignsSorted = adwordsCampaignsFixture.sort((a, b) => a.Campaign !== b.v ? a.Campaign < b.Campaign ? -1 : 1 : 0);
        const adwordsCampaigns = adwordsCampaignsSorted.map(data => ({
            campaign: { value: data.Campaign },
            conversions: { value: data.Conversions },
            clicks: { value: data.Clicks },
            cost: { value: data.Cost },
            "cost / conv": { value: data.Cost_conv },
            "search impressions lost": { value: data.Search_Lost_IS_budget }
        }));
        return (
            <Fragment>
                <ReportSubTitle text="Google Ads" classes="mt-3" logo={logo} />
                <Panel containerClass="col-md-12 p-3" isLoading={isFetching}>
                    <Panel.Body
                        style={{
                            display: "block",
                            paddingBottom: "1em"
                        }}
                        render={() => (
                            <Table
                                headers={[
                                    "Campaign",
                                    "Conversions",
                                    "Clicks",
                                    "Cost",
                                    "Cost / Conv",
                                    "Search Impressions Lost (Budget)"
                                ]}
                                data={adwordsCampaigns}
                            />
                        )}
                    />
                </Panel>
            </Fragment>
        );
    }
}

export default DemoGoogleAdwordsCampaigns;
