import React, { Component } from "react";
import { getEndDate, getStartDate } from "screens/sites/helpers/date_helpers";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { isAlliance } from "lib/api";

class SiteLinks extends Component {
  state = {
    copySuccessMessage: ""
  };

  onCopyToClipboard = e => {
    this.publicLink.select();
    document.execCommand("copy");
    e.target.focus();
    this.setState({ copySuccessMessage: "Copied!" }, () => {
      setTimeout(() => this.setState({ copySuccessMessage: "" }), 3000);
    });
  };

  handleCopy = e => {
    const startDate = getStartDate(this.props.date);
    const endDate = getEndDate(this.props.date);

    e.preventDefault();
    e.clipboardData.setData(
      "text/plain",
      `${window.location.origin}/public/sites/${this.props.public_hash}?startDate=${startDate}&endDate=${endDate}&dy=${this.props.displayYOY}&cp=${this.props.compare}`
    );
  };

  render() {
    const startDate = getStartDate(this.props.date);
    const endDate = getEndDate(this.props.date);
    const { origin } = window.location;
    let prefix = origin.includes("s3sb") ? "2-" : "1-";
    const displayLinks = (!this.props.isPublic && !isAlliance);
    return (
      <div className="site-links">
        {displayLinks && (
          <a
            className="btn leadloop-link"
            href={`https://app.leadloop.io/report/${prefix}${this.props.public_hash}`}
            target="_blank"
          >
            <img
              src="https://static.leadloop.io/images/logo-icon.svg"
              alt="View on LeadLoop"
            />
            View on LeadLoop
          </a>
        )}

        {displayLinks && (
          <input
            className="btn"
            value={this.state.copySuccessMessage || "Copy Old Link"}
            onChange={() => {}}
            onCopy={this.handleCopy}
            onClick={this.onCopyToClipboard}
            ref={publicLink => (this.publicLink = publicLink)}
          />
        )}

        {this.props.isPublic && (
          <a
            className="btn"
            href={`/public/sites/${this.props.public_hash}/print?startDate=${startDate}&endDate=${endDate}&dy=${this.props.displayYOY}&cp=${this.props.compare}`}
            target="_blank"
          >
            Generate PDF
          </a>
        )}

        {displayLinks && (
          <NavLink className="btn" to={`/sites/${this.props.siteId}/edit`}>
            Edit Site
          </NavLink>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { id: siteId, public_hash }
    }
  }
}) => ({
  date,
  siteId,
  public_hash,
  isPublic
});

export default connect(mapStateToProps)(SiteLinks);
