import React, { Component, Fragment } from "react";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { Panel, Table } from "common";
import logo from "images/mailchimp.png";
import _ from "lodash";

export default class MailchimpCampaigns extends Component {
  getDataKeys = () => {
    let keys = ["Campaign"];
    if (this.props.data.length) {
      _.forOwn(this.props.data[0], (value, key) => {
        if (key === "name" || key === "id") {
          return null;
        } else {
          keys.push(
            key.replace(/_/g, " ").replace(/\w\S*/g, function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
          );
        }
      });
    }
    return keys;
  };

  getCampaignMetrics = campaign => {
    let metrics = [];
    for (let [key, value] of Object.entries(campaign)) {
      if(key === "name" || key === "id"){
      } else {
        metrics.push(<td key={key}>{value}</td>);
      
      }
    }
    return metrics;
  };

  render() {
    return (
      <Fragment>
        <ReportSubTitle
          text="Mailchimp Campaigns"
          classes="pt-3 clearfix"
          logo={logo}
        />
        <Panel containerClass="col-md-12 p-3" isLoading={this.props.isLoading}>
          <Panel.Body
            style={{
              display: "block",
              paddingBottom: "1em"
            }}
            render={() => (
              <table className="table responsive-table">
                <thead>
                  <tr>
                    {this.getDataKeys().map((key, id) => {
                      return <th key={id}>{key}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.data.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <tr>
                          <td>{item.name}</td>
                          {this.getCampaignMetrics(item)}
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          />
        </Panel>
      </Fragment>
    );
  }
}
