import React, { Component, Fragment } from "react";

import { Panel, Table } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_facebook.png";

class FacebookAdCampaigns extends Component {
  state = {
    isFetching: false,
    metaData: {},
    ids: [],
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  injectMeta = (data, metaData) => {
    if (Object.keys(metaData).length < 1) {
      return data;
    }

    const metaDataKeys = Object.keys(metaData);
    const result = [];

    data.forEach(campaign => {
      result.push(campaign);

      if (metaDataKeys.includes(campaign.id)) {
        result.push(...metaData[campaign.id]);
      }
    });

    return result;
  };
  render() {
    const metaData = [{
      "id": "6098462753329", "name": "Ad Set for Ad C", "cpc": "0.993647", "ctr": "2.882814", "visitors": "16", "spend": "749.21", "conversions": [{ "action_type": "comment", "value": "14" }, { "action_type": "leadgen.other", "value": "32" }, { "action_type": "like", "value": "12" },
      { "action_type": "offsite_conversion.fb_pixel_lead", "value": "35" },
      { "action_type": "landing_page_view", "value": "10" },
      { "action_type": "link_click", "value": "415" },
      { "action_type": "offsite_conversion", "value": "35" },
      { "action_type": "page_engagement", "value": "505" },
      { "action_type": "post_engagement", "value": "493" }, {
        "action_type": "post_reaction", "value": "64"
      }],
      "conversion_values": null, "roas": null
    },
    {
      "id": "6056915601129", "name": "US - 45+ and Interests", "cpc": null, "ctr": null, "visitors": null,
      "spend": null, "conversions": null, "conversion_values": null, "roas": null
    },
    { "id": "6098462753129", "name": "Ad Set for Ad D", "cpc": null, "ctr": null, "visitors": null, "spend": null, "conversions": null, "conversion_values": null, "roas": null },
    { "id": "6098462753529", "name": "Ad Set for Ad B", "cpc": null, "ctr": null, "visitors": null, "spend": null, "conversions": null, "conversion_values": null, "roas": null }, { "id": "6098462753729", "name": "Ad Set for Ad A", "cpc": null, "ctr": null, "visitors": null, "spend": null, "conversions": null, "conversion_values": null, "roas": null }
    ]

    const isFetching = false;
    const fbData = [
      {
        "id": "6056915599929",
        "name": {
          "value": "Facebook Campaign #1"
        },
        "cpc": {
          "value": "0.993647"
        },
        "ctr": {
          "value": "2.882814"
        },
        "visitors": {
          "value": "16"
        },
        "spend": {
          "value": "749.21"
        },
        "conversions": {
          "value": "1615",
          "meta": [
            {
              "type": "comment",
              "value": "14"
            },
            {
              "type": "leadgen.other",
              "value": "32"
            },
            {
              "type": "like",
              "value": "12"
            },
            {
              "type": "offsite_conversion.fb_pixel_lead",
              "value": "35"
            },
            {
              "type": "landing_page_view",
              "value": "10"
            },
            {
              "type": "link_click",
              "value": "415"
            },
            {
              "type": "offsite_conversion",
              "value": "35"
            },
            {
              "type": "page_engagement",
              "value": "505"
            },
            {
              "type": "post_engagement",
              "value": "493"
            },
            {
              "type": "post_reaction",
              "value": "64"
            }
          ]
        },
        "roas": {
          "value": "-"
        }
      }
    ]
    const withMetaData = this.injectMeta(
      fbData,
      metaData
    );

    const fbHeaders = [
      "name",
      "cpc",
      "ctr",
      "visitors",
      "spend",
      "conversions",
      "roas"
    ];

    return (
      <Fragment>
        <ReportSubTitle text="Facebook Campaigns" classes="mt-3" logo={logo} />
        <Panel containerClass="col-md-12 p-3" isLoading={isFetching}>
          <Panel.Body
            style={{
              display: "block",
              paddingBottom: "1em"
            }}
            render={() => (
              <Table
                headers={fbHeaders}
                data={withMetaData}
                fetchMetaData={this.handleFetchAdsets}
              />
            )}
          />
        </Panel>
      </Fragment>
    );
  }
}


export default FacebookAdCampaigns;




