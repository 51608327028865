import { apiBase } from "lib/api";

export const handleConnectShopify = (params, callback = () => {}) => {
  const { siteId, url, e } = params;
  e && e.preventDefault();

  const pathname = "auth/shopify";
  const qs = `site_id=${siteId}&shopify_url=${url}`;

  const fullUrl = `${apiBase}/${pathname}?${qs}`;
  window.location = fullUrl;

  callback();
};
