import React from "react";

function AutoCompletePopUp(props) {
    return (
        props.address === 'Please select an option from the dropdown' ?
            <div className="confirmation-container">
                <p className="reviews-font-headline mt-2">{props.address}</p>
            </div>
            :
            null
    );
}

export { AutoCompletePopUp };