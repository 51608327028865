

import React, { Component } from "react";
import { PieGraph } from "common";

class DemoGoogleAnalyticsVisitorsByDevices extends Component {
    render() {
        // Fixtures
        const devices = {
            "mobile": 955,
            "desktop": 752,
            "tablet": 51
        }
        const isFetching = false;
        return (
            <PieGraph
                title="New Visitors by Devices"
                isLoading={isFetching}
                data={devices}
            />
        );
    }
}

export default DemoGoogleAnalyticsVisitorsByDevices;
