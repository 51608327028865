import React, { Component } from "react";

import DemoShopifySales from "./DemoShopifySales";

import DemoAmazonSales from "./DemoAmazonSales";

class DemoECommerce extends Component {

    render() {
        return (
            <div className="col-sm-12">
                <DemoShopifySales />
                <DemoAmazonSales />
            </div>
        );
    }
}

export default DemoECommerce;
