import _ from "lodash";
import React, { Component } from "react";
import { bool, number, string, array } from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList
} from "recharts";
import { Panel } from "common";

class GenericBarChart extends Component {
  static propTypes = {
    title: string.isRequired,
    data: array.isRequired,
    isLoading: bool,
    errors: string,
    barSize: number,
    className: string,
    gridClass: string,
    isCurrency: bool,
    printView: bool,
  };

  static defaultProps = {
    barSize: 40,
    gridClass: 'col-md-6',
    data: {}
  };

  render() {
    const isLoading = this.props.isLoading || _.isEmpty(this.props.data);
    return (
      <Panel
        containerClass={this.props.gridClass + " p-3 float-left " + this.props.className}
        isLoading={isLoading}
        errors={this.props.errors}
        padding={2}
      >
        <Panel.Subtitle text={this.props.title} />
        <Panel.Body
          render={() => {
            return (
              <ResponsiveContainer height={230}>
                <BarChart
                  width={600}
                  height={300}
                  data={this.props.data}
                  margin={{ top: 10, right: 0, left: 0, bottom: 5 }}
                >
                  <XAxis tickLine={false} dataKey="name" />
                  {/* If Add $ to currency value */}
                  {
                    this.props.isCurrency === true ?
                      <Tooltip formatter={(value) => ('$' + value)} /> :
                      <Tooltip />
                  }
                  {/* If on print page add label with value to bar */}
                  {
                    this.props.printView ?
                      <Bar maxBarSize={20} className={"yoy-bar"} dataKey="value" fill="#619bf9">
                        <LabelList dataKey="value" position="top" />
                      </Bar>
                      :
                      <Bar maxBarSize={20} className={"yoy-bar"} dataKey="value" fill="#619bf9" />
                  }

                </BarChart>
              </ResponsiveContainer>
            )
          }}
        />
      </Panel>
    );
  }
}

export { GenericBarChart };
