import React, { Component, Fragment } from "react";
class DemoTwitter extends Component {

    render() {
        return (
            <Fragment>
                }
                    <div className="twitter-card-container">
                    <div className="twitter-card">
                        <div className="twitter-card-banner">
                            <img className="background-cover" alt="twitter background" src={"https://picsum.photos/200/300/?random"}></img>
                        </div>
                        <div className="twitter-card-content">
                            <div className="twitter-card-handle-name">
                                <p className="twitter-card-font">{"Company Name"}</p>
                                <p className="twitter-card-handle">{"@Company_Handle"}</p>
                            </div>
                            <div className="twitter-card-stats">
                                <div className="twitter-stat-container">
                                    <span className="twitter-stat-header">
                                        Tweets
                                        </span>
                                    <span className="twitter-stat">
                                        {200}
                                    </span>
                                </div>
                                <div className="twitter-stat-container">
                                    <span className="twitter-stat-header">
                                        Following
                                        </span >
                                    <span className="twitter-stat">
                                        {50}
                                    </span>
                                </div>
                                <div className="twitter-stat-container">
                                    <span className="twitter-stat-header">
                                        Followers
                                        </span>
                                    <span className="twitter-stat">
                                        {800}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="twitter-card-profile-pic">
                            <img className="background-cover-profile" alt="twitter card profile" src={"https://picsum.photos/200/300/?random"}></img>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}




export { DemoTwitter };
