import _ from "lodash";
import React, { Component } from "react";
import { func, array, number, shape, string, bool } from "prop-types";
import { connect } from "react-redux";

import { Panel, Table } from "common";
import { fetchGoogleAnalyticsEvents } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { getPreviousMonthStart, getPreviousMonthEnd } from "lib/dateHelpers";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";

class GoogleAnalyticsEvents extends Component {
  static propTypes = {
    fetchGoogleAnalyticsEvents: func,
    events: array,
    siteId: number,
    ga_property_id: string,
    date: shape({
      month: string,
      year: string
    }),
    isPublic: bool,
    public_hash: string
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };

  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
      prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId =
        this.props.ga_property_id !== nextProps.ga_property_id;

    if (!this.props.ga_property_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
        prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
        prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.props.clearCacheBool === true && this.props.fetchGoogleAnalyticsEvents(
          params,
          () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = params => {
    if (!params.siteId || !this.props.ga_property_id) return;
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsEvents(
        params,
        () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.ga_property_id) return null;
    const events = this.props.events;
    const eventsData = (this.props.events || []).map(n => ({
      "event category": { value: n.eventCategory },
      "event action": { value: n.eventAction },
      "event label": { value: n.eventLabel },
      "total": { value: n.total },
    }));
    if (this.state.showPrintView) {
      return (
          <div className="row">
            <div className="col-xl-12">
              <table className="table">
                <thead>
                <tr>
                  <th colSpan="5">Events</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Event Category</td>
                  <td>Event Action</td>
                  <td>Event Label</td>
                  <td>Total Events</td>
                </tr>
                {_.map(events, (item, index) => {
                  return (
                      <tr key={index}>
                        <td>{item.eventCategory}</td>
                        <td>{item.eventAction}</td>
                        <td>{item.eventLabel}</td>
                        <td>{item.total}</td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
      );
    }
    return (
        <div className="row">
          <ReportSubTitle text="Events By Category" classes="mt-3" logo={logo} />
          <Panel
              containerClass="col-md-12 p-3 float-left"
              isLoading={this.state.isFetching}>
            <Panel.Body
                style={{
                  display: "block",
                  paddingBottom: "1em"
                }}
                render={() => (
                    <Table
                        headers={[
                          "Event Category",
                          "Event Action",
                          "Event Label",
                          "Total Events"
                        ]}
                        data={eventsData}
                    />
                )}
            />
          </Panel>
        </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, id: siteId, ga_property_id },
      analyticsData: { events },
    }
  }
}) => ({
  date,
  siteId,
  ga_property_id,
  events,
  isPublic,
  public_hash
});

export default connect(mapStateToProps, {
  fetchGoogleAnalyticsEvents
})(GoogleAnalyticsEvents);
