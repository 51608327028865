import React from "react";

const StatCard = (props) => {
  return (
    <div className={'stat-card-wrapper card'}>
      {props.stats.map((stat, idx) => (
        <div key={idx} className={'stat-box'}>
          <div className={'stat-value'}>{stat.value}</div>
          <div className={'stat-header'}>{stat.label}</div>
        </div>
      ))}
    </div>
  );
}

export default StatCard;