import React from "react";

const cleanUrl = (str) => {
  if (String(str).includes('http')) {
    return String(str)
      .replace(/(http\:\/\/|https\:\/\/)/g, '')
      .replace(/\//g, '');
  }
  return str;
}

const formatNumber = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const SiteCard = (props) => {

  const sites = props.dataset.map(site => ({
    title: cleanUrl(site[props.col1Key]),
    total: formatNumber(parseFloat(site.seo) + parseFloat(site.a11y)),
    seo: formatNumber(site.seo),
    a11y: formatNumber(site.a11y),
    hostname: site.hostname,
    pathname: site.pathname,
    page_hash: site.page_hash,
  }));

  return (
    <div className={'card'}>
      <div className={'report-row header-row'}>
        <div className={'title'}>{props.title}</div>
        <div className={''}>Total Errors</div>
        <div className={''}>SEO Errors</div>
        <div className={''}>A11y Errors</div>
      </div>
      {sites.map((site, idx) => (
        <div key={idx} className={'report-row'}>
          <div className={'title link'} onClick={e => props.onClick(e, site)}>{site.title}</div>
          <div className={''}>{site.total}</div>
          <div className={''}>{site.seo}</div>
          <div className={''}>{site.a11y}</div>
        </div>
      ))}
    </div>
  );
}

export default SiteCard;