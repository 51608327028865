import _ from "lodash";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Radium from "radium";

import { Panel } from "common";

const generateChildStyle = (data) => ({
  "@media (min-width: 800px)": {
    width: _.size(data) > 4 ? "33%" : 100 / _.size(data) + "%",
    borderBottom: "none",
  },
  "@media (min-width: 1024px)": {
    width: 100 / _.size(data) + "%",
  },
});

StatisticsBarBody.propTypes = {
  data: PropTypes.object,
  isPublic: PropTypes.bool,
};

function StatisticsBarBody({ data, isPublic, props }) {
  const childStyle = generateChildStyle(data);
  let colorClass = "";
  return (
    <div className="statistics-bar" style={styles.container}>
      {_.map(data, (item, index) => {
        if (item.positiveChange === true) {
          colorClass = "colorGreen";
        } else {
          colorClass = null;
        }
        return (
          <section
            key={index}
            className="statistics-bar-item"
            style={[childStyle, "border: 1px solid red"]}
          >
            <span className="title">{item.title}</span>
            <span
              className="value fontDefault"
              style={[isPublic && styles.value]}
            >
              {item.value}
            </span>
            {item.change && item.change !== "0.00" && (
              <span
                className={`stats-substat ${
                  props.fromPaidAds ? "changeDefault" : "change"
                }${colorClass} ${
                  item.positiveChange && item.title !== "Bounce Rate"
                    ? "" //was text-success
                    : ""
                }`}
                style={[isPublic && styles.change]}
              >
                {item.positiveChange ? `+${item.change}` : `-${item.change}`}
              </span>
            )}{" "}
            {item.bubbleValues ? (
              <div className="social-bubble-organic-paid-container">
                {(item.bubbleValues || []).map((n) => (
                  <div className="social-bubble-organic-paid">{n}</div>
                ))}
              </div>
            ) : null}
          </section>
        );
      })}
    </div>
  );
}

const StatisticsBarBodyWithRadium = Radium(StatisticsBarBody);

class StatisticsBar extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    isPublic: PropTypes.bool,
    errors: PropTypes.string,
    data: PropTypes.object,
    borderColor: PropTypes.string,
    renderSubtitle: PropTypes.func,
  };

  static defaultProps = {
    borderColor: "white",
    renderSubtitle: () => {},
    fromPaidAds: false,
  };

  renderBody = (data, isPublic) => (
    <StatisticsBarBodyWithRadium
      data={data}
      isPublic={isPublic}
      props={this.props}
    />
  );

  render() {
    return (
      <Fragment>
        {this.props.renderSubtitle()}
        <Panel
          containerClass="col-md-12 p-3"
          isLoading={this.props.isLoading}
          errors={this.props.errors}
        >
          <Panel.Body
            style={{
              display: this.props.isLoading ? "block" : "inherit",
              padding: this.props.isLoading ? ".2em" : "1.5em 3em",
              borderTop: `2px solid #fff`,
              //borderTop: `2px solid ${this.props.borderColor}`,
            }}
            render={() => this.renderBody(this.props.data, this.props.isPublic)}
          />
        </Panel>
      </Fragment>
    );
  }
}

const styles = {
  container: {
    "@media (min-width: 800px)": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  value: {
    fontSize: "2em",
  },
  change: {
    display: "none",
    height: 0,
  },
};

export { StatisticsBar };

// const DATA = {
//   Item1: {
//     title: "Item1",
//     value: "2775",
//     change: "833",
//     positiveChange: true
//   },
//   Item2: {
//     title: "Item2",
//     value: "$39602.73",
//     change: "$7746.90",
//     positiveChange: true
//   },
// };
