import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import * as actions from "screens/tools/actions";
import { fetchSites } from "screens/sites/actions";

import StatCard from "../components/StatCard";
import SiteCard from "../components/SiteCard";
import TestCard from "../components/TestCard";
import TestFilters from "../components/TestFilters";

class ToolsIndex extends Component {
  static propTypes = {
  };

  state = {
    sites: [],
    level: 0,
    breadcrumb: ['Sites'],
    search: '',
    activeSite: undefined,
    filterExcludes: []
  };

  componentDidMount() {
    let excludes = [];
    try {
      excludes = JSON.parse(localStorage.getItem('hubble-filter-excludes'));
      excludes = (excludes === null) ? [] : excludes;
    } catch(e) {};
    this.setState({
      filterExcludes: excludes
    });

    this.fetchAllSites();
    //this.props.fetchSites(() => {});

    setInterval(() => {
      if (this.state.level === 0) {
        this.fetchAllSites();
      } else if (this.state.level === 1 && this.state.activeSite) {
        this.fetchActiveSite();
      }
    }, 1000 * 60 * 5);

    this.setState({
      sites: []
    })
  }

  fetchAllSites() {
    this.props.fetchHubbleStats();
    //this.props.fetchHubbleSites();
  }

  fetchActiveSite() {
    const activeSite = this.state.activeSite;
    if (activeSite) {
      this.props.fetchHubbleSite({ hostname: activeSite });
      this.props.fetchHubbleSiteStats({ hostname: activeSite });
    }
  }

  handleBreadcrumbClick(idx) {
    if (idx === 0) {
      this.fetchAllSites();
    }
    this.setState({
      level: idx,
      search: '',
      breadcrumb: this.state.breadcrumb.slice(0, idx + 1),
      activeSite: (idx === 0) ? undefined : this.state.activeSite
    })
  }

  formatNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  parseFloats(n) {
    return ({
      ...n,
      seo: parseFloat(n.seo),
      a11y: parseFloat(n.a11y),
    });
  }

  buildTotals(n) {
    return ({
      ...n,
      total: (n.seo + n.a11y)
    })
  }

  cleanUrl(str) {
    if (String(str).includes('http')) {
      return String(str)
        .replace(/(http\:\/\/|https\:\/\/)/g, '')
        .replace(/\//g, '');
    }
    return str;
  }

  sortByTotal (a, b) {
    return (b.total - a.total);
  }

  handleTestLinkClick(e, row) {
    const linkPath = `https://${this.state.activeSite}${row.pathname}?h=${row.test_hash}`;
    window.open(linkPath);
  }

  handleTestFilterChange(excludes) {
    this.setState({
      filterExcludes: excludes
    });
  }

  handleRowClick(e, row) {


    if (this.state.level < 2) {
      setTimeout(() => {
        ([...document.getElementsByClassName('search-wrapper')][0].children[0] || {}).focus();
      }, 50);
    }

    if (this.state.level === 0) {
      this.props.fetchHubbleSite({ hostname: row.hostname });
      this.props.fetchHubbleSiteStats({ hostname: row.hostname });
      this.setState({
        level: 1,
        search: '',
        breadcrumb: [...this.state.breadcrumb, row.hostname],
        activeSite: row.hostname
      });
      return;
    }

    if (this.state.level === 1) {
      this.props.fetchHubbleSiteDetails({ pageHash: row.page_hash });
      this.setState({
        level: 2,
        search: '',
        breadcrumb: [...this.state.breadcrumb, row.pathname],
      });
    }
  }

  handleSearchChange(e, value) {
    this.setState({
      search: e.target.value
    })
  }

  mapStatsToDataset(dataset, stats, matchKey) {
    return dataset.map(n => {
      const out = { ...n };
      out.stats = stats.filter(o => o[matchKey] === n[matchKey] && n[matchKey] !== undefined).map(o => ({
        name: o.name,
        handled_errors: o.handled_errors,
        unhandled_errors: o.unhandled_errors,
        category: o.category
      }));
      return out;
    })
  }

  formatSites() {
    const stats = (this.props.tools.stats || {}).data || [];
    const siteMap = {}
    for (let i = 0; i < stats.length; ++i) {
      siteMap[stats[i].hostname] = true;
    }
    const dataset = Object.keys(siteMap).map(n => ({ hostname: n}));
    return this.mapStatsToDataset(dataset, stats, 'hostname');
  }

  formatSite() {
    const dataset = ((this.props.tools.site || {}).data || [])
      .map(this.parseFloats)
      .map(this.buildTotals)
      .sort(this.sortByTotal);
    const stats = (this.props.tools.siteStats || {}).data || [];
    return this.mapStatsToDataset(dataset, stats, 'page_hash');
  }

  formatName(str) {
    function toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }
    const testNameMap = {
      'SEO': 'SEO',
      'IMAGE_ALT_TAG': 'Image Alt Tag',
      'A_HREF_TAG': 'A Tag HREF',
      'PAGE_H1': 'Page H1 Tag',
      'MISSING_ALT_TAG': 'Missing Alt Tag',
    }
    return testNameMap[str] || toTitleCase(String(str).replace(/_/g, ' '));
  }

  formatSiteDetails() {
    const details = ((this.props.tools.siteDetails || {}).data || []);
    const testSetMap = {};
    for (let i = 0; i < details.length; ++i) {
      let test = details[i];

      test.label = this.formatName(test.name);
      test.message = this.formatName(test.message);
      test.category = this.formatName(test.category);
      try {
        test.payload = JSON.parse(test.payload);
      } catch(e) {}

      if (test.payload.source && String(test.payload.source).includes('img')) {
        let img = String(test.payload.source).match(/src="(.*?)"/);
        if (img && img[1]) {
          test.payload.img = img[1];
        }
      }

      testSetMap[test.name] = testSetMap[test.name] || {
        name: test.name,
        label: test.label,
        category: test.category,
        children: []
      };
      testSetMap[test.name].children.push(test);
    }

    let testSets = [];
    for (const key in testSetMap) {
      testSets.push(testSetMap[key])
    }

    return testSets;
  }

  aggregateDatasetStatsByFilters(dataset) {
    let filters = [];
    const filterMap = {};
    dataset.forEach(n => {
      n.stats.forEach(o => {
        const name = String(o.name).toUpperCase();
        filterMap[name] = filterMap[name] || { handled: 0, unhandled: 0 };
        filterMap[name].handled += parseFloat(o.handled_errors);
        filterMap[name].unhandled += parseFloat(o.unhandled_errors);
        filterMap[name].category = o.category;
      })
    });
    for (const key in filterMap) {
      filters.push({
        key,
        label: this.formatName(key),
        amount: filterMap[key].unhandled,
        category: filterMap[key].category
      });
    }

    const reduceCategoryStats = (data, category) => {
      return data
        .filter(n => (n.category === category && !this.state.filterExcludes.includes(n.name)))
        .map(n => parseFloat(n.unhandled_errors))
        .reduce((a = 0, b = 0) => (a + b), 0);
    }

    dataset = dataset.map(o => {
      o.seo = reduceCategoryStats(o.stats, 'SEO');
      o.a11y = reduceCategoryStats(o.stats, 'ACCESSIBILITY');
      o.total = o.seo + o.a11y;
      return o;
    }).filter(n => (n.total !== 0)).sort((a, b) => (b.total - a.total));

    filters = filters.sort((a, b) => (a.category > b.category ? -1 : (b.category > a.category) ? 1 : 0));

    return ({ dataset, filters });
  }

  getSiteUrls() {
    return (((this.props.sites || {}).index || {}).data || []).map(n => (n.live_url))
      .map(this.cleanUrl)
      .filter(n => (String(n).trim() !== ''));
  }

  render() {
    const fetchingSite = this.props.tools.site.isFetching;
    const fetching = (this.state.level === 0) ? false : (this.state.level === 1) ? fetchingSite : false;

    // Determine display strings based on level.
    const stat1Title = ['Websites', 'Pages', ''][this.state.level];
    const col1Title = ['Site', 'Page', 'Test Name'][this.state.level];
    const col1Key = ['hostname', 'pathname', 'name'][this.state.level];

    // Get active dataset based on level.
    const siteDataset = this.formatSites();
    let dataset = (this.state.level === 0)
      ? siteDataset
      : (this.state.level === 1)
        ? this.formatSite()
        : this.formatSiteDetails();

    // Levels 0 and 1 search
    if (this.state.level < 2) {
      if (this.state.search !== '') {
      const s = String(this.state.search).trim().toLowerCase();
        dataset = dataset.filter(n => {
          if (this.state.level === 0) {
            return String(n.hostname).includes(s);
          } else if (this.state.level === 1) {
            return String(n.pathname).includes(s);
          }
          return true;
        });
      }
    }

    let filters = [];

    if (this.state.level < 2) {
      const result = this.aggregateDatasetStatsByFilters(dataset);
      dataset = result.dataset;
      filters = result.filters;
    }

    // Test-level data
    if (this.state.level === 2) {
      filters = dataset.map(n => ({ key: n.name, label: `${this.formatName(n.name)}`, amount: n.children.length, category: n.category }));
      dataset = dataset.filter(n => !this.state.filterExcludes.includes(String(n.name).toUpperCase()));
      if (this.state.search !== '') {
        const s = String(this.state.search).trim().toLowerCase();
        dataset = dataset.map(n => {
          return {
            ...n,
            children: n.children.filter(row => {
              if (String(row.message).toLowerCase().includes(s)) {
                return true;
              }
              if (row.payload && String(row.payload.fixedSource).toLowerCase().includes(s)) {
                return true;
              }
              if (row.payload && String(row.payload.source).toLowerCase().includes(s)) {
                return true;
              }
              return false;
            })
          }
        });
      }
    }

    //const totals = this.aggregateDatasetTotals(dataset);
    const totalSEO = dataset.length < 1 ? 0 : dataset.map(n => n.seo || 0).reduce((a, b) => parseFloat(a + b));
    const totalA11y = dataset.length < 1 ? 0 : dataset.map(n => n.a11y || 0).reduce((a, b) => parseFloat(a + b));
    const total = this.formatNumber(totalSEO + totalA11y);

    const stat1Value = [dataset.length, dataset.length, ''][this.state.level];

    const stats = [
      {value: stat1Value, label: stat1Title},
      {value: total, label: 'Total Errors'},
      {value: this.formatNumber(totalSEO), label: 'SEO Errors'},
      {value: this.formatNumber(totalA11y), label: 'Accessibility Errors'},
    ];

    const SiteCards = (() => (
      <Fragment>
        <StatCard stats={stats}/>
        <div className={'report-card-wrapper'}>
          <SiteCard title={col1Title} col1Key={col1Key} dataset={dataset} onClick={(e, site) => this.handleRowClick(e, site)}/>
        </div>
      </Fragment>
    ));

    const TestCards = (() => (
      <div className={'report-card-wrapper'}>
        {dataset.map((tests, idx) => (
          <TestCard key={idx} tests={tests} domain={this.state.activeSite} onRowClick={(e, row) => this.handleRowClick(e, row)} onLinkClick={(e, row) => this.handleTestLinkClick(e, row)}/>
        ))}
      </div>
    ));

    return (
      <Fragment>
        <h1>Hubble</h1>
        <div className={'hubble-breadcrumb'}>
          {this.state.breadcrumb.map((n, idx) => (
            <div className={'hubble-breadcrumb-item'} key={idx} onClick={e => (this.handleBreadcrumbClick(idx))}>{this.cleanUrl(n)}</div>
          ))}
        </div>
        <TestFilters filters={filters} excludes={this.state.filterExcludes} onChange={(data) => this.handleTestFilterChange(data)}/>
        <div className={'search-wrapper'}>
          <input type={'text'} placeholder={'Search'} value={this.state.search} onChange={e => this.handleSearchChange(e)} />
        </div>
        {this.state.level < 2 && !fetching && (<SiteCards />)}
        {this.state.level === 2 && (<TestCards />)}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ tools, sites }) => ({
  tools,
  sites
});

const connected = connect(mapStateToProps, {
  ...actions,
  fetchSites
})(ToolsIndex);

export { connected as ToolsIndex };
