import React, { Component } from "react";
import { func, number, string } from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

import { Button, Panel } from "common";
import {
  fetchGoogleAnalyticsAccounts,
  fetchGoogleAnalyticsProperties,
} from "screens/sites/helpers/google_analytics_helpers";
import { updateSite } from "screens/sites/actions";

class GoogleAnalyticsConnect extends Component {
  static propTypes = {
    siteId: number,
    ga_property_id: string,
    updateSite: func,
  };

  static defaultProps = {
    siteId: 0,
  };

  state = {
    account_id: null,
    property_id: null,
    selectedAccount: "",
    selectedProperty: "",
    accounts: [],
  };

  mounted = true;

  componentDidMount() {
    fetchGoogleAnalyticsAccounts(
      this.props.siteId,
      (accounts) => this.mounted && this.setState({ accounts })
    );
  }

  async componentDidUpdate(nextProps) {
    if (!this.props.siteId) return;
    if (nextProps.siteId === this.props.siteId) return;

    fetchGoogleAnalyticsAccounts(
      this.props.siteId,
      (accounts) => this.mounted && this.setState({ accounts })
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleAccountChange(selectedAccount, type) {
    selectedAccount =
      selectedAccount === null ? { value: "" } : selectedAccount;

    this.setState({
      [type]: selectedAccount.id,
      selectedAccount,
    });

    fetchGoogleAnalyticsProperties(
      this.props.siteId,
      selectedAccount.id,
      (properties) => this.setState({ properties })
    );
  }

  handlePropertyChange(selectedProperty, type) {
    selectedProperty =
      selectedProperty === null ? { value: "" } : selectedProperty;

    this.setState({
      [type]: selectedProperty.id,
      selectedProperty,
    });
  }

  render() {
    const isPrimarySite =
      this.props.primarySiteId &&
      this.props.siteId &&
      this.props.siteId === this.props.primarySiteId;
    if (this.props.ga_property_id) return null;
    if (isPrimarySite) return null;
    return (
      <Panel containerClass="col-md-12 p-3">
        <Panel.Header title="Connect Google Analytics" />
        <Panel.Body
          className="p-5"
          style={{ display: "block" }}
          render={() => {
            return (
              <div
                className="clearfix"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="float-left mr-3">
                  <Select
                    placeholder="Select Account"
                    name="form-field-name"
                    value={this.state.selectedAccount}
                    onChange={(item) =>
                      this.handleAccountChange(item, "account_id")
                    }
                    options={this.state.accounts}
                    valueKey="name"
                    labelKey="name"
                  />
                </div>
                {this.state.properties && (
                  <div className="float-left mr-3">
                    <Select
                      placeholder="Select Property"
                      name="form-field-name"
                      value={this.state.selectedProperty}
                      onChange={(item) =>
                        this.handlePropertyChange(item, "property_id")
                      }
                      options={this.state.properties}
                      valueKey="name"
                      labelKey="name"
                    />
                  </div>
                )}
                {this.state.property_id && this.state.account_id && (
                  <Button
                    onClick={() =>
                      this.props.updateSite(this.props.siteId, {
                        ga_property_id: this.state.property_id,
                      })
                    }
                  >
                    Save
                  </Button>
                )}
              </div>
            );
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ga_property_id: state.sites.show.data.ga_property_id,
    primarySiteId: state.sites.show.data.client.primary_site_id,
    siteId: state.sites.show.data.id,
  };
};

export default connect(mapStateToProps, { updateSite })(GoogleAnalyticsConnect);
