
export let LikesPaidOrganicFixtures = [
    {
        "month": "12",
        "label": "Dec",
        "paid": 100,
        "organic": 100,
        "total": 200
    },
    {
        "month": "11",
        "label": "Nov",
        "paid": 200,
        "organic": 250,
        "total": 400
    },
    {
        "month": "1",
        "label": "Jan",
        "paid": 300,
        "organic": 325,
        "total": 625
    }
]

export let ImpressionsFixtures = [
    {
        "name": "Nov",
        "value": 21705
    },
    {
        "name": "Dec",
        "value": 85571
    },
    {
        "name": "Jan",
        "value": 137272
    }
]

export let VideoViewsFixtures = [
    {
        "name": "Nov",
        "value": 1520
    },
    {
        "name": "Dec",
        "value": 2027
    },
    {
        "name": "Jan",
        "value": 3500
    }
]

export let LikesSourcesFixtures = {
    "Ads": 277,
    "Your Page": 57,
    "Page Suggestions": 24,
    "Other": 21,
    "Search": 19
};

export let AudienceDemographicsFixtures = {
    "Male": 15,
    "Female": 84
};

export let AgeGenderBreakdownFixtures =
    [
        {
            "category": "Female",
            "value": "45-54"
        },
        {
            "category": "Female",
            "value": "35-44"
        },
        {
            "category": "Female",
            "value": "25-34"
        },
        {
            "category": "Female",
            "value": "55-64"
        },
        {
            "category": "Female",
            "value": "18-24"
        },
        {
            "category": "Female",
            "value": "65+"
        },
        {
            "category": "Male",
            "value": "25-34"
        },
        {
            "category": "Male",
            "value": "35-44"
        },
        {
            "category": "Male",
            "value": "45-54"
        },
        {
            "category": "Male",
            "value": "18-24"
        },
        {
            "category": "Male",
            "value": "55-64"
        },
        {
            "category": "Male",
            "value": "65+"
        },
        {
            "category": "Male",
            "value": "45-54"
        },
        {
            "category": "Female",
            "value": "13-17"
        },
        {
            "category": "Male",
            "value": "35-44"
        },
        {
            "category": "Male",
            "value": "55-64"
        },
        {
            "category": "Male",
            "value": "25-34"
        },
        {
            "category": "Male",
            "value": "65+"
        },
        {
            "category": "Male",
            "value": "18-24"
        }
    ];

export let DemoLocationsFixtures = [
    {
        "city": "Los Angeles, CA",
        "value": 929
    },
    {
        "city": "Redondo Beach, CA",
        "value": 173
    },
    {
        "city": "Long Beach, CA",
        "value": 94
    },
    {
        "city": "Torrance, CA",
        "value": 85
    },
    {
        "city": "Manhattan Beach, CA",
        "value": 78
    }
]