import _ from "lodash";
import React from "react";
import { array, func, number, string, oneOfType } from "prop-types";

FormGroupSelect.propTypes = {
  label: string,
  onChange: func.isRequired,
  options: array.isRequired,
  classes: string,
  value: oneOfType([string, number])
};

FormGroupSelect.defaultProps = {
  classes: ""
};

function FormGroupSelect(props) {
  return (
    <div className={`form-group ${props.classes}`}>
      <label>{props.label}</label>
      <select
        value={props.value}
        onChange={props.onChange}
        className="form-control">
        <option value="default">{props.label}</option>
        {_.map(props.options, item => {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export { FormGroupSelect };
