import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import * as actions from "screens/users/actions";
import { store } from "store";
import { Card } from "common";
import { handleInputChange } from "lib/utilities";

import { LoginForm } from './LoginForm'
import { ForgotPassword } from './ForgotPassword';

class UsersAuthenticate extends Component {
  state = {
    email: "",
    password: "",
    visible: false
  };
  componentWillMount() {
    this.checkIfAuthenticated();
  }

  componentWillReceiveProps() {
    this.checkIfAuthenticated();
  }

  checkIfAuthenticated() {
    if (this.props.user.authentication.data.token) {
      const location = this.props.lastVisited;
      store.dispatch(push(location));
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.authenticateUser({ email, password });
  };

  showForgotPasswordForm = (e) => {
    e.preventDefault();
    this.setState(function (prevState, props) {
      return { visible: !prevState.visible };
    });
  }



  render() {
    const { email, password } = this.state;
    const isDisabled = email === "" || password === "";
    const cardLabel = this.state.visible ? "Password Reset" : "Login";
    let errors = this.props.user.authentication.errors || this.props.user.reset.error
    let success = this.props.user.reset.success
    return (
      <Card
        title={cardLabel}
        classes="col-sm-12 col-md-6 offset-md-3"
        errors={errors}
        success={success}
        padding={5}
      >
        <LoginForm
          email={email}
          password={password}
          visible={this.state.visible}
          handleInputChange={handleInputChange}
          handleSubmit={this.handleSubmit}
          isDisabled={isDisabled}
          UsersAuthenticateComponent={this}
          showForgotPasswordForm={this.showForgotPasswordForm}
        />
        <ForgotPassword
          email={email}
          visible={this.state.visible}
          isDisabled={isDisabled}
          UsersAuthenticateComponent={this}
          showForgotPasswordForm={this.showForgotPasswordForm}
        />
      </Card>
    );
  }
}

UsersAuthenticate.propTypes = {
  user: PropTypes.object,
  authenticateUser: PropTypes.func,
  lastVisited: PropTypes.string
};

const mapStateToProps = ({ user, lastVisited }) => ({ user, lastVisited });

const connected = connect(mapStateToProps, actions)(UsersAuthenticate);

export { connected as UsersAuthenticate };
