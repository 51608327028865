import dateFormat from "dateformat";

export const leadsState = {
  callRailData: {
    calls: undefined,
    page: undefined,
    per_page: undefined,
    total_pages: undefined,
    total_records: undefined,
    loading: false,
  },
  leadData: {
    leads: [],
    formattedLeads: [],
    leadsById: {},
    total_leads: null,
    type: {},
  },
  leadAnalytics: {
    types: [],
    mediums: [],
    sources: [],
    history: [],
    total_leads: 0,
  },
};

export const leadsReducer = {
  RESET_TOTAL_LEADS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        leadAnalytics: {
          ...state.show.leadAnalytics,
          total_leads: payload,
        },
      },
    };
  },
  FETCHING_CALL_RAIL_CALLS_SUCCESS: (state, payload) => {
    return {
      ...state,
      callRailData: payload,
    };
  },
  SET_CALL_RAIL_LOAD_STATE: (state, payload) => {
    return {
      ...state,
      callRailData: {
        ...state.callRailData,
        ...payload,
      },
    };
  },

  FETCHING_LEADS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      leadData: {
        ...state.show.leadData,
        ...payload,
      },
    },
  }),
  FETCHING_LEAD_ANALYTICS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      leadAnalytics: {
        ...state.show.leadAnalytics,
        ...payload,
      },
    },
  }),
  POSTING_LEAD_DATA_SUCCESS: (state, payload) => {
    const index = state.show.leadData.leads.findIndex(
      (item) => item.lead_id === payload.lead_id
    );
    const selectedLead = state.show.leadData.leads[index];
    selectedLead.lead_meta = payload;

    const updatedLeads = [
      ...state.show.leadData.leads.slice(0, index),
      selectedLead,
      ...state.show.leadData.leads.slice(index + 1),
    ];

    const formattedLeads = updatedLeads.map((lead) => {
      return {
        id: { value: lead.lead_id },
        "lead type": { value: lead.lead_type, meta: lead.lead_meta },
        source: { value: lead.lead_source },
        "phone name": { value: lead.phone_name },
        medium: { value: lead.lead_medium },
        date: { value: dateFormat(lead.date_created, "mmm dS h:MMtt") },
      };
    });

    return {
      ...state,
      show: {
        ...state.show,
        leadData: {
          ...state.show.leadData,
          leads: updatedLeads,
          formattedLeads,
        },
      },
    };
  },
};
