import { createReducer } from "../../../lib/utilities";
import moment from "moment";

const formatInstagramInsights = ({ payload }) => {
  const { reach_28_day, impressions_28_day, data_date } = payload;
  const date = moment(data_date).add(5, "days");
  const dateFormatted = date.format("MMM");
  const instagramReach = {
    name: dateFormatted,
    Reach: reach_28_day ? reach_28_day : 0,
  };
  const instagramImpressions = {
    name: dateFormatted,
    Impressions: impressions_28_day ? impressions_28_day : 0,
  };
  return { instagramReach, instagramImpressions };
};

const initialState = {
  social: {
    clicks: [],
    engagement: [],
    video: [],
    age: [],
    likes: [],
    impressions: [],
    growth: [],
    location: [],
    fans: [],
    sites: [],
    twitter: [],
    temp: [],
    instagram_reach_impressions_followers: [],
    instagram_demographics: [],
    follower_count: null,
  },
  instagramReachCurrentMonthSelection: undefined,
  instagramReachTwoMonthsAgo: undefined,
  instagramReachThreeMonthsAgo: undefined,
  instagramImpressionsCurrentMonthSelection: undefined,
  instagramImpressionsTwoMonthsAgo: undefined,
  instagramImpressionsThreeMonthsAgo: undefined,
  instagramCommentsCountCurrentMonth: undefined,
  instagramFollowerGrowthCurrentMonth: undefined,
  instagramEngagementRateCurrentMonth: undefined,
  instagramLikesCountCurrentMonth: undefined,
  instagramProfileViewsCurrentMonth: undefined,
  instagramSharesCountCurrentMonth: undefined,
  instagramSavedCountCurrentMonth: undefined,
  instagramCurrentMonthLoadState: true,
  instagramTwoMonthsAgoLoadState: true,
  instagramThreeMonthsAgoLoadState: true,
  instagramPostsLoadState: true,
  instagramPosts: undefined,
};

export default createReducer(initialState, {
  FETCHING_INSTAGRAM_INSIGHTS_CURRENTLY_SELECTED_MONTH_SUCCESS: (
    state,
    payload
  ) => {
    const { instagramReach, instagramImpressions } = formatInstagramInsights({
      payload,
    });
    return {
      ...state,
      instagramReachCurrentMonthSelection: instagramReach,
      instagramImpressionsCurrentMonthSelection: instagramImpressions,
      instagramCommentsCountCurrentMonth: payload.comments_count,
      instagramFollowerGrowthCurrentMonth: payload.follower_growth,
      instagramEngagementRateCurrentMonth: payload.ig_engagement_rate_pct,
      instagramLikesCountCurrentMonth: payload.likes_count,
      instagramProfileViewsCurrentMonth: payload.sum_ig_profile_views,
      instagramSavedCountCurrentMonth: payload.saved_count,
      instagramSharesCountCurrentMonth: payload.shares_count,
      instagramCurrentMonthLoadState: false,
    };
  },
  FETCHING_INSTAGRAM_INSIGHTS_TWO_MONTHS_FROM_DATE_SELECTION_SUCCESS: (
    state,
    payload
  ) => {
    const { instagramReach, instagramImpressions } = formatInstagramInsights({
      payload,
    });
    return {
      ...state,
      instagramReachTwoMonthsAgo: instagramReach,
      instagramImpressionsTwoMonthsAgo: instagramImpressions,
      instagramTwoMonthsAgoLoadState: false,
    };
  },
  FETCHING_INSTAGRAM_INSIGHTS_THREE_MONTHS_FROM_DATE_SELECTION_SUCCESS: (
    state,
    payload
  ) => {
    const { instagramReach, instagramImpressions } = formatInstagramInsights({
      payload,
    });
    return {
      ...state,
      instagramReachThreeMonthsAgo: instagramReach,
      instagramImpressionsThreeMonthsAgo: instagramImpressions,
      instagramThreeMonthsAgoLoadState: false,
    };
  },
  FETCHING_SOCIAL_DATA_FANS_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      fans: [payload],
    },
  }),
  FETCHING_SOCIAL_DATA_GROWTH_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      growth: payload.data,
    },
  }),
  FETCHING_SOCIAL_DATA_IMPRESSIONS_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      impressions: payload.data,
    },
  }),
  FETCHING_SOCIAL_DATA_ENGAGEMENT_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      engagement: payload.data,
    },
  }),
  FETCHING_SOCIAL_DATA_VIDEO_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      video: payload.data,
    },
  }),
  FETCHING_SOCIAL_DATA_AGE_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      age: payload.data,
    },
  }),
  FETCHING_SOCIAL_DATA_LOCATION_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      location: payload.data,
    },
  }),
  FETCHING_SOCIAL_DATA_CLICKS_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      clicks: payload.data,
    },
  }),
  FETCHING_SOCIAL_DATA_LIKESANDPAGEVIEWS_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      likes: payload.data,
    },
  }),
  FETCHING_SOCIAL_SITES_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      sites: payload,
    },
  }),
  FETCHING_SOCIAL_TWITTER_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      twitter: payload,
    },
  }),
  FETCHING_SOCIAL_INSTAGRAM_INSIGHTS_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      instagram_reach_impressions_followers: [
        ...state.social.instagram_reach_impressions_followers,
        ...payload.data,
      ],
    },
  }),
  FETCHING_SOCIAL_INSTAGRAM_POST_DATA_SUCCESS: (state, payload) => ({
    ...state,
    instagramPosts: payload,
  }),
  SET_INSTAGRAM_POSTS_LOAD_STATE: (state, payload) => {
    return {
      ...state,
      instagramPostsLoadState: payload,
    };
  },
  FETCHING_SOCIAL_INSTAGRAM_DEMOGRAPHIC_DATA_SUCCESS: (state, payload) => ({
    ...state,
    social: {
      ...state.social,
      instagram_demographics: payload,
    },
  }),
  FETCHING_FOLLOWERS_COUNT_SUCCESS: (state, payload) => {
    return {
      ...state,
      social: {
        ...state.social,
        follower_count: payload,
      },
    };
  },
});
