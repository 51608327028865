import qs from "qs";
import { rootUrl } from "lib/api";
import {
  startOfMonth,
  endOfMonth,
  getPreviousMonthStart,
  getPreviousMonthEnd,
} from "lib/dateHelpers";
import { googleAnalyticsRootUrl } from "lib/api";

export const getHashFromUrl = (pathName) => {
  const regExp = /sites\/(.*)/;
  return pathName.match(regExp)[1];
};

export const getDateFromUrl = (stringParam, url) => {
  try {
    stringParam += "=(.{0,10})";
    const regExp = new RegExp(stringParam);
    return url.match(regExp)[1];
  } catch (e) {
    if (stringParam.includes("startDate")) {
      return getPreviousMonthStart(startOfMonth);
    }
    if (stringParam.includes("endDate")) {
      return getPreviousMonthEnd(endOfMonth);
    }
  }
};

export const withNoQueryString = (params, route) => {
  const { isPublic, hash, siteId } = params;
  const namespace = isPublic ? "/public" : "";
  const identifier = isPublic ? hash : siteId;
  return rootUrl + namespace + route + identifier;
};

export const startAndEndDate = (params, route, GAVersion = "v1") => {
  const { isPublic, siteId, hash, startDate, endDate } = params;
  const namespace = isPublic ? "/public" : "";
  const identifier = isPublic ? hash : siteId;
  const search = qs.stringify({ startDate, endDate });
  return (
    googleAnalyticsRootUrl +
    GAVersion +
    namespace +
    route +
    identifier +
    "?" +
    search
  );
};

export const startAndEndDateAll = (params, route, GAVersion = "v1") => {
  const { isPublic, siteId, hash, startDate, endDate } = params;
  const namespace = isPublic ? "/public" : "";
  const identifier = isPublic ? hash : siteId;
  const search = qs.stringify({ startDate, endDate });
  return (
    googleAnalyticsRootUrl +
    GAVersion +
    namespace +
    route +
    identifier +
    "/all" +
    "?" +
    search
  );
};

export const withPreviousMonth = (params, route, GAVersion = "v1") => {
  const {
    isPublic,
    siteId,
    hash,
    startDate,
    endDate,
    prevStart,
    prevEnd,
  } = params;
  const namespace = isPublic ? "/public" : "";
  const identifier = isPublic ? hash : siteId;
  const search = qs.stringify({ startDate, endDate, prevStart, prevEnd });
  return (
    googleAnalyticsRootUrl +
    GAVersion +
    namespace +
    route +
    identifier +
    "?" +
    search
  );
};

export const generateLeadEndpoint = (params) => {
  const {
    isPublic,
    page,
    hash,
    token,
    secret,
    startDate,
    endDate,
    type,
    mergeChildSites,
    siteId,
  } = params;
  const namespace = isPublic ? "/public" : "";
  const identifier = isPublic
    ? `${hash}/${page}`
    : `${token}/${secret}/${page}`;
  const search = qs.stringify({
    startDate,
    endDate,
    type,
    mergeChildSites,
    siteId,
  });
  return rootUrl + namespace + "/leads/" + identifier + "?" + search;
};
