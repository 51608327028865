/* eslint-disable */
import {
  getHumanReadableTime,
  changePercentDifference,
} from "lib/numberHelper";

const formatReports = payload => {
  let { reports } = payload;
  if (reports[0].data.rows === undefined) {
    return undefined;
  }
  let metrics = reports[0].data.rows[0].metrics;
  const thisMonthMetrics = metrics[0].values;
  const lastMonthMetrics = metrics[1].values;
  const formattedData = {};

  const displayNewUsers = thisMonthMetrics[4] > 1000
    ? (thisMonthMetrics[4] / 1000).toFixed(1) + "k"
    : Math.round(thisMonthMetrics[4]);
  formattedData["New Users"] = {
    title: "New Users",
    value: displayNewUsers,
    change: changePercentDifference(
      thisMonthMetrics[4],
      lastMonthMetrics[4],
    ),
    positiveChange: parseInt(thisMonthMetrics[4]) > parseInt(lastMonthMetrics[4])
  };

  formattedData["Bounce Rate"] = {
    title: "Bounce Rate",
    value: `${Math.round(thisMonthMetrics[3])}%`,
    change: Math.round(thisMonthMetrics[3]) - Math.round(lastMonthMetrics[3]) + "%",
    positiveChange: thisMonthMetrics[3] > lastMonthMetrics[3]
  };

  const displaySessions = thisMonthMetrics[5] > 1000
    ? (thisMonthMetrics[5] / 1000).toFixed(1) + "k"
    : Math.round(thisMonthMetrics[5]);
  formattedData["Sessions"] = {
    title: "Sessions",
    value: displaySessions,
    change: changePercentDifference(
      thisMonthMetrics[5],
      lastMonthMetrics[5],
    ),
    positiveChange: parseInt(thisMonthMetrics[5]) > parseInt(lastMonthMetrics[5])
  };

  formattedData["Pages/Session"] = {
    title: "Pages/Session",
    value: Math.round(thisMonthMetrics[2]),
    change: changePercentDifference(
      thisMonthMetrics[2],
      lastMonthMetrics[2],
    ),
    positiveChange: parseInt(thisMonthMetrics[2]) > parseInt(lastMonthMetrics[2])
  };

  formattedData["Avg. Session Duration"] = {
    title: "Avg. Session Duration",
    value: getHumanReadableTime(thisMonthMetrics[0]),
    change: changePercentDifference(
      thisMonthMetrics[0],
      lastMonthMetrics[0],
    ),
    positiveChange: parseInt(thisMonthMetrics[0]) > parseInt(lastMonthMetrics[0])
  };

  const displayPageViews = thisMonthMetrics[1] > 1000
    ? (thisMonthMetrics[1] / 1000).toFixed(1) + "k"
    : Math.round(thisMonthMetrics[1]);
  formattedData["Page Views"] = {
    title: "Page Views",
    value: displayPageViews,
    change: changePercentDifference(
      thisMonthMetrics[1],
      lastMonthMetrics[1],
    ),
    positiveChange: parseInt(thisMonthMetrics[1]) > parseInt(lastMonthMetrics[1])
  };

  return formattedData;
};

export const googleAnalyticsSiteMetrics = ({ dispatch }) => next => action => {
  if (!action.fromGASiteMetrics) {
    return next(action);
  }

  const formattedData = formatReports(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGASiteMetrics: false,
  };
  console.log("🚀 ~ file: google_analytics_site_metrics.js:94 ~ googleAnalyticsSiteMetrics ~ newAction:", newAction)

  dispatch(newAction);
};

const formatReportsSiteMetricsAnalyticsV4 = (payload) => {
  const thisMonthMetrics = payload.rows[0].current;
  const lastMonthMetrics = payload.rows[0].previous;
  const formattedData = {};
  if (payload.rows.length === 0) {
    return formattedData;
  }
  formattedData['New Users'] = {
    title: 'New Users',
    value: parseInt(thisMonthMetrics.newUsers) ? parseInt(thisMonthMetrics.newUsers).toLocaleString() : 'N/A',
    change: changePercentDifference(parseInt(thisMonthMetrics.newUsers), parseInt(lastMonthMetrics.newUsers)),
    positiveChange: parseInt(thisMonthMetrics.newUsers) > parseInt(lastMonthMetrics.newUsers),
  };

  formattedData['Sessions'] = {
    title: 'Sessions',
    value: thisMonthMetrics.averageSessionDuration ? parseInt(thisMonthMetrics.averageSessionDuration).toFixed(0).toLocaleString() : 'N/A',
    change: changePercentDifference(thisMonthMetrics.averageSessionDuration, lastMonthMetrics.averageSessionDuration),
    positiveChange: parseInt(thisMonthMetrics.averageSessionDuration) > parseInt(lastMonthMetrics.averageSessionDuration),
  };

  formattedData['Pages/Session'] = {
    title: 'Pages/Session',
    value: Math.round(thisMonthMetrics.screenPageViewsPerSession),
    change: changePercentDifference(thisMonthMetrics.screenPageViewsPerSession, lastMonthMetrics.screenPageViewsPerSession),
    positiveChange: parseInt(thisMonthMetrics.screenPageViewsPerSession) > parseInt(lastMonthMetrics.screenPageViewsPerSession),
  };

  formattedData['Avg. Session Duration'] = {
    title: 'Avg. Session Duration',
    value: getHumanReadableTime(thisMonthMetrics.averageSessionDuration),
    change: changePercentDifference(thisMonthMetrics.averageSessionDuration, lastMonthMetrics.averageSessionDuration),
    positiveChange: parseInt(thisMonthMetrics.averageSessionDuration) > parseInt(lastMonthMetrics.averageSessionDuration),
  };

  formattedData['Page Views'] = {
    title: 'Page Views',
    value: thisMonthMetrics.screenPageViews ? parseInt(thisMonthMetrics.screenPageViews).toLocaleString() : 'N/A',
    change: changePercentDifference(thisMonthMetrics.screenPageViews, lastMonthMetrics.screenPageViews),
    positiveChange: parseInt(thisMonthMetrics.screenPageViews) > parseInt(lastMonthMetrics.screenPageViews),
  };


  return formattedData
};


export const googleAnalyticsSiteMetricsGA4 = ({ dispatch }) => next => action => {
  if (!action.fromGASiteMetricsGA4) {
    return next(action);
  }

  const formattedData = (formatReportsSiteMetricsAnalyticsV4(action.payload) || []);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGASiteMetricsGA4: false,
  };

  dispatch(newAction);
};
