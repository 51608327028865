import React from "react";
import { array, bool, func, object } from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getListStyle, getItemStyle } from "screens/todos/helpers/dnd_helpers";

TemplateDetailList.propTypes = {
  data: array,
  editMode: bool,
  onDragEnd: func,
  handleChangeTemplateItem: func,
  handleDeleteTemplateItem: func
};

function TemplateDetailList(props) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {props.data.map((item, subIndex) => (
              <Draggable
                key={subIndex}
                draggableId={`${item.title}+${subIndex}`}
                index={subIndex}
              >
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <TemplateDetailItem
                        key={subIndex}
                        editMode={props.editMode}
                        item={item}
                        onChange={e =>
                          props.handleChangeTemplateItem(e, subIndex)
                        }
                        handleDeleteTemplateItem={props.handleDeleteTemplateItem.bind(
                          this,
                          subIndex
                        )}
                      />
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

TemplateDetailItem.propTypes = {
  editMode: bool,
  item: object,
  onChange: func,
  handleDeleteTemplateItem: func
};

function TemplateDetailItem({
  editMode,
  item,
  onChange,
  handleDeleteTemplateItem
}) {
  return (
    <div
      className="template-detail-item"
      style={{ background: editMode ? "#fcfcde" : "white" }}
    >
      <i className="fas fa-ellipsis-v mr-2" />
      <input
        value={item.title}
        readOnly={!editMode}
        onChange={onChange}
        style={{
          background: editMode ? "lightyellow" : "white",
          fontSize: item.section ? "1.5em" : "1em",
          fontWeight: item.section ? "bold" : "normal"
        }}
      />
      <button
        className="template-detail-item__trash"
        onClick={handleDeleteTemplateItem}
        style={{ opacity: editMode ? 1 : 0 }}
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  );
}

export default TemplateDetailList;
