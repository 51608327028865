const grid = 3;
export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "white" : "white",
  ...draggableStyle
})

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  width: "100%"
});

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
