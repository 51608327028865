import React from "react";
import { string, node } from "prop-types";

const LinkifyLink = props => (
  <a {...props} onClick={() => window.open(props.href, "_blank")}>
    {props.children}
  </a>
);

LinkifyLink.propTypes = { href: string, children: node };

export { LinkifyLink };
