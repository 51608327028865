import React, { Component } from 'react';
import { Panel } from 'common';

class AgeGenderBreakdown extends Component {
  render() {
    let formattedData = this.props.data.map((el, i) => {
      let tmp = el.category.split('.');
      return {
        category: tmp[0] === 'F' ? 'Female' : 'Male',
        value: tmp[1],
      };
    });
    return (
      <Panel isLoading={false} errors={this.props.errors} padding={2}>
        <Panel.Subtitle text={'Top Demographics'} />
        <Panel.Body
          render={() => {
            return (
              <ul className="social-demo-breakdown">
                {formattedData.map((el, i) => {
                  if (i < 6)
                    return (
                      <li className={'social-demo-breakdown_item is-' + el.category.toLowerCase()} key={i}>
                        <i>{i + 1}</i>
                        <span>{el.category + 's'}</span> Ages {el.value}
                      </li>
                    );
                })}
              </ul>
            );
          }}
        />
      </Panel>
    );
  }
}

export default AgeGenderBreakdown;
