import axios from "axios";
import { rootUrl } from "lib/api";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import { CREATING_NOTE, DELETING_NOTE, EDIT_NOTE, FETCH_NOTES } from "./types";

export const createNote = (params, callback) => async () => {
  const dispatching = dispatchHelper(CREATING_NOTE);
  const token = getToken();
  const url = `${rootUrl}/notes`;
  const headers = requestHeaders(token);

  let response = await axios.post(url, params, headers);
  dispatching(response, callback);
};

export const editNote = (params, callback) => async () => {
  const dispatching = dispatchHelper(EDIT_NOTE);
  const token = getToken();
  const url = `${rootUrl}/notes/edit`;
  const headers = requestHeaders(token);

  let response = await axios.post(url, params, headers);
  dispatching(response, callback);
};

export const deleteNote = id => async () => {
  const dispatching = dispatchHelper(DELETING_NOTE);
  const token = getToken();
  const url = `${rootUrl}/notes/delete`;
  const params = { id };
  const headers = requestHeaders(token);

  let response = await axios.post(url, params, headers);
  response.data = id;
  dispatching(response);
};


export const fetchNotes = (client_id, page, callback) => async () => {
  const dispatching = dispatchHelper(FETCH_NOTES);
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/notes/${client_id}`;
  const params = { page }
  const config = {
    ...headers,
    params
  }
  let response = await axios.get(url, config);
  dispatching(response, callback);
}