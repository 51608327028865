import axios from "axios";
import { push } from "react-router-redux";
import { rootUrl } from "lib/api";
import { requestHeaders, getToken } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import { authLogoutAndRedirect } from "screens/users/actions";
import { fetchSBTickets } from "screens/clients/actions";

import {
  FETCHING_CLIENTS,
  UPDATING_CLIENT,
  UPDATING_CLIENT_CONTACT,
  UPDATING_CLIENT_IMAGE,
  CREATING_CLIENT,
  FETCHING_CLIENT,
} from "./types";

export const fetchClients = (callback = () => {}) => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_CLIENTS, dispatch);
  const token = getToken();
  const url = `${rootUrl}/clients`;
  const headers = requestHeaders(token);

  try {
    const response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const updateClient = (id, params) => async (dispatch) => {
  const dispatching = dispatchHelper(UPDATING_CLIENT, dispatch);
  const url = `${rootUrl}/clients/${id}`;
  const token = getToken();
  const headers = requestHeaders(token);

  let response = await axios.post(url, params, headers);
  dispatching(response, () => dispatch(push(`/clients/${id}`)));
};

export const updateClientContacts = (contacts, id) => async (dispatch) => {
  const dispatching = dispatchHelper(UPDATING_CLIENT_CONTACT, dispatch);
  const token = getToken();
  const url = `${rootUrl}/update/contacts`;
  const params = { contacts, id };
  const headers = requestHeaders(token);

  let response = await axios.post(url, params, headers);
  dispatching(response);
};

export const updateClientImage = (params) => async (dispatch) => {
  const dispatching = dispatchHelper(UPDATING_CLIENT_IMAGE, dispatch);
  const token = getToken();
  const url = `${rootUrl}/client/image`;
  const headers = requestHeaders(token);

  let response = await axios.post(url, params, headers);
  dispatching(response);
};

export const createClient = (params) => async (dispatch) => {
  const dispatching = dispatchHelper(CREATING_CLIENT, dispatch);
  const token = getToken();
  const url = `${rootUrl}/clients/new`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);

  const response = await axios.post(url, postParams, headers);
  dispatching(response, () => dispatch(push(`/clients/${response.data.id}`)));
};

export const fetchClient = (params, callback = () => {}) => async (
  dispatch
) => {
  const dispatching = dispatchHelper(FETCHING_CLIENT, dispatch);
  const url = params.isPublic
    ? `${rootUrl}/public/clients/${params.id}`
    : `${rootUrl}/clients/${params.id}`;
  const token = getToken();
  const headers = requestHeaders(token);

  try {
    const response = await axios.get(url, headers);
    params.isPublic
      ? dispatching(response)
      : dispatching(
          response,
          fetchSBTickets(response.data.supportbee_label, 1)
        );
    callback();
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};
