import React, { Component } from "react";
import { bool, func, string, shape, number, object } from "prop-types";
import { connect } from "react-redux";

import { StatisticsBar } from "common";
import { fetchGoogleAnalyticsSiteMetrics } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { getPreviousMonthStart, getPreviousMonthEnd } from "lib/dateHelpers";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";

class GoogleAnalyticsOrganicSiteMetrics extends Component {
  static propTypes = {
    fetchGoogleAnalyticsSiteMetrics: func,
    ga_property_id: string,
    siteId: number,
    metrics: object,
    date: shape({
      month: string,
      year: string
    }),
    public_hash: string,
    isPublic: bool
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.ga_property_id) return;
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
      prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic
    };

    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId =
      this.props.ga_property_id !== nextProps.ga_property_id;

    if (!this.props.ga_property_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
        prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.fetchData(params);
    }

    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
        prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.props.clearCacheBool === true && this.props.fetchGoogleAnalyticsSiteMetrics(
        params,
        () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsSiteMetrics(params, () => {
      this.mounted && this.setState({ isFetching: false });
    });
  };

  render() {
    if (!this.props.ga_property_id) return null;
    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="6">Monthly Organic Site Metrics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>New Users</td>
                  <td>Bounce Rate</td>
                  <td>Sessions</td>
                  <td>Pages/Session</td>
                  <td>Avg. Session Duration</td>
                  <td>Page Views</td>
                </tr>
                <tr>
                  <td>{(this.props.metrics["New Users"] || {}).value}</td>
                  <td>{(this.props.metrics["Bounce Rate"] || {}).value}</td>
                  <td>{(this.props.metrics["Sessions"] || {}).value}</td>
                  <td>{(this.props.metrics["Pages/Session"] || {}).value}</td>
                  <td>{(this.props.metrics["Avg. Session Duration"] || {}).value}</td>
                  <td>{(this.props.metrics["Page Views"] || {}).value}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return (
      <StatisticsBar
        renderSubtitle={() => (
          <ReportSubTitle
            text="Monthly Organic Site Metrics"
            classes="pt-3 clearfix"
            logo={logo}
          />
        )}
        borderColor="#ef7a30"
        isLoading={this.state.isFetching}
        data={this.props.metrics}
      />
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, ga_property_id, id: siteId },
      analyticsData: { metrics }
    }
  }
}) => ({
  date,
  siteId,
  ga_property_id,
  metrics,
  isPublic,
  public_hash
});

export default connect(mapStateToProps, { fetchGoogleAnalyticsSiteMetrics })(
  GoogleAnalyticsOrganicSiteMetrics
);
