import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropDown, MultipleDatesCalendar } from "common";
import _ from "lodash";
const AccountOverview = props => {
  return (
    <div
      onClick={() => props.changeView("accountOverview")}
      className="account-overview-button-container col-3"
    >
      <button className="round-button-styling">Account Overview</button>
    </div>
  );
};

class PaidAdsOptions extends Component {
  constructor (props) {
    super(props);
    this.state = {
      dropdown: []
    };
  }

  sortDropdown = dataForDropDown => {
    if (dataForDropDown.options.length > 0) {
      if (this.props.type === "Google") {
        dataForDropDown.options.sort((a, b) => a.roas !== b.v ? (a.roas > b.roas ? -1 : 1) : 0);
      } else {
        // (Array.isArray(value)
        dataForDropDown.options.forEach(item => {
          if(item.roas === null){
            item.roas = [{action_type: 'none', value: 0}]
          }
        });
        dataForDropDown.options.sort((a, b) =>  a.roas[0].value !== b.v ? (a.roas[0].value > b.roas[0].value ? -1 : 1) : 0);
      }
    }
  };

  filterDropdownDataMCC = (data) => {
    if(data.options){
      if(data.options.length > 0){
        let filteredData = data.options.filter(item => item.mcc !== true);
        data.options = filteredData;
      }
    }
    return data
  }

  componentDidUpdate(prevProps, prevState) {
    let dataForDropDown = {
      name: this.props.name,
      options: [...this.props.data]
    };

    if (this.props.data !== prevProps.data) {
      this.sortDropdown(dataForDropDown);
      this.setState({ dropdown: dataForDropDown });
    }
  }

  render() {
    let dataForMCCDropdown = {
      name: this.props.MCCName,
      options: this.props.paidAdsData.MCC_Accounts
    };
    let filterMCCDropdown = this.filterDropdownDataMCC(dataForMCCDropdown)
    return (
      <div className={`col-12 adwords-options-container ${this.props.view === "campaignInfo" && "campaign-mode"}`}>
        <AccountOverview changeView={this.props.changeView} />
        {this.props.isMCC && this.props.type !== "Facebook" && (
          <DropDown
            placeholder={"Search Accounts"}
            adwords_mcc_id={this.props.adwords_mcc_id}
            fetchAdwordsMCCAccounts={this.props.fetchAdwordsMCCAccounts}
            isMCC={this.props.isMCC}
            type={this.props.type}
            selectedCampaign={this.props.selectedCampaign}
            selectCampaign={this.props.selectCampaign}
            changeView={this.props.changeView}
            data={filterMCCDropdown}
            loading={this.props.MCC_Dropdown_Loader}
            fromMCCDROPDOWN={true}
            selectedMCCAccount={this.props.selectedMCCAccount}
          />
        )}
        <DropDown
          placeholder={"Select Campaign"}
          adwords_mcc_id={this.props.adwords_mcc_id}
          selectedCampaign={this.props.selectedCampaign}
          fetchAdwordsMCCAccounts={this.props.fetchAdwordsMCCAccounts}
          isMCC={false}
          type={this.props.type}
          selectCampaign={this.props.selectCampaign}
          changeView={this.props.changeView}
          data={this.state.dropdown}
          fromMCCDROPDOWN={false}
          loading={this.props.dropDownLoader}
          selectedMCCAccount={this.props.selectedMCCAccount}
        />
        <MultipleDatesCalendar
          selectedDates={this.props.selectedDates}
          view={this.props.view}
          changeOverviewCompareDates={this.props.changeOverviewCompareDates}
          liftDateSelections={this.props.liftDateSelections}
          changeOverviewCompareDatesDateTwo={this.props.changeOverviewCompareDatesDateTwo}
          liftCompareToggleState={this.props.liftCompareToggleState}
        />
      </div>
    );
  }
}
export default PaidAdsOptions;
