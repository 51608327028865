import React, { Component } from "react";
import { func, shape, string, number, object } from "prop-types";
import { connect } from "react-redux";

import { StatisticsBar } from "common";
import { fetchShopifySales } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_shopify.png";

class ShopifySales extends Component {
  static propTypes = {
    fetchShopifySales: func,
    date: shape({
      month: string,
      year: string
    }),
    shopify_access_token: string,
    siteId: number,
    shopifySales: object
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.shopify_access_token) return;
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId =
      this.props.shopify_access_token !== nextProps.shopify_access_token;

    if (!this.props.shopify_access_token) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchShopifySales(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.shopify_access_token) return null;

    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="6">Monthly Organic Site Metrics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>Average Order Value</td>
                  <td>Repeat Customer Rate</td>
                  <td>Total Orders</td>
                  <td>CLV</td>
                </tr>
                <tr>
                  <td>{this.props.shopifySales.totalSales.value}</td>
                  <td>{this.props.shopifySales.averageOrderValue.value}</td>
                  <td>{this.props.shopifySales.repeatCustomerRate.value}</td>
                  <td>{this.props.shopifySales.totalOrders.value}</td>
                  <td>{this.props.shopifySales.clv.value}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return (
      <StatisticsBar
        renderSubtitle={() => (
          <ReportSubTitle
            text="Shopify Sales"
            classes="pt-3 clearfix"
            logo={logo}
          />
        )}
        isLoading={this.state.isFetching}
        isPublic={false}
        data={this.props.shopifySales}
      />
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { shopify_access_token, id: siteId },
      ecommerceData: { shopifySales }
    }
  }
}) => ({
  date,
  siteId,
  shopify_access_token,
  shopifySales
});

export default connect(mapStateToProps, { fetchShopifySales })(ShopifySales);
