import React, { Component } from "react";
import { object } from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { StyleRoot } from "radium";
import ProtectedRoute from "routers/ProtectedRoute";
import SuperRoute from "routers/SuperRoute"
import { Navbar } from "common";
import DevelopmentBanner from "./common/DevelopmentBanner";

import { EditUser } from './screens/users/scenes/EditUser'

import { UsersActivate, UsersAuthenticate, UsersNew } from "screens/users/scenes";
import { ClientsEdit, ClientsIndex, ClientsNew, ClientsShow } from "screens/clients/scenes";
import { SitesEdit, SitesIndex, SitesShow, SitesShowPrint } from "screens/sites/scenes"

import { DemoSitesShow } from './demoPage/DemoSitesShow';

import { SocialIndex } from "./screens/social/SocialIndex";

import { TodosIndex } from "screens/todos/scenes";
import { ToolsIndex } from "screens/tools/scenes";
import { TemplatesNew, TemplatesIndex } from "screens/templates/scenes";
import { isAlliance } from "lib/api";

class App extends Component {
  static propTypes = {
    router: object
  };

  generateClassNames = pathname => {
    return pathname
      .split("/")
      .filter(route => route.length)
      .filter(item => !item.match(/\d/g))
      .join(" ");
  };

  render() {
    const { pathname } = this.props.router.location;
    return (
      <StyleRoot>
        <DevelopmentBanner />
        <div className={(this.generateClassNames(pathname) ? this.generateClassNames(pathname) : "home")}>
          <Navbar />
          <div className="app-container container-fluid">
            <div className="row">
              <Route exact path="/" component={ClientsIndex} />
              <Route exact path="/login" component={UsersAuthenticate} />
              <Route exact path="/demo" component={DemoSitesShow} />

              <Switch>
                <Route exact path="/social" component={SocialIndex} />
              </Switch>


              <Switch>
                <Route exact path="/public/sites/:hash" render={() => !isAlliance && <SitesShow />} />
                <Route exact path="/public/sites/:hash/print" render={() => !isAlliance && <SitesShowPrint />} />
              </Switch>

              <Switch>
                <ProtectedRoute exact path="/clients/new" component={ClientsNew} />
                <ProtectedRoute exact path="/clients/:id/edit" component={ClientsEdit} />
                <ProtectedRoute exact path="/clients/:id" component={ClientsShow} />
                <ProtectedRoute exact path="/clients" component={ClientsIndex} />
              </Switch>

              <Switch>
                <ProtectedRoute exact path="/sites/:id/edit" component={SitesEdit} />
                <ProtectedRoute exact path="/sites/:id" component={SitesShow} />
                <ProtectedRoute exact path="/sites" component={SitesIndex} />
              </Switch>

              <Switch>
                <ProtectedRoute path="/todos" component={TodosIndex} />
              </Switch>
              <Switch>
                <ProtectedRoute path="/tools" component={ToolsIndex} />
              </Switch>

              <Switch>
                <ProtectedRoute exact path="/templates/new" component={TemplatesNew} />
                <ProtectedRoute exact path="/templates" component={TemplatesIndex} />
              </Switch>

              <ProtectedRoute exact path="/users/:id/editAccount" component={EditUser} />
              <SuperRoute exact path="/users/new" component={UsersNew} />

              <Route exact path="/activate/:id" component={UsersActivate} />
            </div>
          </div>
        </div>
      </StyleRoot>
    );
  }
}

const mapStateToProps = ({ router }) => ({ router });

export default connect(mapStateToProps)(App);
