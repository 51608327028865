import React, { Component, Fragment } from "react";
import { func, number, string, shape, oneOfType, array } from "prop-types";
import { connect } from "react-redux";

import { fetchFbAdCampaigns } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { Panel, Table } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_facebook.png";

import axios from "axios";
import { getToken, requestHeaders } from "lib/authHelpers";
import { rootUrl } from "lib/api";

import _ from "lodash";
import { isObject } from "util";
class FacebookAdCampaigns extends Component {
  static propTypes = {
    fetchFbAdCampaigns: func,
    fb_ads_id: oneOfType([string, number]),
    date: shape({
      month: string,
      year: string
    }),
    fbData: array,
    fbHeaders: array
  };

  state = {
    isFetching: false,
    metaData: {},
    ids: [],
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.fb_ads_id) return;
    const params = {
      fb_ads_id: this.props.fb_ads_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = prevProps => {
    const hasDifferentMonth = this.props.date.month !== prevProps.date.month;
    const hasDifferentYear = this.props.date.year !== prevProps.date.year;
    const hasDifferentId = this.props.fb_ads_id !== prevProps.fb_ads_id;

    if (!this.props.fb_ads_id) return;

    if(this.props.date.dateOneStartForCompare !== prevProps.date.dateOneStartForCompare || this.props.date.dateOneEndForCompare !== prevProps.date.dateOneEndForCompare ){
      const params = {
        fb_ads_id: this.props.fb_ads_id,
        startDate: this.props.date.dateOneStartForCompare,
        endDate: this.props.date.dateOneEndForCompare
      };
      this.fetchData(params);
    }

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        fb_ads_id: this.props.fb_ads_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }

    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      const params = {
        fb_ads_id: this.props.fb_ads_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.props.clearCacheBool === true && this.props.fetchFbAdCampaigns(
        params,
        () => { }, this.props.clearCacheBool,
        false
      );
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchFbAdCampaigns(
      params,
      () => this.mounted && this.setState({ isFetching: false }),
      this.props.clearCacheBool,
      false,
    );
  };

  handleFetchAdsets = async ({ id }) => {
    if (this.state.ids.includes(id)) return;

    this.setState({
      ids: [...this.state.ids, id],
      metaData: {
        ...this.state.metaData,
        [id]: [
          {
            __name: { value: "Loading" },
            __cpc: { value: "-" },
            __ctr: { value: "-" },
            __visitors: { value: "-" },
            __spend: { value: "-" },
            __conversions: { value: "-" },
            __roas: { value: "-" }
          }
        ]
      }
    });

    const startDate = getStartDate(this.props.date);
    const endDate = getEndDate(this.props.date);
    const token = getToken();
    const headers = requestHeaders(token);
    const qs = `startDate=${startDate}&endDate=${endDate}`;
    const url = `${rootUrl}/public/fb-ads/adsets/${id}?${qs}`;

    const { data } = await axios.get(url, headers);

    const adsetMeta = data.map(item => {
      const result = {
        __name: { value: item.name },
        __cpc: { value: item.cpc ? item.cpc : "-" },
        __ctr: { value: item.ctr ? item.ctr : "-" },
        __visitors: { value: item.visitors ? item.visitors : "-" },
        __spend: { value: item.spend ? item.spend : "-" },
        __conversions: {
          value: item.conversions
            ? item.conversions
              .reduce((accum, next) => accum + Number(next.value), 0)
              .toFixed()
            : "-"
        },
        __roas: {
          value: item.roas
            ? item.roas
              .reduce((accum, next) => accum + Number(next.value), 0)
              .toFixed(2)
            : "-"
        }
      };

      return result;
    });

    this.setState({
      metaData: {
        ...this.state.metaData,
        [id]: adsetMeta
      }
    });
  };

  injectMeta = (data, metaData) => {
    if (Object.keys(metaData).length < 1) {
      return data;
    }

    const metaDataKeys = Object.keys(metaData);
    const result = [];

    data.forEach(campaign => {
      result.push(campaign);

      if (metaDataKeys.includes(campaign.id)) {
        result.push(...metaData[campaign.id]);
      }
    });

    return result;
  };

  formatHeaders = (isOfTypeEcommerce) => { 
    let headers = this.props.fbHeaders || [];
    const formattedHeaders = headers.filter(header => {
      if(header !== 'ad_type' &&  header !== 'ad_label' && header !== "ad_value"){
        return header;
      }
    });
    if(isOfTypeEcommerce){
      let filterConversions = formattedHeaders.filter(item => item !== "conversions");
      filterConversions.push("sales");
      filterConversions.push("revenue");
      return filterConversions;
    }
    return formattedHeaders;
  }
  
  removeKeys = (data) => {
    if(data.length == 0) {
      return [];
    }
    let arr = [];
    let clone = _.cloneDeep(data);
    clone.forEach(item => {
    delete item.ad_label;   
    delete item.ad_type;   
    arr.push(item);
   })
   return arr;
  }

  checkForRoas = (data) => {
    let isOfTypeEcommerce = false
    if(data.length > 0){
      data.forEach(item => {
        if(item.roas.value !== "-"){
          isOfTypeEcommerce = true;
        }
      })
    }
    return isOfTypeEcommerce;
  }

  calculateSales = (data) => {
    data.forEach(item => {
      let sales = 0;
      if(item.conversions.meta !== null){
        if(item.conversions.meta.length > 0) {
          item.conversions.meta.forEach(item =>{
            if(item.type === "omni_purchase"){
              sales += Number(item.value);
            }
          })
        }
      }
      delete Object.assign(item, {['sales']:{ value: sales }  });
    })
  }

  swapKeyNameForEcommerce = (data, isOfTypeEcommerce) => {
    if(isOfTypeEcommerce){
      if(data.length > 0) {
        this.calculateSales(data);
        data.forEach(item => {
          delete item.conversions;
          delete Object.assign(item, {['revenue']:{ value: item['ad_value'] }  })['ad_value'];
        })   
       }
    }
  }

  verifyCorrectFormatForTable = (data) => {
    let clone = _.cloneDeep(data);
    clone.forEach(item => {
      if(item.ad_value){
        delete item.ad_value;
      }
      if(item.cpc) {
        item.cpc.value = "$" + item.cpc.value;
      }
      if(item.spend) {
        item.spend.value = "$" + item.spend.value;
      }
      if(item.revenue) {
        item.revenue.value = "$" + item.revenue.value;
      }
      if(item.impressions){
        item.impressions = {
          value: item.impressions
        }
      }
    });
    return clone;
  }

  

  render() {
    if (!this.props.fb_ads_id) return null;
    if (this.props.fbError !== "") return null;
    const withMetaData = this.injectMeta(
      this.props.fbData,
      this.state.metaData
    );
    const withKeysRemoved = this.removeKeys(withMetaData);
    const isOfTypeEcommerce = this.checkForRoas(withKeysRemoved);
    this.swapKeyNameForEcommerce(withKeysRemoved, isOfTypeEcommerce);
    const headers = this.formatHeaders(isOfTypeEcommerce);
    if(withKeysRemoved.length > 0){
     withKeysRemoved.sort((a, b) => a.roas.value !== b.v ? a.roas.value > b.roas.value ? -1 : 1 : 0);
    }
    let finalData = this.verifyCorrectFormatForTable(withKeysRemoved);
    let printKeyValues= [];
    if(finalData.length > 0){
      _.forOwn(finalData[0], (value, key) => {
        if(key !== "impressions" && key !== "status" && key !== "id"){
          printKeyValues.push(key);
        }
      });
    }
    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="7">Facebook Campaigns</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {printKeyValues.map(item => {
                    return <td key={item}>{item.toUpperCase()}</td>
                  })}
                </tr>
                {finalData.map((item, i) => {
                  return (
                    <tr key={Math.random()}>
                    {printKeyValues.map(key => {
                    return <td key={Math.random()}>{item[key].value}</td>
                  })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return (
      <Fragment>
        <ReportSubTitle text="Facebook Campaigns" classes="mt-3" logo={logo} />
        <Panel containerClass="col-md-12 p-3" isLoading={this.state.isFetching}>
          <Panel.Body
            style={{
              display: "block",
              paddingBottom: "1em"
            }}
            render={() => (
              <Table
                headers={headers}
                data={finalData}
                fetchMetaData={this.handleFetchAdsets}
                classes={"facebook-table"}
              />
            )}
          />
        </Panel>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { fb_ads_id },
      paidAdsData: { fbCampaigns: { data: fbData, headers: fbHeaders, error: fbError } }
    }
  }
}) => ({
  fb_ads_id,
  date,
  fbData,
  fbHeaders,
  fbError
});

export default connect(mapStateToProps, { fetchFbAdCampaigns })(
  FacebookAdCampaigns
);
