import React, { Component } from "react";
import FacebookAdOverview from "screens/sites/components/FacebookAdOverview";
import FacebookAdCampaigns from "screens/sites/components/FacebookAdCampaigns";
import { ErrorBoundary } from "common";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import AdwordsAccountOverview from "../components/AdwordsAccountOverview";

import { connect } from "react-redux";
import _ from "lodash";

import PaidAdsOptions from "screens/sites/components/PaidAdsOptions";
import {
  changeOverviewCompareDates,
  changeOverviewCompareDatesDateTwo,
} from "../actions/date_actions";

import {
  fetchCampaigns,
  fetchCampaignsInfo_DAILY,
  fetchAdwordsAdgroups,
  fetchFacebookAdsets,
  fetchFBAdsetsAdPreview,
  fetchUpdatedCampaignValues,
  fetchAdwordsPerformanceAcctLevel,
  fetchFBPerformanceAcctLevel,
  clearAdPreviewState,
  updateAdwordsIdWithSubAcctId,
} from "screens/sites/actions";
import { fetchAdwordsMCCAccounts } from "../actions/paid_ads_actions";

import Campaigns from "../components/Campaigns";
import PropTypes from "prop-types";

let count = 0;

class AdsOverview extends Component {
  state = {
    view: "accountOverview",
    selectedCampaign: {},
    campaigns: [],
    selectedSiteAccount: { id: null },
    MCC_Accounts: [],
    MCC_Dropdown_Loader: true,
    error: "",
    dropDownLoader: true,
    dateONE_START: getStartDate(this.props.date),
    dateONE_END: getEndDate(this.props.date),
    dateTWO_START: null,
    dateTWO_END: null,
    statsBarLoader: true,
    infoGraphicLoader: true,
    lineChartLoader: true,
    isComparing: false,
  };

  changeView = (view) => {
    this.setState({ view });
  };

  stopLoader = (key) => {
    this.setState({ [key]: false });
  };

  fetchCampaignsForDropdown = (fromMCC) => {
    const params = {
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
    };
    if (fromMCC) {
      params.adwords_id = this.state.selectedSiteAccount.id;
    } else {
      this.props.type === "Google"
        ? (params.adwords_id = this.props.adwords_id)
        : (params.fb_ads_id = this.props.fb_ads_id);
    }
    this.props.fetchCampaigns(
      params,
      () => {
        this.setState({ dropDownLoader: false });
      },
      this.props.clearCacheBool,
      this.props.type,
      false
    );
  };

  componentDidMount() {
    this.fetchCampaignsForDropdown();
    // Adwords
    // If there is an MCC ID we fetch the MCC account to populate the dropdown.
    this.props.adwords_mcc_id &&
      this.props.fetchAdwordsMCCAccounts(
        this.props.adwords_mcc_id,
        () => {
          this.setState({ MCC_Dropdown_Loader: false });
        },
        this.props.clearCacheBool
      );
  }

  componentDidUpdate(prevProps, prevState) {
    let { dateONE_END, dateONE_START, dateTWO_END, dateTWO_START } = this.state;
    let { type } = this.props;
    const params = {
      startDate: dateONE_START,
      endDate: dateONE_END,
    };
    this.props.type === "Google" &&
      (params.campaign_id = this.state.selectedCampaign.Campaign_ID);
    this.props.type === "Facebook" &&
      (params.campaign_id = this.state.selectedCampaign.id);
    if (this.props.adwords_mcc_id) {
      this.props.type === "Google"
        ? (params.adwords_id = this.state.selectedSiteAccount.id)
        : (params.fb_ads_id = this.props.fb_ads_id);
    } else {
      this.props.type === "Google"
        ? (params.adwords_id = this.props.adwords_id)
        : (params.fb_ads_id = this.props.fb_ads_id);
    }
    // On date change fetch ADS VALUE DATA
    if (
      dateONE_START !== prevState.dateONE_START ||
      dateONE_END !== prevState.dateONE_END
    ) {
      this.setState({
        statsBarLoader: true,
        infoGraphicLoader: true,
        lineChartLoader: true,
      });
      type === "Google" &&
        this.props.fetchAdwordsPerformanceAcctLevel(
          params,
          () => null,
          this.props.clearCacheBool,
          false
        );
      this.props.fetchUpdatedCampaignValues(
        params,
        () => null,
        this.props.clearCacheBool,
        this.props.type,
        false
      );
      type === "Facebook" &&
        this.props.fetchFBPerformanceAcctLevel(
          params,
          () => null,
          this.props.clearCacheBool
        );
    }
    // On date Two change fetch ads Value
    if (
      dateTWO_START !== prevState.dateTWO_START ||
      dateTWO_END !== prevState.dateTWO_END
    ) {
      this.setState({ statsBarLoader: true, lineChartLoader: true });
      params.startDate = dateTWO_START;
      params.endDate = dateTWO_END;
      this.props.fetchUpdatedCampaignValues(
        params,
        () => null,
        this.props.clearCacheBool,
        this.props.type,
        true
      );
      // this.props.fetchAdwordsPerformanceAcctLevel(params, () => { this.setState({ infoGraphicLoader: false }) }, false, false);
    }

    // On Campaing Change Fetch ADS VALUE DATA
    if (!_.isEqual(this.state.selectedCampaign, prevState.selectedCampaign)) {
      this.setState({
        statsBarLoader: true,
        infoGraphicLoader: true,
        lineChartLoader: true,
      });
      this.props.adwords_mcc_id &&
        (params.adwords_id = this.state.selectedSiteAccount.id);
      this.props.fetchUpdatedCampaignValues(
        params,
        () => null,
        this.props.clearCacheBool,
        this.props.type,
        false
      );
      type === "Google" &&
        this.props.fetchAdwordsPerformanceAcctLevel(
          params,
          () => null,
          this.props.clearCacheBool,
          false
        );
      type === "Facebook" &&
        this.props.fetchFBPerformanceAcctLevel(
          params,
          () => null,
          this.props.clearCacheBool
        );
      if (this.state.dateTWO_START && this.state.dateTWO_END) {
        params.startDate = dateTWO_START;
        params.endDate = dateTWO_END;
        this.props.fetchUpdatedCampaignValues(
          params,
          () => null,
          this.props.clearCacheBool,
          this.props.type,
          true
        );
      }
    }
    // If MCC Account Changes Fetch Account Data
    if (
      !_.isEqual(this.state.selectedSiteAccount, prevState.selectedSiteAccount)
    ) {
      this.fetchCampaignsForDropdown(true);
    }
    if (!_.isEqual(this.props.type, prevProps.type)) {
      this.fetchCampaignsForDropdown();
    }

    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      this.setState(
        {
          statsBarLoader: true,
          infoGraphicLoader: true,
          lineChartLoader: true,
        },
        () => {
          this.props.adwords_mcc_id &&
            (params.adwords_id = this.state.selectedSiteAccount.id);
          this.props.fetchUpdatedCampaignValues(
            params,
            () => null,
            this.props.clearCacheBool,
            this.props.type,
            false
          );
          type === "Google" &&
            this.props.fetchAdwordsPerformanceAcctLevel(
              params,
              () => null,
              this.props.clearCacheBool,
              false
            );
          type === "Facebook" &&
            this.props.fetchFBPerformanceAcctLevel(
              params,
              () => null,
              this.props.clearCacheBool
            );
          if (this.state.dateTWO_START && this.state.dateTWO_END) {
            params.startDate = dateTWO_START;
            params.endDate = dateTWO_END;
            this.props.fetchUpdatedCampaignValues(
              params,
              () => null,
              this.props.clearCacheBool,
              this.props.type,
              true
            );
          }
        }
      );
    }
  }

  liftCompareToggleState = () => {
    this.setState({ isComparing: !this.state.isComparing });
  };

  selectCampaign = (campaign, isMCC) => {
    if (isMCC) {
      this.setState(
        { selectedSiteAccount: campaign, MCC_SUB_ACCOUNT_ID: campaign.id },
        () => {
          this.props.updateAdwordsIdWithSubAcctId(
            this.state.MCC_SUB_ACCOUNT_ID
          );
        }
      );
    } else {
      this.setState({ selectedCampaign: campaign }, () => {});
    }
  };

  liftDateSelections = (
    dateONE_START,
    dateONE_END,
    dateTWO_START,
    dateTWO_END
  ) => {
    this.setState({ dateONE_END, dateONE_START, dateTWO_END, dateTWO_START });
  };

  render() {
    let isMCC = false;
    this.props.adwords_mcc_id && (isMCC = true);
    let { dateONE_END, dateONE_START, dateTWO_END, dateTWO_START } = this.state;
    let ID;
    this.props.type === "Google"
      ? (ID = this.props.adwords_id)
      : (ID = this.props.fb_ads_id);
    this.props.adwords_mcc_id &&
      this.props.type !== "Facebook" &&
      (ID = this.state.selectedSiteAccount.id);
    if (this.state.view === "accountOverview") {
      return (
        <div className="col-12">
          <PaidAdsOptions
            type={this.props.type}
            data={this.props.campaignsDropdown}
            liftDateSelections={this.liftDateSelections}
            name={"Campaign"}
            selectedSiteAccount={this.state.selectedSiteAccount}
            selectedCampaign={this.state.selectedCampaign}
            MCCName={"MCC Accounts"}
            selectedDates={{
              dateONE_END,
              dateONE_START,
              dateTWO_END,
              dateTWO_START,
            }}
            selectCampaign={this.selectCampaign}
            changeView={this.changeView}
            isMCC={isMCC}
            MCC_Dropdown_Loader={this.state.MCC_Dropdown_Loader}
            dropDownLoader={this.state.dropDownLoader}
            statsBarLoader={this.state.statsBarLoader}
            view={this.state.view}
            changeOverviewCompareDates={changeOverviewCompareDates}
            changeOverviewCompareDatesDateTwo={
              changeOverviewCompareDatesDateTwo
            }
            liftCompareToggleState={this.liftCompareToggleState}
            {...this.props}
          />
          {this.props.type === "Google" && (
            <AdwordsAccountOverview
              isMCC={isMCC}
              subAccountId={this.state.MCC_SUB_ACCOUNT_ID}
              data={this.props.campaignsDropdown}
              isComparing={this.state.isComparing}
              clearCacheBool={this.props.clearCacheBool}
            />
          )}
          <div className="col-12">
            {this.props.type === "Facebook" && (
              <ErrorBoundary>
                <FacebookAdOverview
                  isComparing={this.state.isComparing}
                  clearCacheBool={this.props.clearCacheBool}
                />
              </ErrorBoundary>
            )}
            {this.props.type === "Facebook" && (
              <FacebookAdCampaigns clearCacheBool={this.props.clearCacheBool} />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <PaidAdsOptions
            type={this.props.type}
            selectedCampaign={this.state.selectedCampaign}
            data={this.props.campaignsDropdown}
            liftDateSelections={this.liftDateSelections}
            name={"Campaign"}
            selectedSiteAccount={this.state.selectedSiteAccount}
            MCCName={"MCC Accounts"}
            selectedDates={{
              dateONE_END,
              dateONE_START,
              dateTWO_END,
              dateTWO_START,
            }}
            selectCampaign={this.selectCampaign}
            changeView={this.changeView}
            isMCC={isMCC}
            MCC_Dropdown_Loader={this.state.MCC_Dropdown_Loader}
            dropDownLoader={this.state.dropDownLoader}
            clearCacheBool={this.props.clearCacheBool}
            view={this.state.view}
            changeOverviewCompareDates={changeOverviewCompareDates}
            changeOverviewCompareDatesDateTwo={
              changeOverviewCompareDatesDateTwo
            }
            liftCompareToggleState={this.liftCompareToggleState}
            {...this.props}
          />
          {!_.isEmpty(this.state.selectedCampaign) && (
            <Campaigns
              selectedCampaign={this.state.selectedCampaign}
              dateONE_START={this.state.dateONE_START}
              dateONE_END={this.state.dateONE_END}
              dateTWO_START={this.state.dateTWO_START}
              dateTWO_END={this.state.dateTWO_END}
              statsBarLoader={this.state.statsBarLoader}
              type={this.props.type}
              fetchDailyStats={this.props.fetchCampaignsInfo_DAILY}
              Ads_ID={ID}
              infoGraphicLoader={this.state.infoGraphicLoader}
              dateOneDailyData={
                this.props.type == "Google"
                  ? this.props.paidAdsData.adwordsStatsBarDaily.dateOne
                  : this.props.paidAdsData.facebookAdsStatsBarDaily.dateOne
              }
              dateTwoDailyData={
                this.props.type == "Google"
                  ? this.props.paidAdsData.adwordsStatsBarDaily.dateTwo
                  : this.props.paidAdsData.facebookAdsStatsBarDaily.dateTwo
              }
              fetchFacebookAdsets={this.props.fetchFacebookAdsets}
              fbAdsets={this.props.paidAdsData.fbAdsets}
              fetchTableData={
                this.props.type === "Google"
                  ? this.props.fetchAdwordsAdgroups
                  : this.props.fetchFacebookAdsets
              }
              tableData={
                this.props.type === "Google"
                  ? this.props.paidAdsData.adwordsAdGroups
                  : this.props.paidAdsData.facebookAdsets
              }
              fetchAdPreview={this.props.fetchFBAdsetsAdPreview}
              facebookAdPreviews={this.props.paidAdsData.facebookAdPreviews}
              paidAdsData={this.props.paidAdsData}
              adwordsPerformanceAcctLevel={
                this.props.paidAdsData.adwordsPerformanceAcctLevel
              }
              facebookPerformanceAcctLevel={
                this.props.paidAdsData.facebookPerformanceAcctLevel
              }
              lineChartLoader={this.state.lineChartLoader}
              dateOneAdValuesData={
                this.props.type == "Google"
                  ? this.props.paidAdsData.adwordsStatsBarDaily.adValuesDateOne
                  : this.props.paidAdsData.facebookAdsStatsBarDaily
                      .adValuesDateOne
              }
              dateTwoAdValuesData={
                this.props.type == "Google"
                  ? this.props.paidAdsData.adwordsStatsBarDaily.adValuesDateTwo
                  : this.props.paidAdsData.facebookAdsStatsBarDaily
                      .adValuesDateTwo
              }
              campaignDoesNotExist={
                this.props.paidAdsData.facebookAdsStatsBarDaily
                  .campaignDoesNotExistInDateRangeError
              }
              stopLoader={this.stopLoader}
              clearCacheBool={this.props.clearCacheBool}
              clearAdPreviewState={this.props.clearAdPreviewState}
            />
          )}
        </div>
      );
    }
  }
}
const mapStateToProps = ({
  sites: {
    show: {
      permissions: { isPublic },
      data: { adwords_id, fb_ads_id, id: siteId },
    },
  },
  sites: {
    show: {
      data: { adwords_mcc_id },
    },
  },
  sites: {
    show: { paidAdsData },
  },
  sites: {
    show: { campaignsDropdown },
  },
  sites: {
    show: { date },
  },
}) => ({
  siteId,
  adwords_id,
  fb_ads_id,
  isPublic,
  adwords_mcc_id,
  paidAdsData,
  campaignsDropdown,
  date,
});

const mapDispatchToProps = {
  fetchCampaigns,
  fetchCampaignsInfo_DAILY,
  fetchAdwordsAdgroups,
  fetchFacebookAdsets,
  fetchFBAdsetsAdPreview,
  fetchUpdatedCampaignValues,
  fetchAdwordsMCCAccounts,
  fetchAdwordsPerformanceAcctLevel,
  fetchFBPerformanceAcctLevel,
  changeOverviewCompareDatesDateTwo,
  clearAdPreviewState,
  updateAdwordsIdWithSubAcctId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsOverview);
