import React, { Component, Fragment } from 'react';
import { GenericBarChart } from "common";

class DemoAdwordsMTM extends Component {
    render() {
        // Fixtures
        const isFetching = false
        const showPrintView = false;
        const data = {
            "conversions":
                [
                    { "name": "Jul", "value": 7 },
                    { "name": "Aug", "value": 10 },
                    { "name": "Sep", "value": 12 }
                ],
            "cost_conversion":
                [
                    { "name": "Jul", "value": 300 },
                    { "name": "Aug", "value": 282.88 },
                    { "name": "Sep", "value": 206.84 }
                ],
            "cost": [
                { "name": "Jul", "value": 6475.9200000000001 },
                { "name": "Aug", "value": 2516.79 },
                { "name": "Sep", "value": 2482.1199999999999 }
            ],
            "conversion_matrix": {
                "Sunday": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "Monday": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "Tuesday": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "Wednesday": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "Thursday": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "Friday": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "Saturday": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
        }
        return (
            <Fragment>
                <GenericBarChart gridClass="col-md-4" isCurrency={false} title="Monthly Conversions" printView={showPrintView} isLoading={isFetching} data={data.conversions} />
                <GenericBarChart gridClass="col-md-4" isCurrency={true} title="Monthly Cost/Conversion" printView={showPrintView} isLoading={isFetching} data={data.cost_conversion} />
                <GenericBarChart gridClass="col-md-4" isCurrency={true} title="Monthly Cost" printView={showPrintView} isLoading={isFetching} data={data.cost} />
            </Fragment>
        )
    }
}

export default DemoAdwordsMTM;