import { createReducer } from "../../lib/utilities";

/**
 * Boilerplate functions for reducers
 */

const defaultState = () => ({
  isFetching: false,
  errors: null,
  data: []
});

const defaultFetch = (key, opts = {}) => (state, payload) => {
  const hasPayload = payload !== undefined && Object.keys(payload).length > 0;
  return ({
    ...state,
    [key]: {
      ...state[key],
      isFetching: true,
      data: hasPayload ? payload : ((opts.override) ? [] : [...state[key].data])
    }
  });
}

const defaultFail = (key) => (state, payload) => ({
  ...state,
  [key]: {
    ...state[key],
    isFetching: false,
    errors: payload
  }
});

const defaultSuccess = (key) => (state, payload) => ({
  ...state,
  [key]: {
    ...state[key],
    isFetching: false,
    data: payload
  }
});

const defaultFetchReducers = (action, key, opts = {}) => ({
  [action]: defaultFetch(key, opts),
  [`${action}_FAIL`]: defaultFail(key, opts),
  [`${action}_SUCCESS`]: defaultSuccess(key, opts),
});

/**
 * Reducers
 */

const initialState = {
  stats: defaultState(),
  sites: defaultState(),
  site: defaultState(),
  siteDetails: defaultState(),
  siteStats: defaultState(),
};

export default createReducer(initialState, {
  ...defaultFetchReducers('FETCHING_HUBBLE_STATS', 'stats'),
  ...defaultFetchReducers('FETCHING_HUBBLE_SITES', 'sites'),
  ...defaultFetchReducers('FETCHING_HUBBLE_SITE', 'site', { }),
  ...defaultFetchReducers('FETCHING_HUBBLE_SITE_STATS', 'siteStats', {  }),
  ...defaultFetchReducers('FETCHING_HUBBLE_SITE_DETAILS', 'siteDetails', { override: true }),
});
