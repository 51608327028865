import _ from "lodash";
import React, { Component } from "react";
import { bool, number, object, string } from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Panel } from "common";

const DEFAULT_COLOR = "#7f5bd0";

export const formatData = (data, yLabel, color = DEFAULT_COLOR) => {
  return _.map(data, (item, index) => ({
    x: index,
    [yLabel]: item,
    fill: color,
  }));
};

class BarGraph extends Component {
  static propTypes = {
    title: string.isRequired,
    data: object.isRequired,
    yLabel: string.isRequired,
    isLoading: bool,
    errors: string,
    barSize: number,
    className: string,
  };

  static defaultProps = {
    barSize: 40,
    data: {},
  };

  render() {
    const data = formatData(this.props.data, this.props.yLabel);
    const isLoading = this.props.isLoading || _.isEmpty(this.props.data);
    return (
      <Panel
        containerClass={
          `${
            this.props.colSize ? `col-${this.props.colSize}` : "col-md-6"
          } p-3 float-left ` + this.props.className
        }
        isLoading={isLoading}
        errors={this.props.errors}
        padding={2}
      >
        <Panel.Subtitle text={this.props.title} />
        <Panel.Body
          render={() => {
            return (
              <ResponsiveContainer height={230}>
                <BarChart
                  width={600}
                  height={300}
                  data={data}
                  margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                >
                  <XAxis tickLine={false} dataKey="x" />
                  <YAxis tickLine={false} />
                  <Tooltip />
                  <Bar
                    dataKey={this.props.yLabel}
                    barSize={this.props.barSize * data.length}
                  />
                </BarChart>
              </ResponsiveContainer>
            );
          }}
        />
      </Panel>
    );
  }
}

export { BarGraph };
