import { createReducer } from "../../lib/utilities";

const initialState = {
  index: {
    data: [],
    totalNotes: 0,
  }
};

export default createReducer(initialState, {
  FETCH_NOTES_SUCCESS: (state, payload) => {
    return {
      ...state,
      index: {
        ...state.index,
        data: payload.notes,
        totalNotes: payload.totalNotes
      }
    }
  }
  ,
  FETCHING_CLIENT_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: payload.notes
    }
  }),
  CREATING_NOTE_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: [payload, ...state.index.data],
    }
  }),
  EDIT_NOTE_SUCCESS: (state, payload) => {
    const index = state.index.data.findIndex(note => note.id === payload.id);
    let notes = [...state.index.data];
    notes[index] = payload;
    return {
      ...state,
      index: {
        ...state.index,
        data: notes
      }
    }
  },
  DELETING_NOTE_SUCCESS: (state, payload) => {
    const index = state.index.data.findIndex(note => note.id === payload);
    const updatedNotes = [
      ...state.index.data.slice(0, index),
      ...state.index.data.slice(index + 1)
    ];
    return {
      ...state,
      index: {
        ...state.index,
        data: updatedNotes
      }
    };
  }
});
