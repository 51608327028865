import React, { Component } from "react";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";

const RenderHashTagData = (props) => {
    if (props.mostUsedHashtags.length > 0) {
        return props.mostUsedHashtags.map((tag, i) => {
            let tagValue = props.mostUsedHashtagsValues[tag].value;
            return (
                <div className="col-12 col-md-3" key={i}>
                    <div className="hashTagContainer">
                        <span className="hashTag">{tag}</span>
                        <span className="hashTagValue">{tagValue}</span>
                    </div>
                </div>
            )
        })
    }
    else {
        return null
    }
}

const RenderHashTagEngagement = (props) => {
    if (props.mostUsedHashtagsEngagement.length > 0) {
        return props.mostUsedHashtagsEngagement.map((data, i) => {
            return (
                <div className="col-12 col-md-3" key={i}>
                    <div className="hashTagContainer">
                        <span className="hashTag">{data.tag}</span>
                        <span className="hashTagValue">{data.engagement}</span>
                    </div>

                </div>
            )
        })
    }
    else {
        return null
    }
}

class DemoInstagramHashtags extends Component {
    state =
        {
            "data": null,
            "isFetching": true,
            "mostUsedHashtags": [
                "#marcusmedicalspa",
                "#skincare",
                "#skinceuticals",
                "#chemicalpeel",
                "#beforeandafter",
                "#zoskinhealth",
                "#skinrejuvenation",
                "#injectables",
                "#redondobeach",
                "#medicalspa",
                "#novemberspecial",
                "#halolaser",
                "#lipfillers",
                "#subtle",
                "#hawaiianeyemeeting"
            ],
            "mostUsedHashtagsValues": {
                "#halolaser": {
                    "value": 1
                },
                "#marcusmedicalspa": {
                    "value": 8
                },
                "#skinrejuvenation": {
                    "value": 2
                },
                "#lipfillers": {
                    "value": 1
                },
                "#injectables": {
                    "value": 2
                },
                "#subtle": {
                    "value": 1
                },
                "#hawaiianeyemeeting": {
                    "value": 1
                },
                "#techniques": {
                    "value": 1
                },
                "#facialfillers": {
                    "value": 1
                },
                "#facialshaping": {
                    "value": 1
                },
                "#skinceuticals": {
                    "value": 3
                },
                "#chemicalpeel": {
                    "value": 3
                },
                "#skincare": {
                    "value": 5
                },
                "#prphairrestoration": {
                    "value": 1
                },
                "#beforeandafter": {
                    "value": 3
                },
                "#hairtransformation": {
                    "value": 1
                },
                "#hairrestoration": {
                    "value": 1
                },
                "#happybirthday": {
                    "value": 1
                },
                "#weloveourjob": {
                    "value": 1
                },
                "#allworkandsomeplay": {
                    "value": 1
                },
                "#skincareroutine": {
                    "value": 1
                },
                "#redondobeach": {
                    "value": 2
                },
                "#medicalspa": {
                    "value": 2
                },
                "#12daysofchristmas": {
                    "value": 1
                },
                "#holidayspecial": {
                    "value": 1
                },
                "#redondobeachmedspa": {
                    "value": 1
                },
                "#kybella": {
                    "value": 1
                },
                "#facepeel": {
                    "value": 1
                },
                "#zoskinhealth": {
                    "value": 3
                },
                "#zo3steppeel": {
                    "value": 1
                },
                "#novemberspecial": {
                    "value": 2
                },
                "#isaps": {
                    "value": 1
                }
            },
            "mostUsedHashTagsEngagement": [
                {
                    "tag": "#marcusmedicalspa",
                    "engagement": 3228
                },
                {
                    "tag": "#skincare",
                    "engagement": 1953
                },
                {
                    "tag": "#chemicalpeel",
                    "engagement": 1217
                },
                {
                    "tag": "#skinceuticals",
                    "engagement": 1184
                },
                {
                    "tag": "#beforeandafter",
                    "engagement": 1146
                },
                {
                    "tag": "#skinrejuvenation",
                    "engagement": 812
                },
                {
                    "tag": "#injectables",
                    "engagement": 798
                },
                {
                    "tag": "#hawaiianeyemeeting",
                    "engagement": 425
                },
                {
                    "tag": "#techniques",
                    "engagement": 425
                },
                {
                    "tag": "#facialfillers",
                    "engagement": 425
                },
                {
                    "tag": "#facialshaping",
                    "engagement": 425
                },
                {
                    "tag": "#halolaser",
                    "engagement": 378
                },
                {
                    "tag": "#lipfillers",
                    "engagement": 373
                },
                {
                    "tag": "#subtle",
                    "engagement": 373
                },
                {
                    "tag": "#prphairrestoration",
                    "engagement": 363
                }
            ]
        }

    render() {
        return (
            <div className="row">
                <ReportSubTitle text="Popular Hashtags" classes="mt-5 mb-4" logo={this.props.logo} />
                <div className="hashTagTable row">
                    <RenderHashTagData
                        mostUsedHashtags={this.state.mostUsedHashtags}
                        mostUsedHashtagsValues={this.state.mostUsedHashtagsValues}
                    />
                </div>
                <ReportSubTitle text="Hashtag Engagement" classes="mt-3 mb-4"  logo={this.props.logo} />
                <div className="hashTagTable row">
                    <RenderHashTagEngagement
                        mostUsedHashtagsEngagement={this.state.mostUsedHashTagsEngagement}
                    />
                </div>
            </div>
        )
    }
}

export { DemoInstagramHashtags };