import React from "react";
import { store } from "store";

export const GA4ConnectModal = ({ children, isOpen, onRequestClose }) => {
  if (isOpen) {
    return (
      <div className="ga4-connect-modal">
        {children}

        <div className="button-bar">
          <button
            className="btn btn-disconnect"
            onClick={(e) => {
              e.stopPropagation();
              onRequestClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
  return null;
};
