import React, { Component } from "react";
import { array, func, number, string } from "prop-types";
import moment from "moment";
import {
  displayFilter,
  sortByDueDate
} from "screens/todos/helpers/todo_helpers";
import Checkbox from "screens/todos/components/Checkbox";
import ProfileCircle from "screens/todos/components/ProfileCircle";
import { renderDueDateLabel } from "screens/todos/helpers/todo_helpers";

class TodosList extends Component {
  static propTypes = {
    data: array,
    filter: string,
    selectedTodo: number,
    handleRefChange: func,
    handleToggleCheckbox: func,
    handleInputChange: func,
    handleInputFocus: func,
    filterTeam: string,
    filterClient: string,
    filterSearch: string
  };

  renderContainerBorder = ({ due_date: date }) => {
    if (!date) return "";
    if (typeof date === "object") {
      date = moment(date).format("YYYY-MM-DD");
    }

    const today = moment().startOf("day");
    const dueDate = moment(date, "YYYY-MM-DD").endOf("day");
    const dueToday = moment()
      .add(1, "days")
      .startOf("day");

    if (dueDate.isBefore(today)) {
      return "todos-main-item--overdue";
    }

    if (moment(dueDate).isBetween(today, dueToday)) {
      return "todos-main-item--today";
    }

    return "";
  };

  renderFollowerMeta = todo => {
    const followerCount = todo.followers.length;
    return (
      <div className="todos-main-item-meta">
        <ProfileCircle
          profileImage={todo.user.profile_image}
          firstName={todo.user.first_name}
          lastName={todo.user.last_name}
        />
        {followerCount > 0 && (
          <span className="todos-main-item-follower-count">
            +{followerCount}
          </span>
        )}
        <span className="todos-main-item-owner-name">
          {`${todo.user.first_name} ${todo.user.last_name[0]}.`}
        </span>
      </div>
    );
  };

  renderSubtaskMeta = ({ subtasks }) => {
    const subtaskCount = JSON.parse(subtasks).filter(subtask => {
      return !subtask.section;
    }).length;
    const activeSubtasks = JSON.parse(subtasks).filter(subtask => {
      return subtask.completed;
    }).length;

    if (JSON.parse(subtasks).length === 0) {
      return (
        <div className="todos-main-item-meta">
          <i className="fas fa-check-square" />
          {`0 subtasks`}
        </div>
      );
    }

    return (
      <div className="todos-main-item-meta">
        <i className="fas fa-check-square" />
        {`${activeSubtasks}/${subtaskCount} subtasks`}
      </div>
    );
  };

  renderCommentMeta = ({ comments: { length } }) => {
    return (
      <div className="todos-main-item-meta">
        <i className="fas fa-comment" />
        {`${length} ${length === 1 ? "comment" : "comments"}`}
      </div>
    );
  };

  render() {
    let todos = sortByDueDate(
      displayFilter(this.props.data, this.props.filter)
    );

    if (this.props.filterTeam) {
      todos = todos.filter(todo => {
        if (todo.team) {
          return todo.team.name === this.props.filterTeam;
        }
        return null;
      });
    }

    if (this.props.filterClient) {
      todos = todos.filter(todo => {
        if (todo.client) {
          return todo.client.name === this.props.filterClient;
        }
        return null;
      });
    }

    if (this.props.filterSearch) {
      todos = todos.filter(todo => {
        const stringified = JSON.stringify(todo);
        const stringifiedLower = stringified.toLowerCase();
        const searchLower = this.props.filterSearch.toLowerCase();
        return stringifiedLower.includes(searchLower);
      });
    }

    if (!this.props.data.length && !this.props.isFetching) {
      return <div className="alert alert-secondary">No todos created!</div>;
    }

    if (!todos.length && !this.props.isFetching) {
      return (
        <div className="alert alert-light">
          All todos completed!{" "}
          <span role="img" aria-labelledby="partypopper">
            🎉
          </span>{" "}
        </div>
      );
    }
    if (!this.props.data.length) {
      return [
        <section key="1" className="todos-main-item">
          <section className="todos-main-item__top">
            <Checkbox completed={false} onClick={() => {}} />
            <div
              style={{
                width: "60%",
                height: "10px",
                backgroundColor: "#f9f9f9"
              }}
            />
          </section>

          <section className="todos-main-item__bottom">
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9"
              }}
            />
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9",
                marginLeft: "20px"
              }}
            />
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9",
                marginLeft: "20px"
              }}
            />
          </section>
        </section>,
        <section key="2" className="todos-main-item">
          <section className="todos-main-item__top">
            <Checkbox completed={false} onClick={() => {}} />
            <div
              style={{
                width: "80%",
                height: "10px",
                backgroundColor: "#f9f9f9"
              }}
            />
          </section>

          <section className="todos-main-item__bottom">
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9"
              }}
            />
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9",
                marginLeft: "20px"
              }}
            />
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9",
                marginLeft: "20px"
              }}
            />
          </section>
        </section>,
        <section key="3" className="todos-main-item">
          <section className="todos-main-item__top">
            <Checkbox completed={false} onClick={() => {}} />
            <div
              style={{
                width: "60%",
                height: "10px",
                backgroundColor: "#f9f9f9"
              }}
            />
          </section>

          <section className="todos-main-item__bottom">
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9"
              }}
            />
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9",
                marginLeft: "20px"
              }}
            />
            <div
              style={{
                width: "20%",
                height: "10px",
                backgroundColor: "#f9f9f9",
                marginLeft: "20px"
              }}
            />
          </section>
        </section>
      ];
    }
    return (
      <section className="todos-main-list">
        {todos.map(todo => {
          return (
            <section
              onClick={e => this.props.handleInputFocus(e, todo)}
              key={todo.id}
              className={`todos-main-item ${
                todo.id === this.props.selectedTodo
                  ? "todos-main-item--selected"
                  : "todos-main-item--unselected"
              } ${this.renderContainerBorder(todo)}`}>
              <section className="todos-main-item__top">
                <Checkbox
                  completed={todo.status.id === 2}
                  onClick={() => this.props.handleToggleCheckbox(todo)}
                />
                <input
                  ref={input => this.props.handleRefChange(input, todo)}
                  onChange={e => this.props.handleInputChange(e, todo)}
                  onFocus={e => this.props.handleInputFocus(e, todo)}
                  className="todos-main-item__input"
                  value={todo.title}
                  style={{
                    color: todo.status.id === 2 ? "#d6d6d6" : "",
                    textDecoration:
                      todo.status.id === 2 ? "line-through" : "none"
                  }}
                />
                <div className="todos-main-item__badges">
                  {renderDueDateLabel(todo.due_date)}
                  {todo.team && (
                    <span className="badge badge-team bg-dark text-white">
                      {todo.team.name}
                    </span>
                  )}
                  {todo.client && (
                    <span className="badge badge-client bg-dark text-white">
                      {todo.client.name}
                    </span>
                  )}
                </div>
              </section>

              <section className="todos-main-item__bottom">
                {this.renderFollowerMeta(todo)}
                {this.renderSubtaskMeta(todo)}
                {this.renderCommentMeta(todo)}
              </section>
            </section>
          );
        })}
      </section>
    );
  }
}
export default TodosList;
