import React, { Component, Fragment } from "react";
import {
  array,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string
} from "prop-types";
import { getEndDate, getStartDate } from "screens/sites/helpers/date_helpers";

import { Panel } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import SEGroupsListItem from "./SEGroupsListItem";
import { connect } from "react-redux";
import { fetchSERankings } from "screens/sites/actions";

class SERankings extends Component {
  static propTypes = {
    fetchSERankings: func,
    siteId: oneOfType([string, number]),
    se_site_id: oneOfType([string, number]),
    date: shape({
      month: string,
      year: string
    }),
    data: array,
    isPublic: bool,
    public_hash: string
  };

  state = {
    isFetching: false,
    displayKeywords: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    if (!this.props.se_site_id) return;
    const params = {
      siteId: this.props.se_site_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId = this.props.se_site_id !== nextProps.se_site_id;

    if (!this.props.se_site_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.se_site_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.fetchData(params);
    }

    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.se_site_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.props.clearCacheBool === true && this.props.fetchSERankings(
        params,
        () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchSERankings(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.se_site_id) return null;
    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            {this.props.data.map(ranking => {
              return (
                <table key={ranking.region} className="table">
                  <thead>
                    <tr>
                      <th colSpan="4">{ranking.region}</th>
                    </tr>
                  </thead>

                  {ranking.groups.map(group => {
                    return [
                      <thead key={`thead-${group.name}`}>
                        <tr>
                          <th colSpan="4">{group.name}</th>
                        </tr>
                      </thead>,
                      <tbody key={`tbody-${group.name}`}>
                        <tr>
                          <td>Keyword</td>
                          <td>Position This Month</td>
                          <td>Position 3 Months Ago</td>
                          <td>Change</td>
                        </tr>
                        {group.keywords.map((keyword, index) => {
                          return (
                            <tr key={index}>
                              <td>{keyword.name}</td>
                              <td>
                                {
                                  keyword.positions[
                                    keyword.positions.length - 1
                                  ].pos
                                }
                              </td>
                              <td>{keyword.positions[0].pos}</td>
                              <td>
                                {keyword.positions[0].pos -
                                  keyword.positions[
                                    keyword.positions.length - 1
                                  ].pos}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ];
                  })}
                </table>
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        <ReportSubTitle text="Rankings" classes="pt-3 clearfix" />
        <Panel containerClass="col-md-12 p-3">
          {this.props.data.map((region, index) => (
            <Fragment key={index}>
              <Panel.Header title={region.region} />
              <Panel.Body
                isLoading={this.state.isFetching}
                render={() => {
                  return (
                    <div className="p-3 w-100">
                      {region.groups.map(group => (
                        <SEGroupsListItem key={group.id} {...group} />
                      ))}
                    </div>
                  );
                }}
              />
            </Fragment>
          ))}
        </Panel>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, id: siteId, se_site_id },
      rankingData: { data }
    }
  }
}) => ({
  date,
  siteId,
  se_site_id,
  data,
  isPublic,
  public_hash
});

export default connect(mapStateToProps, { fetchSERankings })(SERankings);
