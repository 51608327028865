import React, { Component } from 'react'
import StarRatingComponent from 'react-star-rating-component';

const ReviewStars = (props) => {
    let data;
    props.name === 'Yelp' && (data = props.yelpData)
    props.name === 'Google' && (data = props.googleData)
    props.name === 'Facebook' && (data = props.fbData)
    let rounded = (Math.round(data * 2) / 2).toFixed(1)
    let roundedNumber = parseInt(rounded);
    return (
        <div className="star-container">
            <StarRatingComponent
                name="rate2"
                editing={false}
                renderStarIcon={() => <i className="fa fa-star "></i>}
                renderStarIconHalf={() => <i className="fa fa-star-half"></i>}
                emptyStarColor={'#d3d3d3'}
                starColor={"#9964F2"}
                value={roundedNumber}
            />
        </div>
    )
}

export default class ReviewPanelContent extends Component {
    render() {
        let { yelpReviewCount, googleReviewCount, facebookReviewCount } = this.props;
        // Easy access to see if no data is coming in
        yelpReviewCount === undefined && console.log("No Yelp Data Is Coming In");
        googleReviewCount === undefined && console.log("No Google Data Is Coming In");
        facebookReviewCount === undefined && console.log("No Facebook Data Is Coming In");


        (yelpReviewCount === 0 || yelpReviewCount === undefined) && (yelpReviewCount = "0");
        (googleReviewCount === 0 || googleReviewCount === undefined) && (googleReviewCount = "0");
        (facebookReviewCount === 0 || facebookReviewCount === undefined) && (facebookReviewCount = "0");


        return (
            <div className="review-panel">
                <div className="review-panel-details-container">
                    <div className="logo-container">
                        <a target="_blank" href={this.props.generateURL()}><img width={"30px"} height={"30px"} src={this.props.logo} className="review-logo-margin" alt="Review Loop" /></a>
                    </div>
                    <div className="review-count-logo-name-container">
                        <p className="review-info-title mb-0">
                            {this.props.name}
                        </p>
                        <p className="review-info mb-0">
                            {
                                this.props.name === 'Yelp' && `${yelpReviewCount} Reviews`
                            }
                            {
                                this.props.name === 'Google' && `${googleReviewCount} Reviews`
                            }
                            {
                                this.props.name === 'Facebook' && `${facebookReviewCount} Reviews`
                            }
                        </p>
                    </div>
                </div>
                <ReviewStars name={this.props.name} googleData={this.props.googleData} yelpData={this.props.yelpData} fbData={this.props.fbData} />
            </div>
        )
    }
}
