export const reviewsState = {
    reviews: {
        fb: {
            data: {

            }
        },
        google: {
            data: {

            },
            id: null,
        },
        yelp: {
            data: {
                businesses: []
            },
            id: null,
        }
    }
};

export const reviewsReducer = {
    FETCHING_FACEBOOK_REVIEW_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    fb: {
                        ...state.show.reviews.fb,
                        data: { ...payload }
                    }
                },
            },
        }
    },
    FETCHING_YELP_BUSINESS_OPTIONS_REVIEW_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    yelp: {
                        ...state.show.reviews.yelp,
                        data: {
                            ...state.show.reviews.yelp.data,
                            businesses: [...payload.data.businesses],
                            id: payload.data.id
                        }
                    }
                }
            }
        };
    },
    FETCHING_YELP_REVIEW_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    yelp: {
                        ...state.show.reviews.yelp,
                        data: {
                            businesses: [payload.data],
                        }
                    }
                }
            }
        };
    },
    FETCHING_GOOGLE_REVIEW_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    google: {
                        ...state.show.reviews.google,
                        data: { ...payload.result }
                    }
                },
            },
        };
    },
    SAVE_YELP_BUSINESS_ID_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    yelp: {
                        ...state.show.reviews.yelp,
                        data: {
                            ...state.show.reviews.yelp.data,
                            businesses: [...state.show.reviews.yelp.data.businesses],
                        },
                        id: payload,
                    }
                },
            },
        };
    },
    SAVE_GOOGLE_PLACES_ID_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    google: {
                        ...state.show.reviews.google,
                        data: { ...state.show.reviews.google.data },
                        id: payload
                    }
                },
            },
        };
    },
    FETCH_REVIEW_IDS_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    yelp: {
                        ...state.show.reviews.yelp,
                        data: {
                            ...state.show.reviews.yelp.data,
                            businesses: [...state.show.reviews.yelp.data.businesses],
                        },
                        id: payload.yelp_business_id
                    },
                    google: {
                        ...state.show.reviews.google,
                        data: { ...state.show.reviews.google.data },
                        id: payload.google_id
                    }
                },
            },
        };
    },
    UPDATE_YELP_BUSINESS_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    yelp: {
                        ...state.show.reviews.yelp,
                        data: {
                            ...state.show.reviews.yelp.data,
                            businesses: [payload],
                        },
                    },

                },
            },
        };
    },
    UPDATE_REVIEWS_DISCONNECTED_YELP_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    yelp: {
                        ...state.show.reviews.yelp,
                        data: {
                            ...state.show.reviews.yelp.data,
                        },
                        id: null
                    },

                },
            },
        }
    },
    UPDATE_REVIEWS_DISCONNECTED_GOOGLE_SUCCESS: (state, payload) => {
        return {
            ...state,
            show: {
                ...state.show,
                reviews: {
                    ...state.show.reviews,
                    google: {
                        ...state.show.reviews.google,
                        data: { ...state.show.reviews.google.data },
                        id: null
                    }
                },
            },
        };
    },

}

