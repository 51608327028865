import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import rl_logos from "images/rl_logos.png";
import { FormGroup } from "common";
import { handleInputChange } from "lib/utilities";
import axios from "axios";
import { ReviewLoopDemoConfirmation } from "./ReviewDemoConfirm";

class ReviewLoopInfo extends Component {
    state = {
        modalIsOpen: false,
        name: '',
        lastName: '',
        number: '',
        confirm: false
    }

    displayModal = () => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen, confirm: false })
    }

    openEmail = (email) => {
        window.location.href = `mailto:${email}`;
    }
    tryDemo = async () => {
        let name = this.props.managerInfo.first_name;
        try {
            let response = await axios({
                method: 'POST',
                url: "https://api.reviewloop.app/v1/public/sms",
                data: {
                    first_name: this.state.name,
                    last_name: this.state.lastName,
                    phone_number: this.state.number,
                    token: 'rl-7c7348f795f29be1ba43c9001cd7d17e',
                    custom_message: `Thanks for being a Studio III client! Let us know how we’re doing. (This is a demo example connected to our ReviewLoop account, if you are interested in using ReviewLoop contact ${name})`
                },
                // headers: {
                //     'Accept': 'application/json',
                //     'Content-Type': 'application/json'
                // }
            });
            this.setState({ confirm: true });
        } catch (err) {
            console.log(err)
        }
    }

    render() {
        return (
            <Fragment>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.displayModal}
                    contentLabel="Example Modal"
                    className="react-modal lead-modal rl-demo-modal center-modal"
                    overlayClassName="react-modal-overlay">

                    {
                      this.state.confirm === true ? <ReviewLoopDemoConfirmation displayModal={this.displayModal} /> :
                            <div className="modal-container">
                                <p className="modal-font-h">Review Loop In Action!</p>
                                <p className="modal-font-p">Review Loop allows you to use this system to request reviews from your own customers. Enter your details below to see it in action.</p>
                                <div className="center-inputs mt-2 mb-2">
                                    <FormGroup
                                        customClasses={"input-height input-width "}
                                        classes="change-width mb-1"
                                        placeholder="First Name"
                                        value={this.state.name}
                                        onChange={e => handleInputChange(this, e, "name")}
                                    />
                                    <FormGroup
                                        customClasses={"input-height input-width "}
                                        classes="change-width ml-2 mb-1"
                                        placeholder="Last Name"
                                        value={this.state.lastName}
                                        onChange={e => handleInputChange(this, e, "lastName")}
                                    />
                                </div>
                                <FormGroup
                                    customClasses={"input-height input-width mb-2"}
                                    classes="phone-width"
                                    placeholder="Phone Number"
                                    value={this.state.number}
                                    onChange={e => handleInputChange(this, e, "number")}
                                />
                                <div className="center-buttons mt-4">
                                    <button className="btn" onClick={() => { this.tryDemo() }}>Send</button>
                                    <button className="btn btn-secondary" onClick={() => { this.displayModal() }}>Cancel</button>
                                </div>

                            </div>
                    }
                </Modal>
                <div className="col-sm-12 col-md-10 offset-md-1">
                    <div className="review-loop-info-container">
                        <div className='review-loop-info'>
                            <div className='review-loop-content'>
                                <h3 className="color-white">Need More Reviews?</h3>
                                <p>Use our review management platform to get feedback from your customers and monitor your online repution!</p>
                            </div>
                            <div className='review-loop-buttons'>
                                <button className={"indigoBG"} onClick={() => { this.displayModal() }}>Try Demo</button>
                                <button className={"whiteBG"} onClick={() => { this.openEmail(this.props.managerInfo.email) }}>Contact {this.props.managerInfo.first_name}</button>
                            </div>
                        </div>
                        <div className="review-loop-pic">
                            <a href="https://www.reviewloop.app" target="_blank"><img alt="review-loop" src={rl_logos} /></a>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export { ReviewLoopInfo };
