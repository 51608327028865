import React, { Component } from "react";
import { array, func } from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { fetchOneUser } from "../../users/actions/users_actions";

import { fetchClients } from "screens/clients/actions";
import { fetchUsers } from "screens/users/actions";
import { Button, NameBadge, SearchBar, ViewWithLoader, Toggle } from "common";
import { filterResults, handleInputChange } from "lib/utilities";

class ClientsIndex extends Component {
  static propTypes = {
    fetchClients: func,
    fetchUsers: func,
    clientsData: array,
    usersData: array
  };

  state = { filter: "", manager: {}, isFetching: true, show_archived: false };

  componentDidMount() {
    const user_id = this.props.authentication.data.user_id;
    this.props.fetchUsers();
    this.props.fetchClients(() => this.setState({ isFetching: false }));
    this.props.fetchOneUser(user_id);
  }

  handleCheckboxChange = (event) => {
    event.preventDefault();
    this.setState({
      show_archived: !this.state.show_archived,
    });
  };

  onSelectManager = manager => {
    const selectedManager = manager === null ? { value: "" } : manager;
    this.setState({ manager: selectedManager });
  };

  render() {
    let results = filterResults(this.props.clientsData, this.state.filter);
    if (this.state.manager.value) {
      results = results.filter(client => {
        return client.manager.id === this.state.manager.id;
      });
    }
    if (this.state.show_archived) {
      results = results.filter(client => {
        return client.archived === 1;
      });
    } else {
      results = results.filter(client => {
        return client.archived === 0;
      });
    }
    const managerOptions = this.props.usersData.map(manager => ({
      value: `${manager.first_name} ${manager.last_name}`,
      label: `${manager.first_name} ${manager.last_name}`,
      id: manager.id
    }));

    return (
      <div className="row">
        <div className="mb-4 w-100 d-flex flex-row justify-content-between pl-3 pr-3">
          <section className="w-50 d-flex flex-row">
            <SearchBar
              value={this.state.filter}
              handleChange={e => handleInputChange(this, e, "filter")}
              styles={{ width: "183px" }}
            />
            <Select
              placeholder="Filter By Manager"
              name="form-field-name"
              onChange={this.onSelectManager}
              value={this.state.manager.value}
              options={managerOptions}
              class="filter-managers-select"
            />

          </section>
          <div className="archive-filter">
            <Toggle
              label="Archived"
              toggle={this.state.show_archived}
              onChange={this.handleCheckboxChange}
            />
          </div>
          <Button link to="/clients/new">
            Create Client
          </Button>
        </div>

        <ViewWithLoader
          classes="row"
          isLoading={this.state.isFetching}
        >
          {results.map(data => {
            return (
              <NameBadge
                key={data.id}
                id={data.id}
                name={data.name}
                subtitle={data.category.name}
                user={data.manager}
                linkTo={`/clients/${data.id}`}
              />
            );
          })}
        </ViewWithLoader>
      </div>
    );
  }
}

const mapStateToProps = ({
  clients: { index: { data: clientsData } },
  user: { index: { data: usersData }, user_info, authentication },
}) => ({
  clientsData,
  usersData,
  user_info,
  authentication,
});

const connected = connect(mapStateToProps, {
  fetchOneUser,
  fetchClients,
  fetchUsers
})(ClientsIndex);

export { connected as ClientsIndex };
