import axios from "axios";
import { getToken, requestHeaders } from "lib/authHelpers";
import { rootUrl } from "lib/api";

export const fetchSERankingsAccounts = async (callback = () => {}) => {
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/rankings/sites`;

  const { data: seRankingsAccounts } = await axios.get(url, headers);
  callback(seRankingsAccounts);
};

export const formatSERankingsAccounts = account => ({
  value: account.name,
  label: account.name,
  id: account.id
});

export const filterUnreportedGroups = data => {
  const filteredData = data.filter(
    item => !item.name.toLowerCase().includes("not reported")
  );
  return filteredData;
};

export const determinePositions = positions => {
  const result = {
    positionLastMonth: null,
    positionThisMonth: null
  };

  let [{ pos: positionLastMonth }] = positions;
  let [{ pos: positionThisMonth }] = positions.slice(-1);

  positionLastMonth = parseInt(positionLastMonth, 0);
  positionThisMonth = parseInt(positionThisMonth, 0);

  result.positionLastMonth = positionLastMonth;
  result.positionThisMonth = positionThisMonth;

  return result;
};

export const determineChange = ({ positionLastMonth, positionThisMonth }) => {
  let change = positionLastMonth - positionThisMonth;

  change = positionLastMonth === 0 ? 100 - positionThisMonth : change;
  change = positionThisMonth === 0 ? "n/a" : change;

  return change;
};

const isRankedFirst = ({ positionThisMonth }) => {
  return positionThisMonth === 1;
};

export const rankingsMap = (positions, change) => {
  const rankingsMap = {
    changeClass: "",
    movement: ""
  };

  if (change === 0 || change === "n/a") {
    rankingsMap.changeClass = "fas fa-minus";
    rankingsMap.movement = "neutral";
  }

  if (change > 0) {
    rankingsMap.changeClass = "fas fa-chevron-up";
    rankingsMap.movement = "positive";
  }

  if (change < 0) {
    rankingsMap.changeClass = "fas fa-chevron-down";
    rankingsMap.movement = "negative";
  }

  if (change >= 5) {
    rankingsMap.changeClass = "fas fa-fire";
    rankingsMap.movement = "fire";
  }

  if (isRankedFirst(positions)) {
    rankingsMap.changeClass = "fas fa-trophy";
    rankingsMap.movement = "top";
  }

  return rankingsMap;
};
