import { changePercentDifference } from "lib/numberHelper";

const formatReports = payload => {
  if (payload.reports[0].data.rows === undefined) {
    return undefined;
  }
  let data = payload.reports[0].data.rows.splice(0, 10);
  const formattedArray = [];

  data.forEach(item => {
    const thisItem = item.metrics[0].values[0];
    const lastItem = item.metrics[1].values[0];
    const obj = {
      page: item.dimensions[0],
      thisMonth: thisItem,
      lastMonth: lastItem,
      change: String(thisItem - lastItem),
      percentChange: changePercentDifference(thisItem, lastItem)
    };
    formattedArray.push(obj);
  });

  return formattedArray.filter(item => item.page !== "(not set)");
};

export const googleAnalyticsTopLandingPages = ({
  dispatch
}) => next => action => {
  if (!action.fromGATopLanding) {
    return next(action);
  }

  let formattedData = formatReports(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGATopLanding: false
  };

  dispatch(newAction);
};



export const googleAnalyticsTopLandingPagesGA4 = ({
  dispatch
}) => next => action => {
  if (!action.fromGATopLandingGA4) {
    return next(action);
  }
  const dataKey = 'newUsers' 
  let formattedData = (action.payload.rows || []).map((n) => ({
    page: n.dimensionValue,
    thisMonth: n.current[dataKey],
    lastMonth: n.previous[dataKey],
    change: String(parseInt(n.current[dataKey]) - parseInt(n.previous[dataKey])),
    percentChange: changePercentDifference(parseInt(n.current[dataKey]), parseInt(n.previous[dataKey])),
  }));
  const newAction = {
    ...action,
    payload: formattedData,
    fromGATopLandingGA4: false
  };

  dispatch(newAction);
};



export const googleAnalyticsTopLandingPagesGA4PageViews = ({
  dispatch
}) => next => action => {
  if (!action.fromGATopLandingPageViewsGA4) {
    return next(action);
  }
  const dataKey = 'screenPageViews';
  let formattedData = (action.payload.rows || []).map((n) => ({
    page: n.dimensionValue,
    thisMonth: n.current[dataKey],
    lastMonth: n.previous[dataKey],
    change: String(parseInt(n.current[dataKey]) - parseInt(n.previous[dataKey])),
    percentChange: changePercentDifference(parseInt(n.current[dataKey]), parseInt(n.previous[dataKey])),
  }));
  const newAction = {
    ...action,
    payload: formattedData,
    fromGATopLandingPageViewsGA4: false
  };

  dispatch(newAction);
};
