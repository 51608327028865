import React, { Component } from "react";
import SERankings from "screens/sites/components/SERankings";

class PrintRankings extends Component {
  render() {
    return (
      <div className="row">
        <div className="print-page" style={{ width: "100%" }}>
          <h1>SE Rankings</h1>
          <SERankings />
        </div>
      </div>
    );
  }
}

export default PrintRankings;
