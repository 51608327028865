import React, { Component } from "react";
import { number, string } from "prop-types";
import { Button, Panel } from "common";

export default class KlaviyoConnect extends Component {
  static propTypes = {
    siteId: number.isRequired,
    klaviyoApiKey: string
  };

  state = { key: "" };

  render() {
    if (this.props.klaviyoApiKey) return null;
    const isDisabled = !this.state.key;

    return (
      <Panel containerClass="col-md-12 p-3">
        <Panel.Header title="Connect Klaviyo" />
        <Panel.Body
          className="p-5"
          style={{ display: "block" }}
          render={() => {
            return (
              <form
                onSubmit={() => {
                  if(isDisabled) return;
                  this.props.updateSite(this.props.siteId, {
                    klaviyo_api_key: this.state.key
                  })
                }
                }>
                <input
                  id="klaviyo-url"
                  type="text"
                  className="form-control mb-4"
                  placeholder="Klaviyo Key"
                  value={this.state.key}
                  onChange={e => this.setState({ key: e.target.value })}
                />
                <Button
                  disabled={isDisabled}>
                  Connect
                </Button>
              </form>
            );
          }}
        />
      </Panel>
    );
  }
}
