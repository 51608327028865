import _ from "lodash";
import React, { Component } from "react";
import { bool, string, array } from "prop-types";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Panel } from "common";

const COLORS = ["#619bf9", "#abcafc", "#dce9fe"];

class GenericPieGraph extends Component {
  static propTypes = {
    title: string,
    isLoading: bool,
    errors: string,
    data: array
  };

  render() {
    const isLoading = this.props.isLoading || _.isEmpty(this.props.data);
    return (
      <Panel
        containerClass="col-md-6 p-3 float-left"
        isLoading={isLoading}
        errors={this.props.errors}
        padding={2}>
        <Panel.Subtitle text={this.props.title} />
        <Panel.Body
          render={() => {
            return (
              <ResponsiveContainer height={230}>
                <PieChart className={`${this.props.isEcommerce ? "device-graph pie-ecomm" : "device-graph "}`} width={230} height={120}>
                  <Pie
                    labelLine={false}
                    dataKey="value"
                    data={this.props.data}
                    cx={115}
                    cy={115}
                    innerRadius={30}
                    outerRadius={60}>
                    {this.props.data.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            );
          }}
        />
      </Panel>
    );
  }
}
GenericPieGraph.defaultProps = {
  isEcommerce: false,
}
export { GenericPieGraph };
