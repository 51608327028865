import React, { Component } from "react";
import { isEmpty } from "lodash"

class AverageRatingReviews extends Component {
    state = {
        yelpRating: 0,
        facebookRating: 0,
        googleRating: 0,
        googleReviewCount: 0,
        facebookReviewCount: 0,
        yelpReviewCount: 0,
        average: 0,
        calculating: true,
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.yelpData !== this.props.yelpData || prevProps.googleData !== this.props.googleData || prevProps.fbData !== this.props.fbData) {
            // Refactor
            if (!isEmpty(this.props.googleData)) {
                if (this.props.googleId === null) {
                    this.setState({ googleRating: 0 })
                } else if (this.props.googleData.rating === undefined || this.props.googleData.user_ratings_total === undefined) {
                    this.setState({ googleRating: 0, googleReviewCount: 0 })
                } else {
                    this.setState(function (state, props) {
                        return {
                            googleRating: this.props.googleData.rating,
                            googleReviewCount: this.props.googleData.user_ratings_total,
                        }
                    });
                }
            }
            if (!isEmpty(this.props.fbData)) {
                if (this.props.fbPageId === null) {
                    this.setState({ facebookRating: 0 })

                } else {
                    this.setState(function (state, props) {
                        return {
                            facebookRating: this.props.fbData.overall_star_rating,
                            facebookReviewCount: this.props.fbData.rating_count,
                        }
                    });
                }
            }
            if (this.props.yelpData.length > 0) {
                if (this.props.yelpId === null) {
                    this.setState({ yelpRating: 0 })
                } else {
                    this.setState(function (state, props) {
                        return {
                            yelpRating: this.props.yelpData[0].rating,
                            yelpReviewCount: this.props.yelpData[0].review_count,
                        }
                    });
                }

            }

        }
    
        prevState.yelpRating !== this.state.yelpRating && this.calculateAverage();
        prevState.facebookRating !== this.state.facebookRating && this.calculateAverage();
        prevState.googleRating !== this.state.googleRating && this.calculateAverage();

    }

    calculateAverage = () => {
        let totalReviewScore = 0;
        const stateValues = ['yelpRating', 'googleRating', 'facebookRating'];
        const siteValues = ['googleId', 'yelpId', 'fbPageId'];
        for (let key in this.state) {
            if (stateValues.includes(key)) {

                this.state[key] !== 0 && (totalReviewScore += this.state[key])
            }
        }
        let average = 0;
        let siteCount = 3;
        for (let key in this.props) {
            if (siteValues.includes(key)) {
                this.props[key] === null && (siteCount -= 1);
            }
        }
        totalReviewScore !== 0 && (average = totalReviewScore / siteCount)
        average = Math.round(average * 10) / 10;
        isNaN(average) ? this.setState({ average: 'N/A', calculating: false }) :
            this.setState({
                average,
                calculating: false,
            })
    }

    render() {
        return (
            <div className="average-rating-reviews-container">
                <h2 className="mb-1">Average Rating</h2>
                <div className="average-circle">
                    {
                        this.state.calculating === false ? < span className="avg-number">{this.state.average}</span> :
                            <span className="avg-number">...</span>
                    }
                </div>
            </div >

        );
    }
}

export { AverageRatingReviews };
