import React, { Component } from "react";

import { BarGraph } from "common";

class DemoGoogleAnalyticsSocialSourcePageViews extends Component {
    state = { isFetching: false };
    render() {
        // Fixtures 
        const socialSources = {
            "Instagram": 29,
            "Yelp": 24,
            "Facebook": 6,
            "Instagram Stories": 2,
            "Pinterest": 1
        }
        const isFetching = false;
        return (
            <BarGraph
                title="Social Source Page Views"
                yLabel="Views"
                isLoading={isFetching}
                data={socialSources}
                barSize={20}
            />
        );
    }
}

export default DemoGoogleAnalyticsSocialSourcePageViews;

