import React, { Component } from "react";
import { StatisticsBar } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_amazon.png";

class DemoAmazonSales extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        const amazonReports = {
            "totalSales": {
                "title": "Total Sales",
                "value": "$253,172"
            },
            "ordersCount": {
                "title": "Total Orders",
                "value": "1300"
            }
        }


        return (
            <StatisticsBar
                renderSubtitle={() => (
                    <ReportSubTitle
                        text="Amazon Sales"
                        classes="pt-3 clearfix"
                        logo={logo}
                    />
                )}
                borderColor="#f29d39"
                isLoading={isFetching}
                isPublic={false}
                data={amazonReports}
            />
        );
    }
}

export default DemoAmazonSales;