import _ from "lodash";
import { filterUnreportedGroups } from "screens/sites/helpers/rankings_helpers";

const formatData = ({ statsResponse, keywordsResponse, groupsResponse }) => {
  const groupData = filterUnreportedGroups(groupsResponse.data);

  const parsedData = _.map(statsResponse.data, region => {
    let obj = {};
    obj.region = region.region_name;
    obj.seID = region.seID;

    let keywords = _.map(region.keywords, keyword => {
      return _.extend(
        keyword,
        _.find(keywordsResponse.data, { id: keyword.id })
      );
    });

    let groups = _.chain(keywords)
      .groupBy("group_id")
      .map((val, key) => ({ id: key, keywords: val }))
      .value();

    obj.groups = _.map(groups, el => {
      return _.extend(el, _.find(groupData, { id: el.id }));
    });

    return obj;
  });

  const sortedParsedData = parsedData.map(group => {

    let sortedGroups = group.groups.map(item => {

      //reverse array
      item.keywords.forEach((item, index) => {

        let bucket = {};
        let updatedPositions = [];

        item.positions.forEach( (item, index) => {
          let date_array = item.date.split("-");
          let key =  parseInt(date_array[0], 10) + (parseInt(date_array[1], 10) + "").padEnd(2, "0");
          (bucket[key] ? bucket[key].push(item) : bucket[key] = [item] );
        });

        for (let [key, value] of Object.entries(bucket)) {
          updatedPositions.push(...value)
        }

        item.positions = updatedPositions;

      });


      let sortedKeywords = item.keywords.sort((a, b) => {

        /*
        * If the position is equal to 0, overwrite to 100
        * */

        if(a.positions[a.positions.length - 1].pos === 0){
          a.positions[a.positions.length - 1].pos = 100;
        }
        if(b.positions[b.positions.length - 1].pos === 0){
          b.positions[b.positions.length - 1].pos = 100;
        }
        return (
          a.positions[a.positions.length - 1].pos -
          b.positions[b.positions.length - 1].pos
        );
      });


      const index = sortedKeywords.findIndex(keyword => {
        return keyword.positions[keyword.positions.length - 1].pos !== "0";
      });

      if (index > 0) {
        sortedKeywords = [
          ...sortedKeywords.splice(index),
          ...sortedKeywords.splice(0, index)
        ];
      }

      return {
        ...item,
        keywords: sortedKeywords
      };
    });

    const index = sortedGroups.findIndex(item => {
      if (item.name) {
        return item.name.toLowerCase().includes("priority");
      }
      return 0;
    });

    sortedGroups =
      index > 0
        ? [
            sortedGroups[index],
            ...sortedGroups.slice(0, index),
            ...sortedGroups.slice(index + 1)
          ]
        : sortedGroups;

    sortedGroups = sortedGroups.filter(group => group.name);

    return {
      ...group,
      groups: sortedGroups
    };
  });

  return sortedParsedData;
};

export const seRankings = ({ dispatch }) => next => action => {
  if (!action.fromSeRankings) return next(action);

  const formattedData = formatData(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromSeRankings: false
  };

  dispatch(newAction);
};
