import React, { Component } from "react";
import GoogleAdwordsOverview from "screens/sites/components/GoogleAdwordsOverview";
import GoogleAdwordsMonthlyConversions from "screens/sites/components/GoogleAdwordsMonthlyConversions";
import GoogleAdwordsConversionSources from "screens/sites/components/GoogleAdwordsConversionSources";
import GoogleAdwordsCampaigns from "screens/sites/components/GoogleAdwordsCampaigns";
import FacebookAdOverview from "screens/sites/components/FacebookAdOverview";
import FacebookAdCampaigns from "screens/sites/components/FacebookAdCampaigns";
import AdwordsMTM from "screens/sites/components/AdwordsMTM";
import AdwordMatrix from "screens/sites/components/AdwordsMatrix";
import {connect} from "react-redux";

class PrintPaidAds extends Component {
  render() {
    return (
      <div className="row">
        {
          (this.props.site || {}).adwords_id !== null ? (
            <div className="print-page" style={{ width: "100%" }}>
              <h1>Google Adwords</h1>
              <GoogleAdwordsOverview />
              <GoogleAdwordsMonthlyConversions />
              <GoogleAdwordsConversionSources />
              <AdwordsMTM />
              <AdwordMatrix />
              <GoogleAdwordsCampaigns />
            </div>
          ) : null
        }
        {
          (this.props.site || {}).fb_ads_id !== null ? (
            <div className="print-page" style={{ width: "100%" }}>
              <h1>Facebook Ads</h1>
              <FacebookAdOverview />
              <FacebookAdCampaigns />
            </div>
          ) : null
        }
      </div>
    );
  }
}


const mapStateToProps = ({ sites: { show: { data } } }) => ({ site: data });

const connected = connect(mapStateToProps, {})(PrintPaidAds);

export default connected;
