import React from "react";
import { object, func, string, number, oneOfType } from "prop-types";
import { connect } from "react-redux";
import { Button } from "common";
import { updateSite } from "screens/sites/actions";
import { store } from "store";

ThirdPartyDisconnect.propTypes = {
  siteData: object,
  updateSite: func,
  displayName: string,
  type: string,
  siteId: oneOfType([string, number]),
};

function ThirdPartyDisconnect(props) {
  if (!props.siteData[props.type] && !props.ga4_account_id) return null;
  return (
    <Button
      classes="btn-disconnect"
      style={{
        float: "right",
        marginRight: props.marginRight ? props.marginRight : "1em",
      }}
      onClick={() => {
        if (props.type === "ga4_account_id") {
          props.updateSite(
            props.siteId,
            {
              ga4_property_id: null,
              ga4_account_id: null,
            },
            () => {
              if (props.redirectOnDisconnect === false) {
                if (props.isListView) {
                  store.dispatch({
                    type: "SET_GOOGLE_ANALYTICS_CONNECT_PROPERTIES_SITES_ARRAY",
                    payload: {
                      id: props.siteId,
                      ga4_property_id: null,
                      ga4_account_id: null,
                    },
                  });
                } else {
                  store.dispatch({
                    type: "SET_GOOGLE_ANALYTICS_CONNECT_PROPERTIES",
                    payload: {
                      ga4_property_id: null,
                      ga4_account_id: null,
                    },
                  });
                }
                if (props.ga4ConnectOnClick) {
                  props.ga4ConnectOnClick();
                }
              }
            },
            props.redirectOnDisconnect
          );
          return;
        }
        props.updateSite(props.siteId, { [props.type]: null });
        if (props.isMCC) {
          props.updateSite(props.siteId, { ["adwords_mcc_id"]: null });
        }
      }}
    >
      {props.ga4ConnectOnClick ? "Yes" : `Disconnect ${props.displayName}`}
    </Button>
  );
}

const mapStateToProps = ({
  sites: {
    show: { data: siteData },
  },
}) => ({
  siteData,
});

export default connect(mapStateToProps, { updateSite })(ThirdPartyDisconnect);
