import _ from "lodash";
import React, { Component } from "react";
import { func, array, number, shape, string, bool } from "prop-types";
import { connect } from "react-redux";

import { withGoogleMap, withScriptjs, GoogleMap } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { mapStyle } from "../../screens/sites/components/mapStyle";

import { Card } from "common";
import { fetchGoogleAnalyticsVisitorsByState } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";

const LEGEND = {
  0: { color: "#619bf9", size: 60 },
  1: { color: "#619bf9", size: 50 },
  2: { color: "#619bf9", size: 50 },
  3: { color: "#abcafc", size: 40 },
  4: { color: "#abcafc", size: 25 },
  5: { color: "#abcafc", size: 25 },
  6: { color: "#dce9fe", size: 25 },
  7: { color: "#dce9fe", size: 25 },
  8: { color: "#dce9fe", size: 25 },
  9: { color: "#dce9fe", size: 25 }
};

const MAP_URL =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBI8ED_BdoObN87xnIiipO6pBuzuwTUK3Y&v=3.exp&libraries=geometry,drawing,places";

const VisitorsByLocation = withScriptjs(
  withGoogleMap(props => {
    const bounds = new window.google.maps.LatLngBounds();
    props.data.map(region => {
      const { lat, lng } = region;
      const latLng = new window.google.maps.LatLng(lat, lng);
      bounds.extend(latLng);
      return latLng;
    });

    return (
      <GoogleMap
        ref={map => map && map.fitBounds(bounds)}
        defaultZoom={8}
        defaultCenter={{ lat: -34.397, lng: 150.544 }}
        defaultOptions={{ styles: mapStyle }}>
        {_.map(props.data, (item, index) => {
          return (
            <InfoBox
              key={index}
              defaultPosition={
                new window.google.maps.LatLng(item.lat, item.lng)
              }
              options={{ closeBoxURL: ``, enableEventPropagation: true }}>
              <div
                style={{
                  backgroundColor: LEGEND[item.position]["color"],
                  height: `${LEGEND[item.position]["size"]}px`,
                  width: `${LEGEND[item.position]["size"]}px`,
                  borderRadius: `${LEGEND[item.position]["size"] / 2}px`,
                  opacity: 0.8,
                  border: `4px solid ${LEGEND[item.position]["color"]}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white"
                }}>
                {item.newUsers}
              </div>
            </InfoBox>
          );
        })}
      </GoogleMap>
    );
  })
);

class DemoGoogleAnalyticsVisitorsByLocation extends Component {
  static propTypes = {
    fetchGoogleAnalyticsVisitorsByState: func,
    newVisitorsByState: array,
    siteId: number,
    ga_property_id: string,
    date: shape({
      month: string,
      year: string
    }),
    isPublic: bool,
    public_hash: string
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };

  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId =
      this.props.ga_property_id !== nextProps.ga_property_id;

    if (!this.props.ga_property_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.fetchData(params);
    }
  };

  fetchData = params => {
    if (!params.siteId || !this.props.ga_property_id) return;
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsVisitorsByState(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    // Fixtures 
    const isFetching = false;
    const newVisitorsByState = [
      {
        "position": 5,
        "region": "California",
        "city": "Long Beach",
        "lat": "33.7701",
        "lng": "-118.1937",
        "newUsers": "15"
      },
      {
        "position": 4,
        "region": "California",
        "city": "Manhattan Beach",
        "lat": "33.8847",
        "lng": "-118.4109",
        "newUsers": "25"
      },
      {
        "position": 3,
        "region": "California",
        "city": "Rancho Palos Verdes",
        "lat": "33.7445",
        "lng": "-118.3870",
        "newUsers": "29"
      },
      {
        "position": 2,
        "region": "California",
        "city": "Torrance",
        "lat": "33.8358",
        "lng": "-118.3406",
        "newUsers": "43"
      },
      {
        "position": 1,
        "region": "California",
        "city": "Redondo Beach",
        "lat": "33.8492",
        "lng": "-118.3884",
        "newUsers": "51"
      },
      {
        "position": 0,
        "region": "California",
        "city": "Los Angeles",
        "lat": "34.0522",
        "lng": "-118.2437",
        "newUsers": "217"
      }
    ];
    let visitorsByLocationOrdered = _.clone(
      newVisitorsByState
    ).reverse();
    return (
      <div className="row">
        <ReportSubTitle text="Organic New Visitors By Location" classes="mt-3" logo={logo} />
        <Card
          classes="col-md-12 p-3 float-left"
          isLoading={isFetching}
          hideHeader>
          <VisitorsByLocation
            googleMapURL={MAP_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            data={newVisitorsByState}
          />

          <Card hideHeader subtitle="">
            <table className="table table-hover p-5">
              <thead>
                <tr>
                  <th>Region</th>
                  <th>City</th>
                  <th>New Users</th>
                </tr>
              </thead>

              <tbody>
                {_.map(visitorsByLocationOrdered, (item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.region}</td>
                      <td>{item.city}</td>
                      <td>{item.newUsers}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, id: siteId, ga_property_id },
      analyticsData: { newVisitorsByState }
    }
  }
}) => ({
  date,
  siteId,
  ga_property_id,
  newVisitorsByState,
  isPublic,
  public_hash
});

export default connect(mapStateToProps, {
  fetchGoogleAnalyticsVisitorsByState
})(DemoGoogleAnalyticsVisitorsByLocation);
