/*
fetchGoogleAnalyticsSocialSources
fetchGoogleAnalyticsDevices
*/

const formatReports = payload => {
  let { reports } = payload;
  if (reports[0].data.rows === undefined) {
    return undefined;
  }
  reports = reports[0].data.rows;
  const formattedData = {};

  let sortedReports = reports
    .sort((item, nextItem) => {
      return ( 
        parseInt(nextItem.metrics[0].values[0], 0) -
        parseInt(item.metrics[0].values[0], 0)
      );
    })
    .splice(0, 6);

  sortedReports.forEach(obj => {
      formattedData[obj.dimensions[0]] = parseInt(obj.metrics[0].values[0], 0);
  });

  delete formattedData["(not set)"];

  return formattedData;
};

export const googleAnalyticsSocial = ({ dispatch }) => next => action => {
  if (!action.fromGASocial) {
    return next(action);
  }

  let formattedData = formatReports(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGASocial: false,
  };

  dispatch(newAction);
};

const formatGA4Devices = (payload) => {
  if (!payload.rows) {
    return {};
  }
  return payload.rows.reduce((a, n) => {
    const label =  n.dimensionValues  ? n.dimensionValues[0].value :""
    const value = n.metricValues ? parseInt(n.metricValues[0].value)  : ""
    a[label] =  value
    return a
  },{})
};


const formatGA4Social = (payload) => {
  if (!payload.rows) {
    return {};
  }
  const entries = Object.entries(payload.rows);
  return entries.reduce((a, n) => {
    const label = n[0]
    const value = n[1]['Organic Social']
    a[label]=  value
    return a
  },{})
};


export const googleAnalyticsSocialGA4 = ({ dispatch }) => next => action => {
  if (!action.fromGASocialGA4) {
    return next(action);
  }

  let formattedData = formatGA4Social(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGASocialGA4: false,
  };

  dispatch(newAction);
};


export const googleAnalyticsDevicesGA4 = ({ dispatch }) => next => action => {
  if (!action.fromGADevicesGA4) {
    return next(action);
  }

  let formattedData = formatGA4Devices(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGADevicesGA4: false,
  };

  dispatch(newAction);
};