import _ from "lodash";
import React, { Component } from "react";
import { bool, object, string } from "prop-types";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Panel } from "common";

const COLORS = ["#619bf9", "#abcafc", "#dce9fe"];

const StringSnippet = (props) => {
  if (props.stringToDisplay !== null) {
    return (
      <div className="textCenter demographicsSnippet">{props.stringToDisplay}</div>
    )
  } else {
    return null;
  }

}

class PieGraph extends Component {
  static propTypes = {
    title: string,
    isLoading: bool,
    errors: string,
    data: object
  };

  render() {
    let data = this.props.data;
    data = _.map(data, (item, index) => ({
      name: index,
      value: item
    }));
    const displayString = this.props.stringToDisplay || null;
    const isLoading = this.props.isLoading || _.isEmpty(this.props.data);
    let heightClassname = this.props.heightClassname || "nothing";
    return (
      <Panel
        containerClass="col-md-6 p-3 float-left"
        isLoading={isLoading}
        errors={this.props.errors}
        padding={2}>
        <Panel.Subtitle text={this.props.title} />
        <Panel.Body
          render={() => {
            return (
              <ResponsiveContainer className={`${heightClassname}`} height={230}>
                <PieChart className={`device-graph ${heightClassname}`} width={230} height={180}>
                  <Pie
                    labelLine={false}
                    dataKey="value"
                    data={data}
                    cx={115}
                    cy={115}
                    innerRadius={30}
                    outerRadius={60}>
                    {data.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend wrapperStyle={{ position: "static" }} />
                </PieChart>
              </ResponsiveContainer>
            );
          }}
        />
        <StringSnippet stringToDisplay={displayString} />
      </Panel>
    );
  }
}

export { PieGraph };
