import React, { Component } from "react";
import { BarChart, ResponsiveContainer, Bar, XAxis, Tooltip } from "recharts"
import { Panel } from "common";
import { ImpressionsFixtures } from "./fixtures/PanelFixtures";

class DemoImpressions extends Component {
    render() {
        return (
            <Panel
                containerClass="col-md-6 p-3 float-left"
                isLoading={false}
                errors={null}
                padding={2}>
                <Panel.Subtitle text={this.props.text} />
                <Panel.Body
                    render={() => {
                        return (
                            <ResponsiveContainer width="100%" height={230}>
                                <BarChart width={600} height={300} data={ImpressionsFixtures}
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="name" />
                                    <Tooltip />
                                    <Bar dataKey="value" fill="rgb(97, 155, 249)" />
                                </BarChart>
                            </ResponsiveContainer>
                        );
                    }}
                />
            </Panel>
        )
    }
}

export { DemoImpressions };
