import axios from "axios";
import { getToken, requestHeaders } from "lib/authHelpers";
import { rootUrl } from "lib/api";
import { apiBase } from "lib/api";

export const fetchGoogleAnalyticsAccounts = async (id, callback = () => {}) => {
  const token = getToken();
  const url = `${rootUrl}/analytics/accounts/${id}`;
  const headers = requestHeaders(token);

  const {
    data: { items: googleAnalyticsAccounts },
  } = await axios.get(url, headers);

  callback(googleAnalyticsAccounts);
};

export const fetchGoogleAnalyticsProperties = async (
  siteId,
  accountId,
  callback = () => {}
) => {
  const token = getToken();
  const url = `${rootUrl}/analytics/properties/${siteId}/${accountId}`;
  const headers = requestHeaders(token);

  const {
    data: { items: googleAnalyticsProperties },
  } = await axios.get(url, headers);

  callback(googleAnalyticsProperties);
};

export const fetchGoogleAnalyticsAccountsV4 = async (callback = () => {}) => {
  const token = getToken();
  const rootUrl = `${apiBase}/v2`;
  const url = `${rootUrl}/analytics/accounts`;
  const headers = requestHeaders(token);

  const accounts = await axios.get(url, headers);

  callback(accounts);
};

export const fetchGoogleAnalyticsPropertiesV4 = async (
  siteId,
  accountId,
  callback = () => {}
) => {
  const token = getToken();
  const rootUrl = `${apiBase}/v2`;
  const url = `${rootUrl}/analytics/accounts/${accountId}/properties`;
  const headers = requestHeaders(token);

  const properties = await axios.get(url, headers);

  callback(properties);
};
