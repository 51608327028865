import React, { Component } from "react";
import { BarChart, ResponsiveContainer, Bar, XAxis, Tooltip } from "recharts";
import { Panel } from "common";
import { NoData } from "./NoData";
import { connect } from "react-redux";

class InstagramImpressions extends Component {
  render() {
    let data = [];
    if (
      this.props.instagramImpressionsCurrentMonthSelection &&
      this.props.instagramImpressionsTwoMonthsAgo &&
      this.props.instagramImpressionsThreeMonthsAgo
    ) {
      data = [
        this.props.instagramImpressionsThreeMonthsAgo,
        this.props.instagramImpressionsTwoMonthsAgo,
        this.props.instagramImpressionsCurrentMonthSelection,
      ];
    }
    const isLoading =
      this.props.instagramCurrentMonthLoadState === true &&
      this.props.instagramTwoMonthsAgoLoadState === true &&
      this.props.instagramThreeMonthsAgoLoadState === true;
    return this.props.instagramImpressionsCurrentMonthSelection === null &&
      this.props.instagramImpressionsTwoMonthsAgo === null &&
      this.props.instagramImpressionsThreeMonthsAgo === null ? (
      <NoData title={"No Impressions Data Available"} />
    ) : (
      <Panel
        containerClass="col-md-6 p-3 float-left"
        isLoading={isLoading}
        errors={this.props.errors}
        padding={2}
      >
        <Panel.Subtitle
          text={"Page Impressions 3 Months"}
          bubbleValues={["Organic", "Paid"]}
        />
        <Panel.Body
          render={() => {
            return (
              <ResponsiveContainer width="100%" height={230}>
                <BarChart
                  width={600}
                  height={300}
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <Tooltip
                    formatter={(value) =>
                      new Intl.NumberFormat("en").format(value)
                    }
                  />
                  <Bar dataKey="Impressions" fill="rgb(97, 155, 249)" />
                </BarChart>
              </ResponsiveContainer>
            );
          }}
        />
      </Panel>
    );
  }
}
const mapStateToProps = (state) => ({
  instagramImpressionsCurrentMonthSelection:
    state.social.instagramImpressionsCurrentMonthSelection,
  instagramImpressionsTwoMonthsAgo:
    state.social.instagramImpressionsTwoMonthsAgo,
  instagramImpressionsThreeMonthsAgo:
    state.social.instagramImpressionsThreeMonthsAgo,
  instagramCurrentMonthLoadState: state.social.instagramCurrentMonthLoadState,
  instagramTwoMonthsAgoLoadState: state.social.instagramTwoMonthsAgoLoadState,
  instagramThreeMonthsAgoLoadState:
    state.social.instagramThreeMonthsAgoLoadState,
});

const connected = connect(mapStateToProps, {})(InstagramImpressions);

export { connected as InstagramImpressions };
