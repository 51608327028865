import axios from "axios";
import { push } from "react-router-redux";
import { rootUrl } from "lib/api";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import {
  CREATING_TEMPLATE,
  UPDATING_TEMPLATE,
  FETCHING_TEMPLATES,
} from "./types";
import { authLogoutAndRedirect } from "screens/users/actions";

export const fetchTemplates = () => async () => {
  const dispatching = dispatchHelper(FETCHING_TEMPLATES);
  const token = getToken();
  const url = `${rootUrl}/templates`;
  const headers = requestHeaders(token);

  const response = await axios.get(url, headers);
  response.middleware = { fromTemplates: true };
  dispatching(response);
};

export const updateTemplate = (id, params) => async (dispatch) => {
  const dispatching = dispatchHelper(UPDATING_TEMPLATE);
  const url = `${rootUrl}/templates/${id}`;
  const token = getToken();
  const headers = requestHeaders(token);

  try {
    const response = await axios.patch(url, params, headers);
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const createTemplate = (params) => async (dispatch) => {
  const dispatching = dispatchHelper(CREATING_TEMPLATE);
  const token = getToken();
  const url = `${rootUrl}/templates`;
  const headers = requestHeaders(token);

  const response = await axios.post(url, params, headers);
  dispatching(response, () => dispatch(push(`/templates`)));
};
