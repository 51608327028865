import React, { Component } from "react";
import { connect } from "react-redux";
import { func, object } from "prop-types";

import { ViewWithLoader } from "common";
import { fetchSite, setPermission } from "screens/sites/actions";
import {
  startOfMonth,
  endOfMonth,
  startDateMMYYYY,
  endDateMMYYYY,
  getPreviousMonthStart,
  getPreviousMonthEnd
} from "lib/dateHelpers";

import { getQueryVar, hasQueryVar } from "lib/utilities";

import SiteDatePicker from "screens/sites/components/SiteDatePicker";
import SiteTabs from "screens/sites/components/SiteTabs";
import DemoSiteOverview from "./DemoSiteOverview";
import SiteLinks from "screens/sites/components/SiteLinks";

import DemoReviews from "./Social/DemoReviews";
import DemoAnalytics from "./Analytics/DemoAnalytics";
import DemoRankings from "./Rankings/DemoRankings";
import DemoLeads from "./Inqueries/DemoLeads";
import DemoPaidAds from "./PaidAds/DemoPaidAds";
import DemoECommerce from "./E-Commerce/DemoECommerce";
import DemoSocialIndex from "./Social/DemoSocialIndex";
import ReportSelection from "../screens/sites/scenes/ReportSelection";

class DemoSitesShow extends Component {
  static propTypes = {
    fetchSite: func,
    setPermission: func,
    match: object,
    location: object
  };

  state = {
    displayTab:
      window.location.hash !== "" ? window.location.hash.split("#")[1] : "none",
    startDate: getPreviousMonthStart(startOfMonth),
    endDate: getPreviousMonthEnd(endOfMonth),
    isFetching: true,
    displayYOY: getQueryVar("dy") === "true",
    compare: hasQueryVar("cp") ? getQueryVar("cp") : 6
  };

  toggleYOY = () => {
    this.setState(prevState => ({
      displayYOY: !prevState.displayYOY
    }));
  };

  changeComparison = amt => {
    this.setState(prevState => ({
      compare: amt
    }));
  };

  handleDateChange = (month, year) => {
    this.setState({
      startDate: startDateMMYYYY(month, year),
      endDate: endDateMMYYYY(month, year)
    });
  };

  handleTabSwitch = tab => {
    window.location.hash = "#" + tab;
    this.setState({ displayTab: tab });
  };

  renderTabContent = () => {
    switch (this.state.displayTab) {
      case "social":
        return <DemoSocialIndex />;
      case "reviews":
        return <DemoReviews />;
      case "e-commerce":
        return <DemoECommerce />;
      case "paid-ads":
        return <DemoPaidAds />;
      case "inquiries":
        return (
          <DemoLeads
            changeComparison={this.changeComparison}
            compare={this.state.compare}
          />
        );
      case "rankings":
        return <DemoRankings />;
      case "analytics":
        return (
          <DemoAnalytics
            displayYOY={this.state.displayYOY}
            toggleYOY={this.toggleYOY}
          />
        );
      default:
        return <ReportSelection />;
    }
  };
  render() {
    // Fixtures
    const compare = 6;
    const displayYOY = false;
    const isFetching = false;

    return (
      <ViewWithLoader isLoading={isFetching}>
        <div className="row site--control-bar blurOnHover">
          <SiteDatePicker noClickClassName={"noClicking"} />
          <SiteLinks compare={compare} displayYOY={displayYOY} />
        </div>
        <DemoSiteOverview />
        <SiteTabs
          items={[
            { name: "analytics", connected: true },
            { name: "rankings", connected: true },
            { name: "inquiries", connected: true },
            { name: "paid-ads", connected: true },
            { name: "social", connected: true },
            { name: "reviews", connected: true },
            { name: "e-commerce", connected: true },
            { name: "email", connected: true }
          ]}
          selectedTab={this.state.displayTab}
          onSwitchTab={this.handleTabSwitch}
        />
        {this.renderTabContent()}
      </ViewWithLoader>
    );
  }
}

const connected = connect(
  null,
  { fetchSite, setPermission }
)(DemoSitesShow);

export { connected as DemoSitesShow };
