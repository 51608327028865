import React, { Component } from "react";

import GoogleAnalyticsOrganicSiteMetrics from "screens/sites/components/GoogleAnalyticsOrganicSiteMetrics";
import GoogleAnalyticsVisitorsByMonth from "screens/sites/components/GoogleAnalyticsVisitorsByMonth";
import GoogleAnalyticsSessionsByMonth from "screens/sites/components/GoogleAnalyticsSessionsByMonth";
import GoogleAnalyticsEvents from "screens/sites/components/GoogleAnalyticsEvents";
import GoogleAnalyticsSocialSourcePageViews from "screens/sites/components/GoogleAnalyticsSocialSourcePageViews";
import GoogleAnalyticsVisitorsByDevices from "screens/sites/components/GoogleAnalyticsVisitorsByDevices";
import GoogleAnalyticsVisitorsByLocation from "screens/sites/components/GoogleAnalyticsVisitorsByLocation";
import GoogleAnalyticsTopLandingPages from "screens/sites/components/GoogleAnalyticsTopLandingPages";
import GoogleAnalyticsPageViews from "screens/sites/components/GoogleAnalyticsPageViews";
import WhatConvertsLeads from "./WhatConvertsLeads";

class PrintAnalytics extends Component {
  render() {
    return (
      <div className="row leads-row">
        <div className="print-page" style={{ width: "100%" }}>
          <h1>Leads</h1>
          <WhatConvertsLeads
            changeComparison={0}
            compare={0}
            fetchAllSites={true}
            isPrint={true}
          />
        </div>
      </div>
    );
  }
}

export default PrintAnalytics;
