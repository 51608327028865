import React, { Component, Fragment } from "react";
import { fetchCallRailCalls, fetchCallRailCompanies } from "../actions";
import { connect } from "react-redux";
import { Panel } from "common";
import ReactPaginate from "react-paginate";
import { store } from "store";
import { CallRailTable } from "common/CallRailTable";
import CallRailModal from "./CallRailModal";
import ThirdPartyConnect from "screens/sites/components/ThirdPartyConnect";
import ThirdPartyDisconnect from "./ThirdPartyDisconnect";
import { getStartDate, getEndDate } from "../helpers/date_helpers";

class CallRailCalls extends Component {
  state = { selectedItem: null };

  fetchCallRailData = async (page) => {
    store.dispatch({
      type: "SET_CALL_RAIL_LOAD_STATE",
      payload: { loading: true, page },
    });
    await fetchCallRailCalls({
      hash: this.props.hash,
      page: page + 1,
      perPage: "20",
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      callback: () => {
        store.dispatch({
          type: "SET_CALL_RAIL_LOAD_STATE",
          payload: { loading: false },
        });
      },
    });
  };

  componentDidMount() {
    if (this.props.callRailCompanyId) {
      this.fetchCallRailData(0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.callRailCompanyId && !prevProps.callRailCompanyId) {
      this.fetchCallRailData(0);
    }
    const hasDifferentMonth = this.props.date.month !== prevProps.date.month;
    const hasDifferentYear = this.props.date.year !== prevProps.date.year;
    if (hasDifferentMonth || hasDifferentYear) {
      this.fetchCallRailData(0);
    }
  }
  render() {
    if (!this.props.callRailCompanyId) {
      return (
        <Fragment>
          <ThirdPartyConnect
            siteId={this.props.siteId}
            fetchAccounts={fetchCallRailCompanies}
            formatAccounts={(n) => ({ ...n, value: n.id, label: n.name })}
            type="call_rail_company_id"
            displayName="Call Rail Companies"
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Panel containerClass="col-md-12 p-3">
          <Panel.Header
            title="Call Rail Calls"
            color={"#388aed"}
            img={true}
            renderLeft={() => {
              return null;
            }}
          />
          <Panel.Body
            isLoading={this.props.loading}
            style={{
              display: "block",
              borderTop: `2px solid #ffffff`,
              paddingBottom: "1em",
            }}
            render={() => (
              <CallRailTable
                data={this.props.calls}
                setParentState={(data) => {
                  this.setState({ selectedItem: { ...data } });
                }}
              />
            )}
          />
          <Panel.Footer
            render={() => (
              <div className="row p-4">
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel={"next"}
                  pageCount={this.props.totalPages}
                  forcePage={this.props.page - 1}
                  disableInitialCallback
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(page) => {
                    const { selected } = page;
                    this.fetchCallRailData(selected);
                  }}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  breakClassName="page-item"
                  breakLabel={<a className="page-link">...</a>}
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                />
              </div>
            )}
          />
        </Panel>
        <CallRailModal
          data={(this.state.selectedItem || {}).rowData}
          isOpen={this.state.selectedItem ? true : false}
          onRequestClose={() => {
            this.setState({ selectedItem: null });
          }}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ThirdPartyDisconnect
            siteId={this.props.siteId}
            type="call_rail_company_id"
            displayName="Call Rail"
            marginRight="0"
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  date: state.sites.show.date,
  hash: state.sites.show.data.public_hash,
  callRailCompanyId: state.sites.show.data.call_rail_company_id,
  siteId: state.sites.show.data.id,
  calls: ((state.sites.callRailData || {}).calls || []).map((n) => ({
    ...n,
    type: "Phone Call",
  })),
  page: (state.sites.callRailData || {}).page,
  perPage: (state.sites.callRailData || {}).per_page,
  totalPages: (state.sites.callRailData || {}).total_pages,
  totalRecords: (state.sites.callRailData || {}).total_records,
  loading: (state.sites.callRailData || {}).loading,
});

const connected = connect(mapStateToProps)(CallRailCalls);

export { connected as CallRailCalls };
