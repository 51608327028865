import React, { Component } from "react";

class DemoAverageRatings extends Component {
    state = {
        average: 5,
        calculating: false
    }

    render() {
        return (
            <div className="average-rating-reviews-container">
                <h2 className="mb-1">Average Rating</h2>
                <div className="average-circle">
                    {
                        this.state.calculating === false ? < span className="avg-number">{this.state.average}</span> :
                            <span className="avg-number">...</span>
                    }
                </div>
            </div >

        );
    }
}

export { DemoAverageRatings };
