import React from "react";
import { string, shape } from "prop-types";
import { connect } from "react-redux";
import { getMonthFromDate, getYearFromDate } from "lib/dateHelpers";
import { getStartDate } from "screens/sites/helpers/date_helpers";

PrintTitle.propTypes = {
  date: shape({
    month: string,
    year: string
  }),
  live_url: string
};

function PrintTitle(props) {
  const startDate = getStartDate(props.date);
  const month = getMonthFromDate(startDate);
  const year = getYearFromDate(startDate);

  return (
    <h1 className="title">
      {month} {year} Report<br />
      <span>{props.live_url}</span>
    </h1>
  );
}

const mapStateToProps = ({
  sites: { show: { date, data: { live_url } } }
}) => ({ date, live_url });

export default connect(mapStateToProps)(PrintTitle);
