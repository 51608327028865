import React from "react";
import { func, number, object } from "prop-types";
import { connect } from "react-redux";
import { Button } from "common";
import { updateAmazonIntegration } from "screens/sites/actions";

AmazonDisconnect.propTypes = {
  siteId: number,
  amazon_integration: object,
  updateAmazonIntegration: func
};

function AmazonDisconnect(props) {
  if (!props.amazon_integration.merchant_id)
    return null;

  return (
    <Button classes={"btn-disconnect"}
      style={{ float: "right", marginRight: "1em" }}
      onClick={() =>
        props.updateAmazonIntegration({
          siteId: props.siteId,
          merchant_id: null,
          marketplace_id: null,
          auth_token: null
        })
      }
    >
      Disconnect Amazon
    </Button>
  );
}

const mapStateToProps = ({
  sites: {
    show: {
      data: {
        id: siteId,
        amazon_integration
      }
    }
  }
}) => ({
  siteId,
  amazon_integration
});

export default connect(mapStateToProps, { updateAmazonIntegration })(
  AmazonDisconnect
);
