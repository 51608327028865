import React, { Component, Children } from "react";
import { array, node } from "prop-types";

import TemplateDetailHeader from "screens/templates/components/TemplateDetailHeader";
import TemplateDetailForm from "screens/templates/components/TemplateDetailForm";
import TemplateDetailList from "screens/templates/components/TemplateDetailList";

class TemplateDetail extends Component {
  static Header = TemplateDetailHeader;
  static List = TemplateDetailList;
  static Form = TemplateDetailForm;

  static propTypes = {
    children: node,
    data: array
  };

  state = { editMode: false };

  render() {
    if (!this.props.data.length) return null;

    const children = Children.map(
      this.props.children,
      child =>
        React.cloneElement(child, {
          ...this.props
        })
    )

    return (
      <section className="template-detail">
        {children}
      </section>
    );
  }
}

export default TemplateDetail;
