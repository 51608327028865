/* Sites */
export const FETCHING_SITE = "FETCHING_SITE";
export const FETCHING_SITES = "FETCHING_SITES";
export const CREATING_SITE = "CREATING_SITE";
export const UPDATING_SITE = "UPDATING_SITE";

/* Analytics */
export const FETCHING_GA_NEW_VISITORS_BY_STATE =
  "FETCHING_GA_NEW_VISITORS_BY_STATE";
export const FETCHING_GA_SITE_METRICS = "FETCHING_GA_SITE_METRICS";
export const FETCHING_GA_SESSIONS = "FETCHING_GA_SESSIONS";
export const FETCHING_GA_NEW_VISITORS = "FETCHING_GA_NEW_VISITORS";
export const FETCHING_GA_EVENTS = "FETCHING_GA_EVENTS";
export const FETCHING_GA_SOCIAL_SOURCES = "FETCHING_GA_SOCIAL_SOURCES";
export const FETCHING_GA_DEVICES = "FETCHING_GA_DEVICES";
export const FETCHING_GA_TOP_LANDING = "FETCHING_GA_TOP_LANDING";
export const FETCHING_GA_PAGE_VIEWS = "FETCHING_GA_PAGE_VIEWS";

/* Rankings */
export const FETCHING_SE_RANKINGS = "FETCHING_SE_RANKINGS";

/* Leads */
export const FETCHING_LEADS = "FETCHING_LEADS";
export const FETCHING_LEAD_ANALYTICS = "FETCHING_LEAD_ANALYTICS";
export const POSTING_LEAD_DATA = "POSTING_LEAD_DATA";
export const FETCHING_CALL_RAIL_CALLS = "FETCHING_CALL_RAIL_CALLS";
export const FETCHING_CALL_RAIL_COMPANIES = "FETCHING_CALL_RAIL_COMPANIES";

/* Paid Ads  */
export const CLEAR_PAID_ADS_CAMPAIGNS = "CLEAR_PAID_ADS_CAMPAIGNS";
export const FETCHING_FB_AD_CAMPAIGNS = "FETCHING_FB_AD_CAMPAIGNS";
export const FETCHING_ADWORDS_PERFORMANCE = "FETCHING_ADWORDS_PERFORMANCE";
export const FETCHING_ADWORDS_CAMPAIGNS = "FETCHING_ADWORDS_CAMPAIGNS";
export const FETCHING_ADWORDS_PERFORMANCE_DATE_TWO =
  "FETCHING_ADWORDS_PERFORMANCE_DATE_TWO";
export const FETCHING_ADWORDS_AD_SOURCES = "FETCHING_ADWORDS_AD_SOURCES";
export const FETCHING_ADWORDS_MTM = "FETCHING_ADWORDS_MTM";
export const FETCHING_ADWORDS_MATRIX = "FETCHING_ADWORDS_MATRIX";
export const FETCHING_ADWORDS_CAMPAIGNS_STATS_DAILY =
  "FETCHING_ADWORDS_CAMPAIGNS_STATS_DAILY";
export const FETCHING_ADWORDS_ADGROUPS = "FETCHING_ADWORDS_ADGROUPS";
export const FETCHING_FACEBOOK_CAMPAIGNS = "FETCHING_FACEBOOK_CAMPAIGNS";
export const FETCHING_FB_AD_CAMPAIGNS_DATE_TWO =
  "FETCHING_FB_AD_CAMPAIGNS_DATE_TWO";
export const FETCHING_FACEBOOK_CAMPAIGNS_STATS_DAILY =
  "FETCHING_FACEBOOK_CAMPAIGNS_STATS_DAILY";
export const FETCHING_FACEBOOK_CAMPAIGNS_STATS_SECOND_DATE_DAILY =
  "FETCHING_FACEBOOK_CAMPAIGNS_STATS_DAILY_SECOND_DATE";
export const FETCHING_ADWORDS_CAMPAIGNS_STATS_SECOND_DATE_DAILY =
  "FETCHING_ADWORDS_CAMPAIGNS_STATS_DAILY_SECOND_DATE";
export const FETCHING_FB_ADSETS = "FETCHING_FB_ADSETS";
export const FETCHING_FB_ADSETS_AD_PREVIEW = "FETCHING_FB_ADSETS_AD_PREVIEW";
export const FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK =
  "FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK";
export const FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE =
  "FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE";
export const FETCHING_ADWORDS_MCC_ACCOUNTS = "FETCHING_ADWORDS_MCC_ACCOUNTS";
export const FETCHING_FB_PERFORMANCE_ACCT_LEVEL =
  "FETCHING_FB_PERFORMANCE_ACCT_LEVEL";
export const FETCHING_ADWORDS_PERFORMANCE_ACCT_LEVEL =
  "FETCHING_ADWORDS_PERFORMANCE_ACCT_LEVEL";
export const CLEAR_AD_PREVIEW_STATE = "CLEAR_AD_PREVIEW_STATE";
export const FETCHING_ADWORDS_PERFORMANCE_MONTH_VS_MONTH =
  "FETCHING_ADWORDS_PERFORMANCE_MONTH_VS_MONTH";

export const FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE_DATE_TWO =
  "FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE_DATE_TWO";
export const FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK_DATE_TWO =
  "FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK_DATE_TWO";

/* ECommerce */
export const FETCHING_SHOPIFY_SALES = "FETCHING_SHOPIFY_SALES";
export const UPDATE_AMAZON_INTEGRATION = "UPDATE_AMAZON_INTEGRATION";
export const FETCH_AMAZON_REPORTS = "FETCH_AMAZON_REPORTS";

/* TWITTER */
export const SAVE_TWITTER_HANDLE = "SAVE_TWITTER_HANDLE";

// Instagram
export const FETCHING_SOCIAL_INSTAGRAM_BUSINESS_ID =
  "FETCHING_SOCIAL_INSTAGRAM_BUSINESS_ID";

//Reviews
export const FETCHING_FACEBOOK_REVIEW = "FETCHING_FACEBOOK_REVIEW";
export const FETCHING_YELP_REVIEW = "FETCHING_YELP_REVIEW";
export const FETCHING_GOOGLE_REVIEW = "FETCHING_GOOGLE_REVIEW";
export const SAVE_YELP_BUSINESS_ID = "SAVE_YELP_BUSINESS_ID";
export const SAVE_GOOGLE_PLACES_ID = "SAVE_GOOGLE_PLACES_ID";
export const UPDATE_YELP_BUSINESS = "UPDATE_YELP_BUSINESS";
export const FETCH_REVIEW_IDS = "FETCH_REVIEW_IDS";
export const FETCHING_YELP_BUSINESS_OPTIONS_REVIEW =
  "FETCHING_YELP_BUSINESS_OPTIONS_REVIEW";
export const UPDATE_REVIEWS_DISCONNECTED_YELP =
  "UPDATE_REVIEWS_DISCONNECTED_YELP";
export const UPDATE_REVIEWS_DISCONNECTED_GOOGLE =
  "UPDATE_REVIEWS_DISCONNECTED_GOOGLE";

/*
 * Email Types
 * */

export const FETCHING_KLAVIYO_ACCOUNT = "FETCHING_KLAVIYO_ACCOUNT";
export const FETCHING_KLAVIYO_CAMPAIGNS = "FETCHING_KLAVIYO_CAMPAIGNS";
export const FETCHING_MAILCHIMP_ACCOUNT = "FETCHING_MAILCHIMP_ACCOUNT";
export const FETCHING_MAILCHIMP_CAMPAIGNS = "FETCHING_MAILCHIMP_CAMPAIGNS";
