import React, { Component, Fragment } from "react";
import facebookLogo from "images/icon_facebook.png";

import { DemoStatsBarContainer } from './DemoStatsBarContainer';
import { DemoEngagement } from './DemoEngagement';
import { DemoLikesPaidOrganic } from './DemoLikesPaidOrganic';
import { DemoVideoViews } from "./DemoVideoViews";

import { DemoLikesSources } from "./DemoLikesSources";
import { DemoImpressions } from "./DemoImpressions";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { DemoAudienceDemographics } from "./DemoAudienceDemographics";
import { DemoLocations } from "./DemoLocations";

class DemoFacebook extends Component {

    render() {
        return (
            <Fragment>
                <ReportSubTitle text="Facebook Overview" classes="mt-5" logo={facebookLogo} />
                <DemoStatsBarContainer name={"Facebook"} />
                <DemoEngagement logo={facebookLogo} />
                <ReportSubTitle text="Growth" classes="mt-3" logo={facebookLogo} />
                <div className="row">
                    <DemoLikesPaidOrganic />
                    <DemoImpressions text={"Page Impressions 3 Months"} />
                    <DemoVideoViews />
                    <DemoLikesSources />
                </div>
                <DemoAudienceDemographics logo={facebookLogo} />
                <DemoLocations logo={facebookLogo} />
            </Fragment>
        )

    }
}


export { DemoFacebook };
