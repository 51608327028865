import React, { Component } from 'react';
import { connect } from "react-redux";
import { Panel } from "common";

import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { fetchAdwordsMatrix } from "screens/sites/actions";
import { shadeColor } from "lib/utilities";

class AdwordsMatrix extends Component {

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };

  mounted = true;

  componentDidMount() {
    if (!this.props.adwords_id) return;
    const params = {
      adwords_id: this.props.adwords_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId = this.props.adwords_id !== nextProps.adwords_id;

    if (!this.props.adwords_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }

    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.props.clearCacheBool === true && this.props.fetchAdwordsMatrix(
        params,
        () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchAdwordsMatrix(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {

    if (!this.props.adwords_id) return null;

    let hours = [];
    while (hours.length < 24) {
      hours.push(<span key={hours.length}>{hours.length + 1}</span>)
    }
    return (
      <Panel
        containerClass="col-md-12 p-3 float-left"
        isLoading={this.state.isFetching}
        padding={2}>
        <Panel.Subtitle text="Conversions By Hour" />
        <Panel.Body
          render={() => {
            return (
              <div className="adwords-matrix col-12">
                <div className="matrix-heading">{hours}</div>
                {
                  this.props.matrix.map((el, i) => {
                    return (
                      <div key={i} className="matrix-item clearfix">
                        <span className="matrix-label">{el.day}</span>
                        {el.hours.map((el, i) => {
                          return <div key={i} className="matrix-block"><span className={(parseFloat(el, 10) === 0 ? "zero" : "")} style={{ background: shadeColor('#91bdf5', -(parseFloat(el, 10) * 2)) }}><i>{el} Conversions</i></span></div>
                        })}
                      </div>
                    )
                  })
                }
              </div>
            )
          }}
        />
      </Panel>
    )
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { adwords_id },
      paidAdsData: {
        matrix
      }
    }
  }
}) => ({
  date,
  adwords_id,
  matrix
});

export default connect(mapStateToProps, { fetchAdwordsMatrix })(
  AdwordsMatrix
);