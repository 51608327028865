import React, { Component } from "react";
import { bool, func, object } from "prop-types";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import moment from "moment";

import { Button } from "common";
import ProfileCircle from "screens/todos/components/ProfileCircle";

class CommentItem extends Component {
  static propTypes = {
    comment: object,
    handleDelete: func,
    isOwner: bool
  };

  render() {
    const { content, user, created_at } = this.props.comment;
    const { blocks } = JSON.parse(content);
    const editorState = EditorState.createWithContent(
      convertFromRaw({ blocks, entityMap: {} })
    );
    return (
      <section className="todo-comments-item">
        <section className="todo-comments-item__left">
          <div className="todo-comments-item-profile">
            <ProfileCircle
              profileImage={user.profile_image}
              firstName={user.first_name}
              lastName={user.last_name}
            />
          </div>
          <div className="todo-comments-item-text">
            <h4 className="todo-comments-item-text__head">{user.first_name}</h4>
            <span className="todo-comments-item-text__date">
              {moment(created_at, "YYYY-MM-DD hh:mm:ss").format(
                "MMM Do YYYY @  h:mm a"
              )}
            </span>
            <Editor onChange={() => {}} editorState={editorState} readOnly />
          </div>
        </section>

        <section>
          {this.props.isOwner && (
            <Button onClick={this.props.handleDelete} inverse>
              <i className="fas fa-trash" />
            </Button>
          )}
        </section>
      </section>
    );
  }
}

export default CommentItem;
