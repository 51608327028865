import React, { Component } from "react";
import Modal from "react-modal";
import { Button } from "common";

export default class Ticket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    let labels = this.props.labels.map((el, i) => {
      return (
        <span className="badge badge-pill p-2 m-1 text-white" style={{ background: el.color }} key={i}>
          {el.name}
        </span>
      );
    });

    return (
      <div className="sb-ticket">
        <Button onClick={this.openModal} inverse>
          <i className="fas fa-eye" />
        </Button>
        <h3 className="ticket-title">
          {this.props.subject}{" "}
          <span className="ticket-requester">{this.props.requester.email}</span>
        </h3>
        <Modal
          appElement={document.getElementById('root')}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Modal"
          className="react-modal"
          overlayClassName="react-modal-overlay">
          <h2 ref={subtitle => (this.subtitle = subtitle)}>
            {this.props.subject}
          </h2>
          <div className="sb-labels mb-3">{labels}</div>
          <a className="btn react-modal-close" onClick={this.closeModal}>
            <span className="fas fa-times" />
          </a>
          <div dangerouslySetInnerHTML={{ __html: this.props.content.html }} />
        </Modal>

        <div className="sb-labels">{labels}</div>
        <div className="ticket-content" />
      </div>
    );
  }
}
