import React, { Component } from 'react'
import { handleInputChange } from "lib/utilities";
import _ from "lodash";

const DropDownItem = (props) => {
    let formattedData = props.data.replace('_', ' ');
    return (
        <span onClick={() => props.selectOption(formattedData)} className="dropdown-selection">
            {props.data}
        </span>
    )
}

const DropdownContent = (props) => {
    let dropDownItems = props.data.map((item, i) => {
        let formattedData = item.replace('_', ' ');
        return props.renderContent(formattedData, i);
    });
    return (
        <div className="dropdown-list">
            {dropDownItems}
        </div>
    )
}
{/* <input onChange={(e) => handleInputChange(props.context, e, 'inputValue')} value={props.loading ? loader : props.state.inputValue} onClick={() => { props.onDropDownItemClick() }} onMouseOver={(e) => props.highLight(e, props.id)} className={props.state.highlightId === props.id ? 'highlight dropdown-selection' : 'dropdown-selection'} key={props.id} /> */ }
class IframeDropdown extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    state = {
        selected: '',
        highlightId: '',
        dropdownOptionsVisible: false,
        inputValue: '',
        searchedValues: [],
    }

    showHideDropdown = (e, fromUserInput) => {
        !fromUserInput && this.setState({ dropdownOptionsVisible: !this.state.dropdownOptionsVisible })
    }

    highLight = (e, id) => {
        if (this.state.highlightId !== id) {
            this.setState({ highlightId: id })
        }
    }

    onDropDownItemClick = (e, id) => {
        this.setState({ selected: id })
    }


    renderDropDownContent = (data, state, loading) => {
        let dataForView = data;
        return (
            this.state.dropdownOptionsVisible ?
                <DropdownContent
                    count={null}
                    data={dataForView}
                    renderContent={(props, i) =>
                        <DropDownItem
                            key={Math.random()}
                            id={i}
                            loading={loading}
                            nameToDisplay={data.name}
                            onDropDownItemClick={this.onDropDownItemClick}
                            data={props}
                            highLight={this.highLight}
                            selectOption={this.selectOption}
                        />
                    }
                />
                : null
        )
    }

    selectOption = (option) => {
        this.setState({ selected: option, inputValue: option }, () => {
            // Call Data Based on Format
            this.props.liftSelection(this.state.selected);
        })
    }

    formatText = () => {

    }

    render() {
        let data = this.props.data;
        return (
            <div className="dropdown-container-tresio" onClick={(e) => this.showHideDropdown(e, false)}>
                <div className="selected">
                    <input placeholder={this.props.placeholder} onChange={(e) => { handleInputChange(this, e, 'inputValue'); this.showHideDropdown(e, true); }} value={this.props.loading ? "...Loading" : this.state.inputValue} />
                    <span>
                        <i className="fa fa-angle-down"></i>
                    </span>
                </div>
                {this.renderDropDownContent(data, this.state, this.props.loading)}
            </div>
        )
    }
}


export { IframeDropdown }