import _ from "lodash";
import axios from "axios";
import dateFormat from "dateformat";
import qs from "qs";
import { rootUrl } from "lib/api";
import { requestHeaders, getToken } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import {
  generateLeadEndpoint,
  startAndEndDate,
} from "screens/sites/helpers/endpoint_helpers";

import {
  FETCHING_LEADS,
  POSTING_LEAD_DATA,
  FETCHING_LEAD_ANALYTICS,
  FETCHING_CALL_RAIL_CALLS,
} from "./types";
import { useCallRail } from "lib/api";

const requestParams = (clear) => {
  let finalParameters;
  if (clear === true) {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: true,
      },
    };
  } else {
    finalParameters = {
      params: {
        checkRedis: true,
        clearCache: false,
      },
    };
  }
  return finalParameters;
};

export const fetchCallRailCalls = async ({
  hash,
  page,
  perPage,
  startDate,
  endDate,
  callback,
}) => {
  const dispatching = dispatchHelper(FETCHING_CALL_RAIL_CALLS);
  const token = getToken();
  const url = `${rootUrl}/leads/callrail/${hash}/${page}?per_page=${perPage}&startDate=${startDate}&endDate=${endDate}`;
  const headers = requestHeaders(token);

  try {
    let response = await axios.get(url, headers);
    dispatching(response);
    callback();
  } catch (err) {
    console.log("Error receiving call rail data");
  }
};

export const fetchCallRailCompanies = async (callback) => {
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/callrail/companies`;
  const data = await axios.get(url, headers);
  callback((data || {}).data);
};

export const fetchLeads = (
  params,
  callback,
  clearCacheBool,
  callRailCompanyId
) => async (dispatch) => {
  if (useCallRail && callRailCompanyId) {
    params.type = "web_form";
  }
  dispatch({
    type: FETCHING_LEADS,
    payload: params.type,
  });
  const url = generateLeadEndpoint(params);
  const token = getToken();
  const headers = params.isPublic ? {} : requestHeaders(token);

  let finalParameters = requestParams(clearCacheBool);

  let response;
  try {
    response = await axios.get(
      url,
      Object.assign({}, finalParameters, headers)
    );
    response.data = {
      ...response.data,
      type: params.type,
    };

    response.data.formattedLeads = response.data.leads
      .sort((a, b) => {
        let aDate = new Date(a.date_created);
        let bDate = new Date(b.date_created);
        return bDate - aDate;
      })
      .map((lead) => {
        return {
          id: { value: lead.lead_id },
          "lead type": { value: lead.lead_type, meta: lead.lead_meta },
          source: { value: lead.lead_source },
          "phone name": { value: lead.phone_name },
          "tracking number": { value: lead.tracking_number },
          medium: { value: lead.lead_medium },
          date: { value: dateFormat(lead.date_created, "mmm dS h:MMtt") },
        };
      });

    response.data.leadsById = _.mapKeys(response.data.leads, "lead_id");

    dispatch({ type: "FETCHING_LEADS_SUCCESS", payload: response.data });
    callback();
  } catch (e) {
    dispatch({ type: "FETCHING_LEADS_FAIL", payload: "FETCHING_LEADS_FAIL" });
  }
};

export const fetchLeadAnalytics = (
  params,
  callback = () => {},
  clearCacheBool
) => async () => {
  let updatedParams = { ...params };
  const dispatching = dispatchHelper(FETCHING_LEAD_ANALYTICS);
  const token = getToken();
  let url = startAndEndDate(updatedParams, "/lead-analytics/");
  const headers = updatedParams.isPublic ? {} : requestHeaders(token);
  let query = qs.stringify({
    compare: updatedParams.compare,
    mergeChildSites: params.mergeChildSites,
  });
  let finalParameters = requestParams(clearCacheBool);
  let response;
  try {
    response = await axios.get(
      url + "&" + query,
      Object.assign({}, finalParameters, headers)
    );
  } catch (e) {
    response = { status: 500, body: "" };
  }
  console.log("response is", response);
  dispatching(response, callback);
};

export const postLeadData = (params, callback) => async () => {
  const dispatching = dispatchHelper(POSTING_LEAD_DATA);
  const url = `${rootUrl}/leadmeta`;
  const token = getToken();
  const headers = requestHeaders(token);

  let response = await axios.post(url, params, headers);
  dispatching(response, callback);
};
