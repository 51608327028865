import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import GoogleAdwordsOverview from "screens/sites/components/GoogleAdwordsOverview";
import GoogleAdwordsCampaigns from "screens/sites/components/GoogleAdwordsCampaigns";
import GoogleAdwordsMonthlyConversions from "screens/sites/components/GoogleAdwordsMonthlyConversions";
import GoogleAdwordsConversionSources from "screens/sites/components/GoogleAdwordsConversionSources";
import AdwordsMTM from "screens/sites/components/AdwordsMTM";
import AdwordsMatrix from "screens/sites/components/AdwordsMatrix";

import { calculateCampaignSales } from "../helpers/adwords_helpers";
import { ErrorBoundary } from "common";
export default class AdwordsAccountOverview extends Component {
  // static propTypes = {

  // }

  state={
    isEcommerce: false,
  }

  liftDetermineIfEcommerce = (data) => {
    if(data === true){
      this.setState({isEcommerce: true});
    }
  }
  
  render() {
    return (
      <div>
        <ErrorBoundary>
          <GoogleAdwordsOverview clearCacheBool={this.props.clearCacheBool} />
        </ErrorBoundary>
        {this.props.isComparing ? null : (
          <Fragment>
            <AdwordsMTM liftDetermineIfEcommerce={this.liftDetermineIfEcommerce} clearCacheBool={this.props.clearCacheBool} />
            <GoogleAdwordsMonthlyConversions
              isEcommerce={this.state.isEcommerce}
              clearCacheBool={this.props.clearCacheBool}
            />
            <GoogleAdwordsConversionSources
              data = { calculateCampaignSales(this.props.data) }
              isEcommerce={this.state.isEcommerce}
              clearCacheBool={this.props.clearCacheBool}
            />
            <AdwordsMatrix clearCacheBool={this.props.clearCacheBool} />
          </Fragment>
        )}
        <GoogleAdwordsCampaigns  isEcommerce={this.state.isEcommerce} clearCacheBool={this.props.clearCacheBool} />
      </div>
    );
  }
}
