import React, { Component } from "react";
import { StatisticsBar } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/mailchimp.png";
import _ from "lodash";
export default class MailchimpAccountOverview extends Component {
  formatData = () => {
    let clone = _.cloneDeep(this.props.data);
    _.forOwn(clone, (value, key) => {
      value.title = value.title.replace("Over", "/");
    });
    return clone;
  };
  render() {
    let formattedData = this.formatData();
    return (
      <StatisticsBar
        renderSubtitle={() => (
          <ReportSubTitle
            text="Mailchimp Overview"
            classes="pt-3 clearfix"
            logo={logo}
          />
        )}
        isLoading={this.props.isLoading}
        isPublic={false}
        data={formattedData}
      />
    );
  }
}
