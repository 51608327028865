import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as actions from "screens/clients/actions";
import ProfileImage from "screens/clients/components/ProfileImage";
import { Card, Button } from "common";

class ProfileContainer extends Component {
  handleUpdateImage = formData => {
    formData.append("id", this.props.clients.show.data.id);
    this.props.updateClientImage(formData);
  };

  render() {
    const { show } = this.props.clients;
    return (
      <Card
        hideHeader
        classes="profile-container mb-4"
        padding={5}
      >
        <ProfileImage
          clientImage={show.data.profile_image}
          userImage={this.props.userImage}
          handleUpdateImage={this.handleUpdateImage}
        />
        <h3 className="title mb-2">
          {`${show.data.name} `}
          <span className="category">{this.props.clients.show.data.category.name}</span>
        </h3>
        <section className="w-100 text-center">
          <Button link to={`/clients/${show.data.id}/edit`}>
            <i className="fas fa-edit" /> Edit Client
            </Button>
        </section>
      </Card>
    );
  }
}

ProfileContainer.propTypes = {
  clients: PropTypes.object,
  updateClientImage: PropTypes.func
};

const mapStateToProps = ({ categories, clients }) => ({ categories, clients });

const connected = connect(mapStateToProps, actions)(ProfileContainer);

export { connected as ProfileContainer };
