import React from "react";
import { node, string, object } from "prop-types";

import withLoader from "hoc/withLoader";
import withSuccess from "hoc/withSuccess";
import withErrors from "hoc/withErrors";

View.propTypes = {
  children: node,
  classes: string,
  customStyle: object
};

function View({ children, classes, customStyle }) {
  return (
    <div className={`row ${classes ? classes : ""}`} style={customStyle}>
      {children}
    </div>
  );
}

const ViewWithLoader = withLoader(withSuccess(withErrors(View)));

export { ViewWithLoader };
