import React, { Component, Children } from "react";
import { node, number, string } from "prop-types";
import PanelHeader from "./PanelHeader";
import PanelBody from "./PanelBody";
import PanelSubtitle from "./PanelSubtitle";
import PanelFooter from "./PanelFooter";

class Panel extends Component {
  static Header = PanelHeader;
  static Body = PanelBody;
  static Subtitle = PanelSubtitle;
  static Footer = PanelFooter;

  static propTypes = {
    children: node,
    padding: number,
    containerClass: string,
  };

  render() {
    const children = Children.map(this.props.children, (child) => {
      return React.cloneElement(child, { ...this.props });
    });
    return (
      <div className={this.props.containerClass}>
        <div
          className={`panel ${
            this.props.panelClass ? this.props.panelClass : ""
          }`}
          style={{ padding: this.props.padding + "em", position: "relative" }}
        >
          {children}
        </div>
      </div>
    );
  }
}

export { Panel };
