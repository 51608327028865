import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { GenericBarChart } from "common";

import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { fetchAdwordsMTM } from "screens/sites/actions";

class AdwordsMTM extends Component {

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };

  mounted = true;

  componentDidMount() {
    if (!this.props.adwords_id) return;
    const params = {
      adwords_id: this.props.adwords_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId = this.props.adwords_id !== nextProps.adwords_id;

    if (!this.props.adwords_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.props.clearCacheBool === true && this.props.fetchAdwordsMTM(
        params,
        () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchAdwordsMTM(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  determineIfEcomm = () => {
    let isEcomm = false;
    let count = 0;
    if(this.props.Total_conv_value){
      if(this.props.Total_conv_value.length > 0){
        this.props.Total_conv_value.forEach(item => {
            if(item.value !== 0){
              count += 1;
            }
        });
      }
    }
    if(count === 3){
      isEcomm = true;
    }
    return isEcomm;
  }

  render() {
    // Temp PDF fix
    let isEcomm = false;
    let isPrintScreen = window.location.href.includes("print")
    if(isPrintScreen === false){
      isEcomm  = this.determineIfEcomm();
      this.props.liftDetermineIfEcommerce(isEcomm);
    }
    if (!this.props.adwords_id) return null;
    if(isEcomm){
      return(
        <Fragment>
        <GenericBarChart gridClass="col-md-4" isCurrency={false} title="Sales" printView={this.state.showPrintView} isLoading={this.state.isFetching} data={this.props.conversions} />
        <GenericBarChart gridClass="col-md-4" isCurrency={true} title="Revenue" printView={this.state.showPrintView} isLoading={this.state.isFetching} data={this.props.Total_conv_value} />
        <GenericBarChart gridClass="col-md-4" isCurrency={true} title="Monthly Cost" printView={this.state.showPrintView} isLoading={this.state.isFetching} data={this.props.cost} />
      </Fragment>
      )
    } else {
      return (
        <Fragment>
          <GenericBarChart gridClass="col-md-4" isCurrency={false} title="Monthly Conversions" printView={this.state.showPrintView} isLoading={this.state.isFetching} data={this.props.conversions} />
          <GenericBarChart gridClass="col-md-4" isCurrency={true} title="Monthly Cost/Conversion" printView={this.state.showPrintView} isLoading={this.state.isFetching} data={this.props.cost_conversion} />
          <GenericBarChart gridClass="col-md-4" isCurrency={true} title="Monthly Cost" printView={this.state.showPrintView} isLoading={this.state.isFetching} data={this.props.cost} />
        </Fragment>
      )
    }
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { adwords_id },
      paidAdsData: {
        conversions,
        cost_conversion,
        cost,
        Total_conv_value,
      }
    }
  }
}) => ({
  date,
  adwords_id,
  conversions,
  cost_conversion,
  cost,
  Total_conv_value
});

export default connect(mapStateToProps, { fetchAdwordsMTM })(
  AdwordsMTM
);