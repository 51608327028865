import axios from "axios";
import { rootUrl } from "lib/api";
import { dispatchHelper } from "lib/actionHelpers";
import { FETCHING_SOCIAL_DATA_CLICKS } from "../types/types";
import { FETCHING_SOCIAL_DATA_ENGAGEMENT } from "../types/types";
import { FETCHING_SOCIAL_DATA_GROWTH } from "../types/types";
import { FETCHING_SOCIAL_DATA_VIDEO } from "../types/types";
import { FETCHING_SOCIAL_DATA_AGE } from "../types/types";
import { FETCHING_SOCIAL_DATA_LOCATION } from "../types/types";
import { FETCHING_SOCIAL_DATA_IMPRESSIONS } from "../types/types";
import { FETCHING_SOCIAL_DATA_LIKESANDPAGEVIEWS } from "../types/types";
import { FETCHING_SOCIAL_DATA_FANS } from "../types/types";
import { FETCHING_SOCIAL_SITES } from "../types/types";
import { FETCHING_SOCIAL_TWITTER } from "../types/types";
import { FETCHING_SOCIAL_INSTAGRAM_POST_DATA } from "../types/types";
import { FETCHING_SOCIAL_INSTAGRAM_DEMOGRAPHIC_DATA } from "../types/types";
import { FETCHING_FOLLOWERS_COUNT } from "../types/types";
import { requestHeaders, getToken } from "lib/authHelpers";
import { getInstagramStatsEndpoint } from "lib/api";
import { store } from "store";

const types = [
  FETCHING_SOCIAL_DATA_CLICKS,
  FETCHING_SOCIAL_DATA_ENGAGEMENT,
  FETCHING_SOCIAL_DATA_GROWTH,
  FETCHING_SOCIAL_DATA_VIDEO,
  FETCHING_SOCIAL_DATA_AGE,
  FETCHING_SOCIAL_DATA_LOCATION,
  FETCHING_SOCIAL_DATA_IMPRESSIONS,
  FETCHING_SOCIAL_DATA_LIKESANDPAGEVIEWS,
  FETCHING_SOCIAL_DATA_FANS,
  FETCHING_SOCIAL_SITES,
];
export const fetchSocialDataInsights = (
  params,
  startDate,
  endDate,
  public_hash,
  clearCacheBool
) => async () => {
  let dispatching;
  const token = getToken();
  const uppercase = params.toUpperCase();
  const headers = requestHeaders(token);
  types.forEach((type, index) => {
    const matchCategory = type.substr(type.lastIndexOf("_") + 1);
    if (uppercase === matchCategory) {
      dispatching = dispatchHelper(type);
    }
  });
  let parameters = {
    params: {
      category: params,
      startDate: startDate,
      endDate: endDate,
      public_hash: public_hash,
      checkRedis: true,
      clearCache: clearCacheBool,
    },
  };
  if (startDate === "noDate") {
    parameters = {
      params: {
        category: params,
        startDate: startDate,
        endDate: endDate,
        public_hash: public_hash,
        clearCache: clearCacheBool,
      },
    };
  }
  const url = `${rootUrl}/public/fb/insights/${public_hash}`;
  try {
    let response = await axios.get(url, Object.assign({}, parameters, headers));
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};

export const fetchSocialSites = () => async () => {
  const dispatching = dispatchHelper(FETCHING_SOCIAL_SITES);
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/fb/pages`;
  try {
    const response = await axios.get(url, headers);
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};

export const fetchTwitterData = (params) => async () => {
  const dispatching = dispatchHelper(FETCHING_SOCIAL_TWITTER);
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/public/twitter/account`;
  const parameters = {
    params: {
      username: params,
    },
  };
  try {
    let response = await axios.get(url, Object.assign({}, parameters, headers));
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};

export const fetchInstagramDataInsights = (
  category,
  params,
  public_hash,
  clearCacheBool
) => async () => {
  let checkForRedisCache = true;
  let parameters = {
    params: {
      category: category,
      checkRedis: checkForRedisCache,
      clearCache: clearCacheBool,
    },
  };

  let dispatching;
  let url;
  const instagramPostsUrl = `${getInstagramStatsEndpoint()}/v2/public/instagram/posts/${public_hash}?startDate=${
    params.startOfMonth
  }&endDate=${params.endOfMonth}&limit=6&sortBy=engagement`;
  const instagramInsightsCurrentMonthSelectionUrl = `${getInstagramStatsEndpoint()}/v2/public/instagram/stats/${public_hash}?monthDate=${
    params.startOfMonth
  }`;
  const instagramInsightsOneMonthsPriorToCurrentMonthUrl = `${getInstagramStatsEndpoint()}/v2/public/instagram/stats/${public_hash}?monthDate=${
    params.startOfMonth
  }`;
  const instagramInsightsTwoMonthsPriorToCurrentMonthUrl = `${getInstagramStatsEndpoint()}/v2/public/instagram/stats/${public_hash}?monthDate=${
    params.startOfMonth
  }`;
  const instagramDemographicsUrl = `${rootUrl}/public/instagram/insights/${public_hash}`;
  const urlMap = {
    posts: instagramPostsUrl,
    instagramInsightsCurrentMonthSelection: instagramInsightsCurrentMonthSelectionUrl,
    instagramOneMonthsPriorFromCurrentMonthSelection: instagramInsightsOneMonthsPriorToCurrentMonthUrl,
    instagramTwoMonthsPriorFromCurrentMonthSelection: instagramInsightsTwoMonthsPriorToCurrentMonthUrl,
    demographics: instagramDemographicsUrl,
  };

  url = urlMap[category];
  category === "instagramInsightsCurrentMonthSelection" &&
    (dispatching = dispatchHelper(
      "FETCHING_INSTAGRAM_INSIGHTS_CURRENTLY_SELECTED_MONTH"
    ));
  category === "instagramOneMonthsPriorFromCurrentMonthSelection" &&
    (dispatching = dispatchHelper(
      "FETCHING_INSTAGRAM_INSIGHTS_TWO_MONTHS_FROM_DATE_SELECTION"
    ));
  category === "instagramTwoMonthsPriorFromCurrentMonthSelection" &&
    (dispatching = dispatchHelper(
      "FETCHING_INSTAGRAM_INSIGHTS_THREE_MONTHS_FROM_DATE_SELECTION"
    ));
  category === "demographics" &&
    (dispatching = dispatchHelper(FETCHING_SOCIAL_INSTAGRAM_DEMOGRAPHIC_DATA));
  category === "posts" &&
    (dispatching = dispatchHelper(FETCHING_SOCIAL_INSTAGRAM_POST_DATA));

  const token = getToken();
  const headers = requestHeaders(token);
  if (category) {
    store.dispatch({
      type: `SET_INSTAGRAM_${(category || "").toUpperCase()}_LOAD_STATE`,
      payload: true,
    });
  }
  try {
    let response = await axios.get(url, Object.assign({}, parameters, headers));
    dispatching(response, () => {
      store.dispatch({
        type: `SET_INSTAGRAM_${(category || "").toUpperCase()}_LOAD_STATE`,
        payload: false,
      });
    });
  } catch (err) {
    store.dispatch({
      type: `SET_INSTAGRAM_${(category || "").toUpperCase()}_LOAD_STATE`,
      payload: false,
    });
    console.log(err);
  }
};

export const fetchFollowersCount = (public_hash) => async () => {
  const dispatching = dispatchHelper(FETCHING_FOLLOWERS_COUNT);
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/public/instagram/follow_count/${public_hash}`;
  const parameters = {
    params: {
      null: null,
    },
  };
  try {
    let response = await axios.get(url, Object.assign({}, parameters, headers));
    dispatching(response, () => {});
  } catch (err) {
    console.log(err);
  }
};
