import React, { Component, Fragment } from "react";
import { Card, GenericPieGraph, Panel, Toggle } from "common";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList
} from "recharts";
import { isAlliance } from "../../../lib/api";
export default class LeadAnalytics extends Component {
  render() {
    return (
      <div className="row">
        <Panel
          containerClass="col-12 col-md-6 p-3 float-left"
          isLoading={this.props.isFetching}
          padding={2}
        >
          <Panel.Subtitle text={`Inquiries By Month`} />
          <Panel.Body
            render={() => {
              return (
                <Fragment>
                  {!this.props.isPublic && (
                    <div key={1} className="lead-compare-filter">
                      <i className="fa fa-calendar-alt"></i>
                      <ul>
                        <li>
                          <a
                            onClick={e => this.props.handleCompareChange(e, 3)}
                          >
                            3 Months
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={e => this.props.handleCompareChange(e, 6)}
                          >
                            6 Months
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={e => this.props.handleCompareChange(e, 12)}
                          >
                            12 Months
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                  <ResponsiveContainer key={2} height={230}>
                    <BarChart
                      width={600}
                      height={300}
                      data={this.props.leadAnalytics.history}
                      margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                    >
                      {isAlliance ? (
                        <YAxis tickLine={false} dataKey="value" />
                      ) : null}
                      <XAxis tickLine={false} dataKey="name" />
                      <Tooltip />
                      <Bar
                        maxBarSize={20}
                        className={"yoy-bar"}
                        dataKey="value"
                        fill="#619bf9"
                      >
                        {this.props.isPrint ? (
                          <LabelList
                            dataKey="value"
                            position="insideBottom"
                            style={{ fill: "white" }}
                          />
                        ) : null}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Fragment>
              );
            }}
          />
        </Panel>
        <GenericPieGraph
          title="Monthly Inquiries by Medium"
          isLoading={this.props.isFetching}
          data={this.props.leadAnalytics.mediums}
        />
        <Panel
          containerClass="col-12 col-md-6 p-3 float-left lead-referrers-wrap"
          isLoading={this.props.isFetching}
          padding={2}
        >
          <Panel.Subtitle text="Monthly Top Referrers" />
          <Panel.Body
            render={() => {
              return (
                <div className="col-12">
                  {this.props.leadAnalytics.sources.map((el, i) => {
                    if (i > 5) return false;
                    return (
                      <div key={i} className="referrer-item">
                        <p>
                          {el.name} <span>{el.value}</span>
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
        </Panel>
        <GenericPieGraph
          title="Monthly Inquiries by Type"
          isLoading={this.props.isFetching}
          data={this.props.leadAnalytics.types}
        />
      </div>
    );
  }
}
