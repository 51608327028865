import React, {Component} from 'react';
import { StatisticsBar } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/logo-klaviyo.png";

export default class KlaviyoAccountOverview extends Component {
  render() {
    return (
      <StatisticsBar
        renderSubtitle={() => (
          <ReportSubTitle
            text="Klaviyo Overview"
            classes="pt-3 clearfix"
            logo={logo}
          />
        )}
        isLoading={this.props.isLoading}
        isPublic={false}
        data={this.props.data}

      />
    )
  }
}