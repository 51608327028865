import React, { Component, Fragment } from "react";
import { func, number, string, object } from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

import { Button, Panel } from "common";
import { updateSite } from "screens/sites/actions";

class AdwordsConnect extends Component {
  static propTypes = {
    updateSite: func.isRequired,
    fetchAccounts: func.isRequired,
    formatAccounts: func.isRequired,
    siteId: number,
    type: string.isRequired,
    displayName: string.isRequired,
    siteData: object
  }

  state = { accounts: [], selectedAccount: "", accountId: "", selectedMCCAccount: "" }
  mounted = true;

  async componentDidMount() {
    if (this.state.accounts.length) return;
    if (this.props.siteData[this.props.type]) return;
    this.props.fetchAccounts(accounts => {
      this.mounted && this.setState({ accounts })
    })
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleAccountChange = (selectedAccount, type) => {
    selectedAccount = selectedAccount === null ? { value: "" } : selectedAccount;

    this.setState({
      [type]: selectedAccount.id,
      selectedAccount
    })
  }

  render() {
    if (!this.props.siteId) return null;
    if (this.props.siteData[this.props.type]) return null;
    let isMCC = null;
    let propertyOptions = this.state.accounts.map(this.props.formatAccounts);
    if (this.state.selectedAccount) {
      if (this.state.selectedAccount.mcc === true) {
        isMCC = this.state.selectedAccount.id;
      }
    }
    if (this.props.type === "adwords_id") {
      if (this.props.siteData.adwords_mcc_id || this.props.siteData.adwords_id)
        return null;
    }
    return (
      <Panel containerClass="col-md-12 p-3">
        <Panel.Header title={`Connect ${this.props.displayName}`} />
        <Panel.Body
          className="account-connect-body"
          render={() => (
            <Fragment>
              <Select
                name="form-field-name"
                placeholder="Select Account"
                value={this.state.selectedAccount}
                onChange={item => this.handleAccountChange(item, "accountId")}
                options={propertyOptions}
              />
              <Button
                onClick={() => {
                  this.props.updateSite(this.props.siteId, {
                    [this.props.type]: this.state.accountId,
                    adwords_mcc_id: isMCC,
                  })

                }
                }
              >
                Select
                 </Button>
            </Fragment>
          )}
        />
      </Panel>
    )
  }
}

const mapStateToProps = ({ sites: { show: { data: siteData } },   sites: { show: { paidAdsData } },
}) => ({
  siteData,
  paidAdsData
});

export default connect(mapStateToProps, { updateSite })(AdwordsConnect);
