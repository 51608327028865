import React, { Component, Fragment } from "react";
import { PieGraph } from "common";
import { ViewWithLoader } from "common";
import AgeGenderBreakdown from "screens/social/components/AgeGenderBreakdown";
import { NoData } from "./NoData";
import { SocialGraphViewWithLoader } from "../SocialGraphViewWithLoader";
import { Panel } from "common";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const COLORS = ["#619bf9", "#abcafc", "#dce9fe"];

class FacebookDemographics extends Component {
  state = {
    ageDataFinal: {
      female: 0,
      male: 0,
    },
    percentLikesGenderAge: null,
    femaleLikesPercent: 0,
    maleLikesPercent: 0,
    displayString: null,
    isFetching: true,
  };
  componentDidUpdate(prevProps) {
    if (this.props.month !== prevProps.month) {
      this.setState({
        isFetching: true,
        noData: false,
      });
    }
    if (prevProps.data !== this.props.data) {
      let page_fans = {};
      let page_fans_gender_age = {};
      let checkDataFansGenderAge = false;
      let checkDataAudienceGenderAge = false;
      let checkDataPageFans = false;
      this.props.data.forEach((item, index) => {
        item.name === "page_fans_gender_age" && (checkDataFansGenderAge = true);
        item.name === "page_fans_gender_age" && (page_fans_gender_age = item);
        // item.name === "audience_gender_age" && (checkDataAudienceGenderAge = true);
        item.name === "page_fans" && (checkDataPageFans = true);
        item.name === "page_fans" && (page_fans = item);
        // item.name === "audience_gender_age" && (page_fans_gender_age = item);
      });
      if (checkDataFansGenderAge !== false && checkDataPageFans !== false) {
        const ageData = page_fans_gender_age.values[0].value;
        const totalLikes = page_fans.values.slice(-1)[0].value;
        const ageDataObj = {
          female: 0,
          male: 0,
        };

        for (let key in ageData) {
          if (key[0] === "F") {
            ageDataObj.female += ageData[key];
          }
          if (key[0] === "M") {
            ageDataObj.male += ageData[key];
          }
        }
        const tempArr = [];
        let entries = Object.entries(ageData);
        let sorted = entries.sort((a, b) => b[1] - a[1]);
        sorted.forEach((array) => {
          var obj = {};
          obj["category"] = array[0];
          obj["value"] = array[1];
          tempArr.push(obj);
        });
        const percentLikesGenderAge = tempArr.reduce((accum, item) => {
          var tempObj = item;
          tempObj.value = Math.round((item.value / totalLikes) * 100);
          tempObj.amt = 100;
          accum.push(tempObj);
          return accum;
        }, []);
        const ageRange = sorted[0][0];
        const ageRangeSlice = ageRange.slice(2);
        const genderFlag = sorted[0][0][0];
        let gender;
        if (genderFlag === "F") {
          gender = "Women";
        } else if (genderFlag === "M") {
          gender = "Men";
        }
        let stringToDisplay;
        if (ageRange.length > 6) {
          let ageRangeFormatted = ageRangeSlice.replace("-", " - ");
          stringToDisplay = `Facebook Core Demographic: ${gender} ages ${ageRangeFormatted}`;
        } else {
          stringToDisplay = `Facebook Core Demographic: ${gender} aged ${ageRangeSlice}`;
        }

        const femaleLikesP = Math.round((ageDataObj.female / totalLikes) * 100);
        const maleLikesP = Math.round((ageDataObj.male / totalLikes) * 100);
        this.setState(function(state, props) {
          return {
            percentLikesGenderAge: percentLikesGenderAge,
            demographics: tempArr,
            displayString: stringToDisplay,
            ageDataFinal: ageDataObj,
            femaleLikesPercent: femaleLikesP,
            maleLikesPercent: maleLikesP,
            isFetching: false,
          };
        });
      } else {
        this.setState(function(state, props) {
          return {
            isFetching: false,
            noData: true,
          };
        });
      }
    }
  }

  render() {
    const audienceData = [
      { name: "Male", value: this.state.maleLikesPercent },
      { name: "Female", value: this.state.femaleLikesPercent },
    ];
    return this.state.noData ? (
      <NoData title={"No Demographics Data Available"} />
    ) : (
      <Fragment>
        <SocialGraphViewWithLoader
          classes="mb-3 col-6"
          isLoading={this.state.isFetching}
        >
          <AgeGenderBreakdown data={this.state.demographics} />
        </SocialGraphViewWithLoader>
        <SocialGraphViewWithLoader
          classes="mb-3  col-6"
          isLoading={this.state.isFetching}
        >
          <Panel errors={this.props.errors} padding={2}>
            <Panel.Subtitle text={"Gender Demographics"} />
            <Panel.Body
              render={() => {
                return (
                  <ResponsiveContainer height={230}>
                    <PieChart className={`device-graph`}>
                      <Pie
                        labelLine={false}
                        dataKey="value"
                        data={audienceData}
                        cx={115}
                        cy={115}
                        innerRadius={30}
                        outerRadius={60}
                      >
                        {audienceData.map((entry, index) => (
                          <Cell
                            key={index}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend wrapperStyle={{ position: "static" }} />
                    </PieChart>
                  </ResponsiveContainer>
                );
              }}
            />
          </Panel>
        </SocialGraphViewWithLoader>
      </Fragment>
    );
  }
}

export { FacebookDemographics };
