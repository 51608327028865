export const FETCHING_SOCIAL_DATA_GROWTH = "FETCHING_SOCIAL_DATA_GROWTH"
export const FETCHING_SOCIAL_DATA_ENGAGEMENT = "FETCHING_SOCIAL_DATA_ENGAGEMENT"
export const FETCHING_SOCIAL_DATA_VIDEO = "FETCHING_SOCIAL_DATA_VIDEO"
export const FETCHING_SOCIAL_DATA_AGE = "FETCHING_SOCIAL_DATA_AGE"
export const FETCHING_SOCIAL_DATA_LOCATION = "FETCHING_SOCIAL_DATA_LOCATION"
export const FETCHING_SOCIAL_DATA_IMPRESSIONS = "FETCHING_SOCIAL_DATA_IMPRESSIONS"
export const FETCHING_SOCIAL_DATA_CLICKS = "FETCHING_SOCIAL_DATA_CLICKS"
export const FETCHING_SOCIAL_DATA_LIKESANDPAGEVIEWS = "FETCHING_SOCIAL_DATA_LIKESANDPAGEVIEWS"
export const FETCHING_SOCIAL_DATA_FANS = "FETCHING_SOCIAL_DATA_FANS"
export const FETCHING_SOCIAL_SITES = "FETCHING_SOCIAL_SITES"
export const FETCHING_SOCIAL_TWITTER = "FETCHING_SOCIAL_TWITTER"
export const FETCHING_SOCIAL_INSTAGRAM_INSIGHTS = "FETCHING_SOCIAL_INSTAGRAM_INSIGHTS"
export const FETCHING_SOCIAL_INSTAGRAM_POST_DATA = "FETCHING_SOCIAL_INSTAGRAM_POST_DATA"
export const FETCHING_SOCIAL_INSTAGRAM_DEMOGRAPHIC_DATA = "FETCHING_SOCIAL_INSTAGRAM_DEMOGRAPHIC_DATA"
export const FETCHING_SOCIAL_INSTAGRAM_BUSINESS_ID = "FETCHING_SOCIAL_INSTAGRAM_BUSINESS_ID"
export const FETCHING_FOLLOWERS_COUNT = "FETCHING_FOLLOWERS_COUNT"
export const REFRESH_INSTAGRAM_POST_DATA_SUCCESS = "REFRESH_INSTAGRAM_POST_DATA_SUCCESS"

