import _ from "lodash";
import React, { Component } from "react";
import { func, array, number, shape, string, bool } from "prop-types";
import { connect } from "react-redux";

import { Panel, Table } from "common";
import { fetchGoogleAnalyticsPageViews } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { getPreviousMonthStart, getPreviousMonthEnd } from "lib/dateHelpers";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";

class GoogleAnalyticsPageViews extends Component {
  static propTypes = {
    fetchGoogleAnalyticsPageViews: func,
    pageViews: array,
    siteId: number,
    ga_property_id: string,
    date: shape({
      month: string,
      year: string
    }),
    isPublic: bool,
    public_hash: string
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print")
  };
  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
      prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId =
      this.props.ga_property_id !== nextProps.ga_property_id;

    if (!this.props.ga_property_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
        prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        prevStart: getPreviousMonthStart(getStartDate(this.props.date)),
        prevEnd: getPreviousMonthEnd(getEndDate(this.props.date)),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.props.clearCacheBool === true && this.props.fetchGoogleAnalyticsPageViews(
        params,
        () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = params => {
    if (!params.siteId || !this.props.ga_property_id) return;
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsPageViews(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.ga_property_id) return null;

    const pageViews = (this.props.pageViews || []).map(page => ({
      page: { value: page.page },
      "views this month": { value: page.thisMonth },
      "views last month": { value: page.lastMonth },
      change: { value: page.change },
      "% change": { value: page.percentChange }
    }));

    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="5">Top Organic Page Views</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Page</td>
                  <td>Users This Month</td>
                  <td>Users Last Month</td>
                  <td>Change</td>
                  <td>% Change</td>
                </tr>
                {_.map(pageViews, (item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.page.value}</td>
                      <td>{item["views this month"].value}</td>
                      <td>{item["views last month"].value}</td>
                      <td>{item.change.value}</td>
                      <td>{item["% change"].value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <ReportSubTitle text="Top Organic Page Views" classes="mt-3" logo={logo} />
        <Panel
          containerClass="col-md-12 p-3 float-left"
          isLoading={this.state.isFetching}>
          <Panel.Body
            style={{
              display: "block",
              paddingBottom: "1em"
            }}
            render={() => (
              <Table
                headers={[
                  "Page",
                  "This Month",
                  "Last Month",
                  "Change",
                  "% Change"
                ]}
                data={pageViews}
              />
            )}
          />
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, id: siteId, ga_property_id },
      analyticsData: { pageViews }
    }
  }
}) => ({
  date,
  siteId,
  ga_property_id,
  pageViews,
  isPublic,
  public_hash
});

export default connect(mapStateToProps, {
  fetchGoogleAnalyticsPageViews
})(GoogleAnalyticsPageViews);

