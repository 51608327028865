import React, { Component, Fragment } from "react";
import instagram from "../images/instagram.png";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { connect } from "react-redux";
import { store } from "store";
import { fetchInstagramDataInsights } from "../actions/actions";
import moment from "moment";
import iconSocialLikes from "../../../images/icon-social-likes.svg";
import iconSocialEngagement from "../../../images/icon-social-engagement.svg";
import iconSocialEngagementRate from "../../../images/icon-social-engagement-rate.svg";
import iconSocialComments from "../../../images/icon-social-comments.svg";
import iconSocialSaved from "../../../images/icon-social-saved.svg";
import iconSocialShares from "../../../images/icon-social-shares.svg";
import iconSocialImpressions from "../../../images/icon-social-impressions.svg";
import iconSocialReach from "../../../images/icon-social-reach.svg";
import { SocialGraphViewWithLoader } from "../SocialGraphViewWithLoader";
import SocialTooltips from "./SocialTooltips";

const tempImageMap = {
  reach: iconSocialImpressions,
  impressions: iconSocialReach,
  saved: iconSocialSaved,
  shares: iconSocialShares,
};

const getEngagementRateOfPost = (followerCount, engagementRate) => {
  if (typeof followerCount === "number") {
    return ((engagementRate / followerCount) * 100).toFixed(2);
  }
  const followerCountToInt =
    (followerCount || "").includes("K") || (followerCount || "").includes("M")
      ? getFormattedFollowerCount(followerCount)
      : null;
  return followerCountToInt === null
    ? "N/A"
    : ((engagementRate / followerCountToInt) * 100).toFixed(2);
};

const getFormattedFollowerCount = (val) => {
  const isThousand = val.includes("K");
  const formattedValue = (val || "").replace("K", "").replace("M", "");
  let final = `${formattedValue}${isThousand ? "000" : "000000"}`;
  return parseInt(final);
};

const getPostValue = (insight) => {
  const { values } = insight;
  return (values || []).length && (values[0] || {}).value
    ? (values[0] || {}).value.toLocaleString()
    : "N/A";
};

const PostInsights = ({ post, followerCount }) => {
  const engagementRate = getEngagementRateOfPost(
    followerCount,
    post.engagement
  );
  return (
    <ul className="card-social_stats instagram-stats">
      <li data-title={"Likes"} className="card-social_stats-item">
        <img src={iconSocialLikes} alt="" />
        {post.like_count ? post.like_count.toLocaleString() : "N/A"}
        <SocialTooltips title={"Likes"} />
      </li>
      <li data-title={"Engagement"} className="card-social_stats-item">
        <img src={iconSocialEngagement} alt="" />
        {post.engagement ? post.engagement.toLocaleString() : "N/A"}
        <SocialTooltips title="Engagement" />
      </li>{" "}
      <li data-title={"Engagement Rate"} className="card-social_stats-item">
        <img src={iconSocialEngagementRate} alt="" />
        {engagementRate && engagementRate !== "N/A"
          ? `${engagementRate}%`
          : "N/A"}
        <SocialTooltips title={"Engagement Rate"} />
      </li>
      <li data-title={"Comments"} className="card-social_stats-item">
        <img src={iconSocialComments} alt="" />
        {post.comments_count ? post.comments_count.toLocaleString() : "N/A"}
        <SocialTooltips title="Comments" />
      </li>
      {(post.insights || [])
        .filter((n) => n.name !== "engagement")
        .map((n, i) => (
          <li key={i} data-for={n.title} className="card-social_stats-item">
            <img
              src={tempImageMap[n.name] ? tempImageMap[n.name] : null}
              alt=""
            />
            {getPostValue(n)}
            <SocialTooltips title={n.title} />
          </li>
        ))}
    </ul>
  );
};

const RenderInstagramPosts = ({ data, followerCount }) => {
  return (
    <Fragment>
      {(data || []).map((post, i) => (
        <div className="card-social" key={i}>
          {(post.media_type || "") === "VIDEO" ? (
            <video
              controls
              className="card-social_image z-index-max"
              muted="true"
            >
              <source
                type="video/mp4"
                data-reactid=".0.1.0.0.0"
                src={post.media_url}
              />
            </video>
          ) : (
            <div
              className="card-social_image"
              style={{ backgroundImage: `url(${post.media_url})` }}
            ></div>
          )}
          <div
            className="card-social_image"
            style={{ backgroundImage: `url(${post.media_url})` }}
          ></div>
          <div className="card-social_content">
            <p className="card-social_date">
              {post.timestamp
                ? moment(post.timestamp).format("MM-DD-yyyy")
                : null}
            </p>
            <img className="card-social_type" src={null} alt="" />
            <p className="card-social_text">
              {post.caption
                ? post.caption.substring(0, 150) + "..."
                : "No caption"}
            </p>
            <PostInsights post={post} followerCount={followerCount} />
          </div>
        </div>
      ))}
    </Fragment>
  );
};

class InstagramPosts extends Component {
  state = {
    isFetching: true,
  };
  goToPost = (link) => {
    window.location = link;
  };

  refreshPosts = () => {
    store.dispatch({ type: "SET_INSTAGRAM_POSTS_LOAD_STATE", payload: true });
    const currentMonthDates = {
      startOfMonth: this.props.date.dateOneStartForCompare,
      endOfMonth: this.props.date.dateOneEndForCompare,
    };
    this.props.fetchInstagramDataInsights(
      "posts",
      currentMonthDates,
      this.props.siteData.public_hash,
      true
    );
  };

  refreshing = () => {
    this.setState({
      isFetching: true,
    });
  };

  render() {
    return (
      <SocialGraphViewWithLoader
        classes="mb-3"
        customStyle={{ width: "100%" }}
        isLoading={this.props.instagramPostsLoadState}
      >
        <div
          style={{ paddingLeft: 0 }}
          className="col-12 social-posts_title mt-3"
        >
          <ReportSubTitle text="Top Posts" logo={instagram} />
          <button
            className="instagram-refresh"
            onClick={() => {
              this.refreshPosts();
            }}
          >
            <i className="fas fa-sync"></i>
          </button>
        </div>
        <div className="col-12 instagram-posts-container mt-3">
          <RenderInstagramPosts
            data={this.props.instagramPosts}
            followerCount={this.props.followerCount}
          />
        </div>
      </SocialGraphViewWithLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  instagramPosts: state.social.instagramPosts,
  instagramPostsLoadState: state.social.instagramPostsLoadState,
  siteData: { ...state.sites.show.data },
  date: { ...state.sites.show.date },
  followerCount: state.social.social.follower_count,
});

const mapDispatchToProps = { fetchInstagramDataInsights };
const connected = connect(mapStateToProps, mapDispatchToProps)(InstagramPosts);

export { connected as InstagramPosts };
