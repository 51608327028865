export const getHumanReadableTime = timeInSeconds => {
  let time = "";
  const minutes = timeInSeconds / 60;
  const remainingSeconds = Math.round((minutes % 1) * 60);

  time += `${Math.floor(minutes)}m`;

  if (remainingSeconds === 0) return time;

  time += ` ${remainingSeconds}s`;

  return time;
};

export const prettyNumber = str => {
  return parseFloat(str).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

export const changePercentDifference = (thisValue, previousValue) => {
  const difference = thisValue - previousValue;
  if (parseInt(previousValue, 0) === 0) {
    return "100%";
  }
  return (difference / previousValue * 100).toFixed(0).toString() + "%";
};
