import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { Component, Fragment } from "react";
import {
  array,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { getEndDate, getStartDate } from "screens/sites/helpers/date_helpers";

import { Panel } from "common";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchAdwordsPerformance } from "screens/sites/actions";
import moment from "moment";

// import ReportSubTitle from "screens/sites/components/ReportSubTitle";
// import logo from "images/icon_adwords.png";

const CustomTooltip = (props) => {
  if (_.isEmpty(props.payload)) return <div />;

  return (
    <section
      style={{
        backgroundColor: "white",
        padding: "1em",
      }}
    >
      <div>Day: {props.label}</div>
      {props.payload.map((data) => {
        return (
          <div key={data.dataKey}>
            <div>{`${data.dataKey}: ${data.payload[data.dataKey]}`}</div>
          </div>
        );
      })}
    </section>
  );
};

CustomTooltip.propTypes = {
  payload: array,
  label: string,
};

class GoogleAdwordsMonthlyConversions extends Component {
  static propTypes = {
    adwordsPerformance: object,
    fetchAdwordsPerformance: func,
    adwords_id: oneOfType([string, number]),
    date: shape({
      month: string,
      year: string,
    }),
  };

  state = { isFetching: false };
  mounted = true;

  componentDidMount() {
    if (!this.props.adwords_id) return;
    const params = {
      adwords_id: this.props.adwords_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = (nextProps) => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId = this.props.adwords_id !== nextProps.adwords_id;

    if (!this.props.adwords_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
      };
      this.fetchData(params);
    }

    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
      };
      this.props.clearCacheBool === true &&
        this.props.fetchAdwordsPerformance(
          params,
          () => {},
          this.props.clearCacheBool,
          false,
          true
        );
    }
  };

  fetchData = (params) => {
    this.mounted && this.setState({ isFetching: true });
    this.props.fetchAdwordsPerformance(
      params,
      () => {
        this.mounted && this.setState({ isFetching: false });
      },
      this.props.clearCacheBool,
      false,
      true
    );
  };

  render() {
    if (!this.props.adwords_id) return null;
    const startDate = getStartDate(this.props.date);
    const thisMonth = startDate.substring(5, 7);
    const thisMonthMMM = moment(thisMonth, "MM").format("MMM");
    const lastMonthMMM = moment(thisMonth, "MM").subtract("1").format("MMM");
    return (
      <Fragment>
        <Panel
          containerClass="col-md-6 p-3 float-left"
          isLoading={this.state.isFetching}
          padding={2}
        >
          <Panel.Subtitle
            text={`Month Vs Month ${
              this.props.isEcommerce ? "Sales" : "Conversions"
            } `}
          />
          <Panel.Body
            render={() => {
              return (
                <ResponsiveContainer height={230}>
                  <LineChart
                    data={this.props.adwordsPerformanceMonthVsMonth.reports}
                    margin={{ top: 5, right: 40, left: -20, bottom: 20 }}
                  >
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Legend />
                    <Tooltip
                      content={
                        <CustomTooltip
                          thisMonth={thisMonthMMM}
                          lastMonth={lastMonthMMM}
                        />
                      }
                    />
                    <Line
                      type="monotone"
                      dataKey={lastMonthMMM}
                      stroke="#CCCCCC"
                      strokeWidth={3}
                    />
                    <Line
                      type="monotone"
                      dataKey={thisMonthMMM}
                      stroke="#619bf9"
                      strokeWidth={3}
                    />
                  </LineChart>
                </ResponsiveContainer>
              );
            }}
          />
        </Panel>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { adwords_id },
      paidAdsData: { adwordsPerformanceMonthVsMonth },
    },
  },
}) => ({
  adwords_id,
  date,
  adwordsPerformanceMonthVsMonth,
});

GoogleAdwordsMonthlyConversions.defaultProps = {
  isEcommerce: false,
};

export default connect(mapStateToProps, { fetchAdwordsPerformance })(
  GoogleAdwordsMonthlyConversions
);
