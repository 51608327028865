import React from 'react';
import { Panel, CustomPieChart } from "common";
import _ from "lodash";

const formatNumbers = (val, metricName) => {
  if (!_.isNumber(val)) {
    val = Number.parseFloat(val).toFixed(2);
  } else {
    val = Number.parseFloat(val).toFixed(2);
  }
  if (metricName === "Spend" || metricName === "Sales" || metricName === "Revenue") {
    return '$' + val;
  }
  return val
}

const CampaignInfographic = (props) => {
  return (
    <Panel
      containerClass={"col-md-6 p-3 float-left adwords-infographics-container"}
      isLoading={props.isLoading}
      // errors={this.props.errors}
      padding={2}>
      {/* <Panel.Subtitle text={props.title} /> */}
      <Panel.Body
        className={"center-infographic"}
        render={() => {
          return (
            <div className="infographic-panel d-flex" key={Math.random()}>
              <div className="infographic-stats">
                <h3 className="stat-heading">{props.heading}</h3>
                <p className="stat-value">{formatNumbers(props.individualValue, props.heading)}</p>
                <p className="stat-footer">{props.footerPercentValue}% of account {props.heading.toLowerCase()}</p>
              </div>
              <CustomPieChart accountLevelData={props.accountLevelData} individualValue={props.individualValue} />
              <div className="pie-image">
                <img src={props.icon} />
              </div>
            </div>
          );
        }}
      />
    </Panel>
  )
};

export default CampaignInfographic;