import React from 'react';
import { node, string, object } from 'prop-types';

import withLoader from 'hoc/withLoader';
import withSuccess from 'hoc/withSuccess';
import withErrors from 'hoc/withErrors';

function View({ children, classes, customStyle }) {
  return (
    <div className={`${classes ? classes : ''}`} style={customStyle}>
      {children}
    </div>
  );
}

const SocialGraphViewWithLoader = withLoader(withSuccess(withErrors(View)));

export { SocialGraphViewWithLoader };
