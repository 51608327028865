import axios from "axios";
import { rootUrl } from "lib/api";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";

import { authLogoutAndRedirect } from "screens/users/actions";
import { DELETING_TODO_COMMENT } from "./types";

export const deleteTodoComment = (commentId) => async (dispatch) => {
  const dispatching = dispatchHelper(DELETING_TODO_COMMENT);
  const url = `${rootUrl}/comments/${commentId}`;
  const token = getToken();
  const headers = requestHeaders(token);

  try {
    const response = await axios.delete(url, headers);
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};
