import React, { Component } from "react";
import { PieGraph } from "common";
import { NoData } from "./NoData";


class LikeSources extends Component {
    state = {
        likesSourcesData: null,
        isFetching: true,
        noData: false,
    }

    componentDidUpdate(prevProps) {
        if (this.props.month !== prevProps.month) {
            this.setState({
                isFetching: true,
            })
        }
        if (prevProps.data !== this.props.data) {
            let checkForPageFansAddPaidNonPaid = false;
            let checkforPageFansLikeSource = false;
            let someValuesPresent = false;
            this.props.data.forEach(item => {
                item.name === "page_fan_adds_by_paid_non_paid_unique" && (checkForPageFansAddPaidNonPaid = true)
                item.name === "page_fans_by_like_source_unique" && (checkforPageFansLikeSource = true)
            });
            if (checkForPageFansAddPaidNonPaid === true && checkforPageFansLikeSource === true) {
                let page_fans_by_like_source_unique = {};
                this.props.data.forEach((item, index) => {
                    if (item.name === "page_fans_by_like_source_unique") {
                        page_fans_by_like_source_unique = item;
                    }
                });
                let likeSources =
                {
                    "Ads": 0,
                    "News Feed": 0,
                    "Other": 0,
                    "Search": 0,
                    "Page Suggestions": 0,
                    "Restored Likes from Reactivated Accounts": 0,
                    "Your Page": 0,
                };
                page_fans_by_like_source_unique.values.forEach(item => {
                    if (Object.keys(item.value).length !== 0) {
                        for (let key in item.value) {
                            likeSources[key] += item.value[key]
                        }
                        someValuesPresent = true;
                    } 

                });
                if (someValuesPresent === true) {
                    let sortable = [];
                    for (var category in likeSources) {
                        sortable.push([category, likeSources[category]]);
                    }

                    sortable.sort(function (a, b) {
                        return b[1] - a[1];
                    });
                    for (let i = 0; i < 2; i++) {
                        sortable.pop();
                    }
                    let likesSourcesData = {};
                    const checkNamesArray = [];
                    sortable.forEach(item => {
                        if (!checkNamesArray.includes(item[0])) {
                            checkNamesArray.push(item[0]);
                            const key = item[0];
                            const value = item[1];
                            likesSourcesData[key] = value;
                        }
                    });
                    this.setState(function (state, props) {
                        return {
                            likesSourcesData: likesSourcesData,
                            isFetching: false,
                            noData: false,
                        }
                    });
                } else {
                    this.setState(function (state, props) {
                        return {
                            noData: true,
                            isFetching: false,
                        }
                    });
                }
            } else {
                this.setState(function (state, props) {
                    return {
                        noData: true,
                        isFetching: false,
                    }
                });
            }
        }
    }
    render() {
        return (
            this.state.noData ? <NoData title={"No Like Sources Data Available"} /> :
                <PieGraph
                    title="Page Likes By Source"
                    isLoading={this.state.isFetching}
                    data={this.state.likesSourcesData}
                    heightClassname="height-auto"
                />
        )
    }
}

export { LikeSources };

