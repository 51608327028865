import React, { Component, Fragment } from "react";

import { Panel } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import SEGroupsListItem from "../../screens/sites/components/SEGroupsListItem";


import { DemoDataRankingSection } from "../../fixtures/fixtures";

class SERankings extends Component {
    render() {
        // Fixtures
        const isFetching = false;
        return (
            <Fragment>
                <ReportSubTitle text="Rankings" classes="pt-3 clearfix" />
                <Panel containerClass="col-md-12 p-3">
                    {DemoDataRankingSection.map((region, index) => (
                        <Fragment key={index}>
                            <Panel.Header title={region.region} />
                            <Panel.Body
                                isLoading={isFetching}
                                render={() => {
                                    return (
                                        <div className="p-3 w-100">
                                            {region.groups.map(group => (
                                                <SEGroupsListItem key={group.id} {...group} />
                                            ))}
                                        </div>
                                    );
                                }}
                            />
                        </Fragment>
                    ))}
                </Panel>
            </Fragment>
        );
    }
}

export default SERankings;
