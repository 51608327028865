import React, { Component } from "react";
import { func, object } from "prop-types";
import { connect } from "react-redux";

import * as actions from "screens/templates/actions";
import { reorder } from "screens/todos/helpers/dnd_helpers";
import TeamsList from "screens/templates/components/TeamsList";
import TeamTemplates from "screens/templates/components/TeamTemplates";
import TemplateDetail from "screens/templates/components/TemplateDetail";
import { ViewWithLoader } from "common";

class TemplatesIndex extends Component {
  static propTypes = {
    fetchTemplates: func,
    updateTemplate: func,
    templates: object
  };

  initialState = {
    selectedTeam: "Dev",
    selectedTemplate: [],
    selectedTemplateClone: [],
    selectedTemplateId: null,
    selectedTemplateTitle: "",
    selectedTemplateTitleClone: "",
    editMode: false,
    newTemplateItem: ""
  };

  state = this.initialState;

  componentDidMount = async () => {
    await this.props.fetchTemplates();
  };

  handleSelectTeam = selectedTeam => {
    this.setState({
      ...this.initialState,
      selectedTeam
    });
  };

  handleSelectTemplate = ({ id, data, title }) => {
    this.setState({
      selectedTemplate: JSON.parse(data),
      selectedTemplateClone: JSON.parse(data),
      selectedTemplateId: id,
      selectedTemplateTitle: title,
      selectedTemplateTitleClone: title,
      editMode: false
    });
  };

  toggleEditMode = () =>
    this.setState(nextState => ({ editMode: !nextState.editMode }));

  handleChangeTemplateItem = ({ target: { value: title } }, index) => {
    this.setState({
      selectedTemplate: [
        ...this.state.selectedTemplate.slice(0, index),
        { ...this.state.selectedTemplate[index], title },
        ...this.state.selectedTemplate.slice(index + 1)
      ]
    });
  };

  handleChangeTemplateTitle = e => {
    this.setState({ selectedTemplateTitle: e.target.value });
  };

  handleDeleteTemplateItem = index => {
    const selectedTemplate = [
      ...this.state.selectedTemplate.slice(0, index),
      ...this.state.selectedTemplate.slice(index + 1)
    ];
    this.setState({ selectedTemplate });
  };

  handleAddTemplateItem = e => {
    e.preventDefault();
    let selectedTemplate;

    if (this.state.newTemplateItem[0] === "#") {
      selectedTemplate = [
        ...this.state.selectedTemplate,
        {
          title: this.state.newTemplateItem.slice(1),
          section: true,
          completed: false
        }
      ];
    } else {
      selectedTemplate = [
        ...this.state.selectedTemplate,
        { title: this.state.newTemplateItem, section: false, completed: false }
      ];
    }

    this.setState({
      newTemplateItem: "",
      selectedTemplate
    });
  };

  onDragEnd = result => {
    if (!this.state.editMode) return;
    if (!result.destination) return;

    const selectedTemplate = reorder(
      this.state.selectedTemplate,
      result.source.index,
      result.destination.index
    );

    this.setState({ selectedTemplate });
  };

  handleCancelChanges = () => {
    this.setState({
      selectedTemplate: this.state.selectedTemplateClone,
      selectedTemplateTitle: this.state.selectedTemplateTitleClone,
      editMode: false
    });
  };

  handleSaveTemplate = () => {
    this.props.updateTemplate(this.state.selectedTemplateId, {
      data: JSON.stringify(this.state.selectedTemplate),
      title: this.state.selectedTemplateTitle
    });
    this.setState({ editMode: false });
  };

  render() {
    const { templates } = this.props;

    return (
      <ViewWithLoader
        classes="templates-container"
        isLoading={templates.index.isFetching}>
        <TeamsList
          data={templates.index.data}
          handleSelectTeam={this.handleSelectTeam}
        />
        <TeamTemplates
          team={this.state.selectedTeam}
          data={this.props.templates.index.data[this.state.selectedTeam]}
          selectedTemplateId={this.state.selectedTemplateId}
          handleSelectTemplate={this.handleSelectTemplate}
        />
        <TemplateDetail
          title={this.state.selectedTemplateTitle}
          data={this.state.selectedTemplate}
          editMode={this.state.editMode}
          toggleEditMode={this.toggleEditMode}
          handleCancelChanges={this.handleCancelChanges}
          handleSaveTemplate={this.handleSaveTemplate}
          handleChangeTemplateItem={this.handleChangeTemplateItem}
          handleDeleteTemplateItem={this.handleDeleteTemplateItem}
          handleAddTemplateItem={this.handleAddTemplateItem}
          onDragEnd={this.onDragEnd}
          newTemplateItem={this.state.newTemplateItem}
          handleChangeTemplateTitle={this.handleChangeTemplateTitle}
          onChangeNewItem={e =>
            this.setState({ newTemplateItem: e.target.value })
          }>
          <TemplateDetail.Header />
          <TemplateDetail.List />
          <TemplateDetail.Form />
        </TemplateDetail>
      </ViewWithLoader>
    );
  }
}

const mapStateToProps = ({ templates }) => ({ templates });

const connected = connect(mapStateToProps, actions)(TemplatesIndex);


export { connected as TemplatesIndex };
