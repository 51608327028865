/*
fetchGoogleAnalyticsSocialSources
fetchGoogleAnalyticsDevices
*/

const formatReports = payload => {
  if (payload.reports[0].data.rows === undefined) {
    return undefined;
  }
  let data = payload.reports[0].data.rows.splice(0, 10);
  const formattedArray = [];
  data.forEach(item => {
    let total = item.metrics[0].values[0];
    let dimensions = item.dimensions;
    const obj = {
      total,
      eventCategory: dimensions[0],
      eventAction: dimensions[1],
      eventLabel: dimensions[2],
    };
    formattedArray.push(obj);
  });
  return formattedArray;
};

export const googleAnalyticsEvents = ({ dispatch }) => next => action => {
  if (!action.fromGAEvents) {
    return next(action);
  }
  let formattedData = formatReports(action.payload);
  const newAction = {
    ...action,
    payload: formattedData,
    fromGAEvents: false,
  };
  dispatch(newAction);
};

const formatAnalyticsEventsGA4 = (payload) => {
  if(!payload.rows){
    return []
  }
  let data = payload.rows.splice(0, 10);
  const formattedArray = [];
  data.forEach((n) => {
    let total = n.metricValues[0].value ? parseInt(n.metricValues[0].value).toLocaleString() : 0;
    const obj = {
      total,
    };
    if (n.dimensionValues[0] && n.dimensionValues[0].value) {
      obj.eventCategory = n.dimensionValues[0].value.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
    }

    if (n.dimensionValues[1] && n.dimensionValues[1].value) {
      obj.eventAction = n.dimensionValues[1].value.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
    }

    if (n.dimensionValues[2] && n.dimensionValues[2].value) {
      obj.eventLabel = n.dimensionValues[2].value.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
    }
    formattedArray.push(obj);
  });
  return formattedArray.sort((a, b) => {
    return b.total - a.total;
  });
};

export const googleAnalyticsEventsGA4 = ({ dispatch }) => next => action => {
  if (!action.fromGAEventsGA4) {
    return next(action);
  }
  let formattedData = formatAnalyticsEventsGA4(action.payload);
  const newAction = {
    ...action,
    payload: formattedData,
    fromGAEventsGA4: false,
  };
  dispatch(newAction);
};
