import React from "react";
import { string } from "prop-types";

ReportSubTitle.propTypes = {
  text: string.isRequired,
  classes: string,
};

ReportSubTitle.defaultProps = {
  classes: "",
};

function ReportSubTitle(props) {
  const classes = props.classes ? props.classes : "";
  return (
    <h2
      className={`report-sub-title ${classes}`}
      style={props.style ? props.style : {}}
    >
      {props.logo && (
        <img src={props.logo} alt="" width="20px" className="mr-2 pb-1" />
      )}
      {props.text}
    </h2>
  );
}

export default ReportSubTitle;
