import _ from 'lodash'
import React from "react";
import ReactTooltip from "react-tooltip";
import { Button } from "common";

const NaNCheck = (data) => {
if(_.isNaN(data.value)){
    return "-";
} 
return data.value
}

export const Google_Columns = [
    {
        columns: [
            {
                Header: "Ad Group",
                accessor: "Ad_group"
            },
            {
                Header: "Avg CPC",
                accessor: "Avg_CPC"
            },
            {
                Header: "CTR",
                accessor: "CTR",
                sortMethod: (a, b) => {
                    return a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    });
                }
            },
            {
                Header: "Clicks",
                accessor: "Clicks"
            },
            {
                Header: "Conv Rate",
                accessor: "Conv_rate",
                sortMethod: (a, b) => {
                    return a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    });
                }
            },
            {
                Header: "Impressions",
                accessor: "Impressions"
            },
            {
                Header: "Search Lost",
                accessor: "Search_lost_top_IS_budget"
            },
            {
                Header: "Total Conv Val",
                accessor: "Total_conv_value"
            },
            {
                Header: "ROAS",
                accessor: "roas"
            },
        ]
    }
];

const titleCase = str => {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
};

const parseFBConversionLabel = str => {
    return str.replace(".", " ").replace("_", " ");
};

export const Facebook_Columns = [
    {
        columns: [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "CPC",
                accessor: "cpc",
                Cell: data => {
                    return NaNCheck(data);
                }
            },
            {
                Header: "Visitors",
                accessor: "visitors",
                Cell: data => {
                    return NaNCheck(data);
                }
            },
            {
                Header: "Spend",
                accessor: "spend",
                Cell: data => {
                    return NaNCheck(data);
                }
            },
            {
                Header: "CTR",
                accessor: "ctr",
                Cell: data => {
                    return NaNCheck(data);
                }
            },
            {
                Header: "Conversions",
                Cell: data => {
                    let start = 0;
                    if (data.original.conversions.length > 0) {
                        _.forOwn(data.original, (value, key) => {
                            if (key === 'conversions') {
                                if (value.length > 0) {
                                    value.forEach(item => {
                                        let parsedValue = parseFloat(item.value);
                                        start += parsedValue;
                                    });
                                }
                            }
                        });
                        return (
                            <div className="test-1" onMouseLeave={() => data.original.renderTooltip(null)} onMouseOver={() => data.original.renderTooltip(data.original.id)}>
                                <div key={Math.random()} className="test">{start}</div>
                                {data.original.state.id === data.original.id
                                    &&
                                    <div className="react-table-tooltip-custom">
                                        {
                                            data.original.conversions.map((item, index) => <div key={index}><strong>{titleCase(parseFBConversionLabel(item.action_type))}:</strong> {item.value}</div>)
                                        }
                                    </div>
                                }
                            </div>
                        )
                    } else {
                        return "-"
                    }
                }
            },
            {
                Header: "Ad Preview",
                width: 100,
                Cell: data => {
                    return (
                        <div key={Math.random()} className="ad-preview-button">
                            <Button
                                onClick={() => {data.original.openModal(data.original); data.original.clearAdPreviewState();}}
                            >
                                View
                            </Button>
                        </div>
                    )
                }
            }
        ]
    }
];





