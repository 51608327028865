import React, { Component } from "react";
import { StatisticsBar } from "common";

class DemoStatsBarContainer extends Component {
    state = {

    }
    render() {
        let a = {
            page_likes: {
                title: "Page Likes",
                value: "14k",
                change: "75",
                positiveChange: true,
            },
            page_impressions: {
                title: "Page Impressions",
                value: "1500",
                change: "300",
                positiveChange: true,
            },
            reactions: {
                title: "Reactions",
                value: "325",
                change: "55",
                positiveChange: true,
            },
            video_views: {
                title: "Video Views",
                value: "9000",
                change: "2500",
                positiveChange: true,
            },
        }
        let b = {
            page_likes: {
                title: "Followers",
                value: "3.5K",
                change: "75",
                positiveChange: true,
            },
            page_impressions: {
                title: "Likes Received",
                value: "2.9k",
                change: "300",
                positiveChange: true,
            },
            reactions: {
                title: "Comments Received",
                value: "325",
                change: "55",
                positiveChange: true,
            },
            video_views: {
                title: "Impressions",
                value: "9000",
                change: "2500",
                positiveChange: true,
            },
            video_views: {
                title: "Reach",
                value: "9000",
                change: "2500",
                positiveChange: true,
            },
        }
        let state;
        this.props.name === "Facebook" ? (state = a) : (state = b)
        const dataWrapper = {
            data: { ...state }
        }
        return (
            <div className="row">
                <StatisticsBar data={dataWrapper.data} />
            </div>
        )
    }
}

export { DemoStatsBarContainer };
