import React, { Component, Fragment } from "react";
import { func, number, string } from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { store } from "store";
import { Button, Panel } from "common";
import {
  fetchGoogleAnalyticsAccountsV4,
  fetchGoogleAnalyticsPropertiesV4,
} from "screens/sites/helpers/google_analytics_helpers";
import { updateSite } from "screens/sites/actions";
import ThirdPartyDisconnect from "./ThirdPartyDisconnect";
import { GA4ConnectModal } from "common/GA4ConnectModal";
import { allianceConnectAccess } from "lib/utilities";

class GoogleAnalyticsConnectV4 extends Component {
  static propTypes = {
    siteId: number,
    updateSite: func,
  };

  static defaultProps = {
    siteId: 0,
  };

  mounted = true;

  componentDidMount() {
    if (this.props.isListView) return;
    store.dispatch({
      type: "SET_GA4_CONNECT_DATA",
      payload: { accountsLoadState: true },
    });
    fetchGoogleAnalyticsAccountsV4((response) => {
      if (this.mounted) {
        store.dispatch({
          type: "SET_GA4_CONNECT_DATA",
          payload: { accounts: response.data, accountsLoadState: false },
        });
      }
    });
  }

  async componentDidUpdate(nextProps) {
    if (!this.props.siteId) return;
    if (nextProps.siteId === this.props.siteId) return;
    if (this.props.isListView) return;
    store.dispatch({
      type: "SET_GA4_CONNECT_DATA",
      payload: { accountsLoadState: true },
    });
    fetchGoogleAnalyticsAccountsV4((response) => {
      if (this.mounted) {
        store.dispatch({
          type: "SET_GA4_CONNECT_DATA",
          payload: { accounts: response.data, accountsLoadState: false },
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleAccountChange(selectedAccount, type) {
    selectedAccount =
      selectedAccount === null ? { value: "" } : selectedAccount;
    store.dispatch({
      type: "SET_GA4_CONNECT_DATA",
      payload: {
        [type]: selectedAccount.id,
        selectedAccount,
        propertiesLoadState: true,
      },
    });

    fetchGoogleAnalyticsPropertiesV4(
      this.props.siteId,
      selectedAccount.id,
      (response) => {
        if (this.mounted) {
          store.dispatch({
            type: "SET_GA4_CONNECT_DATA",
            payload: {
              properties: response.data,
              propertiesLoadState: false,
            },
          });
        }
      }
    );
  }

  handlePropertyChange(selectedProperty, type) {
    selectedProperty =
      selectedProperty === null ? { value: "" } : selectedProperty;
    store.dispatch({
      type: "SET_GA4_CONNECT_DATA",
      payload: {
        [type]: selectedProperty.id,
        selectedProperty,
      },
    });
  }

  handleResetState() {
    store.dispatch({
      type: "SET_GA4_CONNECT_DATA",
      payload: {
        account_id: null,
        property_id: null,
        selectedAccount: "",
        selectedProperty: "",
        properties: [],
        accountsLoadState: false,
        propertiesLoadState: false,
      },
    });
    store.dispatch({
      type: "SET_GA4_DISCONNECT",
      payload: this.props.siteId,
    });
  }

  render() {
    const isPrimarySite =
      this.props.primarySiteId &&
      this.props.siteId &&
      this.props.siteId === this.props.primarySiteId;
      const userRoleId = this.props.userRoleId;
      const canAccessGA4Connect = allianceConnectAccess(userRoleId)
    if (isPrimarySite) return null;
    return (
      <Fragment>
        <GA4ConnectModal
          siteId={this.props.siteId}
          isOpen={this.props.ga4ModalVisibile.includes(this.props.siteId)}
          onRequestClose={() => {
            this.handleResetState();
            store.dispatch({
              type: "SET_GA4_MODAL_VISBILITY",
              payload: this.props.siteId,
            });
          }}
          className="react-modal ga4-connect-model"
        >
          <Panel containerClass="col-md-12 p-3 ga4-center  ga4-connect-panel">
            <Panel.Header title={"Connect Google Analytics Version 4"} />
            <Panel.Body
              className="p-5l"
              style={{ display: "block" }}
              render={() => {
                return (
                  <div
                    className="clearfix"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="float-left mr-3">
                      <Select
                        placeholder="Select Account"
                        name="form-field-name"
                        value={
                          this.props.accountsLoadState
                            ? "Loading"
                            : this.props.selectedAccount
                        }
                        onChange={(item) =>
                          this.handleAccountChange(item, "account_id")
                        }
                        clearable={false}
                        options={this.props.accounts}
                        valueKey="id"
                        labelKey="display_name"
                      />
                    </div>
                    {this.props.properties &&
                    (this.props.properties || []).length ? (
                      <div className="float-left mr-3">
                        <Select
                          placeholder="Select Property"
                          name="form-field-name"
                          value={
                            this.props.propertiesLoadState
                              ? "Loading"
                              : this.props.selectedProperty
                          }
                          onChange={(item) =>
                            this.handlePropertyChange(item, "property_id")
                          }
                          clearable={false}
                          options={this.props.properties}
                          valueKey="id"
                          labelKey="display_name"
                        />
                      </div>
                    ) : null}
                    {this.props.property_id && this.props.account_id && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.updateSite(
                            this.props.siteId,
                            {
                              ga4_property_id: this.props.property_id,
                              ga4_account_id: this.props.account_id,
                            },
                            () => {
                              if (this.props.isListView) {
                                store.dispatch({
                                  type:
                                    "SET_GOOGLE_ANALYTICS_CONNECT_PROPERTIES_SITES_ARRAY",
                                  payload: {
                                    id: this.props.siteId,
                                    ga4_property_id: this.props.property_id,
                                    ga4_account_id: this.props.account_id,
                                  },
                                });
                              } else {
                                store.dispatch({
                                  type:
                                    "SET_GOOGLE_ANALYTICS_CONNECT_PROPERTIES",
                                  payload: {
                                    ga4_property_id: this.props.property_id,
                                    ga4_account_id: this.props.account_id,
                                  },
                                });
                              }
                              store.dispatch({
                                type: "SET_GA4_MODAL_VISBILITY",
                                payload: this.props.siteId,
                              });

                              store.dispatch({
                                type: "SET_GA4_CONNECT_DATA",
                                payload: {
                                  account_id: null,
                                  property_id: null,
                                  selectedAccount: "",
                                  selectedProperty: "",
                                  properties: [],
                                  propertiesLoadState: false,
                                },
                              });
                            },
                            false
                          );
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                );
              }}
            />
          </Panel>
        </GA4ConnectModal>
        {!this.props.isPublic && this.props.ga4_account_id ? (
          <Fragment>
            {(this.props.ga4ConfirmDisconnect || []).includes(
              this.props.siteId
            ) ? (
              <div
                style={{
                  float: "right",
                  marginRight: "1em",
                }}
              >
                <h3
                  style={
                    this.props.isListView
                      ? {
                          float: "right",
                          position: "relative",
                          bottom: 39,
                        }
                      : {}
                  }
                >
                  Are you sure?
                </h3>
                <div>
                  <ThirdPartyDisconnect
                    siteId={this.props.siteId}
                    type="ga4_account_id"
                    displayName="Yes"
                    ga4_account_id={this.props.ga4_account_id}
                    ga4_property_id={this.props.ga4_property_id}
                    redirectOnDisconnect={false}
                    isListView={this.props.isListView}
                    yesOrNo={true}
                    ga4ConnectOnClick={() => {
                      store.dispatch({
                        type: "SET_GA4_DISCONNECT",
                        payload: this.props.siteId,
                      });
                    }}
                  />
                  <Button
                    classes="btn-disconnect"
                    onClick={() => {
                      store.dispatch({
                        type: "SET_GA4_DISCONNECT",
                        payload: this.props.siteId,
                      });
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            ) : (
              <Button
                classes="btn-disconnect"
                onClick={() => {
                  store.dispatch({
                    type: "SET_GA4_DISCONNECT",
                    payload: this.props.siteId,
                  });
                }}
                style={
                  this.props.isListView
                    ? {
                        float: "right",
                        marginRight: "1em",
                      }
                    : {}
                }
              >
                Disconnect Google Analytics V4
              </Button>
            )}
          </Fragment>
        ) : !this.props.isPublic && canAccessGA4Connect ? (
          <Button
            classes="btn-connect"
            style={{
              float: "right",
              marginRight: "1em",
            }}
            onClick={(e) => {
              e.stopPropagation();
              store.dispatch({
                type: "SET_GA4_MODAL_VISBILITY",
                payload: this.props.siteId,
              });
            }}
          >
            Connect Google Analytics V4
          </Button>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const determineGA4Connected = (type) => {
    if (ownProps.isListView) {
      return ownProps[type] ? ownProps[type] : null;
    }
    return state.sites.show.data[type];
  };
  return {
    userRoleId: ((state.user.user_info || {}).data || {}).role_id,
    primarySiteId: state.sites.show.data.client.primary_site_id,
    siteId: ownProps.siteId ? ownProps.siteId : state.sites.show.data.id,
    ga4_account_id: determineGA4Connected("ga4_account_id"),
    ga4_property_id: determineGA4Connected("ga4_property_id"),
    ga4ModalVisibile: state.sites.show.ga4ModalVisibile,
    account_id: state.sites.show.ga4Connect.account_id,
    property_id: state.sites.show.ga4Connect.property_id,
    selectedAccount: state.sites.show.ga4Connect.selectedAccount,
    selectedProperty: state.sites.show.ga4Connect.selectedProperty,
    accounts: state.sites.show.ga4Connect.accounts,
    properties: state.sites.show.ga4Connect.properties,
    accountsLoadState: state.sites.show.ga4Connect.accountsLoadState,
    propertiesLoadState: state.sites.show.ga4Connect.propertiesLoadState,
    ga4ConfirmDisconnect: state.sites.show.ga4ConfirmDisconnect,
  };
};

export default connect(mapStateToProps, { updateSite })(
  GoogleAnalyticsConnectV4
);
