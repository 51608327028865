import { applyMiddleware, createStore, compose } from "redux";
import createHistory from "history/createBrowserHistory";
import { routerMiddleware } from "react-router-redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers/rootReducer";

// import { logger } from 'redux-logger'

import * as sitesMiddleware from "screens/sites/middlewares";
import * as todosMiddleware from "screens/todos/middlewares";

export const history = createHistory();
const historyMiddleware = routerMiddleware(history);

// eslint-disable-next-line
const isDevelopmentMode = "development" === process.env.NODE_ENV;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && isDevelopmentMode
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const middlewares = [
  thunkMiddleware,
  // logger,
  historyMiddleware,
  ...Object.values(sitesMiddleware),
  ...Object.values(todosMiddleware)
];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, enhancer);
