/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import { allStates, centroidIndexData } from "../../../lib/allStates.js";
import { scaleQuantize } from "d3-scale";
import { connect } from "react-redux";
const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

class AnalyticsGA4Map extends Component {
  render() {
    const connected =
      this.props.ga_property_id ||
      this.props.ga4_property_id ||
      this.props.isPrimarySite;
    if (!connected) return null;
    const data = this.props.data
      ? this.props.data
      : this.props.newVisitorsByState;
    let visitorsByLocationOrdered = [...(data || [])].sort((a, b) => {
      return b.newUsers - a.newUsers;
    });

    const top20NewVisitorLocations = (visitorsByLocationOrdered || []).filter(
      (n, i) => {
        if (i < 20) {
          return { ...n };
        }
      }
    );

    const domainValues = (top20NewVisitorLocations || []).reduce(
      (p, c) => {
        p.min = c.newUsers < p.min ? c.newUsers : p.min ? p.min : c.newUsers;
        p.max = c.newUsers > p.max ? c.newUsers : p.max ? p.max : c.newUsers;
        return p;
      },
      { min: undefined, max: undefined }
    );

    const colorScale = scaleQuantize()
      .domain([domainValues.min, domainValues.max])
      .range([
        "#e5e5e5",
        "#dbbfff",
        "#b680ff",
        "#7a18fc",
        "#6e00ff",
        "#5200bf",
      ]);
    return (
      <div className={"analytics-maps-container map-graph-card"}>
        <div className={"analytics-maps-map"}>
          <ComposableMap projection="geoAlbersUsa">
            <Geographies geography={geoUrl}>
              {({ geographies }) => (
                <Fragment>
                  {geographies.map((geo) => {
                    const cur = allStates.find((s) => s.val === geo.id);
                    const value = (
                      (top20NewVisitorLocations || []).find(
                        (n) => n.region === cur.name
                      ) || {}
                    ).newUsers;
                    return (
                      <Geography
                        key={geo.rsmKey}
                        stroke="#fff"
                        geography={geo}
                        fill={value ? colorScale(value) : "#dddddd"}
                      />
                    );
                  })}
                  {geographies.map((geo, i) => {
                    const centroid = centroidIndexData[i];
                    const cur = allStates.find((s) => s.val === geo.id);
                    const value = (
                      (top20NewVisitorLocations || []).find(
                        (n) => n.region === cur.name
                      ) || {}
                    ).newUsers;
                    return (
                      <g
                        fill={!offsets[cur.id] && value ? "#fff" : "#757575"}
                        key={geo.rsmKey + "-name"}
                      >
                        {cur &&
                          centroid[0] > -160 &&
                          centroid[0] < -67 &&
                          (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                            <Marker coordinates={centroid}>
                              <text y="2" fontSize={14} textAnchor="middle">
                                {cur.id}
                              </text>
                            </Marker>
                          ) : (
                            <Annotation
                              subject={centroid}
                              dx={offsets[cur.id][0]}
                              dy={offsets[cur.id][1]}
                            >
                              <text
                                x={4}
                                fontSize={14}
                                alignmentBaseline="middle"
                              >
                                {cur.id}
                              </text>
                            </Annotation>
                          ))}
                      </g>
                    );
                  })}
                </Fragment>
              )}
            </Geographies>
          </ComposableMap>
        </div>
        <div className={"analytics-maps-data"}>
          <table className="table table-hover p-5">
            <tbody>
              {(top20NewVisitorLocations || []).map((n, idx) => (
                <tr key={idx}>
                  <td>
                    {n.city} <span className={"state"}>{n.region}</span>
                  </td>
                  <td>{n.newUsers}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newVisitorsByState: state.sites.show.analyticsData.newVisitorsByState,
    ga_property_id: state.sites.show.data.ga_property_id,
    ga4_property_id: state.sites.show.data.ga4_property_id,
  };
};

export default connect(mapStateToProps, {})(AnalyticsGA4Map);
