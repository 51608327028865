export const rankingsState = {
  rankingData: {
    data: [
      {
        seID: "",
        region: "Region",
        groups: []
      }
    ]
  }
};

export const rankingsReducer = {
  FETCHING_SE_RANKINGS_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        rankingData: {
          ...state.show.rankingData,
          data: payload
        }
      }
    };
  }
};
