import _ from "lodash";
import React, { Component, Fragment } from "react";
import { func, array, number, shape, string, bool } from "prop-types";
import { connect } from "react-redux";

import { withGoogleMap, withScriptjs, GoogleMap } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { mapStyle } from "../mapStyle";
import { Card } from "common";
import { fetchGoogleAnalyticsVisitorsByState } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { store } from "store";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";
import { determineIfGA4 } from "screens/sites/actions";

import AnalyticsGA4Map from "../AnalyticsGA4Map";
const LEGEND = {
  0: { color: "#619bf9", size: 60 },
  1: { color: "#619bf9", size: 50 },
  2: { color: "#619bf9", size: 50 },
  3: { color: "#abcafc", size: 40 },
  4: { color: "#abcafc", size: 25 },
  5: { color: "#abcafc", size: 25 },
  6: { color: "#dce9fe", size: 25 },
  7: { color: "#dce9fe", size: 25 },
  8: { color: "#dce9fe", size: 25 },
  9: { color: "#dce9fe", size: 25 },
};

const MAP_URL =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBI8ED_BdoObN87xnIiipO6pBuzuwTUK3Y&v=3.exp&libraries=geometry,drawing,places";

const VisitorsByLocation = withScriptjs(
  withGoogleMap((props) => {
    (props.data || []).map((region) => {
      const { lat, lng } = region;
      const latLng = new window.google.maps.LatLng(lat, lng);

      return latLng;
    });

    return (
      <GoogleMap
        defaultZoom={4}
        defaultCenter={{ lat: 34.66609, lng: -95.184683 }}
        defaultOptions={{ styles: mapStyle }}
      >
        {_.map(props.data, (item, index) => {
          return (
            <InfoBox
              key={index}
              defaultPosition={
                new window.google.maps.LatLng(item.lat, item.lng)
              }
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div
                style={{
                  backgroundColor: LEGEND[item.position]["color"],
                  height: `${LEGEND[item.position]["size"]}px`,
                  width: `${LEGEND[item.position]["size"]}px`,
                  borderRadius: `${LEGEND[item.position]["size"] / 2}px`,
                  opacity: 0.8,
                  border: `4px solid ${LEGEND[item.position]["color"]}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {item.newUsers}
              </div>
            </InfoBox>
          );
        })}
      </GoogleMap>
    );
  })
);

class PrimarySiteAnalyticsVisitorsByLocation extends Component {
  static propTypes = {
    fetchGoogleAnalyticsVisitorsByState: func,
    newVisitorsByState: array,
    siteId: number,
    date: shape({
      month: string,
      year: string,
    }),
    isPublic: bool,
    public_hash: string,
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print"),
    expandIndex: null,
  };

  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic,
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = (nextProps, prevState) => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    if (this.state.expandIndex !== prevState.expandIndex) {
    }
    if (hasDifferentMonth || hasDifferentYear) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.props.clearCacheBool === true &&
        this.props.fetchGoogleAnalyticsVisitorsByState(
          params,
          () => {},
          this.props.clearCacheBool,
          "FETCHING_PRIMARY_SITE_GA_NEW_VISITORS_BY_STATE"
        );
    }
  };

  fetchData = (params) => {
    if (!params.siteId) return;
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsVisitorsByState(
      params,
      () => this.mounted && this.setState({ isFetching: false }),
      false,
      "FETCHING_PRIMARY_SITE_GA_NEW_VISITORS_BY_STATE"
    );
  };

  render() {
    const apiVersion = determineIfGA4({
      endDate: this.props.crmDateSelectorEndDate,
    });

    return (
      <div className="row">
        <ReportSubTitle
          text="Organic New Visitors By Location"
          classes="mt-4"
          logo={logo}
          style={{ paddingLeft: 0, marginBottom: 20 }}
        />
        <Card
          classes="col-md-12 p-0"
          isLoading={this.state.isFetching}
          hideHeader
        >
          {apiVersion === "v1" ? (
            <VisitorsByLocation
              googleMapURL={MAP_URL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              data={this.props.primarySiteNewVisitorsByStateMapData}
            />
          ) : (
            <AnalyticsGA4Map
              data={this.props.primarySiteNewVisitorsByStateMapData}
              isPrimarySite={true}
            />
          )}

          <Card
            classes="col-md-12 p-0 primary-site-locations-table"
            hideHeader
            subtitle=""
          >
            <table className="table table-hover primary-site-table p-5">
              <thead>
                <tr>
                  <th>Site Name</th>
                  <th>Top Region</th>
                  <th>Top City</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {(this.props.primarySiteNewVisitorsByStateTableData || []).map(
                  (n, i) => (
                    <Fragment key={i}>
                      <tr
                        className={
                          this.state.expandIndex === i
                            ? "nested-data-selected-row clicked-row"
                            : ""
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          /*const hash = window.location.hash
                            ? window.location.hash
                            : "";
                          window.open(`/sites/${n.id}${hash}`,'_blank');*/

                          const index = this.props.primarySiteNewVisitorsByStateTableData
                            .filter((n) => n.isNestedData !== true)
                            .findIndex((z) => z.id === n.id);

                          this.setState({
                            ...this.state,
                            expandIndex:
                              this.state.expandIndex === index ? null : index,
                          });
                          if (this.state.expandIndex === i) {
                            store.dispatch({
                              type: "CLEAR_INSERTED_TABLE_ROWS",
                              payload: {
                                siteId: n.id,
                              },
                            });
                            return;
                          }
                          if (this.state.expandIndex) {
                            store.dispatch({
                              type: "CLEAR_INSERTED_TABLE_ROWS",
                              payload: {
                                siteId: n.id,
                              },
                            });
                          }
                          store.dispatch({
                            type: "INJECT_TABLE_ROW",
                            payload: {
                              siteId: n.id,
                              expandIndex: index,
                            },
                          });
                        }}
                      >
                        {!n.isNestedData ? (
                          <Fragment>
                            <td>{n.label}</td>
                            <td>{n.topRegion}</td>
                            <td>{n.topCity}</td>
                            <td>{n.value}</td>
                          </Fragment>
                        ) : null}
                      </tr>
                      {n.isNestedData ? (
                        <tr className="nested-data-selected-row">
                          <td></td>
                          <td>{n.region}</td>
                          <td>{n.city}</td>
                          <td>{n.value.toLocaleString()}</td>
                        </tr>
                      ) : null}
                    </Fragment>
                  )
                )}
              </tbody>
            </table>
          </Card>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    date: state.sites.show.date,
    isPublic: state.sites.show.permissions.isPublic,
    public_hash: state.sites.show.data.public_hash,
    siteId: state.sites.show.data.id,
    ga_property_id: state.sites.show.data.ga_property_id,
    primarySiteNewVisitorsByStateTableData:
      state.sites.show.primarySiteAnalyticsData
        .primarySiteNewVisitorsByStateTableData,
    primarySiteNewVisitorsByStateMapData:
      state.sites.show.primarySiteAnalyticsData
        .primarySiteNewVisitorsByStateMapData,
    crmDateSelectorEndDate: state.sites.show.date.dateOneEndForCompare,
  };
};

export default connect(mapStateToProps, {
  fetchGoogleAnalyticsVisitorsByState,
})(PrimarySiteAnalyticsVisitorsByLocation);
