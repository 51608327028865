import React from "react";

const formatJSON = (str) => {
  if (typeof str === 'object') {
    return JSON.stringify(str, null, '  ');
  }
  try {
    str = JSON.stringify(JSON.parse(str), null, '  ');
  } catch(e) {}
  return str;
}

const TestCard = (props) => {
  const testSet = props.tests;

  return (
      <div className={'card'}>
        <div className={'test-set-title'}>{testSet.category}: {testSet.label || testSet.name}</div>
        <div className={'test-set-amount'}>{testSet.children.length} Test{testSet.children.length > 1 ? 's' : ''}</div>
        {testSet.children.map((row, idx2) => (
          <div key={idx2} className={'test-set-row'} onClick={e => props.onRowClick(e, row)}>
            <div className={'test-name'} onClick={e => props.onLinkClick(e, row)}>{row.message}</div>
            <div className={`patched ${row.payload.fixed ? 'fixed' : ''}`}>{row.payload.fixed === true ? 'Patched' : 'Unhandled'}</div>
            {row.payload.fixedSource && (<div className={'fixed-source'}>Changed to "{row.payload.fixedSource}"</div>)}
            <div className={'source-wrapper'}>
              {(row.payload.schema || row.payload.invalidFields) && (
                <div className={'source'}>
                  {row.payload.invalidFields && <span>Invalid fields: {row.payload.invalidFields.join(', ')}</span>}
                  {row.payload.schema && <pre>Schema: {formatJSON(row.payload.schema)}</pre>}
                </div>
              )}
              {row.payload.source && (<div className={'source'}>{row.payload.source}</div>)}
              {row.payload.img && (<img src={String(row.payload.img)[0] === '/' ? props.domain + row.payload.img : row.payload.img}></img>)}
            </div>
          </div>
        ))}
      </div>
  );
}

export default TestCard;