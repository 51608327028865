import React, { Component } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, Tooltip } from "recharts";
import { VideoViewsFixtures } from "./fixtures/PanelFixtures";
import { Panel } from "common";

class DemoVideoViews extends Component {

    render() {
        return (
            <Panel
                containerClass="col-md-6 p-3 float-left"
                isLoading={false}
                errors={null}
                padding={2}>
                <Panel.Subtitle text={"Video Views"} />
                <Panel.Body
                    render={() => {
                        return (
                            <ResponsiveContainer width="100%" height={230}>
                                <BarChart width={600} height={300} data={VideoViewsFixtures}
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="name" />
                                    <Tooltip />
                                    <Bar dataKey="value" fill="rgb(97, 155, 249)" />
                                </BarChart>
                            </ResponsiveContainer>
                        );
                    }}
                />
            </Panel>
        )
    }
}

export { DemoVideoViews };
