import React, { Component } from "react";
import facebook from "../../screens/social/images/facebook.png";
import instagram from "../../screens/social/images/instagram.png";
import twitter from "../../screens/social/images/twitter.png";


class DemoSocialMediaSitesNavBar extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 socialSite">
                        <div onClick={() => this.props.selectSocialMedia('Facebook')} className="socialMediaSiteContainer is-active">
                            <div className="socialLogo">
                                <img width="18px" height="18px" alt="facebook" src={facebook} />
                            </div>
                            <div className="socialNavTextContainer">Facebook</div>
                            <div className="socialData">
                                14k Likes
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div onClick={() => this.props.selectSocialMedia('Instagram')} className="socialMediaSiteContainer is-active">
                            <div className="socialLogo">
                                <img width="18px" height="18px" alt="instagram" src={instagram} />
                            </div>
                            <div className="socialNavTextContainer textContainWidth">Instagram</div>
                            <div className="socialData">
                                3.5K Followers
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 socialSite">
                        <div onClick={() => this.props.selectSocialMedia('Twitter')} className="socialMediaSiteContainer is-active">
                            <div className="socialLogo">
                                <img width="18px" height="18px" alt="twitter" src={twitter} />
                            </div>
                            <div className="socialNavTextContainer textContainWidth">Twitter</div>
                            <div className="socialData">
                                1.2K Followers
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { DemoSocialMediaSitesNavBar };


