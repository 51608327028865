import _ from "lodash";
import React, { Component, Fragment } from "react";
import { func, array, number, shape, string, bool } from "prop-types";
import { connect } from "react-redux";

import { withGoogleMap, withScriptjs, GoogleMap } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { mapStyle } from "./mapStyle";

import { Card } from "common";
import { fetchGoogleAnalyticsVisitorsByState } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";

import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_analytics.png";
import AnalyticsGA4Map from "./AnalyticsGA4Map";

const LEGEND = {
  0: { color: "#619bf9", size: 60 },
  1: { color: "#619bf9", size: 50 },
  2: { color: "#619bf9", size: 50 },
  3: { color: "#abcafc", size: 40 },
  4: { color: "#abcafc", size: 25 },
  5: { color: "#abcafc", size: 25 },
  6: { color: "#dce9fe", size: 25 },
  7: { color: "#dce9fe", size: 25 },
  8: { color: "#dce9fe", size: 25 },
  9: { color: "#dce9fe", size: 25 },
};

const MAP_URL =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBI8ED_BdoObN87xnIiipO6pBuzuwTUK3Y&v=3.exp&libraries=geometry,drawing,places";

const VisitorsByLocation = withScriptjs(
  withGoogleMap((props) => {
    const bounds = new window.google.maps.LatLngBounds();
    (props.data || []).map((region) => {
      const { lat, lng } = region;
      const latLng = new window.google.maps.LatLng(lat, lng);
      bounds.extend(latLng);
      return latLng;
    });

    return (
      <GoogleMap
        ref={(map) => map && map.fitBounds(bounds)}
        defaultZoom={8}
        defaultCenter={{ lat: -34.397, lng: 150.544 }}
        defaultOptions={{ styles: mapStyle }}
      >
        {_.map(props.data, (item, index) => {
          return (
            <InfoBox
              key={index}
              defaultPosition={
                new window.google.maps.LatLng(item.lat, item.lng)
              }
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div
                style={{
                  backgroundColor: LEGEND[item.position]["color"],
                  height: `${LEGEND[item.position]["size"]}px`,
                  width: `${LEGEND[item.position]["size"]}px`,
                  borderRadius: `${LEGEND[item.position]["size"] / 2}px`,
                  opacity: 0.8,
                  border: `4px solid ${LEGEND[item.position]["color"]}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {item.newUsers}
              </div>
            </InfoBox>
          );
        })}
      </GoogleMap>
    );
  })
);

class GoogleAnalyticsVisitorsByLocation extends Component {
  static propTypes = {
    fetchGoogleAnalyticsVisitorsByState: func,
    newVisitorsByState: array,
    siteId: number,
    ga_property_id: string,
    date: shape({
      month: string,
      year: string,
    }),
    isPublic: bool,
    public_hash: string,
  };

  state = {
    isFetching: false,
    showPrintView: window.location.href.includes("print"),
  };

  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic,
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = (nextProps) => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId =
      this.props.ga_property_id !== nextProps.ga_property_id;

    if (!this.props.ga_property_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.props.clearCacheBool === true &&
        this.props.fetchGoogleAnalyticsVisitorsByState(
          params,
          () => {},
          this.props.clearCacheBool
        );
    }
  };

  fetchData = (params) => {
    if (!params.siteId || !this.props.ga_property_id) return;
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsVisitorsByState(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.ga_property_id && !this.props.ga4_property_id) return null;
    let visitorsByLocationOrdered = _.clone(
      this.props.newVisitorsByState || []
    ).reverse();

    if (this.state.showPrintView) {
      return (
        <div className="row">
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="3">Organic New Visitors By Location</th>
                </tr>
              </thead>
              <tbody>
                {_.map(visitorsByLocationOrdered, (item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.region}</td>
                      <td>{item.city}</td>
                      <td>{item.newUsers}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    if (this.props.apiVersion === "v2") {
      return (
        <Fragment>
          <ReportSubTitle
            text="Organic New Visitors By Location"
            classes="mt-3"
            logo={logo}
          />
          <AnalyticsGA4Map />
        </Fragment>
      );
    }
    return (
      <div className="row">
        <ReportSubTitle
          text="Organic New Visitors By Location"
          classes="mt-3"
          logo={logo}
        />
        <Card
          classes="col-md-12 p-3 float-left"
          isLoading={this.state.isFetching}
          hideHeader
        >
          <VisitorsByLocation
            googleMapURL={MAP_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            data={this.props.newVisitorsByState}
          />

          <Card hideHeader subtitle="">
            <table className="table table-hover p-5">
              <thead>
                <tr>
                  <th>Region</th>
                  <th>City</th>
                  <th>New Users</th>
                </tr>
              </thead>

              <tbody>
                {_.map(visitorsByLocationOrdered, (item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.region}</td>
                      <td>{item.city}</td>
                      <td>{item.newUsers}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, id: siteId, ga_property_id },
      analyticsData: { newVisitorsByState },
    },
  },
}) => ({
  date,
  siteId,
  ga_property_id,
  newVisitorsByState,
  isPublic,
  public_hash,
});

export default connect(mapStateToProps, {
  fetchGoogleAnalyticsVisitorsByState,
})(GoogleAnalyticsVisitorsByLocation);
