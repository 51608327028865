export const Inqueries_Types_Mediums_Sources_History = {
    types: [
        {
            "name": "call",
            "value": 192
        },
        {
            "name": "form",
            "value": 21
        }
    ],
    mediums: [
        {
            "name": "organic",
            "value": 201
        },
        {
            "name": "referral",
            "value": 4
        },
        {
            "name": "paid",
            "value": 8
        }
    ],
    sources: [
        {
            "name": "www.studio3marketing.com",
            "value": 189
        },
        {
            "name": "google",
            "value": 15
        },
        {
            "name": "ppc ",
            "value": 3
        },
        {
            "name": "yelp.com",
            "value": 2
        },
        {
            "name": "direct",
            "value": 2
        },
        {
            "name": "m.yelp.com",
            "value": 1
        },
        {
            "name": "marcusfacialplastics.com",
            "value": 1
        }
    ],
    history: [
        {
            "name": "Apr",
            "value": 169
        },
        {
            "name": "May",
            "value": 195
        },
        {
            "name": "Jun",
            "value": 162
        },
        {
            "name": "Jul",
            "value": 145
        },
        {
            "name": "Aug",
            "value": 156
        },
        {
            "name": "Sep",
            "value": 213
        }
    ],

}

export const InqueriesTypes = {
    types: [
        {
            "name": "call",
            "value": 192
        },
        {
            "name": "form",
            "value": 21
        }
    ]
}

export const InqueriesMediums = {
    mediums: [
        {
            "name": "organic",
            "value": 201
        },
        {
            "name": "referral",
            "value": 4
        },
        {
            "name": "paid",
            "value": 8
        }
    ],
}

export const InqueriesSources = {
    sources: [
        {
            "name": "www.studio3marketing.com",
            "value": 189
        },
        {
            "name": "google",
            "value": 15
        },
        {
            "name": "ppc ",
            "value": 3
        },
        {
            "name": "yelp.com",
            "value": 2
        },
        {
            "name": "direct",
            "value": 2
        },
        {
            "name": "m.yelp.com",
            "value": 1
        },
        {
            "name": "marcusfacialplastics.com",
            "value": 1
        }
    ]
}

export const InqueriesHistory = [
    {
        "name": "Apr",
        "value": 169
    },
    {
        "name": "May",
        "value": 195
    },
    {
        "name": "Jun",
        "value": 162
    },
    {
        "name": "Jul",
        "value": 145
    },
    {
        "name": "Aug",
        "value": 156
    },
    {
        "name": "Sep",
        "value": 213
    }
]

// {
// "types": [],
// "mediums": [],
// "sources": [],
// "history": [],
// "total_leads": 213
// }
export const InqueriesData = [
    {
        "id": {
            "value": 9215793
        },
        "lead type": {
            "value": "Phone Call",
            "meta": null
        },
        "source": {
            "value": "www.studio3marketing.com"
        },
        "medium": {
            "value": "(not set)"
        },
        "date": {
            "value": "Sun, Sep 30th, 2018"
        },
        "time": {
            "value": "10:42 AM"
        }
    },
    {
        "id": {
            "value": 9215460
        },
        "lead type": {
            "value": "Phone Call",
            "meta": null
        },
        "source": {
            "value": "www.studio3marketing.com"
        },
        "medium": {
            "value": "(not set)"
        },
        "date": {
            "value": "Sun, Sep 30th, 2018"
        },
        "time": {
            "value": "9:57 AM"
        }
    },
    {
        "id": {
            "value": 9211408
        },
        "lead type": {
            "value": "Phone Call",
            "meta": null
        },
        "source": {
            "value": "www.studio3marketing.com"
        },
        "medium": {
            "value": "(not set)"
        },
        "date": {
            "value": "Sat, Sep 29th, 2018"
        },
        "time": {
            "value": "5:02 PM"
        }
    },
    {
        "id": {
            "value": 9209870
        },
        "lead type": {
            "value": "Web Form",
            "meta": null
        },
        "source": {
            "value": "m.yelp.com"
        },
        "medium": {
            "value": "referral"
        },
        "date": {
            "value": "Sat, Sep 29th, 2018"
        },
        "time": {
            "value": "1:25 PM"
        }
    },
    {
        "id": {
            "value": 9208983
        },
        "lead type": {
            "value": "Phone Call",
            "meta": null
        },
        "source": {
            "value": "www.studio3marketing.com"
        },
        "medium": {
            "value": "(not set)"
        },
        "date": {
            "value": "Sat, Sep 29th, 2018"
        },
        "time": {
            "value": "12:01 PM"
        }
    },
    {
        "id": {
            "value": 9203135
        },
        "lead type": {
            "value": "Web Form",
            "meta": null
        },
        "source": {
            "value": "google"
        },
        "medium": {
            "value": "organic"
        },
        "date": {
            "value": "Fri, Sep 28th, 2018"
        },
        "time": {
            "value": "8:51 PM"
        }
    },
]


export const DemoDataRankingSection = [{
    region: null,
    groups: [{
        "id": "281004",
        "keywords": [
            {
                "id": "10885194",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 4,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 2,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 6,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 9,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 2,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": -11,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 3,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": 7,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 1,
                        "change": 8,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 4,
                        "change": -46,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": -10,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 12,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 2,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 1,
                        "change": 6,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 7,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 3,
                        "change": -6,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": -9,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": 7,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 3,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 2,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": -39,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    }
                ],
                "name": "los angeles doctor",
                "volume": 390,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2018-03-24"
                    }
                ],
                "total_sum": 0,
                "group_id": "281004",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885173",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 4,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 2,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 3,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 2,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 6,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    }
                ],
                "name": "doctors office",
                "volume": 30,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2018-02-21"
                    }
                ],
                "total_sum": 0,
                "group_id": "281004",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885174",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 2,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 3,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 5,
                        "change": 5,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 3,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 4,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 2,
                        "change": -1,
                        "price": 0
                    }
                ],
                "name": "best medical care",
                "volume": 320,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2017-08-13"
                    }
                ],
                "total_sum": 0,
                "group_id": "281004",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885186",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 3,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 2,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 4,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 2,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 2,
                        "change": -4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": -7,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 4,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 2,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 2,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    }
                ],
                "name": "medical los angeles",
                "volume": 40,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2018-05-21"
                    }
                ],
                "total_sum": 0,
                "group_id": "281004",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885167",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 2,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 6,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 4,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 6,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 3,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 2,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 5,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": 5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 4,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 5,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 3,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 2,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 6,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 5,
                        "change": 5,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 4,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 4,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    }
                ],
                "name": "surgery los angeles",
                "volume": 40,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2017-08-29"
                    }
                ],
                "total_sum": 0,
                "group_id": "281004",
                "link": null,
                "first_check_date": "2016-07-06"
            },
        ],
        "name": "Los Angeles",
        "creation_date": null,
    },
    {
        "id": "281006",
        "keywords": [
            {
                "id": "10885251",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 9,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 6,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 3,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 2,
                        "change": -1,
                        "price": 0
                    }
                ],
                "name": "body sculpting orange county",
                "volume": 10,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2018-06-08"
                    }
                ],
                "total_sum": 0,
                "group_id": "281006",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885266",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 2,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 6,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 3,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 6,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 3,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 2,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    }
                ],
                "name": "orange county doctor",
                "volume": 10,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2017-08-14"
                    }
                ],
                "total_sum": 0,
                "group_id": "281006",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885253",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 5,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 2,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 5,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    }
                ],
                "name": "plastic surgery orange county",
                "volume": 30,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2017-10-17"
                    }
                ],
                "total_sum": 0,
                "group_id": "281006",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885245",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 2,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 3,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 3,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": -5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": 5,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 3,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": 6,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": -7,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": 6,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": -6,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 3,
                        "change": 2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": -6,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 1,
                        "change": 6,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": 4,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": -3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 6,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": 3,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    }
                ],
                "name": "skin care orange county",
                "volume": 40,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2017-08-15"
                    }
                ],
                "total_sum": 0,
                "group_id": "281006",
                "link": null,
                "first_check_date": "2016-07-06"
            },
            {
                "id": "10885249",
                "positions": [
                    {
                        "date": "2018-07-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-06",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-07",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-09",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-13",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-15",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-16",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-18",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-19",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-21",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-23",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-25",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-27",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-28",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-07-30",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-07-31",
                        "pos": 4,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-02",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-03",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-06",
                        "pos": 4,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-07",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-10",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-11",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-12",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-13",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-14",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-15",
                        "pos": 2,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-16",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-17",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-18",
                        "pos": 1,
                        "change": -2,
                        "price": 0
                    },
                    {
                        "date": "2018-08-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-21",
                        "pos": 1,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-08-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-24",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-28",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-30",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-08-31",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-01",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-02",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-03",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-04",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-05",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-06",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-07",
                        "pos": 2,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-08",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-09",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-10",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-11",
                        "pos": 4,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-12",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-13",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-14",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-15",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-16",
                        "pos": 1,
                        "change": -1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-17",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-18",
                        "pos": 3,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-19",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-20",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-21",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-22",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-23",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-24",
                        "pos": 2,
                        "change": 1,
                        "price": 0
                    },
                    {
                        "date": "2018-09-25",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-26",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-27",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-28",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-29",
                        "pos": 1,
                        "change": 0,
                        "price": 0
                    },
                    {
                        "date": "2018-09-30",
                        "pos": 1,
                        "change": -4,
                        "price": 0
                    }
                ],
                "name": "best doctor orange county",
                "volume": 10,
                "landing_pages": [
                    {
                        "url": "https://studio3marketing.com",
                        "date": "2017-08-23"
                    }
                ],
                "total_sum": 0,
                "group_id": "281006",
                "link": null,
                "first_check_date": "2016-07-06"
            },
        ],
        "name": "Orange County",
        "creation_date": null
    }
    ]
}];