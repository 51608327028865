import React, { Component } from "react";
import { node } from "prop-types";
import SubtasksList from "screens/todos/components/SubtasksList";
import SubtasksForm from "screens/todos/components/SubtasksForm";

class Subtasks extends Component {
  static List = SubtasksList;
  static Form = SubtasksForm;

  static propTypes = {
    children: node
  }

  render() {
    return (
      <section className="todo-subtasks">
        {this.props.children}
      </section>
    );
  }
}

export default Subtasks
