import React, { Component } from "react";

import { StatisticsBar } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import logo from "images/icon_adwords.png";

class DemoGoogleAdwordsOverview extends Component {
    render() {
        // Fixtures
        const totals = {
            "Cost": {
                "title": "Cost",
                "value": "$2,482.12",
                "change": "-$34.67",
                "positiveChange": false
            },
            "Clicks": {
                "title": "Clicks",
                "value": "420",
                "change": "-42",
                "positiveChange": false
            },
            "Conversions": {
                "title": "Conversions",
                "value": "12",
                "change": "-4.4",
                "positiveChange": false
            },
            "Cost_conv": {
                "title": "Cost / Conv",
                "value": "$206.84",
                "change": "-$76.04",
                "positiveChange": false
            }
        }
        const isFetching = false;
        return (
            <StatisticsBar
                renderSubtitle={() => (
                    <ReportSubTitle
                        text="Google Ads"
                        classes="pt-3 clearfix"
                        logo={logo}
                    />
                )}
                subtitle="Google Ads"
                borderColor="#53a46a"
                isLoading={isFetching}
                data={totals}
            />
        );
    }
}



export default DemoGoogleAdwordsOverview;

