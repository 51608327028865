import React from "react";
import { string, object } from "prop-types";

PanelSubtitle.propTypes = {
  text: string,
  style: object,
};

export default function PanelSubtitle(props) {
  return (
    <h3 className="panel-subtitle" style={props.style}>
      <span>
        {props.text}
        {props.bubbleValues ? (
          <div className="social-bubble-organic-paid-container">
            {(props.bubbleValues || []).map((n) => (
              <div className="social-bubble-organic-paid">{n}</div>
            ))}
          </div>
        ) : null}
      </span>
    </h3>
  );
}
