export const fbAdCampaigns = ({ dispatch }) => next => action => {
  if (!action.fromFbAdCampaigns) return next(action);

  if(action.payload.length === 0){
    const newAction = {
      ...action,
      payload: { error: "No Facebook Ads data for this date range." },
      fromFbAdCampaigns: false
    };

    dispatch(newAction);
    return;
  }

  const [firstItem] = action.payload;
  const headers = Object.keys(firstItem)
    .filter(notId)
    .filter(notConversionValues);
  const data = action.payload.map(item => {
    const result = { ...item };
    delete result["conversion_values"];

    const cpc = item.cpc ? item.cpc : "-";
    const ctr = item.ctr ? item.ctr : "-";
    const clicks = item.clicks ? item.clicks : "-";
    const visitors = item.visitors ? item.visitors : "-";
    const spend = item.spend ? item.spend : "-";

    const conversions = item.conversions
      ? item.conversions
          .reduce((accum, next) => accum + Number(next.value), 0)
          .toFixed()
      : "-";

    const roas = item.roas
      ? item.roas
          .reduce((accum, next) => accum + Number(next.value), 0)
          .toFixed(2)
      : "-";

    const conversionMeta = item.conversions
      ? item.conversions.map(conversion => ({
          type: conversion.action_type,
          value: conversion.value
        }))
      : null;

    result.name = { value: result.name };
    result.cpc = { value: cpc };
    result.clicks = { value: clicks };
    result.ctr = { value: ctr };
    result.visitors = { value: visitors };
    result.spend = { value: spend };
    result.conversions = { value: conversions, meta: conversionMeta };
    result.roas = { value: roas };

    return result;
  });

  const newAction = {
    ...action,
    payload: { headers, data, error: "" },
    fromFbAdCampaigns: false
  };


  dispatch(newAction);
}

const notId = item => item !== "id";
const notConversionValues = item => item !== "conversion_values";
