import React from "react";
import { array, func, number, string } from "prop-types";

TeamTemplates.propTypes = {
  data: array,
  handleSelectTemplate: func,
  team: string,
  selectedTemplateId: number
};

function TeamTemplates({
  data,
  handleSelectTemplate,
  team,
  selectedTemplateId
}) {
  return (
    <section className="templates-main">
      <span className="templates-heading">{`${team} Templates`}</span>
      {data &&
        data.map((template, index) => {
          return (
            <div
              className="templates-main-item"
              key={index}
              onClick={handleSelectTemplate.bind(this, template)}
              style={{
                background:
                  selectedTemplateId === template.id ? "#efefef" : "white"
              }}
            >
              {template.title}
            </div>
          );
        })}
    </section>
  );
}

export default TeamTemplates;
