import React, { Component } from "react";
import moment from "moment";
import { ResponsiveContainer, BarChart, Bar, XAxis, Tooltip } from "recharts";
import { Panel } from "common";
import { NoData } from "./NoData";

class VideoViews extends Component {
  state = {
    data: null,
    isFetching: true,
    noData: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.month !== prevProps.month) {
      this.setState({
        isFetching: true,
      });
    }
    if (prevProps.data !== this.props.data) {
      // Total views per month
      let valuesCount = 0;
      const data = [];
      const checkMonths = [];
      this.props.data[0].values.forEach((item) => {
        item.end_time = moment(item.end_time).format("MMM");
        if (!checkMonths.includes(item.end_time)) {
          checkMonths.push(item.end_time);
        }
      });
      checkMonths.forEach((item) => {
        var obj = { name: item, value: 0 };
        data.push(obj);
      });
      this.props.data[0].values.forEach((item) => {
        const index = checkMonths.indexOf(item.end_time);
        data[index].value += item.value;
        item.value === 0 && (valuesCount += 1);
      });
      // Checks start
      this.setState(function(state, props) {
        if (valuesCount < 29) {
          return {
            data: data,
            isFetching: false,
            noData: false,
          };
        } else {
          return {
            data: data,
            isFetching: false,
            noData: true,
          };
        }
        // Checks end
      });
    }
  }

  render() {
    const isLoading = this.state.isFetching;
    return this.state.noData ? (
      <NoData title={"No Video Data Available"} />
    ) : (
      <Panel
        containerClass="col-md-6 p-3 float-left"
        isLoading={isLoading}
        errors={this.props.errors}
        padding={2}
      >
        <Panel.Subtitle text={this.props.title} />
        <Panel.Body
          render={() => {
            return (
              <ResponsiveContainer width="100%" height={230}>
                <BarChart
                  width={600}
                  height={300}
                  data={this.state.data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Bar dataKey="value" fill="rgb(97, 155, 249)" />
                </BarChart>
              </ResponsiveContainer>
            );
          }}
        />
      </Panel>
    );
  }
}

export { VideoViews };
