import React, { Component } from 'react'


export default class ReportSelection extends Component {
    render() {
        return (
            <div className="report-selection-container">
                <div className="report-selection-content">Select a report</div>
            </div>
        )
    }
}
