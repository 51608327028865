import { toCurrency } from "lib/currencyHelpers";

export const amazonReports = ({ dispatch }) => next => action => {
  if (!action.fromAmazonReports) return next(action);

  let formattedData = {
    error: false,
    totalSales: { title: "Total Sales", value: toCurrency(action.payload.total_sales) },
    ordersCount: { title: "Total Orders", value: String(action.payload.orders_count) }
  };

  if(action.payload.error){
    formattedData.error = true;
  } else {
    delete formattedData.error;
  }

  const newAction = {
    ...action,
    payload: formattedData,
    fromAmazonReports: false
  };

  dispatch(newAction);
}
