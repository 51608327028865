import React, { Component } from "react";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import twitter from "../images/twitter.png";
import PropTypes from "prop-types";

function kFormatter(num) {
    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num
}
class SocialMediaSitesNavBar extends Component {
    state = {
        page_likes: {
            title: "Page Likes",
            value: "...",
            change: "...",
            positiveChange: false,
        },
        twitter_followers: {
            title: "Page Likes",
            value: null,
            change: "0",
            positiveChange: false,
        },
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            let page_likes = Object.assign({}, this.state.page_likes);
            page_likes.value = kFormatter(this.props.data[0].fan_count);
            this.setState({ page_likes });
        }
        if (this.props.twitterData !== prevProps.twitterData) {
            let twitter_followers = Object.assign({}, this.state.twitter_followers);
            twitter_followers.value = this.props.twitterData.followers_count.toLocaleString();
            this.setState({ twitter_followers });
        }
        if (this.props.twitterUsername !== prevProps.twitterUsername) {
            if (this.props.twitterUsername === null) {
                let twitter_followers = Object.assign({}, this.state.twitter_followers);
                twitter_followers.value = null;
                this.setState({ twitter_followers });
            }
        }
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 socialSite">
                        <div onClick={() => this.props.selectSocialMedia('Facebook')} className="socialMediaSiteContainer is-active">
                            <div className="socialLogo">
                                <img width="18px" height="18px" alt="facebook" src={facebook} />
                            </div>
                            <div className="socialNavTextContainer">Facebook</div>
                            <div className="socialData">
                                {
                                    this.props.fbPageId && this.props.fbPageToken ?
                                        `${this.state.page_likes.value} Likes`
                                        : 'N/A'
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div onClick={() => this.props.selectSocialMedia('Instagram')} className="socialMediaSiteContainer is-active">
                            <div className="socialLogo">
                                <img width="18px" height="18px" alt="instagram" src={instagram} />
                            </div>
                            <div className="socialNavTextContainer textContainWidth">Instagram</div>
                            <div className="socialData">
                                {
                                    this.props.followerCount && this.props.instagramBusinessId !== null ?
                                        `${this.props.followerCount} Followers`
                                        : 'N/A'
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 socialSite">
                        <div onClick={() => this.props.selectSocialMedia('Twitter')} className="socialMediaSiteContainer is-active">
                            <div className="socialLogo">
                                <img width="18px" height="18px" alt="twitter" src={twitter} />
                            </div>
                            <div className="socialNavTextContainer textContainWidth">Twitter</div>
                            <div className="socialData">
                                {
                                    this.state.twitter_followers.value !== null ?
                                        `${this.state.twitter_followers.value} Followers`
                                        : 'N/A'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SocialMediaSitesNavBar.propTypes = {
    twitterUsername: PropTypes.string,
    twitterData: PropTypes.array,
    siteId: PropTypes.number,
    fbPageId: PropTypes.string,
    fbPageToken: PropTypes.string,
    data: PropTypes.array,
    selectSocialMedia: PropTypes.func,
    followerCount: PropTypes.string,
};

export { SocialMediaSitesNavBar };


