import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { isAlliance } from '../../../lib/api';

const tooltipFormatter = (value, name, props) => {
  return name === 'Map Position' && value === 110 ? 'N/A' : value;
};

const CustomizedLegend = (props) => {
  const { payload } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'center', gridGap: 20, marginTop: 20, marginBottom: 5, position: 'relative', left: 60 }}>
      {payload.map((entry, index) => (
        <div className="rankings-linechart-legend" key={`item-${index}`}>
          <div style={{ backgroundColor: entry.color, width: 10, height: 10, borderRadius: '50%', marginRight: 8 }}></div>
          <div>{entry.value}</div>
        </div>
      ))}
    </div>
  );
};

export default class SEGroupsListItemKeyword extends Component {
  state = { displayGraph: false };

  triggerGraph = () => {
    this.setState({ displayGraph: !this.state.displayGraph });
  };

  render() {
    // const displayMapPosition = (this.props.positions || []).reduce((all, item) => {},0)
    const graphData = this.props.positions.map((el, i) => {
      const position = i === 0 && el.pos === '0' ? 100 : parseInt(el.pos, 10);
      return {
        date: moment(el.date).format('MM/DD'),
        date_sort_value: new Date(el.date),
        ['Map Position']: el.map_position === 0 || el.map_position === '0' ? null : el.map_position,
        Position: position,
      };
    });

    const graphDataFilteredByDate = _.orderBy(graphData, ['date_sort_value'], ['asc']);

    return (
      <div className="keyword-item" onClick={() => this.triggerGraph()}>
        <div className="details">
          <i className={this.props.changeClass + ' ' + this.props.movement} />
          <div className="name">
            <span>Keyword</span>
            {this.props.name}
          </div>
          <div className="position">
            <span>Position This Month</span>
            {this.props.positionThisMonth === 100 ? 'unavailable' : this.props.positionThisMonth}
          </div>
          <div className="position">
            <span>Position 3 Months Ago</span>
            {this.props.positionLastMonth === 0 ? '100+' : this.props.positionLastMonth}
          </div>
          <div className="change">
            <span>Change</span>
            {this.props.change === 'n/a' ? 'n/a' : (this.props.movement === 'positive' || this.props.movement === 'fire' ? '+' : '') + this.props.change}
          </div>
          {isAlliance === true ? (
            <div className="volume">
              <span>~Search Volume</span>
              {parseInt((this.props || {}).volume) === 0 ? 0 : (this.props || {}).volume}
            </div>
          ) : null}
        </div>
        {this.state.displayGraph ? (
          <div className="keyword-graph">
            <ResponsiveContainer height={280}>
              <LineChart width={600} height={280} data={graphDataFilteredByDate} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis tickLine={false} dataKey="date" />
                <YAxis padding={{ top: 30 }} domain={[1, 'dataMax']} tickLine={false} allowDecimals={false} reversed={true} allowDataOverflow={true} />
                <Tooltip formatter={tooltipFormatter} />
                <Legend content={CustomizedLegend} verticalAlign="bottom" layout="horizontal" align="center" />
                <CartesianGrid strokeDasharray="3 3" />
                <Line type="step" dataKey="Map Position" strokeWidth={2} stroke="#87bc72" dot={{ stroke: '#87bc72', strokeWidth: 2 }} />
                <Line type="monotone" strokeWidth={2} dataKey="Position" stroke="#619bf9" dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : null}
      </div>
    );
  }
}

SEGroupsListItemKeyword.propTypes = {
  positions: PropTypes.array,
  name: PropTypes.string,
};
