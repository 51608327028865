import React from "react";
import { func, object, string } from "prop-types";

SearchBar.propTypes = {
  value: string.isRequired,
  placeholder: string,
  handleChange: func.isRequired,
  classes: string,
  styles: object
};

SearchBar.defaultProps = {
  placeholder: "Search",
  value: "",
  styles: {}
}

function SearchBar(props) {
  return (
    <input
      type="text"
      placeholder={props.placeholder}
      className={`searchbar ${props.classes}`}
      style={props.styles}
      value={props.value}
      onChange={props.handleChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  );
}

export { SearchBar };
