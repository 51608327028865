import React, { Component } from "react";
import { func, object, number, shape, string, bool } from "prop-types";
import { connect } from "react-redux";

import { BarGraph } from "common";
import { fetchGoogleAnalyticsSocialSources } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { getTwelveMonthsAgo } from "lib/dateHelpers";

class GoogleAnalyticsSocialSourcePageViews extends Component {
  static propTypes = {
    fetchGoogleAnalyticsSocialSources: func,
    socialSources: object,
    siteId: number,
    ga_property_id: string,
    date: shape({
      month: string,
      year: string,
    }),
    public_hash: string,
    isPublic: bool,
  };

  state = { isFetching: false };
  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      hash: this.props.public_hash,
      isPublic: this.props.isPublic,
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = (nextProps) => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId =
      this.props.ga_property_id !== nextProps.ga_property_id;

    if (!this.props.ga_property_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.fetchData(params);
    }

    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: this.props.isPublic,
      };
      this.props.clearCacheBool === true &&
        this.props.fetchGoogleAnalyticsSocialSources(
          params,
          () => {},
          this.props.clearCacheBool
        );
    }
  };

  fetchData = (params) => {
    if (!params.siteId || !this.props.ga_property_id) return;
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsSocialSources(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.ga_property_id) return null;
    return (
      <BarGraph
        title="Social Source Page Views"
        yLabel="Views"
        isLoading={this.state.isFetching}
        data={this.props.socialSources}
        barSize={20}
        colSize={this.props.colSize}
      />
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, id: siteId, ga_property_id },
      analyticsData: { socialSources },
    },
  },
}) => ({
  date,
  siteId,
  ga_property_id,
  socialSources,
  isPublic,
  public_hash,
});

export default connect(mapStateToProps, { fetchGoogleAnalyticsSocialSources })(
  GoogleAnalyticsSocialSourcePageViews
);
