import { combineReducers } from "redux";
import { routerReducer as router } from "react-router-redux";

import user from "../screens/users/reducer";
import categories from "../screens/categories/reducer";
import clients from "../screens/clients/reducer";
import notes from "../screens/notes/reducer";
import sites from "../screens/sites/reducer";
import todos from "../screens/todos/reducer";
import tools from "../screens/tools/reducer";
import teams from "../screens/teams/reducer";
import templates from "../screens/templates/reducer";
import social from "../screens/social/reducer/reducer"

const rootReducer = combineReducers({
  router,
  social,
  user,
  categories,
  clients,
  notes,
  sites,
  todos,
  tools,
  teams,
  templates,
  lastVisited: (state = "/", action) => {
    switch (action.type) {
      case "@@router/LOCATION_CHANGE":
        return window.location.pathname === "/login"
          ? state
          : window.location.pathname;
      default:
        return state;
    }
  }
});

export default rootReducer;
