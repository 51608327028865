import React, { Component } from "react";
import { node } from "prop-types";
import CommentsList from "screens/todos/components/CommentsList";
import CommentsForm from "screens/todos/components/CommentsForm";

function Header() {
  return <h3>Comments</h3>;
}

class Comments extends Component {
  static Header = Header;
  static List = CommentsList;
  static Form = CommentsForm;

  static propTypes = {
    children: node
  };

  render() {
    return <section className="todo-comments">{this.props.children}</section>;
  }
}

export default Comments;
