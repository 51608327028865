import { createReducer } from "lib/utilities";

const initialState = { data: [] };

export default createReducer(initialState, {
  FETCHING_CATEGORIES_SUCCESS: (state, payload) => ({
    ...state,
    data: payload
  })
});
