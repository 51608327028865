import React from "react";

const Toggle = props => {
  return (
    <div className={"toggle-comp " + (props.toggle === true ? "is-active" : "")}>
      <span className="toggle-comp_label">{props.label}</span>
      <a className="toggle-comp_toggle" onClick={(e) => props.onChange(e)}></a>
    </div>
  )
};
export { Toggle };