import axios from "axios";
import { push } from "react-router-redux";
import { apiBase } from "lib/api";
import { dispatchHelper } from "lib/actionHelpers";
import { authLoginAndRedirect } from "screens/users/actions";

import { CREATING_PASSWORD } from "./types";

export const createPassword = params => async dispatch => {
  const dispatching = dispatchHelper(CREATING_PASSWORD);
  const url = `${apiBase}/auth/activate`;
  const response = await axios.post(url, params);
  
  try {
    dispatching(response, () => {
      dispatch(authLoginAndRedirect(response.data.token));
      dispatch(push("/"));
    });
  } catch(err) {
    const response = { status: 500 };
    dispatching(response);
  }
};
