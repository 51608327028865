import React, { Component } from "react";
import { connect } from "react-redux";
import yelp_logo from "images/yelp_logo.png";
import google_logo from "images/google_logo.png";

import fb_logo from "images/fb_logo_reviews.png";
import { ReviewPanel } from "../../../common/ReviewPanel";
import { fetchReview, fetchReviewId, saveReviewId, updateYelpBusiness, fetchYelpBusinessOptions, updateSite } from '../actions/sites_actions';
import GooglePlacesAutoComplete from "../../../common/GooglePlacesAutocomplete";
import { Confirmation } from "../../../common/Confirmation";
import { AutoCompletePopUp } from "../../../common/AutoCompletePopUp";
import { YelpSelection } from "../../../common/YelpSelection";
import { AverageRatingReviews } from "../../../common/AverageRatingReviews";
import { ReviewLoopInfo } from "../../../common/ReviewLoopInfo";
import YelpTutorial from "../../../screens/social/YelpTutorial";
import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";

const initialState = {
    isLoading: false,
    address: '',
    name: '',
    ltLng: null,
    addressConfirmed: false,
    placeId: null,
    yelpId: null,
    showInput: false,
}
class Reviews extends Component {
    state = {
        isLoading: false,
        address: '',
        name: '',
        ltLng: null,
        addressConfirmed: false,
        placeId: null,
        yelpId: null,
        showInput: false,
        manualConnectYelpVisable: false,
    }
    resetState = () => {
        this.setState(initialState);
    }

    handleAddressSelection = (address, ltLng, placeId, isValid) => {
        if (isValid === true) {
            const name = address.substring(0, address.indexOf(":")) || address.substring(0, address.indexOf(","));
            this.setState({ address: address, ltLng: ltLng, placeId: placeId, name: name });
        } else {
            this.setState({ address: address });
        }
    }
    confirmAddress = (confirmation) => {
        this.setState({ addressConfirmed: confirmation });
    }

    setYelpId = (id) => {
        let filterBusiness = this.props.sites.reviews.yelp.data.businesses.filter(item => {
            return item.id === id;
        });
        this.setState({ showInput: false });
        this.props.updateYelpBusiness(...filterBusiness);
        this.props.saveReviewId(this.props.sites.data.id, id, 'yelp');
    }

    showInput = () => {
        this.setState({ showInput: true });
    }

    displayManualConnectYelp = () => {
        this.setState({ manualConnectYelpVisable: !this.state.manualConnectYelpVisable })
    }

    componentDidMount() {
        // key=${process.env.REACT_APP_API}Y&
        if (window.google === undefined) {
            const script = document.createElement("script");
            script.setAttribute("id", "the_maps_script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE_PLACES}&v=3.exp&libraries=geometry,drawing,places`
            script.async = true;
            document.body.appendChild(script);
        }
        const { fb_page_id, fb_page_token } = this.props.sites.data;

        // Check if reviews are already stored
        this.props.fetchReviewId(this.props.sites.data.public_hash);

        // Fetch Facebook Reviews
        (fb_page_id !== null && fb_page_token !== null) && this.props.fetchReview(fb_page_id, fb_page_token, 'FACEBOOK', null, null);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.addressConfirmed !== this.state.addressConfirmed) {
            // If no yelp I.D fetch yelp options
            this.props.sites.reviews.yelp.id === null && this.props.fetchYelpBusinessOptions(this.state.name, this.state.ltLng);

            // If the google I.D is null then save the one retrieved
            this.props.sites.reviews.google.id === null && this.props.saveReviewId(this.props.sites.data.id, this.state.placeId, 'google');

            // If only one yelp business is returned save it.

            this.setState({ addressConfirmed: false, address: '', showInput: false })
        }
        if (prevProps.sites.reviews.yelp.data.businesses.length !== this.props.sites.reviews.yelp.data.businesses.length) {
            (this.props.sites.reviews.yelp.data.businesses.length === 1 && this.props.sites.reviews.yelp.id === null) && this.setYelpId(this.props.sites.reviews.yelp.data.businesses[0].id)

        }
        // Fetch Gogle review when ID is loaded and not null        
        (prevProps.sites.reviews.google.id !== this.props.sites.reviews.google.id && this.props.sites.reviews.google.id !== null) && this.props.fetchReview(null, null, 'GOOGLE', null, this.props.sites.reviews.google.id);

        // Fetch Yelp review when ID is loaded and not null        
        (prevProps.sites.reviews.yelp.id !== this.props.sites.reviews.yelp.id && this.props.sites.reviews.yelp.id !== null) && this.props.fetchReview(null, null, 'YELP', this.props.sites.reviews.yelp.id, null);

    }


    componentWillUnmount() {
        var scripts = document.getElementById('the_maps_script');
        scripts && scripts.remove(scripts);
    }

    render() {
        let api_loaded = false;
        if (window.google !== undefined) {
            api_loaded = true;
        }
        let yelpId = this.props.sites.reviews.yelp.isLoading;
        let googleId = this.props.sites.reviews.google.id;
        let fbID = this.props.sites.data.fb_page_id;
        if(this.props.sites.permissions.isPublic){
            if(!yelpId && !googleId && !fbID){
                return(
                    <ThirdPartyFallback
                    id={null}
                    type="Reviews"
                    isPublic={this.props.sites.permissions.isPublic}
                />
                )
            }
        }
        return (
            (this.props.sites.reviews.yelp.data.businesses.length >= 2) ?
                <YelpSelection setYelpId={this.setYelpId} yelpData={this.props.sites.reviews.yelp.data.businesses} /> :
                <div className="row reviews-container">
                    <YelpTutorial siteId={this.props.sites.data.id} saveReviewId={this.props.saveReviewId} manualConnectYelpVisable={this.state.manualConnectYelpVisable} displayManualConnectYelp={this.displayManualConnectYelp} />
                    <div className="row mt-5 z-index-2">
                        <div className="col-sm-12 col-md-5 offset-md-1">
                            <AverageRatingReviews
                                yelpId={this.props.sites.reviews.yelp.id}
                                googleId={this.props.sites.reviews.google.id}
                                yelpData={this.props.sites.reviews.yelp.data.businesses}
                                googleData={this.props.sites.reviews.google.data}
                                fbData={this.props.sites.reviews.fb.data}
                                fbPageId={this.props.sites.data.fb_page_id}
                            />
                        </div>
                        <div className="col-sm-6 col-md-5 panels-container">
                            <ReviewPanel
                                displayManualConnectYelp={this.displayManualConnectYelp}
                                yelpData={this.props.sites.reviews.yelp.data.businesses}
                                googleData={this.props.sites.reviews.google.data}
                                fbData={this.props.sites.reviews.fb.data}
                                siteId={this.props.sites.data.id}
                                reviewSiteId={this.props.sites.reviews.yelp.id}
                                reviewData={this.props.sites.reviews.yelp.data.businesses}
                                updateSite={this.props.updateSite}
                                isLoading={this.state.isLoading}
                                showInputFunc={this.showInput}
                                name={"Yelp"}
                                type={"yelp_business_id"}
                                logo={yelp_logo}
                                isPublic={this.props.sites.permissions.isPublic}
                            />
                            <ReviewPanel
                                yelpData={this.props.sites.reviews.yelp.data.businesses}
                                googleData={this.props.sites.reviews.google.data}
                                fbData={this.props.sites.reviews.fb.data}
                                reviewSiteId={this.props.sites.reviews.google.id}
                                siteId={this.props.sites.data.id}
                                updateSite={this.props.updateSite}
                                isLoading={this.state.isLoading}
                                showInputFunc={this.showInput}
                                name={"Google"}
                                type={"google_places_id"}
                                logo={google_logo}
                                isPublic={this.props.sites.permissions.isPublic}
                            />
                            <ReviewPanel
                                updateSite={() => { }}
                                yelpData={this.props.sites.reviews.yelp.data.businesses}
                                fbData={this.props.sites.reviews.fb.data}
                                reviewSiteId={this.props.sites.data.fb_page_id}
                                googleData={this.props.sites.reviews.google.data}
                                sLoading={this.state.isLoading}
                                name={"Facebook"}
                                data={this.props.sites.reviews.fb.data}
                                logo={fb_logo}
                                fbPageId={this.props.sites.data.fb_page_id}
                                noOptionClass={'cursor-no'}
                                isPublic={this.props.sites.permissions.isPublic}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-10 offset-md-1 z-index-2">
                            <div className="autocomplete-container">
                                {
                                    this.state.showInput && <p className="reviews-font-headline">To add business reviews, select an option from the dropdown below.</p>
                                }
                                <GooglePlacesAutoComplete
                                    showInput={this.state.showInput}
                                    handleAddressSelection={this.handleAddressSelection} apiLoaded={api_loaded}
                                />
                                <Confirmation resetState={this.resetState} confirmAddress={this.confirmAddress} address={this.state.address} />
                                <AutoCompletePopUp address={this.state.address} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ReviewLoopInfo managerInfo={this.props.sites.data.client.manager} />
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state,
    sites: {
        ...state.sites.show
    },
    social: {
        ...state.social
    }
});

const mapDispatchToProps = { fetchReview, fetchReviewId, saveReviewId, updateYelpBusiness, fetchYelpBusinessOptions, updateSite };


export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
