import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as actions from "screens/clients/actions";
import ContactItem from "screens/clients/components/ContactItem";
import { Button, Card } from "common";

class ContactsContainer extends Component {
  state = { contacts: [] };

  componentDidMount() {
    this.setState({ contacts: JSON.parse(this.props.contacts) });
  }

  addContact = () => {
    this.setState({
      contacts: [
        ...this.state.contacts,
        {
          name: "",
          title: "",
          email: "",
          altEmail: "",
          phone: "",
          altPhone: ""
        }
      ]
    });
  };

  handleInputChange = (e, index, type) => {
    const newContacts = this.state.contacts.map((contact, i) => {
      if (i !== index) return contact;
      return { ...contact, [type]: e.target.value };
    });
    this.setState({
      contacts: newContacts
    });
  };

  deleteContact = (e, index) => {
    e.preventDefault();
    let confirm = window.confirm("Are you sure you want to delete?");
    if (confirm) {
      this.setState({
        contacts: this.state.contacts.filter((s, i) => i !== index)
      });
    }
  };

  handleSaveContacts = (contacts, clientId) => {
    this.props.updateClientContacts(JSON.stringify(contacts), clientId);
  };

  renderButtonleft = () => [
    <Button
      key="button-save"
      onClick={() =>
        this.handleSaveContacts(this.state.contacts, this.props.clientId)
      }
      inverse
    >
      Save
    </Button>,
    <Button key="button-add" onClick={this.addContact} inverse>
      <i className="fas fa-plus" />
    </Button>
  ];

  render() {
    let contacts = this.state.contacts.map((item, index) => {
      return (
        <ContactItem
          handleInputChange={this.handleInputChange}
          deleteContact={this.deleteContact}
          index={index}
          key={index}
          {...item}
        />
      );
    });

    return (
      <Card
        title="Contacts"
        buttonLeft={this.renderButtonleft}
        padding={4}
        customPadding="pt-0 pb-0"
      >
        {contacts}
      </Card>
    );
  }
}

ContactsContainer.propTypes = {
  clientId: PropTypes.number,
  contacts: PropTypes.string,
  updateClientContacts: PropTypes.func
};

const connected = connect(null, actions)(ContactsContainer);

export { connected as ContactsContainer };
