import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';


class GooglePlacesAutcomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: '' };
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = (address, placeId) => {
        if (address.includes(',')) {
            geocodeByAddress(address)
                .then(results => getLatLng(results[0]))
                .then(latLng =>
                    this.props.handleAddressSelection(address, latLng, placeId, true)
                )
                .catch(error => console.error('Error', error));
        } else {
            this.props.handleAddressSelection('Please select an option from the dropdown', null, null, false)
        }
    }


    render() {
        if (this.props.apiLoaded === false) {
            return null;
        } else {
            return (
                this.props.showInput === false ? null :
                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Business...',
                                        className: 'location-search-input form-control',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
            );
        }
    }
}

export default GooglePlacesAutcomplete;