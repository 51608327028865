import React, { Component } from "react";
import { number, object } from "prop-types";
import { Link } from "react-router-dom";
import { getRouteType } from "screens/todos/helpers/todo_helpers";
import { filterResults, handleInputChange } from "lib/utilities";

class Sidebar extends Component {
  static propTypes = {
    teams: object,
    clients: object,
    followingCount: number
  };

  state = { filter: "" };

  renderTeams = (teams, fetchingTeams) => {
    if (!teams.length || fetchingTeams) {
      return (
        <section>
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
        </section>
      );
    }
    const routeType = getRouteType(window.location.pathname);
    const [, routeTypeId] = routeType;

    return teams.map(team => {
      return (
        <section key={team.id} className={`todos-sidebar__category__item${
          routeType.includes("teams") && routeTypeId === String(team.id)
            ? " active"
            : ""
          }`}>
          <Link
            className={`todos-sidebar__link`}
            to={`/todos/teams/${team.id}`}>
            {team.name}
          </Link>
        </section>
      );
    });
  };

  renderClients = ({ index: { isFetching, data } }, filter) => {
    const filteredClients = filterResults(data, filter);

    if (!data.length || isFetching) {
      return (
        <section>
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "40%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
          <div
            style={{
              height: "8px",
              width: "60%",
              backgroundColor: "#ededed",
              marginBottom: "10px"
            }}
          />
        </section>
      );
    }

    const routeType = getRouteType(window.location.pathname);
    const [, routeTypeId] = routeType;

    return filteredClients.map(client => {
      return (
        <section
          key={client.id}
          className={`todos-sidebar__category__item${
            routeType.includes("clients") &&
              routeTypeId === String(client.id)
              ? " active"
              : ""
            }`}>
          <Link
            className={`todos-sidebar__link`}
            to={`/todos/clients/${client.id}`}>
            {client.name}
          </Link>
        </section>
      );
    });
  };

  render() {
    const { teams: { data: teamData, isFetching: fetchingTeams } } = this.props;
    const routeType = getRouteType(window.location.pathname);
    return (
      <div className="todos-sidebar">
        <section className="todos-sidebar__category cypress-selector-todos-sidebar-section-myTodos">
          <h3 className="todos-sidebar__heading">My Todos</h3>
          <section
            className={`todos-sidebar__category__item${
              routeType.includes("me") ? " active" : ""
              }`}>
            <Link className={`todos-sidebar__link`} to="/todos/me">
              My Todos
            </Link>
          </section>
          <section
            className={`todos-sidebar__category__item${
              routeType.includes("following") ? " active" : ""
              }`}>
            <Link className={`todos-sidebar__link`} to="/todos/following">
              Following Todos
            </Link>
            {this.props.followingCount > 0 && (
              <span className="todos-sidebar__badge">
                {this.props.followingCount}
              </span>
            )}Ï
          </section>
        </section>

        <section className="todos-sidebar__category cypress-selector-todos-sidebar-section-teams">
          <h3 className="todos-sidebar__heading">Teams</h3>
          {this.renderTeams(teamData, fetchingTeams)}
        </section>

        <section className="todos-sidebar__category cypress-selector-todos-sidebar-clients">
          <h3 className="todos-sidebar__heading">Clients</h3>
          <input
            placeholder="Filter Clients"
            className="todos-sidebar__input"
            value={this.state.filter}
            onChange={e => handleInputChange(this, e, "filter")}
          />
          <div className="todos-sidebar__scrollcontainer">
            {this.renderClients(this.props.clients, this.state.filter)}
          </div>
        </section>
      </div>
    );
  }
}

export default Sidebar;
