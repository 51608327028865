import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { store } from "store";

import SitesNewForm from "screens/clients/components/SitesNewForm";
import { Button, Card } from "common";
import GoogleAnalyticsConnectV4 from "screens/sites/components/GoogleAnalyticsConnectV4";
import { fetchGoogleAnalyticsAccountsV4 } from "screens/sites/helpers/google_analytics_helpers";
class SitesContainer extends Component {
  state = { displayForm: false };
  mounted = true;
  onToggleDisplayForm = () =>
    this.setState((nextState) => {
      return { displayForm: !nextState.displayForm };
    });

  renderButtonleft = () => (
    <Button onClick={this.onToggleDisplayForm} inverse>
      <i className="fas fa-plus" />
    </Button>
  );
  componentDidMount() {
    store.dispatch({
      type: "SET_GA4_CONNECT_DATA",
      payload: { accountsLoadState: true },
    });
    fetchGoogleAnalyticsAccountsV4((response) => {
      if (this.mounted) {
        store.dispatch({
          type: "SET_GA4_CONNECT_DATA",
          payload: { accounts: response.data, accountsLoadState: false },
        });
      }
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let sites = this.props.data || [];
    const primarySite = (this.props.data || []).find(
      (n) => n.id === this.props.primarySiteId
    );
    if (primarySite && sites.length) {
      sites = sites.filter((n) => n.id !== this.props.primarySiteId);
      sites.unshift(primarySite);
    }
    return (
      <Card
        title="Sites"
        isLoading={this.props.isFetching}
        buttonLeft={this.renderButtonleft}
        classes="sites-list p-0"
        padding={0}
        customPadding="pt-0 pb-0"
      >
        {this.state.displayForm && (
          <SitesNewForm
            cypressSelector={".cypress-add-site-form"}
            createSite={this.props.createSite}
            onToggleDisplayForm={this.onToggleDisplayForm}
            clientId={this.props.clientId}
          />
        )}

        {sites.map((site) => (
          <div key={site.id} className={`site-item health-${site.health}`}>
            <NavLink to={`/sites/${site.id}`}>
              <h3>{site.name}</h3>
              <p className="site-url">{site.live_url}</p>
              {this.props.primarySiteId === site.id && (
                <div className={"site-item-primary-site-label"}>
                  Primary Site
                </div>
              )}
            </NavLink>
            <GoogleAnalyticsConnectV4
              siteId={site.id}
              isListView={true}
              ga4_property_id={site.ga4_property_id}
              ga4_account_id={site.ga4_account_id}
            />
          </div>
        ))}
      </Card>
    );
  }
}

SitesContainer.propTypes = {
  data: PropTypes.array,
  isFetching: PropTypes.bool,
  clientId: PropTypes.number,
  primarySiteId: PropTypes.number,
  createSite: PropTypes.func,
};

export { SitesContainer };
