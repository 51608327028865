import axios from "axios";
import { rootUrl } from "lib/api";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";

import { authLogoutAndRedirect } from "screens/users/actions";
import { FETCHING_TODOS } from "./types";

export const fetchMyTodos = (callback) => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_TODOS);
  const token = getToken();
  const url = `${rootUrl}/user/todos`;
  const headers = requestHeaders(token);

  try {
    const response = await axios.get(url, headers);
    dispatching(response);
    callback();
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchTeamTodos = (params, callback) => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_TODOS);
  const token = getToken();
  const url = `${rootUrl}/todos/teams/${params.teamId}`;
  const headers = requestHeaders(token);

  try {
    const response = await axios.get(url, headers);
    dispatching(response);
    callback();
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchClientTodos = (params, callback = () => {}) => async (
  dispatch
) => {
  const dispatching = dispatchHelper(FETCHING_TODOS);
  const token = getToken();
  const url = `${rootUrl}/todos/clients/${params.clientId}`;
  const headers = requestHeaders(token);

  try {
    const response = await axios.get(url, headers);
    dispatching(response);
    callback();
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchingFollowingTodos = (callback) => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_TODOS);
  const token = getToken();
  const url = `${rootUrl}/todos/following`;
  const headers = requestHeaders(token);

  try {
    const response = await axios.get(url, headers);
    dispatching(response);
    callback();
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};
