import moment from "moment";
import _ from "lodash";

export function structureSocialDataPieGraph(data, category) {
  const sumOfValues = [];
  data.forEach((item) => {
    var obj = {
      name: null,
      value: 0,
    };
    if (item.name === "page_engaged_users") {
      obj.name = "Total Clicks on Page";
    }
    if (item.name === "page_get_directions_clicks_logged_in_unique") {
      obj.name = "Total Clicks Get Directions";
    }
    if (item.name === "page_website_clicks_logged_in_unique") {
      obj.name = "Total Clicks Website Link";
    }
    item.values.forEach((item) => {
      obj.value += item.value;
    });
    sumOfValues.push(obj);
  });
  return sumOfValues;
}

export function sumOfVideoViews(data) {
  const sum = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.value;
  }, 0);
  return sum;
}

export function structureInstagramData_Impressions_Reach_Follower_Count(data) {
  var instagramData = {
    impressions: {
      title: "impressions",
      values: [],
    },
    follower_count: {
      title: "follower_count",
      values: [],
    },
    reach: {
      title: "reach",
      values: [],
    },
  };
  data.forEach((setOfData) => {
    setOfData.values.forEach((instaData) => {
      instaData.month_name = moment(instaData.end_time).format("MMM");
      instagramData[setOfData.name].values.push(instaData);
    });
  });
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  for (let key in instagramData) {
    instagramData[key].values.sort(function(a, b) {
      return months.indexOf(a.month_name) - months.indexOf(b.month_name);
    });
  }
  return instagramData;
}

// TODO: Refactor to one function
export function formatInsightsForSelectedDate(date) {
  let correctMonth;
  if (date.month === "0") {
    let jan = "01";
    correctMonth = moment(`${date.year}-${jan}-01`).format("YYYY-MM-DD");
  } else {
    correctMonth = moment(`${date.year}-${date.month}-01`)
      .add(1, "month")
      .format("YYYY-MM-DD");
  }

  let last_month = {
    category: "instagramInsightsCurrentMonthSelection",
    startOfMonth: moment(correctMonth).format("YYYY-MM-DD"),
    endOfMonth: moment(correctMonth)
      .endOf("month")
      .format("YYYY-MM-DD"),
  };
  let monthBefore_last_month = {
    category: "instagramOneMonthsPriorFromCurrentMonthSelection",
    startOfMonth: moment(correctMonth)
      .subtract(1, "month")
      .format("YYYY-MM-DD"),
    endOfMonth: moment(correctMonth)
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD"),
  };
  let twoMonthsBefore_last_month = {
    category: "instagramTwoMonthsPriorFromCurrentMonthSelection",
    startOfMonth: moment(correctMonth)
      .subtract(2, "months")
      .format("YYYY-MM-DD"),
    endOfMonth: moment(correctMonth)
      .subtract(2, "months")
      .endOf("month")
      .format("YYYY-MM-DD"),
  };
  let dateRanges = [
    last_month,
    monthBefore_last_month,
    twoMonthsBefore_last_month,
  ];
  return dateRanges;
}

export function monthSorter(data) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  for (let key in data) {
    data.sort(function(a, b) {
      return months.indexOf(a.name) - months.indexOf(b.name);
    });
  }
  return data;
}

export function removeNegative(number) {
  if (number[0] === "-" || number[0] === "+") {
    number = number.substring(1);
  }
  return number;
}

export const calculateInstagramChange = (data, prevMonth, currMonth) => {
  let obj = {
    change: 0,
    positiveChange: false,
  };

  let currentMonthVal = 0;
  let prevMonthVal = 0;

  data.forEach((item) => {
    if (item.month === prevMonth) {
      prevMonthVal = item.value;
    }
    if (item.month === currMonth) {
      currentMonthVal = item.value;
    }
  });

  obj.change = currentMonthVal - prevMonthVal;
  if (currentMonthVal > prevMonthVal) {
    obj.positiveChange = true;
  }

  return obj;
};
