import { createReducer } from "../../lib/utilities";

export const initialState = {
  index: {
    data: [],
  },
  show: {
    data: {
      id: null,
      sites: [],
      category: { id: "", name: "" },
      contacts: "[]",
      sbTickets: { tickets: [] },
      archived: 0,
    },
  },
};

export default createReducer(initialState, {
  FETCHING_CLIENTS_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: payload,
    },
  }),
  FETCHING_CLIENT_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: payload,
    },
  }),
  SET_GOOGLE_ANALYTICS_CONNECT_PROPERTIES_SITES_ARRAY: (state, payload) => {
    const updatedData = state.show.data.sites.map((n) => {
      if (n.id === payload.id) {
        return { ...n, ...payload };
      }
      return n;
    });
    return {
      ...state,
      show: {
        ...state.show,
        data: {
          ...state.show.data,
          sites: updatedData,
        },
      },
    };
  },
  FETCHING_SB_TICKETS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        sbTickets: payload,
      },
    },
  }),
  UPDATING_CLIENT_IMAGE_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        profile_image: payload,
      },
    },
  }),
  CREATING_SITE_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        sites: [...state.show.data.sites, payload],
      },
    },
  }),
});
