import React from "react";
import { func } from "prop-types";

PanelFooter.propTypes = {
  render: func.isRequired
}

export default function PanelFooter(props) {
  return (
    <div className="panel-footer">
      {props.render()}
    </div>
  )
}
