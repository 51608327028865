import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {Toggle, Button } from "common";
import moment from "moment";
import { DateRangePicker } from "react-dates";

class MultipleDatesCalendar extends Component {
  // static propTypes = {
  //     prop: PropTypes
  // }

  state = {
    checked: false,
    dateONE_START: this.props.selectedDates.dateONE_START,
    dateONE_END: this.props.selectedDates.dateONE_END,
    dateONE_START_ACCOUNT_OVERVIEW: this.props.selectedDates.dateONE_START,
    dateONE_END_ACCOUNT_OVERVIEW: this.props.selectedDates.dateONE_END,
    dateTWO_START_ACCOUNT_OVERVIEW: null,
    dateTWO_END_ACCOUNT_OVERVIEW: null,
    dateTWO_START: null,
    dateTWO_END: null,
    focusedInput: null,
    focusedInputCalendarTwo: null
  };

  handleCheckboxClick = () => {
    this.setState({ checked: !this.state.checked }, () => {
      this.props.liftCompareToggleState();
    });
  };

  renderSaveAndClearButton = type => {
    return (
      <div className="react-dates-set-dates-button">
        <Button classes="react-dates-save" onClick={() => this.fetchData()}>
          Update
        </Button>
      </div>
    );
  };

  handleDateChange = (dateOne, dateTwo, calendar) => {
    if (this.props.view === "accountOverview") {
      if (calendar === 1) {
        this.setState({
          dateONE_START_ACCOUNT_OVERVIEW: dateOne,
          dateONE_END_ACCOUNT_OVERVIEW: dateTwo
        });
      } else {
        this.setState({
          dateTWO_START_ACCOUNT_OVERVIEW: dateOne,
          dateTWO_END_ACCOUNT_OVERVIEW: dateTwo
        });
      }
    } else {
      if (calendar === 1) {
        this.setState(
          {
            dateONE_START: dateOne,
            dateONE_END: dateTwo
          },
          () => {
            //Callback
          }
        );
      }
      if (calendar === 2) {
        this.setState(
          {
            dateTWO_START: dateOne,
            dateTWO_END: dateTwo
          },
          () => {
            //Callback
          }
        );
      }
    }
  };

  fetchData = () => {
    let {
      dateONE_END,
      dateONE_START,
      dateTWO_END,
      dateTWO_START,
      dateTWO_START_ACCOUNT_OVERVIEW,
      dateTWO_END_ACCOUNT_OVERVIEW
    } = this.state;
    if (this.props.view === "campaignInfo") {
      // For Calendar One Campaign View
      this.props.liftDateSelections(
        dateONE_START,
        dateONE_END,
        dateTWO_START,
        dateTWO_END
      );
    } else {
      let month = moment(this.state.dateONE_START_ACCOUNT_OVERVIEW)
        .subtract(1, "months")
        .format("MM");
      let year = moment(this.state.dateONE_START_ACCOUNT_OVERVIEW).format("YYYY");
      this.props.changeOverviewCompareDates({
        month,
        year,
        dateOneStartForCompare: moment(
          this.state.dateONE_START_ACCOUNT_OVERVIEW
        ).format("YYYY-MM-DD"),
        dateOneEndForCompare: moment(
          this.state.dateONE_END_ACCOUNT_OVERVIEW
        ).format("YYYY-MM-DD")
      });
      if (
        dateTWO_START_ACCOUNT_OVERVIEW !== null &&
        dateTWO_END_ACCOUNT_OVERVIEW !== null
      )
        this.props.changeOverviewCompareDatesDateTwo({
          dateTwoStartForCompare: moment(
            this.state.dateTWO_START_ACCOUNT_OVERVIEW
          ).format("YYYY-MM-DD"),
          dateTwoEndForCompare: moment(
            this.state.dateTWO_END_ACCOUNT_OVERVIEW
          ).format("YYYY-MM-DD")
        });
    }
  };

  formatDate = date => {
    if (date === null) {
        return date;
    }
    return moment(date);
  };

  render() {
    let startDate =
      this.props.view === "accountOverview"
        ? this.formatDate(this.state.dateONE_START_ACCOUNT_OVERVIEW)
        : this.formatDate(this.state.dateONE_START);
    let endDate =
      this.props.view === "accountOverview"
        ? this.formatDate(this.state.dateONE_END_ACCOUNT_OVERVIEW)
        : this.formatDate(this.state.dateONE_END);
    let startDateTwo =
      this.props.view === "accountOverview"
        ? this.formatDate(this.state.dateTWO_START_ACCOUNT_OVERVIEW)
        : this.formatDate(this.state.dateTWO_START);
    let endDateTwo =
      this.props.view === "accountOverview"
        ? this.formatDate(this.state.dateTWO_END_ACCOUNT_OVERVIEW)
        : this.formatDate(this.state.dateTWO_END);
    return (
      <div className="multiple-calendar-container col-6">
        <Toggle
          label="Compare"
          toggle={this.state.checked}
          onChange={this.handleCheckboxClick}
        />
        <div className="multiple-dates">
          <DateRangePicker
            renderCalendarInfo={() => this.renderSaveAndClearButton(1)}
            startDate={startDate} 
            startDateId="your_unique_start_date_id" 
            endDate={endDate} 
            endDateId="your_unique_end_date_id" 
            onDatesChange={({ startDate, endDate }) =>
              this.handleDateChange(startDate, endDate, 1)
            } 
            focusedInput={this.state.focusedInput} 
            onFocusChange={focusedInput => this.setState({ focusedInput })} 
            keepOpenOnDateSelect={true}
            isOutsideRange={() => false}
            daySize={47}
            hideKeyboardShortcutsPanel={true}
            displayFormat="MMM D YYYY"
          />
          {this.state.checked && (
            <DateRangePicker
              renderCalendarInfo={() => this.renderSaveAndClearButton(2)}
              startDate={startDateTwo} 
              startDateId="your_unique_start_date_id" 
              endDate={endDateTwo} 
              endDateId="your_unique_end_date_id"
              onDatesChange={({ startDate, endDate }) =>
                this.handleDateChange(startDate, endDate, 2)
              } 
              focusedInput={this.state.focusedInputCalendarTwo}
              onFocusChange={focusedInput =>
                this.setState({ focusedInputCalendarTwo: focusedInput })
              }
              keepOpenOnDateSelect={true}
              isOutsideRange={() => false}
              daySize={47}
              hideKeyboardShortcutsPanel={true}
              displayFormat="MMM D YYYY"
            />
          )}
        </div>
      </div>
    );
  }
}

export { MultipleDatesCalendar };