import React, { Component } from "react";
import { Panel } from "common";
import ReportSubTitle from "screens/sites/components/ReportSubTitle";
import { NoData } from "./NoData";

const TopFiveCitiesTable = (props) => {
    if (props.data) {
        return props.data.map((item, index) => {
            return (
                <tr key={Math.random()}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.city}</td>
                    <td>{item.value}</td>
                </tr>
            )
        })
    } else {
        return null;
    }
}

const structureData = (data, category) => {
    let entries = Object.entries(data);
    let sorted = entries.sort((a, b) => b[1] - a[1]);
    let val;
    let formattedData = [];
    const tempArr = [];
    if (category === 'city') {
        val = 'city'
    }
    sorted.forEach(array => {
        var obj = {}
        obj[val] = array[0];
        obj['value'] = array[1];
        tempArr.push(obj);
    });
    for (let i = 0; i < 5; i++) {
        formattedData.push(tempArr[i])
    }
    return formattedData;
}

class Location extends Component {
    state = {
        topFiveCities: null,
        isFetching: true,
    }
    componentDidUpdate(prevProps) {
        if (this.props.month !== prevProps.month) {
            this.setState({
                isFetching: true,
                noData: false,
            })
        }
        if (prevProps.data !== this.props.data) {
            let page_fans_city = {}
            let checkForPageFansCity = false;
            this.props.data.forEach((item, index) => {
                item.name === "page_fans_city" && (checkForPageFansCity = true);
                item.name === "audience_city" && (checkForPageFansCity = true);
                item.name === "page_fans_city" && (page_fans_city = item);
                item.name === "audience_city" && (page_fans_city = item);

            });
            if (checkForPageFansCity === true) {
                const cities = page_fans_city.values.slice(-1)[0];

                let formattedDataCity = structureData(cities.value, 'city')
                this.setState(function (state, props) {
                    return {
                        topFiveCities: formattedDataCity,
                        isFetching: false,
                    }
                });
            } else {
                this.setState(function (state, props) {
                    return {
                        noData: true,
                        isFetching: false,
                    }
                });
            }
        }
    }
    render() {
        return (
            this.state.noData ? <NoData title={"No Location Data Available"} /> :
                <div className="row">
                    <ReportSubTitle text="Likes By City (Realtime)" classes="mt-3" logo={this.props.logo} />
                    <Panel
                        containerClass="col-md-12 p-3 float-left"
                        isLoading={this.state.isFetching}
                        errors={this.props.errors}
                        padding={0}>
                        <Panel.Body
                            render={() => {
                                return (
                                    <table className="table table-hover" >
                                        <thead className="thead">
                                            <tr>
                                                <th scope="col">Ranking</th>
                                                <th scope="col">City</th>
                                                <th scope="col"># of Likes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <TopFiveCitiesTable data={this.state.topFiveCities} />
                                        </tbody>
                                    </table >
                                );
                            }}
                        />
                    </Panel>
                </div>
        )
    }
}

export { Location };


