import React, { Component } from "react";
import { func, object } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { fetchSite, updateSite } from "screens/sites/actions";
import { Button, Card, FormGroup, FormGroupSelect } from "common";
import { handleInputChange } from "lib/utilities";
import DatePicker from "react-datepicker";

class SitesEdit extends Component {
  static propTypes = {
    fetchSite: func,
    updateSite: func,
    match: object,
    data: object
  };

  state = {
    name: "",
    liveUrl: "",
    devUrl: "",
    wcToken: "",
    wcSecret: "",
    clientId: "",
    health: "",
    isFetching: true,
    launch_date: null,
    date: moment()
  };

  async componentDidMount() {
    const { id: siteId } = this.props.match.params;
    await this.props.fetchSite({ siteId });

    const { data } = this.props;
    this.setState({
      siteId,
      name: data.name,
      liveUrl: data.live_url,
      devUrl: data.dev_url,
      launch_date: data.launch_date,
      wcToken: data.wc_token,
      wcSecret: data.wc_secret,
      clientId: data.client_id,
      health: data.health,
      isFetching: false
    });
  }

  handleDateChange = (date) => {
    const launchDateFormated = moment(date).format('YYYY-MM-DD')
    this.setState({
      date: date,
      launch_date: launchDateFormated
    });
  }

  updateSite = () => {
    const { id } = this.props.data;
    if (this.state.launch_date === null || '') {
      const dateFormatted = this.state.date.format('YYYY-MM-DD')
      this.setState({
        launch_date: dateFormatted
      }, () => {
        this.props.updateSite(id, {
          name: this.state.name,
          live_url: this.state.liveUrl,
          dev_url: this.state.devUrl,
          launch_date: this.state.launch_date,
          wc_token: this.state.wcToken,
          wc_secret: this.state.wcSecret,
          client_id: this.state.clientId,
          health: this.state.health
        });
      })
    } else {
      this.props.updateSite(id, {
        name: this.state.name,
        live_url: this.state.liveUrl,
        dev_url: this.state.devUrl,
        launch_date: this.state.launch_date,
        wc_token: this.state.wcToken,
        wc_secret: this.state.wcSecret,
        client_id: this.state.clientId,
        health: this.state.health
      });
    }
  };

  renderDatePicker = () => {
    var date = null
    if (this.state.launch_date === null || '') {
      date = moment();
    } else {
      date = moment(this.state.launch_date)
    }
    return (
      <div className="launchDateContainerEditPage">
        <label>Launch Date</label>
        <DatePicker
          ref={datePicker => (this.datePicker = datePicker)}
          placeholderText={"Click to select a date"}
          isClearable
          selected={date}
          onChange={this.handleDateChange}
          dateFormat="YYYY-MM-DD"
        />
      </div>
    )
  }

  renderHealthOptions = () => {
    const options = [
      { id: "healthy", name: "healthy" },
      { id: "warning", name: "warning" },
      { id: "danger", name: "danger" }
    ];
    return (
      <FormGroupSelect
        label="Select Health"
        value={this.state.health}
        onChange={e => handleInputChange(this, e, "health")}
        options={options}
      />
    );
  };

  /**
   * When you paste in whatconverts tokens, there's
   * sometimes weird extra characters that mess it up.
   */
  sanitize = (ctx, e, key, fn) => {
    e.target.value = String(e.target.value || '').replace(/[^a-zA-Z0-9-_]/, '');
    fn(ctx, e, key);
  }

  render() {
    let trimWCToken = this.state.wcToken.trim();
    return (
      <Card
        title="Edit Site"
        classes="col-sm-12 col-md-6 offset-md-3"
        padding={5}
        isLoading={this.state.isFetching}>
        <FormGroup
          label="Site Name"
          value={this.state.name}
          onChange={e => handleInputChange(this, e, "name")}
        />
        <FormGroup
          label="Live Url"
          value={this.state.liveUrl}
          onChange={e => handleInputChange(this, e, "liveUrl")}
        />
        <FormGroup
          label="Dev Url"
          value={this.state.devUrl}
          onChange={e => handleInputChange(this, e, "devUrl")}
        />
        <FormGroup
          label="WhatConverts Token"
          value={trimWCToken}
          onChange={e => this.sanitize(this, e, 'wcToken', handleInputChange)}
        />
        <FormGroup
          label="WhatConverts Secret"
          value={this.state.wcSecret}
          onChange={e => this.sanitize(this, e, 'wcSecret', handleInputChange)}
        />
        {this.renderHealthOptions()}
        {this.renderDatePicker()}
        <Button type="submit" onClick={this.updateSite} classes="mr-3">
          Save Edits
        </Button>
        <Button link to={`/sites/${this.state.siteId}`}>
          Cancel
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = ({ sites: { show: { data } } }) => ({ data });

const connected = connect(mapStateToProps, { fetchSite, updateSite })(
  SitesEdit
);

export { connected as SitesEdit };
