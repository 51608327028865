import React from "react";
import { func, string } from "prop-types";

FormGroup.propTypes = {
  label: string,
  type: string,
  value: string.isRequired,
  onChange: func.isRequired,
  classes: string,
  placeholder: string
};

FormGroup.defaultProps = {
  type: "text"
};

function FormGroup(props) {
    return (
      <div className={`form-group ${props.classes} ${props.cypressSelector}`}>
        {props.label && <label>{props.label}</label>}
        <input
          className={`form-control ${props.customClasses}`}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
      </div>
    );
  }

export { FormGroup };
