import axios from "axios";
import { rootUrl } from "lib/api";
import { requestHeaders, getToken } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import { authLogoutAndRedirect } from "screens/users/actions";

import {
  FETCHING_USERS,
  CREATING_USER,
  FETCHING_ONE_USER,
  UPDATE_ONE_USER,
  UPDATING_USER_IMAGE,
} from "./types";

export const fetchUsers = () => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_USERS);
  const token = getToken();
  const url = `${rootUrl}/users`;
  const headers = requestHeaders(token);

  try {
    let response = await axios.get(url, headers);
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchOneUser = (user_id) => async (dispatch) => {
  const dispatching = dispatchHelper(FETCHING_ONE_USER);
  const token = getToken();
  const url = `${rootUrl}/users/${user_id}`;
  const headers = requestHeaders(token);

  try {
    let response = await axios.get(url, headers);
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const updateOneUser = (user_id, params) => async (dispatch) => {
  const dispatching = dispatchHelper(UPDATE_ONE_USER);
  const token = getToken();
  const postParams = JSON.stringify(params);
  const url = `${rootUrl}/users/${user_id}`;
  const headers = requestHeaders(token);

  try {
    let response = await axios.post(url, postParams, headers);
    if (response) {
      window.location.reload();
    }
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const updateUserImage = (user_id, params, callback) => async (
  dispatch
) => {
  const dispatching = dispatchHelper(UPDATING_USER_IMAGE, dispatch);
  const token = getToken();
  const url = `${rootUrl}/images/${user_id}`;
  const headers = requestHeaders(token);

  try {
    let response = await axios.post(url, params, headers);
    dispatching(response, callback);
  } catch (err) {
    const response = { status: 500 };
    dispatching(response, callback);
  }
};

export const createUser = (params) => async () => {
  const dispatching = dispatchHelper(CREATING_USER);
  const token = getToken();
  const url = `${rootUrl}/users/new`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);

  try {
    const response = await axios.post(url, postParams, headers);
    dispatching(response);
  } catch (err) {
    const response = { status: 500 };
    dispatching(response);
  }
};
