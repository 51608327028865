import React from "react";
import { func, string } from "prop-types";
import { useCallRail } from "lib/api";
import CallRaiLogo from "../screens/sites/components/images/callrail_logo.svg";

PanelHeader.propTypes = {
  title: string,
  renderLeft: func,
};

PanelHeader.defaultProps = {
  renderLeft: () => {},
};

export default function PanelHeader(props) {
  if (!props.title) return null;
  const showCallRailControls = useCallRail && props.callRailCompanyId;
  return (
    <div
      style={props.color ? { background: props.color } : {}}
      className="panel-header"
    >
      {props.img ? (
        <img src={CallRaiLogo} height={15} />
      ) : (
        <h3 className="panel-title">{props.title}</h3>
      )}
      {showCallRailControls ? null : (
        <div className="panel-controls">{props.renderLeft()}</div>
      )}
    </div>
  );
}
