import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  LabelList
} from "recharts";
import { Panel } from "common";
import { isAlliance } from "../lib/api";
import DownloadIcon from "../screens/sites/components/images/download.svg";
class YearGraph extends Component {
  state = {
    graphDateRangeType: "Monthly"
  };
  static defaultProps = {
    barSize: 40,
    data: {},
    displayYOY: false,
    isPrint: false
  };

  renderTooltip = data => {
    return (
      data.payload.length && (
        <div className="custom-tooltip">
          <span className="title">{data.payload[1].payload.label}</span>
          <span>
            <b>{this.props.year}</b>: {data.payload[1].payload.currentData}
          </span>
          {this.props.displayYOY && (
            <span>
              <b>{this.props.year - 1}</b>:{" "}
              {data.payload[0].payload.previousData}
            </span>
          )}
        </div>
      )
    );
  };

  formatDataToQuarterly = data => {
    if (data.length) {
      const formatted = data.reduce(
        (a, n, i) => {
          if (i <= 2) {
            a.Q1.currentData += n.currentData;
            a.Q1.previousData += n.previousData;
          }
          if (i > 2 && i <= 5) {
            a.Q2.currentData += n.currentData;
            a.Q2.previousData += n.previousData;
          }
          if (i > 5 && i <= 8) {
            a.Q3.currentData += n.currentData;
            a.Q3.previousData += n.previousData;
          }
          if (i > 8 && i <= 11) {
            a.Q4.currentData += n.currentData;
            a.Q4.previousData += n.previousData;
          }
          return a;
        },
        {
          Q1: { currentData: 0, previousData: 0 },
          Q2: { currentData: 0, previousData: 0 },
          Q3: { currentData: 0, previousData: 0 },
          Q4: { currentData: 0, previousData: 0 }
        }
      );
      return Object.entries(formatted).map(n => ({
        label: n[0],
        currentData: n[1].currentData,
        previousData: n[1].previousData
      }));
    }
    return [];
  };

  render() {
    const isLoading = this.props.isLoading || _.isEmpty(this.props.data);

    const formattedData =
      this.state.graphDateRangeType === "Monthly"
        ? this.props.data
        : this.formatDataToQuarterly(this.props.data);
    return (
      <Panel
        containerClass={
          this.props.useAllCol
            ? "col-12 p-3 float-left yoy-graph" + this.props.className
            : "col-md-6 p-3 float-left yoy-graph" + this.props.className
        }
        isLoading={isLoading}
        errors={this.props.errors}
        padding={2}
      >
        <Panel.Subtitle text={this.props.title} />
        <Panel.Body
          render={() => {
            return (
              <Fragment>
                {this.props.downloadCSV ? (
                  <div
                    onClick={() => {
                      this.props.setDownloading(true);
                      this.props.downloadCSV(
                        this.props.downloadCSVKey,
                        this.props.data
                      );
                    }}
                    className={`primary-site-download-button ${
                      this.props.downloading ? "exporting" : ""
                    }`}
                  >
                    <img src={DownloadIcon} width={20} height={20} />
                    {this.props.downloading ? "Exporting" : "Export"}
                  </div>
                ) : null}

                {isAlliance ? (
                  <div
                    onClick={() => {
                      this.setState({
                        graphDateRangeType:
                          this.state.graphDateRangeType === "Monthly"
                            ? "Quarterly"
                            : "Monthly"
                      });
                    }}
                    className={"alliance-rollup-toggle"}
                  >
                    {this.state.graphDateRangeType}
                  </div>
                ) : null}
                <ResponsiveContainer height={230}>
                  <BarChart
                    width={600}
                    height={300}
                    data={formattedData}
                    margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                  >
                    {isAlliance ? (
                      <YAxis tickLine={false} dataKey="currentData" />
                    ) : null}
                    <XAxis tickLine={false} dataKey="label" />
                    {this.props.displayYOY && <Legend />}
                    <Tooltip content={this.renderTooltip} />
                    <Bar
                      name={this.props.year}
                      className={"yoy-bar"}
                      dataKey="currentData"
                      stackId="b"
                      fill="#619bf9"
                    >
                      {this.props.isPrint ? (
                        <LabelList
                          dataKey="currentData"
                          position="insideBottom"
                          angle={-90}
                          style={{
                            fill: "#194ea3",
                            fontSize: "10px",
                            fontWeight: "bold"
                          }}
                        />
                      ) : null}
                    </Bar>
                    <Bar
                      name={this.props.year - 1}
                      className={"yoy-bar"}
                      dataKey="previousData"
                      stackId="a"
                      fill="#dce9fe"
                    >
                      {this.props.isPrint ? (
                        <LabelList
                          dataKey="previousData"
                          position="insideBottom"
                          angle={-90}
                          style={{
                            fill: "#194ea3",
                            fontSize: "10px",
                            fontWeight: "bold"
                          }}
                        />
                      ) : null}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Fragment>
            );
          }}
        />
      </Panel>
    );
  }
}

export { YearGraph };
