export const allStates = [
  {
    id: "AL",
    val: "01",
    name: "Alabama",
  },
  {
    id: "AK",
    val: "02",
    name: "Alaska",
  },
  {
    id: "AS",
    val: "60",
    name: "American Samoa",
  },
  {
    id: "AZ",
    val: "04",
    name: "Arizona",
  },
  {
    id: "AR",
    val: "05",
    name: "Arkansas",
  },
  {
    id: "CA",
    val: "06",
    name: "California",
  },
  {
    id: "CO",
    val: "08",
    name: "Colorado",
  },
  {
    id: "CT",
    val: "09",
    name: "Connecticut",
  },
  {
    id: "DE",
    val: "10",
    name: "Delaware",
  },
  {
    id: "DC",
    val: "11",
    name: "District of Columbia",
  },
  {
    id: "FL",
    val: "12",
    name: "Florida",
  },
  {
    id: "FM",
    val: "64",
    name: "Federated States of Micronesia",
  },
  {
    id: "GA",
    val: "13",
    name: "Georgia",
  },
  {
    id: "GU",
    val: "66",
    name: "Guam",
  },
  {
    id: "HI",
    val: "15",
    name: "Hawaii",
  },
  {
    id: "ID",
    val: "16",
    name: "Idaho",
  },
  {
    id: "IL",
    val: "17",
    name: "Illinois",
  },
  {
    id: "IN",
    val: "18",
    name: "Indiana",
  },
  {
    id: "IA",
    val: "19",
    name: "Iowa",
  },
  {
    id: "KS",
    val: "20",
    name: "Kansas",
  },
  {
    id: "KY",
    val: "21",
    name: "Kentucky",
  },
  {
    id: "LA",
    val: "22",
    name: "Louisiana",
  },
  {
    id: "ME",
    val: "23",
    name: "Maine",
  },
  {
    id: "MH",
    val: "68",
    name: "Marshall Islands",
  },
  {
    id: "MD",
    val: "24",
    name: "Maryland",
  },
  {
    id: "MA",
    val: "25",
    name: "Massachusetts",
  },
  {
    id: "MI",
    val: "26",
    name: "Michigan",
  },
  {
    id: "MN",
    val: "27",
    name: "Minnesota",
  },
  {
    id: "MS",
    val: "28",
    name: "Mississippi",
  },
  {
    id: "MO",
    val: "29",
    name: "Missouri",
  },
  {
    id: "MT",
    val: "30",
    name: "Montana",
  },
  {
    id: "NE",
    val: "31",
    name: "Nebraska",
  },
  {
    id: "NV",
    val: "32",
    name: "Nevada",
  },
  {
    id: "NH",
    val: "33",
    name: "New Hampshire",
  },
  {
    id: "NJ",
    val: "34",
    name: "New Jersey",
  },
  {
    id: "NM",
    val: "35",
    name: "New Mexico",
  },
  {
    id: "NY",
    val: "36",
    name: "New York",
  },
  {
    id: "NC",
    val: "37",
    name: "North Carolina",
  },
  {
    id: "ND",
    val: "38",
    name: "North Dakota",
  },
  {
    id: "MP",
    val: "69",
    name: "Northern Mariana Islands",
  },
  {
    id: "OH",
    val: "39",
    name: "Ohio",
  },
  {
    id: "OK",
    val: "40",
    name: "Oklahoma",
  },
  {
    id: "OR",
    val: "41",
    name: "Oregon",
  },
  {
    id: "PW",
    val: "70",
    name: "Palau",
  },
  {
    id: "PA",
    val: "42",
    name: "Pennsylvania",
  },
  {
    id: "PR",
    val: "72",
    name: "Puerto Rico",
  },
  {
    id: "RI",
    val: "44",
    name: "Rhode Island",
  },
  {
    id: "SC",
    val: "45",
    name: "South Carolina",
  },
  {
    id: "SD",
    val: "46",
    name: "South Dakota",
  },
  {
    id: "TN",
    val: "47",
    name: "Tennessee",
  },
  {
    id: "TX",
    val: "48",
    name: "Texas",
  },
  {
    id: "UM",
    val: "74",
  },
  {
    id: "UT",
    val: "49",
    name: "Utah",
  },
  {
    id: "VT",
    val: "50",
    name: "Vermont",
  },
  {
    id: "VA",
    val: "51",
    name: "Virginia",
  },
  {
    id: "VI",
    val: "78",
    name: "Virgin Islands, U.S.",
  },
  {
    id: "WA",
    val: "53",
    name: "Washington",
  },
  {
    id: "WV",
    val: "54",
    name: "West Virginia",
  },
  {
    id: "WI",
    val: "55",
    name: "Wisconsin",
  },
  {
    id: "WY",
    val: "56",
    name: "Wyoming",
  },
];

export const centroidIndexData = {
  0: [-86.83015610486233, 32.7759400332063],
  1: [-152.3794192251056, 63.92441825088094],
  2: [-111.66048095979122, 34.274765465186874],
  3: [-105.5479563477572, 38.99725678837504],
  4: [-82.45494968342527, 28.60645756141258],
  5: [-83.4358588053839, 32.63776705804025],
  6: [-86.28162180603606, 39.894582485849384],
  7: [-98.37872873001918, 38.493655217417846],
  8: [-69.23567170739236, 45.34766893818724],
  9: [-71.79242034600713, 42.25426446038118],
  10: [-94.30531755931476, 46.280638057274835],
  11: [-74.663618355102, 40.178608152837874],
  12: [-79.35587324336072, 35.55215701532636],
  13: [-100.46617339754778, 47.4505267275331],
  14: [-97.49322972355021, 35.588323039468115],
  15: [-77.80013468498561, 40.878280649428916],
  16: [-100.22752309700817, 44.44450122047031],
  17: [-99.32049108119689, 31.46283926556831],
  18: [-107.55135765579202, 42.99566416717312],
  19: [-72.72710913374436, 41.620509452236],
  20: [-92.45786671044227, 38.356522745311935],
  21: [-80.62506620462601, 38.63980675041261],
  22: [-89.19686696689173, 40.042115510163725],
  23: [-106.11243637126113, 34.407271511810336],
  24: [-92.44305904799472, 34.89423904686585],
  25: [-119.47292512079134, 37.183973371028614],
  26: [-75.50015575692596, 38.99052700811173],
  27: [-77.01525985120995, 38.90376263275056],
  28: [-156.33720648961088, 20.252540261299423],
  29: [-93.49572480349727, 42.07514281735821],
  30: [-85.30200559812329, 37.53502330485151],
  31: [-76.75896188258154, 39.03217074868775],
  32: [-85.3777677964726, 44.32043723743961],
  33: [-89.6670839210745, 32.73434137635466],
  34: [-109.63302925840277, 47.05246627951662],
  35: [-71.58044498122838, 43.67908684358303],
  36: [-75.50744171594374, 42.936566173248224],
  37: [-82.79443033256767, 40.28894810011228],
  38: [-120.56200520337194, 43.9346503263153],
  39: [-86.35095230477903, 35.85802569191921],
  40: [-111.67014978137803, 39.30563386025281],
  41: [-78.81841247568624, 37.521267286926204],
  42: [-120.45642003253853, 47.384134125813944],
  43: [-89.99316336068425, 44.62584839989366],
  44: [-170.40752127779643, -14.27594410945464],
  45: [144.77446923580428, 13.443702984954792],
  46: [145.6191771259316, 15.668250078665404],
  47: [-99.79454737470336, 41.53800102904533],
  48: [-80.88970180019423, 33.90573898725433],
  49: [-66.45975608648759, 18.223206170779953],
  50: [-64.8169993552107, 18.0144085559273],
  51: [-114.61267638404077, 44.35024884122696],
  52: [-116.63138695435444, 39.329069004142134],
  53: [-72.66662403676517, 44.068857684998385],
  54: [-91.96015308980962, 31.041326066244338],
  55: [-71.55242102676087, 41.675397235541084],
};
