import React from "react";
import PropTypes from "prop-types";

const TabList = props => {
  return (
    <ul className="tabs">
      {props.tabLabels.map((tab, index) => {
        return (
          <li key={index}>
            <a
              className={props.displayTab === tab ? "active" : ""}
              onClick={e => props.handleTabSwitch(e, tab)}
            >
              {tab}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

TabList.propTypes = {
  tabLabels: PropTypes.array,
  displayTab: PropTypes.string,
  handleTabSwitch: PropTypes.func
};

export default TabList;
