import React, { Component } from 'react'
import { handleInputChange } from "lib/utilities";
import _ from "lodash";

const DropDownItem = (props) => {
    return (
        <span onClick={() => props.selectOption(props)} className="dropdown-selection">
            {props.data.Campaign || props.data.name}
        </span>
    )
}
// loader : props.data[props.nameToDisplay]
// props.data[props.nameToDisplay]
const DropdownContent = (props) => {
    let dropDownItems = props.data.map((item, i) => {
        return props.renderContent(item, i);
    });
    return (
        <div className="dropdown-list">
            {dropDownItems}
        </div>
    )
}
{/* <input onChange={(e) => handleInputChange(props.context, e, 'inputValue')} value={props.loading ? loader : props.state.inputValue} onClick={() => { props.onDropDownItemClick() }} onMouseOver={(e) => props.highLight(e, props.id)} className={props.state.highlightId === props.id ? 'highlight dropdown-selection' : 'dropdown-selection'} key={props.id} /> */ }
class DropDown extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    state = {
        selected: '',
        highlightId: '',
        dropdownOptionsVisible: false,
        inputValue: '',
        searchedValues: [],
        autoSelected: false,
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.data.length !== prevProps.data.length) {
    //         this.props.data.length > 0 && this.setState({ defaultValue: this.props.data[0].name })
    //     }
    // }

    showHideDropdown = (e, fromUserInput) => {
        !fromUserInput && this.setState({ dropdownOptionsVisible: !this.state.dropdownOptionsVisible })
    }

    highLight = (e, id) => {
        if (this.state.highlightId !== id) {
            this.setState({ highlightId: id })
        }
    }

    onDropDownItemClick = (e, id) => {
        this.setState({ selected: id })
    }

    liftSelection = () => {
        // this.props.selectAccount(this.state.)
    }

    renderDropDownContent = (data, state, loading) => {
        let dataForView = data.options;
        state.searchedValues.length > 0 && (dataForView = state.searchedValues);
        return (
            this.state.dropdownOptionsVisible ?
                <DropdownContent
                    count={null}
                    data={dataForView}
                    renderContent={(props, i) =>
                        <DropDownItem
                            key={Math.random()}
                            id={i}
                            loading={loading}
                            nameToDisplay={data.name}
                            onDropDownItemClick={this.onDropDownItemClick}
                            data={props}
                            highLight={this.highLight}
                            selectOption={this.selectOption}
                        />
                    }
                />
                : null
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.inputValue !== prevState.inputValue) {
            this.searchByText();
        }
        if(this.props.isMCC){
            if(this.props.data && this.state.autoSelected === false){
                if(this.props.data.options){
                    if(this.props.data.options.length > 0){
                        this.selectOption({data:this.props.data.options[0]})
                        this.setState({autoSelected: true})
                    }
                }
            }
        }
        if (this.state.selected !== prevState.selected) {
            if(this.props.isMCC){
                this.props.changeView("accountOverview");
            } else {
                this.props.changeView('campaignInfo');
            }
        }
    }

    // if (this.props.isMCC === true && !_.isEmpty(selectedMCCAccount)) {
    selectOption = (acct) => {
        
        if (this.props.isMCC === true) {
            this.setState({ selected: acct, inputValue: acct.data.name }, () => {
                this.props.selectCampaign(this.state.selected.data, true);
                // Set MCC ID: Of Overall Account;
            });
            if (this.props.isMCC === true && !_.isEmpty(this.props.selectedMCCAccount) && this.props.fromMCCDROPDOWN === false) {
                this.props.type === "Google" ? this.setState({ selected: acct, inputValue: acct.data.Campaign }, () => {
                    // TODO: CHECK DATA IS OPTIONAL/MANDATORY FOR .DATA
                    this.props.selectCampaign(this.state.selected.data, false);
                })
                    :
                    this.setState({ selected: acct, inputValue: acct.data.name }, () => {
                        // TODO: CHECK DATA IS OPTIONAL/MANDATORY FOR .DATA
                        this.props.selectCampaign(this.state.selected.data, false);
                    })
            }
        } else {
            this.props.type === "Google" ? this.setState({ selected: acct, inputValue: acct.data.Campaign }, () => {
                // TODO: CHECK DATA IS OPTIONAL/MANDATORY FOR .DATA
                this.props.selectCampaign(this.state.selected.data, false);
            })
                :
                this.setState({ selected: acct, inputValue: acct.data.name }, () => {
                    // TODO: CHECK DATA IS OPTIONAL/MANDATORY FOR .DATA
                    this.props.selectCampaign(this.state.selected.data, false);
                })
        }
    }

    searchByText = () => {
        if (this.props.data.length > 0) {
            let data = this.props.options
            let result;
            this.props.type === "Facebook" ? (result = data.filter(item => item.Campaign.includes(this.state.inputValue))) : (result = data.filter(item => item.name.includes(this.state.inputValue)));
            this.setState({ searchedValues: result });
        }
    }


    render() {
        let data = this.props.data;
        return (
            <div className={`dropdown-container-tresio col-${this.props.adwords_mcc_id ? "2" :"3"}`} onClick={(e) => this.showHideDropdown(e, false)}>
                <div className="selected">
                    <input placeholder={this.props.placeholder} onChange={(e) => { handleInputChange(this, e, 'inputValue'); this.showHideDropdown(e, true); }} value={this.props.loading ? "...Loading" : this.state.inputValue} />
                    <span>
                        <i className="fa fa-angle-down"></i>
                    </span>
                </div>
                {this.renderDropDownContent(data, this.state, this.props.loading)}
            </div>
        )
    }
}


export { DropDown }