import axios from "axios";
import { getToken, requestHeaders } from "lib/authHelpers";
import { rootUrl } from "lib/api";
import _ from "lodash";
import { stat } from "fs";
import moment from "moment";

export const fetchAdwordsAccounts = async (callback = () => {}) => {
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/adwords/accounts`;
  const { data: googleAdwordsAccounts } = await axios.get(url, headers);

  callback(googleAdwordsAccounts);
};

export const fetchAdwordsMCCAccounts = async (params, callback) => {
  const token = getToken();
  const headers = requestHeaders(token);
  const url = `${rootUrl}/adwords/accounts`;
  // const { data:  } = await axios.get(url, headers);
  const parameters = {
    params: {
      mcc_account_id: params
    }
  };
  try {
    let response = await axios.get(url, Object.assign({}, parameters, headers));
    callback(response);
  } catch (error) {
    // Fix
    throw { data: [], error: "Unable to retrieve MCC accounts" };
  } finally {
    return;
  }
};

export const formatAdwordsAccounts = account => ({
  value: account.name,
  label: account.name,
  id: account.id,
  mcc: account.mcc
});

const toNumber = val => {
  let toNumber = Number(val).toFixed(2);
  return toNumber;
};

const formatVal = val => {
  if (typeof val !== 'string'){
    val = String(val);
  } 
  if (val.includes("$")) {
    val = val.replace("$", "");
  }
  if (val.includes("%")) {
    val = val.replace("%", "");
  }
  if (val.includes("-")) {
    val = val.replace("-", "");
  }
  if (val.includes(",")) {
    val = val.replace(",", "");
  }
  if (val.includes("NaN")) {
    val = "0";
  }
  return val;

};

const iterateOverStats = obj => {
  _.forOwn(obj, (stats, key) => {
    stats.value = formatVal(stats.value);
    stats.value = toNumber(stats.value);
  })
    return obj;
};

const determineDatesForChangeValue = (date) => {
  let dateClone = _.cloneDeep(date);
  let hasNull = false;
  _.forOwn(dateClone, (stats, key) => {
    if(stats === null){
      hasNull = true;
    }
  });
  _.forOwn(dateClone, (stats, key) => {
    if(hasNull === false){
      let toUnix = moment(stats).unix();
      dateClone[key] = toUnix;
    }
    });
    if(hasNull === false){
      let inFuture = "";
      if(dateClone.dateOneStartForCompare > dateClone.dateTwoStartForCompare){
        inFuture = "dateOneIsGreater"
      } else {
        inFuture = "dateTwoIsGreater"
      }
      return inFuture;
    }
    return null;
}

const calculate = (dataDateOne, dataDatetwo, date) => {
  let dateStuff = determineDatesForChangeValue(date);
  _.forOwn(dataDateOne, (stats, key) => {
    stats.change = "0.00";
  });
  _.forOwn(dataDateOne, (stats, key) => {
    if (dataDatetwo.hasOwnProperty(key)) {
      let change = 0;
      let positiveChange = false;
      if(dateStuff === "dateOneIsGreater"){
        // Date One is ahead of date two
        if(Number(dataDateOne[key].value) > Number(dataDatetwo[key].value)){
          positiveChange = true;
        }
      }
      if(dateStuff === "dateTwoIsGreater"){
        // Date Two is ahead of date one
        if(Number(dataDateOne[key].value) < Number(dataDatetwo[key].value)){
          positiveChange = true;
        }
      }
      change = dataDateOne[key].value - dataDatetwo[key].value;
      let changeFixed = change.toFixed(2);
      if (changeFixed !== dataDateOne[key].value) {
        dataDateOne[key].positiveChange = positiveChange;
        dataDateOne[key].change = changeFixed;
      } else {
        dataDateOne[key].change = "0.00";
      }
    }
  });
  _.forOwn(dataDateOne, (stats, key) => {
    stats.change = formatVal(stats.change);
  })
  return dataDateOne;
};

export const calculateChange = (dataDateOne, dataDateTwo, date) => {
  let cloneDateOne = _.cloneDeep(dataDateOne);
  let cloneDatetwo = _.cloneDeep(dataDateTwo);
  let formattedDateOne = iterateOverStats(cloneDateOne);
  let formattedDateTwo = iterateOverStats(cloneDatetwo);
  
  let withCalculatedValues = calculate(formattedDateOne, formattedDateTwo, date);
  return withCalculatedValues;
};


export const calculateCampaignSales = (data) => {
  if(data){
    if(data.length > 0){
      let arr = [];
      data.forEach(item => {
        let obj = {};
        obj.name = item.Campaign;
        obj.value = 0;
        if(item.sales){
          obj.value = Number(item.sales);
        }
        arr.push(obj);
      });
      return arr;
    }
  }
  return data;
}