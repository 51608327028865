import React, { Component } from "react";
import ShopifySales from "screens/sites/components/ShopifySales";

class PrintEcommerce extends Component {
  render() {
    return (
      <div className="row">
        <div className="print-page" style={{ width: "100%" }}>
          <h1>E-Commerce Sales</h1>
          <ShopifySales />
        </div>
      </div>
    );
  }
}

export default PrintEcommerce;
