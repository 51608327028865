import React, { Component } from "react";
import { number, string } from "prop-types";
import { connect } from "react-redux";

import { Button, Panel } from "common";
import { handleConnectShopify } from "screens/sites/helpers/shopify_helpers";

class ShopifyConnect extends Component {
  static propTypes = {
    siteId: number.isRequired,
    shopify_access_token: string
  };

  state = { url: "" };

  render() {
    if (this.props.shopify_access_token) return null;
    const isDisabled = !this.state.url.includes("myshopify.com");

    return (
      <Panel containerClass="col-md-12 p-3">
        <Panel.Header title="Connect Shopify" />
        <Panel.Body
          className="p-5"
          style={{ display: "block" }}
          render={() => {
            return (
              <form
                onSubmit={e =>
                  handleConnectShopify({
                    e,
                    url: this.state.url,
                    siteId: this.props.siteId
                  }, this.setState({ url: "" }))
                }>
                <input
                  id="shopify-url"
                  type="text"
                  className="form-control mb-4"
                  placeholder="example.myshopify.com"
                  value={this.state.url}
                  onChange={e => this.setState({ url: e.target.value })}
                />
                <Button
                disabled={isDisabled}
                >
                  Connect
                </Button>
              </form>
            );
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ sites: { show: { data: { shopify_access_token } } } }) => ({
  shopify_access_token
});

export default connect(mapStateToProps)(ShopifyConnect);
