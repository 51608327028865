import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Panel } from "common";
import { chooseStatsBasedOnCampaign } from "../screens/sites/helpers/paidAdsHelpers";
import _ from "lodash";

const formatNumbers = (val, name) => {
  if (!_.isNumber(val)) {
    val = Number.parseFloat(val).toFixed(2);
  } else {
    val = val.toFixed(2);
  }
  if (isNaN(val)) {
    val = 0;
  }
  if (!_.isNumber(val)) {
    val = Number.parseFloat(val).toFixed(2);
    val = Number(val);
    val = val.toLocaleString('en');
  }

  if (name === "CPC" || name === "cpc" || name === "Spend" || name === "spend" || name === "Revenue"|| name === "revenue" || name === "Cost Conv") {
    return "$" + val;
  }
  if (name === "CTR") {
    return val + "%";
  }
  return val;
}

function StatisticsBarBody({ data, isPublic, indexOfStatCurrentlyInView, selectStatToView, isEcomm }) {
  return (
    <div className="statistics-bar-clickable">
      {data.map((item, index) => {
        return (
          <section
            key={index}
            onClick={() => selectStatToView(index, item, isEcomm)}
            className="statistics-bar-item-clickable">
            <span className={indexOfStatCurrentlyInView === index ? 'selected-clickable-stats title' : 'title'}>{item.title}</span>
            <span className="value fontDefault">{formatNumbers(item.value, item.title)}</span>
            {
              item.change !== 0 && <span className={item.positiveChange ? "{DELETE_THIS_TO_ENABLE}text-positive" : "{DELETE_THIS_TO_ENABLE}text-negative"}>{item.positiveChange ? `+${formatNumbers(item.change)}` : `${formatNumbers(item.change)}`}</span>
            }
          </section>
        );
      })}
    </div>
  );
}

class ClickableStatsBar extends Component {
  // static propTypes = {
  //   isLoading: PropTypes.bool,
  //   isPublic: PropTypes.bool,
  //   errors: PropTypes.string,
  //   data: PropTypes.object,
  //   borderColor: PropTypes.string,
  //   renderSubtitle: PropTypes.func
  // };

  static defaultProps = {
    borderColor: "white",
    renderSubtitle: () => { }
  }
  state = {
    statsDataDisplayed: [],
  }

  generateStatsBarArray = (data) => {
    let stats = chooseStatsBasedOnCampaign(this.props.selectedCampaign, this.props.type, this.props.isEcomm)
    // If Date One Values Change
    let updateStatsBar = [];
    if (!_.isEmpty(data)) {
      _.forOwn(data, (value, key) => {
        if (stats.keys.includes(key)) {
          let indexOfKey = stats.keys.indexOf(key);
          // In case of ARRAY for facebook Roas
          if (Array.isArray(value)) {
            value = value[0].value
          };
          let title = stats.names[indexOfKey];
          if(this.props.type === "Google" && title === "CPC"){
            title = "Cost Conv";
          }
          updateStatsBar.push({
            title: stats.names[indexOfKey],
            positiveChange: false,
            change: 0,
            value: value,
          })
          // if(this.props.type === "Google" && title === "Conversions"){
          //   if(this.props.isEcomm){
          //     updateStatsBar.push({
          //       title: 'Purchases',
          //       positiveChange: false,
          //       change: 0,
          //       value: value,
          //     })
          //   }
          // } else {
          //   updateStatsBar.push({
          //     title: stats.names[indexOfKey],
          //     positiveChange: false,
          //     change: 0,
          //     value: value,
          //   })
          // }
        }
      });
      return updateStatsBar
    }
    return []
  }

  calculateChange = (dataOne, dataTwo) => {
    let statsBarWithChanges = [];
    let stats = chooseStatsBasedOnCampaign(this.props.selectedCampaign, this.props.type, this.props.isEcomm)
    if (!_.isEmpty(dataOne) && !_.isEmpty(dataTwo)) {
      _.forOwn(dataOne, (value, key) => {
        let positiveChange = false;
        if (stats.keys.includes(key)) {
          let indexOfKey = stats.keys.indexOf(key);
          let parsedValDataOne = parseFloat(value);
          let parsedValDataTwo = parseFloat(dataTwo[key]);
          let change =  parsedValDataOne - parsedValDataTwo;
          var decimalFormat = change.toFixed(2);
          if (decimalFormat > 0) {
            positiveChange = true
          }
          this.state.statsDataDisplayed.forEach(item => {
            if (item.title === stats.names[indexOfKey]) {
              item.positiveChange = positiveChange;
              item.change = decimalFormat;
              statsBarWithChanges.push(item);
            }
          });
        }
      });
      this.setState({ statsDataDisplayed: statsBarWithChanges }, () => {
        this.props.stopLoader("statsBarLoader");
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.dateOneAdValuesData, prevProps.dateOneAdValuesData)) {
      let dateOneArr = this.generateStatsBarArray(this.props.dateOneAdValuesData)
      this.setState({ statsDataDisplayed: dateOneArr }, () => {
        this.props.stopLoader("statsBarLoader");
      });
    }
    if (!_.isEqual(this.props.dateTwoAdValuesData, prevProps.dateTwoAdValuesData)) {
      let dateTwoArr = this.generateStatsBarArray(this.props.dateTwoAdValuesData)
      this.calculateChange(this.props.dateOneAdValuesData, this.props.dateTwoAdValuesData);
    }
    if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
      // Temp fix so bad... SORRY!
      setTimeout(() => {
        let dateOneArr = this.generateStatsBarArray(this.props.dateOneAdValuesData)
        this.setState({ statsDataDisplayed: dateOneArr }, () => {
          this.props.stopLoader("statsBarLoader");
        });
      }, 10000);
    }
  }

  renderBody = (data, isPublic, indexOfStatCurrentlyInView, selectStatToView) => {
    if(_.isEmpty(this.props.dateOneAdValuesData)){
      return <h3 className="centerMe">No data available for selected date range.</h3>
    } else if(data.length > 0){
      return <StatisticsBarBody data={data} isEcomm={this.props.isEcomm} indexOfStatCurrentlyInView={indexOfStatCurrentlyInView} selectStatToView={selectStatToView} isPublic={isPublic} />
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.renderSubtitle()}
        <Panel
          containerClass="col-md-12 p-3"
          isLoading={this.props.isLoading}
          errors={this.props.errors}>
          <Panel.Body
            style={{
              display: this.props.isLoading ? "block" : "inherit",
              padding: this.props.isLoading ? ".2em" : "1.5em 3em",
              borderTop: `2px solid #fff`,
              //borderTop: `2px solid ${this.props.borderColor}`,
            }}
            render={() => this.renderBody(this.state.statsDataDisplayed, this.props.isPublic, this.props.indexOfStatCurrentlyInView, this.props.selectStatToView)}
          />
        </Panel>
      </Fragment>
    );
  }
}

export { ClickableStatsBar };
