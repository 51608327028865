import React from "react";
import { func, bool } from "prop-types";

Checkbox.propTypes = {
  completed: bool,
  onClick: func
};

function Checkbox(props) {
  return (
    <div className="checkbox-container">
      {props.completed && (
        <button onClick={props.onClick} className="checkbox-container__button">
          <i className="far fa-check-circle" />
        </button>
      )}
      {!props.completed && (
        <button onClick={props.onClick} className="checkbox-container__button">
          <i className="far fa-circle" />
        </button>
      )}
    </div>
  );
}

export default Checkbox;
