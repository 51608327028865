import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as actions from "screens/clients/actions";
import * as sitesActions from "screens/sites/actions";
import * as todosActions from "screens/todos/actions";
import { ViewWithLoader } from "common";
import TabList from "screens/clients/components/TabList";
import {
  ProfileContainer,
  NotesContainer,
  SitesContainer,
  TodosContainer,
  TicketsContainer,
  ContactsContainer,
} from "screens/clients/containers";
class ClientsShow extends Component {
  state = {
    isFetching: true,
    displayTab:
      window.location.hash !== ""
        ? window.location.hash.split("#")[1]
        : "sites",
  };

  mounted = true;

  componentDidMount() {
    this.props.fetchClient({ id: this.props.match.params.id }, () => {
      this.mounted && this.setState({ isFetching: false });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleTabSwitch = (e, tab) => {
    e.preventDefault();
    window.location.hash = "#" + tab;
    this.setState({ displayTab: tab });
  };

  renderTabContent() {
    const primarySiteId = this.props.clients.show.data.primary_site_id;
    const tab = this.state.displayTab;
    const { show } = this.props.clients;
    switch (tab) {
      case "notes":
        return (
          <NotesContainer
            currentUser={this.props.user.authentication.data.user_id}
            clientDetails={show.data}
            currentClient={show}
            data={this.props.clients.show.data.notes || []}
          />
        );
      case "sites":
        return (
          <SitesContainer
            data={show.data.sites}
            clientId={show.data.id}
            createSite={this.props.createSite}
            primarySiteId={primarySiteId}
          />
        );
      case "todos":
        return <TodosContainer clientId={show.data.id} />;
      case "supportbee":
        return (
          <TicketsContainer
            data={show.data.sbTickets || { tickets: [] }}
            client={show.data}
            fetchSBTickets={this.props.fetchSBTickets}
          />
        );
      case "contacts":
        return (
          <ContactsContainer
            contacts={show.data.contacts}
            clientId={show.data.id}
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    const { show } = this.props.clients;
    return (
      <ViewWithLoader isLoading={this.state.isFetching}>
        <div className="col-sm-12 col-md-4">
          <ProfileContainer />
          {/* <NotesContainer
            currentUser={this.props.user.authentication.data.user_id}
            clientDetails={show.data}
            currentClient={show}
            data={this.props.clients.show.data.notes || []}
          /> */}
        </div>
        <div className="col-sm-12 col-md-8">
          <TabList
            tabLabels={["sites", "todos", "supportbee", "contacts", "notes"]}
            displayTab={this.state.displayTab}
            handleTabSwitch={this.handleTabSwitch}
          />
          {this.props.clients.show.data.archived === 1 && (
            <div className="alert alert-warning">
              You are viewing an archived account
            </div>
          )}
          {this.renderTabContent()}
        </div>
      </ViewWithLoader>
    );
  }
}

ClientsShow.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  clients: PropTypes.object,
  fetchClient: PropTypes.func,
  createSite: PropTypes.func,
  fetchSBTickets: PropTypes.func,
};

const mapStateToProps = ({ user, clients }) => ({
  user,
  clients,
});

const connected = connect(mapStateToProps, {
  ...actions,
  ...sitesActions,
  ...todosActions,
})(ClientsShow);

export { connected as ClientsShow };
