import React, { Component } from "react";
import DemoSERankings from "./DemoSERankings";

class Rankings extends Component {
    render() {
        return (
            <div className="col-sm-12">
                <DemoSERankings />
            </div>
        );
    }
}


export default Rankings
