import { store } from "store";

export const changeDate = ({ month, year }) => ({
  type: "CHANGE_DATE",
  payload: { month, year }
});

export const changeOverviewCompareDates = ({
  dateOneStartForCompare,
  dateOneEndForCompare,
  month,
  year,
}) => {
  store.dispatch({
    type: "CHANGE_OVERVIEW_COMPARE_DATE",
    payload: {month, year, dateOneStartForCompare, dateOneEndForCompare }
  });
};

export const changeOverviewCompareDatesDateTwo = ({
  dateTwoStartForCompare,
  dateTwoEndForCompare
}) => ({
  type: "CHANGE_OVERVIEW_COMPARE_DATE_TWO",
  payload: { dateTwoStartForCompare, dateTwoEndForCompare }
});
