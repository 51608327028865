import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import { handleInputChange } from "lib/utilities";
import DatePicker from "react-datepicker";

import moment from "moment";
import { Button } from "common";
import EditNote from "./EditNote";

const linkifyPlugin = createLinkifyPlugin({ target: "_blank" });
const plugins = [linkifyPlugin];

export default class NoteForm extends Component {
  state = {
    note_title: "",
    content: "",
    editorState: EditorState.createEmpty(),
    formatted_date: "",
    dbFormat: ""
  };

  handleDateChange = date => {
    const DateFormmated = moment(date).format("DD-MM-YYYY");
    const DBFormat = moment(date).format("YYYY-MM-DD hh:mm:ss");
    this.setState({
      date: date,
      dbFormat: DBFormat,
      formatted_date: DateFormmated
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fromSitesShow) {
      if (this.state.note_title !== prevState.note_title) {
        localStorage.setItem("noteTitle", this.state.note_title);
      }
      if (this.state.content !== prevState.content) {
        let plainText = this.state.editorState.getCurrentContent().getPlainText("\u0001");
        localStorage.setItem("noteContent", plainText);
      }
    }
  }

  onEditorChange = editorState => {
    let plainText = this.state.editorState.getCurrentContent().getPlainText("\u0001");
    this.setState({ editorState, content: plainText });
  };

  componentDidMount(){
    let content = localStorage.getItem('noteContent');
    let noteTitle = localStorage.getItem('noteTitle');
    if(content){
      if(content.length > 0){
        this.setState({editorState:  EditorState.createWithContent(ContentState.createFromText(content)), note_title: noteTitle})
      }
    }
  }

  addNote = () => {
    this.props.handleCreateNote({
      note_title: this.state.note_title,
      rawContent: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
      created_at: this.state.dbFormat
    });
    localStorage.setItem("noteTitle", '');
    localStorage.setItem("noteContent", '');
  };

  render() {
    let noteButtonText =
      this.props.fromSitesShow === true ? "Save Note and Close" : "Save Note";
    if (this.props.editing === true) {
      return (
        <EditNote
          handleEditNote={this.props.handleEditNote}
          editNote={this.props.editNote}
          hideForm={this.props.hideForm}
          notes={this.props.notes}
          editId={this.props.editId}
        />
      );
    } else {
      return (
        <div className={`p-4 ${this.props.classes}`}>
          <div className={`add-note-container `}>
            <label className="note-form-label">Title</label>
            <input
              className={"add-note-form"}
              type={"text"}
              value={this.state.note_title}
              onChange={e => handleInputChange(this, e, "note_title")}
              placeholder={"Add Title"}
            />
            {this.props.fromSitesShow === false && (
              <div>
                <label className="note-form-label">Date</label>
                <DatePicker
                  ref={datePicker => (this.datePicker = datePicker)}
                  placeholderText={"Click to select a date"}
                  isClearable
                  className={"hover-pointer"}
                  selected={this.state.date}
                  onChange={this.handleDateChange}
                  dateFormat="MM/DD/YYYY"
                />
              </div>
            )}
            <label className="note-form-label">Notes</label>
            <Editor
              textAlignment="left"
              stripPastedStyles={true}
              placeholder="Add long form notes"
              onChange={this.onEditorChange}
              plugins={plugins}
              editorState={this.state.editorState}
              onEditorChange={this.onEditorChange}
            />
          </div>
          <Button classes={"mt-1 mb-1"} onClick={this.addNote}>
            {noteButtonText}
          </Button>
          <Button
            classes={"ml-2 mt-1 mb-1 btn-disabled font-black"}
            onClick={this.props.hideForm}
          >
            Cancel
          </Button>
        </div>
      );
    }
  }
}

NoteForm.propTypes = {
  handleCreateNote: PropTypes.func,
  hideForm: PropTypes.func
};
