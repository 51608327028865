import { toCurrency } from "lib/currencyHelpers";
import _ from "lodash";
import { format } from "path";

export const klaviyoAccount = ({ dispatch }) => next => action => {
  if (!action.fromFetchKlaviyoAccount) return next(action);

  let formattedData = [];

  for (let [key, value] of Object.entries(action.payload)) {
    let newKey = key.replace(/_/g, " ").replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    formattedData.push({
      title: newKey,
      value: value
    });
  }

  const newAction = {
    ...action,
    payload: formattedData,
    fromFetchKlaviyoAccount: false
  };

  dispatch(newAction);
};

export const mailchimpCampaigns = ({ dispatch }) => next => action => {
  if (!action.fromMailchimpCampaigns) return next(action);
  let formattedData = action.payload.reduce((all, item, index) => {
    let obj = {};
    obj.name = item.campaign_title;
    obj.id = item.id;
    obj.sent = item.emails_sent || 0;
    obj.click_rate = item.clicks.click_rate * 100;
    obj.click_rate = obj.click_rate.toFixed(2) + "%" || 0;
    obj.total_clicks = item.clicks.clicks_total || 0;
    obj.unique_clicks = item.clicks.unique_clicks || 0;
    obj.subscriber_clicks = item.clicks.unique_subscriber_clicks || 0;
    obj.open_rate = item.opens.open_rate * 100;
    obj.open_rate = obj.open_rate.toFixed(2) + "%" || 0;
    obj.total_opens = item.opens.opens_total || 0;
    all.push(obj);
    return all;
  }, []);

  const newAction = {
    ...action,
    payload: formattedData,
    fromMailchimpCampaigns: false
  };

  dispatch(newAction);
};
