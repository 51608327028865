import React, { Component } from "react";
import { array, func } from "prop-types";
import { connect } from "react-redux";

import { createClient } from "screens/clients/actions";
import { fetchUsers } from "screens/users/actions";
import { fetchCategories } from "screens/categories/actions";
import {Button, Card, FormGroup, FormGroupSelect, Toggle} from "common";
import { handleInputChange } from "lib/utilities";

class ClientsNew extends Component {
  static propTypes = {
    createClient: func,
    fetchUsers: func,
    fetchCategories: func,
    categories: array,
    users: array
  };

  state = {
    client_name: "",
    categories_id: "",
    manager_id: "",
    supportbee_label: ""
  };

  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchCategories();
  }

  handleSubmit = () => {
    this.props.createClient({
      client_name: this.state.client_name,
      manager_id: this.state.manager_id,
      categories_id: this.state.categories_id,
      supportbee_label: this.state.supportbee_label
    });
  };

  renderCategories = () => (
    <FormGroupSelect
      label="Category"
      value={this.state.categories_id}
      onChange={e => handleInputChange(this, e, "categories_id")}
      options={this.props.categories}
    />
  );

  renderUsers = () => {
    const options = this.props.users.map(user => {
      return {
        id: user.id,
        name: `${user.first_name} ${user.last_name} - ${user.job}`
      };
    });

    return (
      <FormGroupSelect
        label="Account Manager"
        value={this.state.manager_id}
        cypressSelector={"cypress-account-manager"}
        onChange={e => handleInputChange(this, e, "manager_id")}
        options={options}
      />
    );
  };

  render() {
    const isDisabled =
      this.state.client_name === "" ||
      this.state.categories_id === "" ||
      this.state.manager_id === "" ||
      this.state.supportbee_label === "";

    return (
      <Card
        title="Create Client"
        classes="col-sm-12 col-md-6 offset-md-3"
        padding={5}
      >
        <FormGroup
          label="Client Name"
          cypressSelector={"cypress-client-name-form"}
          id="client-name-form"
          value={this.state.client_name}
          onChange={e => handleInputChange(this, e, "client_name")}
        />
        {this.renderCategories()}
        {this.renderUsers()}
        <FormGroup
          label="SupportBee Label"
          cypressSelector={"cypress-supportbee"}
          value={this.state.supportbee_label}
          onChange={e => handleInputChange(this, e, "supportbee_label")}
        />
        <Button
          onClick={this.handleSubmit}
          cypressSelector={"cypress-create-client-button"}
          disabled={isDisabled}
          style={{ marginTop: "10px" }}
        >
          Create Client
        </Button>
        <Button
          link to={`/clients`}
          style={{ marginLeft: "10px" }}
        >
          Cancel
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user.index.data,
  clients: state.clients,
  categories: state.categories.data
});

const mapDispatchToProps = { createClient, fetchUsers, fetchCategories }

const connected = connect(mapStateToProps, mapDispatchToProps)(ClientsNew);

export { connected as ClientsNew };
