import React, { Component } from "react";
import { func, number, string, object, shape } from "prop-types";
import { connect } from "react-redux";
import { getStartDate } from "screens/sites/helpers/date_helpers";
import { updateAmazonIntegration, fetchAmazonReports } from "screens/sites/actions";
import { Button, Panel } from "common";

class AmazonConnect extends Component {
  static propTypes = {
    amazon_integration: object,
    updateAmazonIntegration: func,
    fetchAmazonReports: func,
    date: shape({
      month: string,
      year: string
    }),
    siteId: number
  };

  state = {
    auth_token: "",
    marketplace_id: "",
    merchant_id: ""
  };

  handleConnectAmazon = e => {
    e.preventDefault();
    this.props.updateAmazonIntegration(
      {
        siteId: this.props.siteId,
        auth_token: this.state.auth_token,
        marketplace_id: this.state.marketplace_id,
        merchant_id: this.state.merchant_id
      },
      () => {
        const params = {
          siteId: this.props.siteId,
          startDate: getStartDate(this.props.date)
        };
        this.props.fetchAmazonReports(
          params,
          () => {}
        );
      }
    );
  };

  render() {
    if (this.props.amazon_integration.merchant_id) return null;
    const isDisabled =
      this.state.auth_token === "" ||
      this.state.marketplace_id === "" ||
      this.state.merchant_id === "";

    return (
      <Panel containerClass="col-md-12 p-3">
        <Panel.Header title="Connect Amazon" />
        <Panel.Body
          className="p-5"
          style={{ display: "block" }}
          render={() => {
            return (
              <form>
                <input
                  className="form-control mb-4"
                  placeholder="Merchant ID"
                  value={this.state.merchant_id}
                  onChange={e => this.setState({ merchant_id: e.target.value })}
                />
                <input
                  className="form-control mb-4"
                  placeholder="Marketplace ID"
                  value={this.state.marketplace_id}
                  onChange={e =>
                    this.setState({ marketplace_id: e.target.value })
                  }
                />
                <input
                  className="form-control mb-4"
                  placeholder="Auth Token"
                  value={this.state.auth_token}
                  onChange={e => this.setState({ auth_token: e.target.value })}
                />

                <Button
                  onClick={this.handleConnectAmazon}
                  disabled={isDisabled}
                >
                  Connect
                </Button>
              </form>
            );
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      data: { id: siteId, amazon_integration }
    }
  }
}) => ({
  amazon_integration,
  date,
  siteId
});

export default connect(mapStateToProps, { updateAmazonIntegration, fetchAmazonReports })(
  AmazonConnect
);
