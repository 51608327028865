import React, { Component } from 'react';
import { Panel } from "common";
import { AgeGenderBreakdownFixtures } from "./fixtures/PanelFixtures";

class DemoAgeGenderBreakdown extends Component {
    render() {
        let formattedData = AgeGenderBreakdownFixtures;
        return (
            <Panel
                containerClass="col-md-6 p-3 float-left"
                isLoading={false}
                errors={this.props.errors}
                padding={2}>
                <Panel.Subtitle text={"Top Demographics"} />
                <Panel.Body
                    render={() => {
                        return (
                            <ul className="social-demo-breakdown">
                                {formattedData.map((el, i) => {
                                    if (i < 6) return (
                                        <li className={"social-demo-breakdown_item is-" + el.category.toLowerCase()} key={i}><i>{(i + 1)}</i><span>{el.category + "s"}</span> Ages {el.value}</li>
                                    )
                                })}
                            </ul>
                        );
                    }}
                />
            </Panel>
        )
    }
}

export { DemoAgeGenderBreakdown };

