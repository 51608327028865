import React from "react";
import { string, node } from "prop-types";

function withErrors(Component) {
  function Wrapper({ errors, children, ...props }) {
    return (
      <Component {...props}>
        {errors && (
          <div data-testid="alert" className="alert alert-danger w-100">
            {errors}
          </div>
        )}
        {children}
      </Component>
    );
  }

  Wrapper.propTypes = {
    errors: string,
    children: node
  };
  Wrapper.displayName = "withErrors()";

  return Wrapper;
}

export default withErrors;
