import _ from "lodash";
import moment from "moment";
import { pipe } from "lib/utilities";

let thisMonthData;
let lastMonthData;

const fillerReports = (monthString) => ({
  Cost_conv: 0,
  Conversions: "0",
  Cost: 0,
  Clicks: "0",
  Day: moment(monthString, "MM").startOf("month").format("YYYY-MM-DD"),
});

const fillerTotals = {
  Clicks: 0,
  Cost: 0,
  Conversions: 0,
  Cost_conv: 0,
};

const fillMonthGaps = (month, isThisMonth = true) => {
  let monthLength = month.length;

  month.forEach((day, index) => {
    if (index + 1 === monthLength) return;

    let thisDay = parseInt(month[index].Day.substring(8), 0);
    let nextDay = parseInt(month[index + 1].Day.substring(8), 0);

    if (thisDay + 1 !== nextDay) {
      let withDayAdded = [
        ...month,
        {
          Cost_conv: month[index].Cost_conv,
          Conversions: month[index].Conversions,
          Cost: month[index].Cost,
          Clicks: month[index].Clicks,
          Day: moment(month[index].Day, "YYYY-MM-DD")
            .add(1, "days")
            .format("YYYY-MM-DD"),
          Monthly_Conversions: month[index].Monthly_Conversions,
        },
      ];
      let monthSorted = withDayAdded.sort(
        (a, b) => a.Day.substring(8) - b.Day.substring(8)
      );

      if (isThisMonth) {
        thisMonthData = monthSorted;
        fillMonthGaps(thisMonthData);
      } else {
        lastMonthData = monthSorted;
        fillMonthGaps(lastMonthData, false);
      }
    }
  });
};

const fillMonthLength = (month) => {
  const filler = { Day: "", Monthly_Conversions: "" };
  let filledMonth = [...month];

  while (filledMonth.length < 31) {
    filledMonth = [...filledMonth, filler];
  }

  return filledMonth;
};

const formatData = (lastMonth, thisMonth) => {
  const data = _.zip(lastMonth, thisMonth)
    .map((day) => {
      const [lastDay, thisDay] = day;
      const latestDay = lastDay.Day > thisDay.Day ? lastDay.Day : thisDay.Day;
      return {
        day: moment(latestDay, "YYYY-MM-DD").format("DD"),
        [moment(lastDay.Day, "YYYY-MM-DD").format("MMM")]:
          lastDay.Monthly_Conversions,
        [moment(thisDay.Day, "YYYY-MM-DD").format("MMM")]:
          thisDay.Monthly_Conversions,
      };
    })
    .filter((item) => item.day !== "Invalid date");

  return data;
};

function toAbs(value) {
  return Math.abs(value);
}

function toFixed(value) {
  if (_.isNumber(value)) {
    return value.toFixed(2);
  } else {
    return value;
  }
}

function toFloat(value) {
  return parseFloat(value);
}

function withComma(value) {
  return value.toLocaleString("en");
}

function toCurrency(value) {
  return "$" + value;
}

function toNegative(value) {
  return "-" + value;
}
const generateRoas = (data, actionType) => {
  let Total_conv_value = data.total.Total_conv_value;
  let Cost = data.total.Cost;
  if (actionType === true) {
    Total_conv_value = data.total.Total_conv_value;
    Cost = data.total.Cost;
  }

  if (Total_conv_value === 0) {
    return 0;
  } else {
    let roas = Total_conv_value / Cost;
    return pipe(withComma)(roas);
  }
};

const formatTotals = (data, action) => {
  let { start, end, total } = data;
  let clicksDifference = end.Clicks - start.Clicks;
  let costDifference = end.Cost - start.Cost;
  let conversionsDifference = end.Conversions - start.Conversions;
  let costConvDifference = end.Cost_conv - start.Cost_conv;
  let totalCostConvDifference = end.Total_conv_value - start.Total_conv_value;
  if (action.fromAdwordsPerformance.isOverview) {
    clicksDifference = 0;
    costDifference = 0;
    conversionsDifference = 0;
    costConvDifference = 0;
    totalCostConvDifference = 0;
    return {
      Cost: {
        title: "Cost",
        value: pipe(toFixed, toFloat, withComma, toCurrency)(total.Cost),
        change:
          costDifference > 0
            ? pipe(toFixed, toAbs, withComma, toCurrency)(costDifference)
            : pipe(
                toFixed,
                toAbs,
                withComma,
                toCurrency,
                toNegative
              )(costDifference),
        positiveChange: costDifference > 0,
      },
      Conversions: {
        title: "Conversions",
        value: pipe(toFixed, toFloat, withComma)(total.Conversions),
        change:
          conversionsDifference > 0
            ? pipe(toFixed, toAbs)(conversionsDifference)
            : pipe(toFixed, toAbs, toNegative)(conversionsDifference),
        positiveChange: conversionsDifference > 0,
      },
      Cost_conv: {
        title: "Cost / Conv",
        value: pipe(toFixed, toFloat, withComma, toCurrency)(total.Cost_conv),
        change:
          costConvDifference > 0
            ? pipe(toFixed, toAbs, toCurrency)(costConvDifference)
            : pipe(toFixed, toAbs, toCurrency, toNegative)(costConvDifference),
        positiveChange: costConvDifference > 0,
      },
      Total_conv_value: {
        title: "Revenue",
        value: pipe(toCurrency)(total.Total_conv_value),
        change:
          totalCostConvDifference > 0
            ? pipe(toFixed, toAbs, toCurrency)(costConvDifference)
            : pipe(toFixed, toAbs, toCurrency, toNegative)(costConvDifference),
        positiveChange: costConvDifference > 0,
      },
      Roas: {
        title: "ROAS",
        value: generateRoas(data, action.fromAdwordsPerformance.isOverview),
        change: false,
        positiveChange: false,
      },
      Clicks: {
        title: "Clicks",
        value: pipe(withComma)(total.Clicks),
        change:
          clicksDifference > 0
            ? pipe(toAbs, withComma)(clicksDifference)
            : pipe(toAbs, withComma, toNegative)(clicksDifference),
        positiveChange: clicksDifference > 0,
      },
    };
  }

  return {
    Cost: {
      title: "Cost",
      value: pipe(toFixed, toFloat, withComma, toCurrency)(end.Cost),
      change:
        costDifference > 0
          ? pipe(toFixed, toAbs, withComma, toCurrency)(costDifference)
          : pipe(
              toFixed,
              toAbs,
              withComma,
              toCurrency,
              toNegative
            )(costDifference),
      positiveChange: costDifference > 0,
    },
    Conversions: {
      title: "Conversions",
      value: pipe(toFixed, toFloat, withComma)(end.Conversions),
      change:
        conversionsDifference > 0
          ? pipe(toFixed, toAbs)(conversionsDifference)
          : pipe(toFixed, toAbs, toNegative)(conversionsDifference),
      positiveChange: conversionsDifference > 0,
    },
    Cost_conv: {
      title: "Cost / Conv",
      value: pipe(toFixed, toFloat, withComma, toCurrency)(end.Cost_conv),
      change:
        costConvDifference > 0
          ? pipe(toFixed, toAbs, toCurrency)(costConvDifference)
          : pipe(toFixed, toAbs, toCurrency, toNegative)(costConvDifference),
      positiveChange: costConvDifference > 0,
    },
    Total_conv_value: {
      title: "Revenue",
      value: pipe(toCurrency)(end.Total_conv_value),
      change:
        totalCostConvDifference > 0
          ? pipe(toFixed, toAbs, toCurrency)(costConvDifference)
          : pipe(toFixed, toAbs, toCurrency, toNegative)(costConvDifference),
      positiveChange: costConvDifference > 0,
    },
    Roas: {
      title: "ROAS",
      value: generateRoas(data, action.fromAdwordsPerformance.isOverview),
      change: false,
      positiveChange: false,
    },
    Clicks: {
      title: "Clicks",
      value: pipe(withComma)(end.Clicks),
      change:
        clicksDifference > 0
          ? pipe(toAbs, withComma)(clicksDifference)
          : pipe(toAbs, withComma, toNegative)(clicksDifference),
      positiveChange: clicksDifference > 0,
    },
  };
};

export const googleAdwordsPerformance =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (!action.fromAdwordsPerformance) {
      return next(action);
    }

    const formattedData = action.payload.data;
    const { reports, totals } = formattedData;

    totals.start = !totals.start ? fillerTotals : totals.start;
    totals.end = !totals.end ? fillerTotals : totals.end;

    if (_.isEmpty(reports)) {
      dispatch({
        ...action,
        payload: formattedData,
        fromAdwordsPerformance: false,
      });
      return;
    }

    let lastMonthNumber;
    let thisMonthNumber;

    const thisMonthActual = moment().month() + 1;
    const lastMonthActual = moment().subtract(1, "months").month() + 1;

    if (Object.keys(reports).length === 1) {
      const [monthNumber] = Object.keys(reports);

      // eslint-disable-next-line
      lastMonthNumber =
        monthNumber == lastMonthActual ? monthNumber : lastMonthNumber;
      // eslint-disable-next-line
      thisMonthNumber =
        monthNumber == thisMonthActual ? monthNumber : thisMonthNumber;
    } else if (Object.keys(reports).length === 2) {
      [lastMonthNumber, thisMonthNumber] = Object.keys(reports);
    }

    lastMonthData = !lastMonthNumber
      ? [fillerReports(lastMonthActual)]
      : [...reports[lastMonthNumber]];

    thisMonthData = !thisMonthNumber
      ? [fillerReports(thisMonthActual)]
      : [...reports[thisMonthNumber]];

    fillMonthGaps(lastMonthData, false);
    fillMonthGaps(thisMonthData);

    const lastMonthFilled = fillMonthLength(lastMonthData);
    const thisMonthFilled = fillMonthLength(thisMonthData);

    // console.log("lastMonthFilled", lastMonthFilled);
    // console.log("thisMonthFilled", thisMonthFilled);

    const data = formatData(lastMonthFilled, thisMonthFilled);
    const formattedTotals = formatTotals(totals, action);

    formattedData.reports = data;
    formattedData.totals = formattedTotals;
    const newAction = {
      ...action,
      payload: formattedData,
      fromAdwordsPerformance: false,
    };

    dispatch(newAction);
  };
