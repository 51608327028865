import _ from "lodash";
import React, { Component } from "react";
import { string, arrayOf, func } from "prop-types";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Panel } from "common";
import { store } from "store";
import { push } from "react-router-redux";

const formatTableData = (data, displayYOY) => {
  const previousMonth = moment(new Date())
    .subtract(1, "months")
    .format("MMM");
  const monthBeforePreviousMonth = moment(new Date())
    .subtract(2, "months")
    .format("MMM");
  if (!data) return [];
  return data
    .map((n) => {
      const previousMonthSiteData = n.stats.find(
        (z) => z.label === previousMonth
      );
      const monthBeforePreviousMonthSiteData = n.stats.find(
        (z) => z.label === monthBeforePreviousMonth
      );
      let returnData = {
        ...n,
        rowData: [
          {
            value: n.name,
          },
          { value: null },
          { value: null },
          { value: null },
        ],
      };

      if (previousMonthSiteData) {
        returnData.rowData[2].value = previousMonthSiteData.currentData.toLocaleString();
      }
      if (monthBeforePreviousMonthSiteData && !displayYOY) {
        returnData.rowData[1].value = monthBeforePreviousMonthSiteData.currentData.toLocaleString();
      }
      if (displayYOY) {
        returnData.rowData[1].value = previousMonthSiteData.previousData.toLocaleString();
      }
      returnData.rowData[3].value = getPercentDiff(
        returnData.rowData[1].value,
        returnData.rowData[2].value
      );
      return returnData;
    })
    .sort((a, b) => (a.rowData[0].value > b.rowData[0].value ? 1 : -1));
};

const getPercentDiff = (a, b) => {
  if (getNumValue(a) === 0) {
    return "--";
  }
  return (((getNumValue(b) - getNumValue(a)) / getNumValue(b)) * 100).toFixed(
    2
  );
};

const getNumValue = (str = "") => {
  return parseInt((str || "").replace(/,/g, ""), 10);
};

class PrimarySiteTable extends Component {
  static defaultProps = {
    classes: "",
  };

  state = { enableTooltip: false };

  render() {
    const data =
      formatTableData(
        this.props[this.props.dataSelector],
        this.props.displayYOY
      ) || [];

    const isLoading = this.props[this.props.loadStateSelector];

    const headers = [
      "Site Name",
      `${
        !this.props.displayYOY
          ? moment(new Date())
              .subtract(2, "months")
              .format("MMM'YY")
          : moment(new Date())
              .subtract(1, "months")
              .subtract(1, "years")
              .format("MMM'YY")
      }`,
      `${
        !this.props.displayYOY
          ? moment(new Date())
              .subtract(1, "months")
              .format("MMM'YY")
          : moment(new Date())
              .subtract(1, "months")
              .format("MMM'YY")
      }`,
      "% Change",
    ];

    // New Visitors
    return (
      <Panel
        containerClass={`${
          this.props.useAllCol
            ? `col-12 custom-table-padding-${this.props.side}  scrollable-table-no-padding`
            : `col-md-6 custom-table-padding-${this.props.side} scrollable-table`
        }`}
        isLoading={isLoading}
      >
        <Panel.Body
          style={{
            display: "block",
          }}
          render={() => (
            <table
              className={`responsive-table rollup-table ${this.props.classes}`}
            >
              <thead className="responsive-thead stick">
                <tr style={{ cursor: "pointer" }} className="responsive-tr">
                  {headers.map((header) => {
                    return (
                      <th scope="col" className="responsive-th" key={header}>
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="responsive-tbody scroll">
                {data.map((row, index) => {
                  return (
                    <tr
                      className="responsive-tr"
                      key={index}
                      onClick={() => {
                        const hash = window.location.hash
                          ? window.location.hash
                          : "";
                        window.open(`/sites/${row.id}${hash}`, "_blank");
                      }}
                    >
                      {row.rowData.map((n, i) => {
                        let sign =
                          i === 3 ? Math.sign(getNumValue(n.value)) : "";
                        let signClassName = `sign-${
                          sign === "" ? "" : sign >= 0 ? "pos" : "neg"
                        }`;
                        return (
                          <td
                            data-label={n.label}
                            key={i}
                            className={`responsive-td ${signClassName}`}
                            data-tip
                            data-for={"meta-" + i}
                          >
                            {n.value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    date: state.sites.show.date,
    newVisitorsPerSite:
      state.sites.show.primarySiteAnalyticsData.newVisitorsPerSite,
    newVisitorsPerSiteAll:
      state.sites.show.primarySiteAnalyticsData.newVisitorsPerSiteAll,
    primarySiteSessionsPerSite:
      state.sites.show.primarySiteAnalyticsData.primarySiteSessionsPerSite,
    primarySiteNewVisitorsLoadState:
      state.sites.show.primarySiteAnalyticsData.primarySiteNewVisitorsLoadState,
    primarySiteSessionsLoadState:
      state.sites.show.primarySiteAnalyticsData.primarySiteSessionsLoadState,
    primarySiteNewVisitorsAllLoadState:
      state.sites.show.primarySiteAnalyticsData
        .primarySiteNewVisitorsAllLoadState,
  };
};

export default connect(mapStateToProps, {})(withRouter(PrimarySiteTable));
