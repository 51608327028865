import _ from "lodash";
import React from "react";
import { func, object } from "prop-types";
import { Button } from "common";

TeamsList.propTypes = {
  handleSelectTeam: func,
  data: object
}

function TeamsList({ data, handleSelectTeam }){
  return (
    <section className="templates-sidebar">
      <Button link to="/templates/new">
        New Template
      </Button>
      <span className="templates-heading">Teams</span>
      {_.map(data, (templates, team) => {
        return (
          <div
            className="templates-sidebar-item"
            key={team}
            onClick={handleSelectTeam.bind(this, team)}
          >
            {team}
          </div>
        )
      })}
    </section>
  )
}

export default TeamsList;
