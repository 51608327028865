import React, { Component } from "react";
import { Panel } from "common";

class NoData extends Component {
    render() {
        return (
            <Panel
                containerClass="col-md-6 p-3 float-left"
                isLoading={null}
                errors={null}
                padding={2}>
                <Panel.Body
                    render={() => {
                        return (
                            <div className="text-center center-no-data">
                                <h3>{this.props.title}</h3>
                            </div>
                        );
                    }}
                />
            </Panel>
        )
    }
}

export { NoData };
