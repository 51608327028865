import React, { Component, Fragment } from "react";
import { DemoSocialMediaSitesNavBar } from "./DemoSocialMediaSitesNavBar";
import { DemoFacebook } from "./DemoFacebook";
import { DemoInstagram } from "./DemoInstagram";
import { DemoTwitter } from "./DemoTwitter";

class DemoSocialIndex extends Component {
    state = {
        siteList: [],
        selectedAccount: {
            label: null,
            id: null
        },
        selection: 'Facebook'
    }

    selectSocialMedia = (selection) => {
        this.setState(function (state, props) {
            return {
                selection: selection
            }
        });
    }

    render() {
        return (
            <Fragment>
                <DemoSocialMediaSitesNavBar
                    selectSocialMedia={this.selectSocialMedia}
                />
                {
                    this.state.selection === 'Facebook' && <DemoFacebook />
                }
                {
                    this.state.selection === 'Twitter' && <DemoTwitter />
                }
                {
                    this.state.selection === 'Instagram' && <DemoInstagram />
                }
            </Fragment>
        )
    }
}





export default DemoSocialIndex;
