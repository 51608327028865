import _ from "lodash";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Radium from "radium";

import { Panel } from "common";

const generateChildStyle = data => ({
  "@media (min-width: 800px)": {
    width: _.size(data) > 4 ? "33%" : 100 / _.size(data) + "%",
    borderBottom: "none"
  },
  "@media (min-width: 1024px)": {
    width: 100 / _.size(data) + "%"
  }
});

DemoStatisticsBarBody.propTypes = {
  data: PropTypes.object,
  isPublic: PropTypes.bool
};

function DemoStatisticsBarBody({ data, isPublic }) {
  const childStyle = generateChildStyle(data);
  return (
    <div className="statistics-bar" style={styles.container}>
      {_.map(data, (item, index) => {
        return (
          <section
            key={index}
            className="statistics-bar-item"
            style={[childStyle, "border: 1px solid red"]}>
            <span className="title">{item.title}</span>
            <span className="value" style={[isPublic && styles.value]}>
              {item.value}
            </span>
            {item.change && (
              <span
                className={`change ${
                  item.positiveChange && item.title !== "Bounce Rate"
                    ? "" //was text-success
                    : ""
                  }`}
                style={[isPublic && styles.change]}>
                {item.positiveChange ? `+${item.change}` : `${item.change}`}
              </span>
            )}
          </section>
        );
      })}
    </div>
  );
}

const DemoStatisticsBarBodyWithRadium = Radium(DemoStatisticsBarBody);

class DemoStatisticsBar extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    isPublic: PropTypes.bool,
    errors: PropTypes.string,
    data: PropTypes.object,
    borderColor: PropTypes.string,
    renderSubtitle: PropTypes.func
  };

  static defaultProps = {
    borderColor: "white",
    renderSubtitle: () => { }
  }

  renderBody = (data, isPublic) => (
    <DemoStatisticsBarBodyWithRadium data={data} isPublic={isPublic} />
  );

  render() {
    return (
      <Fragment>
        {this.props.renderSubtitle()}
        <Panel
          containerClass="col-md-12 p-3"
          isLoading={this.props.isLoading}
          errors={this.props.errors}>
          <Panel.Body
            style={{
              display: this.props.isLoading ? "block" : "inherit",
              padding: this.props.isLoading ? ".2em" : "1.5em 3em",
              borderTop: `2px solid #fff`,
            }}
            render={() => this.renderBody(this.props.data, this.props.isPublic)}
          />
        </Panel>
      </Fragment>
    );
  }
}

const styles = {
  container: {
    "@media (min-width: 800px)": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center"
    }
  },
  value: {
    fontSize: "2em"
  },
  change: {
    display: "none",
    height: 0
  }
};

export { DemoStatisticsBar };
