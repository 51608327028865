import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import * as actions from "screens/users/actions";
import { store } from "store";
import { Button, FormGroup } from "common";
import { handleInputChange } from "lib/utilities";

class ForgotPassword extends Component {
    state = { email: "" };

    componentWillMount() {
        this.checkIfAuthenticated();
    }

    componentWillReceiveProps() {
        this.checkIfAuthenticated();
    }

    checkIfAuthenticated() {
        if (this.props.user.authentication.data.token) {
            const location = this.props.lastVisited;
            store.dispatch(push(location));
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        const email = this.props.email;
        if (email.length <= 0) {

        }
        this.props.resetPassword({ email });
    };
    render() {
        let isDisabled = null;
        this.props.email.length <= 0 ? isDisabled = true : isDisabled = false;
        if (this.props.visible) {
            return (
                <form onSubmit={this.props.handleSubmit}>
                    <FormGroup
                        label="Email"
                        type="email"
                        value={this.props.email}
                        onChange={e => handleInputChange(this.props.UsersAuthenticateComponent, e, "email")}
                    />
                    <Button
                        onClick={this.handleSubmit}
                        disabled={isDisabled}
                        grey={isDisabled}
                        style={{ marginTop: "10px", marginRight: "10px" }}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={this.props.showForgotPasswordForm}
                        grey={isDisabled}
                        style={{ marginTop: "10px", marginRight: "10px" }}
                        classes={"cancel-btn"}
                    >
                        Cancel
                    </Button>
                </form>
            );
        } else {
            return null;
        }
    }
}

// UsersAuthenticate.propTypes = {
//   user: PropTypes.object,
//   authenticateUser: PropTypes.func,
//   lastVisited: PropTypes.string
// };

const mapStateToProps = ({ user, lastVisited }) => ({ user, lastVisited });

const connected = connect(mapStateToProps, actions)(ForgotPassword);

export { connected as ForgotPassword };
