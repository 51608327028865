const formatReports = payload => {
  if (payload.reports[0].data.rows === undefined) {
    return undefined;
  }
  const data = payload.reports[0].data.rows
    .filter(row => row.dimensions[0] !== "(not set)")
    .splice(0, 10);
  const formattedData = [];

  data.forEach((item, index) => {
    const position = index;
    const region = item.dimensions[0];
    const city = item.dimensions[1];
    const lat = item.dimensions[2];
    const lng = item.dimensions[3];
    const newUsers = item.metrics[0].values[0];
    const data = {
      position,
      region,
      city,
      lat,
      lng,
      newUsers
    };

    formattedData.push(data);
  });

  return formattedData.reverse();
};

export const googleAnalyticsVisitorsByState = ({
  dispatch
}) => next => action => {
  if (!action.fromGAVisitorsByState) {
    return next(action);
  }

  let formattedData = formatReports(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGAVisitorsByState: false
  };

  dispatch(newAction);
};

const formUserLocationReportsGA4 = (payload) => {
  if (!payload.rows) {
    return []
  }
  
  return payload.rows.map((n) => {
    const region =  (n.dimensionValues[0] || {}).value
    const city =  (n.dimensionValues[1] || {}).value
    const newUsers = (n.metricValues[0] || {}).value
    
    return {
      region,city,newUsers
    }
  }).sort((a, b) => {
    return a.newUsers - b.newUsers
  })
};

export const googleAnalyticsVisitorsByStateGA4 = ({
  dispatch
}) => next => action => {
  if (!action.fromGAVisitorsByStateGA4) {
    return next(action);
  }

  let formattedData = formUserLocationReportsGA4(action.payload);

  const newAction = {
    ...action,
    payload: formattedData,
    fromGAVisitorsByStateGA4: false
  };

  dispatch(newAction);
};
