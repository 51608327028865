import React, { Component } from "react";
import moment from "moment";
import { Panel } from "common";
import { NoData } from "./NoData";

import { ResponsiveContainer, BarChart, XAxis, Bar, Tooltip, Legend } from "recharts";

class LikesPaidOrganic extends Component {
    state = {
        dataForChart: [],
        isFetching: true,
        noData: false,
    }

    componentDidUpdate(prevProps) {
        if (this.props.month !== prevProps.month) {
            this.setState({
                isFetching: true,
            })
        }
        if (prevProps.data !== this.props.data) {
            let checkForPageFansAddPaidNonPaid = false;
            let checkforPageFansLikeSource = false;
            this.props.data.forEach(item => {
                item.name === "page_fan_adds_by_paid_non_paid_unique" && (checkForPageFansAddPaidNonPaid = true)
                item.name === "page_fans_by_like_source_unique" && (checkforPageFansLikeSource = true)
            });
            if (checkForPageFansAddPaidNonPaid === true && checkforPageFansLikeSource === true) {
                let valuesCountPaid = 0;
                let valuesCountUnpaid = 0;
                let page_fan_adds_by_paid_non_paid_unique = {}
                this.props.data.forEach((item, index) => {
                    if (item.name === "page_fan_adds_by_paid_non_paid_unique") {
                        page_fan_adds_by_paid_non_paid_unique = item;
                    }
                });
                const checkMonths = [];
                const dataForChart = [];
                page_fan_adds_by_paid_non_paid_unique.values.forEach(item => {
                    const date = moment(item.end_time).format("MMM");
                    if (!checkMonths.includes(date)) {
                        checkMonths.push(date);
                    }
                });
                checkMonths.forEach(item => {
                    var obj = { month: 0, label: item, paid: 0, organic: 0, total: 0 }
                    dataForChart.push(obj);
                });
                page_fan_adds_by_paid_non_paid_unique.values.forEach(item => {
                    const month = moment(item.end_time).format("M");
                    const date = moment(item.end_time).format("MMM");
                    const index = checkMonths.indexOf(date);
                    dataForChart[index].month = month;
                    dataForChart[index].paid += item.value.paid
                    dataForChart[index].organic += item.value.unpaid
                    dataForChart[index].total += item.value.total
                    item.value.paid === 0 && (valuesCountPaid += 1);
                    item.value.unpaid === 0 && (valuesCountUnpaid += 1);
                });
                this.setState(function (state, props) {
                    if (valuesCountPaid < 85 || valuesCountUnpaid < 85) {
                        return {
                            dataForChart: dataForChart,
                            isFetching: false,
                            noData: false,
                        }
                    } else {
                        return {
                            dataForChart: dataForChart,
                            isFetching: false,
                            noData: true,
                        }
                    }
                });
            } else {
                this.setState(function (state, props) {
                    return {
                        dataForChart: [],
                        isFetching: false,
                        noData: true,
                    }
                });
            }
        }
    }
    render() {
        return (
            this.state.noData ? <NoData title={"No Likes Paid and Organic Data Available"} /> :
                <Panel
                    containerClass={"col-md-6 p-3 float-left yoy-graph yearly-graph"}
                    isLoading={this.state.isFetching}
                    errors={this.props.errors}
                    padding={2}>
                    <Panel.Subtitle text={"Likes Paid And Organic"} />
                    <Panel.Body
                        render={() => {
                            return (
                                <ResponsiveContainer height={230}>
                                    <BarChart
                                        width={600}
                                        height={300}
                                        data={this.state.dataForChart}
                                        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
                                        <XAxis tickLine={false} dataKey="label" />
                                        <Tooltip />
                                        <Legend />
                                        <Bar name={"Organic"} className={"yoy-bar"} dataKey="organic" stackId="b" fill="#619bf9" />
                                        <Bar name={"Paid"} className={"yoy-bar"} dataKey="paid" stackId="a" fill="#dce9fe" />
                                    </BarChart>
                                </ResponsiveContainer>
                            );
                        }}
                    />
                </Panel>
        )
    }
}

export { LikesPaidOrganic };

