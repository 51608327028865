import React, { Component } from "react";
import { connect } from "react-redux";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Panel } from "common";
import { NoData } from "./NoData";
import { SocialGraphViewWithLoader } from "../SocialGraphViewWithLoader";

const COLORS = ["#619bf9", "#abcafc", "#dce9fe"];

class InstagramSavesAndSharesPieGraph extends Component {
  render() {
    return (this.props.data || []).filter((n) => n.value === null).length ===
      2 ? (
      <NoData title={"No Saves and Shares data available"} />
    ) : (
      <SocialGraphViewWithLoader
        classes="mb-3 col-6"
        isLoading={this.props.instagramCurrentMonthLoadState}
      >
        <Panel
          isLoading={this.props.instagramCurrentMonthLoadState}
          errors={this.props.errors}
          padding={2}
        >
          <Panel.Subtitle text={"Total Saves & Shares"} />
          <Panel.Body
            render={() => {
              return (
                <ResponsiveContainer height={230}>
                  <PieChart className={`device-graph`}>
                    <Pie
                      labelLine={false}
                      dataKey="value"
                      data={this.props.data}
                      cx={115}
                      cy={115}
                      innerRadius={30}
                      outerRadius={60}
                    >
                      {this.props.data.map((entry, index) => (
                        <Cell
                          key={index}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend wrapperStyle={{ position: "static" }} />
                  </PieChart>
                </ResponsiveContainer>
              );
            }}
          />
        </Panel>
      </SocialGraphViewWithLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  data: [
    { name: "Saved", value: state.social.instagramSavedCountCurrentMonth },
    { name: "Shares", value: state.social.instagramSharesCountCurrentMonth },
  ],
  instagramCurrentMonthLoadState: state.social.instagramCurrentMonthLoadState,
});

const connected = connect(mapStateToProps)(InstagramSavesAndSharesPieGraph);

export { connected as InstagramSavesAndSharesPieGraph };
