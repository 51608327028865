import React, { Component } from "react";
import { func, string, shape, oneOfType, number, array } from "prop-types";
import { connect } from "react-redux";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer
// } from "recharts";
import { GenericPieGraph } from "common";

import { fetchAdwordsAdSources } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";

class GoogleAdwordsConversionSources extends Component {
  static propTypes = {
    adSources: array,
    fetchAdwordsAdSources: func,
    adwords_id: oneOfType([string, number]),
    date: shape({
      month: string,
      year: string
    })
  };

  state = { isFetching: false };
  mounted = true;

  componentDidMount() {
    if (!this.props.adwords_id) return;
    const params = {
      adwords_id: this.props.adwords_id,
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date)
    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentId = this.props.adwords_id !== nextProps.adwords_id;

    if (!this.props.adwords_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        adwords_id: this.props.adwords_id,
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date)
      };
      this.props.clearCacheBool === true && this.props.fetchAdwordsAdSources(
        params,
        () => { }, this.props.clearCacheBool);
    }

  };



  fetchData = params => {
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchAdwordsAdSources(params, () =>
      this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.adwords_id) return null;
    let data = [];
    if(this.props.isEcommerce){
      data = this.props.data;
    } else {
      data = this.props.adSources;
    }
    return (
      <GenericPieGraph
        title={`${this.props.isEcommerce ? "Sales Sources" :"Conversion Sources" }`}
        isEcommerce={this.props.isEcommerce}
        isLoading={this.state.isFetching}
        data={data}
      />
    )
  }
}

const mapStateToProps = ({
  sites: {
    show: { date, data: { adwords_id }, paidAdsData: { adSources } }
  }
}) => ({
  adwords_id,
  date,
  adSources
});

export default connect(mapStateToProps, { fetchAdwordsAdSources })(
  GoogleAdwordsConversionSources
);
