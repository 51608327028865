import React, { Component } from 'react'
import { Button } from "common";

class RedisButton extends Component {

    clearCache = () => {
        if (this.props.cacheFromWeekly === true) {
            this.props.context.setState({ clearCacheBool: true });
        } else {
            this.props.context.setState({ clearCacheBool: true });
            setTimeout(() => {
                window.location.reload();
            }, 300);
        }
    }

    render() {
        if(this.props.isPublic){
            return null
        } else {
            return (
                <div className="redis-button-container">
                <Button classes="redis-button" onClick={this.clearCache}>
                    <span>Clear Cache</span><i className="fas fa-sync"></i>
                </Button>
            </div >
            )
        }
    }
}

RedisButton.defaultProps = {
    cacheFromWeekly: false,
}

export { RedisButton }