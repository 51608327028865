import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { InteractiveTable } from "common";
import _ from "lodash";

export default class AdsTable extends PureComponent {

    state = {
        tableLoader: true,
    }

    fetchTableData = (type) => {
        let tmp = type === "Facebook" && this.props.fetchSubTableData
        this.props.fetchData();
    }

    componentDidMount() {
        //Fetch initial data
        let params = {
            startDate: this.props.dates.dateONE_START,
            endDate: this.props.dates.dateONE_END,
        }
        this.props.type === "Google" && (params.adwords_id = this.props.Ads_ID);
        this.props.type === "Google" && (params.campaign_id = this.props.campaignInfo.Campaign_ID);
        this.props.type === "Facebook" && (params.campaign_id = this.props.campaignInfo.id)
        this.props.fetchTableData(params, () => { this.setState({ tableLoader: false, }) }, this.props.clearCacheBool);
    }

    componentDidUpdate(prevProps) {
        let params = {
            startDate: this.props.dates.dateONE_START,
            endDate: this.props.dates.dateONE_END,
        }
        this.props.type === "Google" && (params.adwords_id = this.props.Ads_ID);
        this.props.type === "Google" && (params.campaign_id = this.props.campaignInfo.Campaign_ID);
        this.props.type === "Facebook" && (params.campaign_id = this.props.campaignInfo.id)

        if (!_.isEqual(this.props.campaignInfo, prevProps.campaignInfo)) {
            this.setState({ tableLoader: true })
            this.props.fetchTableData(params, () => { this.setState({ tableLoader: false, }) }, this.props.clearCacheBool);
        }

        if (this.props.dates.dateONE_START !== prevProps.dates.dateONE_START || this.props.dates.dateONE_END !== prevProps.dates.dateONE_END) {
            this.setState({ tableLoader: true })
            this.props.fetchTableData(params, () => { this.setState({ tableLoader: false, }) }, this.props.clearCacheBool);
        }
        if (this.props.clearCacheBool !== prevProps.clearCacheBool) {
            this.setState({ tableLoader: true })
            this.props.fetchTableData(params, () => { this.setState({ tableLoader: false, }) }, this.props.clearCacheBool);
        }
    }

    parseValuesForSort = () => {
        let clone = _.cloneDeep(this.props.data);
        let newArray = [];
        if (clone.length > 0) {
            clone.forEach(item => {
                let obj = {};
                _.forOwn(item, (value, key) => {
                    if (!isNaN(value) && key !== 'creatives') {
                        let newVal = parseFloat(value);
                        value = newVal;
                        obj[key] = newVal;
                    }
                    obj[key] = value;
                })
                newArray.push(obj);
            });
            return newArray;
        } else {
            return [];
        }
    }


    render() {
        let parsedValues = this.parseValuesForSort();
        return (
            <div>
                <InteractiveTable
                    type={this.props.type}
                    data={parsedValues}
                    fetchAdPreview={this.props.fetchAdPreview}
                    dates={this.props.dates}
                    facebookAdPreviews={this.props.facebookAdPreviews}
                    tableLoader={this.state.tableLoader}
                    clearCacheBool={this.props.clearCacheBool}
                    clearAdPreviewState={this.props.clearAdPreviewState}
                />
            </div>
        )
    }
}
