import axios from "axios";
import { rootUrl } from "lib/api";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import { FETCHING_SB_TICKETS } from "./types";

export const fetchSBTickets = (label, page) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_SB_TICKETS, dispatch);
  const url = `${rootUrl}/supportbee/${label}/${page}`;
  const token = getToken();
  const headers = requestHeaders(token);

  let response = await axios.get(url, headers);
  dispatching(response);
};
