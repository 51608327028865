import _ from "lodash";
import React, { Component } from "react";
import { func, object } from "prop-types";
import { connect } from "react-redux";
import { EditorState, convertFromRaw, RichUtils } from "draft-js";
import Editor from "draft-js-plugins-editor";
import moment from "moment";
import { push } from "react-router-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";

import { store } from "store";
import * as actions from "screens/todos/actions";
import * as clientsActions from "screens/clients/actions";
import * as teamsActions from "screens/teams/actions";
import * as usersActions from "screens/users/actions";
import * as templatesActions from "screens/templates/actions";
import Sidebar from "screens/todos/components/Sidebar";
import Todos from "screens/todos/components/Todos";
import ProfileCircle from "screens/todos/components/ProfileCircle";
import TemplatesDropdown from "screens/todos/components/TemplatesDropdown";
import Comments from "screens/todos/components/Comments";
import Subtasks from "screens/todos/components/Subtasks";

import {
  getRouteType,
  _handleToggleTodo,
  _handleChangeTitle,
  _handleDateChange,
  _handleToggleSubtask,
  _handleDeleteSubtask,
  _handleUpdateSubtask,
  _handleCreateSubtask,
  _onSelectClient,
  _onSelectTeam,
  _handleSelectFollowers,
  _handleAddTemplateData,
  _onDragEnd,
  _handleDeleteComment,
  _handleAddComment,
  _handleChangeDescription
} from "screens/todos/helpers/todo_helpers";
import { linkifyPlugin } from "screens/todos/helpers/draft_js_helpers";

class TodosIndex extends Component {
  static propTypes = {
    dispatch: object,
    user: object,
    todos: object,
    clients: object,
    teams: object,
    templates: object,
    location: object,
    fetchMyTodos: func,
    fetchTeamTodos: func,
    fetchClientTodos: func,
    fetchingFollowingTodos: func,
    updateTodo: func,
    deleteTodoComment: func,
    updatedTodoFollowers: func,
    createTodo: func,
    fetchClients: func,
    fetchTeams: func,
    fetchUsers: func,
    fetchTemplates: func,
    createTodoComment: func
  };

  state = {
    sectionTitle: "",
    data: [],
    selectedTodoId: null,
    selectedTodoIndex: null,
    showDetails: false,
    newTodoTitle: "",
    subtasks: [],
    newSubtaskTitle: "",
    dueDate: null,
    selectedTodoClient: { id: null, name: "" },
    selectedTodoTeam: { id: null, name: "" },
    selectedTodoFollowers: [],
    commentEditorState: EditorState.createEmpty(),
    todosFilter: "active",
    filterTeam: "",
    filterClient: "",
    filterSearch: "",
    todosSort: "asc"
  };

  debounceUpdateTodo = _.debounce(this.props.updateTodo, 250);
  debounceUpdateTodoFollowers = _.debounce(
    this.props.updatedTodoFollowers,
    250
  );

  componentDidMount = async () => {
    this.fetchTodos();
    await this.props.fetchClients();
    await this.props.fetchTeams();
    await this.props.fetchUsers();
    await this.props.fetchTemplates();
  };

  componentWillReceiveProps = async nextProps => {
    const [thisRoute, thisId] = getRouteType(this.props.location.pathname);
    const [nextRoute, nextId] = getRouteType(nextProps.location.pathname);

    const insideTeams = thisRoute === "teams" && nextRoute === "teams";
    const insideClients = thisRoute === "clients" && nextRoute === "clients";
    const differentIds = thisId !== nextId;
    const shouldRefetch =
      (insideTeams && differentIds) || (insideClients && differentIds);

    if (thisRoute !== nextRoute || shouldRefetch) {
      this.setState(
        {
          data: [],
          selectedTodoId: null,
          selectedTodoIndex: null,
          showDetails: false,
          filterTeam: "",
          filterClient: "",
          filterSearch: ""
        },
        () => this.fetchTodos()
      );
    }
  };

  fetchTodos = async () => {
    let pathType, pathTypeId, todoId;
    const todosRoute = getRouteType(window.location.pathname);
    [pathType] = todosRoute;

    switch (pathType) {
      case "following":
        [pathType, todoId] = todosRoute;

        this.props.fetchingFollowingTodos(() => {
          this.setState({
            data: this.props.todos.index.data,
            sectionTitle: "Following"
          });
        });
        break;
      case "teams":
        [pathType, pathTypeId, todoId] = todosRoute;

        await this.props.fetchTeamTodos({ teamId: pathTypeId }, () => {
          let index;
          let selectedTeam;
          try {
            index = this.props.teams.data.findIndex(
              team => team.id === parseInt(pathTypeId, 0)
            );
            selectedTeam = this.props.teams.data[index].name;
          } catch (e) {
            console.warn("Error. Probably a race condition.");
          }

          this.setState({
            data: this.props.todos.index.data,
            sectionTitle: selectedTeam
          });
        });

        break;
      case "clients":
        [pathType, pathTypeId, todoId] = todosRoute;

        await this.props.fetchClientTodos({ clientId: pathTypeId }, () => {
          let index;
          let selectedClient;

          try {
            index = this.props.clients.index.data.findIndex(
              client => client.id === parseInt(pathTypeId, 0)
            );
            selectedClient = this.props.clients.index.data[index].name;
          } catch (e) {
            console.warn("Error. Probably a race condition.");
          }

          this.setState({
            data: this.props.todos.index.data,
            sectionTitle: selectedClient
          });
        });

        break;
      case "assigned":
        [pathType, todoId] = todosRoute;

        await this.props.fetchMyTodos(() => {
          this.setState({
            data: this.props.todos.index.data.filter(todo => {
              return todo.team || todo.client;
            }),
            sectionTitle: "Assigned"
          });
        });
        break;
      case "unassigned":
        [pathType, todoId] = todosRoute;

        await this.props.fetchMyTodos(() => {
          this.setState({
            data: this.props.todos.index.data.filter(todo => {
              return !todo.team && !todo.client;
            }),
            sectionTitle: "Unassigned"
          });
        });
        break;
      case "me":
      default:
        [pathType, todoId] = todosRoute;

        await this.props.fetchMyTodos(() => {
          this.setState({
            data: this.props.todos.index.data,
            sectionTitle: "My Todos"
          });
        });
        break;
    }

    await this.setState({ pathType, pathTypeId });

    const index = this.state.data.findIndex(
      todo => todo.id === parseInt(todoId, 0)
    );
    const selectedTodoIsActive = this.state.data[index]
      ? this.state.data[index].status.id === 1
      : "";
    todoId && selectedTodoIsActive && this[`input-${todoId}`].focus();
  };

  withSelectedIndex = fn => {
    return params => {
      const todoIndex = this.state.data.findIndex(
        todo => todo.id === params.todoId
      );

      fn({
        ...params,
        todoIndex,
        allTodos: this.state.data,
        selectedTodo: this.state.data[todoIndex],
        that: this
      });
    };
  };

  onFocus = this.withSelectedIndex(({ todoId, todoIndex, selectedTodo }) => {
    const url = this.state.pathTypeId
      ? `/todos/${this.state.pathType}/${this.state.pathTypeId}/${todoId}`
      : `/todos/${this.state.pathType}/${todoId}`;

    store.dispatch(push(url));
    const { blocks } = JSON.parse(selectedTodo.details);
    const rawContent = { blocks, entityMap: {} };
    const descriptionEditorState = EditorState.createWithContent(
      convertFromRaw(rawContent)
    );
    const dueDate = moment(selectedTodo.due_date)._isValid
      ? moment(selectedTodo.due_date)
      : null;

    let selectedTodoClient;
    let selectedTodoTeam;

    try {
      const clientsData = this.props.clients.index.data;
      let selectedClientIndex = clientsData.findIndex(client => {
        return client.id === selectedTodo.client.id;
      });

      selectedTodoClient = clientsData[selectedClientIndex];
    } catch (e) {
      selectedTodoClient = { id: null, name: "" };
    }

    try {
      const teamsData = this.props.teams.data;
      let selectedTeamIndex = teamsData.findIndex(team => {
        return team.id === selectedTodo.team.id;
      });
      selectedTodoTeam = teamsData[selectedTeamIndex];
    } catch (e) {
      selectedTodoTeam = { id: null, name: "" };
    }

    const selectedTodoFollowers = [];

    selectedTodo.followers.forEach(follower =>
      selectedTodoFollowers.push({
        ...follower.user,
        full_name: `${follower.user.first_name} ${follower.user.last_name}`
      })
    );

    this.setState({
      descriptionEditorState,
      dueDate,
      showDetails: true,
      selectedTodoIndex: todoIndex,
      selectedTodoId: todoId,
      subtasks: JSON.parse(selectedTodo.subtasks),
      selectedTodoClient: selectedTodoClient || { id: null, name: "" },
      selectedTodoTeam: selectedTodoTeam || { id: null, name: "" },
      selectedTodoFollowers
    });
  });

  updatedTodosData = (todoIndex, updatedTodo) => [
    ...this.state.data.slice(0, todoIndex),
    updatedTodo,
    ...this.state.data.slice(todoIndex + 1)
  ];

  handleCreateTodo = e => {
    e.preventDefault();

    const { pathType, pathTypeId } = this.state;

    switch (pathType) {
      case "teams":
        this.props.createTodo(
          { title: this.state.newTodoTitle, team_id: pathTypeId },
          data => {
            this.setState({
              data: [...this.state.data, data],
              newTodoTitle: ""
            });
          }
        );
        break;
      case "clients":
        this.props.createTodo(
          { title: this.state.newTodoTitle, client_id: pathTypeId },
          data => {
            this.setState({
              data: [...this.state.data, data],
              newTodoTitle: ""
            });
          }
        );
        break;
      default:
        this.props.createTodo({ title: this.state.newTodoTitle }, data => {
          this.setState({
            data: [...this.state.data, data],
            newTodoTitle: ""
          }, () => {
            const newlyCreatedTodoId = this.state.data.slice(-1)[0].id
            this.onFocus({ todoId: newlyCreatedTodoId })
          });
        });
    }
  };

  handleChangeDescription = this.withSelectedIndex(_handleChangeDescription);
  handleToggleTodo = this.withSelectedIndex(_handleToggleTodo);
  handleChangeTitle = this.withSelectedIndex(_handleChangeTitle);
  handleDateChange = this.withSelectedIndex(_handleDateChange);
  handleToggleSubtask = this.withSelectedIndex(_handleToggleSubtask);
  handleDeleteSubtask = this.withSelectedIndex(_handleDeleteSubtask);
  handleUpdateSubtask = this.withSelectedIndex(_handleUpdateSubtask);
  handleCreateSubtask = this.withSelectedIndex(_handleCreateSubtask);
  onSelectClient = this.withSelectedIndex(_onSelectClient);
  onSelectTeam = this.withSelectedIndex(_onSelectTeam);
  handleAddComment = this.withSelectedIndex(_handleAddComment);
  handleDeleteComment = this.withSelectedIndex(_handleDeleteComment);
  handleSelectFollowers = this.withSelectedIndex(_handleSelectFollowers);
  onDragEnd = this.withSelectedIndex(_onDragEnd);
  handleAddTemplateData = this.withSelectedIndex(_handleAddTemplateData);

  handleDescriptionKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.handleChangeDescription({
        descriptionEditorState: newState,
        todoId: this.state.data[this.state.selectedTodoIndex].id
      });
    }
  };

  onChangeCommentEditor = commentEditorState => {
    this.setState({ commentEditorState });
  };

  renderTemplateOptions = todoId => {
    const currentUser = this.props.user.authentication.data.user_id;
    const userClients = this.props.clients.index.data
      .filter(client => {
        return client.manager_id === currentUser;
      })
      .map(client => ({
        title: client.name,
        completed: false
      }));
    let data;

    try {
      data = {
        ...this.props.templates.index.data,
        Accounts: [
          { id: 0, data: JSON.stringify(userClients), title: "My Clients" },
          ...this.props.templates.index.data.Accounts
        ]
      };
    } catch (e) {
      console.warn("renderTemplateOptions");
    }

    return (
      <TemplatesDropdown
        data={data}
        todoId={todoId}
        handleAddTemplateData={this.handleAddTemplateData}
      />
    );
  };

  render() {
    const currentUser = this.props.user.authentication.data.user_id;
    const followerOptions = this.props.user.index.data.slice(0).map(user => ({
      ...user,
      full_name: `${user.first_name} ${user.last_name}`
    }));

    return (
      <div className="w-100 d-flex justify-content-center">
        <Sidebar
          teams={this.props.teams}
          clients={this.props.clients}
        />

        <Todos
          data={this.state.data}
          isFetching={this.props.todos.index.isFetching}
          title={this.state.sectionTitle}
          filter={this.state.todosFilter}
          filterTeam={this.state.filterTeam}
          filterClient={this.state.filterClient}
          filterSearch={this.state.filterSearch}
          selectedTodo={this.state.selectedTodoId}
          formInput={this.state.newTodoTitle}
          isCreatingNewTodo={this.props.todos.new.isCreating}
          handleRefChange={(input, todo) => (this[`input-${todo.id}`] = input)}
          handleFilterTeam={e => this.setState({ filterTeam: e.target.value })}
          handleFilterClient={e =>
            this.setState({ filterClient: e.target.value })
          }
          handleFilterChange={e =>
            this.setState({ todosFilter: e.target.value })
          }
          handleFilterSearch={e =>
            this.setState({ filterSearch: e.target.value })
          }
          handleToggleCheckbox={({ id: todoId }) =>
            this.handleToggleTodo({ todoId })
          }
          handleInputChange={(e, { id: todoId }) =>
            this.handleChangeTitle({ e, currentUser, todoId })
          }
          handleInputFocus={(e, { id: todoId }) => this.onFocus({ todoId })}
          handleFormInputChange={e =>
            this.setState({ newTodoTitle: e.target.value })
          }
          handleFormFocus={() =>
            this.setState({ showDetails: false, selectedTodoId: null })
          }
          handleFormSubmit={this.handleCreateTodo}
          routeType={getRouteType(window.location.pathname)}>
          <Todos.Header />
          <Todos.List />
          <Todos.Form />
        </Todos>

        {/* DETAIL CARD */}
        {this.state.showDetails && (
          <section className="todos-detail">
            <section
              className="todos-detail-top"
              style={{
                paddingBottom: this.commentBox && this.commentBox.clientHeight
              }}>
              {/* Start Header */}
              <section className="todos-detail__header">
                <section className="left">
                  <ProfileCircle
                    profileImage={
                      this.state.data[this.state.selectedTodoIndex].user
                        .profile_image
                    }
                    firstName={
                      this.state.data[this.state.selectedTodoIndex].user
                        .first_name
                    }
                    lastName={
                      this.state.data[this.state.selectedTodoIndex].user
                        .last_name
                    }
                  />

                  <span>{`${
                    this.state.data[this.state.selectedTodoIndex].user
                      .first_name
                  } ${
                    this.state.data[this.state.selectedTodoIndex].user.last_name
                  }`}</span>
                </section>

                <section className="right">
                  {this.state.data[this.state.selectedTodoIndex].user.id ===
                    currentUser &&
                    this.renderTemplateOptions(
                      this.state.data[this.state.selectedTodoIndex].id
                    )}
                  <DatePicker
                    ref={datePicker => (this.datePicker = datePicker)}
                    placeholderText="Due Date"
                    isClearable
                    selected={this.state.dueDate}
                    onChange={dueDate =>
                      this.handleDateChange({
                        dueDate,
                        todoId: this.state.data[this.state.selectedTodoIndex].id
                      })
                    }
                    dateFormat="YYYY-MM-DD"
                  />
                </section>
              </section>
              {/* End Header */}

              {/* Start Assignment*/}
              <section className="todos-detail__assignments">
                <Select
                  placeholder="Assign to Client"
                  onFocus={() => this.datePicker.cancelFocusInput()}
                  name="form-field-name"
                  value={this.state.selectedTodoClient.name}
                  onChange={client =>
                    this.onSelectClient({
                      client,
                      todoId: this.state.data[this.state.selectedTodoIndex].id
                    })
                  }
                  options={this.props.clients.index.data}
                  valueKey="name"
                  labelKey="name"
                />
                <Select
                  placeholder="Assign to Team"
                  onFocus={() => this.datePicker.cancelFocusInput()}
                  name="form-field-name"
                  value={this.state.selectedTodoTeam.name}
                  onChange={team =>
                    this.onSelectTeam({
                      team,
                      todoId: this.state.data[this.state.selectedTodoIndex].id
                    })
                  }
                  options={this.props.teams.data}
                  valueKey="name"
                  labelKey="name"
                />
              </section>
              {/* End Assignment */}

              {/* Start Followers*/}
              <section className="todos-detail__followers">
                <Select
                  placeholder="Assign Followers"
                  multi={true}
                  onFocus={() => this.datePicker.cancelFocusInput()}
                  name="form-field-name"
                  options={followerOptions}
                  value={this.state.selectedTodoFollowers}
                  valueKey="full_name"
                  labelKey="full_name"
                  onChange={value =>
                    this.handleSelectFollowers({
                      value,
                      todoId: this.state.data[this.state.selectedTodoIndex].id
                    })
                  }
                />
              </section>
              {/* End Followers */}

              {/* Start Description */}
              <section className="todos-detail__description">
                <h2 className="todos-detail__title">
                  {this.state.data[this.state.selectedTodoIndex].title}
                </h2>

                <Editor
                  placeholder="Description"
                  editorState={this.state.descriptionEditorState}
                  onChange={descriptionEditorState =>
                    this.handleChangeDescription({
                      descriptionEditorState,
                      todoId: this.state.data[this.state.selectedTodoIndex].id
                    })
                  }
                  handleKeyCommand={this.handleDescriptionKeyCommand}
                  plugins={[linkifyPlugin]}
                />
              </section>
              {/* End Description */}

              {/* Start Subtasks */}
              <section className="">
                <Subtasks>
                  <Subtasks.List
                    data={this.state.subtasks}
                    todoId={this.state.data[this.state.selectedTodoIndex].id}
                    onDragEnd={this.onDragEnd}
                    handleDeleteSubtask={this.handleDeleteSubtask}
                    handleToggleSubtask={this.handleToggleSubtask}
                    handleUpdateSubtask={this.handleUpdateSubtask}
                  />
                  <Subtasks.Form
                    onSubmit={this.handleCreateSubtask}
                    value={this.state.newSubtaskTitle}
                    todoId={this.state.data[this.state.selectedTodoIndex].id}
                    onChange={e =>
                      this.setState({ newSubtaskTitle: e.target.value })
                    }
                  />
                </Subtasks>
              </section>
              {/* End Subtasks */}

              {/* Start Comments */}
              {this.state.data[this.state.selectedTodoIndex].comments.length >
                0 && (
                <section className="">
                  <Comments>
                    {this.state.data[this.state.selectedTodoIndex].comments
                      .length > 0 && <Comments.Header />}
                    {this.state.data[this.state.selectedTodoIndex].comments
                      .length > 0 && (
                      <Comments.List
                        data={
                          this.state.data[this.state.selectedTodoIndex].comments
                        }
                        handleDeleteComment={this.handleDeleteComment}
                        todoId={
                          this.state.data[this.state.selectedTodoIndex].id
                        }
                        isOwner={
                          currentUser ===
                          this.state.data[this.state.selectedTodoIndex].user.id
                        }
                      />
                    )}
                  </Comments>
                </section>
              )}
              {/* End Comments */}
            </section>

            <section
              onClick={() => this.commentEditor.focus()}
              ref={el => (this.commentBox = el)}
              className="todos-comments-form">
              <Comments.Form
                setRef={el => (this.commentEditor = el)}
                isCreating={this.props.todos.new.isCreatingComment}
                editorState={this.state.commentEditorState}
                onChange={this.onChangeCommentEditor}
                onSubmit={this.handleAddComment.bind(null, {
                  todoId: this.state.data[this.state.selectedTodoIndex].id
                })}
              />
            </section>
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ todos, clients, teams, user, templates }) => ({
  todos,
  clients,
  teams,
  user,
  templates
});

const connected = connect(mapStateToProps, {
  ...actions,
  ...clientsActions,
  ...teamsActions,
  ...usersActions,
  ...templatesActions
})(TodosIndex);

export { connected as TodosIndex };
