import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "screens/users/actions";
import { Card, FormGroup, Button } from "common";
import ProfileImage from "../../clients/components/ProfileImage";
import { handleInputChange } from "lib/utilities";


class EditUser extends Component {
    state = {
        first_name: "",
        last_name: "",
        email: "",
        job: "",
        isFetching: true
    };

    async componentDidMount() {
        const user_id = this.props.match.params.id;
        await this.props.fetchOneUser(user_id)
        this.setState((state, props) => {
            return {
                first_name: props.user.user_info.data.first_name,
                last_name: props.user.user_info.data.last_name,
                job: props.user.user_info.data.job,
                user_image: props.user.user_info.data.profile_image,
                email: props.user.user_info.data.email,
                isFetching: false,
            }
        })
    }

    leavePage = e => {
        e.preventDefault();
        this.props.history.push('/');
    }

    handleSubmit = e => {
        e.preventDefault();
        const user_id = this.props.match.params.id;
        const { first_name, last_name, job } = this.state;
        // this.props.authenticateUser({ email, password });
        this.props.updateOneUser(
            user_id,
            {
                first_name,
                last_name,
                job,
            })
    };

    render() {
        const user_id = this.props.match.params.id;
        const history = this.props.history;
        return (
            <Card
                hideHeader
                title="Edit Account"
                classes="col-sm-12 col-md-6 offset-md-3"
                padding={5}
                isLoading={this.state.isFetching}
            >
                <h6 className="centerText">{this.state.email}</h6>
                <FormGroup
                    label="First Name"
                    value={this.state.first_name || ""}
                    onChange={e => handleInputChange(this, e, "first_name")}
                />
                <FormGroup
                    label="Last Name"
                    value={this.state.last_name || ""}
                    onChange={e => handleInputChange(this, e, "last_name")}
                />
                <FormGroup
                    label="Role"
                    value={this.state.job || ""}
                    onChange={e => handleInputChange(this, e, "job")}
                />
                <label>Upload Profile Picture</label>
                <ProfileImage
                    history={history}
                    userImage={this.props.user.user_info.data.profile_image}
                    forUserProfileImage={true}
                    userId={user_id}
                    updateUserImage={this.props.updateUserImage}
                />
                <Button
                    idforSpacing={"edit_user_buttons"}
                    onClick={this.handleSubmit}
                >
                    Save Edits
                    </Button>
                <Button
                    classes={"cancel-btn"}
                    idforSpacing={"edit_user_buttons"}
                    onClick={this.leavePage}

                >
                    Cancel
                    </Button>
            </Card>
        );
    }
}

// EditUser.propTypes = {
//     user: PropTypes.object,
//     authenticateUser: PropTypes.func,
//     lastVisited: PropTypes.string
// };

const mapStateToProps = ({ user, lastVisited }) => ({ user, lastVisited });

const connected = connect(mapStateToProps, actions)(EditUser);

export { connected as EditUser };
