import React, { Component, Fragment } from "react";
import { string, number, oneOfType, bool } from "prop-types";
import { connect } from "react-redux";
import { RedisButton } from "common";
import {
  fetchSERankingsAccounts,
  formatSERankingsAccounts
} from "screens/sites/helpers/rankings_helpers";

import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";
import ThirdPartyDisconnect from "screens/sites/components/ThirdPartyDisconnect";
import ThirdPartyConnect from "screens/sites/components/ThirdPartyConnect";
import SERankings from "screens/sites/components/SERankings";

class Rankings extends Component {
  static propTypes = {
    siteId: oneOfType([string, number]),
    isPublic: bool,
    se_site_id: string
  };

  state = {
    clearCacheBool: false,
  }

  render() {
    let context = this;
    return (
      <div className="col-sm-12">
        <ThirdPartyFallback id={this.props.se_site_id} type="Rankings" />
        {!this.props.isPublic && (
          <Fragment>
            <ThirdPartyConnect
              siteId={this.props.siteId}
              fetchAccounts={fetchSERankingsAccounts}
              formatAccounts={formatSERankingsAccounts}
              type="se_site_id"
              displayName="SE Rankings"
            />
            <ThirdPartyDisconnect
              siteId={this.props.siteId}
              type="se_site_id"
              displayName="SE Rankings"
            />
          </Fragment>
        )}
        <SERankings clearCacheBool={this.state.clearCacheBool} />
        <RedisButton isPublic ={this.props.isPublic} context={context} />
      </div>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: { permissions: { isPublic }, data: { se_site_id, id: siteId } }
  }
}) => ({
  siteId,
  se_site_id,
  isPublic
});

export default connect(mapStateToProps)(Rankings);
