import React, { Component, Fragment } from "react";
import {
    LineChart,
    XAxis,
    YAxis,
    Line,
    Legend,
    ResponsiveContainer
} from "recharts";
import moment from "moment";
import { Panel } from "common";

import { getStartDate } from "screens/sites/helpers/date_helpers";

class DemoGoogleAdwordsMonthlyConversions extends Component {

    render() {
        // Fixtures
        const date = {
            "month": "8",
            "year": "2018"
        }
        const startDate = getStartDate(date);
        const isFetching = false;
        const adwordsPerformance = {
            "totals": {
                "Cost": {
                    "title": "Cost",
                    "value": "$2,482.12",
                    "change": "-$34.67",
                    "positiveChange": false
                },
                "Clicks": {
                    "title": "Clicks",
                    "value": "420",
                    "change": "-42",
                    "positiveChange": false
                },
                "Conversions": {
                    "title": "Conversions",
                    "value": "9.8",
                    "change": "-4.4",
                    "positiveChange": false
                },
                "Cost_conv": {
                    "title": "Cost / Conv",
                    "value": "$253.28",
                    "change": "$76.04",
                    "positiveChange": true
                }
            },
            "reports": [
                {
                    "day": "01",
                    "Aug": 0,
                    "Sep": 0
                },
                {
                    "day": "02",
                    "Aug": 1,
                    "Sep": 0.8
                },
                {
                    "day": "03",
                    "Aug": 2,
                    "Sep": 0.8
                },
                {
                    "day": "04",
                    "Aug": 3,
                    "Sep": 0.8
                },
                {
                    "day": "05",
                    "Aug": 3,
                    "Sep": 0.8
                },
                {
                    "day": "06",
                    "Aug": 3,
                    "Sep": 0.8
                },
                {
                    "day": "07",
                    "Aug": 3,
                    "Sep": 1.8
                },
                {
                    "day": "08",
                    "Aug": 4,
                    "Sep": 2.8
                },
                {
                    "day": "09",
                    "Aug": 4,
                    "Sep": 2.8
                },
                {
                    "day": "10",
                    "Aug": 4,
                    "Sep": 3.8
                },
                {
                    "day": "11",
                    "Aug": 6,
                    "Sep": 3.8
                },
                {
                    "day": "12",
                    "Aug": 7,
                    "Sep": 4.8
                },
                {
                    "day": "13",
                    "Aug": 7,
                    "Sep": 5.8
                },
                {
                    "day": "14",
                    "Aug": 7,
                    "Sep": 5.8
                },
                {
                    "day": "15",
                    "Aug": 7,
                    "Sep": 5.8
                },
                {
                    "day": "16",
                    "Aug": 7,
                    "Sep": 5.8
                },
                {
                    "day": "17",
                    "Aug": 7,
                    "Sep": 5.8
                },
                {
                    "day": "18",
                    "Aug": 7,
                    "Sep": 5.8
                },
                {
                    "day": "19",
                    "Aug": 7,
                    "Sep": 5.8
                },
                {
                    "day": "20",
                    "Aug": 8,
                    "Sep": 5.8
                },
                {
                    "day": "21",
                    "Aug": 8,
                    "Sep": 5.8
                },
                {
                    "day": "22",
                    "Aug": 8,
                    "Sep": 6.8
                },
                {
                    "day": "23",
                    "Aug": 8,
                    "Sep": 6.8
                },
                {
                    "day": "24",
                    "Aug": 9,
                    "Sep": 8.8
                },
                {
                    "day": "25",
                    "Aug": 9.8,
                    "Sep": 8.8
                },
                {
                    "day": "26",
                    "Aug": 10.200000000000001,
                    "Sep": 9.8
                },
                {
                    "day": "27",
                    "Aug": 11.200000000000001,
                    "Sep": 9.8
                },
                {
                    "day": "28",
                    "Aug": 12.200000000000001,
                    "Sep": 9.8
                },
                {
                    "day": "29",
                    "Aug": 14.200000000000001,
                    "Sep": 9.8
                },
                {
                    "day": "30",
                    "Aug": 14.200000000000001,
                    "Sep": 9.8
                },
                {
                    "day": "31",
                    "Aug": 14.200000000000001,
                    "Invalid date": ""
                }
            ]
        };
        const thisMonth = startDate.substring(5, 7);
        const thisMonthMMM = moment(thisMonth, "MM").format("MMM");
        const lastMonthMMM = moment(thisMonth, "MM")
            .subtract("1")
            .format("MMM");

        return (
            <Fragment>
                <Panel
                    containerClass="col-md-6 p-3 float-left"
                    isLoading={isFetching}
                    padding={2}>
                    <Panel.Subtitle text="Month Vs Month Conversions" />
                    <Panel.Body
                        render={() => {
                            return (
                                <ResponsiveContainer height={230}>
                                    <LineChart
                                        data={adwordsPerformance.reports}
                                        margin={{ top: 5, right: 40, left: -20, bottom: 20 }}
                                    >
                                        <XAxis dataKey="day" />
                                        <YAxis />
                                        <Legend />
                                        <Line
                                            type="monotone"
                                            dataKey={lastMonthMMM}
                                            stroke="#CCCCCC"
                                            strokeWidth={3}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey={thisMonthMMM}
                                            stroke="#619bf9"
                                            strokeWidth={3}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            )
                        }}
                    />
                </Panel>
            </Fragment>
        );
    }
}

export default DemoGoogleAdwordsMonthlyConversions;

