import React, { Component, Fragment } from "react";
import { string, number, oneOfType, bool } from "prop-types";
import { connect } from "react-redux";

import DemoGoogleAdwordsOverview from "./DemoGoogleAdwordsOverview";
import DemoGoogleAdwordsCampaigns from "./DemoGoogleAdwordsCampaigns";
import DemoGoogleAdwordsMonthlyConversions from "./DemoGoogleAdwordsMonthlyConversions";
import DemoGoogleAdwordsConversionSources from "./DemoGoogleAdwordsConversionSources";
import DemoAdwordsMTM from "./DemoAdwordsMTM";
import DemoAdwordsMatrix from "./DemoAdwordsMatrix";

import DemoFacebookAdOverview from "./DemoFacebookAdOverview";
import DemoFacebookAdCampaigns from "./DemoFacebookAdCampaigns";

class DemoPaidAds extends Component {
    static propTypes = {
        siteId: oneOfType([string, number]),
        adwords_id: string,
        fb_ads_id: string,
        isPublic: bool
    };

    state = {
        tab: "Adwords"
    };

    toggleTab(e, tab) {
        e.preventDefault();
        this.setState({
            tab
        });
    }

    renderTab() {
        let { tab } = this.state;
        if (tab === "Adwords") {
            return (
                <div className="row">
                    <DemoGoogleAdwordsOverview />
                    <DemoAdwordsMTM />
                    <DemoGoogleAdwordsMonthlyConversions />
                    <DemoGoogleAdwordsConversionSources />
                    <DemoAdwordsMatrix />
                    <DemoGoogleAdwordsCampaigns />
                </div>
            )
        } else {
            return (
                <Fragment>
                    <DemoFacebookAdOverview />
                    <DemoFacebookAdCampaigns />
                </Fragment>
            );
        }
    }

    render() {
        let tabs = ["Adwords", "Facebook"];
        return (
            <div className="col-sm-12">

                <ul className="secondary-nav">
                    {tabs.map((el, i) => {
                        return <li key={i}><a className={(el === this.state.tab ? "active" : "")} onClick={e => this.toggleTab(e, el)}>{el}</a></li>
                    })}
                </ul>
                {this.renderTab()}
            </div>
        );
    }
}

const mapStateToProps = ({
    sites: {
        show: {
            permissions: { isPublic },
            data: { adwords_id, fb_ads_id, id: siteId }
        }
    }
}) => ({
    siteId,
    adwords_id,
    fb_ads_id,
    isPublic
});

export default connect(mapStateToProps)(DemoPaidAds);
