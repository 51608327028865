export const ecommerceState = {
  ecommerceData: {
    // shopify
    shopifySales: {
      averageOrderValue: { value: null },
      repeatCustomerRate: { value: null },
      totalOrders: { value: null },
      totalSales: { value: null },
      clv: { value: null }
    },
    amazonReports: {
      error: false,
      totalSales: { title: "", value: "" },
      ordersCount: { title: "", value: "" }
    }
  }
};

export const ecommerceReducer = {
  // shopify
  FETCHING_SHOPIFY_SALES_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        ecommerceData: {
          ...state.show.ecommerceData,
          shopifySales: {
            ...payload
          }
        }
      }
    };
  },
  // amazon
  FETCH_AMAZON_REPORTS_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        ecommerceData: {
          ...state.show.ecommerceData,
          amazonReports: { ...payload }
        }
      }
    };
  }
};
