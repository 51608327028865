import React, { Component, Fragment } from "react";
import { func, number, string, object } from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

import { Button, Panel } from "common";
import { updateSite } from "screens/sites/actions";
import { allianceConnectAccess } from "lib/utilities";
class ThirdPartyConnect extends Component {
  static propTypes = {
    updateSite: func.isRequired,
    fetchAccounts: func.isRequired,
    formatAccounts: func.isRequired,
    siteId: number,
    type: string.isRequired,
    displayName: string.isRequired,
    siteData: object,
  };

  state = { accounts: [], selectedAccount: "", accountId: "" };
  mounted = true;

  async componentDidMount() {
    if (this.state.accounts.length) return;
    if (this.props.siteData[this.props.type]) return;
    this.props.fetchAccounts((accounts) => {
      this.mounted && this.setState({ accounts });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleAccountChange = (selectedAccount, type) => {
    selectedAccount =
      selectedAccount === null ? { value: "" } : selectedAccount;

    this.setState({
      [type]: selectedAccount.id,
      selectedAccount,
    });
  };

  render() {
    const userRoleId = this.props.userRoleId;
    const canAccessConnect = allianceConnectAccess(userRoleId)
    if (!this.props.siteId) return null;
    if (this.props.siteData[this.props.type]) return null;
    if(!canAccessConnect) return null

    let propertyOptions = (this.state.accounts || []).map(
      this.props.formatAccounts
    );

    return (
      <Panel containerClass="col-md-12 p-3">
        <Panel.Header title={`Connect ${this.props.displayName}`} />
        <Panel.Body
          className="account-connect-body"
          render={() => (
            <Fragment>
              <Select
                name="form-field-name"
                placeholder="Select Account"
                value={this.state.selectedAccount}
                onChange={(item) => this.handleAccountChange(item, "accountId")}
                options={propertyOptions}
              />
              <Button
                onClick={() =>
                  this.props.updateSite(this.props.siteId, {
                    [this.props.type]: this.state.accountId,
                  })
                }
              >
                Select
              </Button>
            </Fragment>
          )}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteData: state.sites.show.data,
    userRoleId: ((state.user.user_info || {}).data || {}).role_id,
  };
};


export default connect(mapStateToProps, { updateSite })(ThirdPartyConnect);
