import axios from "axios";
import { rootUrl } from "lib/api";
import { getToken, requestHeaders } from "lib/authHelpers";
import { dispatchHelper } from "lib/actionHelpers";
import { authLogoutAndRedirect } from "screens/users/actions";

//const toolsCache = await caches.open('tools-cache');

export const fetch = ({ action, url, callback, cache }) => {
  return async (dispatch) => {
    const dispatching = dispatchHelper(action);
    const cacheKey = `crm-hubble-${url}`;

    let start = new Date();
    let payload = {};
    if (cache === true) {
      try {
        const item = localStorage.getItem(cacheKey);
        if (item !== null && String(item) !== "undefined") {
          payload = JSON.parse(item);
        }
      } catch (e) {}
    }

    dispatch({
      type: `${action}`,
      payload,
    });
    const token = getToken();
    const fullUrl = `${rootUrl}${url}`;
    const headers = requestHeaders(token);
    try {
      const response = await axios.get(fullUrl, headers);
      if (cache === true) {
        if (response.status === 200) {
          localStorage.setItem(cacheKey, JSON.stringify(response.data));
        }
      }
      dispatching(response);
      callback();
    } catch (err) {
      dispatch(authLogoutAndRedirect());
    }
  };
};

export const fetchHubbleStats = (params, callback) =>
  fetch({
    action: "FETCHING_HUBBLE_STATS",
    url: `/tools/hubble/stats`,
    callback,
    cache: true,
  });

export const fetchHubbleSites = (callback) =>
  fetch({
    action: "FETCHING_HUBBLE_SITES",
    url: `/tools/hubble/sites`,
    callback,
    cache: true,
  });

export const fetchHubbleSite = (params, callback) =>
  fetch({
    action: "FETCHING_HUBBLE_SITE",
    url: `/tools/hubble/site?origin=${params.hostname}`,
    callback,
  });

export const fetchHubbleSiteStats = (params, callback) =>
  fetch({
    action: "FETCHING_HUBBLE_SITE_STATS",
    url: `/tools/hubble/site-stats?origin=${params.hostname}`,
    callback,
  });

export const fetchHubbleSiteDetails = (params, callback) =>
  fetch({
    action: "FETCHING_HUBBLE_SITE_DETAILS",
    url: `/tools/hubble/site-details?page-hash=${params.pageHash}`,
    callback,
  });
