import React, { Component } from 'react';
import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { formatDate, parseDate } from 'react-day-picker/moment';

class DateRange extends Component {
    state = {
        from: undefined,
        to: undefined,
    };


    componentDidUpdate(prevProps, prevState) {
        if (this.state.from !== undefined && this.state.to !== undefined) {
            if (this.state.from !== prevState.from || this.state.to !== prevState.to) {
                this.props.liftDateRanges(this.props.dateType, this.state);
            }
        }
    }

    componentDidMount() {
        if (this.props.dateType === 1) {
            let from = moment(this.props.initialDate.startDate);
            let to = moment(this.props.initialDate.endDate)
            this.setState({ from: from._d, to: to._d })
        }
    }

    showFromMonth = () => {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }
    handleFromChange = (from) => {
        // Change the from date and focus the "to" input field
        this.setState({ from });
    }
    handleToChange = (to) => {
        this.setState({ to }, this.showFromMonth);
    }
    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo multiple-calendar-inputs">
                <DayPickerInput
                    value={from}
                    placeholder="From"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: moment().toDate() },
                        toMonth: to,
                        modifiers,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                />{' '}
                —
                {' '}
                <span className="InputFromTo-to multiple-calendar-inputs">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder="To"
                        format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { after: moment().toDate() },
                            modifiers,
                            month: from,
                            fromMonth: from,
                            numberOfMonths: 2,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
            </div>
        );
    }
}

export { DateRange }