import { ViewWithLoader } from "common";
import React, { Component } from "react";
import yelp_logo from "images/yelp_logo.png";
import google_logo from "images/google_logo.png";

import fb_logo from "images/fb_logo_reviews.png";
import ReviewPanelContent from "../../common/ReviewPanelContent";

class DemoReviewPanel extends Component {
    state = {
        yelpId: 1,
        googleId: 1,
        fbPageId: 1,
        yelpData: {
            review_count: 45,
            rating: 4.9,
        },
        googleData: {
            rating: 5,
            user_ratings_total: 58,
        },
        fbData: {
            overall_star_rating: 5,
            rating_count: 76
        },
        reviewSiteId: 1,
    }

    nothing = () => {
        console.log("Demo CRM")
    }

    render() {
        return (
            <ViewWithLoader isLoading={this.props.isLoading}>
                <ReviewPanelContent
                    updateSite={() => { }}
                    yelpData={this.props.yelpData.rating}
                    yelpReviewCount={this.props.yelpData.review_count}
                    fbData={this.props.fbData.rating_count}
                    facebookReviewCount={this.props.fbData.rating_count}
                    reviewSiteId={this.props.reviewSiteId}
                    googleData={this.props.googleData.rating}
                    googleReviewCount={this.props.googleData.user_ratings_total}
                    isLoading={false}
                    name={this.props.name}
                    logo={this.props.logo}
                    fbPageId={this.props.fbPageId}
                    noOptionClass={'cursor-no'}
                    isPublic={true}
                    generateURL={this.nothing}
                />
            </ViewWithLoader>
        )
    }
};


export { DemoReviewPanel };
