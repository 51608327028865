import jwtDecode from "jwt-decode";
import { createReducer } from "../../lib/utilities";

const initialState = {
  index: {
    data: []
  },
  new: {
    data: {}
  },
  activation: {
    data: {}
  },
  authentication: {
    data: {}
  },
  reset: {
    data: {}
  },
  user_info: {
    data: {
      client_id: "",
      email: "",
      first_name: "",
      id: null,
      job: "",
      last_name: "",
      profile_image: null,
      role_id: null,
    }
  }
};

export default createReducer(initialState, {
  FETCHING_USERS_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: payload
    }
  }),
  FETCHING_ONE_USER_SUCCESS: (state, payload) => ({
    ...state,
    user_info: {
      ...state.user_info,
      data: payload
    }
  }),
  UPDATE_ONE_USER_SUCCESS: (state, payload) => ({
    ...state,
    user_info: {
      ...state.user_info,
      data: payload
    }
  }),
  UPDATING_USER_IMAGE_SUCCESS: (state, payload) => ({
    ...state,
    user_info: {
      ...state.user_info,
      data: {
        profile_image: {
          payload
        }
      },
    }
  }),
  // UPDATE_ONE_USER_FAIL: (state, payload) => ({
  //   ...state,
  //   user_info: {
  //     ...state.user_info,
  //     data: payload
  //   }
  // }),
  // TODO: Notifcation for fetching user fail
  // FETCHING_ONE_USER_FAIL: (state, payload) => ({
  //   ...state,
  //   index: {
  //     ...state.index,
  //     data: payload
  //   }
  // }),
  CREATING_USER_SUCCESS: state => ({
    ...state,
    new: {
      ...state.new,
      data: {
        message: "An invitation has been sent"
      }
    }
  }),
  CREATING_PASSWORD_SUCCESS: state => ({
    ...state,
    activation: {
      ...state.activation,
    }
  }),
  AUTHENTICATING_USER_SUCCESS: (state, payload) => {
    localStorage.setItem("token", payload.token);
    return {
      ...state,
      authentication: {
        ...state.authentication,
        data: payload
      }
    };
  },
  RESETTING_PASSWORD_SUCCESS: (state, payload) => ({
    ...state,
    reset: {
      ...state.reset,
      success: payload
    }
  }),
  RESETTING_PASSWORD_FAIL: (state, payload) => ({
    ...state,
    reset: {
      ...state.reset,
      error: payload
    }
  }),
  AUTHENTICATING_USER_FAIL: (state, payload) => ({
    ...state,
    authentication: {
      ...state.authentication,
      errors: payload
    }
  }),
  AUTHENTICATION_REDIRECT: (state, payload) => {
    let jwt = jwtDecode(payload);

    return {
      ...state,
      authentication: {
        ...state.authentication,
        data: {
          ...state.authentication.data,
          token: payload,
          first_name: jwt.first_name,
          last_name: jwt.last_name,
          user_id: jwt.id,
          role: {
            id: parseInt(jwt.role_id, 10)
          }
        }
      }
    }
  },
  LOG_OUT: () => initialState
});
