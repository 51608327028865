import _ from "lodash";
import React, { Component } from "react";
import { func, number, shape, string, bool } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { fetchSite, fetchLeadAnalytics } from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { push } from "react-router-redux";
import { store } from "store";

class SiteOverview extends Component {
  static propTypes = {
    fetchAllSites: bool,
    fetchSite: func,
    wc_token: string,
    wc_secret: string,
    total_leads: number,
    lead_type: string,
    date: shape({
      month: string,
      year: string,
    }),
    isPublic: bool,
    public_hash: string,
  };

  static defaultProps = {
    isPublic: false,
  };

  state = { isFetching: false };

  mounted = true;

  componentDidMount() {
    // if (!this.props.wc_token || !this.props.wc_secret) return;
    this.setState({
      id: this.props.site_id,
      public_hash: this.props.public_hash,
    });
    // PERFORMANCE REFACTOR
    // This fetchData doesn't seem to be needed. Leaving it commented in case any issues arise.
    // this.fetchData({
    //   startDate: getStartDate(this.props.date),
    //   endDate: getEndDate(this.props.date),
    //   page: 1,
    //   type: "",
    //   token: this.props.wc_token,
    //   secret: this.props.wc_secret,
    //   hash: this.props.public_hash,
    //   isPublic: this.props.isPublic,
    //   siteId: this.props.site_id,
    // });
  }

  componentWillUnmount() {
    store.dispatch({
      type: "RESET_TOTAL_LEADS",
      payload: null,
    });
    this.mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const hasDifferentMonth = this.props.date.month !== prevProps.date.month;
    const hasDifferentYear = this.props.date.year !== prevProps.date.year;
    const hasDifferentSecret = this.props.wc_secret !== prevProps.wc_secret;

    const shouldRefreshSite =
      this.state.id !== prevState.id ||
      this.state.public_hash !== prevState.public_hash;

    if (
      hasDifferentMonth ||
      hasDifferentYear ||
      hasDifferentSecret ||
      shouldRefreshSite
    ) {
      this.setState({ filterLeadsBy: "", currentPage: 0 });
      const params = {
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        compare: 6,
        mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
        hash: this.props.public_hash,
        isPublic: true,
      };

      if (shouldRefreshSite) {
        params.siteId = this.state.id;
        params.hash = this.state.public_hash;
      }
      this.fetchData(params);
    }
  }

  shouldComponentUpdate(nextProps) {
    // return true
    // prevents "total leads" from udating when toggling between
    // the different lead types

    const monthHasChanged = this.props.date.month !== nextProps.date.month;
    const yearHasChanged = this.props.date.year !== nextProps.date.year;

    if (monthHasChanged || yearHasChanged) return true;

    return !this.props.lead_type && !nextProps.lead_type;
  }

  fetchData = (params) => {
    this.mounted && this.setState({ isFetching: true });
    // This fetchSite doesn't seem to be needed. Leaving it commented in case any issues arise.
    // PERFORMANCE REFACTOR
    // if (this.props.isPublic) {
    //   this.props.fetchSite(params);
    // }
    this.props.fetchLeadAnalytics(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  handleSiteChange = ({ id, public_hash }) => {
    const hash = window.location.hash ? window.location.hash : "";

    this.setState({ id, public_hash }, () => {
      this.fetchData({
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
        hash: this.props.public_hash,
        isPublic: true,
        compare: this.props.compare,
      });
    });
    this.props.isPublic
      ? store.dispatch(
          push(`/public/sites/${public_hash}${window.location.search}${hash}`)
        )
      : store.dispatch(push(`/sites/${id}${hash}`));
    window.location.reload();
  };

  renderSiteList = () => {
    const sites = this.props.site_list;
    const name = this.props.site_name;
    if (_.isEmpty(sites)) return null;

    const siteList = sites
      .filter((site) => site.name !== name)
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

    return (
      <ul className="mb-0">
        {siteList.map((site) => {
          return (
            <li key={site.id} onClick={this.handleSiteChange.bind(this, site)}>
              {site.name}
            </li>
          );
        })}
      </ul>
    );
  };

  onNavigateToClient = (id) => {
    if (this.props.isPublic) return;
    store.dispatch(push(`/clients/${id}`));
  };

  render() {
    const { month, year } = this.props.date;
    if (this.props.launch_date === null || "" || undefined) {
      var launchDateFormatted = "N/A";
    } else {
      const launchYear = this.props.launch_date.slice(0, 4);
      const launchMonth = this.props.launch_date.slice(5, 7);
      const launchDate = launchMonth.concat(launchYear);
      launchDateFormatted = launchDate.slice(0, 2) + "/" + launchDate.slice(2);
    }
    const sites = _.isUndefined(this.props.site_list)
      ? [{}]
      : this.props.site_list;

    return (
      <div className="col-xl-12 pb-5">
        <div className="site-meta">
          <div className="row pt-5">
            <div className="col-md-3 site-stat site-client">
              <img src={this.props.client_image} alt="" />
              <h3
                onClick={this.onNavigateToClient.bind(
                  this,
                  this.props.client_id
                )}
              >
                <span>Client</span>
                {this.props.client_name}
              </h3>
            </div>

            <div
              className={`col-md-3 site-stat ${
                sites.length > 1 ? "site-name" : ""
              }`}
            >
              <h3>
                <span>Website</span>
                {this.props.site_name}
                {sites.length > 1 && <i className="fas fa-chevron-down" />}
              </h3>
              {this.renderSiteList()}
            </div>

            <div className="col-md-2 site-stat">
              {!true && (
                <h3>
                  <span>Health</span>
                  {this.props.site_health}
                </h3>
              )}
              {true && (
                <h3>
                  <span>{year}</span>
                  {moment(Number(month) + 1, "MM").format("MMMM")}
                </h3>
              )}
            </div>
            <div className="col-md-2 site-stat site-leads">
              <h3>
                <span>Inquiries</span>
                {this.props.total_leads ? this.props.total_leads : "..."}
              </h3>
            </div>
            <div className="col-md-2 site-stat site-leads">
              <h3>
                <span>Launched</span>
                {launchDateFormatted}
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    date: state.sites.show.date,
    isPublic: state.sites.show.permissions.isPublic,
    public_hash: state.sites.show.data.public_hash,
    wc_token: state.sites.show.data.wc_token,
    wc_secret: state.sites.show.data.wc_secret,
    site_id: state.sites.show.data.id,
    site_name: state.sites.show.data.name,
    launch_date: state.sites.show.data.launch_date,
    site_health: state.sites.show.data.health,
    client_id: state.sites.show.data.client.id,
    client_name: state.sites.show.data.client.name,
    client_image: state.sites.show.data.client.profile_image,
    site_list: state.sites.show.data.client.sites,
    lead_type: state.sites.show.leadData.type.lead_type,
    total_leads: state.sites.show.leadAnalytics.total_leads,
  };
};

export default connect(mapStateToProps, { fetchSite, fetchLeadAnalytics })(
  SiteOverview
);
