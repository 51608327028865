import React, { Component } from "react";

import DemoWhatConvertsLeads from "./DemoWhatConvertsLeads";

class DemoLeads extends Component {
    render() {
        // Fixtures 
        const compare = 6;
        return (
            <div className="col-sm-12">
                <DemoWhatConvertsLeads changeComparison={this.props.changeComparison} compare={compare} />
            </div>
        )
    }
}

export default DemoLeads;
