import React, { Component, Fragment } from "react";
import PrimarySiteGoogleAnalyticsVisitorsByMonth from "./PrimarySiteGoogleAnalyticsVisitorsByMonth";
import PrimarySiteGoogleAnalyticsVisitorsByMonthAll from "./PrimarySiteGoogleAnalyticsVisitorsByMonthAll";
import PrimarySiteSessionsByMonth from "./PrimarySiteSessionsByMonth";
import ReportSubTitle from "../ReportSubTitle";
import PrimarySiteAnalyticsVisitorsByLocation from "./PrimarySiteAnalyticsVisitorsByLocation";
import logo from "images/icon_analytics.png";
import PrimarySiteTable from "./PrimarySiteTable";
import { unparse } from "papaparse";
import moment from "moment";
import downloadFile from "./DownloadFile";
import { connect } from "react-redux";

const labelMap = {
  newVisitorsPerSite: "New Visitors",
  primarySiteSessionsPerSite: "Sessions",
  primarySiteNewVisitorsByStateTableData: "New Visitors By State",
};

const filenameMap = {
  newVisitorsPerSite: "new-visitors",
  newVisitorsPerSiteAll: "new-visitors-all",
  primarySiteSessionsPerSite: "new-sessions",
  primarySiteNewVisitorsByStateTableData: "new-visitors-by-state",
};
class PrimarySiteAnalytics extends Component {
  state = {
    downloading: false,
  };

  setDownloading = (bool) => {
    this.setState({ downloading: bool });
  };

  downloadCSV = async (selectorKey, data, isLocationData = false) => {
    const rawData = this.props[selectorKey] || data;
    const currentYearKeyFileName = `${moment(new Date()).format("YYYY")}`;
    const previousMonthFilename = `${moment(new Date())
      .subtract("1", "months")
      .format("MMMM")}`;

    const currentYearKeyColumn = `${moment(new Date()).format("YY")}`;
    const previousYearKeyColumn = `${moment(new Date())
      .subtract("1", "years")
      .format("YY")}`;

    const fields = isLocationData
      ? ["Site Name", "Region", "City", currentYearKeyFileName]
      : [
          "Site Name",
          `Jan ${currentYearKeyColumn}`,
          `Feb ${currentYearKeyColumn}`,
          `Mar ${currentYearKeyColumn}`,
          `Apr ${currentYearKeyColumn}`,
          `May ${currentYearKeyColumn}`,
          `Jun ${currentYearKeyColumn}`,
          `Jul ${currentYearKeyColumn}`,
          `Aug ${currentYearKeyColumn}`,
          `Sep ${currentYearKeyColumn}`,
          `Oct ${currentYearKeyColumn}`,
          `Nov ${currentYearKeyColumn}`,
          `Dec ${currentYearKeyColumn}`,
          ...(this.props.displayYOY
            ? [
                `Jan ${previousYearKeyColumn}`,
                `Feb ${previousYearKeyColumn}`,
                `Mar ${previousYearKeyColumn}`,
                `Apr ${previousYearKeyColumn}`,
                `May ${previousYearKeyColumn}`,
                `Jun ${previousYearKeyColumn}`,
                `Jul ${previousYearKeyColumn}`,
                `Aug ${previousYearKeyColumn}`,
                `Sep ${previousYearKeyColumn}`,
                `Oct ${previousYearKeyColumn}`,
                `Nov ${previousYearKeyColumn}`,
                `Dec ${previousYearKeyColumn}`,
              ]
            : []),
        ];
    let prevData = [];
    let formattedData = (rawData || []).reduce((all, n, i) => {
      const nestedData = n.stats || n.data;
      all.push([!isLocationData ? `${n.name}` : n.site]);
      prevData.push([]);
      nestedData.forEach((z, q) => {
        all[i].push(z.currentData);
        if (this.props.displayYOY) {
          prevData[i].push(z.previousData);
        }
        if (this.props.displayYOY && q == nestedData.length - 1) {
          all[i] = [...all[i], ...prevData[i]];
        }
      });
      return all;
    }, []);

    const csv = unparse({ fields, data: formattedData });

    const filename = `${"analytics"}-report-${
      filenameMap[selectorKey]
    }-${previousMonthFilename}-${currentYearKeyFileName}.csv`;
    this.setDownloading(false);
    return downloadFile(filename, csv);
  };

  render() {
    return (
      <Fragment>
        <ReportSubTitle
          text="Analytics Roll Up Report"
          classes=""
          style={{
            paddingLeft: 0,
            marginBottom: 20,
          }}
          logo={logo}
        />

        <div
          style={{
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
            marginTop: 0,
          }}
          className="col-12 primary-site-yoy"
        >
          <div className="yoy-toggle-bar row">
            <div className="col-12 col-md-8">
              <h3>
                <i className="fa fa-info-circle"></i> Display YoY Data?
              </h3>
              <p>
                Turn on YoY data to share with client using the public link
                above. Clients cannot change this setting on their end.
              </p>
            </div>
            <div className="col-12 col-md-4 text-right">
              <button className="btn" onClick={() => this.props.toggleYOY()}>
                {this.props.displayYOY ? "Hide YoY" : "Show YoY"}
              </button>
            </div>
          </div>
        </div>

        <PrimarySiteGoogleAnalyticsVisitorsByMonth
          displayYOY={this.props.displayYOY}
          setDownloading={this.setDownloading}
          downloading={this.state.downloading}
          downloadCSV={this.downloadCSV}
          downloadCSVKey={"newVisitorsPerSite"}
        />

        <PrimarySiteSessionsByMonth
          displayYOY={this.props.displayYOY}
          setDownloading={this.setDownloading}
          downloading={this.state.downloading}
          downloadCSV={this.downloadCSV}
          downloadCSVKey={"primarySiteSessionsPerSite"}
        />

        <PrimarySiteTable
          dataSelector={"newVisitorsPerSite"}
          headerLabel={"Visitors"}
          side={"left"}
          loadStateSelector={"primarySiteNewVisitorsLoadState"}
          displayYOY={this.props.displayYOY}
        />

        <PrimarySiteTable
          dataSelector={"primarySiteSessionsPerSite"}
          headerLabel={"Sessions"}
          side={"right"}
          loadStateSelector={"primarySiteSessionsLoadState"}
          displayYOY={this.props.displayYOY}
        />

        <div style={{ width: "50%" }}>
          <PrimarySiteGoogleAnalyticsVisitorsByMonthAll
            useAllCol={true}
            displayYOY={this.props.displayYOY}
            setDownloading={this.setDownloading}
            downloading={this.state.downloading}
            downloadCSV={this.downloadCSV}
            downloadCSVKey={"newVisitorsPerSiteAll"}
          />
          <PrimarySiteTable
            useAllCol={true}
            dataSelector={"newVisitorsPerSiteAll"}
            headerLabel={"Sessions"}
            side={"right"}
            loadStateSelector={"primarySiteNewVisitorsAllLoadState"}
            displayYOY={this.props.displayYOY}
          />
        </div>

        <PrimarySiteAnalyticsVisitorsByLocation
          setDownloading={this.setDownloading}
          downloading={this.state.downloading}
          downloadCSVKey={"primarySiteNewVisitorsByStateTableData"}
          downloadCSV={this.downloadCSV}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newVisitorsPerSite:
      state.sites.show.primarySiteAnalyticsData.newVisitorsPerSite,
    primarySiteSessionsPerSite:
      state.sites.show.primarySiteAnalyticsData.primarySiteSessionsPerSite,
  };
};

export default connect(mapStateToProps, {})(PrimarySiteAnalytics);
