import React, { Component } from "react";
import { Panel } from "common";

import { ResponsiveContainer, BarChart, XAxis, Bar, Tooltip, Legend } from "recharts";
import { LikesPaidOrganicFixtures } from "./fixtures/PanelFixtures";

class DemoLikesPaidOrganic extends Component {

    render() {
        return (
            <Panel
                containerClass={"col-md-6 p-3 float-left yoy-graph yearly-graph"}
                isLoading={false}
                errors={null}
                padding={2}>
                <Panel.Subtitle text={"Likes Paid And Organic"} />
                <Panel.Body
                    render={() => {
                        return (
                            <ResponsiveContainer height={230}>
                                <BarChart
                                    width={600}
                                    height={300}
                                    data={LikesPaidOrganicFixtures}
                                    margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
                                    <XAxis tickLine={false} dataKey="label" />
                                    <Tooltip />
                                    <Legend />
                                    <Bar name={"Organic"} className={"yoy-bar"} dataKey="organic" stackId="b" fill="#619bf9" />
                                    <Bar name={"Paid"} className={"yoy-bar"} dataKey="paid" stackId="a" fill="#dce9fe" />
                                </BarChart>
                            </ResponsiveContainer>
                        );
                    }}
                />
            </Panel>
        )
    }
}

export { DemoLikesPaidOrganic };

