import React, { Component, Fragment } from "react";

class TestFilters extends Component {

  static propTypes = {};

  state = {};

  handleTestFilterToggle(testName) {
    let excludes = this.props.excludes || [];
    if (this.props.excludes.includes(testName)) {
      excludes = excludes.filter(n => String(n) !== String(testName));
    } else {
      excludes.push(testName);
    }
    this.props.onChange(excludes);
    localStorage.setItem('hubble-filter-excludes', JSON.stringify(excludes));
  }

  formatShortCategory(key) {
    return ({
      'SEO': 'SEO',
      'ACCESSIBILITY': 'A11Y'
    })[key];
  }

  render() {
    return (
        <div className={'test-filter-wrapper'}>
          {this.props.filters.map((n, idx) => (
            <div key={idx} className={`test-filter ${this.props.excludes.includes(n.key) ? 'disabled' : ''}`} onClick={e => this.handleTestFilterToggle(n.key)}>
              <span className={'test-filter-pill'}>{this.formatShortCategory(n.category)}</span>
              <span className={'test-filter-label'}>{n.label} <span className={'hint'}>({n.amount})</span></span>
            </div>
          ))}&nbsp;
        </div>
    );
  }
}

export default TestFilters;
