export const analyticsState = {
  analyticsData: {
    metrics: {
      "New Users": {},
      "Bounce Rate": {},
      Sessions: {},
      "Pages/Session": {},
      "Avg. Session Duration": {},
      "Page Views": {},
    },
    newVisitors: [],
    sessions: [],
    socialSources: {},
    devices: {},
    newVisitorsByState: [],
    topLanding: [],
    events: [],
    pageViews: [],
  },
  primarySiteAnalyticsData: {
    newVisitors: [],
    newVisitorsAll: [],
    primarySiteNewVisitorsLoadState: false,
    primarySiteNewVisitorsAllLoadState: false,
    newVisitorsPerSiteAll: [],
    primarySiteSessions: [],
    primarySiteNewVisitorsByState: [],
    primarySiteSessionsLoadState: false,
    primarySiteNewVisitorsByStateTableData: [],
    primarySiteNewVisitorsTableSiteMap: {},
  },
  ga4ModalVisibile: [],
  ga4ConfirmDisconnect: [],
  ga4Connect: {
    account_id: null,
    property_id: null,
    selectedAccount: "",
    selectedProperty: "",
    accounts: [],
    properties: [],
    accountsLoadState: false,
    propertiesLoadState: false,
  },
};

const formatPrimarySitePayload = (data) => {
  return Object.entries(data).map((n) => ({
    label: n[0],
    currentData: n[1].current,
    previousData: n[1].previous,
  }));
};

const insert = (arr, index, item) => {
  return [
    ...arr.slice(0, index), // first half
    ...item, // items to be inserted
    ...arr.slice(index), // second half
  ];
};

export const analyticsReducer = {
  SET_PRIMARY_SITE_TABLES_LOAD_STATE: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        primarySiteAnalyticsData: {
          ...state.show.primarySiteAnalyticsData,
          ...payload,
        },
      },
    };
  },
  SET_GA4_MODAL_VISBILITY: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        ga4ModalVisibile: state.show.ga4ModalVisibile.find((n) => n === payload)
          ? state.show.ga4ModalVisibile.filter((n) => n !== payload)
          : [...state.show.ga4ModalVisibile, payload],
      },
    };
  },
  SET_GA4_DISCONNECT: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        ga4ConfirmDisconnect: state.show.ga4ConfirmDisconnect.find(
          (n) => n === payload
        )
          ? state.show.ga4ConfirmDisconnect.filter((n) => n !== payload)
          : [...state.show.ga4ConfirmDisconnect, payload],
      },
    };
  },
  SET_GA4_CONNECT_DATA: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        ga4Connect: {
          ...state.show.ga4Connect,
          ...payload,
        },
      },
    };
  },
  SET_GOOGLE_ANALYTICS_CONNECT_PROPERTIES: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        data: {
          ...state.show.data,
          ...payload,
        },
      },
    };
  },
  CLEAR_INSERTED_TABLE_ROWS: (state, payload) => {
    const clearPrevNestedData = (
      state.show.primarySiteAnalyticsData
        .primarySiteNewVisitorsByStateTableData || []
    ).filter((n) => !n.isNestedData === true);

    return {
      ...state,
      show: {
        ...state.show,
        primarySiteAnalyticsData: {
          ...state.show.primarySiteAnalyticsData,
          primarySiteNewVisitorsByStateTableData: clearPrevNestedData,
        },
      },
    };
  },

  INJECT_TABLE_ROW: (state, payload) => {
    const clearPrevNestedData = (
      state.show.primarySiteAnalyticsData
        .primarySiteNewVisitorsByStateTableData || []
    ).filter((n) => !n.isNestedData === true);

    const dataToInsert = (
      clearPrevNestedData.find((n) => n.id === payload.siteId) || {}
    ).data;
    const formatInsertData = (dataToInsert || []).map((n, i) => ({
      ...n,
      isNestedData: true,
      isLast: i === (dataToInsert || []).length - 1 ? true : false,
    }));

    const insertData = insert(
      clearPrevNestedData,
      payload.expandIndex + 1,
      formatInsertData
    );
    return {
      ...state,
      show: {
        ...state.show,
        primarySiteAnalyticsData: {
          ...state.show.primarySiteAnalyticsData,
          primarySiteNewVisitorsByStateTableData: insertData,
        },
      },
    };
  },
  // google analytics
  FETCHING_GA_SITE_METRICS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        metrics: payload,
      },
    },
  }),
  FETCHING_GA_SITE_METRICS_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        metrics: payload,
      },
    },
  }),
  FETCHING_PRIMARY_SITE_GA_NEW_VISITORS_BY_STATE_SUCCESS: (state, payload) => {
    const formattedNewVisitorsByState = (payload || [])
      .reduce((a, n) => {
        n.data.map((z, i) => {
          if (z.region !== "(not set)" && z.city !== "(not set)") {
            a.push({
              position: i,
              region: z.region,
              city: z.city,
              lat: z.latitude,
              lng: z.longitude,
              newUsers: z.value,
              value: z.value,
            });
          }
        });
        return a;
      }, [])
      .sort((a, b) => a.value - b.value);

    const formattedTableData = payload
      .map((n) => ({
        label: n.site,
        topCity: (n.data || []).length ? n.data[0].city : null,
        topRegion: (n.data || []).length ? n.data[0].region : null,
        value: (n.data || []).length ? n.data[0].value : null,
        ...n,
      }))
      .sort((a, b) => b.value - a.value);

    return {
      ...state,
      show: {
        ...state.show,
        primarySiteAnalyticsData: {
          ...state.show.primarySiteAnalyticsData,
          primarySiteNewVisitorsByStateMapData: formattedNewVisitorsByState,
          primarySiteNewVisitorsByStateTableData: formattedTableData,
        },
      },
    };
  },
  FETCHING_PRIMARY_SITE_GA_NEW_VISITORS_SUCCESS: (state, payload) => {
    const formattedPayload = formatPrimarySitePayload(
      (payload || {}).totals || []
    );

    return {
      ...state,
      show: {
        ...state.show,
        primarySiteAnalyticsData: {
          ...state.show.primarySiteAnalyticsData,
          newVisitors: formattedPayload,
          newVisitorsPerSite: payload.sites,
        },
      },
    };
  },
  FETCHING_PRIMARY_SITE_GA_NEW_VISITORS_ALL_SUCCESS: (state, payload) => {
    const formattedPayload = formatPrimarySitePayload(
      (payload || {}).totals || []
    );

    return {
      ...state,
      show: {
        ...state.show,
        primarySiteAnalyticsData: {
          ...state.show.primarySiteAnalyticsData,
          newVisitorsAll: formattedPayload,
          newVisitorsPerSiteAll: payload.sites,
        },
      },
    };
  },
  FETCHING_PRIMARY_SITE_GA_SESSIONS_SUCCESS: (state, payload) => {
    const formattedPayload = formatPrimarySitePayload(
      (payload || {}).totals || []
    );
    return {
      ...state,
      show: {
        ...state.show,
        primarySiteAnalyticsData: {
          ...state.show.primarySiteAnalyticsData,
          primarySiteSessions: formattedPayload,
          primarySiteSessionsPerSite: payload.sites,
        },
      },
    };
  },
  FETCHING_GA_NEW_VISITORS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        newVisitors: payload,
      },
    },
  }),
  FETCHING_GA_NEW_VISITORS_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        newVisitors: payload,
      },
    },
  }),
  FETCHING_GA_SESSIONS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        sessions: payload,
      },
    },
  }),
  FETCHING_GA_SESSIONS_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        sessions: payload,
      },
    },
  }),
  FETCHING_GA_EVENTS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        events: payload,
      },
    },
  }),
  FETCHING_GA_EVENTS_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        events: payload,
      },
    },
  }),
  FETCHING_GA_SOCIAL_SOURCES_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        socialSources: payload,
      },
    },
  }),
  FETCHING_GA_SOCIAL_SOURCES_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        socialSources: payload,
      },
    },
  }),
  FETCHING_GA_DEVICES_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        devices: payload,
      },
    },
  }),
  FETCHING_GA_DEVICES_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        devices: payload,
      },
    },
  }),
  FETCHING_GA_NEW_VISITORS_BY_STATE_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        newVisitorsByState: payload,
      },
    },
  }),
  FETCHING_GA_NEW_VISITORS_BY_STATE_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        newVisitorsByState: payload,
      },
    },
  }),
  FETCHING_GA_TOP_LANDING_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        topLanding: payload,
      },
    },
  }),
  FETCHING_GA_PAGE_VIEWS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        pageViews: payload,
      },
    },
  }),
  FETCHING_GA_PAGE_VIEWS_GA4_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      analyticsData: {
        ...state.show.analyticsData,
        pageViews: payload,
      },
    },
  }),
};
