export const paidAdsState = {
  campaignsDropdown: [],
  paidAdsData: {
    // facebook
    fbCampaigns: { headers: [], data: [], error: "" },
    fbCampaignsDateTwo: { headers: [], data: [], error: "" },
    // google adwords
    adwordsPerformance: {
      totals: {
        Clicks: { value: "" },
        Cost: { value: "" },
        Conversions: { value: "" },
        Cost_conv: { value: "" },
      },
      reports: [],
    },
    adwordsPerformanceDateTwo: {
      totals: {
        Clicks: { value: "" },
        Cost: { value: "" },
        Conversions: { value: "" },
        Cost_conv: { value: "" },
      },
      reports: [],
    },
    adwordsPerformanceMonthVsMonth: {
      totals: {
        Clicks: { value: "" },
        Cost: { value: "" },
        Conversions: { value: "" },
        Cost_conv: { value: "" },
      },
      reports: [],
    },
    MCC_Accounts: [],
    adwords_mcc_id: null,
    adSources: [],
    adwordsPerformanceAcctLevel: {
      totals: {
        start: {
          Clicks: { value: 0 },
          Cost: { value: 0 },
          Conversions: { value: 0 },
          Cost_conv: { value: 0 },
        },
        Clicks: { value: 0 },
        Cost: { value: 0 },
        Conversions: { value: 0 },
        Cost_conv: { value: 0 },
      },
    },
    facebookPerformanceAcctLevel: { headers: [], data: [], error: "" },
    adwordsStatsBarDaily: {
      dateOne: {
        Impressions: { data: [] },
        Conversions: { data: [] },
        Avg_CPC: { data: [] },
        Cost: { data: [] },
        Sales: { data: [] },
        roas: { data: [] },
        forms: { data: [] },
        Clicks: { data: [] },
        VideoViews: { data: [] },
        CTR: { data: [] },
        Phone_calls: { data: [] },
      },
      adValuesDateOne: {},
      dateTwo: {
        Impressions: { data: [] },
        Conversions: { data: [] },
        Avg_CPC: { data: [] },
        Cost: { data: [] },
        Sales: { data: [] },
        roas: { data: [] },
        forms: { data: [] },
        Clicks: { data: [] },
        VideoViews: { data: [] },
        CTR: { data: [] },
        Phone_calls: { data: [] },
      },
      adValuesDateTwo: {},
    },
    facebookAdsStatsBarDaily: {
      campaignDoesNotExistInDateRangeError: false,
      dateOne: {
        impressions: { data: [] },
        conversions: { data: [] },
        cpc: { data: [] },
        ctr: { data: [] },
        sales: { data: [] },
        roas: { data: [] },
        formleads: { data: [] },
        clicks: { data: [] },
        spend: { data: [] },
      },
      adValuesDateOne: {},
      dateTwo: {
        impressions: { data: [] },
        conversions: { data: [] },
        cpc: { data: [] },
        ctr: { data: [] },
        sales: { data: [] },
        roas: { data: [] },
        formleads: { data: [] },
        clicks: { data: [] },
        spend: { data: [] },
      },
      adValuesDateTwo: {},
    },
    adwordsAdGroups: [],
    facebookAdsets: [],
    facebookAdPreviews: [],
    adwordsCampaigns: [],
    facebookCampaigns: [],
    facebookCampaignsDateTwo: [],
    conversions: [],
    cost_conversion: [],
    cost: [],
    Total_conv_value: [],
    matrix: [],
  },
};

export const paidAdsReducer = {
  CLEAR_AD_PREVIEW_STATE: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          facebookAdPreviews: [],
        },
      },
    };
  },
  FETCHING_ADWORDS_PERFORMANCE_ACCT_LEVEL_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsPerformanceAcctLevel: payload.data,
        },
      },
    };
  },
  FETCHING_FB_PERFORMANCE_ACCT_LEVEL_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      paidAdsData: {
        ...state.show.paidAdsData,
        facebookPerformanceAcctLevel: payload,
      },
    },
  }),
  FETCHING_FB_ADSETS_AD_PREVIEW_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      paidAdsData: {
        ...state.show.paidAdsData,
        facebookAdPreviews: [
          ...state.show.paidAdsData.facebookAdPreviews,
          payload.data,
        ],
      },
    },
  }),
  // facebook
  FETCHING_ADWORDS_MCC_ACCOUNTS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      paidAdsData: {
        ...state.show.paidAdsData,
        MCC_Accounts: payload,
      },
    },
  }),
  CLEAR_PAID_ADS_CAMPAIGNS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      campaignsDropdown: [],
      paidAdsData: {
        ...state.show.paidAdsData,
        adwordsCampaigns: [],
        facebookCampaigns: [],
      },
    },
  }),
  FETCHING_FB_AD_CAMPAIGNS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      paidAdsData: {
        ...state.show.paidAdsData,
        fbCampaigns: payload,
      },
    },
  }),
  FETCHING_FB_AD_CAMPAIGNS_DATE_TWO_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          fbCampaignsDateTwo: payload,
        },
      },
    };
  },
  FETCHING_ADWORDS_PERFORMANCE_MONTH_VS_MONTH_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsPerformanceMonthVsMonth: payload,
        },
      },
    };
  },
  FETCHING_ADWORDS_PERFORMANCE_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsPerformance: payload,
        },
      },
    };
  },
  FETCHING_ADWORDS_PERFORMANCE_DATE_TWO_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      paidAdsData: {
        ...state.show.paidAdsData,
        adwordsPerformanceDateTwo: payload,
      },
    },
  }),
  FETCHING_ADWORDS_AD_SOURCES_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      paidAdsData: {
        ...state.show.paidAdsData,
        adSources: payload.data,
      },
    },
  }),
  FETCHING_FACEBOOK_CAMPAIGNS_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        campaignsDropdown: payload,
        paidAdsData: {
          ...state.show.paidAdsData,
          facebookCampaigns: payload,
        },
      },
    };
  },
  FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsStatsBarDaily: {
            ...state.show.paidAdsData.adwordsStatsBarDaily,
            adValuesDateOne: { ...payload.data },
          },
        },
      },
    };
  },
  FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          facebookAdsStatsBarDaily: {
            ...state.show.paidAdsData.facebookAdsStatsBarDaily,
            adValuesDateOne: { ...payload.data },
          },
        },
      },
    };
  },
  FETCH_UPDATED_CAMPAIGN_VALUES_GOOGLE_DATE_TWO_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsStatsBarDaily: {
            ...state.show.paidAdsData.adwordsStatsBarDaily,
            adValuesDateTwo: { ...payload.data },
          },
        },
      },
    };
  },

  FETCH_UPDATED_CAMPAIGN_VALUES_FACEBOOK_DATE_TWO_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          facebookAdsStatsBarDaily: {
            ...state.show.paidAdsData.facebookAdsStatsBarDaily,
            adValuesDateTwo: { ...payload.data },
          },
        },
      },
    };
  },
  FETCHING_FACEBOOK_CAMPAIGNS_STATS_DAILY_SUCCESS: (state, payload) => {
    let key = payload.key;
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          facebookAdsStatsBarDaily: {
            ...state.show.paidAdsData.facebookAdsStatsBarDaily,
            campaignDoesNotExistInDateRangeError:
              payload.campaignDoesNotExistInDateRangeError,
            dateOne: {
              ...state.show.paidAdsData.facebookAdsStatsBarDaily.dateOne,
              [key]: {
                ...state.show.paidAdsData.facebookAdsStatsBarDaily.dateOne[key],
                data: payload.data,
                value: payload.value,
              },
            },
          },
        },
      },
    };
  },
  FETCHING_FACEBOOK_CAMPAIGNS_STATS_DAILY_SECOND_DATE_SUCCESS: (
    state,
    payload
  ) => {
    let key = payload.key;
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          facebookAdsStatsBarDaily: {
            ...state.show.paidAdsData.facebookAdsStatsBarDaily,
            dateTwo: {
              ...state.show.paidAdsData.facebookAdsStatsBarDaily.dateTwo,
              [key]: {
                ...state.show.paidAdsData.facebookAdsStatsBarDaily.dateTwo[key],
                data: payload.data,
                value: payload.value,
              },
            },
          },
        },
      },
    };
  },

  FETCHING_ADWORDS_CAMPAIGNS_STATS_DAILY_SUCCESS: (state, payload) => {
    let key = payload.key;

    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsStatsBarDaily: {
            ...state.show.paidAdsData.adwordsStatsBarDaily,
            dateOne: {
              ...state.show.paidAdsData.adwordsStatsBarDaily.dateOne,
              [key]: {
                ...state.show.paidAdsData.adwordsStatsBarDaily.dateOne[key],
                data: payload.data,
                value: payload.value,
              },
            },
          },
        },
      },
    };
  },

  FETCHING_ADWORDS_CAMPAIGNS_STATS_DAILY_SECOND_DATE_SUCCESS: (
    state,
    payload
  ) => {
    let key = payload.key;
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsStatsBarDaily: {
            ...state.show.paidAdsData.adwordsStatsBarDaily,
            dateTwo: {
              ...state.show.paidAdsData.adwordsStatsBarDaily.dateTwo,
              [key]: {
                ...state.show.paidAdsData.adwordsStatsBarDaily.dateTwo[key],
                data: payload.data,
                value: payload.value,
              },
            },
          },
        },
      },
    };
  },

  FETCHING_FB_ADSETS_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          facebookAdsets: payload,
        },
      },
    };
  },

  FETCHING_ADWORDS_ADGROUPS_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          adwordsAdGroups: payload.data,
        },
      },
    };
  },
  FETCHING_ADWORDS_CAMPAIGNS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      campaignsDropdown: payload.data,
      paidAdsData: {
        ...state.show.paidAdsData,
        adwordsCampaigns: payload.data,
      },
    },
  }),
  FETCHING_ADWORDS_MTM_SUCCESS: (state, payload) => {
    return {
      ...state,
      show: {
        ...state.show,
        paidAdsData: {
          ...state.show.paidAdsData,
          conversions: payload.data.conversions,
          cost_conversion: payload.data.cost_conversion,
          cost: payload.data.cost,
          Total_conv_value: payload.data.Total_conv_value,
        },
      },
    };
  },
  FETCHING_ADWORDS_MATRIX_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      paidAdsData: {
        ...state.show.paidAdsData,
        matrix: payload.data,
      },
    },
  }),
};
