import _ from "lodash";
import React from "react";
import { func, number, object } from "prop-types";

TemplatesDropdown.propTypes = {
  data: object,
  todoId: number,
  handleAddTemplateData: func
};

function TemplatesDropdown({ data, todoId, handleAddTemplateData }) {
  return (
    <section className="todos-templates">
      <span className="todos-templates__title">Templates</span>
      <ul className="todos-templates__parent">
        {_.map(data, (team, index) => {
          return (
            <li key={index} className="todos-templates__parent-item">
              {index}
              <ul className="todos-templates__child">
                {_.map(team, (template, templateIndex) => {
                  return (
                    <li
                      className="todos-templates__child-item"
                      key={templateIndex}
                      onClick={handleAddTemplateData.bind(null, {
                        todoId,
                        templateData: template.data
                      })}
                    >
                      {template.title}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default TemplatesDropdown;
